import React, { Component } from 'react'
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { Box, Grid, Stack, Paper, Typography, Button, FormControlLabel, Radio, TextField, Checkbox, Tooltip, RadioGroup, FormControl } from '@mui/material';
import { ImagePaths } from '../../Utility/ImagePaths';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { Card_Layout_Default_JSON } from '../../Utility/Constants';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../Styles/Colors';
import Loader from '../../Components/Loader'


class CardLayoutConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            cardWidth: "75",
            cardHeight: "50",
            Label: "true",
            card_detail_data: Card_Layout_Default_JSON,
            drag_label: null,
            AlignLeft: true,
            AlignCenter: false,
            AlignRight: false,
            Margintop: "0",
            MarginRight: "0",
            MarginBottom: "0",
            MarginLeft: "0",
            FontSize: 12,
            isLoader: false,
            LineSpace: 15,
        }
    }

    componentDidMount() {
        this.getCardLayout()
    }

    getCardLayout = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.CUSTOM_BARCODE_CONFIG)
                .then((response) => {
                    if (response.data.status === "success") {
                        var Data = response.data.data
                        this.setState({
                            card_detail_data: Data?.card_details ? Data?.card_details : Card_Layout_Default_JSON,
                            cardWidth: Data?.width ? Data?.width : 75,
                            cardHeight: Data?.height ? Data?.height : 50,
                            Label: Data?.label == true || Data?.label == false ? JSON.stringify(Data?.label) : "true",
                            AlignLeft: Data?.text_align === "left",
                            AlignCenter: Data?.text_align === "center",
                            AlignRight: Data?.text_align === "right",
                            Margintop: Data?.top ? Data?.top : "0",
                            MarginRight: Data?.right ? Data?.right : "0",
                            MarginBottom: Data?.bottom ? Data?.bottom : "0",
                            MarginLeft: Data?.left ? Data?.left : "0",
                            FontSize: Data?.font_size ? Data?.font_size : 12,
                            LineSpace: Data?.line_space ? Data?.line_space : 15,
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    checkValidation = () => {
        let states = this.state
        let cardDetails = states?.card_detail_data.every(obj => !obj.is_active);

        if (states?.cardWidth < 75) {
            this.errorMessage("Card width should be Greater then 75")
            return false
        } else if (states?.cardHeight < 50) {
            this.errorMessage("Card height should be Greater then 50")
            return false
        } else if (states?.FontSize < 7) {
            this.errorMessage("Font Size should be Greater then 7")
        } else if (states?.LineSpace < 10) {
            this.errorMessage("Line Space should be Greater than 10")
        }
        // else if ((+states?.MarginLeft + +states?.MarginRight) > (+states?.cardWidth / 10)) {
        //     this.errorMessage("Increase width or Decrease left & right spacing")
        //     return false
        // }
        // else if ((+states?.Margintop + +states?.MarginBottom) > (+states?.cardHeight / 10)) {
        //     this.errorMessage("Increase Height or Decrease top & bottom spacing")
        //     return false
        // }
         else if (cardDetails) {
            this.errorMessage("Card details should'n be empty")
            return false
        } else {
            return true
        }
    }

    PostCardDetails = () => {
        let states = this.state
        try {
            let validation = this.checkValidation()
            if (validation) {
                this.LoaderFunction(true)
                let data = {
                    "top": states?.Margintop ? Number(states?.Margintop) : 0,
                    "left": states?.MarginLeft ? Number(states?.MarginLeft) : 0,
                    "label": JSON.parse(states?.Label),
                    "right": states?.MarginRight ? Number(states?.MarginRight) : 0,
                    "bottom": states?.MarginBottom ? Number(states?.MarginBottom) : 0,
                    "font_size": Number(states?.FontSize),
                    "text_align": states?.AlignRight ? "right" : states?.AlignLeft ? 'left' : 'center',
                    "width": Number(states?.cardWidth),
                    "height": Number(states?.cardHeight),
                    "card_details": states?.card_detail_data,
                    "line_space": states?.LineSpace ? Number(states?.LineSpace) : 15,
                }
                RestAPIService.create(data, Serviceurls.CUSTOM_BARCODE_CONFIG).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            this.getCardLayout()
                        }
                    }).catch((error) => {
                        this.LoaderFunction(false)
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    ResetCardDetails = () => {
        try {
            this.setState({
                cardWidth: "75",
                cardHeight: "50",
                Label: "true",
                card_detail_data: Card_Layout_Default_JSON,
                drag_label: null,
                AlignLeft: true,
                AlignCenter: false,
                AlignRight: false,
                Margintop: "0",
                MarginRight: "0",
                MarginBottom: "0",
                MarginLeft: "0",
                FontSize: 12
            }, () => { this.handleCheckClick() })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    handleDrag = (e) => {
        this.setState({
            drag_label: e.currentTarget.id
        })
    }

    handleDrop = (e) => {
        var states = this.state
        const dragBox = (states.card_detail_data).find((field) => field.label === states.drag_label);
        const dropBox = (states.card_detail_data).find((field) => field.label === e.currentTarget.id);

        const dragBoxID = dragBox.id;
        const dropBoxID = dropBox.id;

        const dragBoxField = dragBox.field_name;
        const dropBoxField = dropBox.field_name;

        const dragBoxCheck = dragBox.is_active;
        const dropBoxCheck = dropBox.is_active;

        const dragBoxDefault = dragBox.is_default;
        const dropBoxDefault = dropBox.is_default;

        const newBoxState = (states.card_detail_data).map((field) => {
            if ((!dragBoxDefault && !dropBoxDefault) || (dragBoxDefault && dropBoxDefault)) {
                if (field.label === states.drag_label) {
                    field.id = dropBoxID;
                    field.field_name = dragBoxField;
                    field.is_active = dragBoxCheck;
                    field.is_default = dragBoxDefault;
                }
                if (field.label === e.currentTarget.id) {
                    field.id = dragBoxID;
                    field.field_name = dropBoxField;
                    field.is_active = dropBoxCheck;
                    field.is_default = dropBoxDefault;
                }
            } else if (dropBoxID <= +this.state.mandotory_count && dragBoxID <= +this.state.mandotory_count) {
                if (field.label === states.drag_label) {
                    field.id = dropBoxID;
                    field.field_name = dragBoxField;
                    field.is_active = dragBoxCheck;
                    field.is_default = dragBoxDefault;
                }
                if (field.label === e.currentTarget.id) {
                    field.id = dragBoxID;
                    field.field_name = dropBoxField;
                    field.is_active = dropBoxCheck;
                    field.is_default = dropBoxDefault;
                }
            }
            return field;
        });

        const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

        this.setState({
            card_detail_data: SortData
        })
    }

    handleCheckClick = (e) => {
        var { card_detail_data } = this.state

        const ID = e?.target?.id

        if (ID) {
            const Index = card_detail_data.findIndex((data) => {
                return data.id == ID
            })

            if (card_detail_data[Index].is_default) {

            } else {
                card_detail_data[Index].is_active = e.target.checked
            }

            this.setState({
                card_detail_data
            })
        } else {
            card_detail_data.forEach((item) => {
                const Index = card_detail_data.findIndex((data) => {
                    return data.id == item.id
                })

                if (card_detail_data[Index].is_default) {

                } else {
                    card_detail_data[Index].is_active = true
                }

                this.setState({
                    card_detail_data
                })
            })
        }
    }

    renderCardField = (data) => {
        return (
            data?.label === "Barcode" ?
                <div
                    draggable={false}
                    id={data.label}
                    className="eMed_demolayout_right2_field">
                    <FormControlLabel control={<Checkbox id={data.id} emed_tid="Card_Details_CheckBox" onChange={this.handleCheckClick} checked={data.is_active} />} label={data.label} />
                </div> :
                <div
                    draggable={true}
                    onDragOver={(e) => e.preventDefault()}
                    onDragStart={this.handleDrag}
                    onDrop={this.handleDrop}
                    id={data.label}
                    className="eMed_demolayout_right2_field">
                    <img className="eMed_demolayout_right2_field_img" src={ImagePaths.DragIndicator.default} alt="Drag Here" />
                    <FormControlLabel control={<Checkbox id={data.id} emed_tid="Card_Details_CheckBox" onChange={this.handleCheckClick} checked={data.is_active} />} label={data.label} />
                </div>
        )
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let states = this.state
            switch (names) {
                case 'Width':
                    if ((CommonValidation.DecimalNumber(value) && value <= 100 && (value != "00")) || value === '') {
                        this.setState({ cardWidth: value })
                    }
                    break;
                case 'Height':
                    if ((CommonValidation.DecimalNumber(value) && value <= 100 && (value != "00")) || value === '') {
                        this.setState({ cardHeight: value })
                    }
                    break;
                case 'Top':
                    if ((CommonValidation.DecimalNumber(value) && value <= 30 && (value != "00")) || value === '') {
                        this.setState({ Margintop: value })
                    }
                    break;
                case 'Bottom':
                    if ((CommonValidation.DecimalNumber(value) && value <= 30 && (value != "00")) || value === '') {
                        this.setState({ MarginBottom: value })
                    }
                    break;
                case 'Left':
                    if ((CommonValidation.DecimalNumber(value) && value <= 15 && (value != "00")) || value === '') {
                        this.setState({ MarginLeft: value })
                    }
                    break;
                case 'Right':
                    if ((CommonValidation.DecimalNumber(value) && value <= 15 && (value != "00")) || value === '') {
                        this.setState({ MarginRight: value })
                    }
                    break;
                case 'Font Size':
                    if ((CommonValidation.DecimalNumber(value) && value <= 14 && (value != "00")) || value === '') {
                        this.setState({ FontSize: value })
                    }
                    break;
                case 'Line Space':
                    if ((CommonValidation.DecimalNumber(value) && value <= 30 && (value != "00")) || value === '') {
                        this.setState({ LineSpace: value })
                    }
                    break;
                default:
                    states[names] = value
                    this.setState({ states })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextField = (name, value, marginLeft) => {
        return (
            <Box sx={{ marginLeft: marginLeft }}>
                <Typography mb={"0.3vw"}>{name}</Typography>
                <TextField
                    size='small'
                    sx={{ width: "10vw" }}
                    autoComplete='off'
                    value={value}
                    inputProps={{ style: { fontWeight: '600' } }}
                    onChange={(e) => {
                        this.onChangeHandler(e, name)
                    }}
                    InputProps={{
                        endAdornment: <Typography>{name === "Font Size" ? "px" : "mm"}</Typography>,
                        emed_tid: `Field_${name}`
                    }}
                />
            </Box>
        )
    }

    renderCardLeft = () => {
        try {
            var states = this.state

            var Active_Array = (states.card_detail_data).filter((item) => {
                return item.is_active == true
            })
            let lineHeight = states.LineSpace > 10 ? ((states.LineSpace - 10) / 2) + 4 : 6.5
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_common_Tbl_right' sx={{ width: "98%", }} elevation={2}>
                        <Typography className='eMed_refdoc_title'>{"Card Layout Configuration"}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "95%" }}>
                            <Box sx={{ width: `${states.cardWidth}mm`, border: "1px solid black", height: `${+states.cardHeight + 10}mm`, padding: "0.5vw 0.65vw 0vw 0.5vw" }}>
                                <Box sx={{
                                    display: "flex", flexDirection: "column", alignItems: states?.AlignCenter ? "center" : states?.AlignRight ? "flex-end" : "flex-start",
                                    marginTop: `${states.Margintop}mm`, marginBottom: `${states.MarginBottom}mm`, marginLeft: `${states?.MarginLeft}mm`, marginRight: `${states?.MarginRight}mm`,
                                    lineHeight: `${lineHeight}mm`
                                }}>

                                    {Active_Array.map((item, index) => (
                                        item?.label === "Barcode" ?
                                            <img src={ImagePaths.HorizonBarCode.default} style={{ height: "2.75vw", marginLeft: "0.65vw", marginRight: "0.65vw" }} />
                                            :
                                            states?.Label === "true" && states?.AlignLeft ?
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                                    <div key={index} className='eMed_Card_Details' style={{ fontSize: `${states.FontSize}px`, width: item?.label === "Address 1" ? "5vw" : "7vw" }}>{item?.label}</div>
                                                    <div key={index} className='eMed_Card_Details' style={{ fontSize: `${states.FontSize}px` }}>{`: ${item?.without_label}`}</div>
                                                </div>
                                                :
                                                states?.Label === "true" && !states?.AlignLeft ?
                                                    <div key={index} className='eMed_Card_Details' style={{ fontSize: `${states.FontSize}px` }}>{item?.with_label}</div>
                                                    :
                                                    <div key={index} className='eMed_Card_Details' style={{ fontSize: `${states.FontSize}px` }}>{item?.without_label}</div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Stack>
            )
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderCardRight = () => {
        try {
            var states = this.state
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_common_Tbl_right' sx={{ overflow: "auto" }} elevation={2}>
                        <Typography className='eMed_refdoc_title'>{"Card Layout Configuration"}</Typography>
                        <Box sx={{ height: "63.5vh", overflow: "auto" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", padding: "1vw", justifyContent: "center", borderBottom: "1px solid #E3E2E2" }}>
                                <Typography sx={{ fontWeight: "600" }}>{"Card Layout Field"}</Typography>
                                <Typography sx={{ fontSize: "0.8vw" }}>{"(Switch the fields according to your practice)"}</Typography>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #E3E2E2" }}>
                                <FormControl sx={{ display: "flex", alignItems: "flex-end", marginTop: "0.5vw" }}>
                                    <RadioGroup emed_tid={'Radio_Container'}
                                        value={this.state.Label}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        onChange={(e) => {
                                            this.setState({
                                                Label: e.target.value
                                            })
                                        }}
                                    >
                                        <FormControlLabel emed_tid={'With_Label'} value={true} control={<Radio size='small' />} label="With Label" />
                                        <FormControlLabel emed_tid={'With_Out_Label'} value={false} control={<Radio size='small' />} label="Without Label" />
                                    </RadioGroup>
                                </FormControl>
                                <Typography sx={{ fontWeight: "600", marginLeft: "1vw", marginTop: "0.5vw" }}>{"Card Resolution"}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "0.5vw", marginLeft: "1vw", marginBottom: "0.75vw" }}>
                                    {this.renderTextField('Width', this.state.cardWidth, "")}
                                    {this.renderTextField('Height', this.state.cardHeight, "1.5vw")}
                                </Box>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #E3E2E2" }}>
                                <Typography sx={{ fontWeight: "600", marginLeft: "1vw", marginTop: "0.5vw" }}>{"Card Spacing"}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "0.5vw", marginLeft: "1vw", marginBottom: "0.75vw" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "0.75vw", marginBottom: "0.75vw" }}>
                                        {this.renderTextField('Top', this.state.Margintop, "")}
                                        {this.renderTextField('Left', this.state.MarginLeft, "1vw")}
                                        {this.renderTextField('Right', this.state.MarginRight, "1vw")}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #E3E2E2" }}>
                                <Typography sx={{ fontWeight: "600", marginLeft: "1vw", marginTop: "0.5vw" }}>{"Card Spacing"}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "0.5vw", marginLeft: "1vw", marginBottom: "0.75vw" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "0.75vw", marginBottom: "0.75vw" }}>
                                        {this.renderTextField('Font Size', this.state.FontSize, "")}
                                        {this.renderTextField('Line Space', this.state.LineSpace, "1.5vw")}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #E3E2E2" }}>
                                <Box sx={{ borderBottom: "1px solid #E3E2E2" }}>
                                    <Typography sx={{ fontWeight: "600", marginLeft: "1vw", marginTop: "0.75vw", marginBottom: "0.65vw" }}>{"Card Details"}</Typography>
                                </Box>
                                {states.card_detail_data.map((item) => {
                                    return (
                                        this.renderCardField(item)
                                    )
                                })}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", padding: "1vw", justifyContent: "center" }}>
                                <Typography sx={{ fontWeight: "600" }}>{"Content Alignment"}</Typography>
                                <Typography sx={{ fontSize: "0.8vw" }}>{"(Switch the fields according to your practice)"}</Typography>
                                <Stack direction="row" spacing={2} alignItems="flex-end" mt={"1vw"}>
                                    <Tooltip placement="top" title={"Align Left"}>
                                        <Button emed_tid={'Left_Align_Button'} onClick={() => { this.setState({ AlignLeft: true, AlignCenter: false, AlignRight: false }) }} variant={states?.AlignLeft ? 'contained' : 'outlined'} size="small"><img style={{ width: "1.3vw", height: "2vw" }} src={states?.AlignLeft ? ImagePaths.AlignLeft.default : ImagePaths.DisAlignLeft.default} alt='Align Left' /></Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={"Align Center"}>
                                        <Button emed_tid={'Center_Align_Button'} onClick={() => { this.setState({ AlignCenter: true, AlignRight: false, AlignLeft: false }) }} variant={states?.AlignCenter ? 'contained' : 'outlined'} size="small"><img style={{ width: "1.3vw", height: "2vw" }} src={states?.AlignCenter ? ImagePaths.AlignCenter.default : ImagePaths.DisAlignCenter.default} alt='Align Center' /></Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={"Align Right"}>
                                        <Button emed_tid={'Right_Align_Button'} onClick={() => { this.setState({ AlignRight: true, AlignLeft: false, AlignCenter: false }) }} variant={states?.AlignRight ? 'contained' : 'outlined'} size="small"><img style={{ width: "1.3vw", height: "2vw" }} src={states?.AlignRight ? ImagePaths.AlignRight.default : ImagePaths.DisAlignRight.default} alt='Align Right' /></Button>
                                    </Tooltip>
                                </Stack>
                            </Box>
                        </Box>
                        <Stack className='eMed_DisSum_right_Btn' spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                            <Button emed_tid={'Reset_Button'} disabled={states?.isLoader} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ResetCardDetails() }}>{"Reset"}</Button>
                            <Button emed_tid={'Post_Button'} disabled={states?.isLoader} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.PostCardDetails() }}>{"Save"}</Button>
                        </Stack>
                    </Paper>
                </Stack>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        try {
            return (
                <Box component={'div'} className='eMed_usrconf_container'>
                    <Grid container className='eMed_refdoc_content' >
                        <Grid item xs={7.5}>
                            {this.renderCardLeft()}
                        </Grid>
                        <Grid item xs={4.5}>
                            {this.renderCardRight()}
                        </Grid>
                    </Grid>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    <Loader loaderOpen={this.state.isLoader} />
                </Box>
            )
        } catch (e) {
            this.errorMessage(e.message)
        }

    }
}

export default CardLayoutConfig;