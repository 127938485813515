import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, Card, Drawer, FormControlLabel, Grid, IconButton, Modal, Paper, Radio, RadioGroup, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { clearCachevalue, getCachevalue, localGetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { CommonPatientCard, CommonPatientDetails, ConfirmPopup, ConsultantPatientCard } from '../../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../../Styles/Colors';
import '../../../FrontOffice/IPPatients/PatientsDetails/IpPatientDetails.css'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import MenuOpenIcon from '@mui/icons-material/MenuOpen'; import '../DoctorNotes.css'
import DocSymptomsLeft from './DocSymptomsLeft';
import { Other_Doctor_Notes_Layout_JSON } from '../../../../Utility/Constants';
import DocSymptomsRight from './DocSymptomsRight';
import DocDiagnosisLeft from './DocDiagnosisLeft';
import DocDiagnosisRight from './DocDiagnosisRight';
import DocGenAdviceRight from './DocGenAdviceRight';
import DocGenAdviceleft from './DocGenAdviceleft';
import DocPatientCetegoryRight from './DocPatientCetegoryRight';
import DocPatientCategoryLeft from './DocPatientCategoryLeft';
import DocAllergiesLeft from './DocAllergiesLeft';
import DocAllergiesRight from './DocAllergiesRight';
import DocExaminationRight from './DocExaminationRight';
import DocExaminationLeft from './DocExaminationLeft';
import DocPrecriptionRight from './DocPrecriptionRight';
import DocPrescriptionLeft from './DocPrescriptionLeft';
import DocTestLeft from './DocTestLeft';
import DocTestRight from './DocTestRight';
import DocPlanRight from './DocPlanRight';
import DocPlanLeft from './DocPlanLeft';
import DocCounsellingRight from './DocCounsellingRight';
import DocCounsellingLeft from './DocCounsellingLeft';
import DocVitalsLeft from './DocVitalsLeft';
import DocVitalsRight from './DocVitalsRight';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { DateTime } from 'luxon';
import DocReviewPlanLeft from './DocReviewPlanLeft';
import DocReviewPlanRight from './DocReviewPlanRight';
import DocClinicExamENTLeft from './DocClinicExamENTLeft';
import DocClinicExamENTRight from './DocClinicExamENTRight';

class OtherDoctorNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicId: localGetItem('SelectedDoctorClinicId'),
            PatientData: {},
            SelectedComponent: null,
            IPadmissionDetails: {},
            CallGetComponent: "",
            EditSymptomsData: {},
            EditCounsellingData: {},
            EditReviewPlanData: {},
            EditPlanDatas: {},
            EditExaminationsData: {},
            EditGenAdviceText: "",
            withOutAppointment: {},
            EditAllergyData: {},
            EditPrescriptionData: {},
            DocNotesLayout: [],
            TransferPopup: false,
            SpecializationInfo: [],
            SpecdocDetails: [],
            filteredDocList: [],
            filterspcInfo: [],
            Specilazation: null,
            Doctor: null,
            layoutloading: true,
            ClearClickedonRightPrecs: false,
            isLocked: false,
            TranferMethod: "Transfer Appointment",
            spcInfo: [],
            selectedspec: null,
            docDetails: [],
            selectedDoc: null,
            POVList: [],
            PurposeOfVisit: "",
            allergyData: [],
            prescriptionList: [],
            isSaveClicked: false,
        }
        this.divRefs = [];
        for (let i = 0; i < 15; i++) {
            this.divRefs[i] = React.createRef();
        }
    }

    componentDidMount() {
        let PatientData = getCachevalue("DoctorPatientData")
        let patientData = JSON.parse(PatientData)
        let ServiceType = localGetItem("loggedInUserInfo")
        let myUser = JSON.parse(ServiceType)
        let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
        let array = []
        JSON.parse(POVList).forEach(element => array.push(element.reason));
        if (array.length > 0) {
            this.setState({
                POVList: array
            })
        }
        this.setState({
            PatientData: patientData,
            appointmentId: patientData?.appointment_id,
            patientId: patientData?.patient_id,
            isLocked: patientData?.transfer_appointment ? patientData?.transfer_appointment : patientData?.is_locked,
            TransferDisable: patientData?.is_transmitted ? patientData?.is_transmitted : false
        }, () => {
            this.getDoctorNotesLayout()
            this.getDoctorDetails(myUser?.doctor_id ? myUser?.doctor_id : null)

        })
    }

    getDoctorDetails = (CurrentDocID) => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?clinic_id=${this.state.clinicId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        let List = response.data.data ? response.data.data.filter((item) => (item?.doctor_id !== CurrentDocID)) : []
                        this.setState({
                            docDetails: List,
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    allergyData = (data) => {
        this.setState({ allergyData: data })
    }

    getDoctorNotesLayout = () => {
        try {
            this.setState({ layoutloading: true })
            let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
            let Doctor_Notes_Layout = JSON.parse(JSON.stringify(Other_Doctor_Notes_Layout_JSON))
            if (UserData?.service_type === "ENT") {
                let ENTLayout = { id: Doctor_Notes_Layout?.length + 1, field_name: 'clinicExam', label: 'Clinical Examination', is_active: true }
                Doctor_Notes_Layout?.push(ENTLayout)
            }
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_LAYOUT)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            DocNotesLayout: response.data.data?.left_data ? response.data.data?.left_data : Doctor_Notes_Layout,
                            //   doctorNotesLeft: response.data.data?.left_data ? response.data.data?.left_data : (this.state.isOPT || (UserData?.service_type === "OPTOMETRY")) ? Doctor_Notes_OPT_Left_Layout_JSON : Doctor_Notes_Left_Layout_JSON,
                            //   doctorNotesRight: response.data.data?.right_data ? response.data.data?.right_data : (this.state.isOPT || (UserData?.service_type === "OPTOMETRY")) ? Doctor_Notes_OPT_Right_Layout_JSON : Doctor_Notes_Right_Layout_JSON,
                            layoutloading: false
                        }, () => {
                            this.setState({
                                SelectedComponent: this.state.DocNotesLayout[0]?.field_name,

                            }, () => {
                                // this.TriggerGetApi(this.state.DocNotesLayout[0]?.field_name)
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ layoutloading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ layoutloading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ layout: false })
            this.errorMessage(error.message)
        }
    }

    getPatientData = () => {
        let admission_id = this.state.PatientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            IPadmissionDetails: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    TriggerGetApi(component) {
        this.setState({
            CallGetComponent: component
        }, () => {
            setTimeout(() => { this.setState({ CallGetComponent: "" }) }, 500)
        })
    }

    EditSymptomData(data) {
        this.setState({
            EditSymptomsData: data
        })
    }
    EditCounsellingData(data) {
        this.setState({
            EditCounsellingData: data
        })
    }
    EditReviewPlanData(data) {
        this.setState({
            EditReviewPlanData: data
        })
    }
    EditClinicalExamNotes(data) {
        this.setState({
            EditClinicalExamNotes: data
        })
    }
    EditPlanDatas(data) {
        this.setState({
            EditPlanDatas: data
        })
    }
    EditExaminationsData(data) {
        this.setState({
            EditExaminationsData: data
        })
    }
    EditGenAdviceText(data) {
        this.setState({
            EditGenAdviceText: data
        })
    }
    EditPrescriptionData(data) {
        this.setState({
            EditPrescriptionData: data
        })
    }
    EditAllergyData(data) {
        this.setState({
            EditAllergyData: data
        })
    }
    AddPrescritionItem(data) {
        this.setState({
            AddPrescritionItem: data
        })
    }
    AddClinicalExamNotes(data) {
        this.setState({
            clinicalExamNotes: data
        })
    }
    isDataSaved = () => {
        this.setState({
            isSaveClicked: false
        })
    }
    ClearClickedonRightPrecs(flag) {
        this.setState({
            ClearClickedonRightPrecs: flag
        })
    }
    handleDisableTransfer(flag) {
        this.setState({
            TansmitDisable: flag
        })
    }
    ClearPropsData() {
        this.setState({
            EditSymptomsData: {},
            EditCounsellingData: {},
            EditPlanDatas: {},
            EditExaminationsData: {},
            EditGenAdviceText: "",
            EditAllergyData: {},
            EditPrescriptionData: {},
            EditReviewPlanData: {},
            ClearClickedonRightPrecs: false
        })
    }

    renderComponent(Component) {
        let Userdata = localGetItem("loggedInUserInfo")
        let UserInfo = JSON.parse(Userdata)
        switch (Component) {
            case "symptoms":
                return <DocSymptomsLeft
                    fromDocModule={true}
                    PatientData={this.state.PatientData}
                    EditSymptomData={this.EditSymptomData.bind(this)}
                    GetAPiCall={this.state.CallGetComponent === 'symptoms'}
                    isLocked={this.state.isLocked}
                    UserInfo={UserInfo}
                    SelectedComponent={this.state.SelectedComponent}
                />

            case "examination":
                return <DocExaminationLeft
                    PatientData={this.state.PatientData}
                    EditExaminationsData={this.EditExaminationsData.bind(this)}
                    GetAPiCall={this.state.CallGetComponent === 'examination'}
                    isLocked={this.state.isLocked}
                />

            case "diagnosis":
                return <DocDiagnosisLeft
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'diagnosis'}
                    isLocked={this.state.isLocked}
                />

            case "patient_category":
                return <DocPatientCategoryLeft
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'patient_category'}
                    isLocked={this.state.isLocked}
                />

            case "general_advice":
                return <DocGenAdviceleft
                    PatientData={this.state.PatientData}
                    EditGenAdviceText={this.EditGenAdviceText.bind(this)}
                    GetAPiCall={this.state.CallGetComponent === 'general_advice'}
                    isLocked={this.state.isLocked}
                />

            case "allergies":
                return <DocAllergiesLeft
                    PatientData={this.state.PatientData}
                    EditAllergyData={this.EditAllergyData.bind(this)}
                    GetAPiCall={this.state.CallGetComponent === 'allergies'}
                    isLocked={this.state.isLocked}
                    SelectedComponent={this.state.SelectedComponent}
                    allergyData={this.allergyData}
                />

            case "prescription":
                return <DocPrescriptionLeft
                    PatientData={this.state.PatientData}
                    EditPrescriptionData={this.EditPrescriptionData.bind(this)}
                    GetAPiCall={this.state.CallGetComponent === 'prescription'}
                    AddPrescritionItem={this.state.AddPrescritionItem}
                    ClearClickedonRightPrecs={this.state.ClearClickedonRightPrecs}
                    isLocked={this.state.isLocked}
                    handleDisableTransfer={this.handleDisableTransfer.bind(this)}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    getPrescriptionData = {(list) => {this.setState({prescriptionList: list})}}
                />
            case "test":
                return <DocTestLeft
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'test'}
                    isLocked={this.state.isLocked}
                />
            case "plan":
                return <DocPlanLeft
                    EditPlanDatas={this.EditPlanDatas.bind(this)}
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'plan'}
                    isLocked={this.state.isLocked}
                    SelectedComponent={this.state.SelectedComponent}
                />
            case "counselling":
                return <DocCounsellingLeft
                    EditCounsellingData={this.EditCounsellingData.bind(this)}
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'counselling'}
                    isLocked={this.state.isLocked}
                    handleDisableTransfer={this.handleDisableTransfer.bind(this)}
                />

            case "vitals":
                return <DocVitalsLeft
                    PatientData={this.state.PatientData}
                    GetAPiCall={this.state.CallGetComponent === 'vitals'}
                    isLocked={this.state.isLocked}
                    UserInfo={UserInfo}
                />

            case 'review_plan':
                return <DocReviewPlanLeft
                    appointmentId={this.state.appointmentId}
                    isLocked={this.state.isLocked}
                    GetAPiCall={this.state.CallGetComponent === 'review_plan'}
                    EditReviewPlanData={this.EditReviewPlanData.bind(this)}
                />

            case 'clinicExam':
                return <DocClinicExamENTLeft
                    appointmentId={this.state.appointmentId}
                    PatientData={this.state.PatientData}
                    isLocked={this.state.isLocked}
                    GetAPiCall={this.state.CallGetComponent === 'clinicExam'}
                    clinicalExamNotes={this.state.clinicalExamNotes}
                    EditClinicalExamNotes={this.EditClinicalExamNotes.bind(this)}
                    AddClinicalExamNotes={this.AddClinicalExamNotes.bind(this)}
                    isSaveClicked={this.state.isSaveClicked}
                    isDataSaved={this.isDataSaved}
                />
        }
    }

    renderRightComponent(Component) {
        let Userdata = localGetItem("loggedInUserInfo")
        let UserInfo = JSON.parse(Userdata)
        switch (Component) {
            case "symptoms":
                return <DocSymptomsRight
                    EditSymptomsData={this.state.EditSymptomsData}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                    UserInfo={UserInfo}
                />

            case "examination":
                return <DocExaminationRight
                    EditExaminationsData={this.state.EditExaminationsData}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />

            case "diagnosis":
                return <DocDiagnosisRight
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    isLocked={this.state.isLocked}
                />

            case "patient_category":
                return <DocPatientCetegoryRight
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    isLocked={this.state.isLocked}
                />

            case "general_advice":
                return <DocGenAdviceRight
                    EditGenAdviceText={this.state.EditGenAdviceText}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />

            case "allergies":
                return <DocAllergiesRight
                    EditAllergyData={this.state.EditAllergyData}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />
            case "prescription":
                return <DocPrecriptionRight
                    EditPrescriptionData={this.state.EditPrescriptionData}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearClickedonRightPrecs={this.ClearClickedonRightPrecs.bind(this)}
                    AddPrescritionItem={this.AddPrescritionItem.bind(this)}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                    prescripTblData = {this.state.prescriptionList || []}
                />
            case "test":
                return <DocTestRight
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />

            case "plan":
                return <DocPlanRight
                    EditPlanDatas={this.state.EditPlanDatas}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />

            case "counselling":
                return <DocCounsellingRight
                    EditCounsellingData={this.state.EditCounsellingData}
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                />

            case "vitals":
                return <DocVitalsRight
                    CallgetApi={this.TriggerGetApi.bind(this)}
                    PatientData={this.state.PatientData}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    isLocked={this.state.isLocked}
                    UserInfo={UserInfo}
                />

            case 'review_plan':
                return <DocReviewPlanRight
                    EditReviewPlanData={this.state.EditReviewPlanData}
                    appointmentId={this.state.appointmentId}
                    ClearPropsData={this.ClearPropsData.bind(this)}
                    callGet = {this.TriggerGetApi.bind(this)}
                />

            case 'clinicExam':
                return <DocClinicExamENTRight
                    EditClinicalExamNotes={this.state.EditClinicalExamNotes}
                    AddClinicalExamNotes={this.AddClinicalExamNotes.bind(this)}
                />
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ""
        })
    }
    ClosePopUp() {
        this.setState({
            OpenDrawer: false,
        })
    }

    getSpecInfo = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION + `?clinic_id=${this.state.clinicId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
                        let datas = response.data.data ? response.data.data : []
                        let SpecList = []
                        let Specialization = []
                        if (this.state.TranferMethod === "Transfer Appointment") {
                            SpecList = datas?.filter((item) => (item.is_active === true && item.id === UserData?.specialization_id))
                        } else {
                            Specialization = datas?.filter((item) => (item.is_active === true))
                        }
                        this.setState({
                            spcInfo: SpecList,
                            SpecializationInfo: Specialization,
                            selectedspec: SpecList[0],
                            SpecdocDetails: this.state?.docDetails?.filter((item) => (item?.specialization_id === SpecList[0]?.id))
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    PostAddendum = () => {
        try {
            let data = {
                addendum_notes: this.state.Addcomments
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LOCK + `${this.state.appointmentId}/lock/`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ addendumPopup: false, Addcomments: "" }, () => {
                            this.getDoctorPatientAppointment()
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postPatientStatus = (status) => {
        let { history } = this.props;
        try {
            var states = this.state
            var data = {}
            if (status == "Scan/Surgery") {
                data = {
                    "appointment_id": this.state.appointmentId,
                    "appointment_status": status,
                    "waiting_timer": null,
                    "clinic_id" : this.state.clinicId,
                }
            } else if (status == "Completed") {
                data = {
                    "appointment_id": this.state.appointmentId,
                    "appointment_status": "Completed",
                    "waiting_timer": null,
                    "clinic_id" : this.state.clinicId,
                }
            } else {
                data = {
                    "appointment_id": this.state.appointmentId,
                    "appointment_status": "Completed",
                    "waiting_timer": null,
                    "is_transfered": true,
                    "transfer_doctor_id": this.state.Doctor?.doctor_id ? this.state.Doctor?.doctor_id : null,
                    "transfer_id": states?.transferId ? states?.transferId : null,
                    "clinic_id" : this.state.clinicId,
                }
            }
            RestAPIService.create(data, Serviceurls.DOC_APPOINTMENT_STATUS_UPDATE)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            scan_status: false
                        })
                        history.push({ pathname: "/DoctorHome" })
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    postDoctorAppointmentCreate = () => {
        try {
            var states = this.state

            var AptDateTime = new Date()

            var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')

            var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let clinicId = getCachevalue('SelectedDoctorClinic')
            if (states.Doctor?.doctor_id && states.Specilazation?.id && states.PurposeOfVisit) {
                var data = {
                    patient_id: this.state.patientId,
                    doctor_id: states.Doctor?.doctor_id ? states.Doctor?.doctor_id : null,
                    speciality_id: states.Specilazation?.id ? states.Specilazation?.id : null,
                    purpose_of_visit: states.PurposeOfVisit,
                    patient_tag_id: states.selectedTagId?.id,
                    appointment_date: AptDate,
                    appointment_start_time: Aptime,
                    patient_appointment_type: states.selectedAppointTypeId === null ? null : states.selectedAppointTypeId?.id ? states.selectedAppointTypeId?.id : 3,
                    nurse_id: states.selectedNurseId?.staff_id ? states.selectedNurseId?.staff_id : null,
                    request_id: this.state.RequestedPatientData?.id ? this.state.RequestedPatientData?.id : null,
                    clinic_id: +clinicId,
                    new_appointment: true
                }
                RestAPIService.create(data, Serviceurls.DOC_PATIENT_APPOINTMENT)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.setState({ TransferPopup: false })
                            this.postPatientStatus()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
            else {
                this.errorMessage("Kindly Select the Mandatory Fields")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostTransfer = () => {
        let { history } = this.props;
        try {
            let data = {
                "clinic_id": this.state.clinicId,
                "appointment_id": this.state?.appointmentId,
                "doctor_id": this.state.selectedDoc?.doctor_id,
                "patient_id": this.state.patientId,
                "transfer_notes": this.state.comments
            }
            if (this.state.selectedDoc?.doctor_id) {
                RestAPIService.create(data, Serviceurls.DOC_TRANSFER_REQUEST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response.data.message)
                            this.setState({
                                TransferPopup: false
                            }, () => {
                                clearCachevalue("DoctorPatientData")
                                history.push({ pathname: "/DoctorHome" })
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Kindly Select the Mandatory Fields")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    TransferAppointPopup = () => {
        let states = this.state
        return (
            <div>
                <Modal open={this.state.TransferPopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "33vw", height: "60vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600}>Transfer Appointment</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ TransferPopup: false, TranferMethod: "Transfer Appointment", selectedDoc: null, selectedspec: null, comments: "", PurposeOfVisit: "" })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <RadioGroup value={this.state.TranferMethod}
                                    sx={{ display: 'flex', flexDirection: 'row', marginTop: "0.65vw" }}
                                    onClick={(e) => { this.setState({ TranferMethod: e.target.value, selectedspec: null, selectedDoc: null, filteredDocList: [], Specilazation: null, Doctor: null, PurposeOfVisit: "" }, () => { this.getSpecInfo() }) }}
                                >
                                    <FormControlLabel value={'Transfer Appointment'} control={<Radio size='small' />} label='Transfer Doctor' />
                                    <FormControlLabel value={'New Appointment'} control={<Radio size='small' />} label='Transfer Appointment' />
                                </RadioGroup>
                            </Box>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                                <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                                    {this.state?.TranferMethod === "Transfer Appointment" ? <Autocomplete
                                        clearIcon
                                        freeSolo
                                        size='small'
                                        sx={{ width: "15vw" }}
                                        options={this.state.spcInfo}
                                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.name}
                                        value={this.state?.selectedspec}
                                        onChange={(e, newValue) => {
                                            states["selectedspec"] = newValue
                                            this.setState({
                                                states
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Specialization *' />}
                                    /> :
                                        this.renderSuggestionDropDown("Specialization", "Specilazation", this.state.SpecializationInfo)
                                    }
                                </div>
                                <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                                    {this.state?.TranferMethod === "Transfer Appointment" ? <Autocomplete
                                        clearIcon
                                        freeSolo
                                        size='small'
                                        sx={{ width: "15vw" }}
                                        options={this.state.SpecdocDetails}
                                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.doctor_name}
                                        value={this.state?.selectedDoc}
                                        onChange={(e, newValue) => {
                                            states["selectedDoc"] = newValue
                                            this.setState({
                                                states
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Doctor *' />}
                                    /> :
                                        this.renderSuggestionDropDown("Doctor", "Doctor", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.docDetails)
                                    }
                                </div>
                            </div>
                            {this.state.TranferMethod == "New Appointment" ? <Box sx={{ paddingLeft: "1vw", paddingBottom: "0.5vw" }}>
                                <Autocomplete
                                    inputProps={{ maxLength: 90 }}
                                    options={states.POVList}
                                    getOptionLabel={(options) => options}
                                    onChange={(e, value) => {
                                        this.setState({
                                            PurposeOfVisit: value
                                        })
                                    }}
                                    clearIcon={false}
                                    size='small'
                                    sx={{ width: "31vw" }}
                                    value={states.PurposeOfVisit}
                                    renderInput={(params) => <TextField onChange={(event) => {
                                        this.setState({
                                            PurposeOfVisit: event.target.value
                                        })
                                    }}
                                        {...params}
                                        placeholder="Purpose Of Visit *"
                                    />}
                                />
                            </Box> : null}
                            <div style={{ fontWeight: 600, marginLeft: "0.5vw", color: Colors.SecondaryText }}>Notes</div>
                            <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                                <TextField
                                    label={("Enter Description")}
                                    style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                                    multiline={true}
                                    rows={4}
                                    variant="outlined"
                                    value={this.state.comments}
                                    onChange={(event) => {
                                        let value = event.target.value
                                        if (value?.length <= 250) {
                                            this.setState({ comments: value })
                                        } else {
                                            this.errorMessage('Allowed only 250 characters')
                                        }
                                    }}
                                />
                            </div>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='contained'
                                    onClick={() => { this.setState({ TransferPopup: false, TranferMethod: "Transfer Appointment", selectedDoc: null, selectedspec: null, comments: "", PurposeOfVisit: "" }) }}
                                >Cancel</Button>
                                <Button
                                    sx={{ height: '2vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => { this.state.TranferMethod == "Transfer Appointment" ? this.PostTransfer() : this.postDoctorAppointmentCreate() }}
                                >{this.state.TranferMethod == "Transfer Appointment" ? 'Transfer' : 'create Appointment'}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div >
        )
    }

    renderSuggestionDropDown = (label, stateKey, Data) => {
        let states = this.state;
        return (
            <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: '15vw' }}
                options={Data}
                getOptionLabel={(item) => (label === "Doctor" ? item.doctor_name : item.name)}
                value={states[stateKey]}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue
                    if (newValue !== null && "doctor_id" in newValue) {
                        let docDetail = { ...newValue }
                        docDetail["name"] = docDetail["specialization_name"];
                        delete docDetail.specialization_name;
                        docDetail["id"] = docDetail["specialization_id"];
                        delete docDetail.specialization_id;
                        states["Specilazation"] = docDetail;
                    }
                    if (newValue !== null && "name" in newValue) {
                        let filteredLst = states["docDetails"].length > 0 && states["docDetails"].filter(item => item["specialization_id"] === newValue["id"])
                        states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                        states["Doctor"] = null;
                    }
                    if (newValue === null && label === "Specialization") {
                        states["filteredDocList"] = states["docDetails"];
                    }
                    this.setState({ states })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label + `${(label === "Doctor" || label === "Specialization") ? " *" : ""}`}
                        placeholder={label}
                    />
                )}
            />
        )
    }

    addendumPopup = () => {
        return (
            <div>
                <Modal open={this.state.addendumPopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "33vw", height: "40vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>Addendum</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ addendumPopup: false, Addcomments: "" })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <div style={{ fontWeight: 600, marginLeft: "0.5vw", color: Colors.SecondaryText }}>Reason</div>
                        <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                            <TextField
                                label={("Enter Description")}
                                style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                                multiline={true}
                                rows={4}
                                variant="outlined"
                                value={this.state.Addcomments}
                                onChange={(event) => {
                                    let value = event.target.value
                                    if (value?.length <= 250) {
                                        this.setState({ Addcomments: value })
                                    } else {
                                        this.errorMessage('Allowed only 250 characters')
                                    }
                                }}
                            />
                        </div>
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                            <Button
                                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize', mr: '1vw' }}
                                variant='contained'
                                onClick={() => { this.setState({ addendumPopup: false, Addcomments: "" }) }}
                            >Cancel</Button>
                            <Button
                                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize' }}
                                variant='contained'
                                onClick={() => {
                                    if (this.state.Addcomments != "") {
                                        this.PostAddendum()
                                    } else {
                                        this.errorMessage('Enter Addendum Comments')
                                    }
                                }}
                            >Addendum</Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    getDoctorPatientAppointment = () => {
        let { history } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + '?clinic_id=' + this.state.clinicId + '&appointment_id=' + this.state.appointmentId)
                .then((response) => {
                    if (response) {
                        this.setState({
                            appointmentDetails: response.data.data,
                            isLocked: response.data.data?.is_locked
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.appointmentDetails), 'DoctorPatientData')
                            // history.push({ pathname: "/DoctorHome" })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    postTransmitSummary = () => {
        try {
            let data = {}
            RestAPIService.create(data, Serviceurls.DOC_TRANSMIT_POST + this.state.appointmentId + "/")
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    lockDoctorNotes = () => {
        try {
            let data = {
                appointment_id: this.state.appointmentId,
                status: 'appointment lock'
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LOCK + `${this.state.appointmentId}/lock/`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getDoctorPatientAppointment()
                        // this.postTransmitSummary()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    confirmSave = () => {
        this.setState({
            confirmpopup: false
        }, () => {
            this.lockDoctorNotes()
        })
    }

    statusChange = () => {
        this.setState({
            statuspopup: false
        }, () => {
            if (this.state?.scan_status) {
                this.postPatientStatus("Scan/Surgery")
            } else {
                this.postPatientStatus("Completed")
            }
        })
    }

    popClose = () => {
        this.setState({
            confirmpopup: false
        })
    }

    statuspopClose = () => {
        this.setState({
            statuspopup: false
        })
    }

    HideRightComponent(Component) {
        switch (Component) {
            case "prescription": return (this.state.PatientData?.is_prescription_transmitted || this.state.PatientData?.is_transmitted)
            case "counselling": return (this.state.PatientData?.is_transmitted)
            case "clinicExam": return true
            default: return false
        }
    }

    render() {
        const { t } = this.props
        let { history } = this.props
        let { PatientData } = this.state
        let consultant = {
            lineOne: t("ConsultantName"),
            lineTwo: `${PatientData?.doctor_name != null ? PatientData?.doctor_name : '-'}`,
        }
        let LockDocNotes;
        if (localGetItem('DoctorNotesLock') === 'false') {
            LockDocNotes = true
        } else {
            LockDocNotes = false
        }
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
                <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header eMed_OtherDocNotes_Header'>
                    <Box display={'flex'} alignItems='center'>
                        <Box className='eMed_Patient_Details_Long eMed_patient_box'>
                            <CommonPatientDetails data={this.state.PatientData} showDetailed={true} fromDoctor={true} />
                        </Box>
                        <ConsultantPatientCard name={consultant} />
                        <Box component={'div'} onClick={() => { history.push({ pathname: "/DoctorHome/Reports/DoctorVisit" }) }} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
                            <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.8vw'} width={'1.8vw'} />
                            <div style={{ fontSize: "0.85vw" }}>Visit Log</div>
                        </Box>
                        {this.state.allergyData?.length > 0 ?
                            <Tooltip placement='top' title={this.state.allergyData?.map(item => item.name).join(",")} arrow>
                                <Box component={'div'} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
                                    <Box component={'img'} src={ImagePaths.AllergyAlert.default} height={'2vw'} width={'2vw'} />
                                    <div style={{ fontSize: "0.7vw" }}>Allergy</div>
                                </Box>
                            </Tooltip> : null}
                    </Box>

                    <Box>
                        <Button
                            emed_tid='transfer_doc_notes'
                            sx={{ textTransform: 'capitalize', mr: '1vw' }}
                            size="small"
                            variant='contained'
                            disabled={this.state.disableButton || this.state.TansmitDisable || this.state.TransferDisable || this.state.isLocked || PatientData?.appointment_status === "Completed"}
                            onClick={() => { this.setState({ TransferPopup: true }, () => { this.getSpecInfo() }) }}
                        >TRANSFER</Button>
                        <Button
                            emed_tid={this.state.isLocked ? "addendum_doc_notes" : "lock_doc_notes"}
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            size="small"
                            disabled={this.state.isLocked ? LockDocNotes : false}
                            onClick={() => { this.state.isLocked ? this.setState({ addendumPopup: true }) : this.setState({ confirmpopup: true }) }}
                        >{this.state.isLocked ? "ADDENDUM" : "LOCK"}</Button>
                        <Button
                            emed_tid='complete_doc_notes'
                            sx={{ textTransform: 'capitalize', ml: '1vw' }}
                            size="small"
                            variant='contained'
                            disabled={this.state.disableButton || PatientData?.appointment_status === "Completed" || this.state.isLocked}
                            onClick={() => { this.setState({ statuspopup: true }) }}
                        >COMPLETE</Button>
                        <Button emed_tid='doc_notes_open_drawer' sx={{ width: '2vw' }} className="eMed_Other_DocNotes_DrawerIcon" onClick={() => { this.setState({ OpenDrawer: true }) }} onMouseEnter={() => { this.setState({ OpenDrawer: true }) }}>
                            <MenuOpenIcon color='primary' />
                        </Button>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
                    <Paper elevation={3} className='eMed_DocNotes_Left_Main' sx={{ height: '68.8vh' }}>
                        {this.state.layoutloading ?
                            <Box>
                                <Skeleton variant="rounded" animation="wave" width={'97%'} height={'25vh'} sx={{ margin: '1vw' }} />
                                <Skeleton variant="rounded" animation="wave" width={'97%'} height={'25vh'} sx={{ margin: '1vw' }} />
                                <Skeleton variant="rounded" animation="wave" width={'97%'} height={'10vh'} sx={{ margin: '1vw' }} />
                            </Box>
                            : this.state.DocNotesLayout.map((item, index) => (
                                item?.is_active ?
                                    <Box ref={this.divRefs[index]} sx={{ padding: '0.5vw' }} >
                                        <Card key={index} onClick={() => { this.setState({ SelectedComponent: item.field_name }) }} elevation={3} sx={{ border: `0.15vw solid ${item.field_name === this.state.SelectedComponent ? Colors.themeDark : Colors.white}`, }} >
                                            <Box sx={{ display: 'flex', flexDirection: item?.field_name === "clinicExam" ? 'row' : 'column', justifyContent: item?.field_name === "clinicExam" && 'space-between' }}>
                                                <Box display={'flex'} alignItems={'center'} marginLeft={"1vw"}>
                                                    {item.field_name === this.state.SelectedComponent ? <DoubleArrowIcon color='error' sx={{ marginX: '0.5vw', fontSize: '1.2vw' }} /> : null}
                                                    <Typography color={item.field_name === this.state.SelectedComponent ? Colors.themeDark : Colors.SecondaryText} padding={"0.3vw"} fontWeight={600} sx={{ cursor: 'default' }}>{item.label}</Typography>
                                                </Box>
                                                {item?.field_name === "clinicExam" ? <Button variant='contained' size="small" id="eMed_Config_btn" sx={{ margin: '0.5vw' }} disabled={this.state.isSaveClicked || this.state.isLocked} onClick={() => { this.setState({ isSaveClicked: true }) }}>Save</Button> : null}
                                            </Box>
                                            {this.renderComponent(item.field_name)}
                                        </Card>
                                    </Box> : null))}

                    </Paper>
                    <Paper elevation={3} className='eMed_DocNotes_Right_Main' sx={{ height: '68.8vh' }}>
                        {(this.state.layoutloading || this.state.isLocked || this.HideRightComponent(this.state.SelectedComponent)) ?
                            <Box>
                                <Skeleton variant="rounded" animation={this.state.isLocked || this.HideRightComponent(this.state.SelectedComponent) ? false : "wave"} width={'92%'} height={'7vh'} sx={{ margin: '1vw' }} />
                                <Skeleton variant="rounded" animation={this.state.isLocked || this.HideRightComponent(this.state.SelectedComponent) ? false : "wave"} width={'92%'} height={'53vh'} sx={{ margin: '1vw' }} />

                            </Box>
                            : this.renderRightComponent(this.state.SelectedComponent)}
                    </Paper>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.OpenDrawer}
                    ModalProps={{ onBackdropClick: () => { this.setState({ OpenDrawer: false }) } }}
                >
                    <Box className="eMed_OtherDocNotes_DrawerDiv" onMouseLeave={() => { this.setState({ OpenDrawer: false }) }}>
                        {
                            this.state.DocNotesLayout.map((item, index) => (
                                item?.is_active ?
                                    <Button
                                        emed_tid='doc_notes_moveto_option'
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => {
                                            this.setState({
                                                SelectedComponent: item.field_name,
                                                OpenDrawer: false
                                            }, () => {
                                                this.divRefs[index]?.current?.scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'start',
                                                    inline: 'center'
                                                });
                                            })

                                        }}>
                                        {item.label}
                                    </Button> : null
                            ))
                        }
                    </Box>
                </Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={"error"}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.TransferAppointPopup()}
                {this.addendumPopup()}
                {this.state.confirmpopup ?
                    <ConfirmPopup
                        cnfTitle={'Confirmation'}
                        cnfContent={'Are you sure, Do you want to Lock Doctor Notes'}
                        cnfAlertPopupClose={this.popClose.bind(this)}
                        cnfData={this.confirmSave.bind(this)}
                    />
                    : null}
                {this.state.statuspopup ?
                    <ConfirmPopup
                        cnfTitle={'Confirmation'}
                        cnfContent={'Are you sure, Do you want to change status'}
                        cnfAlertPopupClose={this.statuspopClose.bind(this)}
                        cnfData={this.statusChange.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(OtherDoctorNotes)

