import React, { Component } from "react";
import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import DescriptionIcon from '@mui/icons-material/Description';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Days, Months, Weeks, Year } from "../../../../Utility/Constants";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";

const Eye_Type = [
    { value: "Right Eye (OD)", label: "Right Eye (OD)" },
    { value: "Left Eye (OS)", label: "Left Eye (OS)" },
    { value: "Both Eye (OU)", label: "Both Eye (OU)" }
]

export default class DocComplaints extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            openPopUp: false,
            loading: false,
            deletePopup: false,
            removeData: false,
            isEdit: false,
            postLoading: false,
            isLocked: false,
            complainsData: [],
            complainDescrp: "",
            selectedDays: '1',
            selectedDuration: '',
            durationDays: Days,
            durationWeeks: Weeks,
            durationMonts: Months,
            durationYears: Year,
            eyeType: null,
            Complaints: null,
            updateId: "",
            tempData: []
        }
    }

    apiCalls = () => {
        this.getComplaintsData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getComplainssuggestion = (key) => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_COMPLAINTS_SUGGESTION_GET + `?search_key=${key}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            tempData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getComplaintsData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_GET + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            complainsData: response.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        if ((this.state.Complaints != null)) {
            return null
        } else {
            if (this.state.Complaints == null) {
                return "Enter Complaints"
            }
        }
    }

    DeleteComplaintsData = () => {
        try {
            let data = {}
            this.setState({ removeData: true })
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_DELETE  + `?id=${this.state.updateId}`,data)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getComplaintsData()
                        this.setState({ deletePopup: false, removeData: false, updateId: "" })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }

    addComplaint = () => {
        let states = this.state
        try {
            let data = {
                "id": states.updateId ? states.updateId : null,
                "appointment_id": states.appointmentId,
                "complaints": states.eyeType == "Right Eye (OD)" ? 1 : states.eyeType == "Left Eye (OS)" ? 2 : states.eyeType == "Both Eye (OU)" ? 3 : null,
                "complaint_description": states.Complaints,
                "duration_type": states.selectedDays ? states.selectedDays : null,
                "duration_time": states.selectedDuration ? states.selectedDuration : null,
                "comments": states.complainDescrp
            }
            let validate = this.checkValidation()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getComplaintsData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    renderChip = (values, key) => {
        let states = this.state
        return (
            <div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "8vw" }}
                                label={item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (item.label === key) {
                                        states.eyeType = ""
                                        this.setState({ states })
                                    } else {
                                        states.eyeType = item?.value
                                        this.setState({ states })
                                    }
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    onClearHandler = () => {
        this.setState({
            complainDescrp: "",
            selectedDays: '1',
            selectedDuration: '',
            eyeType: null,
            Complaints: null,
            tempData: [],
            updateId: ""
        })
    }

    renderPopUpScreen = () => {
        let states = this.state
        try {
            return (
                <Box>
                    <Modal
                        open={this.state.openPopUp}
                    >
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "30vw", height: "41vw" }}>
                            <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add New Complaints</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false, isEdit: false }, () => { this.onClearHandler() })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "1vw", marginTop: "0.5vw" }}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye</Typography>
                                    <Box component={'div'} style={{ marginLeft: "1vw" }}>
                                        {this.renderChip(Eye_Type, this.state.eyeType)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "1vw", marginTop: "0.5vw" }}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Complaints</Typography>
                                    <Box component={'div'} style={{ marginLeft: "1vw", marginTop: "0.5vw" }}>
                                        <Autocomplete
                                            size='small'
                                            sx={{ width: '94%' }}
                                            options={this.state.tempData}
                                            getOptionLabel={(options) => options}
                                            value={this.state?.Complaints}
                                            onChange={(e, newValue) => {
                                                states["Complaints"] = newValue
                                                this.setState({
                                                    states
                                                })
                                            }}
                                            renderInput={(params) => <TextField  {...params}
                                                placeholder="Complaints"
                                                onChange={(e) => {
                                                    if (e.target.value != "") {
                                                        this.setState({
                                                            Complaints: e.target.value
                                                        }, () => { this.getComplainssuggestion(e.target.value) })
                                                    } else {
                                                        this.setState({ tempData: [] })
                                                    }
                                                }}
                                            />}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} height={'24vw'} overflow={'scroll'} pl={'0.5vw'} mt={"0.5vw"}>
                                <Box component={'div'} flex={0.5} pl={'0.5vw'}>
                                    <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                                        <FormControl size='small' style={{ marginTop: "0.5vw", marginRight: "1.2vw" }}>
                                            <InputLabel>Select Duration</InputLabel>
                                            <Select
                                                sx={{ width: '10vw' }}
                                                label='Select Duration'
                                                value={this.state.selectedDays}
                                                onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                                            >
                                                <MenuItem value={'1'}>Days</MenuItem>
                                                <MenuItem value={'2'}>Weeks</MenuItem>
                                                <MenuItem value={'3'}>Months</MenuItem>
                                                <MenuItem value={'4'}>Years</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box component={'div'} mt={'0.5vw'}>
                                        {this.state.selectedDays === '1' ?
                                            this.state.durationDays?.map((item, index) => (
                                                <Chip
                                                    className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                    clickable
                                                />
                                            )) : this.state.selectedDays === '2' ?
                                                this.state.durationWeeks?.map((item, index) => (
                                                    <Chip
                                                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                        clickable
                                                    />
                                                )) : this.state.selectedDays === '3' ?
                                                    this.state.durationMonts?.map((item, index) => (
                                                        <Chip
                                                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                            key={index}
                                                            onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                            clickable
                                                        />
                                                    )) : this.state.durationYears?.map((item, index) => (
                                                        <Chip
                                                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                            label={item.label}
                                                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                            key={index}
                                                            onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                            clickable
                                                        />
                                                    ))
                                        }
                                    </Box>
                                </Box>
                                <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.5vw", marginTop: "0.5vw" }}>
                                    <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Description</Typography>
                                    </Box>
                                    <Box component={'div'} style={{ marginLeft: "0.5vw" }}>
                                        <TextField
                                            multiline
                                            label='Description'
                                            rows={3}
                                            sx={{ width: '26.7vw', marginTop: "0.5vw" }}
                                            value={this.state.complainDescrp}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length < 250) {
                                                    this.setState({ complainDescrp: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed only 250 characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.addComplaint()}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        this.setState({
            updateId: item?.id,
            eyeType: item?.complaints == 1 ? "Right Eye (OD)" : item?.complaints == 2 ? "Left Eye (OS)" : item?.complaints == 3 ? "Both Eye (OU)" : null,
            Complaints: item?.complaint_description ? item?.complaint_description : "",
            selectedDays: item?.duration_type ? (item?.duration_type).toString() : "1",
            selectedDuration: item?.duration_time ? (item?.duration_time).toString() : "",
            complainDescrp: item?.comments ? item?.comments : ""
        })
    }

    onDeleteHandler = (item) => {
        try {
            this.setState({
                deletePopup: true,
                updateId: item?.id
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'}>
                            <Typography fontWeight={600}>Complaints</Typography>
                            {/* <Typography fontSize={'0.8vw'} color={'gray'}>Complaints data added By
                                <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
                            </Typography> */}
                        </Box>
                        <Box component={'div'}>
                            <Button
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: "capitalize" }}
                                onClick={() => { this.setState({ openPopUp: true }) }}
                                disabled={this.state.isLocked || this.props.isLocked}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '20vw', overflow: 'auto' }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Eye</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Complaints</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Duration</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.complainsData?.length > 0 ? this.state.complainsData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                        <Button className='Common_Btn_Min_Width' disabled={this.state.isLocked || this.props.isLocked}
                                                            onClick={() => this.onDeleteHandler(item)}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.complaints == 1 ? "Right" : item?.complaints == 2 ? "Left" : item?.complaints == 3 ? "Both" : "-"}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.complaint_description ?
                                                        item?.complaint_description?.length > 15 ?
                                                            <Tooltip placement='top' title={item?.complaint_description}>
                                                                <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.complaint_description?.slice(0, 15) + '...'}</Typography>
                                                            </Tooltip> :
                                                            <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.complaint_description}</Typography> : '-'
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                        {item?.duration_time ?
                                                            item?.duration_time?.length > 10 ?
                                                                <Tooltip placement='top' title={item?.duration_time}>
                                                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.duration_time?.slice(0, 10) + '...'}</Typography>
                                                                </Tooltip> :
                                                                <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.duration_time + " " + (item?.duration_type == 1 ? "Days" : item?.duration_type == 2 ? "Weeks" : item?.duration_type == 3 ? "Months" : "Years")}</Typography> : '-'
                                                        }
                                                        <Button className='Common_Btn_Min_Width'
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                            onClick={() => { this.setState({ openPopUp: true }, () => { this.onEditHandler(item) }) }}
                                                        >
                                                            <Tooltip placement='top' title={'Edit'}>
                                                                {this.state.isLocked || this.props.isLocked ?
                                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                    :
                                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                                }
                                                            </Tooltip>
                                                        </Button>
                                                        {item?.comments ? <Tooltip placement='top' title={item?.comments}><DescriptionIcon color='primary' /></Tooltip> : null}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell align='center'>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteComplaintsData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}