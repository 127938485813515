import React, { Component } from 'react'
import ShowComponents from './ShowComponent'
import { IOL_Master_Data } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../../Styles/Colors'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'

export default class DocIOLMaster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iolMasterData: IOL_Master_Data,
      patientId: this.props.patientId,
      appointmentId: this.props.appointmentId,
      clinicId: this.props.clinicId,
      doctorID: this.props.doctorID,
      isLocked: this.props.isLocked,
      isEdit: false
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  apiCalls = () => {
    this.getIOLMasterList()
  }

  getIOLMasterList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_NOTES_IOL_MASTER + '?patient_id=' + this.state.patientId + '&appointment_id=' + this.state.appointmentId + '&doctor_id=' + this.state.doctorID)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              iolMasterData: response.data.data?.length > 0 ? response.data.data : JSON.parse(JSON.stringify(IOL_Master_Data)),
            }, () => {
              this.setState({ isEdit: this.state.iolMasterData?.some(item => item?.le_eye !== '' || item?.re_eye !== '') })
            })
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postIOLMasterList = () => {
    try {
      let data = {
        patient_id: this.state.patientId,
        appointment_id: this.state.appointmentId,
        iol_data: this.state.iolMasterData,
        doctor_id: this.state.doctorID
      }
      if (this.state.iolMasterData?.some(item => item.le_eye !== '' || item.re_eye !== '')) {
        RestAPIService.create(data, Serviceurls.DOC_NOTES_IOL_MASTER)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getIOLMasterList()
              this.setState({ isEdit: false })
            }
          }).catch(error => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage('Please,Enter atleast one data')
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names, index) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'le_eye':
          if (CommonValidation.NumberWithPlusMinusSymbolOnly(value) || value === '') {
            this.state.iolMasterData[index]['le_eye'] = value
            this.setState({ iolMasterData: this.state.iolMasterData })
          }
          break;
        case 're_eye':
          if (CommonValidation.NumberWithPlusMinusSymbolOnly(value) || value === '') {
            this.state.iolMasterData[index]['re_eye'] = value
            this.setState({ iolMasterData: this.state.iolMasterData })
          }
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (names, values, index, label, disable) => {
    try {
      return (
        <TextField
          autoComplete='off'
          size='small'
          variant='standard'
          sx={{ width: '10vw' }}
          value={values}
          onChange={(e) => this.onChangeHandler(e, names, index)}
          InputProps={{ emed_tid: label, readOnly: disable }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderIOLMasterTable = () => {
    try {
      let disable = this.state.isEdit ? true : false
      return (
        <Box component={'div'}>
          <Box component={'div'} display={'flex'} justifyContent={'flex-end'} mb={'0.5vw'}>
            {!this.state.isEdit ? <Button variant='contained' emed_tid={'iol_save_btn'}
              disabled={this.state.isLocked}
              onClick={() => this.postIOLMasterList()}
            >Save</Button> :
              <Button className='Common_Btn_Min_Width'
                onClick={() => this.setState({ isEdit: false })}
              ><EditIcon color='primary' /></Button>}
          </Box>
          <Box component={'div'} border={'1px solid lightgray'}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw' }}>Curvature Value</TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw' }}>Right Eye (OD)</TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw' }}>Left Eye (OS)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.iolMasterData?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography fontWeight={600} fontSize={'0.9vw'}>{item?.label ? item?.label : '-'}</Typography>
                      </TableCell>
                      <TableCell>
                        {this.inputTextBox('re_eye', item?.re_eye, index, item?.label, disable)}
                      </TableCell>
                      <TableCell>
                        {this.inputTextBox('le_eye', item?.le_eye, index, item?.label, disable)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: Colors.Background, height: "2.5vw" }}
            >
              <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>IOL Master</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box component={'div'} p={'0.5vw'}>
                <Box component={'div'}>
                  {this.renderIOLMasterTable()}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
        </Box>
      </ShowComponents>
    )
  }
}