import { Box, Button, Divider, Drawer, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import SubLocReportFilter from '../Reports/SubLocReportFilter'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../Styles/Colors'
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid'
import { CommonStockTransferPopup } from '../../../Components/Common Components/SubLocCommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import Loader from '../../../Components/Loader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { setCachevalue } from '../../../Utility/Services/CacheProviderService'


class SubLocInbound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DataList: [],
      filterDatas: {
        'fromDate': '',
        'toDate': '',
        "dateIndex": 0,
        'toLocationName': '',
        'status': ''
      },
      totalBill: 0,
      totalAmount: 0,
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      pageName:'SubLocationInbound',
      isReceiveClicked: false,
      selectedData: {},
      selectedRowId: null,
      selectedRow: null,
      is_cancel: false,
      anchorEl: false
    }
  }

  componentDidMount() {
    this.getInboundList()
  }

  getInboundList = () => {
    try {
      this.LoaderFunction(true)
      var {filterDatas} = this.state
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_TRANSFER + 
        `?transmit_type=Inbound&to_location=${filterDatas.toLocationName ? filterDatas.toLocationName.id: ''}&status=${filterDatas.status ? filterDatas.status : ''}&from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}`).
          then((response) => {
              if (response.data.status === "success") {
                this.LoaderFunction(false)
                var Data = response?.data?.data ? response.data.data : []
                  this.setState({
                    DataList: Data
                  },()=>{
                    this.props.getcountNotification(true)
                  })
              }
          }).catch((error) => {
              if (error?.response?.data?.message) {
                this.LoaderFunction(false)
                  this.errorMessage(error.response.data.message)
              } else {
                this.LoaderFunction(false)
                  this.errorMessage(error.message)
              }
          })
  } catch (error) {
    this.LoaderFunction(false)
      this.errorMessage(error.message)
  }
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
        this.getInboundList()
    })
  }
  

  gridToolBar = () => {
    let TextArray = []
    if (this.state.filterDatas.fromDate !== '' && this.state.filterDatas.toDate !== '') {
      TextArray = [
        { label: "From Date", value: this.state.filterDatas.fromDate },
        { label: "To Date", value: this.state.filterDatas.toDate }
      ]
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  getPrint = (id) => {
    try {
      RestAPIService.getAllPrint(Serviceurls.SUBLOC_LINE_OF_ITEM + `?request_id=${id}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((e) => {
          if (e?.reponse?.data?.message) {
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.errorMessage(e?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }


  MoreMenus = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "ViewCancel", label: t("View / Cancel"), dis: data?.row?.status === 'Initiated' && (CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Inbound", "SubTab")?.editAccess) ? false : true },
      { value: "Print", label: t("Print"), dis: false },
      { value: "Request Reason", label: t("Request Reason"), dis: false },
      { value: "Approve Reason", label: t("Approve Reason"), dis: false },
      { value: "Transfer Reason", label: t("Transfer Reason"), dis: false },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data?.row?.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data?.row?.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => {
              let tooltipTitle = '';
              if (option.label === "Request Reason") {
                tooltipTitle = data?.row?.request_reason || "No reason available";
              } else if (option.label === "Approve Reason") {
                tooltipTitle = data?.row?.approved_reason || "No reason available";
              } else if (option.label === "Transfer Reason") {
                tooltipTitle = data?.row?.transfer_reason || "No reason available";
              }
              return (
                <MenuItem disabled={option.dis} onClick={() => {
                  if (option.value === "ViewCancel") {
                    this.setState({
                      isReceiveClicked: true,
                      selectedData: data?.row,
                      selectedRowId: data?.row?.id,
                      is_cancel: true
                    })
                  } else if (option.value === "Print") {
                    this.getPrint(data?.row?.id)
                  }
                }}>
                  {<Tooltip title={tooltipTitle} placement="left" arrow>
                    <div>{option.label}</div>
                  </Tooltip>}
                </MenuItem>)
            })}
          </Menu>
          : null}
      </div>
    )
  }

  renderTable = () => {
    const { t } = this.props
    let states = this.state;
    const columns = [
      {
        field: "request_date", flex: 0.11, headerName: t("ReqDate"),
        renderCell: (params) => (params?.row?.request_date?.length > 14 ?
          <Tooltip placement="top" title={params?.row?.request_date} arrow><div>{params?.row?.request_date.slice(0, 13) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.request_date ? params?.row?.request_date : "-"}</Box>)
      },
      {
        field: "request_number", flex: 0.11, headerName: t("ReqNum"),
        renderCell: (params) => (params?.row?.request_number?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.request_number} arrow><div>{params?.row?.request_number.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.request_number ? params?.row?.request_number : "-"}</Box>)
      },
      {
        field: "transfer_to", flex: 0.13, headerName: 'Request Form', headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.transfer_to?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.transfer_to} arrow><div>{params?.row?.transfer_to.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.transfer_to ? params?.row?.transfer_to : "-"}</Box>)
      },
      {
        field: "transfer_from", flex: 0.13, headerName: 'Request To', headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.transfer_from?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.transfer_from} arrow><div>{params?.row?.transfer_from.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.transfer_from ? params?.row?.transfer_from : "-"}</Box>)
      },
      {
        field: "transit_name", flex: 0.14, headerName: t("TransitDetail"), headerAlign: "center", align: "center",
        renderCell: (params) => ((params.row.transit_name && params.row.transit_number) ? 
        <Box>
        {params?.row?.transit_name?.length > 15 ? 
        <Tooltip title={params?.row?.transit_name} placement='top'><Typography sx={{fontWeight: 'bold'}}>{params?.row?.transit_name.slice(0, 15) + "..."}</Typography></Tooltip> : params?.row?.transit_name ? params?.row?.transit_name : '-'}
        <Typography sx={{color: Colors.themeDark}}>{params?.row?.transit_number ? params.row.transit_number : '-'}</Typography>
    </Box> : '-')},
      {
        field: "no_of_product", flex: 0.10, headerName: t("NoOfProd"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.no_of_product ? params?.row?.no_of_product : "-"}</Box>)
      },
      // {
      //   field: "stock_value", flex: 0.10, headerName: t("StkValue"), hideable: false, headerAlign: "center", align: "center",
      //   renderCell: (params) => (<Box component={'div'}>{params?.row?.stock_value ? params?.row?.stock_value : "-"}</Box>)
      // },
      {
        field: "status", flex: 0.13, headerName: t("Status"), hideable: false, headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.status?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.status} arrow><div>{params?.row?.status.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.status ? params?.row?.status : "-"}</Box>)
      },
      {
        field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Button emed_tid='subLoc_inbound_reqst' disabled={params?.row?.status === 'In Transit' && (CheckAccessFunc("sub_location", "Stock Transfer", "Inbound",  null, "Tab")?.editAccess) ? false : true} className='eMed_Filter_Btns' variant='contained' size='small' 
          onClick={() => {
            this.setState({
              isReceiveClicked: true,
              selectedData: params?.row,
              selectedRowId: params?.row?.id
            })
           }}
          >{params?.row?.status === 'In Transit'  ? t("ReceiveCAP") : t("Pending") }</Button>
          {this.MoreMenus(params)}
        </Box>
      }
    ]
    return (
      <Box component={'div'}>
        <Paper sx={{ height: '69vh' }}>
          <DataGrid
            rows={states.DataList}
            columns={columns}
            getRowId={(row) => row.id}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Paper>
      </Box>
    )
  }

  popupClose = () => {
    this.setState({
      isReceiveClicked: false,
      selectedData: {},
      selectedRowId: null,
      anchorEl: false,
    },()=>{
      this.getInboundList()
    })
    this.getInboundList()
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  render() {
    const { t } = this.props;
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Button emed_tid='subLoc_inbound_reqst' className='eMed_Filter_Btns' variant='contained' size='small'
          disabled={CheckAccessFunc("sub_location", "Stock Transfer", "Inbound",  null, "Tab")?.editAccess === false} 
            onClick={() => {
              if (this.props.fromOT) {
                this.props.history.push({ pathname: "/OTStockTransfer/RaiseRequest", state: { page: "inbound" } })
              } else {
                this.props.history.push({ pathname: "/SubLocationStockTransfer/RaiseRequest", state: { page: "inbound" } })
              }
            }}
          >{t("DrugRequest")}</Button>
          <Box 
          // borderRight={'2px solid gray'} 
          pr={'1vw'}>
            <Button
              className='eMed_common'
              sx={{ width: '2vw', height: '2vw', mr: '1vw' }}
              onClick={() => this.setState({ filterOpen: true })}
            >
              <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Box>
          {/* need to work start */}
          {/* <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', ml: '1vw', mr: '1vw' }}>
            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
          </Button>
          <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', mr: '1vw' }}>
            <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
          </Button> */}
          {/* need to work end */}
        </Box>
        <Box component={'div'} height={'73vh'} p={'1vw'}>
          {this.renderTable()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isReceiveClicked ? 
        <CommonStockTransferPopup 
        Data = {this.state.selectedData}
        title={"Inbound Details"}
        isCancel={this.state.is_cancel}
        handlePopUpClose={this.popupClose}
        RowId={this.state.selectedRowId}
        />
        : null}

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

export default withTranslation()(SubLocInbound);
