import { Autocomplete, Box, Button, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import CircularProgress from '@mui/material/CircularProgress';

class PurchaseWiseEnquiry extends Component {

    constructor(props) {
        super(props)
        this.state = {
            purcahseWiseData: [],
            page: 0,
            pageSize: 10,
            isLoader: false,
            dateIndex: 1,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            days: 730,
            brand_suggestionList: [],
            brand_name: null,
            vendor_list: [],
        }
    }

    componentDidMount() {
        this.getPurchaseWiseData()
        this.getVendorList()
    }

    getPurchaseWiseData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PURCHASE_WISE_ENQUIRY + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_id=${this.state.vendor_name ? this.state.vendor_name?.vendor_id : ''}&drug_id=${this.state.brand_name ? this.state.brand_name?.drug_ms_id : ''}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            purcahseWiseData: response.data?.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getDrugName = (brandName) => {
        try {
            this.brandLoader(true);
            let brand_name = brandName ? brandName?.replaceAll('&','%26') : ''
            RestAPIService.getAll(`${Serviceurls.PHARMA_BILLING_BRAND_SEARCH}?search=${brand_name}&full_drug_list=true`)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response?.data;
                        this.brandLoader(false);
                        this.setState({
                            brand_suggestionList: response?.data?.data
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.brandLoader(false);
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.brandLoader(false);
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getVendorList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            vendor_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getPurchaseWisePrint = () => {
        try {
            this.setState({ disableBtn: true })
            RestAPIService.getAllPrint(Serviceurls.PURCHASE_WISE_ENQUIRY + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_id=${this.state.vendor_name ? this.state.vendor_name.vendor_id : ''}&drug_id=${this.state.brand_name ? this.state.brand_name.drug_ms_id : ''}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        return (
            <Box display={'flex'} flexDirection={'row'} mt={'.5vw'} mr={'.8vw'} ml={'.8vw'} alignItems={'center'}>
                <Box sx={{ width: "15%" }}>
                    <CommonDatePicker
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        DateIndex={this.state.dateIndex}
                        SelectedDates={this.filterDate.bind(this)}
                        HideAllMenu={true}
                        days={this.state.days}
                        HideDate={true}
                        height={true}
                        showCurrentYear={true}
                        showLastYear={true}
                    />
                </Box>
                <Autocomplete
                    name={"brand_name"}
                    value={this.state.brand_name ? this.state.brand_name.drug_name : null}
                    sx={{ width: "15vw", marginRight: '1vw' }}
                    options={this.state.brand_suggestionList?.length > 0 ? this.state.brand_suggestionList : []}
                    getOptionLabel={(option) => (typeof (option.drug_name) === "string" ? option.drug_name : option)}
                    onChange={(event, newBrand) => {
                        this.setState({
                            brand_name: newBrand ? newBrand : null
                        })
                    }}
                    onInputChange={(event, newBrand) => {
                        if (newBrand?.length > 2) {
                            this.getDrugName(newBrand);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Drug Name")}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                emed_tid: "Drug Name",
                                endAdornment: this.state.isBrandLoader ?
                                    <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                    : null,
                            }}
                        />
                    )}
                />
                <Autocomplete
                    name={"vendor_name"}
                    value={this.state.vendor_name ? this.state.vendor_name : null}
                    sx={{ width: "15vw", marginRight: '1vw' }}
                    options={this.state.vendor_list?.length > 0 ? this.state.vendor_list : []}
                    getOptionLabel={(option) => (typeof (option.name) === "string" ? option.name : option)}
                    onChange={(event, newBrand) => {
                        this.setState({
                            vendor_name: newBrand ? newBrand : null
                        })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Vendor Name")}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                emed_tid: "Vendor Name",
                            }}
                        />
                    )}
                />
                <Box display={'flex'} gap={'1vw'}>
                    <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', width: '10vw' }}
                        onClick={() => {
                            this.setState({
                                dateIndex: 1,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                days: 730,
                                brand_name: null,
                                vendor_name: null,
                            }, () => {
                                this.getPurchaseWiseData()
                            })
                        }}>
                        Clear
                    </Button>
                    <Button size='small' variant='contained' className='eMed_Canteen_BtnText' sx={{height: '2vw', width: '10vw'}} disabled={(this.state.brand_name?.drug_name || this.state.vendor_name?.name) ? false : true} onClick={() => { this.getPurchaseWiseData() }}>Search</Button>
                </Box>
            </Box>
        )
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    filterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }
    brandLoader = (key) => {
        this.setState({
            isBrandLoader: key
        })
    }
    render() {
        const { t } = this.props
        this.state.purcahseWiseData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: "S.No", flex: 0.05 },
            {
                field: 'bill_no', headerName: "Bill Details", flex: 0.083, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    <Box component={'div'}>
                        {params?.row?.bill_no?.length > 7 ?
                            <Tooltip title={params?.row?.bill_no} placement='top'><Typography fontSize={'0.7vw'} fontWeight={'bold'}>{`Bill No: ${params?.row?.bill_no.slice(0, 7) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'} fontWeight={'bold'}>{params?.row?.bill_no ? 'Bill No: ' + params?.row?.bill_no : '-'}</Typography>}
                        {params?.row?.bill_date?.length > 7 ?
                            <Tooltip title={params?.row?.bill_date} placement='top'><Typography fontSize={'0.7vw'}>{`Date: ${params?.row?.bill_date.slice(0, 7) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'}>{params?.row?.bill_date ? 'Date: ' + params?.row?.bill_date : '-'}</Typography>}
                    </Box>
                </Box>)
            },
            {
                field: 'po_no', headerName: "PO Details", flex: 0.083, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    <Box component={'div'}>
                        {params?.row?.po_no?.length > 7 ?
                            <Tooltip title={params?.row?.po_no} placement='top'><Typography fontSize={'0.7vw'} fontWeight={'bold'}>{`PO No: ${params?.row?.po_no.slice(0, 7) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'} fontWeight={'bold'}>{params?.row?.po_no ? 'PO No: ' + params?.row?.po_no : '-'}</Typography>}
                        {params?.row?.po_date?.length > 7 ?
                            <Tooltip title={params?.row?.po_date} placement='top'><Typography fontSize={'0.7vw'} >{`Date: ${params?.row?.po_date.slice(0, 7) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'}>{params?.row?.po_date ? 'Date: ' + params?.row?.po_date : '-'}</Typography>}
                    </Box>
                </Box>)
            },
            {
                field: 'bill_date', headerName: "Bill Date", flex: 0.083, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.bill_date ? params?.row?.bill_date : '-'}
                </Box>)
            },
            {
                field: 'brand_name', headerName: "Drug Details", flex: 0.083, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        <Typography fontSize={'0.8vw'} fontWeight={"bold"}>{params?.row?.brand_name ?
                            params?.row?.brand_name?.length > 12 ?
                                <Tooltip title={params?.row?.brand_name} placement='top'><Typography fontSize={'0.7vw'}>{params?.row?.brand_name.slice(0, 11) + '...'}</Typography></Tooltip>
                                : params?.row?.brand_name : '-'}</Typography>
                        <Typography fontSize={'0.7vw'}>{params?.row?.generic_name ? params?.row?.generic_name : '-'}</Typography>
                        {params?.row?.batch_no?.length > 4 ?
                            <Tooltip title={params?.row?.batch_no} placement='top'><Typography fontSize={'0.7vw'}>{`Batch No: ${params?.row?.batch_no.slice(0, 4) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'}>{params?.row?.batch_no ? 'Batch No: ' + params?.row?.batch_no : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: 'vendor_name', headerName: `Vendor Details`, flex: 0.116, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        <Typography fontSize={'0.8vw'} fontWeight={"bold"}>{params?.row?.vendor_name ? params?.row?.vendor_name : '-'}</Typography>
                        {params?.row?.vendor_gstno?.length > 15 ?
                            <Tooltip title={params?.row?.vendor_gstno} placement='top'><Typography fontSize={'0.7vw'}>{`GST: ${params?.row?.vendor_gstno.slice(0, 15) + '...'}`}</Typography></Tooltip> : <Typography fontSize={'0.7vw'}>{params?.row?.vendor_gstno ? 'GST: ' + params?.row?.vendor_gstno : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: 'expiry_date', headerName: `Exp Date`, flex: 0.083, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box>{params.row?.expiry_date ? params.row?.expiry_date : '0'}</Box>)
            },
            {
                field: 'rate_per_qty_exclude_gst', headerName: (
                    <Box>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'}>Rate/Qty</Typography>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'} marginTop={'-.3vw'} >(excl.GST)</Typography>
                    </Box>
                ), flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.rate_per_qty_exclude_gst ? params.row?.rate_per_qty_exclude_gst : '0'}</Box>)
            },
            {
                field: 'rate_per_qty', headerName: (
                    <Box>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'}>Rate/Qty</Typography>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'} marginTop={'-.3vw'} >(incl.GST)</Typography>
                    </Box>
                ), flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.rate_per_qty ? params.row?.rate_per_qty : '0'}</Box>)
            },
            {
                field: 'stock_in_quantity', headerName: `Pur Qty`, flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.stock_in_quantity ? params.row?.stock_in_quantity : '0'}</Box>)
            },
            {
                field: 'mrp_per_quantity', headerName: `MRP`, flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.mrp_per_quantity ? params.row?.mrp_per_quantity : '0'}</Box>)
            },
            {
                field: 'total_purchase_value', headerName: (
                    <Box>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'}>Pur Value</Typography>
                        <Typography fontSize={'0.8vw'} fontWeight={'bold'} marginTop={'-.3vw'} >(incl.GST)</Typography>
                    </Box>
                ), flex: 0.083, type: 'number',
                renderCell: (params) => (<Box>{params.row?.total_purchase_value ? params.row?.total_purchase_value : '0'}</Box>)
            },
            {
                field: 'free_quantity', headerName: `Free Qty`, flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.free_quantity ? params.row?.free_quantity : '0'}</Box>)
            },
            {
                field: 'discount_percentage', headerName: `${"Discount"}%`, flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.discount_percentage ? params.row?.discount_percentage : '0'}</Box>)
            },
            {
                field: 'gst_percentage', headerName: `${"GST"}%`, flex: 0.063, type: "number",
                renderCell: (params) => (<Box>{params.row?.gst_percentage ? params.row?.gst_percentage : '0'}</Box>)
            }
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background, paddingTop: '1vw' }}>
                <Box component={'div'} backgroundColor={'white'} width={'92vw'} height={'16vh'} marginTop={'1vw'} margin={'auto'} border={'1px solid #E3E2E2'} borderRadius={'.3vw'}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={'.5vw'} mr={'.8vw'} ml={'.8vw'}>
                        <Typography fontSize={'1vw'} fontWeight={'bold'}>Filter</Typography>
                        <Button emed_tid="rpt_consoli_flt" disabled={this.state.purcahseWiseData?.length === 0} className='eMed_Pharma_rts_btn' onClick={() => this.getPurchaseWisePrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2vw'} width={'2vw'} />
                        </Button>
                    </Box>
                    <hr />
                    {this.renderFilterScreen()}
                </Box>
                <Box component={'div'} height={'59vh'} marginTop={'.5vw'}>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.purcahseWiseData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        paginationMode="client"
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PurchaseWiseEnquiry)
