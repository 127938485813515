import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
class DocGenAdviceRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            GenAdviceText: "",
            TemplateName: "",
            TemplateAdvice: "",
            AddTemplate: false,
        }
    }

    componentDidMount() {
        if (this.props.EditGenAdviceText) {
            this.setState({
                GenAdviceText: this.props.EditGenAdviceText,
            })
            this.props.ClearPropsData()
        }
        this.getTemplateListData()
    }

    getTemplateListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERAL_ADVICE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            templateList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.EditGenAdviceText !== this.props.EditGenAdviceText && this.props.EditGenAdviceText) {
            this.setState({
                GenAdviceText: this.props.EditGenAdviceText,
            })
            this.props.ClearPropsData()
        }
    }

    ClearData() {
        this.setState({
            GenAdviceText: "",
            TemplateName: "",
            TemplateAdvice: "",
            AddTemplate: false,
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    PostGenAdvice() {
        try {
            var states = this.state
            // if (CommonValidation.removeSpace(states.GenAdviceText) !== "") {
                if ((states.GenAdviceText) !== "") {
                let data = {
                    advice_given: this.state.GenAdviceText ? this.state.GenAdviceText : "",
                    appointment_id: this.props?.PatientData?.appointment_id,
                    patient_id: this.props?.PatientData?.patient_id
                }

                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.props.CallgetApi('general_advice')
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Enter Provisional Diagnosis")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    postTemplateData = () => {
        try {
            let data = {
                advice: this.state.TemplateAdvice,
                template_name: this.state.TemplateName
            }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_GENERAL_ADVICE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.ClearData()
                        this.getTemplateListData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    deleteFrequencysugg = () => {
        try {
          this.setState({ removeData: true })
          RestAPIService.delete(`${Serviceurls.DOC_DOCTOR_NOTES_GENERAL_ADVICE}?id=${this.state.SelectedDeleteChip?.id}`)
            .then((response) => {
              if (response?.data?.status === 'success') {
                this.successMessage(response?.data?.message)
                this.getTemplateListData()
                this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false })
              }
            }).catch((error) => {
              if (error?.response?.data?.message) {
                this.setState({ removeData: false })
                this.errorMessage(error.response.data.message)
              } else {
                this.setState({ removeData: false })
                this.errorMessage(error.message)
              }
            })
        } catch (error) {
          this.setState({ removeData: false })
          this.errorMessage(error.message)
        }
      }

      popupClose = () => {
        try {
          this.setState({
            deletePopup: false
          })
        } catch (error) {
          this.errorMessage(error.message)
        }
      }

    render() {
        const { t } = this.props
        return (
            <Box>
                <Box height={"61.3vh"}>
                    <Typography className='eMed_refdoc_title'>{t("General Patient Advice")}</Typography>
                    <Box>
                        <Button sx={{ marginLeft: '1vw', width: '15vw', textTransform: 'capitalize' }} onClick={() => {
                            this.setState({ AddTemplate: !this.state.AddTemplate }, () => {
                                if (!this.state.AddTemplate) {
                                    this.setState({
                                        TemplateName: "",
                                        TemplateAdvice: ""
                                    })
                                }
                            })
                        }}>{this.state.AddTemplate ? "Select from Template" : "+ Add Template"}</Button>
                    </Box>
                    <Box component={'div'}>
                        {this.state.AddTemplate ?
                            <Box sx={{ display: 'flex', height: "30vh", flexDirection: 'column', justifyContent: 'space-between' }}>
                                <TextField
                                    size='small'
                                    className='eMed_DocNotes_TextFields'
                                    label={t("Template Name")}
                                    value={this.state.TemplateName}
                                    onChange={(e) => {
                                        if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                            this.setState({ TemplateName: e.target.value })
                                        }
                                    }}
                                />
                                <TextField
                                    size='small'
                                    className='eMed_DocNotes_TextFields'
                                    multiline={true}
                                    rows={4}
                                    label={t("Template Advice")}
                                    inputProps={{ maxLength: 200 }}
                                    value={this.state.TemplateAdvice}
                                    onChange={(e) => {
                                        // if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                            this.setState({ TemplateAdvice: e.target.value })
                                        // }
                                    }}
                                />
                                <Button sx={{ width: "15vw", textTransform: 'capitalize', marginLeft: '8vw' }} variant='contained' onClick={() => {
                                    if (this.state.templateList?.some((item) => (item?.template_name?.toLowerCase().trim() === this.state.TemplateName?.toLowerCase().trim()))) {
                                        this.errorMessage("Template Name Already Exists")
                                    } else {
                                        this.postTemplateData()
                                    }
                                }}
                                        disabled={this.state.TemplateName === "" || this.state.TemplateAdvice === ""}>Save Template</Button>
                            </Box>
                            :
                            <Box marginLeft={'1vw'}>
                                {
                                    this.state.templateList?.length > 0 && this.state.templateList?.map((item, index) => (
                                        <Chip
                                            className={`eMed_chip ${item?.advice === this.state.GenAdviceText ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                            size='small'
                                            label={item.template_name}
                                            variant={item.advice === this.state.GenAdviceText ? 'contained' : 'outlined'}
                                            key={index}
                                            onClick={() => {
                                                if (item.advice === this.state.selectedTemplate) {
                                                    this.setState({ selectedTemplate: '' })
                                                } else {
                                                    this.setState({ selectedTemplate: item?.advice, GenAdviceText: item?.advice }, () => {
                                                        if (this.state.addTemplate) {
                                                            this.setState({
                                                                addTemplate: false,
                                                                templateName: '',
                                                                comments: '',
                                                                postLoad: false,
                                                            })
                                                        }
                                                    })
                                                }
                                            }}
                                            onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                            clickable
                                        />
                                    ))
                                }
                            </Box>}
                    </Box>
                    <TextField
                        size='small'
                        className='eMed_DocNotes_TextFields'
                        multiline={true}
                        rows={4}
                        label={t("Advice (Max 200 Characters)")}
                        inputProps={{ maxLength: 200 }}
                        value={this.state.GenAdviceText}
                        onChange={(e) => {
                            // if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                                this.setState({ GenAdviceText: e.target.value })
                            // }
                        }}
                    />
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" disabled={this.state.GenAdviceText === ""} id="eMed_Config_btn" onClick={() => { this.PostGenAdvice() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip?.template_name} from suggestion ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteFrequencysugg.bind(this)}
                    disable={this.state.removeData}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(DocGenAdviceRight)
