import React, { Component } from "react";
import '../../Configurations/Configuration.css';
import { ClickableCard, NoAccessScreen } from "../../../Components/Common Components/CommonComponents";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import { Badge, Box, Typography } from "@mui/material";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { getCachevalue, localGetItem } from "../../../Utility/Services/CacheProviderService";

let NewServiceConfigList = [
    {
        id: 0, main_title: 'Stock Transfer',
        sub_tab: [
            { id: 0, sub_title: 'Sub Location Transfer', value: 'SubLocationTransfer', icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", null, "Tab"), path: "/PharmacyStockTransfer/Inbound" },
            { id: 1, sub_title: "Inter Branch Stock Movement", value: "InterBranchStockMovement", icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", null, "Tab"), path: "/PharmacyStockTransfer/PharmaBranchWanted" },
        ]
    },
]
class StockHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConfigScreen: "",
            isSelected: false,
            selectedIndex: null,
        }
    }

    RoleWiseAccess(item, ListName) {
        try {
            var UserDetails = JSON.parse(localGetItem("loggedInUserInfo"))
            if (item.value === "UserAccess" && ListName === "UserConfigList") {
                return UserDetails?.is_user ? UserDetails?.is_user : true
            }
            else {
                return true
            }
        }
        catch (e) {
            return true
        }
    }

    newUserConfigNavigation(id, item) {
        this.setState({
            isSelected: true,
            selectedIndex: id ?? null,
            selectedConfigScreen: item?.value
        }, () => {
            let { history } = this.props
            if (this.state.selectedIndex === item?.id) {
                history.push({ pathname: item?.path })
            }
        })
    }

    render() {
        let { isSelected, selectedIndex } = this.state;
        let subCount = getCachevalue('subTotal')
        let branchCount = getCachevalue('branchTotal')
        return (
            <Box component='div' className="eMed_Config_Wrapper" style={{ backgroundColor: Colors.configHomeBg }}>
                {NewServiceConfigList?.map((item, index) => {
                    const view_access = item.sub_tab?.some(item => CheckAccessFunc("Pharmacy", "Stock Transfer", item.sub_title, null, "Tab")?.viewAccess);
                    return (
                        view_access ? 
                        <Box component={'div'} className="eMed_Config_Div" key={index}>
                            <Box component={'div'} display={'inline'}>
                                <Typography id="eMed_Config_header">{item?.main_title}</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                {item?.sub_tab?.map((sub, subIndex) => {
                                    if (CheckAccessFunc("Pharmacy", "Stock Transfer", sub.sub_title, null, "Tab")?.viewAccess) {
                                        return (
                                            <Box component={'div'} key={subIndex} id="eMed_Config_mainDiv">
                                                <Badge color="error" badgeContent={sub.value === 'SubLocationTransfer' ? subCount : sub.value === 'InterBranchStockMovement' ? branchCount : 0 } showZero={false}>
                                                    <ClickableCard label={sub.sub_title} icon={sub.icon} width={"4vw"} height={"4vw"} isSelected={isSelected && selectedIndex === subIndex ? true : false} onClick={() => this.newUserConfigNavigation(sub.id, sub)} />
                                                </Badge>
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>
                        </Box> : <NoAccessScreen/>
                    )
                })}
            </Box>
        )
    }
}
export default withTranslation()(StockHome);
