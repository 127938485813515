import { Box, Button, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class SalesReturn extends Component {

    constructor(props) {
        super(props)
        this.state = {
            salesReturnData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": 30,
                "patientName": "",
                "billNumber": "",
                "brandName": "",
                "OPSelected": true,
                "IPSelected": true,
                "SelfSelected": true,
            },
            pageName: 'salesReturn',
            filterOpen: false,
            totalReturnAmount: 0,
            totalRoundoff: 0,
            totalReturnGst: 0,
            roundoff: 0,
            cgst: 0,
            sgst: 0,
            discountAmount: 0,
            taxableAmount: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getSalesReturnReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getSalesReturnReportData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    getSalesReturnReportData = () => {
        try {
            this.LoaderFunction(true);
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.getAll(Serviceurls.PHARMA_SALES_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&patient_name=${this.state.filterDatas.patientName}&bill_number=${this.state.filterDatas.billNumber}&brand_name=${this.state.filterDatas.brandName}&module_type=${patientType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            salesReturnData: response.data.data,
                            // rowCount: response.data?.total_count ? response.data?.total_count : 0,
                            totalReturnAmount: response.data?.total_amount ? response.data?.total_amount : 0,
                            totalRoundoff: response.data?.total_amount ? response.data?.total_round_off : 0,
                            totalReturnGst: response.data?.total_gst_amount ? response.data?.total_gst_amount : 0,
                            roundoff: response.data?.total_round_off ? response.data?.total_round_off : 0,
                            cgst: response.data?.total_cgst_amount ? response.data?.total_cgst_amount : 0,
                            sgst: response.data?.total_sgst_amount ? response.data?.total_sgst_amount : 0,
                            discountAmount: response.data?.total_discount_amount ? response.data?.total_discount_amount : 0,
                            taxableAmount: response.data?.total_taxable_amount ? response.data?.total_taxable_amount : 0,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSalesReturnExcel = () => {
        try {
            this.setState({ disableBtn: true })
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.excelGet(Serviceurls.PHARMA_SALES_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&patient_name=${this.state.filterDatas.patientName}&bill_number=${this.state.filterDatas.billNumber}&brand_name=${this.state.filterDatas.brandName}&module_type=${patientType}&export_type=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({ disableBtn: false })
        }
    }

    getSalesRtnPrint = () => {
        try {
            this.setState({ disableBtn: true })
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.getAll(Serviceurls.PHARMA_SALES_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&patient_name=${this.state.filterDatas.patientName}&bill_number=${this.state.filterDatas.billNumber}&brand_name=${this.state.filterDatas.brandName}&module_type=${patientType}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    render() {
        const { t } = this.props
        this.state.salesReturnData.forEach((element, index) => element.sno = index + 1)
        // const columns = [
        //     { field: "sno", sortable: false, flex: 0.0226, headerName: t("SNo") },
        //     {
        //         field: 'invoice_number', headerName: "Bill No", flex: 0.0826,
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.invoice_number?.length > 7 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{'..' + params?.row?.invoice_number?.slice(10)}</div></Tooltip> :
        //                 params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
        //         </Box>)
        //     },
        //     {
        //         field: 'invoice_date', headerName: "Return Date", flex: 0.0626, headerAlign: "center", align: "center",
        //         renderCell: (params) => {
        //             let invoice_date = params?.row?.invoice_date ? splitDate(params?.row?.invoice_date) : "-";
        //             return (<Box component={'div'}>
        //                 {invoice_date?.length > 10 ? <Tooltip placement='top' title={invoice_date} arrow><div>{invoice_date?.slice(0, 10) + '...'}</div></Tooltip> :
        //                     params?.row?.invoice_date ? invoice_date : '-'}
        //             </Box>)
        //         }
        //     },
        //     {
        //         field: 'patient_name', headerName: "Patient Name", flex: 0.0726, align: "center", headerAlign: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.patient_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.patient_name} arrow><div>{params?.row?.patient_name?.slice(0, 12) + '...'}</div></Tooltip> :
        //                 params?.row?.patient_name ? params?.row?.patient_name : '-'}
        //         </Box>)
        //     },
        //     {
        //         field: 'batch_no', headerName: "Batch No", flex: 0.0526, align: "center", headerAlign: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.batch_no?.length > 8 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 8) + '...'}</div></Tooltip> :
        //                 params?.row?.batch_no ? params?.row?.batch_no : '-'}
        //         </Box>)
        //     },
        //     {
        //         field: 'brand_name', headerName: "Drug Details", flex: 0.12,
        //         renderCell: (params) => {
        //             var drug_detail = params?.row?.brand_name + " | " + params?.row?.generic_name + " | " + params?.row?.dosage_type + " | " + params?.row?.dosage_strength
        //         return(
        //         <Box component={'div'}>
        //             {drug_detail.length > 20 ? <Tooltip placement='top' title={drug_detail} arrow><div>{drug_detail.slice(0,20) + '...'}</div></Tooltip> : drug_detail ? drug_detail : '-'}
        //         </Box>
        //         )}
        //     },
        //     { field: 'quantity', headerName: "Quantity", flex: 0.0526, type: 'number' },
        //     { field: 'returned_quantity', headerName: "Return Quantity", flex: 0.0526, type: 'number' },
        //     {
        //         field: 'payment_mode', headerName: "Payment Type", flex: 0.0626, type: 'number',
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.payment_mode ? params?.row?.payment_mode : '-'}
        //         </Box>)
        //     },
        //     { field: 'net_amount', headerName: `${"Return Amt"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     { field: 'round_off_amt', headerName: `${"Round off"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     { field: 'taxable_amount', headerName: `${"Taxable Amt"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     {
        //         field: 'state', headerName: `${"State"}`, flex: 0.0526,
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.state ? params?.row?.state?.length > 3 ?
        //                 <Tooltip placement='top' title={params?.row?.state}><div>{params?.row?.state?.slice(0, 3) + '...'}</div></Tooltip> :
        //                  params?.row?.state : '-'}
        //         </Box>)
        //     },
        //     { field: 'gst_amount', headerName: `${"GST Amt"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     { field: 'sgst_amount', headerName: `${"SGST"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     { field: 'cgst_amount', headerName: `${"CGST"} (${CurrencySymbol})`, flex: 0.0526, type: 'number' },
        //     { field: 'igst', headerName: `${"IGST"} (${CurrencySymbol})`, flex: 0.0526 ,type:'number'},
        // ]
        const columns = [
            { field: 'sno', headerName: 'S.No', flex: 0.05 },
            {
                field: 'invoice_number', headerName: 'Bill Number', flex: 0.071,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 10 ?
                        <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                            <Typography fontSize={'0.9vw'}>{params?.row?.invoice_number?.slice(0, 10) + '...'}</Typography>
                        </Tooltip> :
                        params?.row?.invoice_number ? <Typography fontSize={'0.9vw'}>{params?.row?.invoice_number}</Typography> : '-'}
                </Box>)
            },
            {
                field: 'invoice_date', headerName: "Return Date", flex: 0.071, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let invoice_date = params?.row?.invoice_date ? splitDate(params?.row?.invoice_date) : "-";
                    return (<Box component={'div'}>
                        {invoice_date?.length > 10 ? <Tooltip placement='top' title={invoice_date} arrow><div style={{ fontSize: "0.9vw" }}>{invoice_date?.slice(0, 10) + '...'}</div></Tooltip> :
                            params?.row?.invoice_date ? <Typography fontSize={'0.9vw'}>{invoice_date}</Typography> : '-'}
                    </Box>)
                }
            },
            {
                field: 'brand_name', headerName: 'Drug Details', flex: 0.071,
                renderCell: (params) => {
                    let drugDetails = (params?.row?.brand_name ? params?.row?.brand_name : '-') + '|' + (params?.row?.generic_name ? params?.row?.generic_name : '-')
                    let dosageDetails = (params?.row?.dosage_type ? params?.row?.dosage_type : '-') + "-" + (params?.row?.dosage_strength ? params?.row?.dosage_strength : '-')
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {drugDetails ? drugDetails?.length > 10 ?
                                    <Tooltip placement='top' title={drugDetails}><Typography fontSize={'0.9vw'} fontWeight={600}>{drugDetails?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{drugDetails}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {dosageDetails ? dosageDetails?.length > 15 ?
                                    <Tooltip placement='top' title={dosageDetails}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageDetails?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageDetails}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'batch_no', headerName: "Batch No", flex: 0.071, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let batch_no = params?.row?.batch_no ? params?.row?.batch_no : "-";
                    return (<Box component={'div'}>
                        {batch_no?.length > 10 ? <Tooltip placement='top' title={batch_no} arrow><div style={{ fontSize: '0.9vw' }}>{batch_no?.slice(0, 10) + '...'}</div></Tooltip> :
                            params?.row?.batch_no ? <Typography>{batch_no}</Typography> : '-'}
                    </Box>)
                }
            },
            {
                field: 'patient_name', headerName: 'Patient Details', flex: 0.071,
                renderCell: (params) => {
                    let accNumAndMob = (params?.row?.uhid ? params?.row?.uhid : "-") + "|" + (params.row?.mobile_no ? params.row?.mobile_no : "-")
                    return (
                        <Box component={'div'}>
                            {params?.row?.patient_name ? params?.row?.patient_name?.length > 10 ?
                                <Tooltip placement='top' title={params?.row?.patient_name}><Typography fontSize={'0.9vw'}>{params?.row?.patient_name?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{params?.row?.patient_name}</Typography> : '-'}
                            {params?.row?.uhid ? accNumAndMob?.length > 10 ?
                                <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.uhid?.slice(0, 10) + "..."}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.uhid}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'module_type', headerName: "Patient Type", flex: 0.071,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.module_type ? params?.row?.module_type : '-'}</Box>)
            },
            {
                field: 'returned_quantity', headerName: 'Return Details', flex: 0.071,
                renderCell: (params) => {
                    let buyQty = "Buying Qty :" + (params?.row?.quantity ? params?.row?.quantity : 0)
                    let rtnQty = "Return Qty :" + (params?.row?.returned_quantity ? params?.row?.returned_quantity : 0)
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {buyQty ? buyQty?.length > 15 ?
                                    <Tooltip placement='top' title={buyQty}><Typography fontSize={'0.9vw'} fontWeight={600}>{buyQty?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{buyQty}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {rtnQty ? rtnQty?.length > 15 ?
                                    <Tooltip placement='top' title={rtnQty}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{rtnQty?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{rtnQty}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'net_amount', headerName: 'Return Amount', flex: 0.071,
                renderCell: (params) => {
                    let rtenQty = (params?.row?.net_amount ? params?.row?.net_amount : 0)
                    let roundOff = "Round Off:" + (params?.row?.round_off_amt ? params?.row?.round_off_amt : '0')
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {rtenQty ? rtenQty?.length > 15 ?
                                    <Tooltip placement='top' title={rtenQty}><Typography fontSize={'0.9vw'} fontWeight={600}>{rtenQty?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{rtenQty}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {roundOff ? roundOff?.length > 15 ?
                                    <Tooltip placement='top' title={roundOff}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{roundOff?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{roundOff}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'taxable_amount', headerName: 'Taxable Amt', flex: 0.071,
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={'0.9vw'}>{params?.row?.taxable_amount ? params?.row?.taxable_amount : 0}</Typography>
                </Box>)
            },
            {
                field: 'gst_amount', headerName: 'GST', flex: 0.071,
                renderCell: (params) => {
                    let gstPer = "GST % :" + (params?.row?.gst_percentage ? params?.row?.gst_percentage : '0')
                    let gstAmt = "GST Amt :" + (params?.row?.gst_amount ? params?.row?.gst_amount : '0')
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {gstPer ? gstPer?.length > 15 ?
                                    <Tooltip placement='top' title={gstPer}><Typography fontSize={'0.9vw'} fontWeight={600}>{gstPer?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{gstPer}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {gstAmt ? gstAmt?.length > 15 ?
                                    <Tooltip placement='top' title={gstAmt}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{gstAmt?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{gstAmt}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'sgst_percentage', headerName: 'SGST', flex: 0.071,
                renderCell: (params) => {
                    let sgstPer = "SGST % :" + (params?.row?.sgst_percentage ? params?.row?.sgst_percentage : '0')
                    let sgstAmt = "SGST Amt :" + (params?.row?.sgst_amount ? params?.row?.sgst_amount : '0')
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {sgstPer ? sgstPer?.length > 15 ?
                                    <Tooltip placement='top' title={sgstPer}><Typography fontSize={'0.9vw'} fontWeight={600}>{sgstPer?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{sgstPer}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {sgstAmt ? sgstAmt?.length > 15 ?
                                    <Tooltip placement='top' title={sgstAmt}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{sgstAmt?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{sgstAmt}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'cgst_percentage', headerName: 'CGST', flex: 0.071,
                renderCell: (params) => {
                    let cgstPer = "CGST % :" + (params?.row?.cgst_percentage ? params?.row?.cgst_percentage : '0')
                    let cgstAmt = "CGST Amt :" + (params?.row?.cgst_amount ? params?.row?.cgst_amount : '0')
                    return (
                        <Box component={'div'}>
                            <Box component={'div'} display={'flex'}>
                                {cgstPer ? cgstPer?.length > 15 ?
                                    <Tooltip placement='top' title={cgstPer}><Typography fontSize={'0.9vw'} fontWeight={600}>{cgstPer?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{cgstPer}</Typography> : '-'}
                            </Box>
                            <Box component={'div'} display={'flex'}>
                                {cgstAmt ? cgstAmt?.length > 15 ?
                                    <Tooltip placement='top' title={cgstAmt}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{cgstAmt?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{cgstAmt}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: 'payment_mode', headerName: 'Payment Type', flex: 0.071,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.payment_mode ? params?.row?.payment_mode?.length > 10 ?
                        <Tooltip placement='top' title={params?.row?.payment_mode}>
                            <Typography fontSize={'0.9vw'}>{params?.row?.payment_mode?.slice(0, 10) + '...'}</Typography>
                        </Tooltip> :
                        <Typography fontSize={'0.9vw'}>{params?.row?.payment_mode}</Typography> : '-'}
                </Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Taxable Amount", this.state.taxableAmount, false, "white", false, null, 'rpt_saleDlt_tax_amt')}
                        {AmountsCard("CGST", this.state.cgst, false, "white", false, null, 'rpt_saleDlt_cgst')}
                        {AmountsCard("SGST", this.state.sgst, false, "white", false, null, 'rpt_saleDlt_sgst')}
                        {AmountsCard("Returned GST Amount", this.state.totalReturnGst, false, "white", false, null, 'rpt_saleRtn_rtn_gst_amt')}
                        {AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, null, 'rpt_saleDlt_dis_amt')}
                        {AmountsCard("Net Amount", this.state.totalReturnAmount, false, "white", false, null, 'rpt_saleRtn_trn_amt')}
                        {AmountsCard("Round Off", this.state.totalRoundoff, false, "white", false, null, 'rpt_saleRtn_rnd_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_saleRtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleRtn_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getSalesRtnPrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleRtn_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getSalesReturnExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.salesReturnData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    state: false,
                                    igst: false
                                }
                            }
                        }}
                        // rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDoc={true}/>
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(SalesReturn)