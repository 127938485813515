import React, { Component } from 'react'
import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TwentyDays } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';

export default class DocReviewPlanRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentId: this.props.appointmentId,
      loading: false,
      postLoading: false,
      selectedDays: '1',
      selectedDuration: '',
      durationDays: TwentyDays,
      durationWeeks: TwentyDays,
      durationMonts: TwentyDays,
      durationYears: TwentyDays,
      updateId: "",
      AppointmentTypeList: [],
      selectedAppointTypeId: null,
      isDisabled: false,
      appointmentDateTime: new Date().setDate(new Date().getDate() + 1),
    }
  }

  componentDidMount = () => {
    this.getAppointTypeList()
    if (this.props.EditReviewPlanData && Object.keys(this.props?.EditReviewPlanData).length !== 0) {
      let data = this.props.EditReviewPlanData
      this.setState({
        updateId: data?.id,
        selectedDays: this.renderDurationType((data?.duration_type)),
        selectedDuration: data?.duration?.toString(),
        comments: data?.comments,
        appointmentDateTime: new Date(`${data?.review_date} ${data?.appointment_time}`),
      },() => {
        this.props.ClearPropsData()
      })
    }
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps?.EditReviewPlanData !== this.props.EditReviewPlanData && this.props.EditReviewPlanData && Object.keys(this.props.EditReviewPlanData).length !== 0) {
      let data = this.props.EditReviewPlanData
      this.setState({
        updateId: data?.id,
        selectedDays: this.renderDurationType((data?.duration_type)),
        selectedDuration: data?.duration?.toString(),
        comments: data?.comments,
        appointmentDateTime: new Date(`${data?.review_date} ${data?.appointment_time}`),
      },() => {
        this.props.ClearPropsData()
      })
    }
  }

  getAppointTypeList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              AppointmentTypeList: response.data.data?.filter((item) => (item.is_active === true)),
            }, () => {
              this.setState({
                selectedAppointTypeId: response.data.data?.find((item) => (item.appoinment_type === "Walk in Appointment"))
              })
            });
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  checkValidation = () => {
    if (this.state.Complaints != "" && this.state.eyeType != null) {
      return null
    } else {
      if (this.state.Complaints == "") {
        return "Enter Complaints"
      } else {
        return "Select Eye Type "
      }
    }
  }

  renderDurationType = (type) => {
    switch (type) {
      case "1": return "Days"
      case "2": return "Weeks"
      case "3": return "Months"
      case "4": return "Years"
      case "Days": return "1"
      case "Weeks": return "2"
      case "Months": return "3"
      case "Years": return "4"
      default: return type
    }
  }

  CreateReviewPlan = () => {
    let states = this.state
    var AptDateTime = new Date(states.appointmentDateTime)
    var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')
    var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    try {
      let data = {
        "appointment_id": states.appointmentId ? states.appointmentId : '',
        "duration_type": this.renderDurationType(states.selectedDays),
        "duration": states.selectedDuration ? +states.selectedDuration : null,
        "review_date": AptDate,
        "appointment_time": Aptime,
        "patient_appointment_type_id": states.selectedAppointTypeId?.id ? states.selectedAppointTypeId?.id : null,
        "comments": states.comments ? states.comments : '',
      };
      if (states.updateId) {
        data["id"] = states.updateId
      }
      this.setState({ postLoading: true })
      RestAPIService.create(data, Serviceurls.DOC_OPT_REVIEW_PALN)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.onClearHandler()
            this.props.callGet("review_plan")
            this.setState({ postLoading: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  renderChip = (values, key) => {
    let states = this.state
    return (
      <div>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
          {
            values && values.map((item, index) => (
              <Chip
                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                style={{ width: "8vw" }}
                label={item?.label}
                variant={item.label === key ? 'contained' : 'outlined'}
                key={index}
                onClick={() => {
                  if (item.label === key) {
                    states.eyeType = ""
                    this.setState({ states })
                  } else {
                    states.eyeType = item?.value
                    this.setState({ states })
                  }
                }}
                clickable
              />
            ))
          }
        </div>
      </div>
    )
  }

  onClearHandler = () => {
    this.setState({
      comments: "",
      selectedDays: '1',
      selectedDuration: '',
      isDisabled: false,
      updateId: "",
      appointmentDateTime: new Date().setDate(new Date().getDate() + 1),
    })
  }

  handleChipClick = (Number, Duration) => {
    const newDate = this.calculateNewDate(+Number, Duration);
    this.setState({ appointmentDateTime: newDate });
  };

  calculateNewDate = (number, duration) => {
    const NewDate = new Date();

    switch (duration) {
      case 'days':
        NewDate.setDate(NewDate.getDate() + number);
        break;
      case 'weeks':
        NewDate.setDate(NewDate.getDate() + 7 * number);
        break;
      case 'months':
        NewDate.setMonth(NewDate.getMonth() + number);
        break;
      case 'years':
        NewDate.setFullYear(NewDate.getFullYear() + number);
        break;
      default:
        break;
    }

    return NewDate;
  };


  renderDateTimeFilter = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    try {
      return (
        <Box sx={{ mt: '1vw' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              open={this.state.DateOpen}
              onOpen={() => { this.setState({ DateOpen: true }) }}
              onClose={() => { this.setState({ DateOpen: false }) }}
              inputFormat='dd-MM-yyyy & hh:mm a'
              value={this.state.appointmentDateTime}
              minDate={currentDate}
              onChange={(newDate) => {
                if (newDate === null) {
                  this.setState({ 
                    appointmentDateTime: currentDate,
                    selectedDuration: ''
                  })
                }
                else {
                  this.setState({ 
                    appointmentDateTime: newDate,
                    selectedDuration: ''
                  })
                }
              }}
              renderInput={(params) => <TextField size='small'
                onKeyDown={(e) => e.preventDefault()}
                onClick={() => { this.setState({ DateOpen: true }) }}
                {...params}
                fullWidth
                variant='outlined'
                label="Appointment Date & Time *"
                style={{ width: "14vw", marginRight: "0.5vw", marginLeft: "0.5vw" }}
              />}
            />
          </LocalizationProvider>
        </Box>
      )
    } catch (e) {

    }
  }


  render() {
    let states = this.state
    return (
      <Box>
        <Box component={'div'} >
          <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Review Plan</Typography>
          </Box>
          <Box component={'div'} height={"56.5vh"} overflow={'auto'} style={{ padding: "1.25vw" }}>
            <Box component={'div'} flex={0.5} pl={'0.5vw'}>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                <FormControl size='small' style={{ marginTop: "0.2vw", marginRight: "1.2vw" }}>
                  <InputLabel>Select Duration</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    label='Select Duration'
                    value={this.state.selectedDays}
                    disabled={this.state.isDisabled}
                    onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                    inputProps={{ emed_tid: "reviewPlan_selectDuration_testId" }}
                  >
                    <MenuItem value={'1'}>Days</MenuItem>
                    <MenuItem value={'2'}>Weeks</MenuItem>
                    <MenuItem value={'3'}>Months</MenuItem>
                    <MenuItem value={'4'}>Years</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box component={'div'} mt={'0.5vw'}>
                {this.state.selectedDays === '1' ?
                  this.state.durationDays?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.label}
                      variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                      key={index}
                      disabled={this.state.isDisabled}
                      onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "days") }) }}
                      clickable
                    />
                  )) : this.state.selectedDays === '2' ?
                    this.state.durationWeeks?.map((item, index) => (
                      <Chip
                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                        label={item.label}
                        disabled={this.state.isDisabled}
                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                        key={index}
                        onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "weeks") }) }}
                        clickable
                      />
                    )) : this.state.selectedDays === '3' ?
                      this.state.durationMonts?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          disabled={this.state.isDisabled}
                          onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "months") }) }}
                          clickable
                        />
                      )) : this.state.durationYears?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          disabled={this.state.isDisabled}
                          onClick={() => { this.setState({ selectedDuration: item?.value }, () => { this.handleChipClick(item?.value, "years") }) }}
                          clickable
                        />
                      ))
                }
              </Box>
            </Box>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              {this.renderDateTimeFilter()}
              <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: '13vw', marginTop: "1vw" }}
                options={this.state.AppointmentTypeList}
                getOptionLabel={(item) => (item.appoinment_type)}
                value={this.state.selectedAppointTypeId}
                disabled
                onChange={(event, newValue) => {
                  states["selectedAppointTypeId"] = newValue
                  this.setState({ states })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Appointment Type"}
                    placeholder={"Appointment Type"}
                    inputProps={{...params.inputProps, 'emed_tid': 'reviewPlan_appType_testId' }}
                  />
                )}
              />
            </div>
            <Box component={'div'} p={'0.5vw'}>
              <div style={{ fontWeight: 600 }}>Comments</div>
              <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                <TextField
                  style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                  multiline={true}
                  rows={4}
                  variant="outlined"
                  value={this.state.comments}
                  inputProps={{ maxLength: 300, emed_tid: 'reviewPlan_comments_testId' }}
                  onChange={(event) => {
                    this.setState({ comments: event.target.value })
                  }}
                />
              </div>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'2vw'}>
              <Button
                sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => this.onClearHandler()}
                emed_tid='reviewPlan_clearBtn_testId'
              >Clear</Button>
              <Button
                disabled={this.state.postLoading}
                sx={{ height: '2vw', width: '8.5vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => this.CreateReviewPlan()}
                emed_tid='reviewPlan_AddBtn_testId'
              >Add</Button>
            </Box>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
