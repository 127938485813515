import { Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { AddCircle } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { ReasonPopup } from '../../../Components/Common Components/CommonComponents';

export default class PharmaBranchRaiseReq extends Component {
    constructor(props) {
        super(props)
        let drugRequest = this.props.history?.location?.state
        this.state = {
            brandList: [],
            selectedBrandNamelist: null,
            brandName: null,
            toLocationNameList: [],
            toLocationName: null,
            drugRequestList: (drugRequest?.page === "branchwanted") ? drugRequest?.drugRequestList : [],
            page: drugRequest?.page ? drugRequest?.page : "",
            pharmacyId: '',
            quantity: '',
            selectedBrandName: '',
            selectedGenericName: '',
            selectedDosageType: '',
            selectedDosageStrength: '',
            selectedDrugmsId: '',
            selectedBatchNo: null,
            fromLocationName: '',
            batchList: [],
            multipleBatchNo: [{ 'batch_no': null, 'quantity': '', 'reason': '' }],
            outboundBatchList: [],
            selectedQuantity: '',
            selectedIndex: null,
            selectedExpiryDate: '',
            transData: drugRequest?.tranferData ? drugRequest?.tranferData : null,
            is_decline: false,
            is_Success: false,
            isfromotDrugs: drugRequest?.isfrom === "OtDrugs",
            disableBtn: false,
            isEdit: false,
            isStock: false,
            Avl_Qty: '',         
        }
    }

    componentDidMount() {
        this.getToLocationListData()
        let subLocationDetails = localGetItem("loggedInUserInfo")
        let details = JSON.parse(subLocationDetails)
        this.setState({
            fromLocationName: details?.pharmacy_name,
            pharmacyId: details?.pharmacy_id,
        })
        if (this.state.page === 'branchoutbound' && this.state.transData?.id) {
            this.setState({
                toLocationName: { 'pharmacy_name': this.state.transData?.transfer_to, 'id': this.state.transData?.pharmacy_id }
            })
            this.getOutbondList()
        }
    }

    getOutbondList = () => {
        try {
            this.LoaderFunction(true)
            var states = this.state
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_LINE_OF_ITEM + "?request_id=" + states?.transData.id)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            drugRequestList: response.data.data,
                        })

                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    getToLocationListData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_TOLOC)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)

                        this.setState({
                            toLocationNameList: response.data.data
                        })
                    }

                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getRequestDrugListGet = (id, index, list) => {
        try {
            let params = ''
            var name = this.state.brandName ? this.state.brandName : ''
            var id = id ? id : ''
            if (this.state.page === 'branchoutbound') {
                params = Serviceurls.PHA_BRANCH_DRUGSEARCH + `?drug_name=${name}&drug_ms_id=${id}`
            }else if(this.state.page === 'branchreturn'){
                params = Serviceurls.PHA_BRANCH_DRUGSEARCH + `?drug_name=${name}&mode=return&from_pharmacy=${this.state.toLocationName.pharmacy_id}`
            } else {
                params = Serviceurls.PHA_BRANCH_DRUGSEARCHLIST + `?pharmacy_id=${this.state.toLocationName.pharmacy_id}&drug_name=${name}`
            }
            if (this.state.toLocationName !== null) {
            this.LoaderFunction(true)
            RestAPIService.getAll(params)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            brandList: response.data.data ? response.data.data : []
                        })
                        this.LoaderFunction(false)
                        if ((id && this.state.page === 'branchoutbound')) {
                            const isHSNisMandatory = localGetItem("hsncode_mandatory_for_billing") === "true";
                            if (!response.data.data[0]?.hsn_code && isHSNisMandatory && this.state.page === "branchoutbound") {
                                this.errorMessage(`HSN Code not available for drug: ${response.data.data[0]?.drug_name ? response.data.data[0]?.drug_name : ""}`);
                            } else {
                                this.setState({
                                    selectedDrugmsId: response.data.data[0]?.id || null,
                                    selectedBrandNamelist: response.data.data[0]?.drug_name || null,
                                    selectedGenericName: response.data.data[0]?.generic_name || "",
                                    selectedBrandName: response.data.data[0]?.brand_name || '',
                                    selectedDosageStrength: response.data.data[0]?.dosage_strength || '',
                                    selectedDosageType: response.data.data[0]?.dosage_type || '',
                                    selectedQuantity: response.data.data[0]?.stock_in_quantity || '',
                                    selectedIndex: index,
                                    selectedExpiryDate: response.data.data[0]?.expiry_date || null,
                                    selectedMrpPerQty: response.data.data[0]?.cost_per_quantity || '',
                                    batchList: response.data.data[0]?.batch_detail || [],
                                    multipleBatchNo: list?.batch_no ? list?.batch_no : [{ 'batch_no': null, 'quantity': '', 'reason': '' }]
                                })
                            }
                        }
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
            } else {
                this.errorMessage("Select To Location for drug search")
            }
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getBatchListData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.SUBLOC_BATCH_DETAILS_SEARCH + `?drug_ms_id=${this.state.selectedDrugmsId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            batchList: response.data.data,
                            selectedBatchNo: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    navigatePage = () => {
        try {
            switch (this.state.page) {
                case 'branchinbound':
                    this.props.history.push("/PharmacyStockTransfer/PharmaBranchInbound")
                    break;
                case 'branchoutbound':
                    this.props.history.push("/PharmacyStockTransfer/PharmaBranchOutbound")
                    break;
                case 'branchreturn':
                    this.props.history.push("/PharmacyStockTransfer/PharmaBranchReturn")
                    break;
                case 'branchwanted':
                    this.props.history.push("/PharmacyStockTransfer/PharmaBranchWanted")
                    break;
                default:
                    this.props.history.push("/PharmacyStockTransfer/RaiseRequest")
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postNewDrugListData = () => {
        try {
            let states = this.state
            let drugRequest = this.props.history?.location?.state
            let finalDrugList = []
            if (states.page === 'branchreturn') {
                states.drugRequestList?.forEach(element => {
                    finalDrugList.push({
                        'drug_ms_id': element.drug_ms_id,
                        'request_quantity': +element.quantity,
                        'received_quantity': 0,
                        'transfer_quantity': +element.quantity,
                        'batch_detail': [{
                            'drug_ms_id': element.drug_ms_id,
                            'batch_no': element.batch_no,
                            'expiry_date': element.expiry_date,
                            'cost_per_quantity': element.cost_per_quantity ? element.cost_per_quantity : element.mrp_per_quantity || 0,
                            'quantity': +element.quantity ? +element.quantity : +element.stock_in_quantity,
                        }],
                        'command': element.reason ? element.reason : '',
                        'is_delete': false,
                    })
                })
            } else if ((this.state.page === 'branchoutbound')) {

                states.drugRequestList.filter(item => !item.is_delete)?.forEach((list, index) => {
                    let batchData = []
                    if(!list?.stock_not_available && list.approved_quantity !== 0){
                        list.batch_no?.forEach((element) => {
                            batchData.push({
                                'batch_no': element.batch_no?.batch_no,
                                'expiry_date': element.batch_no?.expiry_date,
                                'cost_per_quantity': element.batch_no?.cost_per_quantity || 0,
                                'drug_ms_id': element.batch_no?.drug_ms_id,
                                'quantity': +element.quantity
                            })
                        })
                    }

                    finalDrugList.push({
                        'drug_ms_id': list.drug_ms_id,
                        'request_quantity': list.request_quantity ? list.request_quantity : 0,
                        'received_quantity': 0,
                        'approved_quantity': list.approved_quantity ? list.approved_quantity : 0,
                        'transfer_quantity': list.transfer_quantity ? list.transfer_quantity : 0,
                        'batch_detail': (list?.stock_not_available && list.approved_quantity === 0)  ? [] : batchData,
                        'is_delete': false,
                        'command': list.reason ? list.reason : '',
                    })

                    if (list?.id) {
                        finalDrugList[index].id = list?.id ? list?.id : null
                    }
                })
                states.drugRequestList.filter(item => item.is_delete)?.forEach((list, index) => {
                    var NonDeleteData = states.drugRequestList.filter(item => !item.is_delete)
                    var Len = NonDeleteData?.length
                    finalDrugList.push({
                        'drug_ms_id': list.drug_ms_id,
                        'request_quantity': list.request_quantity ? list.request_quantity : 0,
                        'received_quantity': 0,
                        'approved_quantity': 0,
                        'transfer_quantity': 0,
                        'batch_detail': [],
                        'is_delete': true,
                        'command': list.reason ? list.reason : '',
                    })

                    if (list?.id) {
                        finalDrugList[index + Len].id = list?.id ? list?.id : null
                    }
                })
            } else {
                states.drugRequestList?.forEach(element => {
                    finalDrugList.push({
                        "drug_ms_id": element.drug_ms_id,
                        "request_quantity": +element.request_quantity,
                        "received_quantity": 0,
                        'transfer_quantity': 0,
                        'batch_detail': [],
                        'command': '',
                        'is_delete': false
                    })
                })
            }
            var fromLocationID = null
            var toLocationID = null
            if (states.page === 'branchreturn') {
                fromLocationID = states.toLocationName.pharmacy_id ? states.toLocationName.pharmacy_id : null
                toLocationID = states.pharmacyId ? states.pharmacyId : null
            } else if (states.page === 'branchoutbound' && states.pharmacyId) {
                fromLocationID = states.toLocationName.pharmacy_id ? states.toLocationName.pharmacy_id : null
                toLocationID = states.pharmacyId ? states.pharmacyId : null
            }
            let data = {
                'main_pharmacy_id': states.pharmacyId ? states.pharmacyId : null,
                'req_to_location': toLocationID ? toLocationID : states.toLocationName?.pharmacy_id ? states.toLocationName?.pharmacy_id : null,
                'transit_number': 0,
                'transit_name': '',
                'transfer_type': (states.page === 'branchoutbound') ? 'Outbound' : states.page === 'branchreturn' ? 'Returned' : 'Inbound',
                'line_item': finalDrugList,
                'req_from_location': fromLocationID ? fromLocationID : states.pharmacyId ? states.pharmacyId : null,
                'inbound_id': states.transData?.id ? states.transData.id : null,
                'cancel_reason': '',
                'is_cancel': false,
            }
            if (data.transfer_type === 'Outbound') {
                data['status'] = 'Approved'
                data['transfer_from'] = states.fromLocationName
                data['transfer_to'] = states.toLocationName.pharmacy_name ? states.toLocationName.pharmacy_name : states.toLocationName ? states.toLocationName : null
            } else if (data.transfer_type === 'Inbound') {
                data['status'] = 'Initiated'
                data['transfer_to'] = states.fromLocationName
                data['transfer_from'] = states.toLocationName.pharmacy_name ? states.toLocationName.pharmacy_name : states.toLocationName ? states.toLocationName : null
            } else if (data.transfer_type === 'Returned') {
                data['status'] = 'In Transit'
                data['transfer_from'] = states.fromLocationName
                data['transfer_to'] = states.toLocationName.pharmacy_name ? states.toLocationName.pharmacy_name : states.toLocationName ? states.toLocationName : null
            }
            this.LoaderFunction(true)
            if (states.toLocationName !== null && states.drugRequestList?.length > 0) {
                this.LoaderFunction(true)
                this.setState({ disableBtn: true })
                RestAPIService.create(data, Serviceurls.PHA_BRANCH_REQ_POST)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            setTimeout(() => {
                                this.navigatePage()
                            }, 1000)
                            this.setState({
                                toLocationName: null,
                                drugRequestList: [],
                                disableBtn: false,
                            })
                            this.onClearNewDrugList()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                        this.setState({ disableBtn: false })
                    })
            } else {
                this.LoaderFunction(false)
                this.setState({ disableBtn: false })
                this.errorMessage("Enter To location or Add drug list")
            }
        } catch (error) {
            this.LoaderFunction(false)
            this.setState({ disableBtn: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    onClickEditReturnDrug = (item, index) => {
        try {
            if (this.state.page === 'branchoutbound') {
                this.setState({
                    selectedBrandNamelist: null,
                    selectedBrandName: '',
                    selectedDosageStrength: '',
                    selectedDosageType: '',
                    selectedGenericName: '',
                    quantity: '',
                    selectedDrugmsId: '',
                    brandList: [],
                    brandName: null,
                    selectedBatchNo: null,
                    reason: '',
                    batchList: [],
                    outboundBatchList: { 'batch_no': null, 'quantity': '' },
                    multipleBatchNo: [{ batch_no: null, reason: '', quantity: '' }],
                    selectedIndex: null,
                    isEdit: true,
                }, () => {
                    this.getRequestDrugListGet(item?.drug_ms_id, index, item)
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftTableContent = () => {
        try {
            return (
                <Box component={'div'} height={'58vh'} overflow={'scroll'} border={'1px solid lightgray'}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {(this.state.page === 'branchoutbound' && this.state.transData?.id) ? null :
                                 <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, color: Colors.SecondaryText }}>
                                    <Button
                                        className='Common_Btn_Min_Width'
                                        sx={{ height: '1.5vw', width: '1.5vw' }}
                                        onClick={() => { }}
                                        disabled
                                    >
                                        {/* <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} /> */}
                                     </Button>
                                </TableCell>}
                                <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText }}>
                                    Product Details
                                </TableCell>
                                <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText }}>
                                    Dosage Type/Strength
                                </TableCell>
                                {this.state.page === 'branchoutbound' ?
                                    <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText }}>
                                        Approved Quantity
                                    </TableCell> : null}
                                <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText }}>
                                    Requested Quantity
                                </TableCell>
                                {this.state.page === 'branchoutbound' ?
                                    <TableCell sx={{ padding: '0.5vw', backgroundColor: Colors.Background, fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText }}>
                                        Action
                                    </TableCell> : null}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.drugRequestList?.length > 0 ? this.state.drugRequestList?.filter(list => !list.is_delete).map((item, index) => (
                                <TableRow key={index}>
                                    {(this.state.page === 'branchoutbound' && this.state.transData?.id) ? null : <TableCell sx={{ padding: '0.5vw' }}>
                                        <Button
                                            className='Common_Btn_Min_Width'
                                            sx={{ height: '1.5vw', width: '1.5vw' }}
                                            onClick={() => {
                                                var { drugRequestList } = this.state
                                                var orininalList = drugRequestList?.filter(list => !list.is_delete)
                                                if (this.state.transData?.id && orininalList.length > 1) {
                                                    var OriginalIndex = drugRequestList?.findIndex(item => item.id === orininalList[index].id)
                                                    drugRequestList[OriginalIndex]['is_delete'] = true
                                                    this.setState({ drugRequestList })
                                                } else if (this.state.transData?.id && orininalList.length === 1) {
                                                    this.errorMessage("List cannot be empty")
                                                } else {
                                                    this.state.drugRequestList?.splice(index, 1)
                                                    this.setState({ drugRequestList: this.state.drugRequestList })
                                                }
                                            }}
                                        >
                                            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Button>
                                    </TableCell>}
                                    <TableCell sx={{ padding: '0.5vw' }}>
                                        <Box>
                                            <Typography fontWeight={600} fontSize={'0.9vw'}>{item?.drug_name ? item?.drug_name : item?.brand_name ? item?.brand_name : '-'}</Typography>
                                            <Typography fontSize={'0.8vw'} color={'gray'}>{item?.generic_name}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ padding: '0.5vw' }}>
                                        <Typography fontSize={'0.9vw'}>{`${item?.dosage_type} / ${item?.drug_strength ? item?.drug_strength : item?.dosage_strength ? item?.dosage_strength : '-'}`}</Typography>
                                    </TableCell>
                                    {this.state.page === 'branchoutbound' ?
                                        <TableCell sx={{ padding: '0.5vw' }}>
                                            {item?.approved_quantity ? item?.approved_quantity : item.quantity ? item.quantity : item.stock_not_available ? '0' : '-'}
                                        </TableCell> : null}
                                    <TableCell sx={{ padding: '0.5vw' }}>
                                        {(this.state.page === 'branchreturn') ?
                                            <Box>
                                                <Typography fontWeight={600} fontSize={'0.9vw'}>{item?.quantity ? item?.quantity : '-'}</Typography>
                                                <Typography fontSize={'0.8vw'} color={'gray'}>{`Batch No.:${item?.batch_no}`}</Typography>
                                            </Box> :
                                            <Typography fontSize={'0.9vw'}>{item?.request_quantity ? item?.request_quantity : '-'}</Typography>
                                        }
                                    </TableCell>
                                    {this.state.page === 'branchoutbound' ?
                                        <TableCell>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                sx={{ width: '1.5vw', height: '1.5vw' }}
                                                onClick={() => this.onClickEditReturnDrug(item, index)}
                                            >
                                                <Box component={'img'} src={ImagePaths.LabEditButton.default} alt='edit' height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                        </TableCell> : null}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    cancelRequest = (cmt) => {
        try {
            let data = {
                "status_data": "Cancelled",
                "request_id": this.state.transData?.id,
                "cancel_reason": cmt ? cmt : '',
                "is_cancel": true,
            }
            this.setState({
                is_Success: true
            })
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.PHA_BRANCH_STATUS_CHANGE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.setState({ is_decline: false })
                        this.props.history.push('/PharmacyStockTransfer/PharmaBranchOutbound')
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    renderLeftContent = () => {
    var isValid = this.state.drugRequestList.every(function (item) {
        return (item.approved_quantity > 0) || 
               (item.approved_quantity === 0 && item.stock_not_available === true);
    });
    
    var hasNonZeroApprovedQuantity = this.state.drugRequestList.some(function (item) {
        return item.approved_quantity !== 0;
    });
    
    var app_dis = isValid && hasNonZeroApprovedQuantity; 
        try {
            let disable = this.state.transData ? true : false
            return (
                <Box component={'div'}>
                    <Box component={'div'} border={'1px solid lightgray'} display={'flex'} alignItems={'center'} height={'10vh'} mb={'1vw'} sx={{ backgroundColor: 'white' }}>
                        <Box component={'div'}>
                            <TextField
                                size='small'
                                sx={{ width: '20vw', mr: '1vw', ml: '0.5vw' }}
                                value={this.state.fromLocationName}
                                disabled
                                label='From Location'
                            />
                        </Box>
                        <Box component={'div'}>
                            <Autocomplete
                                size='small'
                                clearIcon
                                disabled={disable}
                                sx={{ width: '20vw' }}
                                options={this.state.toLocationNameList}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.pharmacy_name}
                                value={this.state.toLocationName}
                                onChange={(e, newValue) => this.setState({ toLocationName: newValue }, () => {
                                    if (this.state.page !== 'branchwanted') {
                                        this.setState({ drugRequestList: [] }, () => { this.onClearNewDrugList() })
                                    }
                                })}
                                renderInput={(params) => <TextField {...params} label='To Location Name' />}
                            />
                        </Box>
                    </Box>
                    <Box component={'div'} maxHeight={'58vh'} sx={{ backgroundColor: 'white' }}>
                        {this.renderLeftTableContent()}
                    </Box>
                    <Box component={'div'} height={'7.5vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        {this.state.transData?.id ? <Button
                            variant='outlined'
                            color='error'
                            sx={{ width: '10vw', height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                            onClick={() => {
                                this.setState({ is_decline: true })
                            }}
                        >{"Decline"}</Button> : null}
                        <Button
                            variant='contained'
                            sx={{ width: '10vw', height: '2vw', textTransform: 'capitalize' }}
                            onClick={() => {
                                if (this.state.toLocationName) {
                                    this.postNewDrugListData()
                                } else {
                                    this.errorMessage('Please Select To Location')
                                }

                            }}
                            disabled={this.state.disableBtn ? true : this.state.drugRequestList.length === 0 || (this.state.page === 'branchoutbound' && app_dis === false)? true : false}
                        >{this.state.page === "branchreturn" ? "Return" : (this.state.page === "branchoutbound") ? "Approve" : 'Raise Request'}</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names, index) => {
        try {
            const { value } = e.target
            switch (names) {
                case 'quantity':
                    if ((this.state.page === 'branchreturn')) {
                        if ((CommonValidation.numberOnly(value) && value.length <= 10 && value <= this.state.selectedBatchNo?.stock_in_quantity) || value === '') {
                            this.setState({ quantity: value })
                        }
                    } else {
                        if (CommonValidation.numberOnly(value) && value.length <= 10 || value === '') {
                            this.setState({ quantity: value })
                        }
                    }
                    break;
                case 'outboundQuantity':
                    if (CommonValidation.numberOnly(value) && value <= this.state.multipleBatchNo[index]['batch_no']['stock_in_quantity'] || value === '') {
                        this.state.multipleBatchNo[index]['quantity'] = value
                        this.setState({ multipleBatchNo: this.state.multipleBatchNo })
                    } else {
                        this.errorMessage("Quantity exceeds the stock limit")
                    }
                    break;
                case 'reason':
                    this.setState({ reason: value })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    textBox = (names, values, label, width, mr, fullWidth, disabled, multiline, rows, index) => {
        try {
            return (
                <TextField
                    size='small'
                    multiline={multiline}
                    autoComplete='off'
                    rows={rows}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    sx={{ width: width, mr: mr, mb: names === 'outboundQuantity' ? '0vw' : '1vw' }}
                    label={label}
                    name={names}
                    value={values}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBrandNameAutoComplete = (list, popperWidth) => {
        try {
            let isHSNisMandatory = (localGetItem("hsncode_mandatory_for_billing") === "true") ? true : false
            return (
                <Autocomplete
                    size='small'
                    clearIcon
                    fullWidth
                    autoComplete='off'
                    disabled={this.state.toLocationName === null}
                    componentsProps={{ popper: { style: { width: popperWidth } }, paper: { style: { marginLeft: '-2vw' } } }}
                    options={list ? list : []}
                    filterOptions={(options) => options}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.drug_name}
                    value={this.state.selectedBrandNamelist}
                    onChange={(event, newValue) => {
                        if(newValue?.hsn_code || (this.state.page !== "branchoutbound") || !isHSNisMandatory){
                        this.setState({ selectedBrandNamelist: newValue }, () => {
                            let { selectedBrandNamelist } = this.state
                            this.setState({
                                selectedDrugmsId: selectedBrandNamelist?.id ? selectedBrandNamelist?.id : selectedBrandNamelist?.drug_ms_id ? selectedBrandNamelist?.drug_ms_id : selectedBrandNamelist?.drug_id,
                                selectedBrandName: selectedBrandNamelist?.drug_name ? selectedBrandNamelist?.drug_name : null,
                                selectedGenericName: selectedBrandNamelist?.generic_name ? selectedBrandNamelist?.generic_name : '',
                                selectedDosageStrength: selectedBrandNamelist?.drug_strength ? selectedBrandNamelist?.drug_strength : selectedBrandNamelist?.dosage_strength ? selectedBrandNamelist?.dosage_strength : '',
                                selectedDosageType: selectedBrandNamelist?.dosage_type ? selectedBrandNamelist?.dosage_type : '',
                                batchList: ((this.state.page === "branchoutbound" || this.state.page === 'branchreturn') && selectedBrandNamelist?.batch_detail) ? selectedBrandNamelist?.batch_detail : [],
                                Avl_Qty: selectedBrandNamelist?.available_quantity
                            }, () => {
                                this.setState({
                                    multipleBatchNo: [{ batch_no: null, reason: '', quantity: '' }],
                                    selectedBatchNo: null,
                                    quantity: '',
                                    reason: '',
                                })
                            })
                        })}
                        else{
                            this.errorMessage(`HSN Code not available for drug: ${newValue?.drug_name ? newValue?.drug_name : ""}`);
                        }
                    }}
                    onInputChange={(event, value) => { this.setState({ brandName: value }) }}
                    renderOption={(props, item) => {
                        return (
                            <Box key={props["data-option-index"]}>
                                {props["data-option-index"] === 0 ?
                                    <Box sx={{ display: 'flex' }} emed_tid={"bill_batch_no_items"}>
                                        <Typography width={"40%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Brand Name</Typography>
                                        <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Generic Name</Typography>
                                        <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Dosage Type/Strength</Typography>
                                    </Box> : null}
                                <Box component="li" className='' {...props}>
                                    {item?.drug_name ? item?.drug_name?.length > 15 ?
                                        <Tooltip placement='top' title={item?.drug_name}><Box width={"40%"}>{item.drug_name.slice(0, 15) + '...'}</Box></Tooltip> :
                                        <Typography width={"40%"}>{item?.drug_name}</Typography> : '-'}

                                    {item?.generic_name ? item?.generic_name?.length > 15 ?
                                        <Tooltip placement='top' title={item?.generic_name}><Box width={"30%"}>{item?.generic_name.slice(0, 15) + '...'}</Box></Tooltip> :
                                        <Typography width={'30%'}>{item?.generic_name}</Typography> : '-'}

                                    {`${item?.dosage_type}/${item?.drug_strength ? item?.drug_strength : item?.dosage_strength}` ? `${item?.dosage_type}/${item?.drug_strength ? item?.drug_strength : item?.dosage_strength}` > 15 ?
                                        <Tooltip placement='top' title={`${item?.dosage_type}/${item?.drug_strength ? item?.drug_strength : item?.dosage_strength}`}><Box width={"30%"}>{`${item?.dosage_type}/${item?.drug_strength}`.slice(0, 15) + '...'}</Box></Tooltip> :
                                        <Typography width={"30%"}>{`${item?.dosage_type}/${item?.drug_strength ? item?.drug_strength : item?.dosage_strength}`}</Typography> : '-'}

                                </Box>
                            </Box>
                        )
                    }}
                    noOptionsText={this.state.brandName === '' ? "Please Search" : 'No Records'}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                'emed_tid': 'brand_name',
                            }}
                            onChange={(e) => {
                                this.setState({
                                    brandName: e.target.value
                                }, () => {
                                    if (this.state.brandName?.length > 3) {
                                        this.getRequestDrugListGet()
                                    }
                                    else {
                                        this.setState({ brandList: [] })
                                    }
                                })

                            }}
                            label='Enter Brand Name'
                            sx={{ mb: '1vw', mt: '1vw' }}
                        />)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBatchNoAutoComplete = (list, values, index) => {
        try {
            return (
                <Autocomplete
                    size='small'
                    clearIcon
                    autoComplete='off'
                    disabled={this.state.toLocationName === null || this.state.isStock}
                    sx={{ width: '16.5vw', mr: '0.5vw' }}
                    options={list}
                    componentsProps={{ popper: { style: { width: '25vw' } } }}
                    filterOptions={(options) => options}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options.batch_no}
                    value={((this.state.page === 'branchoutbound')) ? this.state.multipleBatchNo[index].batch_no : this.state.selectedBatchNo}
                    onChange={(event, newValue) => {
                        if (this.state.page === 'branchoutbound') {
                            this.state.multipleBatchNo[index]['batch_no'] = newValue
                            this.state.multipleBatchNo[index]['quantity'] = ""
                            this.setState({
                                multipleBatchNo: this.state.multipleBatchNo,
                                outboundQuantity: ''
                            })
                        } else {
                            let states = this.state
                            states.selectedBatchNo = newValue
                            this.setState({ states })
                        }
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'brand_name' }} label='Select Batch No' />}
                    renderOption={(props, item) => {
                        return (
                            <Box key={props["data-option-index"]}>
                                {props["data-option-index"] === 0 ?
                                    <Box sx={{ display: 'flex' }} emed_tid={"bill_batch_no_items"}>
                                        <Typography width={"40%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Batch No.</Typography>
                                        <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Expiry Date</Typography>
                                        <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Available Qty</Typography>
                                    </Box> : null}
                                <Box component="li" className='' {...props}>
                                    <Typography width={'40%'}>{(item?.batch_no)}</Typography>
                                    <Typography width={'30%'}>{item?.expiry_date ? (item?.expiry_date)?.slice(0, 7) : '-'}</Typography>
                                    <Typography width={'30%'}>{(item?.stock_in_quantity)}</Typography>
                                </Box>
                            </Box>
                        )
                    }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    onClearNewDrugList = () => {
        try {
            this.setState({
                selectedBrandNamelist: null,
                selectedBrandName: '',
                selectedDosageStrength: '',
                selectedDosageType: '',
                selectedGenericName: '',
                quantity: '',
                selectedDrugmsId: '',
                brandList: [],
                brandName: null,
                selectedBatchNo: null,
                reason: '',
                batchList: [],
                outboundBatchList: { 'batch_no': null, 'quantity': '' },
                multipleBatchNo: [{ batch_no: null, reason: '', quantity: '' }],
                selectedIndex: null,
                isEdit: false,
                isStock: false,
                Avl_Qty: '',
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    batchDuplicate = () => {
        // right side dup check
        try {
            var states = this.state
            var batchDup = false
            states.multipleBatchNo.map((list, i) => (
                states.multipleBatchNo.find((li, index) => {
                    if ((JSON.stringify(list.batch_no) === JSON.stringify(li.batch_no)) && (i !== index)) {
                        batchDup = true
                    }
                })
            ))
            return batchDup
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    checkDrugDuplicate = () => {
        try {
            let message = null;
            let states = this.state
            var batchbulication = this.batchDuplicate()
            if ((states.page === 'branchoutbound') && !batchbulication) {
                states.multipleBatchNo?.forEach((batch, index) => {
                    if (batch.batch_no !== null && batch.quantity !== '') {
                        states.drugRequestList?.forEach(element => {
                            if (element.drug_ms_id === states.selectedDrugmsId) {
                                message = "Brand name already exists"
                            }
                        })
                    } else {
                        message = 'Enter All Details'
                    }
                })
            } else if (!batchbulication) {
                if (states.quantity !== '') {

                    states.drugRequestList?.forEach((element, index) => {
                        if (index !== this.state.selectedIndex) {
                            if (states.page === 'branchreturn' && (element.drug_ms_id === states.selectedDrugmsId) && (element.batch_no === states.selectedBatchNo?.batch_no)) {
                                message = "BrandName with same BatchNo already exists"
                            }else if((states.page === 'branchwanted' || states.page === 'branchinbound' ) && (element.drug_ms_id === states.selectedDrugmsId)){
                                message = "Brand name already exists"
                            }
                        }
                    })
                } else {
                    message = "Enter Quantity"
                }
            } else {
                message = "Batch Number Already Exisit"
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    updateOrInsertList = (array, newValue, selectedIndex) => {
        if (array.length === 0) {
            return [newValue]
        } else {
            const obj = array.find((item, index) => index === selectedIndex)
            if (!obj) {
                return [...array, newValue]
            } else {
                if (JSON.stringify(obj) === JSON.stringify(newValue)) {
                    return [...array.filter((object, index) => index !== selectedIndex), newValue]
                } else {
                    return [...array.filter((object, index) => index !== selectedIndex), newValue]
                }
            }
        }
    }

    onAddNewDrugList = () => {
        try {
            var states = this.state
            let duplicate = states.isStock ? null : this.checkDrugDuplicate()
            if (duplicate === "Brand name already exists" && states.page === 'branchoutbound' && this.state.isEdit) {
                let totalQuantity = 0;
                states.multipleBatchNo?.forEach((element, index) => {
                    totalQuantity += (+element.quantity)
                    if (element.batch_no === null && element.quantity === '') {
                        states.multipleBatchNo.splice(index, 1)
                    }
                })
                if (this.state.selectedDrugmsId) {
                    let index = this.state.drugRequestList.findIndex((item => (
                        item.drug_ms_id === states.selectedDrugmsId
                    )))
                    states.drugRequestList[index].batch_no = states.multipleBatchNo
                    states.drugRequestList[index].approved_quantity = +totalQuantity
                    states.drugRequestList[index].stock_not_available = false
                    this.setState({ states }, () => { this.onClearNewDrugList() })
                }                
            }else if(duplicate === "Brand name already exists" && (states.page === 'branchoutbound' || states.page === 'branchinbound' || states.page === 'branchwanted')){
                this.errorMessage(duplicate)
            } else if (duplicate === null && !states.isStock) {
                let totalQuantity = 0;
                states.multipleBatchNo?.forEach((element, index) => {
                    totalQuantity += (+element.quantity)
                    if (element.batch_no === null && element.quantity === '') {
                        states.multipleBatchNo.splice(index, 1)
                    }
                })
                let batch_no = null
                if (states.page === 'branchoutbound') {
                    batch_no = states.multipleBatchNo ? states.multipleBatchNo : null
                } else if (states.page === 'branchreturn') {
                    batch_no = states.selectedBatchNo.batch_no ? states.selectedBatchNo.batch_no : null
                }
                let add_drugRequestList = {
                    "drug_ms_id": +states.selectedDrugmsId,
                    "drug_name": states.selectedBrandName,
                    "generic_name": states.selectedGenericName,
                    "drug_strength": states.selectedDosageStrength,
                    "dosage_type": states.selectedDosageType,
                    "quantity": +states.quantity ? +states.quantity : +totalQuantity,
                    "request_quantity": +states.quantity ? +states.quantity : +totalQuantity,
                    "approved_quantity": +totalQuantity ? +totalQuantity : null,
                    'batch_no': batch_no,
                    'reason': states.reason ? states.reason : '',
                    'expiry_date': states.selectedBatchNo?.expiry_date ? states.selectedBatchNo?.expiry_date : states.selectedExpiryDate,
                    'cost_per_quantity': states.selectedBatchNo?.cost_per_quantity ? +states.selectedBatchNo?.cost_per_quantity : +states.selectedMrpPerQty,
                    'multiple_batchs': states.multipleBatchNo,
                    'stock_in_quantity': states.selectedQuantity
                }
                    states.drugRequestList = [...states.drugRequestList, add_drugRequestList]
                    this.setState({ states }, () => this.onClearNewDrugList())
            }else if(states.isStock && states.page === 'branchoutbound'){
                let totalQuantity = +0;
                if (this.state.selectedDrugmsId) {
                    let index = this.state.drugRequestList.findIndex((item => (
                        item.drug_ms_id === states.selectedDrugmsId
                    )))
                    states.drugRequestList[index].batch_no = states.multipleBatchNo
                    states.drugRequestList[index].approved_quantity = +totalQuantity
                    states.drugRequestList[index].stock_not_available = true
                    this.setState({ states }, () => { this.onClearNewDrugList() })
                }
            } else {
                this.errorMessage(duplicate)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    renderRightContent = () => {
        try {
            let states = this.state
            let disaCheck = !(states.selectedBrandNamelist && states.multipleBatchNo.some((item) => !item.batch_no) && this.state.isEdit);
            return (
                <Box component={'div'} border={'1px solid lightgray'}>
                    <Box component={'div'} height={'5vh'} display={'flex'} alignItems={'center'} ml={'0.5vw'}>
                        <Typography color={Colors.SecondaryText} fontWeight={600}>Product Details</Typography>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: 'white' }} p={'0.5vw'} height={'65vh'}>
                        {this.renderBrandNameAutoComplete(this.state.brandList, '30vw')}
                        {this.textBox('selectedGenericName', this.state.selectedGenericName, "Generic Name *", null, null, true, true)}
                        {this.textBox('selectedDosageStrength', this.state.selectedDosageStrength, "Dosage Strength *", '16.5vw', '0.5vw', null, true)}
                        {this.textBox('selectedDosageType', this.state.selectedDosageType, "Dosage Type *", '16.5vw', null, null, true)}
                        <Box component={'div'} borderTop={'1px solid lightgray'} pt={'1vw'} display={'flex'}>
                            {(this.state.page === "branchoutbound") ?
                                <Box>
                                    <Box>
                                        <FormControlLabel disabled={disaCheck} control={<Checkbox onChange={(e) => {this.setState({isStock: e.target.checked})}} checked={this.state.isStock} />} label={'Stock Not Avilable'} />
                                    </Box>
                                    <Box display={'flex'} flexDirection={'column'} height={'13vw'} overflow={'scroll'}>
                                        {this.state.multipleBatchNo?.map((item, index) => (
                                            <Box display={'flex'} pt={'1vw'}>
                                                {this.renderBatchNoAutoComplete(this.state.batchList, item?.batch_no, index)}
                                                {this.textBox('outboundQuantity', item?.quantity, "Quantity *", '15vw', null, null, this.state.toLocationName === null || !item?.batch_no || this.state.isStock ? true : false, null, null, index)}
                                                {index ?
                                                    <img src={ImagePaths.CrossIcon.default} alt='cancel' className='eMed_batch_delete' onClick={() => {
                                                        var states = this.state
                                                        states.multipleBatchNo.splice(index, 1)
                                                        this.setState({ states })
                                                    }} /> : null}
                                            </Box>
                                        ))}
                                        <Box display={'flex'} justifyContent={'flex-end'}>
                                            <Button
                                                sx={{ textTransform: 'capitalize' }}
                                                onClick={() => {
                                                    let newData = { 'batch_no': null, 'quantity': '', 'reason': '' }
                                                    let message = null
                                                    this.state.multipleBatchNo?.forEach(element => {
                                                        if (element.batch_no === null || element.quantity === '') {
                                                            message = 'Batch Number and quantity is not entered'
                                                        }
                                                    })
                                                    if (message === null) {
                                                        this.state.multipleBatchNo = [...this.state.multipleBatchNo, newData]
                                                        this.setState({
                                                            multipleBatchNo: this.state.multipleBatchNo
                                                        })
                                                    } else {
                                                        this.errorMessage(message)
                                                    }
                                                }}
                                                startIcon={<AddCircle />}
                                            >Add Batch</Button>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                this.state.page === "branchreturn" ?
                                    <Box display={'flex'}>
                                        {this.renderBatchNoAutoComplete(this.state.batchList, this.state.selectedBatchNo)}
                                        {this.textBox('quantity', this.state.quantity, "Quantity *", '16.5vw')}
                                    </Box> :
                                    <Box display={'flex'} alignItems={'center'}>
                                        {this.textBox('quantity', this.state.quantity, "Quantity *", '16.5vw', null, false, states.selectedDrugmsId ? false : true)}
                                        <Typography fontSize={'.9vw'} marginLeft={'2vw'} fontWeight={this.state.Avl_Qty ? 'bold': 100}>{`Available Qty: ${this.state.Avl_Qty}`}</Typography>
                                    </Box>
                            }
                        </Box>
                        {(this.state.page === "branchreturn") ?
                            this.textBox("reason", this.state.reason, "Reason", null, null, true, null, true, 3) : null}

                    </Box>
                    <Box component={'div'} height={'7.5vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                            onClick={() => { this.onClearNewDrugList() }}
                        >Clear</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                            onClick={() => { this.onAddNewDrugList() }}
                        >Add</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    popupClose = () => {
        this.setState({
            is_decline: false,
        })
    }
    addCancelComments = (comments) => {
        this.cancelRequest(comments)
    }
    render() {
        var { page } = this.state
        return (
            <Box component={'div'} height={'85vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} height={'5vh'} display={'flex'} alignItems={'center'} sx={{ backgroundColor: 'white', borderBottom: '1px solid lightgray' }}>
                    <IconButton onClick={() => {
                        page === "branchreturn" ?
                            this.props.history.push({ pathname: '/PharmacyStockTransfer/PharmaBranchReturn' }) :
                            page === "branchinbound" && !this.props.fromOT && !this.state.isfromotDrugs ?
                                this.props.history.push({ pathname: '/PharmacyStockTransfer/PharmaBranchInbound' }) :
                                page === "branchoutbound" ?
                                    this.props.history.push({ pathname: '/PharmacyStockTransfer/PharmaBranchOutbound' }) :
                                    this.props.history.push({ pathname: '/PharmacyStockTransfer/PharmaBranchWanted' })
                    }}>
                        <ChevronLeftIcon color='primary' />
                    </IconButton>
                    <Typography color={Colors.SecondaryText} fontWeight={600}>{(page === "branchreturn") ? 'Return Request' : page === "branchoutbound" ? 'Send Drug' : 'Raise Request'}</Typography>
                </Box>
                <Box component={'div'} display={'flex'} height={'80vh'}>
                    <Box flex={0.62} p={'0.5vw'}>
                        {this.renderLeftContent()}
                    </Box>
                    <Box flex={0.38} height={'80vh'} p={'0.5vw'}>
                        {this.renderRightContent()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.is_decline ? <ReasonPopup
                    title={"Are you sure? Do you want to Decline the Request"}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={"Enter the Cancel Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btntextDis={this.state.is_Success}
                    btnvarient1={'contained'}
                    sendCmt={this.addCancelComments.bind(this)} /> : null}
            </Box>
        )
    }
}
