import React, { Component } from 'react';
import './Billings.css';
import { IconButton, Tooltip, Box, MenuItem, Stack, Button, Menu, Drawer, Autocomplete, Typography, TextField, Modal } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import CommonGridHeader, { ReasonPopup } from "../../../Components/Common Components/CommonComponents";
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import  { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DataGrid } from '@mui/x-data-grid';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { AmountFormat, formatDate,OpenDirectPrint,timeOnlyConvert } from "../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from "luxon";
import ExpensePopUp from './ExpensePopUp';
import { CurrencySymbol } from '../../../Utility/Constants';

class ExpenseBills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expenseDetails: [],
            total_bill_amount: 0,
            total_bill_count: 0,
            expCategoryList: [],
            expCategory: [],
            userNameList: [],
            userName: [],
            isAddExpense: false,
            isViewExpense: false,
            viewDetails: {},
            anchorEl: null,
            selectedRow: -1,
            isSelected: false,
            selectedOption: "",
            modifyDetails: {},
            cancelId: null,
            Expense_date: null,
            FromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            ToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            "DateIndex": 1,
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
        }
    }

    componentDidMount() {
        this.getExpensesDetail();
        this._getExpenseCategoryDetails();
        this.GetUserDetails();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getExpenseCategoryDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.EXPENSE_CATEGORY_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            expCategoryList: response.data?.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetUserDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            userNameList: response.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getExpensesDetail = () => {
        let states = this.state;
        let startDate = states.Expense_date ? new Date(states.Expense_date) : "";
        let expenseDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let userId = [], categoryId = [];
        states.userName?.forEach(element => userId.push(element.id));
        states.expCategory?.forEach(element => categoryId.push(element.id));

        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(`${Serviceurls.EXPENSE_BILL_GET}?user_id=${userId}&expense_category=${categoryId}&from_date=${this.state.FromDate}&to_date=${this.state.ToDate}`)
                .then(response => {
                    let data = response?.data;
                    if (response.data.status === "success") {
                        this.setState({
                            total_bill_amount: data?.total_bill_amount,
                            total_bill_count: data?.total_bill_count,
                            expenseDetails: data?.data
                        }, () => {
                            this.LoaderFunction(false);
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.EXPENSE_BILL_GET}?id=${this.state.cancelId ? this.state.cancelId : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.reasonPopupClose();
                        this.successMessage(response.data?.message);
                        this.getExpensesDetail();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "Modify", label: t("Modify") },
            { value: "Cancel", label: t("Cancel") }
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} onClick={() => {
                                this.setState(
                                    {
                                        anchorEl: null,
                                        selectedOption: option.value,
                                        modifyDetails: option.value === "Modify" ? data : {},
                                        cancelId: option.value === "Cancel" ? data.id : null
                                    })
                            }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    // Drawer start

    renderMultiSlt = (label, optionList, valueKey, statekey) => {
        let states = this.state;
        const { t } = this.props;

        return (
            <Box className='eMed_rts_dropdown'>
                <Autocomplete
                    multiple
                    limitTags={1}
                    size='small'
                    options={optionList?.length > 0 ? optionList?.map((item) => item) : "No Data"}
                    getOptionLabel={(option) => (option[valueKey] ? option[valueKey] : option)}
                    value={states[statekey] ? states[statekey] : []}
                    onChange={(event, newValue) => {
                        states[statekey] = newValue;
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label)}
                        />
                    )}
                />
            </Box>)
    }

    renderDatePicker = (label, statekey) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <Box className='eMed_rts_dropdown'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={states[statekey] ? states[statekey] : null}
                        inputFormat='DD-MM-YYYY'
                        label={t(label)}
                        onChange={
                            (newDate) => {
                                states[statekey] = newDate
                                this.setState({
                                    states
                                });
                            }}
                        renderInput={(params) => <TextField size='small' autoComplete='off' {...params} />}
                    />
                </LocalizationProvider>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
    FilterDate = (From, To, Index) => {
        this.setState({ FromDate: From,
                        ToDate: To,
                        DateIndex: Index
         }, () => {
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle' sx={{marginTop:"1.5vw"}}>
                <CommonDatePicker
                            FromDate={this.state.FromDate}
                            ToDate={this.state.ToDate}
                            DateIndex={this.state.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    {/* {this.renderDatePicker("SelectDate", "Expense_date")} */}
                    {this.renderMultiSlt("sltExpCategory", states.expCategoryList, "name", "expCategory")}
                    {this.renderMultiSlt("sltUserName", states.userNameList, "user_name", "userName")}
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                Expense_date: null,
                                expCategory: [],
                                userName: [],
                                FromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                ToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                DateIndex: 1,
                            },
                                () => this.getExpensesDetail())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getExpensesDetail()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    // Drawer end

    PopupClose = () => {
        this.setState({
            selectedOption: "",
            selectedRow: null
        })
    }

    // Individual and overall print
    printExpenseBill = () => {
        let states = this.state;
        let startDate = states.Expense_date ? new Date(states.Expense_date) : "";
        let expenseDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let userId = [], categoryId = [];
        states.userName?.forEach(element => userId.push(element.id));
        states.expCategory?.forEach(element => categoryId.push(element.id));

        let url = ""
        if(this.state.selectedRow !== -1) {
           url = `${Serviceurls.EXPENSE_BILL_GET}?id=${states.selectedRow ? states.selectedRow : 0}&export_type=pdf`
        }else{
           url = `${Serviceurls.EXPENSE_BILL_GET}?user_id=${userId}&expense_category=${categoryId}&from_date=${this.state.FromDate}&to_date=${this.state.ToDate}&export_type=pdf`
        }
        try {
            RestAPIService.getAllPrint(url).
                then((response) => {
                // const file = new Blob(
                //   [response.data],
                //   { type: 'application/pdf' });
                // const fileURL = URL.createObjectURL(file);
                // window.open(fileURL);
                OpenDirectPrint(response)
                this.setState({ selectedRow: -1 })
              }).catch((error) => {
                if(error.response?.data?.message){
                this.errorMessage(error.response?.data?.message);
                }else{
                  this.errorMessage(error.message);
                }
                 this.setState({ selectedRow: -1 })
              })
        } catch(error){
            this.errorMessage(error.message)
        }
    }

    getExpenseExcel = () => {
        const {t} = this.props
        let states = this.state;
        let startDate = states.Expense_date ? new Date(states.Expense_date) : "";
        let expenseDate = startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : "";
        let userId = [], categoryId = [];
        states.userName?.forEach(element => userId.push(element.id));
        states.expCategory?.forEach(element => categoryId.push(element.id));
        try {
          RestAPIService.excelGet(`${Serviceurls.EXPENSE_BILL_GET}?user_id=${userId}&expense_category=${categoryId}&from_date=${expenseDate}&export_type=excel`).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 

              let filename = response.headers["content-disposition"].split("filename=")[1]

              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
            })
        } catch (error) {
            this.errorMessage(t("PrintError"))
        }
      }

    renderCard = () => {
        const { t } = this.props;
        return (
            <Box className="eMed_Top_Container">
                <Box className="eMed_Cards_Container">
                    {AmountsCard(t("TotalAmount"), this.state.total_bill_amount)}
                    {AmountsCard(t("TotalBill"), this.state.total_bill_count, true)}
                </Box>
                <Box className="eMed_Action_Container">
                    <Button variant='contained' size="small" id="eMed_recPayment_btn" sx={{ marginRight: "0.5vw" }}
                        onClick={() => this.setState({ isAddExpense: true })}
                    >
                        {t("AddExpense")}
                    </Button>
                    <IconButton className='eMed_usrconf_btn' size="small" onClick={() => { this.setState({ FilterOpen: true }) }}>
                        <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                    </IconButton>
                    <IconButton className='eMed_usrconf_btn' size="small"
                     onClick={() => { this.printExpenseBill() }}
                    >
                        <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                    </IconButton>
                    {/* <IconButton className='eMed_usrconf_btn' size="small"
                     onClick={() => { this.getExpenseExcel() }}
                    >
                        <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                    </IconButton> */}
                </Box>
            </Box>
        )
    }

    
    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FromDate) },
            { label: "To Date", value: formatDate(this.state.ToDate) },
        ]
            var ExportData = []
            this.state.expenseDetails?.length > 0 && this.state.expenseDetails.map((item) =>
                ExportData.push({'UserName':item.created_by,'Date':item.bill_date,'ExpenseBillNo':item.bill_no ,'ExpenseCategory':item.category_name,'PaidAmount':item.amount ,'PaymentMode':item.payment_type ,'Notes':item.description,'ModifiedBy':item.modified_by})
            )
        return(
          <Box>
             <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray}  data={ExportData} title={"Expenses"} filename={"ExpenseBills"}/>
          </Box>
    
        )
      }

    renderTable = () => {
        const { t } = this.props;
        let states = this.state;
        const columns = [
            {
                field: 'created_by', headerName: t('UserName'), flex: 0.1, renderCell: ({ row }) => (<div>{row?.created_by?.length > 20 ?
                    <Tooltip placement="top" title={row?.created_by} arrow><div>{row?.created_by.slice(0, 18) + "..."}</div></Tooltip> : row.created_by ? row.created_by : "-"}</div>)
            },
            { field: 'bill_date', headerName: t('Date'), flex: 0.13, headerAlign: "center", align: "center", renderCell: ({ row }) => (<div>{row.bill_date ? formatDate(row.bill_date) : "-"}</div>) },
            {
                field: 'bill_no', headerName: t('ExpenseBillNo'), flex: 0.12,
                renderCell: ({ row }) => (<div>{row?.bill_no?.length > 20 ?
                    <Tooltip placement="top" title={row?.bill_no} arrow><div>{row?.bill_no.slice(0, 18) + "..."}</div></Tooltip> : row.bill_no ? row.bill_no : "-"}</div>)
            },
            {
                field: 'category_name', headerName: t('ExpenseCategory'), flex: 0.11, renderCell: ({ row }) => (<div>{row?.category_name?.length > 20 ?
                    <Tooltip placement="top" title={row?.category_name} arrow><div>{row?.category_name.slice(0, 18) + "..."}</div></Tooltip> : row.category_name ? row.category_name : "-"}</div>)
            },
            {
                field: 'amount', headerName: `${t('PaidAmount')} (${CurrencySymbol})`, flex: 0.1, type: "number", renderCell: ({ row }) => {
                    let amount = AmountFormat(row.amount);
                    return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: 'payment_modes', headerName: t('PaymentMode'), flex: 0.11, headerAlign: "center", align: "center", renderCell: ({ row }) => (<div>{row?.payment_modes?.length > 20 ?
                    <Tooltip placement="top" title={row?.payment_modes} arrow><div>{row?.payment_modes.slice(0, 18) + "..."}</div></Tooltip> : row.payment_modes ? row.payment_modes : "-"}</div>)
            },
            {
                field: 'description', headerName: t('Notes'), flex: 0.12, renderCell: ({ row }) => (<div>{row?.description?.length > 20 ?
                    <Tooltip placement="top" title={row?.description} arrow><div>{row?.description.slice(0, 18) + "..."}</div></Tooltip> : row.description ? row.description : "-"}</div>)
            },
            {
                field: 'modified_by', headerName: t('ModifiedBy'), flex: 0.1, renderCell: ({ row }) => (<div>{row?.modified_by?.length > 20 ?
                    <Tooltip placement="top" title={row?.modified_by} arrow><div>{row?.modified_by.slice(0, 18) + "..."}</div></Tooltip> : row.modified_by ? row.modified_by : "-"}</div>)
            },
            {
                field: 'action', headerName: t('Action'), flex: 0.11, headerAlign: 'center', align: "center", hideable: false, sortable: false,
                renderCell: ({ row }) => (
                    <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                        <Tooltip title={t("View")} placement='top' arrow>
                            <IconButton size="small"
                                onClick={() => {
                                    this.setState({
                                        isViewExpense: true,
                                        viewDetails: row
                                    })
                                }}
                            >
                                <img src={ImagePaths.LabViewButton.default} alt="View" className='eMed_IP_Exp_printimg' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("Print")} placement='top' arrow>
                            <IconButton size="small"
                            onClick={() => {
                                this.setState({
                                    selectedRow: row.id
                                }, () => {
                                    this.printExpenseBill()
                                })
                            }}
                            >
                                <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_IP_Exp_printimg' />
                            </IconButton>
                        </Tooltip>
                        {this.MenuItem(row)}
                    </Stack>
                )
            }
        ];
        return (
            <div>
                <Box className='eMed_IPAptTbl_Con'>
                    <DataGrid
                        rows={states?.expenseDetails}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30]}
                        components={{
                            Toolbar:this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            </div>
        )
    }

    handleAddClose = () => {
        this.setState({
            isAddExpense: false,
            isViewExpense: false,
            viewDetails: {},
            selectedOption: "",
            modifyDetails: {},
        })
    }

    reasonPopupClose = () => {
        this.setState({
            selectedOption: "",
            cancelId: null,
        })
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box id='eMed_IP_Appointment'>
                {this.renderCard()}
                {this.renderTable()}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {states.selectedOption === "Cancel" ? <ReasonPopup
                    title={t("sureToCancelExpense")}
                    AlertPopupClose={this.reasonPopupClose.bind(this)}
                    label={"Enter the Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btnvarient1={'contained'}
                    sendCmt={this.removeDataList.bind(this)} /> : null
                }
                {states.isAddExpense ? <ExpensePopUp
                    Add={states.isAddExpense}
                    expCategoryList={states.expCategoryList}
                    popUpClose={this.handleAddClose.bind(this)}
                    errorMessage={this.errorMessage.bind(this)}
                    successMessage={this.successMessage.bind(this)}
                    LoaderFunction={this.LoaderFunction.bind(this)}
                    getDetails = {this.getExpensesDetail.bind(this)}
                    key={"Add"}
                />
                    : null}

                {states.isViewExpense ? <ExpensePopUp
                    View={states.isViewExpense}
                    viewData={states.viewDetails}
                    expCategoryList={states.expCategoryList}
                    popUpClose={this.handleAddClose.bind(this)}
                    errorMessage={this.errorMessage.bind(this)}
                    successMessage={this.successMessage.bind(this)}
                    LoaderFunction={this.LoaderFunction.bind(this)}
                    key={"view"}
                />
                    : null
                }
                
                {states.selectedOption === "Modify" ?
                    <ExpensePopUp
                        Modify={states.selectedOption === "Modify" ? true : false}
                        modifyData={states.modifyDetails}
                        expCategoryList={states.expCategoryList}
                        popUpClose={this.handleAddClose.bind(this)}
                        errorMessage={this.errorMessage.bind(this)}
                        successMessage={this.successMessage.bind(this)}
                        LoaderFunction={this.LoaderFunction.bind(this)}
                        getDetails = {this.getExpensesDetail.bind(this)}
                        key={"modify"}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(ExpenseBills);