import React, { Component } from 'react';
import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../../Utility/Constants';

class IpAdmissionReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            ipAdmissionData: [],
            FilterOpen: false,
            doctorNameList: localGetItem("DoctorsList") === null ? [] : JSON.parse(localGetItem("DoctorsList")),
            doctorName: [],
            SearchedPatientKey: "",
            SearchedAdmissDiag: "",
            SearchedDischaDiag: "",
        }
    }

    componentDidMount() {
        this.getIpAdmissionData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getIpAdmissionData = () => {
        let doctor_id = this.state.doctorName?.length > 0 ? this.state.doctorName?.map(element => (element.doctor_id)) : [];
        try {
            this.LoaderFunction(true);
            // RestAPIService.getAll(`${Serviceurls.FO_REPORTS_IPADMISSIION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&consultant=${doctor_id}&patient_search_key=${this.state.SearchedPatientKey}&admission_diagnosis_key=${this.state.SearchedAdmissDiag}&discharge_diagnosis_key=${this.state.SearchedDischaDiag}`)
            RestAPIService.getAll(`${Serviceurls.FO_REPORTS_IPADMISSIION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&consultant=${doctor_id}&patient_search_key=${this.state.SearchedPatientKey}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            ipAdmissionData: response.data.data
                        },()=>{
                            this.state.ipAdmissionData?.forEach((item) => {
                                item.room_ward_block = `${item.bed_number ? item.bed_number : "-"}/${item.room_number ? item.room_number : "-"}/${item.ward_name ? item.ward_name : "-"}/${item.block_name ? item.block_name : "-"}`
                              })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    IpAdmissionPrint = () => {
        try {
            let doctor_id = this.state.doctorName?.length > 0 ? this.state.doctorName?.map(element => (element.doctor_id)) : [];
            // RestAPIService.getAll(`${Serviceurls.FO_REPORTS_IPADMISSIION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&consultant=${doctor_id}&patient_search_key=${this.state.SearchedPatientKey}&admission_diagnosis_key=${this.state.SearchedAdmissDiag}&discharge_diagnosis_key=${this.state.SearchedDischaDiag}&export_type=pdf`)
            RestAPIService.getAllPrint(`${Serviceurls.FO_REPORTS_IPADMISSIION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&consultant=${doctor_id}&patient_search_key=${this.state.SearchedPatientKey}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAdmissionExcel = () => {
        try {
            let doctor_id = this.state.doctorName?.length > 0 ? this.state.doctorName?.map(element => (element.doctor_id)) : [];
            RestAPIService.excelGet(`${Serviceurls.FO_REPORTS_IPADMISSIION}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&consultant=${doctor_id}&patient_search_key=${this.state.SearchedPatientKey}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true}
                            MaxDaysCount={60} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorNameList}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('selectDoctor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Patient Name / UHID"}
                            sx={{ width: "20vw" }}
                            size='small'
                            value={this.state.SearchedPatientKey}
                            onChange={(e) => {
                                this.setState({
                                    SearchedPatientKey: e.target.value
                                })
                            }}
                        />
                    </Box>
                    {/* <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Admission Diagnosis"}
                            sx={{ width: "20vw" }}
                            size='small'
                            value={this.state.SearchedAdmissDiag}
                            onChange={(e) => {
                                this.setState({
                                    SearchedAdmissDiag: e.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Discharge Diagnosis"}
                            sx={{ width: "20vw" }}
                            size='small'
                            value={this.state.SearchedDischaDiag}
                            onChange={(e) => {
                                this.setState({
                                    SearchedDischaDiag: e.target.value
                                })
                            }}
                        />
                    </Box> */}
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                doctorName: [],
                                SearchedPatientKey: "",
                                SearchedAdmissDiag: "",
                                SearchedDischaDiag: "",
                            }, () => this.getIpAdmissionData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getIpAdmissionData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    render() {
        this.state.ipAdmissionData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_detail", headerName: t("PatientDetails"), flex: 0.21, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}><CommonPatientDetails data={row} />
                </Box>)
            },
            {
                field: "admission_date", headerName: t("Admission Details"), flex: 0.14, density: 'compact', headerAlign: "center", align: "left",
                renderCell: (params) => (
                    <Box>
                        <Typography fontSize={"0.8vw"} fontWeight={600}>{(params?.row?.doctor_name ? params?.row?.doctor_name : "-")}</Typography>
                        <Typography fontSize={"0.8vw"}>{"Date: " + (params?.row?.admission_date ? params?.row?.admission_date : "-")}</Typography>
                        <Typography fontSize={"0.8vw"}>{"Time: " + (params?.row?.admission_time ? params?.row?.admission_time : "-")}</Typography>
                    </Box>)
            },
            {
                field: 'room_ward_block', headerName: t('Bed/Room/Ward/Block'), headerClassName: 'eMed_Apt_TblHead', flex: 0.14, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.room_ward_block?.length > 16 ? <Tooltip placement="top" title={row?.room_ward_block} arrow><div>{row?.room_ward_block?.slice(0, 16) + "..."}</div></Tooltip> : row?.room_ward_block ? row?.room_ward_block : "-"}</div>)
            },
            {
                field: "discharge_date", headerName: t("Discharge Details"), flex: 0.12, density: 'compact', headerAlign: "center", align: "center",
                renderCell: (params) => (
                    params?.row?.discharge_date ?
                        <Box>
                            <Typography fontSize={"0.8vw"}>{"Date: " + (params?.row?.discharge_date ? params?.row?.discharge_date : "-")}</Typography>
                            <Typography fontSize={"0.8vw"}>{"Time: " + (params?.row?.discharge_time ? params?.row?.discharge_time : "-")}</Typography>
                        </Box> : <Typography id="eMed_Lab_techFont" >{t("Not Discharged")}</Typography>)
            },
            {
                field: "admission_diagnosis", headerName: t("Admission Diagnosis"), flex: 0.23, density: 'compact',
                renderCell: (params) => (
                    <Box component={'div'} id="eMed_Lab_techFont">
                        {params?.row?.admission_diagnosis?.length > 0 ?
                            <>
                                {params?.row?.admission_diagnosis.map((list, index) => {
                                    if (index < 2) {
                                        return <Tooltip placement='top' title={params?.row?.admission_diagnosis.map((list) => (<li style={{ fontSize: "0.8vw" }}>{list}</li>))} arrow>
                                            <li id="eMed_Lab_techFont">{list.length > 35 ? list.slice(0, 35) + "..." : list}</li>
                                        </Tooltip>
                                    }
                                })}
                                {params?.row?.admission_diagnosis.length > 2 ?
                                    <Tooltip placement='top' title={params?.row?.admission_diagnosis.map((list) => (<li style={{ fontSize: "0.8vw" }}>{list}</li>))} arrow>
                                        <div id="eMed_Lab_techFont">{"....."}</div>
                                    </Tooltip>
                                    : ""}
                            </> : "-"}
                    </Box>
                )
            },
            {
                field: "discharge_diagnosis", headerName: t("Discharge Diagnosis"), flex: 0.23, density: 'compact',
                renderCell: (params) => (
                    <Box component={'div'} id="eMed_Lab_techFont">
                        {params?.row?.discharge_diagnosis?.length > 0 ?
                            <>
                                {params?.row?.discharge_diagnosis.map((list, index) => {
                                    if (index < 2) {
                                        return <Tooltip placement='top' title={params?.row?.discharge_diagnosis.map((list) => (<li style={{ fontSize: "0.8vw" }}>{list}</li>))} arrow>
                                            <li id="eMed_Lab_techFont">{list?.length > 34 ? list?.slice(0, 34) + "..." : list}</li>
                                        </Tooltip>
                                    }
                                })}
                                {params?.row?.discharge_diagnosis.length > 2 ?
                                    <Tooltip placement='top' title={params?.row?.discharge_diagnosis.map((list) => (<li style={{ fontSize: "0.8vw" }}>{list}</li>))} arrow>
                                        <div id="eMed_Lab_techFont">{"....."}</div>
                                    </Tooltip>
                                    : ""}
                            </> : "-"}
                    </Box>
                )
            },
            {
                field: "total_bill_amount", headerName: t("Bill Amount"), flex: 0.08, type: "number", density: 'compact',
                renderCell: (params) => (<Box component={'div'} >{params?.row?.total_bill_amount ? AmountFormat(params?.row?.total_bill_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => this.IpAdmissionPrint()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.getAdmissionExcel() }}>
                            <img src={ImagePaths.ExportIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.ipAdmissionData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        density={'compact'}
                        rowHeight={100}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(IpAdmissionReport)

