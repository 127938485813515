import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';

class ServiceCensusReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {},
            srcCensusData: [],
            testConfigData: [],
            Testsearch: [],
            TestTotalCount: 0,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getSrvCensusData();
    }
    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }
    getSrvCensusData = () => {
        try {
            this.LoaderFunction(true)
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.LAB_SERVICE_CENSUS_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            srcCensusData: response.data.data ? response.data.data : [],
                            TestTotalCount: response.data?.total ? response.data?.total : {},
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                            disableBtn: false,
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                Testsearch: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            },
                                () => this.getSrvCensusData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='TestWise_Report_FilterSearch_btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getSrvCensusData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    getServCensusPrint = () => {
        try {
          this.setState({disableBtn: true})
          RestAPIService.getAllPrint(Serviceurls.LAB_SERVICE_CENSUS_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((e) => {
            this.errorMessage(e.message)
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }

    render() {
        this.state.srcCensusData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.05, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.sno ? row?.sno : "0"}</Box>)
            },
            {
                field: "description", headerName: t("Service description"), flex: 0.4,
                renderCell: ({ row }) => (<Box component={'div'}>{row?.description?.length > 20 ?
                    <Tooltip placement='top' title={row?.description} arrow><div>{row?.description.slice(0, 20) + "..."}</div></Tooltip>
                    : row?.description ? row?.description : "-"}</Box>)
            },
            {
                field: "ip_count", headerName: t("IP Count"), flex: 0.1, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.ip_count ? row?.ip_count : "0"}</Box>)
            },
            {
                field: "op_count", headerName: t("OP Count"), flex: 0.1, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.op_count ? row?.op_count : "0"}</Box>)
            },
            {
                field: "self_count", headerName: t("Self Count"), flex: 0.1, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.self_count ? row?.self_count : "0"}</Box>)
            },
            {
                field: "total_count", headerName: `${t("Total Count")}`, flex: 0.1, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.total_count ? row?.total_count : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("IP Count"), this.state.TestTotalCount?.ip_count ? this.state.TestTotalCount?.ip_count : 0, true)}
                        {AmountsCard(t("OP Count"), this.state.TestTotalCount?.op_count ? this.state.TestTotalCount?.op_count : 0, true)}
                        {AmountsCard(t("Self Count"), this.state.TestTotalCount?.self_count ? this.state.TestTotalCount?.self_count : 0, true)}
                        {AmountsCard(t("Total Count"), this.state.TestTotalCount?.total_count ? this.state.TestTotalCount?.total_count : 0, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.getServCensusPrint() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>

                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.srcCensusData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        loading={this.state.isLoader}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(ServiceCensusReport)