import React from "react";
import eMedHub from "../Assets/Logo/eMedHub.jpeg";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Logo from "../Assets/Logo/Logo.svg";
import './Signin.css';
import './Forgotpassword.css';
import { clearAllCachevalue, localClearAll, localClearItem, localGetItem, localSetItem } from "../Utility/Services/CacheProviderService";
import RestAPIService from "../Utility/Services/RestAPIService";
import { Serviceurls } from "../Utility/API/Serviceurls";
import Dialog from '../Components/Dialog';
import Loader from '../Components/Loader';
import Failed from '../Assets/Animation/Failed.gif'
import Success from '../Assets/Animation/Success.gif';
import { Trans, withTranslation } from 'react-i18next';
import ToastMsg from "../Components/ToastMsg/ToastMsg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Colors } from "../Styles/Colors";
import { ConfirmPopup } from "../Components/Common Components/CommonComponents";
class Signin extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      usernameError: '',
      usernameHelperText: '',
      passwordError: '',
      passwordHelperText: '',
      isLoader: false,
      loginSuccess: false,
      openFailed: false,
      login_details: {},
      isResetPass: false,
      isResetDays: '0',
      skipforNow: false,
    };
  }
  componentDidMount() {
      localClearAll()
  }
  validate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  getModulesAPI = () =>{
    try{
      RestAPIService.getAll(Serviceurls.GET_MODULES_LIST).
      then((response) => {
          if (response.data.status === "success") {
            this.props.history.push({pathname: '/Dashboard', state : { ModuleData : response.data.data}})
          }
          else{
            console.log(response)
          }
      }).catch((error) => {
           console.log(error.message)
      })
  }
  catch(e){
       console.log(e.message)
  }
  }

  Submit = () => {
    try {
      if (this.state.username && this.state.password) {
        localClearAll();
        this.setState({
          isLoader: true,
          login_details: { username: this.state.username, password: this.state.password }
        })
        let data = {
          user_name: this.state.username,
          password: this.state.password 
        }
        RestAPIService.create(data, Serviceurls.API_SIGN_IN)
          .then(response => {
           localStorage.clear()
            if (response.data.status == "success") {
              let reply = response.data;

              if(!reply?.data?.is_alert || this.state.skipforNow){
                localSetItem("LoginAuthToken", reply.data.token);
                localSetItem("isLoggedIn", true);
                localSetItem("LogedUserName", reply.data.user_name);
                this.props.history.push({ pathname: '/Dashboard' })
              }else{
                this.setState({
                  isResetPass: reply?.data?.is_alert,
                  isResetDays: reply?.data?.balance_days
                })
              }
              // this.props.history.push({pathname: '/Dashboard'})
              // window.location.reload()
              
              // setTimeout(()=>{this.getModulesAPI()}, 2000)
              // let service_type = reply.data.service_type
              // if(service_type == "LAB"){
              //   service_type = "Laboratory User"
              // }
              // if(service_type == "FO"){
              //   service_type = "FO"
              // }
              // let loggedInUserInfo = {
              //   login_user_name: reply.data.name,
              //   login_user_role_name: service_type,
              //   isAdmin: reply.data.is_admin_access ? reply.data.is_admin_access : false,
              //   is_lab_admin: reply.data.is_lab_admin ? reply.data.is_lab_admin : false,
              //   profile_id:reply.data.profile_id,
              //   user_id: reply.data.user_id,
              //   access: reply.data.access,
              //   is_home_menu :reply.data.employee_access ? reply.data.employee_access.is_home_menu : false,
              //   is_inventry_menu :reply.data.employee_access ? reply.data.employee_access.is_inventry_menu : false,
              //   is_purchase_menu :reply.data.employee_access ? reply.data.employee_access.is_purchase_menu : false,
              //   is_config_menu :reply.data.employee_access ? reply.data.employee_access.is_config_menu : false,
              //   is_report_menu :reply.data.employee_access ? reply.data.employee_access.is_report_menu : false,
              //   laboratory_type : reply.data.default_value ? reply.data.default_value.laboratory_type : false
              // }
              // var loginUser = JSON.stringify(loggedInUserInfo);
              // localSetItem("loggedInUserInfo", loginUser);
              // this.setState({
              //   isLoader: false,
              //   loginSuccess: true
              // });
              // setTimeout(() => {
              //   let SingleModuleUser = false;
              //   if(service_type == "FO"){
              //     if(SingleModuleUser){
              //       this.props.history.push({pathname: '/OPHome' , state:{NewLogin:true}})
              //     }else{
              //       this.props.history.push({pathname: '/Dashboard' , state:{NewLogin:true}})
              //     }
                 
              //   }else{
              //     this.props.history.push({pathname: "/MainHome" , state:{NewLogin:true}})
              //   }
              //   this.setState({
              //     loginSuccess: false,
              //     isLoader: false,
              //   })
              // }, 2000)
            } else {
              this.setState({
                isLoader: false,
                openFailed: true
              });
            }

          })
          .catch(e => {
            if (e?.response?.data?.message == "Invalid username") {
              this.setState({
                isLoader: false,
                openFailed: false,
                usernameError: 'error',
                usernameHelperText: e.response.data.message
              })
            } else if (e?.response?.data?.message == "Invalid password") {
              this.setState({
                isLoader: false,
                openFailed: false,
                passwordError: 'error',
                passwordHelperText: e.response.data.message
              })
            } else {
              this.setState({
                isLoader: false,
                usernameError: 'error',
                usernameHelperText: e?.response?.data?.message,
                isErrorMsg: true,
                isErrorMsgText: e?.response?.data?.message ? e?.response?.data?.message : e.message
            }, () => {
                setTimeout(() => {
                    this.setState({ isErrorMsg: false,  })
                }, 2000);
            })
            }

          });

      } else {
        localSetItem("isLoggedin", false);
        if (this.state.username == "") {
          this.setState({
            usernameError: 'error',
            usernameHelperText: 'Enter Valid username',
          })
        }
        else {
          this.setState({
            usernameError: "",
            usernameHelperText: "",
          })
        }
        if (this.state.password == "") {
          this.setState({
            passwordError: 'error',
            passwordHelperText: 'Enter valid password',
          })
        }
        else {
          this.setState({
            passwordError: "",
            passwordHelperText: "",
          })
        }
      }
    }
    catch (e) {
      if (e.response.data.message == "Invalid username") {
        this.setState({
          isLoader: false,
          openFailed: false,
          usernameError: 'error',
          usernameHelperText: e.response.data.message
        })
      } else if (e.response.data.message == "Invalid password") {
        this.setState({
          isLoader: false,
          openFailed: false,
          passwordError: 'error',
          passwordHelperText: e.response.data.message
        })
      } else {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: e.message
      }, () => {
          setTimeout(() => {
              this.setState({ isErrorMsg: false })
          }, 2000);
      })
      }
    }
  };
  dialogCloseFunction = () => {
    this.props.history.push(window.location.reload(), '/Laboratory') //Dashboard
    this.setState({
      loginSuccess: false,
      failedDialogBox: false
    })
  }
  failesDialogClose = () => {
    this.setState({
      openFailed: false,
    })
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: ''
    })
  }
  popClode = () => {
    this.setState({
      isResetPass: false,
      skipforNow: true,
    },()=>{this.Submit()})
  }
  resetPassword = () => { 
    this.props.history.push({pathname:'/ResetPassword', state:{userName: this.state.username}})
  }
  render() {
    const { history } = this.props
    const { t } = this.props
    return (
      <div class="total">
        <Loader loaderOpen={this.state.isLoader} />
        <div class="left">
          <img src={eMedHub} width="100%" height="100%" alt="login-img"/>
        </div>
        <Box class="eMed_Forget_Con">
        <img src={Logo} alt="logo" style={{width: '6vw', height: '6vw'}}/>
          <Box className='eMed_Forget_Box'>
            <Typography sx={{ fontSize: '1.3vw', marginTop: '1vw', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>{'Welcome To eMedHub !'}</Typography>
            <TextField
              focused
              size='small'
              error={this.state.usernameError}
              helperText={this.state.usernameHelperText}
              name="username"
              inputProps={{ emed_tid: 'username' }}
              value={this.state.username}
              sx={{ margin: '2vw 1vw 1vw 1vw' }}
              onChange={this.validate}
              label={t("userName")}
            />
            <TextField
              focused
              size='small'
              error={this.state.passwordError}
              helperText={this.state.passwordHelperText}
              value={this.state.password}
              sx={{ margin: '1vw 1vw 1vw 1vw' }}
              inputProps={{ emed_tid: 'password' }}
              name="password" onChange={this.validate}
              label={t("password")}
              type={this.state.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment:
                  <InputAdornment>
                    <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                      {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
              }} />
            <Box sx={{ margin: '0vw 2vw 1vw 2vw' }}>
              <FormControlLabel sx={{fontSize: '0.9vw', color: '#616161'}} control={<Checkbox />} label="Remember Me" />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                id="eMed_Forget_Btn"
                variant='contained'
                size='small'
                emed_tid='signin'
                onClick={() => { this.Submit() }}>{"Sign in"}</Button>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '1vw'}}>
              <Typography onClick={() => history.push('/Forgotpassword')} id='eMed_Resent_CodeText'>{"Forgot Your Password ?"}</Typography>
            </Box>
          </Box>
          {this.state.loginSuccess ? <Dialog
            type={"loginsucess"}
            DialogClose={this.dialogCloseFunction}
            header={"Message"}
            content={"Login Successfully!"}
            icon={<img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />}
            secondary={"Close"}
            closeButton={this.homeScreen} /> : null}
          {this.state.openFailed ? <Dialog
            type={"Notification"}
            DialogClose={this.failesDialogClose}
            header={"Message"}
            content={this.state.usernameHelperText}
            icon={<img className="eMed_dilboxAnimations" alt="Failed" src={Failed} />}
            secondary={"Close"}
            closeButton={this.dialogCloseFunction} /> : null}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.isSuccessMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.isSuccessMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
        {this.state.isResetPass ?
          <ConfirmPopup
          cnfTitle={'Reset Password'}
          cnfContent={this.state.isResetDays === 0 ? `Your password will expire, so please reset your password.` :`Your password will expire in ${this.state.isResetDays} days, so please reset your password.`}
          contentBold={'bold'}
          cnfAlertPopupClose={this.popClode.bind(this)}
          btnText1={'Skip for Now'}
          btnText2={'Reset Password'}
          btnType={'error'}
          btn1Hide={this.state.isResetDays === 0 ? true : false}
          cnfData={this.resetPassword.bind(this)}
        /> : null}
      </div>
    );
  }
}
export default withTranslation()(Signin);