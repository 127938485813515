import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, IconButton, Grid, Autocomplete, Typography, FormControlLabel, Checkbox } from "@mui/material";
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from "../../../Utility/Services/CacheProviderService";
class Specialization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specDetails: [],
            spcInfo: [],
            specialization_id: null,
            specialization: "",
            selectedIndex: -1,
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            disableBtn: false,
            purposeOfVisit: "",
            purposeOfVisitID: null,
            povList: [],
            isActive: true,
            editItemId: null
        }
    }
    componentDidMount = () => {
        this._getSpecializationDetails();
        this._getSpecInfo();
        let POVList = localGetItem("PurposeOfVisit") === null ? [] : JSON.parse(localGetItem("PurposeOfVisit"))
        this.setState({
            povList: POVList
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getSpecializationDetails = () => {
        this.setState({
            specDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            specDetails: response.data.data,
                        }, () => {
                            this.state.specDetails.forEach((item, i) => {
                                item.index = i
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _getSpecInfo = () => {
        this.setState({
            spcInfo: []
        })
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPLGET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            spcInfo: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    renderTextBox(label, statekey, value, options = []) {
        const { t } = this.props;
        return (
            <div id="eMed_srvConfig_divs" style={{ margin: '1vw'}}>
                <Autocomplete
                    disableClearable
                    value={value ? value : ""}
                    sx={{ width: "29.8vw" }}
                    size = "small"
                    options={options?.length != 0 ? options : []}
                    getOptionLabel={option => typeof (option) === "string" ? option : statekey === "purposeOfVisit" ? option?.reason : option?.name}
                    onChange={(event, newValue) => {
                        if(newValue){
                            if(statekey === "purposeOfVisit"){
                                this.setState({
                                    purposeOfVisit: newValue?.reason,
                                    purposeOfVisitID: newValue?.id,
                                })
                            }else{
                                this.setState({
                                    specialization: newValue?.name,
                                    specialization_id: newValue?.id
                                })
                            }
                        }
                        
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label)}
                            name={statekey}
                            InputProps={{
                                ...params.InputProps
                            }}
                        />
                    )}
                />
            </div>
        )
    }
    handleClear = () => {
        this.setState({
            specialization: "",
            specialization_id: null,
            purposeOfVisit: "",
            purposeOfVisitID: null,
            isActive: true,
            editItemId: null,
        })
    }

    handleEdit = (data) => {
        if(data?.id){
            let povData = this.state.povList?.find((item)=> item?.id === data?.op_pov_id)?.reason
            this.setState({
                editItemId: data?.id,
                specialization: data?.name,
                specialization_id: data?.name_id ? data?.name_id : null,
                purposeOfVisit: povData ? povData : '',
                purposeOfVisitID: data?.op_pov_id ? data?.op_pov_id : null,
                isActive: data?.is_active
            })
        }
      
    }

    handleInactive = (e) => {
        if(this.state.isActive !== e.target.checked){
            this.setState({ isActive: e.target.checked })
        }
    }
    
    handleSave = () => {
        const { t } = this.props
        let { specialization, specialization_id, editItemId, purposeOfVisitID } = this.state;
        let isAvl = true;
        let Message = "";
        if (!specialization) { isAvl = false; Message = "Fillmandatory" }
        if (isAvl) {
            // api call
            let datas = {
                "id": specialization_id,
                "name": specialization,
                "clinic_spec_id": editItemId ? editItemId : null,
                "op_pov_id": purposeOfVisitID ? purposeOfVisitID : null,
                "is_active": this.state.isActive
            }
            try {
                this.LoaderFunction(true)
                this.setState({disableBtn: true})
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.handleClear();
                            this._getSpecializationDetails();
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.setState({disableBtn: false})
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage(t(Message), false)
        }
    }
    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }
    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, specDetails, specialization, purposeOfVisit } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.12, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'name', headerName: t('Specialization'), flex: 0.48 },
            { field: 'purpose_of_visit', headerName: t('Purpose of Vsit'), sortable: false, flex: 0.20, renderCell: (params) =>(
                <Box component={'div'}>
                    {params?.row?.purpose_of_visit ? params?.row?.purpose_of_visit?.length > 25 ?
                        <Tooltip placement="top" title={params?.row?.purpose_of_visit}><Typography fontSize={'0.9vw'}>{params?.row?.purpose_of_visit?.slice(0, 25) + '...'}</Typography></Tooltip> :
                        <Typography fontSize={'0.9vw'}>{params?.row?.purpose_of_visit}</Typography> : '-'}
                </Box>
            )},
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: (params) => {
                    return (
                        <CommonEditButton size="1.5vw" onClick={() => { this.handleEdit(params?.row) }} />
                    )
                }
            }
        ]
        return (
            <Box  id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={specDetails}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                headerHeight = {40}
                                rowCount={specDetails.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t('Specialization')}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                {this.renderTextBox('Specialization *', "specialization", specialization, this.state.spcInfo)}
                                {this.renderTextBox('Purpose Of Visit', "purposeOfVisit", purposeOfVisit, this.state.povList)}
                                <Box sx={{ width: "14vw", margin: '1vw' }}>
                                    <FormControlLabel label="Active" labelPlacement="start" sx={{gap:'1vw'}} control={<Checkbox emed_tid={'specilization_active_checkBox'} checked={this.state.isActive ? true : false} onChange={(e) => this.setState({ isActive: e.target.checked})} />} />
                                </Box>
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration",  "Specialization", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" emed_tid="Specializaton_Save_Btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(Specialization);