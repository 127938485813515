import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import { Autocomplete, Box, Button, Checkbox, Drawer, FormControlLabel, Menu, MenuItem, Stack, TextField, Typography, IconButton, Tooltip } from '@mui/material'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { withTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { DateTime } from 'luxon'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';
class CreditServiceReports extends Component {
  constructor(props) {
    super(props)
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentYear = new Date().getFullYear()
    const minDate = dayjs(`${currentYear}-04-01`)
    this.state = {
      credit_ReportData: [],
      totalRFAmt: 0,
      page: 0,
      pageSize: 10,
      isLoader: false,
      FilterOpen: false,
      DatePickerOpen: false,
      datePicker: yesterday,
      isPatient: true,
      isCorporate: true,
      isInsurance: true,
      isOutpatient: true,
      isInpatient: true,
      isLab: true,
      minDate: minDate,
      creditType: ['Patient credit', 'Corprate credit', 'Insurance credit'],
      Department: ['Out Patient', 'In Patient', 'Laboratory'],
      isErrorMsg: false,
      isErrorMsgText: '',
      printLoad: false
    }
  }

  componentDidMount() {
    this.getcreditservices()
  }

  getcreditservices = () => {
    try {
      this.setState({ isLoader: true })
      var states = this.state
      let datepic = new Date(states.datePicker)
      let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
      RestAPIService.getAllTwo(Serviceurls.FO_REP_CRED_SERVICE + `?date=${formatDate}&dept=${states.Department}&type=${states.creditType}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({ task_id: response.data?.task_id, },
            () => {
              this.pollTaskStatus();
            })
          }
        }).catch((error) => {
          this.setState({ isLoader: false })
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ isLoader: false })
      this.errorMessage(error.message)
    }
  }

  pollTaskStatus = async () => {
    const { task_id } = this.state;
    let isTaskComplete = false;

    while (!isTaskComplete) {
      const statusData = await this.checkTaskStatus(task_id);

      if (statusData?.status === 'success') {
        isTaskComplete = true;
        this.setState({
          credit_ReportData: statusData?.data,
          totalRFAmt: statusData?.total_credit_amount,
          cash: statusData?.total_cash_refund,
          upi: statusData?.total_upi_refund,
          bank_transfer: statusData?.total_bank_refund,
          isLoader: false,
        })
      } else if (statusData?.status === 'fail') {
        isTaskComplete = true;
        this.setState({ loading: false })
        this.errorMessage( 'Prepration failed! Please try again.' )
      }

      await new Promise((resolve) => setTimeout(resolve, 5000)); // Poll every 2 seconds
    }
  };

  checkTaskStatus = async (taskId) => {
    const res = await RestAPIService.getAllTwo(Serviceurls.CELERY_DEMO_START + "?task_id=" + taskId);
    
    // Assuming `res.data` contains the data you're expecting
    const data = res?.data;
    return data; 
  };

  getcreditservicesPrint = (Detailed) => {
    try {
      var states = this.state
      let datepic = new Date(states.datePicker)
      let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
      let params = ''
      if (Detailed) {
        params = `?date=${formatDate}&dept=${states.Department}&type=${states.creditType}&export_type=pdf&mode=Detailed`
      } else {
        params = `?date=${formatDate}&dept=${states.Department}&type=${states.creditType}&export_type=pdf`
      }
      RestAPIService.getAllTwo(Serviceurls.FO_REP_CRED_SERVICE + params)
        .then((response) => {
          if (response) {
            this.setState({ task_id: response.data?.task_id, },
            () => {
              this.pollTaskReportStatus();
            })
          }
        }).catch((error) => {
          this.setState({ printLoad: false })
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }

  pollTaskReportStatus = async () => {
    const { task_id } = this.state;
    let isTaskComplete = false;

    while (!isTaskComplete) {
      const statusData = await this.checkTaskStatus(task_id);

      if (typeof(statusData) != 'object') {
        isTaskComplete = true;
        this.setState({ printLoad: false })
        const file = new Blob(
          [statusData],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else if (statusData?.status === 'fail') {
        isTaskComplete = true;
        this.setState({ printLoad: false })
        this.errorMessage( 'Prepration failed! Please try again.' )
      }

      await new Promise((resolve) => setTimeout(resolve, 5000)); // Poll every 2 seconds
    }
  };

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }
  c = () => {
    this.setState({
      FilterOpen: false,
    })
  }

  handleCheckBox = (e, key) => {
    this.state[key] = e.target.checked
    let crType = []
    if (this.state.isPatient) {
      crType.push("Patient credit")
    }
    if (this.state.isCorporate) {
      crType.push("Corprate credit")
    }
    if (this.state.isInsurance) {
      crType.push("Insurance credit")
    }
    this.state.creditType = crType
    this.setState({
      isPatient: this.state.isPatient,
      isCorporate: this.state.isCorporate,
      isInsurance: this.state.isInsurance,
      creditType: this.state.creditType
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    var states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
    let TextArray = [
      { label: "Date", value: formatDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  handleCheckBoxDept = (e, key) => {
    this.state[key] = e.target.checked
    let Dept = []
    if (this.state.isOutpatient) {
      Dept.push("Out Patient")
    }
    if (this.state.isInpatient) {
      Dept.push("In Patient")
    }
    if (this.state.isLab) {
      Dept.push("Laboratory")
    }
    this.state.Department = Dept
    this.setState({
      isOutpatient: this.state.isOutpatient,
      isInpatient: this.state.isInpatient,
      isLab: this.state.isLab,
      Department: this.state.Department
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button emed_tid={"close_TestID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' ml={'0.4vw'}>
          <Box mt={'1vw'} display={'flex'} justifyContent={'flex-end'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                name={"datePicker"}
                minDate={this.state.minDate}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                onChange={
                  (newDate) => {
                    this.setState({
                      datePicker: newDate,
                    });
                  }}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'creditservice_datepicker_testid' }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box mt={'1vw'}>
            <Box className="eMed_Filter_Header">
              <Typography fontWeight={'bold'}>{"Department"}</Typography>
            </Box>
            <Box  >
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isOutpatientChecked_TestID"} checked={this.state.isOutpatient} onChange={(e) => { this.handleCheckBoxDept(e, "isOutpatient",) }} size='small' />} label={"Out Patient"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isInpatientChecked_TestID"} checked={this.state.isInpatient} onChange={(e) => { this.handleCheckBoxDept(e, "isInpatient",) }} size='small' />} label={"In Patient"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isLabChecked_TestID"} checked={this.state.isLab} onChange={(e) => { this.handleCheckBoxDept(e, "isLab",) }} size='small' />} label={"Laboratory"} />
            </Box>
          </Box>
          <Box mt={'1vw'}>
            <Box className="eMed_Filter_Header">
              <Typography fontWeight={'bold'}>{"Credit Type"}</Typography>
            </Box>
            <Box  >
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isOutpatientChecked01_TestID"} checked={this.state.isPatient} onChange={(e) => { this.handleCheckBox(e, "isPatient",) }} size='small' />} label={"Patient"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isCorporateChecked_TestID"} checked={this.state.isCorporate} onChange={(e) => { this.handleCheckBox(e, "isCorporate",) }} size='small' />} label={"Corporate"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={"isInsurance_Testid"} checked={this.state.isInsurance} onChange={(e) => { this.handleCheckBox(e, "isInsurance",) }} size='small' />} label={"Insurance"} />
            </Box>
          </Box>

        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid={"Clear_testid"}
            onClick={() => {
              this.setState({
                datePicker: new Date().setDate(new Date().getDate() - 1),
                isPatient: true,
                isCorporate: true,
                isInsurance: true,
                isOutpatient: true,
                isInpatient: true,
                isLab: true,
                FilterOpen: false,
                creditType: ['Patient credit', 'Corprate credit', 'Insurance credit'],
              }, () => { this.getcreditservices() })
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid={"Search_testid"}
            onClick={() => { this.setState({ FilterOpen: false }, () => this.getcreditservices()) }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  closeFilter = () => {
    this.setState({
      FilterOpen: false,
    })
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "DetailedCreditServiceReport", label: "Detailed Credit Service Report" },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton emed_tid={"MoreVertIcon_testid"}
            size='small'
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon sx={{ width: '1.5vw', height: '1.5vw' }} />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            emed_tid={"DetailedCreditServiceReport_testid"}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => {
                this.setState({ anchorEl: null }, () => {
                  if (option.value === "DetailedCreditServiceReport") {
                    this.getcreditservicesPrint(true)
                  }
                })
              }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  render() {
    const { t } = this.props
    this.state.credit_ReportData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "dept", headerName: 'Department', type: 'string', flex: 0.30,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.dept ? params?.row?.dept : "-"}</Box>)
      },
      {
        field: "type", headerName: 'Credit Type', flex: 0.30, type: 'string',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.type ? params?.row?.type : "-"}</Box>)
      },
      {
        field: "count", headerName: 'Count', type: 'number', flex: 0.20,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.count ? params?.row?.count : "-"}</Box>)
      },
      {
        field: "amount", headerName: 'Amount', flex: 0.20, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.amount ? params?.row?.amount : "-"}</Box>)
      },
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='eMed_Main_Container'>
          <Box component={'div'} className='eMed_Header_Container'>
            <Box component={'div'} className='eMed_Header_Rgt_Content'>
              {AmountsCard("Total Credit Amount", this.state.totalRFAmt, false, "white", false, '', 'Refund_cancel_Report_TotalAmt')}
            </Box>
            <Box component={'div'} className='eMed_Header_Lft_Content'>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='filter_btn'
                onClick={() => this.setState({ FilterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn' disabled={this.state.printLoad}
                onClick={() => this.setState({ printLoad: true }, () => this.getcreditservicesPrint())}
              >
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
              {this.MenuItem()}
            </Box>
          </Box>
          <Box component={'div'} className='eMed_Table_Content'>
            <DataGrid
              sx={{ bgcolor: 'white' }}
              rows={this.state.credit_ReportData}
              columns={columns}
              getRowId={(row) => row.sno}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.isLoader}

            />
          </Box>
          <Drawer
            anchor={'right'}
            open={this.state.FilterOpen}
            ModalProps={{ onBackdropClick: this.closeFilter }}
          >
            {this.renderFilterScreen()}
          </Drawer>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(CreditServiceReports);