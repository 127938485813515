import React, { Component } from 'react'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { Autocomplete, Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'

export default class DocCollabDisSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      selectLayout: '',
      selectTemplate: '',
      disDateTimePicker: null,
      nextDatePicker: null,
      codeICDapi: [],
      icdCode: '',
      selecticd: null,
      disLayoutDesign: [],
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      icdADTblData: [],
      icdDDTblData: [],
      mediRoute: '',
      mediDosage: '',
      mediName: '',
      selectMEAN: '',
      selectConsumptions: '',
      mediInstruction: '',
      medicationTbl: [],
      selectDiet: '',
      selectActivity: '',
      selectDischargeProvisional: "",
      selectAdmissionProvisional: "",
      adviceDischarge: '',
      typeAnaesthesia: '',
      nameAnaesthesia: '',
      disSumTemData: [],
      selectedIndex: null,
      postDisSumData: {},
      disSumdata: {},
      reasonAdmission: '',
      getDisSumData: [],
      selectSurDate: null,
      disSumID: null,
      isFinish: false,
      isLoader: false,
      alertPop: false,
      temLayId: null,
      completePop: false,
      notifyPop: false,
      temraryID: null,
      withOutAppointment: {},
      specTxt: '',
      selectSpeciality: null,
      splItem: [],
      testData: [],
      invetigation: '',
      testgetData: [],
      attenderName: '',
      attenderMobile: '',
      consultantName: null,
      catagoryList: [],
      selectedCategory: '',
      categoryType: ''
    }
  }

  componentDidMount = () => {
    let IpPatientDetails = getCachevalue('IppatientData')
    let patientData = JSON.parse(IpPatientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if (details?.patient_id === patientData?.patient_id) {
      this.setState({
        withOutAppointment: details
      })
    }
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.GetDiagnosisData()
      this.GetProvDiagnosisData()
      setTimeout(() => {
        this.getDischargeSummary()
      }, 1000);
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  GetDiagnosisData() {
    let admission_id = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : null;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_DIAGNOSIS + "?ip_admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              icdADTblData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDischargeSummary = () => {
    try {
      var states = this.state
      let admission_id = this.state.ipPatientData?.id || this.props?.ip_admission_id || this.state.withOutAppointment?.ip_admission_id;
      RestAPIService.getAll(Serviceurls.IP_DIS_SUM_POST + '?ip_admission_id=' + admission_id + '&patient_id=' + states.ipPatientData.patient_id)
        .then(response => {
          if (response.data.status === 'success') {
            this.getSavedData(response?.data?.data?.length > 0 ? response?.data?.data : [])
            this.setState({
              getDisSumData: response.data.data ? response.data.data : [],
              attenderName: response?.data?.header_data ? response.data.header_data.attender_name : '',
              attenderMobile: response?.data?.header_data ? response.data.header_data.attender_mobile : '',
              consultantName: response?.data?.header_data ? response.data.header_data.doctor_name : '',
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  GetProvDiagnosisData() {
    let admission_id = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : null;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG + "?ip_admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              selectAdmissionProvisional: response.data.data.notes
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getSavedData = (data) => {
    if (data.length == 1) {
      var tem_json = data[0].template_data
      const inputTime = data[0].discharged_date ? new Date(data[0].discharged_date) : null;
      this.setState({
        disSumID: data[0].id,
        isFinish: data[0].is_finished,
        selectLayout: data[0].layout_id,
        selectTemplate: data[0].template_id,
        selectSurDate: data[0].date_of_surgery,
        nextDatePicker: data[0].followup_date,
        reasonAdmission: data[0].reason_for_admission,
        disDateTimePicker: inputTime,
        selectSpeciality: data[0].speciality_id,
        selectedCategory: data[0].category_id,
      }, () => {
        var states = this.state
        var data1 = states.disLayoutDesign.filter((item) => (item.id == states.selectLayout))
        var keys = {}
        data1[0]?.dis_sum_json?.forEach((element) => {
          keys[element.field_name] = tem_json[element.field_name]
        })
        var splID = states.splItem.filter((item) => item.id === states.selectSpeciality)
        if (data[0].is_finished || keys?.investigation_and_test_report?.test_report?.length > 0 ? (keys?.investigation_and_test_report?.test_report?.length - 1).id == null : null) {
          this.setState({
            postDisSumData: keys,
            icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
            selectAdmissionProvisional: tem_json.provisional_diagnosis ? tem_json.provisional_diagnosis : "",
            typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
            nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
            icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
            selectDischargeProvisional: tem_json.discharge_provisional_diagnosis ? tem_json.discharge_provisional_diagnosis : "",
            medicationTbl: keys.medication ? keys.medication : [],
            selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
            selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
            adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
            invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
            testgetData: keys.investigation_and_test_report ? keys.investigation_and_test_report.test_report : '',
            selectSpeciality: splID[0].name,
          })
        } else {
          this.setState({
            postDisSumData: keys,
            icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
            selectAdmissionProvisional: tem_json.provisional_diagnosis ? tem_json.provisional_diagnosis : "",
            typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
            nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
            icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
            selectDischargeProvisional: tem_json.discharge_provisional_diagnosis ? tem_json.discharge_provisional_diagnosis : "",
            medicationTbl: keys.medication ? keys.medication : [],
            selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
            selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
            adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
            invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
            selectSpeciality: splID[0].name,
          })
        }
      })
      this.getTemplateList()
    }
  }

  getTemplateList = () => {
    try {
      if (this.state.selectLayout) {
        RestAPIService.getAll(Serviceurls.IP_DIS_SUM_TEM + '?id=' + this.state.selectLayout)
          .then(response => {
            if (response.data.status === 'success') {
              this.setState({
                disSumTemData: response.data ? response.data.data : [],
              })
            }
          }).catch(e => {
            if (e.response?.data?.status === "fail") {
              this.errorMessage(e.response.data.message)
            } else {
              this.errorMessage(e.message)
            }
          })
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderTestTbl = () => {
    const { t } = this.props
    var states = this.state
    var arr1 = states.isFinish ? [] : states.testData
    var arr2 = states.testgetData
    var test = arr1.concat(arr2)

    var uniqueArray = [];
    var uniqueIds = new Set();

    test.forEach((obj) => {
      if (obj && obj.id && !uniqueIds.has(obj.id)) {
        uniqueArray.push(obj);
        uniqueIds.add(obj.id);
      }
    });
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Typography>{"Test Reports"}</Typography>
        <Paper elevation={0} sx={{ height: '40vh', overflow: 'scroll' }}>
          <TableContainer component={'Paper'}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell width={'10vw'}>{"S.No"}</TableCell>
                  <TableCell width={'20vw'}>{"Test Name/Test Group"}</TableCell>
                  <TableCell width={'20vw'}>{"Result"}</TableCell>
                  <TableCell width={'20vw'}>{"Tested On"}</TableCell>
                  <TableCell width={'20vw'}>{"Turn Over Time"}</TableCell>
                  {/* <TableCell width={'1vw'}>{"Action"}</TableCell> */}
                </TableRow>
              </TableHead>
              {uniqueArray?.length > 0 ? uniqueArray.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell width={'10vw'}>{index + 1}</TableCell>
                    <TableCell width={'25vw'}>{(item.lab_test_name && item.lab_set_name) ? (item.lab_test_name + ' / ' + item.lab_set_name) : item.lab_test_name ? item.lab_test_name : item.lab_set_name ? item.lab_set_name : '-'}</TableCell>
                    <TableCell> {item.is_culture ? <b style={{ fontSize: '0.8vw' }}>{"Result will be displayed in Print"}</b> : item.result?.value ? item.result?.value : '-'}</TableCell>
                    <TableCell width={'25vw'}>{item.tested_on ? item.tested_on : '-'}</TableCell>
                    <TableCell width={'25vw'}>{item.trun_over_time ? item.trun_over_time : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              )) : null}
            </Table>
            {uniqueArray?.length === 0 ?
              <Box className='eMed_Dis_Template_NoRecord'>
                <Typography >{t('NoRecordsFound')}</Typography>
              </Box> : null}
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  renderTextBoxs = (label, width, key, mantatory, isrow, is_active) => {
    let { fromBillSummary } = this.props;
    var states = this.state
    if (is_active && label != "Provisional Diagnosis" && label != "Discharge Diagnosis") {
      return (
        <Box className='eMed_DisSum_Lay_CommonBox1'>
          <TextField
            sx={{ width: width }}
            id="icd-code"
            multiline={isrow}
            rows={3}
            name={key}
            inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
            label={mantatory ? label + "*" : label}
            variant="outlined"
            value={states.postDisSumData[key] ? states.postDisSumData[key] : ''}
            onChange={(event) => {
              states.postDisSumData[key] = event.target.value
              this.setState({
                states
              })
            }}
          />
        </Box>
      )
    }
  }

  renderadmissionDiagnosis = (label, mantatory) => {
    var states = this.state
    var { icdADTblData, icdDDTblData } = this.state
    const { t, fromBillSummary } = this.props
    return (
      <Box className='eMed_DisSum_Lay_CommonBox'>
        <Box className='eMed_DisSum_Lay_AdmDig'>
          <Typography>{mantatory ? label + "*" : label}</Typography>
          <Autocomplete
            noOptionsText={states.icdCode === '' ? t('PLS') : t('NDF')}
            options={states.codeICDapi}
            getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
            onChange={(e, value) => {

              this.setState({
                testRow: value,
                selecticd: value
              })
            }}
            value={states.selecticd}
            size='small'
            className='eMed_DisSum_Icd_TxtBox'
            disablePortal
            readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
            id="combobox-demo"
            renderInput={(params) => <TextField onChange={(event) => {
              const value = event.target.value
              if (value === "" || value != "") { this.setState({ icdCode: value }, () => { this.getICDcode() }) }
            }} {...params} placeholder="Enter ICD Code / Short Description / Long Description" />}
          />
          <Button id='eMed_Config_btn' disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { label === "Admission Diagnosis" ? this.addAdmissionDig() : this.addDisDignosis() }} variant='contained'>{t('Add')}</Button>
        </Box>
        <Box sx={{ marginTop: '1vw' }}>
          <Paper elevation={0}>
            <TableContainer sx={{ minHeight: '23vh' }} component={Paper}>
              <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <TableRow>
                    <TableCell width={'5vw'}>S.No</TableCell>
                    <TableCell width={'20vw'}>ICD 10 Codes</TableCell>
                    <TableCell width={'35vw'}>Short Description</TableCell>
                    <TableCell width={'35vw'}>Long Description</TableCell>
                    <TableCell width={'5vw'}>Action</TableCell>
                  </TableRow>
                </TableHead>
                {label === "Admission Diagnosis" ? icdADTblData?.length > 0 ? icdADTblData.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell width={'5vw'}>{index + 1}</TableCell>
                      <TableCell width={'20vw'}>{item.icd_code ? item.icd_code : item.icd_codes}</TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_short_desc?.length > 40 ?
                          <Tooltip title={item.icd_short_desc} placement='top' arrow>
                            <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                      </TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_desc?.length > 40 ?
                          <Tooltip title={item.icd_desc} placement='top' arrow>
                            <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                      <TableCell width={'5vw'}>
                        <Box className='eMed_Dis_Tem_ActionBtn'>
                          <Tooltip title={'Delete'} placement='top' arrow>
                            <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => {
                              icdADTblData.splice(index, 1)
                              this.setState({
                                icdADTblData
                              })
                            }}>
                              {<img className='eMed_DisSum_Lay_StarIcon' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) : null : icdDDTblData?.length > 0 ? icdDDTblData.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell width={'5vw'}>{index + 1}</TableCell>
                      <TableCell width={'20vw'}>{item.icd_code}</TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_short_desc?.length > 40 ?
                          <Tooltip title={item.icd_short_desc} placement='top' arrow>
                            <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                      </TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_desc?.length > 40 ?
                          <Tooltip title={item.icd_desc} placement='top' arrow>
                            <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                      <TableCell width={'5vw'}>
                        <Box className='eMed_Dis_Tem_ActionBtn'>
                          <Tooltip title={'Delete'} placement='top' arrow>
                            <IconButton disabled={(states.isFinish === true || fromBillSummary) ? true : false} onClick={() => {
                              icdDDTblData.splice(index, 1)
                              this.setState({
                                icdDDTblData
                              })
                            }}>
                              {<img className='eMed_DisSum_Lay_StarIcon' src={(states.isFinish === true || fromBillSummary) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) : null}
              </Table>
              {label === "Admission Diagnosis" ? icdADTblData?.length === 0 ?
                <Box className='eMed_Dis_Template_NoRecord'>
                  <Typography >{t('NoRecordsFound')}</Typography>
                </Box> : null : icdDDTblData?.length === 0 ?
                <Box className='eMed_Dis_Template_NoRecord'>
                  <Typography >{t('NoRecordsFound')}</Typography>
                </Box> : null}
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    )
  }

  medicationTextBox = (label, value, name) => {
    var states = this.state
    let { fromBillSummary } = this.props;
    return (
      <Box >
        <TextField
          label={label}
          value={value}
          name={name}
          size='small'
          inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
          variant='outlined'
          onChange={(event) => {
            const name = event.target.name
            const value = event.target.value
            if (value != "" || value === '') {
              this.setState({
                [name]: value
              })
            }
          }}
        />
      </Box>
    )
  }

  renderDisInstruction = (mantatory) => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    return (
      <Box className='eMed_DisSum_Lay_disBox' >
        <Box className='eMed_DisSum_Lay_CommonBox'>
          <Typography>{mantatory ? 'Discharge Instruction*' : 'Discharge Instruction'}</Typography>
          <Box className='eMed_DisSum_Lay_Dietcon'>
            <TextField className={this.props.fromBillSummary ? 'eMed_Discharge_txtbox' : 'eMed_DisSum_Template_Diet'}
              autoComplete="off"
              size="small"
              label={t('Diet')}
              value={this.state.selectDiet}
              inputProps={
                { readOnly: (states.isFinish === true || fromBillSummary) ? true : false }
              }
              onChange={(event) => {
                this.setState({
                  selectDiet: event.target.value
                })
              }}
            />
            <TextField className={this.props.fromBillSummary ? 'eMed_Discharge_txtbox' : 'eMed_DisSum_Template_Diet'}
              autoComplete="off"
              size="small"
              label={t('Activity')}
              value={this.state.selectActivity}
              inputProps={
                { readOnly: (states.isFinish === true || fromBillSummary) ? true : false }
              }
              onChange={(event) => {
                this.setState({
                  selectActivity: event.target.value
                })
              }}
            />
          </Box>
          <TextField
            sx={{ width: this.props.fromBillSummary ? '84.5vw' : '87.3vw', marginTop: '1vw' }}
            id="icd-code"
            multiline
            inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
            rows={3}
            value={this.state.adviceDischarge}
            label={t('AdviceonDischarge*')}
            variant="outlined"
            onChange={(event) => {
              this.setState({
                adviceDischarge: event.target.value
              })
            }}
          />
        </Box>
      </Box>
    )
  }

  renderMedication = (mantatory) => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Typography>{mantatory ? 'Medication*' : 'Medication'}</Typography>
        <Box className='eMed_DisSum_Tem_MediTextBox'>
          {this.medicationTextBox('Route', states.mediRoute, 'mediRoute')}
          {this.medicationTextBox('Medicine Name', states.mediName, 'mediName')}
          {this.medicationTextBox('Dosage', states.mediDosage, 'mediDosage')}
          {this.medicationTextBox('Timing', states.selectMEAN, 'selectMEAN')}
          {this.medicationTextBox('Instructions', states.mediInstruction, 'mediInstruction')}
          <Box>
            <Tooltip title='Add' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.addMedication() }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledAddIcon.default : ImagePaths.AddIcon.default} alt='Add' />}
              </IconButton>
            </Tooltip>
            <Tooltip title='Clear' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.clearMedication() }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledClearIcon.default : ImagePaths.ClearIcon.default} alt='Clear' />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Paper elevation={0}>
          <TableContainer sx={{ minHeight: '23vh', marginTop: '1vw' }} component={Paper}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell width={'5vw'}>S.No</TableCell>
                  <TableCell width={'18vw'}>Route</TableCell>
                  <TableCell width={'18vw'}>Medicine Name</TableCell>
                  <TableCell width={'18vw'}>Dosage</TableCell>
                  <TableCell width={'18vw'}>Timing</TableCell>
                  <TableCell width={'18vw'}>Instruction</TableCell>
                  <TableCell width={'5vw'}>Action</TableCell>
                </TableRow>
              </TableHead>
              {states.medicationTbl?.length > 0 ? states.medicationTbl.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell width={'5vw'}>{index + 1}</TableCell>
                    <TableCell width={'18vw'}>
                      {item?.route?.length > 15 ?
                        <Tooltip title={item.route} placement='top' arrow>
                          <Typography>{item.route.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.route}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.medicinename?.length > 15 ?
                        <Tooltip title={item.medicinename} placement='top' arrow>
                          <Typography>{item.medicinename.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.medicinename}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.dosage?.length > 15 ?
                        <Tooltip title={item.dosage} placement='top' arrow>
                          <Typography>{item.dosage.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.dosage}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>{item.maen}</TableCell>
                    <TableCell width={'18vw'}>
                      {item?.instruction?.length > 20 ?
                        <Tooltip title={item.instruction} placement='top' arrow>
                          <Typography>{item.instruction.slice(0, 20) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.instruction}</Typography>}
                    </TableCell>
                    <TableCell width={'5vw'}>
                      <Box className='eMed_Dis_Tem_ActionBtn'>
                        <Tooltip title={'Eidt'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.editMedication(item, index) }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledEdit.default : ImagePaths.Edit.default} alt='Edit' />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => {
                            states.medicationTbl.splice(index, 1)
                            this.setState({
                              states,
                              mediRoute: '',
                              mediName: '',
                              mediDosage: '',
                              selectMEAN: '',
                              selectConsumptions: '',
                              mediInstruction: '',
                            })
                          }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )) : null}
            </Table>
            {states.medicationTbl?.length === 0 ?
              <Box className='eMed_Dis_Template_NoRecord'>
                <Typography >{t('NoRecordsFound')}</Typography>
              </Box> : null}
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  renderDischargeSummary = () => {
    var states = this.state
    var data = states.disLayoutDesign
    var datalabel = data.filter((item) => (item.id == states.selectLayout))
    let { fromBillSummary } = this.props;
    return (
      <Box>
        {datalabel.length === 1 ? datalabel[0].dis_sum_json.map((item) => {
          if (item.label === "Admission Diagnosis" && item.is_active === true) {
            return (
              <Box>
                {this.renderadmissionDiagnosis("Admission Diagnosis", item.is_mantatory)}
                <Box className='eMed_DisSum_Lay_CommonBox1'>
                  <TextField
                    sx={{ width: "90vw" }}
                    id="icd-code"
                    multiline={true}
                    rows={3}
                    name={"selectAdmissionProvisional"}
                    label={"Admission Provisional Diagnosis"}
                    variant="outlined"
                    value={states.selectAdmissionProvisional ? states.selectAdmissionProvisional : ""}
                    onChange={(event) => {
                      this.setState({
                        selectAdmissionProvisional: event.target.value
                      })
                    }}
                  />
                </Box>
              </Box>
            )
          } else if (item.label === "Discharge Diagnosis" && item.is_active === true) {
            return (
              <Box>
                {this.renderadmissionDiagnosis("Discharge Diagnosis", item.is_mantatory)}
                <Box className='eMed_DisSum_Lay_CommonBox1'>
                  <TextField
                    sx={{ width: "90vw" }}
                    id="icd-code"
                    multiline={true}
                    rows={3}
                    name={"selectDischargeProvisional"}
                    label={"Discharge Provisional Diagnosis"}
                    variant="outlined"
                    value={states.selectDischargeProvisional}
                    onChange={(event) => {
                      this.setState({
                        selectDischargeProvisional: event.target.value
                      })
                    }}
                  />
                </Box>
              </Box>
            )
          } else if (item.label === 'Name Anaesthetist and Type of Anaesthesia' && item.is_active === true) {
            return (
              <Box className='eMed_DisSum_Anaesthsia'>
                <TextField
                  size='small'
                  label={item.is_mantatory ? "Type of Anaesthesia*" : "Type of Anaesthesia"}
                  sx={{ width: '44vw' }}
                  id="icd-cde"
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.typeAnaesthesia}
                  onChange={(event) => {
                    this.setState({
                      typeAnaesthesia: event.target.value
                    })
                  }}
                />
                <TextField
                  size='small'
                  label={item.is_mantatory ? "Name of Anaesthetist*" : "Name of Anaesthetist"}
                  sx={{ width: '44vw' }}
                  id="icd-cde"
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.nameAnaesthesia}
                  onChange={(event) => {
                    this.setState({
                      nameAnaesthesia: event.target.value
                    })
                  }}
                />
              </Box>
            )
          } else if (item.label === "Medication" && item.is_active === true) {
            return (
              this.renderMedication(item.is_mantatory)
            )
          } else if (item.label === "Discharge Instruction" && item.is_active === true) {
            return (
              this.renderDisInstruction(item.is_mantatory)
            )
          } else if (item.label === 'Investigation and Test Report' && item.is_active === true) {
            return (
              <Box className='eMed_DisSum_Lay_CommonBox1'>
                <TextField
                  label={item.is_mantatory ? "Investigation*" : "Investigation"}
                  sx={{ width: '90vw' }}
                  id="icd-cde"
                  multiline={true}
                  rows={3}
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.invetigation}
                  onChange={(event) => {
                    this.setState({
                      invetigation: event.target.value
                    })
                  }}
                />
                {this.renderTestTbl()}
              </Box>
            )
          } else {
            return (
              this.renderTextBoxs(item.label, '90vw', item.field_name, item.is_mantatory, true, item.is_active)
            )
          }
        }) : <Box className='eMed_DisSum_Tem_Nolayout'>
          <Typography color={'#888888'}>{'Please Select the Layout and Template'}</Typography>
        </Box>}
      </Box>
    )
  }

  render() {
    return (
      <div>
        <Box component={'div'} overflow={'scroll'} height={'45.5vh'}>
          {this.renderDischargeSummary()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </div>
    )
  }
}
