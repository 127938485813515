import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Doctor_Notes_Right_Layout_JSON, Doctor_Notes_Left_Layout_JSON, Doctor_Notes_OPT_Right_Layout_JSON, Doctor_Notes_OPT_Left_Layout_JSON, Other_Doctor_Notes_Layout_JSON, Doctor_Notes_OPTHOMETRY_Right_Layout_JSON, Doctor_Notes_OPTHOMETRY_Left_Layout_JSON } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { localGetItem } from '../../../../Utility/Services/CacheProviderService'

export default class DocLayoutConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            right_Fields_data: [],
            left_Fields_data: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            drag_label: null,
            drag_label_left: null,
            tblBillData: [],
            isOPT: false,
            isOpthometry: false,
        }
    }

    componentDidMount = () => {
        let ServiceType = localGetItem("loggedInUserInfo")
        let myUser = JSON.parse(ServiceType)
        this.setState({
            isOPT: myUser.service_type === "OPT" ? true : false,
            isOpthometry: myUser.service_type === "OPTOMETRY" ? true : false,
            isENT: myUser.service_type === "ENT" ? true : false,
        }, () => { this.getLayoutConfiguration() })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    returnDefaultLayout(Side) {
        if (Side === "right") {
            if (this.state.isOPT) {
                return Doctor_Notes_OPT_Right_Layout_JSON
            } else if (this.state.isOpthometry) {
                return Doctor_Notes_OPTHOMETRY_Right_Layout_JSON
            }
            else {
                return []
            }
        } else if (Side === "left") {
            let Doctor_Notes_Layout = JSON.parse(JSON.stringify(Other_Doctor_Notes_Layout_JSON))
            if (this.state.isOPT) {
                return Doctor_Notes_OPT_Left_Layout_JSON
            } else if (this.state.isOpthometry) {
                return Doctor_Notes_OPTHOMETRY_Left_Layout_JSON
            } else if (this.state.isENT) {
                let ENTLayout = { id: Doctor_Notes_Layout?.length + 1, field_name: 'clinicExam', label: 'Clinical Examination', is_active: true }
                Doctor_Notes_Layout?.push(ENTLayout)
                return Doctor_Notes_Layout
            } else {
                return Other_Doctor_Notes_Layout_JSON
            }
        }
    }

    getLayoutConfiguration = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_LAYOUT)
                .then((response) => {
                    if (response.data.status === "success") {
                        var Data = response.data.data
                        this.setState({
                            right_Fields_data: Data?.right_data ? Data?.right_data : this.returnDefaultLayout("right") ,
                            left_Fields_data: Data?.left_data ? Data?.left_data : this.returnDefaultLayout("left") , 
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({
                            right_Fields_data: this.returnDefaultLayout("right") ,
                            left_Fields_data: this.returnDefaultLayout("left") , 
                        })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({
                            right_Fields_data: this.returnDefaultLayout("right") ,
                            left_Fields_data: this.returnDefaultLayout("left") , 
                        })
                        this.errorMessage(error.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    postLayoutConfiguration = () => {
        try {
            var states = this.state

            var data = {
                "right_data": states.right_Fields_data,
                "left_data": states.left_Fields_data
            }

            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LAYOUT)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getLayoutConfiguration()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }


    handleDragLeft = (e) => {
        this.setState({
            drag_label_left: e.currentTarget.id
        })
    }

    handleCheckClickLeft = (e) => {
        var { left_Fields_data } = this.state

        const ID = e.target.id;
        const Index = left_Fields_data.findIndex((data) => {
            return data.id == ID;
        });

        left_Fields_data[Index].is_active = e.target.checked;

        let lastChangedIndex = Index;

        for (let i = 0; i < left_Fields_data.length; i++) {
            if (i !== Index && left_Fields_data[i].is_active) {
                lastChangedIndex = i;
                break;
            }
        }

        if (left_Fields_data.every(data => !data.is_active)) {
            left_Fields_data[lastChangedIndex].is_active = true;
        }

        this.setState({
            left_Fields_data
        })
    }

    handleDropLeft = (e) => {
        var states = this.state
        const dragBox = (states.left_Fields_data).find((field) => field.label === states.drag_label_left);
        const dropBox = (states.left_Fields_data).find((field) => field.label === e.currentTarget.id);

        const matchingObject = (states?.right_Fields_data).find((item) => item.label === states?.drag_label);

        if (matchingObject) {
            this.errorMessage("Not Allow to Drag Right side values into Left")
            this.setState({
                drag_label: null
            })
        } else {
            const dragBoxID = dragBox.id;
            const dropBoxID = dropBox.id;

            const dragBoxField = dragBox.field_name;
            const dropBoxField = dropBox.field_name;

            const dragBoxCheck = dragBox.is_active;
            const dropBoxCheck = dropBox.is_active;

            // To make the default field also draggable.
            const dragBoxDefault = dragBox.is_default;
            const dropBoxDefault = dropBox.is_default;

            const newBoxState = (states.left_Fields_data).map((field) => {
                if ((!dragBoxDefault && !dropBoxDefault) || (dragBoxDefault && dropBoxDefault)) {
                    if (field.label === states.drag_label_left) {
                        field.id = dropBoxID;
                        field.field_name = dragBoxField;
                        field.is_active = dragBoxCheck;
                        field.is_default = dragBoxDefault;
                    }
                    if (field.label === e.currentTarget.id) {
                        field.id = dragBoxID;
                        field.field_name = dropBoxField;
                        field.is_active = dropBoxCheck;
                        field.is_default = dropBoxDefault;
                    }
                } else if (dropBoxID <= +this.state.mandotory_count && dragBoxID <= +this.state.mandotory_count) {
                    if (field.label === states.drag_label_left) {
                        field.id = dropBoxID;
                        field.field_name = dragBoxField;
                        field.is_active = dragBoxCheck;
                        field.is_default = dragBoxDefault;
                    }
                    if (field.label === e.currentTarget.id) {
                        field.id = dragBoxID;
                        field.field_name = dropBoxField;
                        field.is_active = dropBoxCheck;
                        field.is_default = dropBoxDefault;
                    }
                }

                return field;
            });

            const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

            this.setState({
                left_Fields_data: SortData,
                drag_label_left: null,
                drag_label: null,
            })
        }
    }

    renderDemoLayoutLeft = () => {
        let states = this.state
        return (
            <div style={{ height: "73vh", borderRight: "1px solid #D3D3D3", paddingRight: "0.5vw" }}>
                {(states.left_Fields_data)
                    .map((item, index) => {
                        return (
                            <div style={{ paddingTop: "0.4vw", paddingLeft: "0.4vw" }}
                                draggable={true}
                                onDragOver={(e) => e.preventDefault()}
                                onDragStart={this.handleDragLeft}
                                onDrop={this.handleDropLeft}
                                id={item.label}>
                                <Box border={'1px solid lightgray'} mb={'1vw'}>
                                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                                        <img className="eMed_demolayout_right2_field_img" src={ImagePaths.DragIndicator.default} alt="" />
                                        <FormControlLabel control={<Checkbox id={item.id} checked={item.is_active} onChange={this.handleCheckClickLeft} size='small' />} label={<Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{item.label}</Typography>} />
                                    </Box>
                                    <Box component={'div'} sx={{ backgroundColor: Colors.white, height: "7.5vw" }} p={'1vw'} borderBottom={'1px solid lightgray'}>
                                        <TableContainer component={'div'} sx={{ height: "15vh", overflowY: "scroll" }}>
                                            <Table aria-label="collapsible table" stickyHeader style={{ border: "1px solid #B9B7BD" }}>
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell sx={{ width: '25vw', textAlign: "center" }} className="eMed_ABill_Head">{'Heading 1'}</TableCell>
                                                        <TableCell sx={{ width: '25vw', textAlign: "center" }} className="eMed_ABill_Head">{"Heading 2"}</TableCell>
                                                        <TableCell sx={{ width: '25vw', textAlign: "center" }} className="eMed_ABill_Head">{"Heading 3"}</TableCell>
                                                        <TableCell sx={{ width: '25vw', textAlign: "center" }} className="eMed_ABill_Head">{"Action"}</TableCell>
                                                    </TableRow>
                                                    <div style={{ height: "2.3vw" }}></div>
                                                </TableHead>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </div>
                        )
                    })}
            </div>
        )
    }

    handleDrag = (e) => {
        this.setState({
            drag_label: e.currentTarget.id
        })
    }

    handleCheckClick = (e) => {
        var { right_Fields_data } = this.state

        const ID = e.target.id;
        const Index = right_Fields_data.findIndex((data) => {
            return data.id == ID;
        });

        right_Fields_data[Index].is_active = e.target.checked;

        let lastChangedIndex = Index;

        for (let i = 0; i < right_Fields_data.length; i++) {
            if (i !== Index && right_Fields_data[i].is_active) {
                lastChangedIndex = i;
                break;
            }
        }

        if (right_Fields_data.every(data => !data.is_active)) {
            right_Fields_data[lastChangedIndex].is_active = true;
        }

        this.setState({
            right_Fields_data
        })
    }

    handleDrop = (e) => {
        var states = this.state
        const dragBox = (states.right_Fields_data).find((field) => field.label === states.drag_label);
        const dropBox = (states.right_Fields_data).find((field) => field.label === e.currentTarget.id);

        const matchingObject = (states?.left_Fields_data).find((item) => item.label === states?.drag_label_left);

        if (matchingObject) {
            this.errorMessage("Not Allow to Drag Left side values into Right")
            this.setState({
                drag_label_left: null
            })
        } else {

            const dragBoxID = dragBox.id;
            const dropBoxID = dropBox.id;

            const dragBoxField = dragBox.field_name;
            const dropBoxField = dropBox.field_name;

            const dragBoxCheck = dragBox.is_active;
            const dropBoxCheck = dropBox.is_active;

            // To make the default field also draggable.
            const dragBoxDefault = dragBox.is_default;
            const dropBoxDefault = dropBox.is_default;

            const newBoxState = (states.right_Fields_data).map((field) => {
                if ((!dragBoxDefault && !dropBoxDefault) || (dragBoxDefault && dropBoxDefault)) {
                    if (field.label === states.drag_label) {
                        field.id = dropBoxID;
                        field.field_name = dragBoxField;
                        field.is_active = dragBoxCheck;
                        field.is_default = dragBoxDefault;
                    }
                    if (field.label === e.currentTarget.id) {
                        field.id = dragBoxID;
                        field.field_name = dropBoxField;
                        field.is_active = dropBoxCheck;
                        field.is_default = dropBoxDefault;
                    }
                } else if (dropBoxID <= +this.state.mandotory_count && dragBoxID <= +this.state.mandotory_count) {
                    if (field.label === states.drag_label) {
                        field.id = dropBoxID;
                        field.field_name = dragBoxField;
                        field.is_active = dragBoxCheck;
                        field.is_default = dragBoxDefault;
                    }
                    if (field.label === e.currentTarget.id) {
                        field.id = dragBoxID;
                        field.field_name = dropBoxField;
                        field.is_active = dropBoxCheck;
                        field.is_default = dropBoxDefault;
                    }
                }

                return field;
            });

            const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

            this.setState({
                right_Fields_data: SortData,
                drag_label_left: null,
                drag_label: null
            })
        }
    }


    renderDemoLayoutRight = () => {
        let states = this.state
        return (
            <div style={{ height: "73vh" }}>
                {(states.right_Fields_data)
                    .map((item, index) => {
                        return (
                            <div style={{ paddingTop: "0.4vw", paddingRight: "0.4vw" }}
                                draggable={true}
                                onDragOver={(e) => e.preventDefault()}
                                onDragStart={this.handleDrag}
                                onDrop={this.handleDrop}
                                id={item.label}>
                                <Box border={'1px solid lightgray'} mb={'1vw'}>
                                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                                        <img className="eMed_demolayout_right2_field_img" src={ImagePaths.DragIndicator.default} alt="" />
                                        <FormControlLabel control={<Checkbox id={item.id} checked={item.is_active} onChange={this.handleCheckClick} size='small' />} label={<Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{item.label}</Typography>} />
                                    </Box>
                                    <Box component={'div'} sx={{ backgroundColor: Colors.white, height: item.label === "Vitals" ? "16vw" : "4vw", display: "flex", alignItems: "center", justifyContent: 'center' }} p={'1vw'} borderBottom={'1px solid lightgray'}>
                                        <div style={{ color: "#B9B7BD", fontSize: "0.85vw", fontWeight: 600 }}>Add Content Here</div>
                                    </Box>
                                </Box>
                            </div>
                        )
                    })}
            </div>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        return (
            <Box component={"div"} className='eMed_refdoc_container'>
                <div>
                    <Grid container spacing={1} style={{ height: "73vh", zIndex: 10, overflow: "scroll", paddingLeft: "0.5vw", paddingRight: "0.5vw" }} >
                        <Grid item xs={9}>
                            {this.renderDemoLayoutLeft()}
                        </Grid>
                        <Grid item xs={3}>
                            {this.renderDemoLayoutRight()}
                        </Grid>
                    </Grid>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "white", height: "2.7vw", zIndex: 99, paddingTop: "0.25vw" }}>
                        <Button variant='outlined' size="small" id="eMed_Config_btn" sx={{ mr: '0.5vw' }} onClick={() => {
                            this.setState({
                                right_Fields_data: this.returnDefaultLayout("right"),
                                left_Fields_data: this.returnDefaultLayout("left")
                            })
                        }} >{"RESET LAYOUT"}</Button>
                        <Button variant='contained' size="small" id="eMed_Config_btn" sx={{ ml: '0.5vw' }} onClick={() => { this.postLayoutConfiguration() }}>{"SAVE LAYOUT"}</Button>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}