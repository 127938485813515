import React, { Component } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
    Box, Paper, Stack, Button, Typography, Tooltip, TableCell,
    TableContainer, Table, TableHead, TableBody, TableRow
} from '@mui/material';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import { CommonPopUp } from "../../../Components/Common Components/CommonComponents";

export default class TestHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            HistoryData: [],
            isViewHistory: false,
            viewCultureData: [],
            testName: "",
        }
    }

    componentDidMount() {
        this.GetHistoryDataList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetHistoryDataList() {
        try {
            let result_summary_id = this.props?.PatientDetails?.result_summary_id ? this.props?.PatientDetails?.result_summary_id : this.props?.PatientDetails?.id;
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_TEST_HISTORY + "?patient_id=" + this.props?.PatientDetails?.patient_id + "&result_summary_id=" + result_summary_id).
                then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data?.length > 0 ? response?.data?.data?.map((item, index) => {
                            let resultValue = '';
                            if (item?.result) {
                                if (Array.isArray(item.result.value) && item.method === "Culture Test Result") {
                                    resultValue = 'Culture Test';
                                } else if (item?.result?.value === null || item?.result?.value) {
                                    resultValue = item?.result?.value;
                                } else {
                                    resultValue = item?.result;
                                }
                            }

                            return {
                                ...item,
                                result_value: resultValue,
                                sno: index + 1,
                            };
                        }) : [];
                        this.setState({
                            HistoryData: Data
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    PopUpClose = () => {
        this.setState({
            isViewHistory: false,
            viewCultureData: [],
            testName: "",
        })
    }

    viewCultureTest = (data) => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "47vh",
                overflowY: "auto",
            }}
            >
                {data?.map((items, indexs) => {
                    return (
                        <div key={indexs} style={{ width: "70vw", marginBottom: "1vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "1vw", marginBottom: "1vw" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Box component={'div'} sx={{ display: "flex" }}>
                                        <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                            {items?.bacteria ? items?.bacteria?.length > 20 ?
                                                <Tooltip placement='top' title={items?.bacteria} arrow>
                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                        {items?.bacteria.slice(0, 20) + '...'}
                                                    </Typography>
                                                </Tooltip> :
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                                        </Typography>
                                    </Box>
                                </div>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`MICROSCOPY : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.microscopy ? items?.microscopy?.length > 20 ?
                                            <Tooltip placement='top' title={items?.microscopy} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.microscopy.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`COLONY COUNT : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.colony_count ? items?.colony_count?.length > 20 ?
                                            <Tooltip placement='top' title={items?.colony_count} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.colony_count.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`METHOD : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.method_type ? items?.method_type?.length > 20 ?
                                            <Tooltip placement='top' title={items?.method_type} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.method_type.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                                    </Typography>
                                </Box>
                            </div>
                            <TableContainer style={{ maxHeight: "35vh" }}>
                                <Table style={{ border: "1px solid gray" }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"SNo"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Antibiotic"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"MIC Result/Range"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Zone Result/Range"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Interpretaion"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Efficiency Ration"}</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.antibacteria?.length > 0 ?
                                            items?.antibacteria?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.antibiotic} arrow>
                                                                <Typography>
                                                                    {item?.antibiotic.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.antibiotic}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.mic_break_point} arrow>
                                                                    <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                                                </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                                            {item?.mic_break_point_std ?
                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.mic_break_point_std} arrow>
                                                                        <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                                                    </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.zone_size} arrow>
                                                                    <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                                                </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                                            {item?.zone_size_std ?
                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.zone_size_std} arrow>
                                                                        <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                                                    </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.interpretation ? item?.interpretation?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.interpretation} arrow>
                                                                <Typography>
                                                                    {item?.interpretation.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.interpretation}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.methodThreeBacteriaResult} arrow>
                                                                <Typography>
                                                                    {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : <TableRow>
                                                <TableCell align="center" colSpan={6}>No Records To Be Shown</TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const columns = [
            { field: "sno", flex: 0.05, headerName: "S No", headerAlign: "center", align: "center", },
            {
                field: "test_date", flex: 0.2, headerName: "Bill Date & Time", headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.test_date ? row?.test_date : "-"}</Box>)
            },
            {
                field: "test_name", flex: 0.3, headerName: "Test Name", headerAlign: "left", align: "left",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.test_name?.length > 80 ?
                    <Tooltip placement='top' title={row?.test_name} arrow><div>{row?.test_name.slice(0, 80) + "..."}</div></Tooltip>
                    : row?.test_name ? row?.test_name : "-"}</Box>)
            },
            {
                field: "result_value", flex: 0.2, headerName: "Result",
                renderCell: ({ row }) => (
                    (row?.result_value === "Culture Test" && row?.method === "Culture Test Result" && row?.result?.value?.length > 0) ?
                        <Button size="small" variant="contained" sx={{textTransform: "capitalize"}}
                            onClick={() => {
                                this.setState({
                                    isViewHistory: true,
                                    viewCultureData: row?.result?.value?.length > 0 ? row?.result?.value : [],
                                    testName: row.test_name,
                                })
                            }}>{"View Culture History"}</Button>:
                            (row?.method === "Graph Based Result" && row?.result?.value?.length > 0) ?
                            <Typography>Graph Based Test</Typography>
                        :
                        <Box component={'div'}>{row?.result_value?.length > 45 ?
                            <Tooltip placement='top' title={row?.result_value} arrow><div>{row?.result_value.slice(0, 45) + "..."}</div></Tooltip>
                            : row?.result_value ? row?.result_value
                                : (row?.status === "Result Completed" || row?.status === "Result Delivered") ?
                                    "Result Not Entered"
                                    : "Result yet to complete"}</Box>)
            },
            {
                field: 'comments', headerName: 'Comments', flex: 0.2,
                renderCell: (params) => (<Box component={'div'}>
                  {params?.row?.result?.comment ? params?.row?.result?.comment?.length > 25 ?
                    <Tooltip placement='top' title={params?.row?.result?.comment}><Typography>{params?.row?.result?.comment?.slice(0, 25) + '...'}</Typography></Tooltip> :
                    <Typography>{params?.row?.result?.comment}</Typography> : '-'}
                </Box>)
              },
        ]
        return (
            <Box>
                <Box component={"div"} className='eMed_Table_Container' marginTop={"1vw"}>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.HistoryData}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {"No Records Found"}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                {
                    this.state.isViewHistory ?
                        <CommonPopUp width={"75vw"}
                            backgroundColor={Colors.white}
                            popUpClose={this.PopUpClose.bind(this)}
                            title={this.state.testName}
                            component={this.viewCultureTest.bind(this, this.state.viewCultureData)} />
                        : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
