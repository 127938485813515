import { Autocomplete, Box, Button, Chip, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, Divider, FormControl, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { Search } from '@material-ui/icons'
import ShowComponents from './ShowComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default class DocDiagonisis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: this.props.clinicId,
      patientId: this.props.patientId,
      appointmentId: this.props.appointmentId,
      openPopUp: false,
      postLoading: false,

      diagnosisIcdList: [],
      shortIcd: '',
      longIcd: '',
      icd: '',
      selectedIcd: null,
      provisional_diagnosis: '',
      diagonisData: [],
      diagonisDataList: [],
      deletePopup: false,
      removeData: false,
      isLocked: false,
      selectedId: null,

      icdSearchData: [],
      searchedIcdCode: null,
      searchedShortIcd: null,
      editProvisional: false,
      get_provisional_diagnosis: '',

      ICDdatas: [],
      FrequentData: [],
      selecticd: null,
      SearchBy: "desc"
    }
  }

  componentDidMount() {
    // let clinicId = getCachevalue('SelectedDoctorClinic')
    // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    // this.setState({
    //   clinicId: clinicId,
    //   appointmentId: appointmentId?.appointment_id,
    //   patientId: appointmentId?.patient_id,
    //   isLocked: appointmentId?.is_locked
    // }, () => {
    //   this.getDiagonisData()
    // })
    // this.apiCalls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  apiCalls = () => {
    this.getDiagonisData()
    // this.getDiagonisICDData()
    this.GetICDFrequents()
  }

  // getDiagonisICDData = () => {
  //   try {
  //     RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET)
  //       .then((response) => {
  //         if (response.data.status === 'success') {
  //           this.setState({
  //             diagnosisIcdList: response.data.data
  //           })
  //         }
  //       }).catch((error) => {
  //         if (error?.response?.data?.message) {
  //           this.errorMessage(error.response.data.message)
  //         } else {
  //           this.errorMessage(error.message)
  //         }
  //       })
  //   } catch (error) {
  //     this.errorMessage(error.message)
  //   }
  // }

  GetICDFrequents() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              FrequentData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDiagonisData = () => {
    try {
      let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              diagonisData: response.data.data?.icd_codes,
              diagonisDataList: JSON.parse(JSON.stringify(response.data.data?.icd_codes)),
              get_provisional_diagnosis: response.data.data?.provisional_diagnosis ? response.data.data?.provisional_diagnosis : '',
              provisional_diagnosis: response.data.data?.provisional_diagnosis ? response.data.data?.provisional_diagnosis : ''
            }, () => {
              if (this.state.get_provisional_diagnosis) {
                this.setState({
                  editProvisional: true
                })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getIcdCodeSearch = (searchKey, searchValue) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_REGISTRY_ICD_SEARCH + `?${searchKey}=${searchValue}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              icdSearchData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postDiagonisData = (FromProvDiag = false) => {
    try {
      let IcdList = []
      this.state.diagonisData.forEach((item) => {
        if (!item?.id) {
          IcdList.push(item?.icd_code)
        }
      })
      let data = {
        appointment_id: this.props.appointmentId,
        // icd_codes: this.state.searchedIcdCode?.icd_code ? [this.state.searchedIcdCode?.icd_code] : this.state.icd ? [this.state.icd] : [],
        icd_codes: IcdList,
        patient_id: this.props.patientId,
        id: this.state.selectedId ? this.state.selectedId?.id : null
      }
      if (FromProvDiag) { data["provisional_diagnosis"] = this.state.provisional_diagnosis }
      this.setState({ postLoading: true })
      if ((!FromProvDiag && IcdList.length > 0) || FromProvDiag) {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.onClearHandler(true)
              this.getDiagonisData()
              this.setState({ postLoading: false, openPopUp: false, selectedId: null, selecticd: null, diagonisData: JSON.parse(JSON.stringify(this.state.diagonisDataList)) })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Diagnosis not Added ")
        this.setState({ postLoading: false })
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  deleteDiagonisData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT + '?id=' + this.state.selectedId?.id, data)
        .then((response) => {
          if (response?.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ removeData: false, deletePopup: false })
            this.getDiagonisData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearHandler = () => {
    try {
      this.setState({
        shortIcd: '',
        longIcd: '',
        icd: '',
        selectedIcd: null,
        provisional_diagnosis: '',
        searchedIcdCode: '',
        searchedShortIcd: '',
        selecticd: null,
        SearchBy:'desc'
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false,
        selectedId: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          deletePopup: true,
          selectedId: item
        })
      } else {
        this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let check = null;
      this.state.diagnosisIcdList?.forEach(element => {
        if ((element?.icd_short_desc === this.state.searchedShortIcd?.icd_short_desc)) {
          check = 'Short description already exist'
        } else if (element?.icd_code === this.state.searchedIcdCode?.icd_code) {
          check = 'ICD code already exists'
        }
      })
      return check;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderDiagnosisTable = () => {
    try {
      let states = this.state
      return (
        <Box component={'div'} m={'0.5vw'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
          <Box sx={{ display: 'flex', justifyContent: "flex-end", marginX: "1vw", marginY: "0.3vw" }}>
            <Button className='Common_Btn_Min_Width'
              sx={{ height: '2vw', width: "5vw", textTransform: 'capitalize' }}
              disabled={this.props.isLocked || this.state.postLoading}
              variant='contained'
              onClick={() => { this.setState({ openPopUp: true }) }}
            >Add
            </Button>
          </Box>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={'25%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>ICD Code</TableCell>
                <TableCell width={'32%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Short Description</TableCell>
                <TableCell width={'32%'} sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Long Description</TableCell>
                <TableCell width={'11%'} align='center' sx={{ backgroundColor: Colors.Background, color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                states.diagonisDataList?.length > 0 ? states.diagonisDataList?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.icd_code ? item?.icd_code : '-'}</TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.short_desc ? item?.short_desc?.length > 30 ?
                      <Tooltip placement='top' title={item?.short_desc}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.short_desc?.slice(0, 30) + '...'}</div></Tooltip> :
                      item?.short_desc : '-'}</TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.desc ? item?.desc?.length > 30 ?
                      <Tooltip placement='top' title={item?.desc}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.desc?.slice(0, 30) + '...'}</div></Tooltip> :
                      item?.desc
                      : '-'}</TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>
                      <Box component={'div'} display={'flex'}>
                        <Button className='Common_Btn_Min_Width'
                          onClick={() => this.onDeleteHandler(item)}
                          disabled={this.props.isLocked}
                        >
                          {this.state.isLocked || this.props.isLocked ?
                            <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                            :
                            <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                          }
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell />
                    <TableCell align='center'>No Records To be Shown</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteFrequencysugg = () => {
    try {
      this.setState({ removeData: true })
      RestAPIService.delete(`${Serviceurls.DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET}?icd_code=${this.state.SelectedDeleteChip?.icd_code}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.GetICDFrequents()
            this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false, deletePopup: false, SelectedDeleteChip: null })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false, deletePopup: false, SelectedDeleteChip: null })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  renderPopUpScreen = () => {
    let states = this.state
    try {
      return (
        <Box>
          <Modal open={this.state.openPopUp}>
            <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "80vw", height: "80vh" }}>
              <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diagnosis</Typography>
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => this.setState({ openPopUp: false, isEdit: false, updateId: "", diagonisData: JSON.parse(JSON.stringify(this.state.diagonisDataList)) }, () => { this.onClearHandler(true) })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box flex={0.6} height={"72vh"} overflow={'auto'}>
                  <Box height={"65vh"} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell width={'15%'} sx={{ color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>ICD Code</TableCell>
                          <TableCell width={'32%'} sx={{ color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Short Description</TableCell>
                          <TableCell width={'42%'} sx={{ color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Long Description</TableCell>
                          <TableCell width={'11%'} align='center' sx={{ color: Colors.SecondaryText, fontWeight: '600', fontSize: '0.9vw' }}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          states.diagonisData?.length > 0 ? states.diagonisData?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.icd_code ? item?.icd_code : '-'}</TableCell>
                              <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.short_desc ? item?.short_desc?.length > 30 ?
                                <Tooltip placement='top' title={item?.short_desc}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.short_desc?.slice(0, 30) + '...'}</div></Tooltip> :
                                item?.short_desc : '-'}</TableCell>
                              <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>{item?.desc ? item?.desc?.length > 40 ?
                                <Tooltip placement='top' title={item?.desc}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.desc?.slice(0, 40) + '...'}</div></Tooltip> :
                                item?.desc
                                : '-'}</TableCell>
                              <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw' }}>
                                <Box component={'div'} display={'flex'}>
                                  <Button className='Common_Btn_Min_Width'
                                    onClick={() => {
                                      if (item?.id) {
                                        this.onDeleteHandler(item)
                                      } else {
                                        let NewArray = this.state.diagonisData
                                        NewArray.splice(index, 1)
                                        this.setState({
                                          diagonisData: NewArray
                                        })
                                      }
                                    }}
                                    disabled={this.props.isLocked}
                                  >
                                    {this.state.isLocked || this.props.isLocked ?
                                      <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                      :
                                      <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                    }
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )) :
                            <TableRow>
                              <TableCell />
                              <TableCell align='center'>No Records To be Shown</TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                    <Button disabled={this.props.isLocked || this.state.postLoading} size='small' variant='contained' sx={{ textTransform: "capitalize" }} onClick={() => { this.postDiagonisData() }}>Save & Exit</Button>
                  </Box>
                </Box>
                <Box flex={0.4} height={"72vh"} overflow={'auto'}>
                  <Box height={"65vh"} overflow={'auto'}>
                    <Autocomplete
                      noOptionsText={states.IcdSearchKey === '' ? 'Please Search...' : 'No Data Found...'}
                      options={states.icdSearchData}
                      getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
                      onChange={(e, value) => {
                        this.setState({
                          testRow: value,
                          selecticd: value,
                          icdSearchData: []
                        }, () => {
                          if (this.state.selecticd && !this.state.FrequentData.some((list) => (list?.icd_code === this.state.selecticd?.icd_code))) {
                            this.setState({
                              FrequentData: [...this.state.FrequentData, this.state.selecticd]
                            })
                          }
                        })
                      }}
                      value={states.selecticd}
                      size='small'
                      className='eMed_DocNotes_icd_TxtBox'
                      disablePortal
                      id="combobox-demo"
                      renderInput={(params) => <TextField onChange={(event) => {
                        const value = event.target.value
                        if (value === "" || value != "") { this.setState({ IcdSearchKey: value }, () => { this.getIcdCodeSearch(this.state.SearchBy, this.state.IcdSearchKey) }) }
                      }} {...params} placeholder={this.state.SearchBy === "icd_code" ? "Enter ICD Code" : "Enter Description"} />}
                    />
                    <FormControl sx={{ marginLeft: "1vw" }}>
                      <RadioGroup row
                        value={this.state.SearchBy}
                        onChange={(e) => {
                          this.setState({ SearchBy: e.target.value })
                        }}
                      >
                        <FormControlLabel value="icd_code" control={<Radio size='small' />} label="Search by ICD Code" />
                        <FormControlLabel value="desc" control={<Radio size='small' />} label="Search by Description" />
                      </RadioGroup>
                    </FormControl>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>ICD Codes</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                      {
                        this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                          <Chip
                            size='small'
                            className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            key={index}
                            label={item.icd_code}
                            variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                            onClick={() => { this.setState({ selecticd: item }) }}
                            onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                          />
                        )) : null
                      }
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Short Description</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                      {
                        this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                          <Chip
                            size='small'
                            className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            key={index}
                            label={item.icd_short_desc}
                            variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                            onClick={() => { this.setState({ selecticd: item }) }}
                            onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                          />
                        )) : null
                      }
                    </Box>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ backgroundColor: Colors.Background, height: "2.5vw" }}
                      >
                        <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                          <Typography fontSize={'0.9vw'} fontWeight={600}>Long Term Description</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box component={'div'} p={'0.5vw'}>
                          <Box className="eMed_DocNotes_FreqChips_Wrapp">
                            {
                              this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                  size='small'
                                  className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                  key={index}
                                  label={item.icd_desc}
                                  variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                  onClick={() => { this.setState({ selecticd: item }) }}
                                  onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                />
                              )) : null
                            }
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Long Description</Typography></Divider> */}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center", marginTop: "1vw" }}>
                    <Button className='Common_Btn_Min_Width'
                      sx={{ height: '2vw', width: "5vw", textTransform: 'capitalize' }}
                      disabled={this.props.isLocked || this.state.postLoading}
                      variant='outlined'
                      onClick={() => this.onClearHandler()}
                    >Clear
                    </Button>
                    <Button className='Common_Btn_Min_Width'
                      sx={{ height: '2vw', width: "5vw", marginLeft: '1vw', textTransform: 'capitalize' }}
                      disabled={this.state.selecticd === null}
                      variant='contained'
                      onClick={() => {
                        let NewArray = this.state.diagonisData
                        if (NewArray.some((list) => list.icd_code === this.state.selecticd?.icd_code)) {
                          this.errorMessage("Diagnosis Already Added")
                        } else {
                          NewArray.push({
                            "icd_code": this.state.selecticd?.icd_code,
                            "short_desc": this.state.selecticd?.icd_short_desc,
                            "desc": this.state.selecticd?.icd_desc
                          })
                        }

                        this.setState({
                          diagonisData: NewArray,
                          selecticd: null
                        })
                      }}
                    >Add
                    </Button>
                  </Box>

                </Box>
              </Box>

            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} height={'2.5vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontWeight={600}>Diagnosis</Typography>
          </Box>
          <Box component={'div'}>
            {this.renderDiagnosisTable()}
          </Box>
          <Box component={'div'} m={'0.5vw'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.5vw'}>
              <Typography fontWeight={600}>Provisional Diagnosis</Typography>
              {
                this.state.editProvisional ?
                  <Button className='Common_Btn_Min_Width'
                    sx={{ mr: '1vw' }}
                    onClick={() => this.setState({ editProvisional: false })}
                    disabled={this.props.isLocked}
                  >
                    {this.props.isLocked ?
                      <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                      :
                      <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                    }
                  </Button> :
                  <Button className='Common_Btn_Min_Width'
                    disabled={this.props.isLocked || this.state.postLoading}
                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                    variant='contained'
                    onClick={() => this.postDiagonisData(true)}
                  >Add
                  </Button>
              }
            </Box>
            <TextField
              multiline
              rows={3}
              fullWidth
              disabled={this.props.isLocked}
              value={this.state.provisional_diagnosis}
              InputProps={{ readOnly: this.state.editProvisional }}
              onChange={(e) => {
                let value = e.target.value
                if (value.length <= 250 || value === '') {
                  this.setState({
                    provisional_diagnosis: value
                  })
                } else {
                  this.errorMessage('Allowed only 250 characters')
                }
              }}
            />
          </Box>
          {
            this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
          {
            this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
          {
            this.state.deletePopup ? <DeletePopup
              DeletTitle={this.state.SelectedDeleteChip ? `Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip?.icd_code} from suggestion ?` : `Are you sure you want to delete - ${this.state.selectedId?.icd_code} ?`}
              deleteAlertPopupClose={this.popupClose.bind(this)}
              removeData={this.state.SelectedDeleteChip ? this.deleteFrequencysugg.bind(this) : this.deleteDiagonisData.bind(this)}
              disable={this.state.removeData}
            /> : null
          }
          {this.renderPopUpScreen()}
        </Box >
      </ShowComponents>
    )
  }
}
