import React, { Component } from 'react'
import ToastMsg from '../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tooltip, Typography, Stack, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Colors } from '../../Styles/Colors';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import PackageDeatailPop from '../../Components/Common Components/PackageDeatailPop';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import { DeletePopup } from '../../Components/Common Components/CommonComponents';

class OtIpRequestPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            IpRequestList: [],
            PackageDetailPop: false,
        }
    }

    componentDidMount = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            ClinicID: myUser?.clinic_id
        })
        this.getIpRequestList()
    }

    getIpRequestList = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.OT_IP_REQUESTLIST)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            IpRequestList: response.data?.data?.length > 0 ? response.data.data : [],
                        }, () => {
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                });
        }
        catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({ disableBtn: false })
        }

    }

    postIpRequest = (packageData) => {
        try {
            let data = {}
            if (packageData) { data = packageData }
            else {
                data = {
                    ip_admission_id: this.state.SelectedPatientDetails?.id ? this.state.SelectedPatientDetails?.id : null,
                    status: "Cancelled"
                }
            }
            RestAPIService.create(data, Serviceurls.OT_IP_REQUESTLIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMsgFunc(response.data.message)
                        this.setState({ PackageDetailPop: false, deletePopup: false })
                        this.props.onClickClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMsgFunc(error?.response?.data?.message)
                    } else {
                        this.errorMsgFunc(error?.message)
                    }
                })

        } catch (e) {
            this.errorMsgFunc(e?.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }
    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    closePopup = () => {
        this.setState({
            PackageDetailPop: false,
        })
    }

    popupClose = () => {
        this.setState({
            deletePopup: false,
        })
    }

    ErrorToast(data) {
        this.errorMsgFunc(data)
    }

    SuccessToast(data) {
        this.successMsgFunc(data)
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>IP Request</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt="close"
                        />
                    </div>
                </div>
                {this.state.IpRequestList && this.state.IpRequestList?.length > 0 ?
                    <div className='eMed_App_DataView' style={{ width: '100%', height: '52dvh' }}>
                        <Box sx={{ overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Patient Details</TableCell>
                                        <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Bed Details</TableCell>
                                        <TableCell align='center' sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.IpRequestList?.length > 0 ? this.state.IpRequestList?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw" }}>
                                                <div>
                                                    <div style={{ fontSize: "0.9vw" }}>{`${item?.patient_name ? `${item?.patient_title ? item?.patient_title : ""} ${item?.patient_name}` : ""}`}</div>
                                                    <div style={{ fontSize: "0.75vw" }}>{`${item?.mobile_number ? item?.mobile_number : "-"} | ${item?.uhid ? item?.uhid : "-"} `}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: "0.9vw" }}>
                                                <div>
                                                    <div style={{ fontSize: "0.9vw" }}>{`${item?.patient_name ? `${item?.patient_title ? item?.patient_title : ""} ${item?.patient_name}` : ""}`}</div>
                                                    <div style={{ fontSize: "0.75vw" }}>{`${item?.bed_number ? item?.bed_number : "-"} | ${item?.room_number ? item?.room_number : "-"} | ${item?.ward_name ? item?.ward_name : "-"} | ${item?.floor_number ? item?.floor_number : "-"} | ${item?.block_name ? item?.block_name : "-"}`}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Box component={'div'} display={'flex'} flexDirection={"row"} justifyContent={'center'}>
                                                    <Tooltip title={t("Create Appointment")} arrow placement='top'>
                                                        <IconButton onClick={() => {
                                                            this.setState({ PackageDetailPop: true, SelectedPatientDetails: item })
                                                        }}>
                                                            <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t("Cancel Request")} arrow placement='top'>
                                                        <IconButton onClick={() => { this.setState({ deletePopup: true, SelectedPatientDetails: item }) }}>
                                                            <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </div> :
                    <div><Typography id='eMed_Pres_pop_NORec' style={{ marginTop: "10vw" }}>{"No records found"}</Typography></div>}
                {this.state.PackageDetailPop ? <PackageDeatailPop
                    fromOtIpRequest={true}
                    PatientDetails={this.state.SelectedPatientDetails}
                    ClinicID={this.state.ClinicID}
                    ErrorMsg={this.ErrorToast.bind(this)}
                    SuccessMsg={this.SuccessToast.bind(this)}
                    proceedBtnAction={this.postIpRequest.bind(this)}
                    handleClose={this.closePopup.bind(this)} /> : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to cancel this request ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.postIpRequest}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(OtIpRequestPopup)