import { Box, Button, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import CommonDatePicker from '../../Components/Common Components/CommonDatePicker';
import './ChartHomeCss.css';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

class ChartDashboardFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FilterDatas: {
                "FromDate": this.props.FilterDatas.FromDate,
                "ToDate": this.props.FilterDatas.ToDate,
                "DateIndex": this.props.FilterDatas.DateIndex,
                "CompareFromDate": this.props.FilterDatas.CompareFromDate,
                "CompareToDate": this.props.FilterDatas.CompareToDate,
                "CompareDateIndex": this.props.FilterDatas.CompareDateIndex,
                "BranchesList": this.props.FilterDatas.BranchesList,
                "ModulesList": this.props.FilterDatas.ModulesList,
                "SelectedBranches": this.props.FilterDatas.SelectedBranches,
                "SelectedModules": this.props.FilterDatas.SelectedModules,
            },
        }
    }
    FilterDate = (From, To, Index) => {
        this.state.FilterDatas.FromDate = From
        this.state.FilterDatas.ToDate = To
        this.state.FilterDatas.DateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas }, () => {
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    ComparedFilterDate = (From, To, Index) => {
        this.state.FilterDatas.CompareFromDate = From
        this.state.FilterDatas.CompareToDate = To
        this.state.FilterDatas.CompareDateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas }, () => {
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    ClearFilterData() {
        this.setState({
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "CompareFromDate": "",
                "CompareToDate": "",
                "CompareDateIndex": 0,
                "BranchesList": this.props.FilterDatas.BranchesList,
                "ModulesList": this.props.FilterDatas.ModulesList,
                "SelectedBranches": this.props.FilterDatas.BranchesList,
                "SelectedModules": this.props.FilterDatas.ModulesList,
            }
        })
    }

    ShowErrorMsg(Msg) {
        this.setState({
            ErrorText: Msg
        })
        setTimeout(() => { this.setState({ ErrorText: "" }) }, 3000)
    }

    FilterSearch() {
        if (this.state.FilterDatas?.SelectedModules.length > 0) {
            this.setState({ FilterOpen: false })
            this.props.FilteredData(this.state.FilterDatas)
        } else {
            this.ShowErrorMsg("Select atleast one module")
        }
    }

    render() {
        let states = this.state
        let { t } = this.props
        return (
            <Box className="eMed_Chart_Dashboard_Filter_main">
                <Box component={"div"} className='eMed_Filter_Top'>
                    <Typography variant='h6' fontWeight={"600"}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }, () => { this.props.CloseFilter() }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                {/* <Box>
                    <FormControl size='small' className='eMed_CharhPage_filter_elements'>
                        <InputLabel>Branch</InputLabel>
                        <Select
                            multiple
                            value={this.state.FilterDatas?.SelectedBranches}
                            onChange={(e) => {
                                states.FilterDatas.SelectedBranches = e.target.value
                                this.setState({
                                    FilterDatas: states.FilterDatas
                                })
                            }}
                            input={<OutlinedInput label="Branch" />}
                            renderValue={(selected) => selected.join(', ')}
                            size='small'
                        >
                            {this.state.FilterDatas?.BranchesList.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={this.state.FilterDatas?.SelectedBranches.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box> */}
                <Box className='eMed_CharhPage_filter_elements_select' marginTop={"1vw"}>
                    <FormControl size='small' fullWidth >
                        <InputLabel>Modules</InputLabel>
                        <Select
                            multiple
                            value={this.state.FilterDatas?.SelectedModules}
                            onChange={(e) => {
                                states.FilterDatas.SelectedModules = e.target.value
                                this.setState({
                                    FilterDatas: states.FilterDatas
                                })
                            }}
                            input={<OutlinedInput label="Modules" />}
                            renderValue={(selected) => selected.map((item) => item.module_name).join(', ')}
                            size='small'
                        >
                            {this.state.FilterDatas?.ModulesList.map((item) => (
                                <MenuItem key={item?.module_name} value={item}>
                                    <Checkbox checked={this.state.FilterDatas?.SelectedModules?.some(
                                        (selected) => selected?.module_name === item?.module_name
                                    )} />
                                    <ListItemText primary={item?.module_name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className='eMed_CharhPage_filter_elements'>
                    <CommonDatePicker
                        FromDate={this.state.FilterDatas?.FromDate}
                        ToDate={this.state.FilterDatas?.ToDate}
                        DateIndex={this.state.FilterDatas?.DateIndex}
                        SelectedDates={this.FilterDate.bind(this)}
                        HideAllMenu={true} />
                </Box>
                <Box sx={{ display:'flex', flexDirection:"row", alignItems:'flex-start', }}>
                    <Box className='eMed_CharhPage_filter_elements_compare'>
                        <CommonDatePicker
                            FromDate={this.state.FilterDatas?.CompareFromDate}
                            ToDate={this.state.FilterDatas?.CompareToDate}
                            DateIndex={this.state.FilterDatas?.CompareDateIndex}
                            SelectedDates={this.ComparedFilterDate.bind(this)}
                            HideAllMenu={true}
                            LabelText={"Compared Date"} />

                    </Box>
                    <Tooltip title="Clear Compare Date" placement='top' arrow>
                        <IconButton size='small'
                        onClick={() => {
                            states.FilterDatas.CompareFromDate = ""
                            states.FilterDatas.CompareToDate = ""
                            states.FilterDatas.CompareDateIndex = 0
                            this.setState({
                                FilterDatas: states.FilterDatas
                            })
                        }}
                        >
                            <HighlightOffIcon color='primary' />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box height={"1vw"} textAlign={"center"}>
                    <Typography color={'red'} fontSize={"0.8vw"}>{this.state.ErrorText ? this.state.ErrorText : ""}</Typography>
                </Box>
                <Box component={"div"} className="eMed_Filter_Btn_Div">
                    <Button size="small" className='eMed_Filter_Btns' onClick={() => { this.ClearFilterData() }} variant='outlined'>{t("Clear")}</Button>
                    <Button size="small" className='eMed_Filter_Btns' disabled={((this.state.FilterDatas?.startTime !== null && this.state.FilterDatas?.endTime === null) || (this.state.FilterDatas?.startTime !== null && this.state.error)) ? true : false} onClick={() => { this.FilterSearch() }} variant='contained'>{t("Search")}</Button>
                </Box>
            </Box>
        )
    }
}
export default withTranslation()(ChartDashboardFilter);