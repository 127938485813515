import React, { Component } from 'react';
import "./PharmaInventory.css";
import { Box, Button, Typography, Autocomplete, TextField, Stack, Paper } from '@mui/material'
import { withTranslation } from 'react-i18next';
import { Expiry_period, gstPercentage } from '../../../Utility/Constants';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { Colors } from '../../../Styles/Colors';

class PharmaAddNewDrug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand_name: "",
            generic_name: null,
            dosage_type: "",
            dosage_strength: "",
            gst_percentage: null,
            manufacture_Name: "",
            hsn_code: "",
            phyBox_No: "",
            reorder_threshold: "",
            group: "",
            schedule: "",
            expiry_alert_period: null,
            recommended_By: "",
            bar_code: "",
            reorder_quantity: "",
            sales_discount: "",
            dosageType_list: [],
            genericName_list: [],
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            isEdit: this.props.isEdit ? this.props.isEdit : false,
            EditData: {},
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            mandatoryFields: [],
            disableBtn : false,
            manufacturerName : [],
        }
    }

    componentDidMount() {
        this._getDosageTypeList();
        this.getDoctorsList();
        this.getManufacturerList();
    }

    componentDidUpdate() {
        if (this.props.UpdateData !== undefined && Object.keys(this.props.UpdateData)?.length > 0 && this.props.UpdateData !== this.state.EditData) {
            this.setState({
                EditData: this.props.UpdateData
            }, () => {
                this.assignUpdateDetails()
            })
        }
    }

    assignUpdateDetails = () => {
        let { EditData } = this.state;
        if (EditData) {
            this.setState({
                brand_name: EditData.drug_name,
                generic_name: EditData.generic_name,
                dosage_type: EditData.dosage_type,
                dosage_strength: EditData.dosage_strength,
                gst_percentage: EditData.gst_percentage,
                manufacture_Name: EditData.manufacturer_name,
                hsn_code: EditData.hsn_code,
                phyBox_No: EditData.physical_box_no,
                reorder_threshold: EditData.reorder_threshold,
                group: EditData.grouping,
                schedule: EditData.schedule,
                expiry_alert_period: EditData.expiry_alert_period,
                recommended_By: EditData.recommended_by,
                bar_code: EditData.bar_code,
                reorder_quantity: EditData.reorder_quantity,
                sales_discount: EditData.sales_discount,
                reorder_quantity : EditData?.reorder_quantity ? +EditData?.reorder_quantity : 0
            })
        }
    }
    getDoctorsList() {
        try {
          RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
            .then((response) => {
              if (response.data.status === "success") {
                let DocList = []
                response.data?.data.forEach((item) => {
                  item.displayKey = `${item?.name} | ${item?.doctor_reg_no}`
                  DocList.push(item)
                })
                this.setState({
                  DoctorsList: response.data?.data
                })
              }
              else {
                this.errorMessage(response.data?.message)
              }
            }).catch((error) => {
              this.errorMessage(error?.message)
            })
        }
        catch (e) {
          this.errorMessage(e?.message)
        }
      }

    getManufacturerList = (manufacturer) => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_MANUFACTURER_GET}?manufacturer_name=${manufacturer? manufacturer : ""}`)
                .then(response => {
                    // let data = response?.data;
                    if (response.data.status === "success") {
                        this.setState({
                             manufacturerName: response?.data?.data ? response?.data?.data : []
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message);
        }
    }


    _getDosageTypeList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PHARMA_DOSAGE_TYPE_LIST}`)
                .then(response => {
                    if (response.data.status === "success") {
                     let dosageType_list =  response?.data?.data?.filter((item)=>(item !== "N/A")) 
                       dosageType_list.unshift("N/A")
                        this.setState({
                            dosageType_list: dosageType_list  
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    _getGenericNameList = (value) => {
        try {
            RestAPIService.getAll(`${Serviceurls.PHARMA_GENERIC_NAME_LIST}?search_key=${value ? value : ""}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            genericName_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    check_Mandatory_Fields = () => {
        let states = this.state;
        if (states.brand_name !== "" && states.generic_name !== null && states.dosage_type !== "" && states.dosage_strength !== "" && states.hsn_code !== '' && states.manufacture_Name !== '') {
            return true;
        }
        return false;
    }

    handleSave = () => {
        let states = this.state;
        // let isMantadoryAvail = this.check_Mandatory_Fields();
        // if (isMantadoryAvail) {
            let datas = { 
                drugs: []
            }
            let method = "create";
            let status = states.loggedInuserInfo?.permission_access?.pharma?.isCrtDrug ? "Accepted" : "Pending";

            let isGeneric = states.generic_name ? typeof (states.generic_name) === "object" : false;
            let isGst = states.gst_percentage ? typeof (states.gst_percentage) === "object" : false;
            let isExpiry = states.expiry_alert_period ? typeof (states.expiry_alert_period) === "object" : false;

            let tempData = {
                bar_code: states.bar_code,
                dosage_strength: states.dosage_strength.trim(),
                dosage_type: states.dosage_type === "N/A" ? states.dosage_type : states.dosage_type.trim(),
                drug_name: states.brand_name.trim(),
                expiry_alert_period: isExpiry ? +states.expiry_alert_period?.value : states.expiry_alert_period ? states.expiry_alert_period : 0,
                generic_id: isGeneric ? states.generic_name.id : 0,
                generic_name: isGeneric ? states.generic_name.generic_name?.trim() : states.generic_name ? states.generic_name.trim() : "",
                grouping: states.group,
                gst_percentage:  isGst ? +states.gst_percentage.value : states.gst_percentage ? +states.gst_percentage : 0,
                hsn_code: states.hsn_code,
                manufacturer_name: states.manufacture_Name,
                physical_box_no: states.phyBox_No,
                recommended_by: states.recommended_By,
                reorder_threshold: +states.reorder_threshold,
                schedule: states.schedule,
                status: status,
                vendorName: "",
                vendor_id: null,
                reorder_quantity : states?.reorder_quantity ? +states?.reorder_quantity : 0,
                sales_discount : states?.sales_discount ? +states?.sales_discount : 0,

            }
            if (states.isEdit) {
                method = "updateWithOutId";

                delete tempData.vendorName;
                delete tempData.status;
                delete tempData.generic_id;

                tempData["id"] = states.EditData?.id;
                tempData["pharmacy_id"] = states.EditData?.pharmacy_id;
                tempData["comments"] = states.EditData?.comments;
                tempData["created_date__date"] = states.EditData?.created_date;
            }
            datas["drugs"].push(tempData)
            try {
                this.LoaderFunction(true);
                // this.setState({disableBtn: true})
                RestAPIService[method](states.isEdit ? tempData : datas, Serviceurls.PHARMA_ADD_NEW_DRUG)
                    .then(response => {
                        if (response.data.status === "Success") {
                            this.setState({disableBtn: false})
                            this.LoaderFunction(false);
                            if(states.isEdit){
                                this.props.popupClose();
                                this.props._getRequestList();
                            }
                            this.handleClear();
                            this.successMessage(response.data.message);
                            this.setState({disableBtn: false})
                            this.getDoctorsList();
                            this.getManufacturerList();
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "Failed") {
                            this.LoaderFunction(false);
                            this.errorMessage(error.response.data.message);
                        }
                        this.setState({disableBtn: false})
                    });
            } catch (e) {
                this.errorMessage(e.message);
                this.setState({disableBtn: false})
            }
        // }
        // else{
        //     this.setState({disableBtn: false})
        // }
    }

    handleClear = () => {
        this.setState({
            brand_name: "",
            generic_name: null,
            dosage_type: "",
            dosage_strength: "",
            gst_percentage: null,
            manufacture_Name: "",
            hsn_code: "",
            phyBox_No: "",
            reorder_threshold: "",
            group: "",
            schedule: "",
            expiry_alert_period: null,
            recommended_By: "",
            bar_code: "",
            reorder_quantity: "",
            sales_discount: "",
        })
    }

    handleChange = (e) => {
        let states = this.state;
        let { name, value } = e.target;
        let isNum = CommonValidation.numberOnly(value)
        let isDecimalNum = CommonValidation.DecimalNumber(value)
        switch (name) {
            case "reorder_threshold":
                if (value === "" || isNum && value > 0) {
                    states.reorder_threshold = +value.trimStart();
                }
                break;
            case "reorder_quantity":
                if (value === "" || isNum && value > 0) {
                    states.reorder_quantity = +value.trimStart();
                }
                break;
            case "sales_discount":
                if (value === "" || isDecimalNum && value > 0 && value <= 100) {
                    states.sales_discount = value.trimStart();
                }
                break;
            default:
                if (value === "" || value.length <= 150) {
                    states[name] = value.trimStart();
                }
                break;
        }
        this.setState({
            states
        })
    }

    renderTextBox = (label, statekey, mandatory = false) => {
        const { t } = this.props;
        let states = this.state;

        if (mandatory && !states.mandatoryFields.includes(statekey)) {
            states.mandatoryFields = [...states.mandatoryFields, statekey];
        }

        return (
            <div className="eMed_pharmadrctpo_txt">
                <TextField
                    className= {states.isEdit ? 'eMed_pharmaUpdatePop_txtBox' : 'eMed_pharmaAdd_txtBox'}
                    label={t(label)}
                    required={mandatory}
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    inputProps={{emed_tid:label}}
                    value={states[statekey] || ""}
                    onChange={(event) => this.handleInputChange(event, statekey, mandatory)}
                />
            </div>
        );
    }
    
    renderAutoSlt = (label, stateKey, showkey, Data = [], mandatory = false) => {
        const { t } = this.props;
        let states = this.state;
    
        if (mandatory && !states.mandatoryFields.includes(stateKey)) {
            states.mandatoryFields = [...states.mandatoryFields, stateKey];
        }
    
        return (
            <div className="eMed_pharmadrctpo_txt">
                <Autocomplete
                    className={states.isEdit ? 'eMed_pharmaUpdatePop_txtBox' : 'eMed_pharmaAdd_txtBox'}
                    size="small"
                    freeSolo={(label === "RecommendedBy" || label === "ManufactureName")}
                    clearIcon={false}
                    options={Data}
                    getOptionLabel={(option) =>
                        typeof option[showkey] === "string"
                            ? option[showkey]
                            : option?.manufacturer_name
                            ? option?.manufacturer_name
                            : option?.name
                            ? option?.name
                            : option
                    }
                    value={states[stateKey] || ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        states[stateKey] =
                            label === "ManufactureName"
                                ? newValue?.manufacturer_name
                                : label === "RecommendedBy"
                                ? newValue?.name
                                : newValue;
    
                        this.setState({
                            states,
                            disableBtn: !this.checkMandatoryFields()
                        });
                    }}
                    onInputChange={(event, newvalue) => {
                        if (stateKey === "generic_name" && newvalue.length > 3) {
                            this._getGenericNameList(newvalue);
                        } else if (stateKey === "manufacture_Name") {
                            states[stateKey] = newvalue
                            this.setState({ 
                                states,
                                disableBtn: !this.checkMandatoryFields()
                             });
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={mandatory}
                            label={t(label)}
                            placeholder={
                                label === "ManufactureName"
                                    ? "Enter Manufacture Name"
                                    : label === "RecommendedBy"
                                    ? "Enter recommended Doctor"
                                    : ""
                            }
                            inputProps={{ ...params.inputProps, emed_tid: label }}
                            size="small"
                            autoComplete="off"
                        />
                    )}
                />
            </div>
        );
    };
    
    handleInputChange = (event, stateKey, mandatory) => {
        const states = this.state;
        const { value } = event.target;
        let isNum = CommonValidation.numberOnly(value)
        let isDecimalNum = CommonValidation.DecimalNumber(value)
        switch (stateKey) {
            case "reorder_threshold":
                if (value === "" || isNum && value > 0) {
                    states.reorder_threshold = +value.trimStart();
                }
                break;
            case "reorder_quantity":
                if (value === "" || isNum && value > 0) {
                    states.reorder_quantity = +value.trimStart();
                }
                break;
            case "sales_discount":
                if (value === "" || isDecimalNum && value > 0 && value <= 100) {
                    states.sales_discount = value.trimStart();
                }
                break;
    
            default:
                if (value === "" || value.length <= 150) {
                    states[stateKey] = value.trimStart();
                }
                break;
        }
    
        if (mandatory && !states.mandatoryFields.includes(stateKey)) {
            states.mandatoryFields.push(stateKey);
        }
    
        this.setState({
            states,
            disableBtn: !this.checkMandatoryFields()
        });
    };
    
    
    checkMandatoryFields = () => {
        const { mandatoryFields } = this.state;
        return mandatoryFields.every((field) => !!this.state[field]);
    };
    
    render() {
        console.log(this.state);
        
        const { t } = this.props;
        let states = this.state;
        return (
            <Box className={states.isEdit ? "eMed_pharmaUpdatePop_Con" : "eMed_pharmaAdd_Container"}>
                <Box component={'div'} className={states.isEdit ? null : 'eMed_pharma_box'}>
                    <Box className={states.isEdit ? "eMed_pharmaUpdatePop_Con" : "eMed_pharmaAdd_mainBox"}>
                        <Box className='eMed_pharmaAdd_subDiv'>
                            <Box component={'div'} mb={'1.5vw'} mt={'1vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Drug Details</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                {this.renderTextBox("BrandName", "brand_name", true)}
                                {this.renderAutoSlt("GenericName", "generic_name", "generic_name", states.genericName_list, true)}
                                {this.renderAutoSlt("DosageType", "dosage_type", "label", states.dosageType_list, true)}
                                {this.renderTextBox("DosageStrength", "dosage_strength", true)}

                                {this.renderTextBox("PhyBoxNo", "phyBox_No")}
                                {this.renderTextBox("HSNCode", "hsn_code", true)}
                                {this.renderAutoSlt("ManufactureName", "manufacture_Name", "label", this.state.manufacturerName, true)}
                                {this.renderAutoSlt("GST%", "gst_percentage", "label", gstPercentage)}
                                {this.renderTextBox("ReorderThreshold", "reorder_threshold")}
                                {this.renderTextBox("Reorder Quantity (ROQ)", "reorder_quantity")}
                                {this.renderAutoSlt("ExpiryPeriod", 'expiry_alert_period', "label", Expiry_period)}
                                {this.renderTextBox("Sales Discount %", "sales_discount")}
                            </Box>
                        </Box>  
                        <Box className='eMed_pharmaAdd_subDiv'>
                            <Box component={'div'} mb={'1.5vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Additional Details</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                {this.renderAutoSlt("RecommendedBy", "recommended_By", "label", this.state.DoctorsList, false)}
                                {this.renderTextBox("Group", "group")}
                                {this.renderTextBox("Schedule", "schedule")}
                                {this.renderTextBox("UPC", "bar_code")}
                            </Box>
                        </Box>
                        <Stack id={states.isEdit ? "eMed_pharmaUpdatePop_btnCont" : "eMed_pharmaAdd_btnCont"} direction="row" alignItems="center" justifyContent={states.isEdit ? "flex-end" : "flex-end"} spacing={3}>
                            <Button emed_tid='nd_clear' variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { states.isEdit ? this.assignUpdateDetails() : this.handleClear() }}>{states.isEdit ? t("Reset") : t("Clear")}</Button>
                            <Button emed_tid='nd_save' variant='contained' size="small" id="eMed_Config_btn" disabled={this.state.disableBtn ? true : !this.check_Mandatory_Fields()} onClick={() => { this.setState({disableBtn : true},()=>{this.handleSave()}) }}>{states.isEdit ? t("Update") : t("Save")}</Button>
                        </Stack>
                    </Box>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg && (
                    <ToastMsg severity='error' msg={this.state.isErrorMsgText} msgPop={this.msgClose.bind(this)} />
                )}
                {this.state.successMsg && (
                    <ToastMsg severity='success' msg={this.state.successMsgText} msgPop={this.msgClose.bind(this)} />
                )}
            </Box>
        );
    }   
}
export default withTranslation()(PharmaAddNewDrug)