import React, { Component } from 'react';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import { CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';
import Loader from '../../../../Components/Loader';
export default class DocMedicalCertific extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            patientId: '',
            doctor_details: {
                city_name: "",
                doctor_name: '',
                regNo: '',
                doctor_id: null
            },
            doctor_content: "",
            date: new Date(),
            companyName: '',
            designation: '',
            diagnosisDetails: '',
            fromDateOpen: false,
            fromDate: null,
            toDateOpen: false,
            toDate: null,
            effectiveDateOpen: false,
            effectiveDate: null,
            dateOpen: false,
            disableInputs: false,
        }
    }

    componentDidMount = () => {
        let states = this.state
        let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        states["patientId"] = appointmentId?.patient_id
        states["appointment_id"] = appointmentId?.appointment_id
        states["doctor_details"]["doctor_name"] = UserData?.doctor_name
        states["doctor_details"]["doctor_id"] = UserData?.doctor_id
        states["doctor_details"]["city_name"] = UserData?.clinic_city
        states["doctor_details"]["regNo"] = UserData?.registeration_number
        this.setState({
            states
        }, () => {
            this.getPatientDetailData()
            this.getMedicalCertifiData()
        })
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data ? response?.data?.data : {},
                        }, () => this.handleChangeMainText())
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getMedicalCertifiData = () => {
        try {
            let states = this.state
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.DOC_MEDICAL_CERTFICATE_GET + `?patient_id=${states.patientId}&appointment_id=${states?.appointment_id ? states?.appointment_id : null}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let data = response?.data?.medical_data?.length > 0 ? response?.data?.medical_data[response?.data?.medical_data?.length - 1] : {}
                        this.setState({
                            id: data.id || null,
                            companyName: data.company_name || '',
                            designation: data.designation_name || '',
                            diagnosisDetails: data.diagnosis_details || '',
                            fromDate: data.from_date ? new Date(data.from_date) : null,
                            toDate: data.to_date ? new Date(data.to_date) : null,
                            effectiveDate: data.effective_date ? new Date(data.effective_date) : null,
                            date: data.date ? new Date(data.date) : new Date(),
                            doctor_details: {
                                ...states.doctor_details,
                                city_name: data.location || states.doctor_details?.city_name,
                                regNo: data.registration_proof_number || states.doctor_details?.regNo,
                            },
                        }, () => {
                            if (data?.medical_notes) {
                                this.setState({
                                    doctor_content: data.medical_notes,
                                    disableInputs: true
                                })
                            } else {
                                this.handleChangeMainText()
                            }
                            this.LoaderFunction(false);
                        });
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.LoaderFunction(false);
                })
        } catch (error) {
            this.errorMessage(error?.message)
            this.LoaderFunction(false);
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            disableBtn: false
        })
    }

    LoaderFunction = (value) => {
        this.setState({
            isLoader: value
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleMedCertifiSave = () => {
        try {
            let states = this.state
            let data = {
                "id": states.id || null,
                "doctor_id": states.doctor_details?.doctor_id || null,
                "patient_id": states.patientId || null,
                "designation_name": states.designation || '',
                "company_name": states.companyName || '',
                "appointment_id": states.patientDetails?.appointment_id ? states.patientDetails?.appointment_id : this.state.appointment_id ? this.state.appointment_id : null,
                "diagnosis_details": states.diagnosisDetails || '',
                "from_date": states.fromDate ? DateTime.fromJSDate(states?.fromDate)?.toFormat('yyyy-MM-dd') : '',
                "to_date": states.toDate ? DateTime.fromJSDate(states?.toDate)?.toFormat('yyyy-MM-dd') : '',
                "date": states.date ? DateTime.fromJSDate(states?.date)?.toFormat('yyyy-MM-dd') : '',
                "effective_date": states.effectiveDate ? DateTime.fromJSDate(states?.effectiveDate)?.toFormat('yyyy-MM-dd') : '',
                "medical_notes": states.doctor_content,
                "patient_name": states.patientDetails?.first_name + states.patientDetails?.last_name || "",
                "doctor_name": states.doctor_details?.doctor_name || '',
                "location": states.doctor_details?.city_name || '',
                "registration_proof_number": states.doctor_details?.regNo || ''
            }
            this.LoaderFunction(true);
            RestAPIService.create(data, Serviceurls.DOC_MEDICAL_CERTFICATE_GET)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            disableInputs: true,
                            id: response?.data?.medical_data?.id || null
                        }, () => {
                            this.getMedicalCertifiData()
                        })
                        this.successMessage(response?.data?.message)
                        this.LoaderFunction(false);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    handleMedCertifiPrint = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.DOC_MEDICAL_CERTFICATE_GET}?patient_id=${this.state.patientId}&appointment_id=${this.state?.appointment_id ? this.state?.appointment_id : null}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleChange = (e, stateKey) => {
        let states = this.state;
        if (stateKey === "city_name" || stateKey === "regNo") {
            states["doctor_details"][stateKey] = e.target.value
        } else {
            states[stateKey] = e.target.value
        }
        this.setState({ states }, () => this.handleChangeMainText())
    }


    renderTextField = (label, stateKey) => {
        let states = this.state;
        let value = (stateKey === "city_name" || stateKey === "regNo") ? states["doctor_details"][stateKey] : states[stateKey]
        return (
            <Box margin={'1vw'}>
                <TextField
                    label={label}
                    inputProps={{ maxLength: stateKey === 'diagnosisDetails' ? 250 : (stateKey === "companyName" || stateKey === "city_name") ? 75 : 100, emed_tid: stateKey }}
                    rows={stateKey === 'diagnosisDetails' && 4}
                    multiline={stateKey === "diagnosisDetails" ? true : false}
                    disabled={stateKey === "regNo" ? true : states.disableInputs}
                    sx={{ width: "100%" }}
                    size="small"
                    autoComplete="off"
                    name={label}
                    value={value ? value : ""}
                    onChange={(e) => { this.handleChange(e, stateKey) }}
                />
            </Box>
        )
    }

    handleDatePickerPopup = (stateKey, open = false) => {
        let states = this.state;
        states[stateKey] = open ? true : false
        this.setState({ states })
    }

    renderDatePicker = (label, statekey, popupState) => {
        let states = this.state;
        return (
            <Box sx={{ margin: '1vw' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        open={states[popupState]}
                        onOpen={() => { this.handleDatePickerPopup(popupState, true) }}
                        onClose={() => { this.handleDatePickerPopup(popupState, false) }}
                        value={states[statekey]}
                        label={label}
                        name={label}
                        disabled={states.disableInputs}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        onChange={
                            (newDate) => {
                                states[statekey] = newDate?.$d ? newDate?.$d : newDate
                                this.setState({
                                    states
                                }, () => this.handleChangeMainText())
                            }}
                        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: statekey === "effectiveDate" ? '47%' : '100%' }} size='small' {...params} onClick={() => { this.handleDatePickerPopup(popupState, true) }} />}
                    />
                </LocalizationProvider>
            </Box>
        )
    }

    handleChangeMainText = () => {
        let states = this.state
        const value = `I, Dr. ${states.doctor_details?.doctor_name} after careful personal examination of the case, hereby certify that ${states?.patientDetails?.title ? states?.patientDetails?.title : 'Dr./Shri/Smt./Ms.'} ${states?.patientDetails?.first_name ? states?.patientDetails?.first_name : '_____'} ${states?.patientDetails?.last_name ? ` ${states?.patientDetails?.last_name}` : ""}${states?.designation ? `, ${states?.designation}` : ''} (name & designation of applicant) of the ${states.companyName ? states.companyName : '_____'}, whose signature is given above is suffering from ${(states?.diagnosisDetails ? states?.diagnosisDetails : "_____")} and, Therefore, I consider, that a period of duty absense from ${states?.fromDate ? DateTime.fromJSDate(states?.fromDate)?.toFormat('dd-MM-yyyy') : "_____"} to ${states.toDate ? DateTime.fromJSDate(states?.toDate)?.toFormat('dd-MM-yyyy') : '_____'} effective from ${states?.effectiveDate ? DateTime.fromJSDate(states?.effectiveDate)?.toFormat('dd-MM-yyyy') : "_____"} is absolutely necessary for the restoration of his/her health.`
        this.setState({
            doctor_content: value
        })
    }

    handleValidation = () => {
        let states = this.state
        let msg = null
        if (!states.companyName) {
            msg = "Enter Company Name"
        } else if (!states.diagnosisDetails) {
            msg = "Enter Diagnosis Details"
        } else if (!states.fromDate) {
            msg = "Select From Date"
        } else if (!states.toDate) {
            msg = "Select To Date"
        } else if (!states.effectiveDate) {
            msg = "Select Effective Date"
        } else if (!states.doctor_details?.city_name) {
            msg = "Enter Place"
        } else if (!states.date) {
            msg = "Select Date"
        } else if (!states.doctor_details?.regNo) {
            msg = "Enter Registration No"
        } else if (DateTime.fromJSDate(states?.fromDate) > DateTime.fromJSDate(states?.toDate)) {
            msg = "From Date is Greater than To Date"
        } else {
            msg = null
        }

        if (msg) {
            this.errorMessage(msg)
        } else {
            this.handleMedCertifiSave()
        }
    }

    handleclear = () => {
        let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.state["doctor_details"]["city_name"] = UserData?.clinic_city
        this.setState({
            doctor_content: "",
            date: new Date(),
            companyName: '',
            designation: '',
            diagnosisDetails: '',
            fromDateOpen: false,
            fromDate: null,
            toDateOpen: false,
            toDate: null,
            effectiveDateOpen: false,
            effectiveDate: null,
            dateOpen: false,
            disableInputs: false,
            doctor_details: this.state.doctor_details
        }, () => this.handleChangeMainText())
    }

    render() {
        let states = this.state;
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'73vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
                    </Box>
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'} width={'100%'}>
                    <Box component={'div'} width={'65%'}>
                        <Box border={'1px solid lightgray'} height={"100%"} p={"1.5vw"}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", height: "10vh" }}>
                                <div style={{ width: '33%' }}></div>
                                <div style={{ fontSize: "1.1vw", fontWeight: "600", textAlign: "center", width: '33%' }}>{`MEDICAL CERTIFICATE`}</div>
                                <div style={{ width: '33%', textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', wordBreak: 'break-word' }}>
                                    <div>Place</div>
                                    <div>
                                        {states.doctor_details?.city_name ? (
                                            states.doctor_details.city_name.length > 20 ? (
                                                <Tooltip title={states.doctor_details.city_name} placement="top" arrow>
                                                    <Typography>{`${states.doctor_details.city_name.slice(0, 17)}...`}</Typography>
                                                </Tooltip>
                                            ) : (<Typography>{states.doctor_details.city_name}</Typography>)
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                            <div>Signature of Applicant .........................</div>
                            <br />
                            <div>
                                <TextField
                                    onChange={(e) => {
                                        this.setState({
                                            doctor_content: e.target.value
                                        })
                                    }}
                                    variant='standard'
                                    sx={{ width: "100%", overflowY: 'auto' }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    multiline={true}
                                    rows={5}
                                    value={this.state.doctor_content}
                                />
                            </div>
                            <div style={{ marginTop: "5vh", display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <div>Signature of Medical Officer /</div>
                                <div>Doctor along with official seal</div>
                                <br />
                                <div>{`Registration No. ${states.doctor_details?.regNo ? states.doctor_details?.regNo : ''}`}</div>
                            </div>
                            <div>Date: {`${states?.date ? DateTime.fromJSDate(states?.date).toFormat('dd-MM-yyyy') : ''}`}</div>
                        </Box>
                    </Box>
                    <Box component="div" width={'35%'} border="1px solid lightgray" height={"61.5vh"}>
                        <Box height="5.15vh" display="flex" pl="0.5vw" alignItems="center">
                            <Typography fontWeight={600}>Medical Certificate</Typography>
                        </Box>
                        <Box sx={{ height: '50vh', overflow: 'auto', backgroundColor: Colors.white }}>
                            {this.renderTextField("Company/Office Name *", "companyName", true)}
                            {this.renderTextField("Designation", "designation")}
                            <hr />
                            <Typography p={'0.5vw'}>Diagnosis Details</Typography>
                            {this.renderTextField("Diagnosis Details Here *", "diagnosisDetails")}
                            <hr />
                            <Typography style={{ margin: '0.5vw' }}>Date Details</Typography>
                            <Box display="flex">
                                {this.renderDatePicker("From Date *", "fromDate", "fromDateOpen")}
                                {this.renderDatePicker("To Date *", "toDate", "toDateOpen")}
                            </Box>
                            {this.renderDatePicker("Effective Date *", "effectiveDate", "effectiveDateOpen")}
                            <hr />
                            {this.renderTextField("Place *", "city_name")}
                            <Box display="flex">
                                {this.renderDatePicker("Date *", "date", "dateOpen")}
                                {this.renderTextField("Registration No *", "regNo")}
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" height="6.18vh">
                            {this.state.disableInputs ? <Button
                                variant='outlined'
                                emed_tid='medical_certifi_reset'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => { this.handleclear() }}
                            >{"Reset"}</Button> : null}
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                variant="outlined"
                                emed_tid='medical_certifi_save'
                                disabled={this.state.disableBtn}
                                onClick={() => this.setState({ disableBtn: true }, () => this.handleValidation())}
                            >
                                Save
                            </Button>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                variant="contained"
                                emed_tid='medical_certifi_print'
                                disabled={!this.state.id || this.state.disableBtn}
                                onClick={() => this.setState({ disableBtn: true }, () => this.handleMedCertifiPrint())}
                            >
                                Print
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <div>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}

                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </div>
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}