import React, { Component } from "react";
import { Trans, withTranslation } from 'react-i18next'
import {
    Box, Button, Stack, IconButton, Tooltip,
    Typography,
    Menu,
    MenuItem,
    Modal,
    Autocomplete,
    TextField,
    Drawer
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { AmountsCard } from "../../../Components/CommonCards/CommonCards";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { DataGrid } from "@mui/x-data-grid";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../Styles/Colors";
import '../Asset.css';
import { Complaint_Status } from "../../../Utility/Constants";
import AssetFilter from "../Filter/AssetFilter";
import { DateTime } from "luxon";
import { formatDateAndTimeMeth2 } from "../../../Components/CommonFunctions/CommonFunctions";

class AssetComplaints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Complaints: [],
            isAllChecked: false,
            page: 0,
            pageSize: 10,
            statusLogData: [],
            selectedKey: null,
            selectedRow: null,
            anchorEl: null,
            isSelected: false,
            openChangeStatus: false,
            openStatusLog: false,
            complaintStatus: Complaint_Status,
            selectedToStatus: null,
            statusComment: '',
            statusData: null,
            fromStatus: null,
            printRowData: [],
            registered: 0,
            work_done: 0,
            work_initiated: 0,
            resolved: 0,
            total: 0,
            workProgress: 0,
            FilterOpen: false,
            filterDatas: {
                'selectedCategory': null,
                'selectedSubCategory': null,
                "fromDate": null,
                "toDate": null,
                "dateIndex": 1,
                'assetTagNo': '',
                'selectedComplaintType': null,
                'selectedStatus': null,
                'vendorName': ''
            },
            rowCount: 0

        }
    }

    componentDidMount() {
        this.getAssetComplaintList()
    }

    getAssetComplaintList = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let complaintType = states.filterDatas.selectedComplaintType ? states.filterDatas.selectedComplaintType?.value : ''
            let status = states.filterDatas.selectedStatus ? states.filterDatas.selectedStatus : ''
            let vendorName = states.filterDatas.vendorName ? states.filterDatas.vendorName : ''
            let cmpFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let cmpTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.ASSET_COMPLAINT_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&complaint_type=' + complaintType +
                '&complaint_from_date=' + cmpFrm +
                '&complaint_to_date=' + cmpTo +
                '&status=' + status +
                '&vendor_name=' + vendorName +
                '&page_no=' + (states.page + 1) +
                '&page_size=' + states.pageSize
            )
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            Complaints: response.data.data,
                            registered: response.data.registered,
                            resolved: response.data.resolved,
                            total: response.data.total,
                            work_done: response.data.work_done,
                            work_initiated: response.data.work_initiated,
                            workProgress: response.data.work_inprogress,
                            loading: false,
                            rowCount: response.data.total_count
                        })
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getAssetComplaintPrint = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let complaintType = states.filterDatas.selectedComplaintType ? states.filterDatas.selectedComplaintType?.value : ''
            let status = states.filterDatas.selectedStatus ? states.filterDatas.selectedStatus : ''
            let vendorName = states.filterDatas.vendorName ? states.filterDatas.vendorName : ''
            let cmpFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let cmpTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.getAllPrint(Serviceurls.ASSET_COMPLAINT_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&complaint_type=' + complaintType +
                '&complaint_from_date=' + cmpFrm +
                '&complaint_to_date=' + cmpTo +
                '&status=' + status +
                '&vendor_name=' + vendorName +
                '&export_type=pdf')
                .then((response) => {
                    if (response) {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAssetComplaintExcel = () => {
        try {
            let states = this.state
            let category = states.filterDatas.selectedCategory ? states.filterDatas.selectedCategory?.category_id : ''
            let subCat = states.filterDatas.selectedSubCategory ? states.filterDatas.selectedSubCategory?.sub_category_id : ''
            let assetNo = states.filterDatas.assetTagNo ? states.filterDatas.assetTagNo : ''
            let complaintType = states.filterDatas.selectedComplaintType ? states.filterDatas.selectedComplaintType?.value : ''
            let status = states.filterDatas.selectedStatus ? states.filterDatas.selectedStatus : ''
            let vendorName = states.filterDatas.vendorName ? states.filterDatas.vendorName : ''
            let cmpFrm = states.filterDatas.fromDate ? states.filterDatas.fromDate : ''
            let cmpTo = states.filterDatas.toDate ? states.filterDatas.toDate : ''

            RestAPIService.excelGet(Serviceurls.ASSET_COMPLAINT_GET_POST +
                '?asset_no=' + assetNo +
                '&category_id=' + category +
                '&sub_category_id=' + subCat +
                '&complaint_type=' + complaintType +
                '&complaint_from_date=' + cmpFrm +
                '&complaint_to_date=' + cmpTo +
                '&status=' + status +
                '&vendor_name=' + vendorName +
                '&export_type=excel')
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response.data?.message) {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAssetGetPassPrint = (item) => {
        try {
            let query = ''

            if (this.state.printRowData?.length > 0) {
                let ids = [];
                this.state.printRowData?.forEach(element => ids?.push(element?.id))
                query = '?id=' + ids
            } else {
                query = '?id=' + item?.id
            }

            RestAPIService.getAll(Serviceurls.ASSET_GATE_PASS_PRINT + query)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postAssetStatusLog = () => {
        try {
            let data = {
                id: this.state.selectedRow,
                status_log: {
                    from_status: this.state.fromStatus,
                    to_status: this.state.selectedToStatus,
                    comment: this.state.statusComment
                }
            }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.ASSET_STATUS_LOG_POST_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getAssetComplaintList()
                        this.setState({
                            openChangeStatus: false,
                            statusData: null,
                            selectedToStatus: null,
                            statusComment: '',
                            postLoad: false
                        })
                    }
                }).catch((error) => {
                    this.setState({ postLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    openStatusChangePopUp = () => {
        try {
            return (
                <Box component={'div'}>
                    <Modal
                        open={this.state.openChangeStatus}
                    >
                        <Box component={'div'} className="eMed_Asset_Sts_Cge_style">
                            <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'} borderBottom={'1px solid lightgray'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Change Status</Typography>
                                <Button
                                    className="Common_Btn_Min_Width"
                                    onClick={() => this.setState({ openChangeStatus: false, isSelected: false })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} p={'0.5vw'}>
                                <Autocomplete
                                    clearIcon
                                    size="small"
                                    options={this.state.complaintStatus}
                                    getOptionLabel={(option) => option}
                                    value={this.state.fromStatus}
                                    disabled
                                    renderInput={(params) => <TextField {...params} variant="standard" label='Status From' sx={{ width: '15vw', mr: '1vw' }} />}
                                />
                                <Autocomplete
                                    clearIcon
                                    size="small"
                                    options={this.state.complaintStatus}
                                    getOptionLabel={(option) => option}
                                    value={this.state.selectedToStatus}
                                    onChange={(e, value) => this.setState({ selectedToStatus: value })}
                                    renderInput={(params) => <TextField {...params} variant="standard" label='Status To *' sx={{ width: '15vw', mr: '1vw' }} />}
                                />
                            </Box>
                            <Box component={'div'} p={'1vw'}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label='Comment'
                                    rows={5}
                                    multiline
                                    value={this.state.statusComment}
                                    onChange={(e) => this.setState({ statusComment: e.target.value })}
                                />
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    variant="outlined"
                                    sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                    onClick={() => {
                                        this.setState({ openChangeStatus: false, statusComment: '', selectedToStatus: null })
                                    }}
                                >Cancel</Button>
                                <Button
                                    variant="contained"
                                    sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                    disabled={this.state.postLoad}
                                    onClick={() => {
                                        if (this.state.selectedToStatus === null) {
                                            this.errorMessage('Select To Status')
                                        } else {
                                            this.postAssetStatusLog()
                                        }
                                    }}
                                >Save</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    openStatusLogPopup = () => {
        try {
            this.state.statusLogData?.forEach((element, index) => element.sno = index + 1)
            const columns = [
                { field: 'sno', headerName: 'S.No', flex: 0.05 },
                {
                    field: 'user_name', headerName: 'Username', flex: 0.2,
                    renderCell: (params) => {
                        let userName = params?.row?.user_name
                        return (
                            <Box component={'div'}>
                                {userName ? userName?.length > 15 ?
                                    <Tooltip placement="top" title={userName}><Typography fontSize={'0.9vw'}>{userName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'}>{userName}</Typography> : '-'}
                            </Box>
                        )
                    }
                },
                {
                    field: 'from_status', headerName: 'From Status', flex: 0.2,
                    renderCell: (params) => {
                        let fromStatus = params?.row?.from_status
                        return (
                            <Box component={'div'}>
                                {fromStatus ? fromStatus?.length > 15 ?
                                    <Tooltip placement="top" title={fromStatus}><Typography fontSize={'0.9vw'}>{fromStatus?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'}>{fromStatus}</Typography> : '-'}
                            </Box>
                        )
                    }
                },
                {
                    field: 'to_status', headerName: 'To Status', flex: 0.2,
                    renderCell: (params) => {
                        let toStatus = params?.row?.to_status
                        return (
                            <Box component={'div'}>
                                {toStatus ? toStatus?.length > 15 ?
                                    <Tooltip placement="top" title={toStatus}><Typography fontSize={'0.9vw'}>{toStatus?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'}>{toStatus}</Typography> : '-'}
                            </Box>
                        )
                    }
                },
                {
                    field: 'comment', headerName: 'Comments', flex: 0.35,
                    renderCell: (params) => {
                        let comments = params?.row?.comment
                        return (
                            <Box component={'div'}>
                                {comments ? comments?.length > 25 ?
                                    <Tooltip placement="top" title={comments}><Typography fontSize={'0.9vw'}>{comments?.slice(0, 25) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'}>{comments}</Typography> : '-'}
                            </Box>
                        )
                    }
                },
            ]
            return (
                <Box component={'div'}>
                    <Modal open={this.state.openStatusLog}>
                        <Box component={'div'} className="eMed_Asset_Sts_log_style">
                            <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'} borderBottom={'1px solid lightgray'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Change Status</Typography>
                                <Button
                                    className="Common_Btn_Min_Width"
                                    onClick={() => this.setState({ openStatusLog: false, isSelected: false, page: 0, pageSize: 10 })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} p={'0.5vw'} height='58vh'>
                                <DataGrid
                                    rows={this.state.statusLogData}
                                    columns={columns}
                                    getRowId={(row) => row['sno']}
                                    page={this.state.page}
                                    pageSize={this.state.pageSize}
                                    localeText={{
                                        toolbarColumns: "",
                                        toolbarDensity: "",
                                        toolbarExport: "",
                                        toolbarFilters: "",
                                        toolbarExportPrint: ""
                                    }}
                                    components={{
                                        // Toolbar: CommonGridHeader.CommonToolBar,
                                        NoRowsOverlay: () => (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                {'No Records To Be Shown'}
                                            </Box>
                                        )
                                    }}
                                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    pagination
                                    headerHeight={40}
                                    disableSelectionOnClick
                                    loading={this.state.statusLoad}
                                />
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClickHandler = (data, selectedKey) => {
        try {
            switch (selectedKey) {
                case 'Change Status':
                    this.setState({
                        openChangeStatus: true,
                        fromStatus: data?.complain_status,
                        isSelected: false
                    })
                    break;
                case 'Status Log':
                    this.setState({ openStatusLog: true, statusLogData: data?.status_log })
                    break;
                case 'Print Gate-Pass':
                    this.getAssetGetPassPrint(data);
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderMenu = (data) => {
        try {
            let menuItems = [
                { value: 'Print Gate-Pass', label: 'Print Gate-Pass' },
                { value: 'Change Status', label: 'Change Status' },
                { value: 'Status Log', label: 'Status Log' },
            ]
            return (
                <Box component={'div'} display={'flex'}>
                    <Tooltip title={("More")} placement='top' arrow>
                        <Button
                            className='Common_Btn_Min_Width'
                            onClick={(e) => {
                                this.setState({
                                    anchorEl: e.currentTarget,
                                    selectedRow: data?.id,
                                    isSelected: true
                                })
                            }}
                        >
                            <MoreVertIcon sx={{ color: 'black' }} />
                        </Button>
                    </Tooltip>
                    {this.state.isSelected && this.state.selectedRow == data?.id ?
                        <Menu
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => { this.setState({ anchorEl: null, }) }}
                            elevation={1}
                        >
                            {menuItems?.map((item, index) => (
                                <MenuItem key={index}
                                    onClick={() => this.onClickHandler(data, item?.value)}
                                >{item?.label}</MenuItem>
                            )
                            )}
                        </Menu> : null}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getAssetComplaintList()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            FilterOpen: false,
        }, () => { this.getAssetComplaintList() })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    render() {
        const { t } = this.props;
        this.state.Complaints?.forEach((element, index) => element.sno = index + 1)
        const colums = [
            {
                field: 'complaint_details', headerName: 'Complaint Details', flex: 0.09,
                renderCell: (params) => {
                    let compId = params.row?.complaint_id
                    let compDate = formatDateAndTimeMeth2(params?.row?.complaint_date)
                    // let compDate = params?.row?.complaint_date
                    return (
                        <Box component={'div'}>
                            {compId ? compId?.length > 15 ?
                                <Tooltip placement="top" title={compId}><Typography fontSize={'0.9vw'} fontWeight={600}>{compId?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{compId}</Typography> : '-'}
                            {compDate ? compDate?.length > 15 ?
                                <Tooltip placement="top" title={compDate}><Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{compDate?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{compDate}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'asset_details', headerName: 'Asset Details', flex: 0.09,
                renderCell: (params) => {
                    let assetName = params?.row?.asset_name
                    let assetNo = params?.row?.asset_no
                    return (
                        <Box component={'div'}>
                            {assetName ? assetName?.length > 15 ?
                                <Tooltip placement="top" title={assetName}><Typography fontSize={'0.9vw'} fontWeight={600}>{assetName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{assetName}</Typography> : '-'}
                            {assetNo ? assetNo?.length > 15 ?
                                <Tooltip placement="top" title={assetNo}><Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{assetNo?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{assetNo}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'Vendor_Details', headerName: 'Vendor Details', flex: 0.09,
                renderCell: (params) => {
                    let vendorName = params?.row?.vendor_name
                    let venMobNo = params?.row?.vendor_mobile_no
                    return (
                        <Box component={'div'}>
                            {vendorName ? vendorName?.length > 15 ?
                                <Tooltip placement="top" title={vendorName}><Typography fontSize={'0.9vw'} fontWeight={600}>{vendorName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{vendorName}</Typography> : '-'}
                            {venMobNo ? venMobNo?.length > 15 ?
                                <Tooltip placement="top" title={venMobNo}><Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{venMobNo?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{venMobNo}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'complained_by', headerName: 'Complaint By', flex: 0.09,
                renderCell: (params) => {
                    let compBy = params?.row?.complained_by
                    return (
                        <Box component={'div'}>
                            {compBy ? compBy?.length > 15 ?
                                <Tooltip placement="top" title={compBy}><Typography fontSize={'0.9vw'}>{compBy?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{compBy}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'complain_type', headerName: 'Complaint Type', flex: 0.09,
                renderCell: (params) => {
                    let compType = params?.row?.complain_type
                    return (
                        <Box component={'div'}>
                            {compType ? compType?.length > 15 ?
                                <Tooltip placement="top" title={compType}><Typography fontSize={'0.9vw'}>{compType?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{compType}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'category_details', headerName: 'Category Details', flex: 0.09,
                renderCell: (params) => {
                    let catName = params?.row?.category_name
                    let subCat = params?.row?.sub_category_name
                    return (
                        <Box component={'div'}>
                            {catName ? catName?.length > 15 ?
                                <Tooltip placement="top" title={catName}><Typography fontSize={'0.9vw'} fontWeight={600}>{catName?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{catName}</Typography> : '-'}
                            {subCat ? subCat?.length > 15 ?
                                <Tooltip placement="top" title={subCat}><Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{subCat?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{subCat}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'block_name', headerName: 'Block', flex: 0.09,
                renderCell: (params) => {
                    let block = params?.row?.block_name
                    return (
                        <Box component={'div'}>
                            {block ? block?.length > 15 ?
                                <Tooltip placement="top" title={block}><Typography fontSize={'0.9vw'}>{block?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{block}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'floor_name', headerName: 'Floor', flex: 0.09,
                renderCell: (params) => {
                    let floor = params?.row?.floor_name
                    return (
                        <Box component={'div'}>
                            {floor ? floor?.length > 15 ?
                                <Tooltip placement="top" title={floor}><Typography fontSize={'0.9vw'}>{floor?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{floor}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'spot_name', headerName: 'Spot', flex: 0.09,
                renderCell: (params) => {
                    let spot = params?.row?.spot_name
                    return (
                        <Box component={'div'}>
                            {spot ? spot?.length > 15 ?
                                <Tooltip placement="top" title={spot}><Typography fontSize={'0.9vw'}>{spot?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{spot}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'complain_status', headerName: 'Status', flex: 0.09,
                renderCell: (params) => {
                    let status = params?.row?.complain_status
                    return (
                        <Box component={'div'}>
                            {status ? status?.length > 15 ?
                                <Tooltip placement="top" title={status}><Typography fontSize={'0.9vw'}>{status?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{status}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'action', headerName: 'Action', flex: 0.09,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} display={'flex'}>
                            <Button className="Common_Btn_Min_Width"
                                onClick={() => {
                                    this.props.history?.push({ pathname: '/AssetComplaints/NewComplaints', state: { viewData: params?.row } })
                                }}
                            >
                                <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            {this.renderMenu(params?.row)}
                        </Box>
                    )
                }
            },
        ]
        return (
            <Box className="emedhub__purchase_page" sx={{ '& .grid--header': { backgroundColor: '#FAF9F9' } }}>
                <Stack sx={{ marginY: "1vw" }} direction="row" justifyContent="space-between">
                    <Box component={"div"} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {AmountsCard(t("Total"), this.state.total, true, 'white', false, '', 'tlt', '9.5vw')}
                        {AmountsCard(t("Registered"), this.state.registered, true, 'white', false, '', 'rgst', '9.5vw')}
                        {AmountsCard(t("Work Initiated"), this.state.work_initiated, true, 'white', false, '', 'wrkInt', '9.5vw')}
                        {AmountsCard(t("Inprogress"), this.state.workProgress, true, 'white', false, '', 'Inprss', '9.5vw')}
                        {AmountsCard(t("Resolved"), this.state.resolved, true, 'white', false, '', 'rsvld', '9.5vw')}
                        {AmountsCard(t("Work Done"), this.state.work_done, true, 'white', false, '', 'wrdne', '9.5vw')}
                    </Box>
                    <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="flex-end">
                        <div className="emedhub_vendor_btnDiv">
                            <Button variant="contained" size="small" sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} disabled={this.state.printRowData?.length === 0} onClick={() => { this.getAssetGetPassPrint() }}>{t("GENERATE GATE-PASS")}</Button>
                            <Button variant="contained" size="small" sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} onClick={() => {
                                this.props.history.push({ pathname: "/AssetComplaints/NewComplaints", state: { page: "asset_complaints" } })
                            }}>{t("NEW COMPLAINT")}</Button>
                        </div>
                        <Stack direction="row" spacing={1} alignItems="flex-end">
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.Filter.default} onClick={() => { this.setState({ FilterOpen: true }) }} alt='filter' /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} onClick={() => this.getAssetComplaintPrint()} alt='print' /></IconButton>
                            <IconButton size="small"><img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} onClick={() => { this.getAssetComplaintExcel() }} alt='Excel' /></IconButton>
                        </Stack>
                    </Stack>
                </Stack>
                <Box component={'div'} height={'69vh'}>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRows = this.state.Complaints?.filter((row) => selectedIds.has(row.sno))
                            this.setState({ printRowData: selectedRows })
                        }}
                        rows={this.state.Complaints}
                        columns={colums}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            // Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    {'No Records To Be Shown'}
                                </Box>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        paginationMode="server"
                        rowCount={this.state.rowCount}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.loading}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <AssetFilter PageName={"AssetComplaint"} filterDatas={this.state.filterDatas} CloseFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {this.openStatusChangePopUp()}
                {this.openStatusLogPopup()}
            </Box>
        )
    }
}
export default withTranslation()(AssetComplaints)