import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Button, Box, Tooltip, Stack, Divider, Drawer, Typography, TextField } from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { formatDate, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { CommonGridToolBarWithFilterText, PharmaConfirmPopup } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';

let userAccess = null;
class PharmaInactiveDrug extends Component {

  constructor() {
    super()
    this.state = {
      pageSize: 10,
      checkboxSelection: [],
      count: [],
      page: 0,
      InactiveInventoryList: [],
      activeStatus: true,
      count: 0,
      searchkey: '',
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      },
      loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
      isActive: false,
      isBulkActive: false,
      ActiveId: null,
      ActiveDrug: "",
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
    }
  }

  componentDidMount() {
    userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Inventory", 'Inactive Drug List', null, "Tab");
    this.getInActiveInventoryList();
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: "",
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  onChangeHandler = (event, key) => {
    this.setState({
      [key]: event.target.value
    })
  }

  updatePageSize = (newPageSize) => {
    this.setState({
      pageSize: newPageSize
    })
  }

  getInActiveInventoryList = () => {
    try {
      this.LoaderFunction(true);
      this.setState({
        InactiveInventoryList: [],
        activeStatus: true
      })
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&','%26')).replaceAll(','," "): ""
      RestAPIService.getAll(Serviceurls.ACTIVE_DRUG_LIST + `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}&active_status=${false}&search_key=${searchKey}`).
        then((response) => {
          if (response.data.status === "Success") {
            this.LoaderFunction(false);
            this.setState({
              InactiveInventoryList: response.data.result,
              total: response.data.page_count,
              newPageCount: response.data.no_of_page
            })
          }
        }).catch((error) => {this.LoaderFunction(false); this.errorMessage(error.response.data.message)})
    } catch (error) { this.errorMessage(error.message)}
  }

  ActivePost = (isBulk) => {
    let states = this.state;
    if (states.checkboxSelection.length > 0 || states.ActiveId) {
      let datas = {
        active_status: true,
        id: isBulk ? states.checkboxSelection : states.ActiveId
      }
      let url = isBulk ? Serviceurls.PHARMA_ACTIVE_DRUG_POST : Serviceurls.ACTIVE_DRUG_LIST
      try {
        this.LoaderFunction(true);
        // this.setState({disableBtn: true})
        RestAPIService.create(datas, url)
          .then(response => {
            if (response.data.status === "success") {
              this.LoaderFunction(false);
              this.handleBulkNo();
              this.handleActiveNo();
              this.getInActiveInventoryList();
              this.successMessage(response.data.message);
              this.setState({disableBtn: false})
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false);
              this.setState({disableBtn: false})
              this.errorMessage(error.response.data.message);
            }
          });
      } catch (e) {
        this.errorMessage(e.message);
        this.setState({disableBtn: false})
      }
    }
  }

  handleBulkNo = () => {
    this.setState({
      checkboxSelection: [],
      isBulkActive: false
    })
  }

  handleActiveNo = () => {
    this.setState({
      ActiveId: null,
      isActive: false,
      ActiveDrug: "",
    })
  }

  // filter start
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='ind_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("BrandName")}
              sx={{ width: "20vw" }}
              inputProps={{emed_tid: 'BrandName'}}
              size="small"
              autoComplete="off"
              value={states.searchkey ? states.searchkey : ""}
              onChange={(e) => {
                this.setState({
                  searchkey: e.target.value
                })
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button emed_tid='fil_clear' variant='outlined' size="small"
            onClick={() => {
              this.setState({
                searchkey: "",
              }, () => this.getInActiveInventoryList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button emed_tid='fil_search' variant='contained' size="small"
            onClick={() => {
              this.getInActiveInventoryList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  // filter end

  gridToolBar = () => {
    const {t} = this.props;
    // let TextArray = [
    //   { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
    //   { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) }
    // ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText searchHolder = {t("searchBrandName")} />
      </Box>
    )
  }

  onFilterChange = (filterModel) => {
    if(filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0 ){
      this.setState({
        searchkey: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues.join() : ""
      }, () =>  {
        this.getInActiveInventoryList();
      })
    }
  }

  render() {
    this.state.InactiveInventoryList.forEach((element, index) => { 
      let page = this.state.page 
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1) 
      element.serial = serial
      // element.sno = index + 1
     })
    const { t } = this.props
    let states = this.state;
    let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;

    const columns = [
      { field: 'serial', headerName: t('SNo'), sortable: false, flex: 0.05 },
      { field: 'id', headerName: t("Drug ID"), sortable: false, flex: 0.120, headerAlign: "center", align: "center" },
      {
        field: 'drug_name', headerName: t('BrandName'), sortable: false, flex: 0.145,
        renderCell: (params) => (<div>{params?.row?.drug_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.drug_name ? params?.row?.drug_name : "-"}</div>)
      },
      {
        field: 'generic_name', headerName: t('GenericName'), sortable: false, flex: 0.145,
        renderCell: (params) => (<div>{params?.row?.generic_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.generic_name ? params?.row?.generic_name : "-"}</div>)
      },
      {
        field: 'dosage_type', headerName: t('DosageType'), sortable: false, flex: 0.130,
        renderCell: (params) => <div>{params?.row?.dosage_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.dosage_type ? params?.row?.dosage_type : "-"}</div>
      },
      {
        field: 'drug_strength', headerName: t('DosageStrength'), sortable: false, flex: 0.125, headerAlign: "center", align: "center",
        renderCell: (params) => <div>{params?.row?.drug_strength?.length > 14 ? <Tooltip placement="top" title={params?.row?.drug_strength} arrow><div>{params?.row?.drug_strength.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.drug_strength ? params?.row?.drug_strength : "-"}</div>
      },
      {
        field: 'stock_in_quantity', headerName: t('AvailableQuantity'), type: "number", sortable: false, flex: 0.125,
        renderCell: (params) => <div>{params?.row?.stock_in_quantity?.length > 14 ? <Tooltip placement="top" title={params?.row?.stock_in_quantity} arrow><div>{params?.row?.stock_in_quantity.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "0"}</div>
      },
      {
        field: 'hsn_code', headerName: t(`HSN Code`), type: "number", sortable: false, flex: 0.125,
        renderCell: (params) => (<div>{params?.row?.hsn_code?.length > 11 ? <Tooltip placement="top" title={params?.row?.hsn_code} arrow><div>{params?.row?.hsn_code.slice(0, 11) + "..."}</div></Tooltip> : params?.row?.hsn_code ? params?.row?.hsn_code : "-"}</div>)
      },
      {
        field: 'gst_percentage', headerName: t(`GST%`), headerAlign: "center", align: "center", sortable: false, flex: 0.125,
        renderCell: (params) => <div>{params?.row?.gst_percentage ? params?.row?.gst_percentage : "0"}</div>
      },
      {
        field: 'inactive_remarks', headerName: 'Remarks', type: 'string', sortable: false, flex: 0.125,
        renderCell: (params) => (<div>{params?.row?.inactive_remarks?.length > 12 ? <Tooltip placement="top" title={params?.row?.inactive_remarks} arrow><div>{params?.row?.inactive_remarks.slice(0, 12) + "..."}</div></Tooltip> : params?.row?.inactive_remarks ? params?.row?.inactive_remarks : "-"}</div>)
      },
    ]
      
      if(userAccess?.editAccess){
        columns.push(
          {
            field: 'action', headerName: t("Action"), type: 'actions', sortable: false, flex: 0.130, headerAlign: "center", align: "center",
            renderCell: ({row}) => ( <Button emed_tid='ind_tbl_active' variant="contained" className="checkbox" id="eMed_Config_btn" disabled = {this.state.checkboxSelection.length > 0 ? true : false}  onClick = {() => this.setState({isActive: true, ActiveId: row.id, ActiveDrug: row.drug_name})}>{("Active")}</Button>)
          }
        )
      }
    return (
      <div className='active_tab_container'>
        <div className='eMed_Purchase_Table_top'>
        {userAccess?.editAccess ?
          <><Button emed_tid='ind_active' className='eMed_Filter_Btns' variant='contained' size='small'
            disabled = {this.state.checkboxSelection.length === 0 ? true : false} onClick={() => {this.setState({isBulkActive: true})}}
          >{`Active (${this.state.count})`}</Button> 
          {/* <Divider orientation='vertical' /> */}
          </>
          : null}
          {/* <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid='ind_filter' className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip> */}
          {/* <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid='ind_print' className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip title="upload" placement='top' arrow>
            <Button emed_tid='ind_export' className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
            </Button></Tooltip> */}
        </div>
        <div className='active_tab_table_container'>
          <DataGrid
            checkboxSelection={userAccess?.editAccess ? true : false}
            selectionModel={states.checkboxSelection}
            onSelectionModelChange={(checkbox) => {
              this.setState({
                checkboxSelection: checkbox,
                count: checkbox.length,
                activeStatus: false
              })
            }}
            rows={this.state.InactiveInventoryList}
            rowCount={this.state.total}
            page={this.state.page}
            pageSize={this.state.pageSize}
            columns={columns}
            hideFooterSelectedRowCount
            getRowId={(row) => row?.id}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getInActiveInventoryList()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getInActiveInventoryList())}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            paginationMode='server'
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            filterMode="server"
            onFilterModelChange={this.onFilterChange}
          />
        </div>
        {
          this.state.isBulkActive ? <PharmaConfirmPopup
            popupTitle={t("BulkActive")}
            handleNo={this.handleBulkNo.bind(this)}
            handleYes={this.ActivePost.bind(this, true)}
            disableBtn={this.state.disableBtn}
          /> : null
        }
        {
          this.state.isActive ? <PharmaConfirmPopup
            popupTitle={`${t("individualActive")} ${this.state.ActiveDrug ? this.state.ActiveDrug : "-"} ?`}
            handleNo={this.handleActiveNo.bind(this)}
            handleYes={this.ActivePost.bind(this, false)}
            disableBtn={this.state.disableBtn}
          /> : null
        }
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>
    )
  }
}

export default withTranslation()(PharmaInactiveDrug)
