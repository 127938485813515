import {
    Box, Button, Drawer, FormControlLabel, Radio, RadioGroup,
    Stack, TextField, Tooltip, Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { CommonGridToolBarWithFilterTextCustom, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { appointment_Status_opt } from '../../../../Utility/Constants';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';

const fieldMapping = {
    "Arrived": "patient_arrived_time",
    "With Nurse": "with_nurse_time",
    "With Optometry": "with_optometry_time",
    "Optometry Completed": "with_opto_completed_time",
    "With Doctor": "patient_with_doctor_time",
    "With Counsellor": "with_counsellor_time",
    "Scan/Surgery": "with_scansurgery_time",
    "Completed": "patient_completed_time"
};

class OpthotatReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            tatData: [],
            PatientName: "",
            uhid: "",
            flt_appoint_status: "all",
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterOpen: false,
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            total_patient_count: 0,
            clinic_id: getCachevalue('SelectedDoctorClinic'),
        }
    }

    componentDidMount = () => {
        let loggedInUserInfo = JSON.parse(localGetItem('loggedInUserInfo'));
        let appointmentStatus = JSON.parse(localGetItem('appointment_status')) || loggedInUserInfo?.appointment_status || appointment_Status_opt;
        const { t } = this.props;

        const column = [
            {
                field: "patientName",
                headerName: t("Patient Name"),
                flex: 0.0837,
                renderCell: ({ row }) => (
                    <Box>{row.patientName?.length > 12 ? (
                        <Tooltip title={row.patientName}>
                            <div>{row.patientName?.slice(0, 11)}...</div>
                        </Tooltip>
                    ) : row.patientName || "-"}</Box>
                )
            },
            {
                field: "patient_details",
                headerName: t("Patient Details"),
                flex: 0.083,
                renderCell: ({ row }) => (
                    <Box>{row.patient_details?.length > 10 ? (
                        <Tooltip title={row.patient_details}>
                            <div>{row.patient_details.slice(0, 8)}...</div>
                        </Tooltip>
                    ) : row.patient_details || "-"}</Box>
                )
            },
            {
                field: "waiting_timer",
                headerName: t("Total Waiting Time"),
                headerAlign: "center", align: 'center',
                flex: 0.083,
                renderCell: ({ row }) => <Box>{row.waiting_timer || "-"}</Box>
            },
            {
                field: "turn_around_time",
                headerName: t("TAT"),
                headerAlign: "center", align: 'center',
                flex: 0.083,
                renderCell: ({ row }) => <Box>{row.turn_around_time || "-"}</Box>
            }
        ];

        const ConfiguredStatus = appointmentStatus?.reduce((acc, item) => {
            const field_name = fieldMapping[item.label];
            if (item.is_checked && field_name) {
                acc.push({
                    field: field_name,
                    headerName: t(item.label),
                    headerAlign: "center", align: 'center',
                    flex: 0.083,
                    renderCell: ({ row }) => <Box>{row[field_name] || "-"}</Box>
                });
            }
            return acc;
        }, []);
        let finalColumns = [];
        if (ConfiguredStatus?.length > 0) {
            finalColumns = [
                ...column.slice(0, 2),
                ...ConfiguredStatus,
                ...column.slice(2)
            ];
        } else {
            finalColumns = [...column];
        }

        this.setState({ columns: finalColumns },
            () => {
                this.getOptTatDetails();
            });
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getOptTatDetails = () => {
        try {
            let states = this.state;
            this.LoaderFunction(true);
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : '';
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : '';
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.DOC_OPT_TAT_GET
                + `?clinic_id=${states.clinic_id}&from_date=${states.fromDate}&to_date=${states.toDate}&appointment_status=${states.flt_appoint_status}&patient_name=${states.PatientName}&uhid=${states.uhid}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        let Data = response.data.data?.map(item => ({
                            ...item,
                            patientName: `${item.patient_names} ${item.patient_age ? item.patient_age : ""} ${item.patient_gender ? item.patient_gender : ""}`,
                            patient_details: `${item.patient_dob ? formatDate(item.patient_dob) : "-"} | ${item.patient_mobile_number ? item.patient_mobile_number : "-"}`,
                        })) || [];

                        this.setState({
                            tatData: Data,
                            FilteredFromDate: states.fromDate,
                            FilteredToDate: states.toDate,
                            total_patient_count: response.data?.total_appointment_count || 0,
                        })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getOptTatDetailsPdf = () => {
        try {
            let states = this.state;
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : '';
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : '';
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAllPrint(Serviceurls.DOC_OPT_TAT_GET
                + `?clinic_id=${states.clinic_id}&from_date=${states.fromDate}&to_date=${states.toDate}&appointment_status=${states.flt_appoint_status}&patient_name=${states.PatientName}&uhid=${states.uhid}&start_time=${setStartTime}&end_time=${setEndTime}&export_type=pdf`).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleCheckBox = (e, key) => {
        this.setState({ [key]: e.target.checked })
    }

    selectedTime = (start, end, error) => {
        this.setState(prevState => ({
            FilterDatas: {
                ...prevState.FilterDatas,
                startTime: start,
                endTime: end
            },
            timeError: error
        }));
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box sx={{ width: '22dvw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            inputProps={{ maxLength: 15 }}
                            size='small'
                            label={t("Patient Name")}
                            name="PatientName"
                            value={this.state.PatientName}
                            onChange={(e) => {
                                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                                if (e.target.value === "" || isSpecial) {
                                    this.setState({ PatientName: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"UHID"}
                            autoComplete='off'
                            sx={{ width: "20dvw" }}
                            size='small'
                            value={this.state.uhid}
                            onChange={(e) => {
                                this.setState({
                                    uhid: e.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown' sx={{ width: '22.5dvw' }}>
                        <Typography sx={{ mb: '0.3vw' }}>Appointment Status</Typography>
                        <RadioGroup row sx={{ display: 'flex' }} value={this.state.flt_appoint_status} onChange={(e) => { this.setState({ flt_appoint_status: e.target.value }) }}>
                            <FormControlLabel value={"all"} control={<Radio inputProps={{ 'emed_tid': `flt_optTat_allBtn` }} />} label="All" />
                            <FormControlLabel value={"completed"} control={<Radio inputProps={{ 'emed_tid': `flt_optTat_completedBtn` }} />} label="Completed" />
                            <FormControlLabel value={"others"} control={<Radio inputProps={{ 'emed_tid': `flt_optTat_othersBtn` }} />} label="Others" />
                        </RadioGroup>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                PatientName: "",
                                uhid: "",
                                flt_appoint_status: 'all',
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false,
                                },
                            })
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getOptTatDetails()
                            this.closeFilter()
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        var { t } = this.props;
        let loggedInUserInfo = JSON.parse(localGetItem('loggedInUserInfo'));
        let appointmentStatus = JSON.parse(localGetItem('appointment_status')) || loggedInUserInfo?.appointment_status || appointment_Status_opt;
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        let ExportData = [];
        if (this.state.tatData?.length > 0) {
            this.state.tatData?.forEach((item) => {
                let exportItem = {
                    "Patient Name": item.patientName,
                    "Patient Details": item.patient_details,
                };

                appointmentStatus?.forEach(status => {
                    if (status.is_checked) {
                        const fieldName = fieldMapping[status.label];
                        exportItem[status.label] = item[fieldName] || "-";
                    }
                });
                exportItem["Total Waiting Time"] = item.waiting_timer;
                exportItem["TAT"] = item.turn_around_time;
                ExportData.push(exportItem);
            });
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom
                    FilterTextArray={TextArray}
                    data={ExportData}
                    title={"Ip Patient List"}
                    filename={"IpPatientList"} />
            </Box>
        )
    }

    render() {
        this.state.tatData?.forEach((element, index) => element.sno = index + 1);
        const { t } = this.props;
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalPatients"), this.state.total_patient_count, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.getOptTatDetailsPdf() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.tatData || []}
                        columns={this.state.columns || []}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={70}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}
export default withTranslation()(OpthotatReport)