import { Box, Button, Drawer, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CurrencySymbol } from '../../../../Utility/Constants';

class StockOverview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stockOverviewData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'SO_BrandName': '',
        'SO_GenName': '',
        'SO_DosType': '',
        'groupName': null,
        "dosageType": [],
      },
      pageName: 'stockOverview',
      filterOpen: false,
      totalAmount: 0,
      cgst: 0,
      sgst: 0,
      discountAmount: 0,
      netAmount: 0,
      sortDirection: 'asc',
      DrugTypeFilter : "active"
    }
  }

  componentDidMount() {
    this.getStockOverOverviewData()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page: 0,
    }, () => { this.getStockOverOverviewData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    
    return (
      <Box>
        <CommonGridToolBarWithFilterText noExport={true} />
      </Box>

    )
  }

  getStockOverOverviewData = () => {
    try {
      let brandName = this.state.filterDatas?.SO_BrandName?.replaceAll('&','%26')
      this.LoaderFunction(true)
      let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
      let DrugTypeFilter = this.state.DrugTypeFilter === 'all' ? "" : (this.state.DrugTypeFilter === 'active' ? "&is_active=true" : "&is_active=false")
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_OVERVIEW_REPORT_GET + `?page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.SO_GenName}&dosage_type=${this.state.filterDatas?.dosageType}&order=${''}&group_name=${group}${DrugTypeFilter}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              stockOverviewData: response.data.data?.result,
              rowCount: response.data.data?.total_count
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getStockOverOverviewExcel = () => {
    try {
        let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
        this.setState({disableBtn: true})
        let brandName = this.state.filterDatas?.SO_BrandName?.replaceAll('&','%26')
        let DrugTypeFilter = this.state.DrugTypeFilter === 'all' ? "" : (this.state.DrugTypeFilter === 'active' ? "&is_active=true" : "&is_active=false")
        RestAPIService.excelGet(Serviceurls.PHARMA_INVENTORY_STOCK_OVERVIEW_REPORT_GET + `?page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.SO_GenName}&dosage_type=${this.state.filterDatas?.dosageType}&order=${''}&group_name=${group}&export=excel${DrugTypeFilter}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]
                    // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({disableBtn: false})
                }
            }).catch((error) => {
                if (error.response?.data?.message) {
                    this.errorMessage(error.response?.data?.message);
                } else {
                    this.errorMessage(error.message);
                }
                this.setState({disableBtn: false})
            })
    } catch (error) {
        this.errorMessage(error.message);
        this.setState({disableBtn: false})
    }
}

  stringOrNumberSort = (v1, v2) => {
    let sorted = gridStringOrNumberComparator(v1, v2);
    return sorted;
  }

  StockOverviewPrint = () => {
    try {
      this.setState({disabled: true})
      let brandName = this.state.filterDatas?.SO_BrandName?.replaceAll('&','%26')
      let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
      let DrugTypeFilter = this.state.DrugTypeFilter === 'all' ? "" : (this.state.DrugTypeFilter === 'active' ? "&is_active=true" : "&is_active=false")
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_OVERVIEW_REPORT_GET + `?page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${brandName}&generic_name=${this.state.filterDatas?.SO_GenName}&dosage_type=${this.state.filterDatas?.dosageType}&order=${''}&group_name=${group}&export=pdf${DrugTypeFilter}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disabled: false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disabled: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disabled: false})
    }
  }

  render() {
    const { t } = this.props
    this.state.stockOverviewData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'drugDetails', headerName: "Drug Details", flex: 0.12, density: 'compact',
        renderCell: (params) => (<Box component={'div'}>
          <CommonCorporateAndInsuranceDetails fromPharma={true} data={params?.row} />
        </Box>), valueGetter: ({ row }) => row?.brand_name, sortComparator: this.stringOrNumberSort, sortingOrder: ['asc', 'desc']
      },
      {
        field: 'dosage_strength', headerName: "Dosage Strength", flex: 0.1, headerAlign: "center", align: "center", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.dosage_strength?.length > 12 ? <Tooltip placement='top' title={params?.row?.dosage_strength} arrow><div>{params?.row?.dosage_strength?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.dosage_strength ? params?.row?.dosage_strength : '-'}
        </Box>)
      },
      {
        field: 'batch_no', headerName: "Batch No.", flex: 0.09, headerAlign: "center", align: "center", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.batch_no ? params?.row?.batch_no : '-'}
        </Box>)
      },
      {
        field: 'expiry_date', headerName: "Expiry Date", flex: 0.09, headerAlign: 'center', align: 'center', density: 'compact',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.expiry_date?.length > 12 ? <Tooltip placement='top' title={params?.row?.expiry_date} arrow><div>{params?.row?.expiry_date?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.expiry_date ? params?.row?.expiry_date : '-'}
        </Box>)
      },
      {
        field: 'schedule', headerName: `Schedule`, flex: 0.08, density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.schedule?.length > 12 ? <Tooltip placement='top' title={params?.row?.schedule} arrow><div>{params?.row?.schedule?.slice(0, 10) + '...'}</div></Tooltip>
          : params.row?.schedule ? params.row?.schedule : '-'}</Box>)
      },
      {
        field: 'group_name', headerName: `Group`, flex: 0.08, density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.group_name?.length > 12 ? <Tooltip placement='top' title={params.row?.group_name} arrow><div>{params.row?.group_name?.slice(0, 10) + '...'}</div></Tooltip>
        : params.row?.group_name ? params.row?.group_name : '-'}</Box>)
      },
      {
        field: 'available_stock', headerName: `Purchase Qty`, flex: 0.09, type: "number", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.available_stock ? params.row?.available_stock : '0'}</Box>)
      },
      {
        field: 'mrp_per_quantity', headerName: `MRP/QTY`, flex: 0.08, type: "number", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.mrp_per_quantity ? params.row?.mrp_per_quantity : '0'}</Box>)
      },
      {
        field: 'price', headerName: `${"Price"} (${CurrencySymbol})`, flex: 0.09, type: "number", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.price ? AmountFormat(params.row?.price).replace(`${CurrencySymbol}`, "") : '-'}</Box>)
      },
      {
        field: 'physical_box_no', headerName: `Box No.`, flex: 0.09, type: "number", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.physical_box_no ? params.row?.physical_box_no : '0'}</Box>)
      },
      {
        field: 'epr_value', headerName: `EPR Value`, flex: 0.09, type: "number", density: 'compact',
        renderCell: (params) => (<Box component={'div'}>{params.row?.epr_value ? params.row?.epr_value : '0'}</Box>)
      },
    ]
    const getStockOverOverviewData = (type) => {
      try {
        let Type = type ? type === 'asc' ? 'dosage_type' : "dosage_type_dec" : ''
        let DrugTypeFilter = this.state.DrugTypeFilter === 'all' ? "" : (this.state.DrugTypeFilter === 'active' ? "&is_active=true" : "&is_active=false")
        RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_OVERVIEW_REPORT_GET + `?page=${this.state.page + 1}&page_size=${this.state.pageSize}&order=${Type}${DrugTypeFilter}`)
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                stockOverviewData: response.data.data?.result
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } catch (error) {
        this.errorMessage(error.message)
      }
    }
 
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header' sx={{ height : "7vh"}}>
          <Box component={'div'} flex={0.7} display={'flex'}>

          </Box>
          <Box flex={0.5} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <FormControl sx={{ marginLeft: "1.5vw", display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30vw', justifyContent: 'end' }}>
              <RadioGroup
                value={this.state.DrugTypeFilter}
                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                onChange={(e) => {
                  this.setState({ DrugTypeFilter: e.target.value }, () => {
                    this.getStockOverOverviewData()
                  })
                }}
                emed_tid='active_inactive_filter'
              >
                <FormControlLabel value={"all"} control={<Radio size='small' />} label="All" />
                <FormControlLabel value={"active"} control={<Radio size='small' />} label="Active Drugs" />
                <FormControlLabel value={"inactive"} control={<Radio size='small' />} label="Inactive Drugs" />
              </RadioGroup>
            </FormControl>
            <Button emed_tid="rpt_stkOver_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.StockOverviewPrint()}/>
            </Button>
            <Button emed_tid = "rpt_stkOver_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getStockOverOverviewExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card' sx={{ height : "72vh"}}>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.stockOverviewData}
            // columns={columns}
            columns={columns.map((column) => {
              if (column.field === 'dosage_type') {
                return {
                  ...column,
                  field: 'dosage_type', sortable: false ,
                  headerName: (
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={() => this.setState({
                      sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc'
                    },()=>{
                      getStockOverOverviewData(this.state.sortDirection)
                    })}>
                      {column.headerName}
                      {this.state.sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </div>  
                  ),
                };
              }
              return column;
            })}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            density={'compact'}
            rowHeight={100}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowCount={this.state.rowCount}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getStockOverOverviewData()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getStockOverOverviewData())}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            paginationMode='server'
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDosType={true} calGrp={true}/>
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(StockOverview)