import { Button, Divider, Stack, Tooltip, IconButton, } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { formatDate, CheckAccessFunc, AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CurrencySymbol } from '../../../Utility/Constants';


let userAccess = null;
let RoleData = null;

class PharmaDeliveryChellan extends Component {
    constructor(props) {
      super(props);
      this.state = {
        DcData: [],
        searchkey: "",
        pageSize: 10,
        current_page: 0,
      }
    }

    componentDidMount() {
        RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
        userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Delivery Challan', null, "Tab");
        this.DeliveryChallanList()
        if(this.props.history.location.state?.DirectPoSuccessMsg){
            this.successMessage(this.props.history.location.state?.DirectPoSuccessMsg)
            this.props.history.push({ state: { } })
          }
    }

    DeliveryChallanList = () => {
        try {
        this.LoaderFunction(true);
        this.setState({
            DcData: [],
            pageSize:10,
            current_page:0
        })
        RestAPIService.getAll(Serviceurls.PURCHASE_DC_POST + "?detailed_view=false"  + "&dc_number=" + "&vendor_id=").
            then((response) => {
            if (response.data.status === "success") {
                let arr = []
                response?.data?.data?.forEach((item)=>{
                    item.no_of_TotalItem = `${ item.no_of_items ? `${item.no_of_items_moved_po ? item.no_of_items_moved_po : 0} | ${item.no_of_items}` : "-"}`
                    arr.push(item)
                })
                this.LoaderFunction(false);
                this.setState({
                DcData: arr,
                })
            }
            else {
                this.LoaderFunction(false);
                this.errorMessage(response.data.message)
            }
            }).catch((error) => {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
            })
        }
        catch (e) {
        this.errorMessage(e.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
          successMsg: true,
          successMsgText: message
        })
      }

      msgClose() {
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }

    handleCellClick = (param, event) => { event.stopPropagation() }

    renderTable = () => {
        const {t} = this.props
        let {DcData, pageSize, current_page} = this.state
        DcData?.forEach((element,index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerClassName: 'grid--header', headerAlign:"center", align: "center", headerName: t('SNo'), flex: 0.04, hideable: false},
            { field: 'dc_number', headerClassName: 'grid--header', headerAlign:"center", headerName: t('DC Number'), flex: 0.142, align:"center",
            renderCell:(params)=> (<div>{params?.row?.dc_number?.length > 20 ? <Tooltip placement="top" title={params?.row?.dc_number} arrow><div>{params?.row?.dc_number.slice(0,20)+"..."}</div></Tooltip> : params?.row?.dc_number ? params?.row?.dc_number : "-"}</div>)},
            { field: 'dc_date', headerClassName: 'grid--header', headerAlign:"center", headerName: t('DC Date'), flex: 0.112, align:"center",
            renderCell:(params)=> (<div>{params?.row?.dc_date ? formatDate(params?.row?.dc_date) : "-"}</div>)},
            { field: 'vendor_name', headerClassName: 'grid--header', headerName: t('Vendor Name'), flex: 0.15,
            renderCell:(params)=> (<div>{params?.row?.vendor_name?.length > 20 ? <Tooltip placement="top" title={params?.row?.vendor_name} arrow><div>{params?.row?.vendor_name.slice(0,20)+"..."}</div></Tooltip> : params?.row?.vendor_name ? params?.row?.vendor_name : "-"}</div>)},
            { field: 'no_of_TotalItem', headerClassName: 'grid--header', headerName: t("No of Items"), flex: 0.1, type:"number",
            renderCell:(params)=> (<div>{(params?.row?.no_of_items && params?.row?.no_of_items_moved_po) ? params?.row?.no_of_items_moved_po + " / " + params?.row?.no_of_items : params?.row?.no_of_items ? 0 + " / " + params?.row?.no_of_items : "-"}</div>)},
            { field: 'total_amount', headerClassName: 'grid--header', headerName: t(`Total Amount (${CurrencySymbol})`), flex: 0.132, type:"number", renderCell: ({row}) =>(<div>{row?.total_amount ? AmountFormat(row?.total_amount).replace(`${CurrencySymbol}`,"") : "-"}</div>)},
            { field: 'actions', headerClassName: 'grid--header', headerName: t('Action'),type :'actions', flex: 0.08, sortable: false, hideable: false,
            renderCell : ({ row })=>{
                return(
                    <Tooltip title={(row?.is_finished || !userAccess?.editAccess)? t("View") : t("Edit")} placement="top" arrow>
                        <div>
                        <IconButton  sx={{ borderRadius:"0.3vw"}} 
                        onClick = {() => {
                            this.props.history.push(
                                {
                                    pathname: "/PharmacyPurchase/DeliveryChallan/MoveToPO",
                                    state: {
                                        dc_data: row,
                                        from_page: "DC-View"
                                    }
                                })
                        }} 
                        ><img className="emedhub_vendor_img2" src={(row?.is_finished || !userAccess?.editAccess) ? ImagePaths.View.default : ImagePaths.LabEditButton.default} alt={row?.is_finished ? "View" : "Edit"} /></IconButton>
                        </div>
                    </Tooltip>
                );
            }}
        ]
        return (
            
            <div id = "emedhub_deliveryChallen_Table">
            <DataGrid 
                    rows={this.state?.DcData?.length > 0 ? this.state?.DcData : []}
                    columns={columns}
                    hideFooterSelectedRowCount
                    getRowId={(row) => row.sno}
                    components={{
                        Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: ()=>{
                            return(
                                <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"53vh"}}>
                                {t("NoRecordsFound")}
                                </Stack>
                            )
                        }
                        }}
                        localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                        }}
                    onCellClick = {this.handleCellClick}
                    onRowClick = {this.handleCellClick}
                    rowCount={this.state?.DcData ? this.state?.DcData?.length : 0}
                    headerHeight={40}
                    page={current_page}
                    onPageChange={(newPage)=>this.setState({ current_page: newPage })}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination 
                    loading={this.state.isLoader}/>
            </div>
        )
    }

    render(){
        const {t} = this.props;
        return(
            <div>
                <div className='eMed_Purchase_Table_top'>
                {
                    userAccess?.editAccess ?
                    <><Button emed_tid='drt_po' className='eMed_Filter_Btns' variant='contained' size='small'
                        onClick={() => {
                        this.props.history.push({ pathname: '/PharmacyPurchase/DeliveryChallan/Create', state: { from_page: "DC" }  })
                        }}
                    >{`Create DC`}</Button> 
                    <Divider orientation='vertical' /></> 
                    : null
                }
                </div>
                {this.renderTable()}
                {this.state.isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={this.state.isErrorMsgText}
                    msgPop={this.msgClose.bind(this)}
                />
                : null}
                {this.state.successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={this.state.successMsgText}
                    msgPop={this.msgClose.bind(this)}
                />
                : null}
            </div>
        )
    }
}
export default withTranslation()(PharmaDeliveryChellan)