import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, InputAdornment, FormControlLabel, Checkbox, Switch, Stack } from '@mui/material'
import { DateTime } from 'luxon'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import CircularProgress from '@mui/material/CircularProgress';


const Status_List = [
    { value: 'Partially Delivered', label: 'Partial Delivered' },
    // { value: 'Placed Order', label: 'Placed Order' },
    { value: 'Completely Delivered', label: 'Completed Delivered' },
]

const Active_Inactive_Status = [
    { value: 'All', label: 'All' },
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
]

const DrugMovementMonthList = [
    { value: 1, label: '1 Month' },
    { value: 2, label: '2 Months' },
    { value: 3, label: '3 Months' },
    { value: 4, label: '4 Months' },
    { value: 5, label: '5 Months' },
    { value: 6, label: '6 Months' },
    { value: 7, label: '7 Months' },
    { value: 8, label: '8 Months' },
    { value: 9, label: '9 Months' },
    { value: 10, label: '10 Months' },
    { value: 11, label: '11 Months' },
    { value: 12, label: '12 Months' },
]

class PharmaFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            filterOpen: false,
            filterDatas: {
                "fromDate": this.props.filterDatas.fromDate,
                "toDate": this.props.filterDatas.toDate,
                "dateIndex": this.props.filterDatas.dateIndex,
                'patientName': this.props.filterDatas.patientName,
                'drugName': this.props.filterDatas.drugName,
                'schedule': this.props.filterDatas.schedule,
                'month': this.props.filterDatas.month,
                'vendorName': this.props.filterDatas.vendorName,
                'status': this.props.filterDatas.status,
                'brandName': this.props.filterDatas.brandName,
                'isActive': this.props.filterDatas.isActive,
                'genericName': this.props.filterDatas.genericName,
                'quantityFrom': this.props.filterDatas.quantityFrom,
                'quantityTo': this.props.filterDatas.quantityTo,
                'vendorOne': this.props.filterDatas.vendorOne,
                'vendorTwo': this.props.filterDatas.vendorTwo,
                'vendorThree': this.props.filterDatas.vendorThree,
                'SO_BrandName': this.props.filterDatas.SO_BrandName,
                'SO_GenName': this.props.filterDatas.SO_GenName,
                'SO_DosType': this.props.filterDatas.SO_DosType,
                'selectDate': this.props.filterDatas.selectDate,
                'groupName': this.props.filterDatas.groupName,
                'isSheduleOnly': this.props.filterDatas.isSheduleOnly,
                'hsnWise': this.props.filterDatas.hsnWise,
                'report_type': this.props.filterDatas.report_type,
                'days': this.props.filterDatas.days,
                'billNumber': this.props.filterDatas.billNumber,
                'billFrom': this.props.filterDatas.billFrom,
                'billTo': this.props.filterDatas.billTo,
                'drugId': this.props.filterDatas.drugId,
                'dsv_drugName': this.props.filterDatas.dsv_drugName,
                'dsv_genericName': this.props.filterDatas.dsv_genericName,
                'dsv_dosageType': this.props.filterDatas.dsv_dosageType,
                'dsv_groupName': this.props.filterDatas.dsv_groupName,
                'doctorName': this.props.filterDatas.doctorName,
                'withGstCheck': this.props.filterDatas.withGstCheck,
                "vendorList": this.props.filterDatas.vendorList,
                "dosageType": this.props.filterDatas.dosageType,
                "dosageType_list": this.props.filterDatas.dosageType_list,
                "is_bill_wise": this.props.filterDatas.is_bill_wise,
                "OPSelected": this.props.filterDatas.OPSelected,
                "IPSelected": this.props.filterDatas.IPSelected,
                "SelfSelected": this.props.filterDatas.SelfSelected,
                "UserList" : this.props.filterDatas.UserList,
                "UserDetails" : this.props.filterDatas.UserDetails,
                "movement_period": this.props.filterDatas.movement_period,
                "fromMonth": this.props.filterDatas.fromMonth,
                "toMonth": this.props.filterDatas.toMonth,
            },
            open: false,
            brandListData: [],
            genericListData: [],
            vendorListData: [],
            isBrandLoader: false,
            groupData: [],
            disableBtn: false,
            doctorList: [],
            purchaseVendorList: [],
            dosageTypeList: [],
            isTextFocused: false,
        }
    }

    componentDidMount() {
        if(this.props.calGrp) this.getGroupData()
        if(this.props.calDoc) this.getDoctorList()
        if(this.props.calPurVen) this.getPurchaseVendorNameData()
        if(this.props.calDosType) this.getDosageTypeListData()
    }

    getGroupData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHRAMCY_GROUP_FILTER)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            groupData: response.data.data ? response.data.data : []
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getDosageTypeListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_DOSAGE_TYPE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            dosageTypeList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    filterDate = (From, To, Index) => {
        this.state.filterDatas.fromDate = From
        this.state.filterDatas.toDate = To
        this.state.filterDatas.dateIndex = Index
        this.setState({ FilterDatas: this.state.filterDatas })
    }

    LoaderFunction = (key) => {
        this.setState({
            isBrandLoader: key
        })
    }

    clearFilterData = () => {
        this.setState({
            filterDatas: {
                "fromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "toDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                'patientName': '',
                'drugName': '',
                'schedule': '',
                'month': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'vendorName': '',
                'status': [],
                'brandName': '',
                'isActive': 'All',
                'quantityFrom': '',
                'quantityTo': '',
                'genericName': '',
                'vendorOne': '',
                'vendorTwo': '',
                'vendorThree': '',
                'selectDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'SO_BrandName': '',
                'SO_GenName': '',
                'SO_DosType': '',
                "groupName": null,
                'isSheduleOnly': false,
                'hsnWise': this.props.filterDatas.hsnWise,
                'report_type': this.state.filterDatas.report_type,
                'days': null,
                'billNumber': '',
                'billFrom': '',
                'billTo': '',
                'drugId': '',
                'dsv_drugName': '',
                'dsv_genericName': '',
                'dsv_dosageType': '',
                'dsv_groupName': null,
                'doctorName': null,
                'withGstCheck': false,
                'vendorList': [],
                "dosageType": [],
                "dosageType_list": this.props.filterDatas.dosageType_list,
                "is_bill_wise": false,
                "OPSelected": true,
                "IPSelected": true,
                "SelfSelected": true,
                "UserDetails": [],
                "movement_period": { value: 1, label: '1 Month' },
                'fromMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            }, 
            disableBtn: true,
            isTextFocused: false,
        }, () => {
            this.props.ClearData(this.state.filterDatas)
            this.filterSearch()
        })
    }

    closeFilter() {
        this.setState({
            filterOpen: false
        })
    }

    filterSearch() {
        let states = this.state
        if (this.props.pageName === "salesDetailed") {
            if(!states.filterDatas?.OPSelected && !states.filterDatas?.IPSelected && !states.filterDatas?.SelfSelected){
                this.errorMessage("Please select any one of the patient type")
            } else if (states.filterDatas.billFrom <= states.filterDatas.billTo) {
                this.setState({ filterOpen: false, disableBtn: true });
                this.props.filteredData(this.state.filterDatas);
            } else {
                this.errorMessage("Bill To No is Greater then Bill From No");
            }
        }
        else if(this.props.pageName === "salesReturn" || this.props.pageName === "salesOverview"){
            if (!states.filterDatas?.OPSelected && !states.filterDatas?.IPSelected && !states.filterDatas?.SelfSelected) {
                this.errorMessage("Please select any one of the patient type")
            } else {
                this.setState({ filterOpen: false, disableBtn: true });
                this.props.filteredData(this.state.filterDatas);
            }
        } else {
            this.setState({ filterOpen: false, disableBtn: true });
            this.props.filteredData(this.state.filterDatas);
        }

    }

    onChangeHandler = (e, names) => {
        let value = e.target.value
        switch (names) {
            case 'quantityFrom':
                if (CommonValidation.NumberWithDot(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'quantityTo':
                if (CommonValidation.NumberWithDot(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'drugId':
                if (CommonValidation.numberOnly(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'schedule':
                this.state.filterDatas[names] = value
                this.setState({
                    filterDatas: this.state.filterDatas
                })
                break;
            case 'invoiceNo':
                if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.state.filterDatas.billNumber = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'billNumber':
                if (CommonValidation.numberOnly(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'billFrom':
                if (CommonValidation.numberOnly(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'billTo':
                if (CommonValidation.numberOnly(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
            case 'brandName':
                if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
            case 'drugName':
                if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
            case 'SO_BrandName':
            case 'dsv_drugName':
                if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
            default:
                if (CommonValidation.alphabetOnly(value) || value === '') {
                    this.state.filterDatas[names] = value
                    this.setState({
                        filterDatas: this.state.filterDatas
                    })
                }
                break;
        }
    }

    inputTextBox = (values, names, label, width) => {
        return (
            <TextField
                size='small'
                autoComplete='off'
                sx={{ width: width, margin: '0.5vw' }}
                defaultValue={values}
                label={label}
                inputProps={{ emed_tid: label }}
                onChange={(e) => { this.onChangeHandler(e, names) }}
            />
        )
    }

    removeGenericDuplicates(data) {
        let uniqueArr = [], uniqueObj = {};
        for (let i in data) {
            let objDisplayName = data[i]['generic_name'];
            uniqueObj[objDisplayName] = data[i];
        }
        for (let key in uniqueObj) {
            uniqueArr.push(uniqueObj[key]);
        }
        return uniqueArr;
    }

    getBrandListData = () => {
        try {
            this.LoaderFunction(true);
            let brandName = this.state.filterDatas.brandName?.replaceAll('&', '%26')
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_BRAND_GET + `?brand_name=${brandName}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            brandListData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.data)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGenericListData = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&', '%26')
            RestAPIService.getAll(`${Serviceurls.PHARMA_INVENTORY_GENERIC_GET}?brand_name=${brandName}`)
                .then((resposne) => {
                    if (resposne.data.status === 'success') {
                        let list = this.removeGenericDuplicates(resposne.data.data);
                        this.setState({
                            genericListData: list?.length > 0 ? list : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.data)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getVendorListData = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PHARMA_INVENTORY_VENDOR_GET}?brand_name=${this.state.filterDatas.brandName}&generic_name=${this.state.filterDatas.genericName}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vendorListData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.data)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            doctorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPurchaseVendorNameData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + '?from=purchase_order')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            purchaseVendorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    dosageAutoComplete = () => {
        try {
            return (
                <Autocomplete
                    freeSolo
                    size='small'
                    sx={{ width: '13.5vw', m: '0.5vw' }}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    disablePortal
                    options={this.state.dosageTypeList}
                    getOptionLabel={(option) => option}
                    value={this.state.filterDatas.dosageType}
                    onFocus={() => this.setState({ isTextFocused: true })}
                    onBlur={() => this.setState({ isTextFocused: false })}
                    onChange={(e, value) => {
                        if (value.length <= 7) {
                            this.state.filterDatas.dosageType = value
                            this.setState({ filterDatas: this.state.filterDatas })
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Select Dosage Type'
                            helperText={this.state.isTextFocused && this.state.filterDatas.dosageType?.length === 7 ? 'Maximum Options Selected' : ''}
                            FormHelperTextProps={{
                                style: {
                                    color: 'red',
                                },
                            }}
                        />}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleCheckBox = (e, stateKey) => {
        let { filterDatas } = this.state
        filterDatas[stateKey] = e.target.checked
        this.setState({ filterDatas })
        
    }

    render() {
        const { t } = this.props
        return (
            <Box component={'div'} className='eMed_Filter_Billing_Container'>
                <Box component={"div"} className='eMed_Filter_Top'>
                    <Typography variant='h6' fontWeight={"600"}>{t("Filter")}</Typography>
                    <Button emed_tid="rpt_flt_close" onClick={() => { this.setState({ filterOpen: false }, () => { this.props.closeFilter() }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                {(this.props.pageName === 'salesMonthly' || this.props.pageName === 'purchaseMonthly' || this.props.pageName === "consolidatedSales") ?
                    <Box style={{ width: "40%", margin: "1vw", marginLeft: "17.5vw" }} component={"div"}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                open={this.state.open}
                                onOpen={() => { this.setState({ open: true }) }}
                                onClose={() => { this.setState({ open: false }) }}
                                inputFormat={this.props.pageName === 'consolidatedSales' ? 'yyyy' : 'MM-yyyy'}
                                views={this.props.pageName === 'consolidatedSales' ? ['year'] : ['month', 'year']}
                                label={'year and Month'}
                                minDate={this.props.pageName === 'consolidatedSales' ? new Date((new Date).getFullYear() - 13, (new Date).getMonth() - 5, 0) : new Date((new Date).getFullYear() - 3, (new Date).getMonth() - 5, 0)}
                                maxDate={this.props.pageName === 'expiryStock' ? null : new Date()}
                                value={this.state.filterDatas.month}
                                onChange={(value) => {
                                    this.state.filterDatas.month = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                    this.setState({
                                        filterDatas: this.state.filterDatas
                                    })
                                }}
                                emed_tid={"rpt_consoli_yrMnth"}
                                renderInput={(params) => <TextField size="small" sx={{ width: "9.8vw" }} {...params}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ open: true }) }} />}
                            />
                        </LocalizationProvider>
                    </Box> :
                    this.props.pageName === 'expiryStock' ?
                        <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'28dvw', marginTop: "1vw", marginLeft:'0.5vw' }}>
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    open={this.state.todate}
                                    onOpen={() => { this.setState({ todate: true }) }}
                                    onClose={() => { this.setState({ todate: false }) }}
                                    inputFormat={'MM-yyyy'}
                                    views={['month', 'year']}
                                    label={'From Month'}
                                    minDate={new Date((new Date).getFullYear() - 3, (new Date).getMonth() - 5, 0)}
                                    // maxDate={new Date((this.state.filterDatas.toMonth))}
                                    value={this.state.filterDatas.fromMonth}
                                    onChange={(value) => {
                                        this.state.filterDatas.fromMonth = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                        this.state.filterDatas.toMonth = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                        this.setState({
                                            filterDatas: this.state.filterDatas
                                        })
                                    }}
                                    emed_tid={"rpt_consoli_yrMnth"}
                                    renderInput={(params) => <TextField size="small" sx={{ width: "13vw" }} {...params}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onClick={() => { this.setState({ todate: true }) }} />}
                                />
                            </LocalizationProvider> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    open={this.state.open}
                                    onOpen={() => { this.setState({ open: true }) }}
                                    onClose={() => { this.setState({ open: false }) }}
                                    inputFormat={'MM-yyyy'}
                                    views={['month', 'year']}
                                    label={'Expiry Till'}
                                    // minDate={new Date((this.state.filterDatas.fromMonth))}
                                    // maxDate={new Date(new Date(this.state.filterDatas.fromMonth).setMonth(new Date(this.state.filterDatas.fromMonth).getMonth() + 3))}
                                    value={this.state.filterDatas.toMonth}
                                    onChange={(value) => {
                                        this.state.filterDatas.toMonth = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                                        this.setState({
                                            filterDatas: this.state.filterDatas
                                        })
                                    }}
                                    emed_tid={"rpt_consoli_yrMnth"}
                                    renderInput={(params) => <TextField size="small" sx={{ width: "13vw" }} {...params}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onClick={() => { this.setState({ open: true }) }} />}
                                />
                            </LocalizationProvider>
                        </Box> :
                        this.props.pageName === 'stockOverview' ? null :
                            (this.props.pageName === 'lowStockDrug' || this.props.pageName === "stockValue" || this.props.pageName === 'pharmaNoMovement') ? null :
                                <Box style={{ width: "40%", margin: "1vw", marginLeft: "17.5vw" }} component={"div"}>
                                    <CommonDatePicker
                                    FromDate={this.state.filterDatas.fromDate}
                                    ToDate={this.state.filterDatas.toDate}
                                    DateIndex={this.state.filterDatas.dateIndex}
                                    SelectedDates={this.filterDate.bind(this)}
                                    HideAllMenu={true}
                                    PharmaDateFilterRestriction={true}
                                    // Future= {this.props.pageName === 'MISReport' ? true : false}
                                    // DisablePast = {this.props.pageName === 'MISReport' ? true : false}
                                    days={this.state.filterDatas.days}
                                    fromStockValue={this.props.pageName === "drugWiseStockValue" ? true : false}
                                />
                            </Box>}
                {
                    this.props.pageName === 'pharmaNoMovement' ?
                        <Box>
                            <Autocomplete
                                sx={{ width: '20vw', marginTop:'1vw', marginLeft:'0.5vw' }}
                                disableClearable
                                size='small'
                                value={this.state.filterDatas?.movement_period ? this.state.filterDatas?.movement_period : null}
                                options={DrugMovementMonthList}
                                getOptionLabel={(option) => (option?.label)}
                                onChange={(event, newValue) => {
                                    this.state.filterDatas.movement_period = newValue
                                    this.setState({ filterDatas: this.state.filterDatas })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('No Movement Period')} />
                                )}
                            />
                        </Box> : null
                }

                {this.props.pageName === 'salesOverview' || this.props.pageName === 'salesReturn' ?
                    <Box component={'div'}>
                        {this.props.pageName === 'salesReturn' ? this.inputTextBox(this.state.filterDatas.brandName, 'brandName', 'Brand Name', '28vw') : null}
                        {this.inputTextBox(this.state.filterDatas.patientName, 'patientName', "Patient Name", '13.5vw')}
                        {this.inputTextBox(this.state.filterDatas.billNumber, 'billNumber', "Bill Number", '13.5vw')}
                        <Autocomplete
                            size='small'
                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                            options={this.state.doctorList}
                            getOptionLabel={(option) => option.name}
                            value={this.state.filterDatas.doctorName}
                            onChange={(event, newValue) => {
                                this.state.filterDatas.doctorName = newValue
                                this.setState({ filterDatas: this.state.filterDatas })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Doctor Name')}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.name}
                                </li>
                            )}
                        />
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"}>{t("Patient Type")}</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.filterDatas.OPSelected} onChange={(e) => { this.handleCheckBox(e, "OPSelected") }} size='small' />} label={t("OutPatient")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.filterDatas.IPSelected} onChange={(e) => { this.handleCheckBox(e, "IPSelected") }} size='small' />} label={t("InPatient")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.filterDatas.SelfSelected} onChange={(e) => { this.handleCheckBox(e, "SelfSelected") }} size='small' />} label={t("Self")} />
                            </Box>
                        </Box>
                    </Box> : null
                }
                {this.props.pageName === "salesDetailed" ?
                    <Box component={'div'}>
                        {this.inputTextBox(this.state.filterDatas.patientName, 'patientName', 'Patient Name', '13.5vw')}
                        {this.inputTextBox(this.state.filterDatas.drugName, 'drugName', 'Drug Name', '13.5vw')}
                        <Box component={'div'} display={'flex'} flexDirection={'row'}>
                            {this.inputTextBox(this.state.filterDatas.schedule, 'schedule', 'Schedule', '13.5vw')}
                            <Autocomplete
                                size='small'
                                sx={{ width: "13.5vw", margin: "0.5vw" }}
                                options={this.state.groupData}
                                getOptionLabel={(option) => option.group}
                                value={this.state.filterDatas.groupName}
                                onChange={(event, newValue) => {
                                    this.state.filterDatas.groupName = newValue
                                    this.setState({ filterDatas: this.state.filterDatas })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('Select Group Name')}
                                        on
                                    />
                                )}
                            />
                        </Box>
                        {this.inputTextBox(this.state.filterDatas.billFrom, 'billFrom', 'Bill No From', '13.5vw')}
                        {this.inputTextBox(this.state.filterDatas.billTo, 'billTo', 'Bill No To', '13.5vw')}
                        <Box display={'flex'}>
                            {this.dosageAutoComplete()}
                            <FormControlLabel
                                sx={{ margin: '0.1vw' }}
                                control={<Checkbox checked={this.state.filterDatas.isSheduleOnly}
                                    onChange={(e) => {
                                        this.state.filterDatas.isSheduleOnly = e.target.checked;
                                        this.setState({ filterDatas: this.state.filterDatas })
                                    }} size='small' />} label="Schedule Only" />
                        </Box>
                        <Box component={"div"}>
                            <Box className="eMed_Filter_Header">
                                <Typography marginLeft={"1vw"}>{t("Patient Type")}</Typography>
                            </Box>
                            <Box className="eMed_CheckBox_Group">
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.filterDatas.OPSelected} onChange={(e) => { this.handleCheckBox(e, "OPSelected") }} size='small' />} label={t("OutPatient")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.filterDatas.IPSelected} onChange={(e) => { this.handleCheckBox(e, "IPSelected") }} size='small' />} label={t("InPatient")} />
                                <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.filterDatas.SelfSelected} onChange={(e) => { this.handleCheckBox(e, "SelfSelected") }} size='small' />} label={t("Self")} />
                            </Box>
                        </Box>
                    </Box> :
                    this.props.pageName === "purchaseOverview" ?
                        <Box component={'div'}>
                            {this.inputTextBox(this.state.filterDatas.billNumber, 'invoiceNo', 'Invoice No', '13.5vw')}
                            <Stack direction="row" spacing={1} alignItems="center" margin={'.5vw'} marginLeft={'1vw'}>
                                <Typography>Bill Wise</Typography>
                                <Switch size='small' color="primary" checked={this.state.filterDatas.is_bill_wise ? true : false}
                                    onClick={(e) => {
                                        this.state.filterDatas.is_bill_wise = e.target.checked;
                                        this.setState({
                                            filterDatas: this.state.filterDatas
                                        })
                                    }} />
                                <Typography>GRN Entry Wise</Typography>
                            </Stack>
                        </Box> :
                        this.props.pageName === "Product Wise Sales" ?
                            <Box component={'div'}>
                                {this.inputTextBox(this.state.filterDatas.brandName, 'brandName', 'Brand Name', '13.5vw')}
                                {this.inputTextBox(this.state.filterDatas.drugId, 'drugId', 'Drug ID', '13.5vw')}
                                {this.dosageAutoComplete()}
                            </Box>
                            :
                            this.props.pageName === "purchaseDetailed" ?
                                <Box component={'div'}>
                                    <Box component={'div'} sx={{ width: '28vw', margin: '0.5vw' }}>
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            size='small'
                                            options={Status_List}
                                            getOptionLabel={(option) => (option?.label)}
                                            value={this.state.filterDatas.status}
                                            onChange={(event, newValue) => {
                                                this.state.filterDatas.status = newValue
                                                this.setState({ filterDatas: this.state.filterDatas })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Select Status')}
                                                />
                                            )}
                                        />
                                    </Box>
                                    {this.inputTextBox(this.state.filterDatas.drugName, 'drugName', 'Drug Name', '13.5vw')}
                                    {this.inputTextBox(this.state.filterDatas.vendorName, 'vendorName', 'vendor Name', '13.5vw')}
                                    <Box display={'flex'}>
                                        {this.inputTextBox(this.state.filterDatas.billNumber, 'invoiceNo', 'Bill Number', '13.5vw')}
                                        {this.dosageAutoComplete()}
                                    </Box>
                                    <Stack direction="row" spacing={1} alignItems="center" margin={'.5vw'} marginLeft={'1vw'}>
                                        <Typography>Bill Wise</Typography>
                                        <Switch size='small' color="primary" checked={this.state.filterDatas.is_bill_wise ? true : false}
                                            onClick={(e) => {
                                                this.state.filterDatas.is_bill_wise = e.target.checked;
                                                this.setState({
                                                    filterDatas: this.state.filterDatas
                                                })
                                            }} />
                                        <Typography>GRN Entry Wise</Typography>
                                    </Stack>

                                </Box> :
                                (this.props.pageName === 'expiryStock' || this.props.pageName === "slowMovementDrug" || this.props.pageName === 'pharmaNoMovement' || this.props.pageName === 'lowStockDrug') ?
                                    <Box component={'div'}>
                                        {this.inputTextBox(this.state.filterDatas.brandName, 'brandName', 'Brand Name', '28vw')}
                                        {this.dosageAutoComplete()}
                                    </Box>
                                    :
                                    this.props.pageName === 'stockCorrectionLog' ?
                                        <Box component={'div'}>
                                            <FormControl size='small' sx={{ width: '20vw', margin: '0.5vw' }}>
                                                <InputLabel>Select Status</InputLabel>
                                                <Select
                                                    label='Select Status'
                                                    value={this.state.filterDatas.isActive}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        this.state.filterDatas['isActive'] = value
                                                        this.setState({
                                                            filterDatas: this.state.filterDatas
                                                        })
                                                    }}
                                                >
                                                    {Active_Inactive_Status.map((item) => (
                                                        <MenuItem emed_tid={item.label} value={item.value} key={item.value}>{item.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box> :
                                        this.props.pageName === 'stockValue' ?
                                            <Box component={'div'}>
                                                {this.dosageAutoComplete()}
                                                {/* {this.inputTextBox(this.state.filterDatas.drugName, 'drugName', 'Drug Name', '13.5vw')}
                                                {this.inputTextBox(this.state.filterDatas.genericName, 'genericName', 'Generic Name', '13.5vw')}
                                                <Box component={'div'}>
                                                    {this.inputTextBox(this.state.filterDatas.quantityFrom, 'quantityFrom', 'Stock Quantity From', '13.5vw')}
                                                    {this.inputTextBox(this.state.filterDatas.quantityTo, 'quantityTo', 'Stock Quantity To', '13.5vw')}
                                                </Box> */}
                                            </Box> :
                                            this.props.pageName === 'MISReport' ?
                                                <Box component={'div'}>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Autocomplete
                                                            freeSolo
                                                            size='small'
                                                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                            options={this.state.brandListData?.map((item) => item)}
                                                            getOptionLabel={(option) => (option)}
                                                            value={this.state.filterDatas.brandName}
                                                            onChange={(event, newValue) => {
                                                                this.state.filterDatas.brandName = newValue
                                                                this.setState({ filterDatas: this.state.filterDatas }, () => this.getGenericListData())
                                                            }}
                                                            onInputChange={(e, brandName) => {
                                                                this.state.filterDatas.brandName = brandName
                                                                this.setState({ filterDatas: this.state.filterDatas }, () => {
                                                                    if (this.state.filterDatas.brandName?.length > 3) {
                                                                        this.getBrandListData()
                                                                    }
                                                                })
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('Select Brand Name')}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "Enter" && this.state.filterDatas.brandName?.length > 2) {
                                                                            this.getBrandListData()
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        emed_tid: "BrandName",
                                                                        endAdornment: this.state.isBrandLoader ?
                                                                            <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                                                            : null,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <Autocomplete
                                                            size='small'
                                                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                            disabled={this.state.filterDatas.brandName === '' ? true : false}
                                                            options={this.state.genericListData?.map((item) => item.generic_name)}
                                                            getOptionLabel={(option) => (option)}
                                                            value={this.state.filterDatas.genericName}
                                                            onChange={(event, newValue) => {
                                                                this.state.filterDatas.genericName = newValue
                                                                this.setState({ filterDatas: this.state.filterDatas }, () => this.getVendorListData())
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('Select Generic Name')}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        emed_tid: "GenericName"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Autocomplete
                                                            size='small'
                                                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                            disabled={this.state.filterDatas.genericName === '' ? true : false}
                                                            options={this.state.vendorListData}
                                                            getOptionLabel={(option) => (typeof (option?.vendor_name) === "string" ? option?.vendor_name : option)}
                                                            value={this.state.filterDatas.vendorOne}
                                                            onChange={(event, newValue) => {
                                                                if (newValue === "") {
                                                                    this.getVendorListData()
                                                                }
                                                                this.state.filterDatas.vendorOne = newValue
                                                                this.setState({ filterDatas: this.state.filterDatas })
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('Select Vendor One')}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        emed_tid: "VndOne"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <Autocomplete
                                                            size='small'
                                                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                            disabled={this.state.filterDatas.vendorOne === '' ? true : false}
                                                            options={this.state.vendorListData}
                                                            getOptionLabel={(option) => typeof (option?.vendor_name) === "string" ? option?.vendor_name === this.state.filterDatas?.vendorOne?.vendor_name ? [] : option?.vendor_name : option}
                                                            value={this.state.filterDatas.vendorTwo}
                                                            onChange={(event, newValue) => {
                                                                this.state.filterDatas.vendorTwo = newValue
                                                                this.state.filterDatas["vendorErr1"] = false;
                                                                if (this.state.filterDatas.vendorOne === newValue) {
                                                                    this.errorMessage(`${newValue} is already selected`)
                                                                    this.state.filterDatas["vendorErr1"] = true;
                                                                }
                                                                this.setState({ filterDatas: this.state.filterDatas })
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    error={this.state.filterDatas.vendorErr1}
                                                                    label={t('Select Vendor Two')}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        emed_tid: "VndTwo"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Autocomplete
                                                            size='small'
                                                            sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                            disabled={this.state.filterDatas.vendorTwo === '' ? true : false}
                                                            options={this.state.vendorListData}
                                                            getOptionLabel={(option) => (typeof (option?.vendor_name) === "string" ? ((option?.vendor_name === this.state.filterDatas?.vendorOne?.vendor_name) || (option?.vendor_name === this.state.filterDatas?.vendorTwo?.vendor_name)) ? [] : option?.vendor_name : option)}
                                                            value={this.state.filterDatas.vendorThree}
                                                            onChange={(event, newValue) => {
                                                                this.state.filterDatas.vendorThree = newValue
                                                                this.state.filterDatas["vendorErr2"] = false;
                                                                if (this.state.filterDatas.vendorOne === newValue) {
                                                                    this.errorMessage(`${newValue} is already selected`)
                                                                    this.state.filterDatas["vendorErr2"] = true;
                                                                }
                                                                this.setState({ filterDatas: this.state.filterDatas })
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    error={this.state.filterDatas.vendorErr2}
                                                                    label={t('Select Vendor Three')}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        emed_tid: "VndThree"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {this.dosageAutoComplete()}
                                                    </Box>
                                                </Box>
                                                :
                                                this.props.pageName === 'patientReport' ?
                                                    <Box component={'div'}>
                                                        {this.inputTextBox(this.state.filterDatas.patientName, 'patientName', 'Patient Name', '13.5vw')}
                                                    </Box> :
                                                    this.props.pageName === 'HsnWise' ?
                                                        <Box component={'div'}>
                                                            <Autocomplete
                                                                size='small'
                                                                sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                                options={this.state.filterDatas.hsnWise}
                                                                getOptionLabel={(option) => (typeof (option?.label) === "string" ? option?.label : option)}
                                                                value={this.state.filterDatas.report_type}
                                                                onChange={(event, newValue) => {
                                                                    this.state.filterDatas.report_type = newValue
                                                                    this.setState({ filterDatas: this.state.filterDatas })
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={t('Select Hsn Wise')}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            emed_tid: "HsnWise"
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        :
                                                        this.props.pageName === "profitAnalysis" ?
                                                            <Box component={'div'} marginLeft={"0.5vw"}>
                                                                {this.inputTextBox(this.state.filterDatas.drugName, 'drugName', 'Drug Name', '16vw')}
                                                                <Box component={'div'} marginLeft={"0.5vw"}>
                                                                    <FormControlLabel control={<Checkbox checked={this.state.filterDatas?.withGstCheck} size='small'
                                                                        onChange={(e) => {
                                                                            this.state.filterDatas.withGstCheck = e.target.checked
                                                                            this.setState({ filterDatas: this.state.filterDatas })
                                                                        }} />} label="Show Without GST" />
                                                                </Box>
                                                            </Box>
                                                            : this.props.pageName === "drugWiseStockValue" ? <Box>
                                                                {this.inputTextBox(this.state.filterDatas.dsv_drugName, 'dsv_drugName', 'Brand Name', '28vw')}
                                                                {/* {this.inputTextBox(this.state.filterDatas.dsv_genericName, 'dsv_genericName', 'Generic Name', '13.5vw')} */}
                                                                <Box display={'flex'} flexDirection={'row'}>
                                                                    {this.dosageAutoComplete()}
                                                                    <Autocomplete
                                                                        size='small'
                                                                        sx={{ width: "13.5vw", margin: "0.5vw" }}
                                                                        options={this.state.groupData}
                                                                        getOptionLabel={(option) => option.group}
                                                                        value={this.state.filterDatas.dsv_groupName}
                                                                        onChange={(event, newValue) => {
                                                                            this.state.filterDatas.dsv_groupName = newValue
                                                                            this.setState({ filterDatas: this.state.filterDatas })
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label={t('Select Group Name')}
                                                                                on
                                                                            />
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                                : this.props.pageName === "purchaseGST" ?
                                                                    <Box component={'div'}>
                                                                        <Stack direction="row" spacing={1} alignItems="center" margin={'.5vw'} marginLeft={'1vw'}>
                                                                            <Typography>Bill Wise</Typography>
                                                                            <Switch size='small' color="primary" checked={this.state.filterDatas.is_bill_wise ? true : false}
                                                                                onClick={(e) => {
                                                                                    this.state.filterDatas.is_bill_wise = e.target.checked;
                                                                                    this.setState({
                                                                                        filterDatas: this.state.filterDatas
                                                                                    })
                                                                                }} />
                                                                            <Typography>GRN Entry Wise</Typography>
                                                                        </Stack>
                                                                    </Box> 
                                                                    : this.props.pageName === "userSalesPerformance" ?
                                                                        <Box component={'div'} className='eMed_Filter_DateFilter'>
                                                                            <Autocomplete
                                                                                multiple
                                                                                limitTags={1}
                                                                                size='small'
                                                                                options={this.state.filterDatas?.UserList || []}
                                                                                getOptionLabel={(options) => options?.user_name || ''}
                                                                                onChange={(e, value) => {
                                                                                    this.setState((prevState) => ({
                                                                                        filterDatas: {
                                                                                            ...prevState.filterDatas,
                                                                                            UserDetails: value
                                                                                        }
                                                                                    }))
                                                                                }}
                                                                                disableCloseOnSelect
                                                                                disablePortal
                                                                                autoComplete='off'
                                                                                value={this.state?.filterDatas?.UserDetails || null}
                                                                                id="combo-box-demo"
                                                                                sx={{ width: "20dvw" }}
                                                                                renderInput={(params) => <TextField {...params} label={t('UserName')} size='small' />}
                                                                            />
                                                                        </Box>
                                                                        : null
                }
                {
                    this.props.pageName === "stockOverview" ?
                        <Box>
                            {this.inputTextBox(this.state.filterDatas.SO_BrandName, 'SO_BrandName', 'Brand Name', '13.5vw')}
                            {this.inputTextBox(this.state.filterDatas.SO_GenName, 'SO_GenName', 'Generic Name', '13.5vw')}
                            <Box display={'flex'} flexDirection={'row'}>
                                {this.dosageAutoComplete()}
                                <Autocomplete
                                    size='small'
                                    sx={{ width: "13.5vw", margin: "0.5vw" }}
                                    options={this.state.groupData}
                                    getOptionLabel={(option) => option.group}
                                    value={this.state.filterDatas.groupName}
                                    onChange={(event, newValue) => {
                                        this.state.filterDatas.groupName = newValue
                                        this.setState({ filterDatas: this.state.filterDatas })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Select Group Name')}
                                            on
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        : null
                }
                {this.props.pageName === 'creditNote' ?
                    <Box component={'div'} padding={'1vw'}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            sx={{ width: '20vw' }}
                            options={this.state.purchaseVendorList}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.filterDatas.vendorList}
                            onChange={(event, newValue) => {
                                this.state.filterDatas.vendorList = newValue
                                this.setState({ filterDatas: this.state.filterDatas })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Vendor Name')}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.vendor_id}>
                                    {option?.name}
                                </li>
                            )}
                        />
                    </Box> : null
                }

                <Box component={"div"} className="eMed_Filter_Btn_Div">
                    <Button emed_tid="rpt_flt_clear" size="small" className='eMed_Filter_Btns' disabled={this.state.disableBtn} onClick={() => { this.clearFilterData() }} variant='outlined'>{t("Clear")}</Button>
                    <Button emed_tid="rpt_flt_search" size="small" className='eMed_Filter_Btns' disabled={this.state.disableBtn} onClick={() => { this.filterSearch() }} variant='contained'>{t("Search")}</Button>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(PharmaFilter)