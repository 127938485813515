import { Box, Button, Stack, TextField, Tooltip, Drawer } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import SubLocReportFilter from '../Reports/SubLocReportFilter'

export default class SubLocWantedList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wantedListData: [],
      page: 0,
      pageSize: 10,
      drugRequestList: [],
      pageName: 'wantedList',
      selectedPharmacyId: null,
      filterDatas: {
        'toLocationName': null,
      },
      isMultiPharmacy: false,
      isDiffPharmaFound: false,
    }
  }

  componentDidMount() {
    this.getToLocationListData();
    this.getWantedListData();
  }

  getWantedListData = () => {
    try {
      const {filterDatas, isMultiPharmacy} = this.state;
      this.LoaderFunction(true)
      let url = Serviceurls.SUBLOC_STOCK_TFR_WANTED_LIST_GET;
      if(isMultiPharmacy){
        url = url + `?from_pharmacy_id=${filterDatas?.toLocationName?.pharmacy_id || ""}`
      }
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              wantedListData: response.data.data
            }, () => {
              this.state.wantedListData?.forEach((element, index) => {
                element.sno = index + 1
                element.request_quantity = ''
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  getToLocationListData = () => {
    try {
        RestAPIService.getAll(Serviceurls.SUBLOC_FROM_LOCATION_GET)
            .then((response) => {
                if (response.data.status === 'success') {   
                  this.setState({
                    isMultiPharmacy: response.data.data?.filter((item) => item.is_primary_pharmacy)?.length > 1 || false
                  })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    } catch (error) {
        this.errorMessage(error.message)
    }
}

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onChangeHandler = (e, values) => {
    try {
      const { name, value } = e.target
      switch (name) {
        case 'request_quantity':
          if ((CommonValidation.numberOnly(value) && value?.length <= 10) || value === '') {
            this.state.wantedListData[values.id - 1]['request_quantity'] = value
            this.setState({ wantedListData: this.state.wantedListData })
          }
          break;
        default:
          this.setState({
            [name]: value
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  InputTextBox = (names, values, label, width) => {
    try {
      let row = values.row
      return (
        <TextField
          size='small'
          sx={{ width: width }}
          inputProps={{
            emed_tid: { names }
          }}
          label={label}
          name={names}
          value={row[names]}
          onChange={(e) => this.onChangeHandler(e, values)}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClickCheckBoxHandler = (ids) => {
    try {
      const selectedIds = new Set(ids);
      const selectedRows = this.state.wantedListData?.filter((row) => selectedIds.has(row.sno));
      if (selectedRows.length > 0) {
        const selectedPharmaId = selectedRows[0]?.from_pharmacy_id;
        const isDiffPharmaFound = selectedRows?.some((item) => item?.from_pharmacy_id !== selectedPharmaId);
        if (isDiffPharmaFound) {
          this.setState({
            isDiffPharmaFound: true
          }, () => {
            this.errorMessage("Drugs from multiple pharmacies cannot be included in a single request.")
          })
        } else {
          this.setState({
            isDiffPharmaFound: false,
            selectedPharmacyId: {
              pharmacy_id: selectedRows[0]?.from_pharmacy_id,
              module_name: selectedRows[0]?.from_pharmacy_name
            },
            drugRequestList: selectedRows,
          });
        }
      } else {
        this.setState({ selectedPharmacyId: null, drugRequestList: [] });
      }
    } catch (error) {
      this.errorMessage(error.message);
    }
  };
  

  checkRequest = () => {
    try {
      let message = null;
      if (this.state.drugRequestList?.length > 0) {
        this.state.drugRequestList?.forEach(element => {
          if (!element.request_quantity) {
            message = "Enter Request Quantity"
          }
        })
      } else if (this.state.isDiffPharmaFound){
        message = "Drugs from multiple pharmacies cannot be included in a single request.";
      } else {
        message = "Select items for drug request"
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
      this.getWantedListData()
    })
  }

  render() {
    const column = [
      {
        field: 'drug_name', headerName: 'Brand Name', flex: 0.166,
        renderCell: (params) => (<Box>{params?.row?.drug_name ?
          params?.row?.drug_name?.length > 25 ?
            <Tooltip placement='top' title={params?.row?.drug_name} arrow><Box>{params?.row?.drug_name.slice(0, 25) + '...'}</Box></Tooltip>
            : params?.row?.drug_name : '-'}</Box>)
      },
      {
        field: 'generic_name', headerName: 'Generic Name', flex: 0.166,
        renderCell: (params) => (<Box>{params?.row?.generic_name ?
          params?.row?.generic_name?.length > 25 ?
            <Tooltip placement='top' title={params?.row?.generic_name} arrow><Box>{params?.row?.generic_name.slice(0, 25) + '...'}</Box></Tooltip>
            : params?.row?.generic_name : '-'}</Box>)
      },
      {
        field: 'dosage_type', headerName: 'Dosage Type', flex: 0.166,
        renderCell: (params) => (<Box>{params?.row?.dosage_type}</Box>)
      },
      {
        field: 'drug_strength', headerName: 'Dosage Strength', flex: 0.166, hederAlign: 'center', align: 'center',
        renderCell: (params) => (<Box>{params?.row?.drug_strength}</Box>)
      },
      {
        field: 'stock_in_quantity', headerName: 'Available Quantity', flex: 0.166, type: 'number',
        renderCell: (params) => (<Box>{params?.row?.stock_in_quantity}</Box>)
      },
      {
        field: 'request_quantity', headerName: 'Request Quantity', flex: 0.166,
        renderCell: (params) => (
          <Box>{this.InputTextBox("request_quantity", params, null)}</Box>
        )
      }
    ]

    if (this.state.isMultiPharmacy) {
      column.splice(4, 0, {
        field: 'from_pharmacy_name', headerName: 'Pharmacy Name', flex: 0.166,
        renderCell: (params) => (<Box>{params?.row?.from_pharmacy_name ?
          params?.row?.from_pharmacy_name?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.from_pharmacy_name} arrow>
              <Box>{params?.row?.from_pharmacy_name.slice(0, 20) + '...'}</Box></Tooltip>
            : params?.row?.from_pharmacy_name : '-'}</Box>)
      })
    }

    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '80vh' }}>
        <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Button
              size='small'
              sx={{ mr: '1vw', height: '2vw', textTransform: 'capitalize' }}
              variant='contained'
              disabled={CheckAccessFunc("sub_location", "Stock Transfer", "Wanted List",  null, "Tab")?.editAccess === false}
              endIcon={this.state.drugRequestList?.length ? `(${this.state.drugRequestList?.length})` : null}
              onClick={() => {
                let request = this.checkRequest()
                if (request === null) {
                  this.props.history.push({
                    pathname: "/SubLocationStockTransfer/RaiseRequest", state: {
                      drugRequestList: this.state.drugRequestList,
                      page: "wanted",
                      selectedPharmacy: this.state.selectedPharmacyId
                    }
                  })
                } else {
                  this.errorMessage(request)
                }
              }}
            >Raise Request</Button>
          <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            {this.state.isMultiPharmacy ? <Box pr={'1vw'}>
              <Button
                className='eMed_common'
                sx={{ width: '2vw', height: '2vw', mr: '1vw' }}
                onClick={() => this.setState({ filterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box> : null}
            {/* need to work start */}
            {/* <Box>
              <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', ml: '1vw', mr: '1vw' }}>
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', mr: '1vw' }}>
                <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box> */}
            {/* need to work end */}
          </Box>
        </Box>
        <Box component={'div'} height={'73vh'} p={'1vw'}>
          <DataGrid
            checkboxSelection
            onSelectionModelChange={(ids) => { this.onClickCheckBoxHandler(ids) }}
            sx={{ backgroundColor: 'white' }}
            columns={column}
            disableColumnFilter
            rows={this.state.wantedListData}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
