import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTime } from 'luxon'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'

const Months = [
  { label: "1 Month", value: 1 },
  { label: '2 Months', value: 2 },
  { label: '3 Months', value: 3 },
]

const StatusList = [
  {label: "Initiated", value: 'Initiated' },
  {label: "In Transit", value: 'In Transit' },
  {label: "Approved", value: 'Approved' }
]

const TransferList = [
  {label: "Inbound", value: 'Inbound' },
  {label: "Outbound", value: 'Outbound' },
  {label: "Returned", value: 'Returned' }
]

export default class PharmaBranchReportFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      filterOpen: false,
      filterDatas: {
        'fromDate': this.props.filterDatas.fromDate,
        'toDate': this.props.filterDatas.toDate,
        'dateIndex': this.props.filterDatas.dateIndex,
        'brandName': this.props.filterDatas.brandName,
        'months': this.props.filterDatas.months,
        'toLocationName': this.props.filterDatas.toLocationName,
        'status': this.props.filterDatas.status,
        'trans_type': this.props.filterDatas.trans_type,
        'selectMonth': this.props.filterDatas.selectMonth,
      },
      brandListData: [],
      toLocationNameList: [],
      brandName: '',
      openPickaer: false,
    }
  }
componentDidMount(){
  this.getToLocationListData()
}
  getBrandListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_DRUG_SEARCH + `?search_key=${this.state.brandName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              brandListData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getToLocationListData = () => {
    try {
        RestAPIService.getAll(Serviceurls.PHA_BRANCH_TOLOC)
            .then((response) => {
                if (response.data.status === 'success') {
                        this.setState({
                            toLocationNameList: response.data.data,
                        })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    } catch (error) {
        this.errorMessage(error.message)
    }
}

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  filterDate = (From, To, Index) => {
    this.state.filterDatas.fromDate = From
    this.state.filterDatas.toDate = To
    this.state.filterDatas.dateIndex = Index
    this.setState({ FilterDatas: this.state.filterDatas })
  }

  clearFilterData = () => {
    this.setState({
      filterDatas: {
        "fromDate": '',
        "toDate": '',
        "dateIndex": 1,
        'brandName': '',
        'months': 1,
        'toLocationName': '',
        'status': '',
        'selectMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      },
    }, () => {
      this.props.ClearData(this.state.filterDatas)
      this.filterSearch()
    })
  }


  closeFilter() {
    this.setState({
      filterOpen: false
    })
  }

  filterSearch() {
    this.setState({ filterOpen: false })
    this.props.filteredData(this.state.filterDatas)
  }

  render() {
    var status_list = this.props.pageName === 'PharmaInbound' ? StatusList.filter(item => item.value !== 'Initiated') : StatusList
    var trans_list = this.props.pageName === 'PhramaStockTransferReport' ? TransferList.filter(item => item.value !== 'Inbound') : TransferList
    return (
      <Box component={'div'} className='eMed_Filter_Billing_Container'>
        <Box component={"div"} className='eMed_Filter_Top'>
          <Typography variant='h6' fontWeight={"600"}>{"Filter"}</Typography>
          <Button emed_tid="rpt_flt_close" onClick={() => { this.setState({ filterOpen: false }, () => { this.props.closeFilter() }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box style={{ width: "40%", margin: "1vw", marginLeft: "17.5vw" }} component={"div"}>
          {this.props.pageName === 'StockExpiry' ?
            <FormControl size='small' sx={{ width: '10vw' }}>
              <InputLabel>Select Month</InputLabel>
              <Select
                label='Select Month'
                value={this.state.filterDatas.months}
                onChange={(e) => {
                  this.state.filterDatas.months = e.target.value
                  this.setState({ filterDatas: this.state.filterDatas })
                }}
              >
                {Months.map((item, index) => (
                  <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                ))}
              </Select>
            </FormControl> :
            this.props.pageName === "SubLocExpiryStock" ?
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    open={this.state.openPickaer}
                    onOpen={() => { this.setState({ openPickaer: true }) }}
                    onClose={() => { this.setState({ openPickaer: false }) }}
                    inputFormat={'MM-yyyy'}
                    views={['month', 'year']}
                    label={'Year and Month'}
                    minDate={new Date((new Date).getFullYear() - 3, (new Date).getMonth() - 5, 0)}
                    // maxDate={new Date()}
                    value={this.state.filterDatas.selectMonth}
                    onChange={(value) => {
                      this.state.filterDatas.selectMonth = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
                      this.setState({
                        filterDatas: this.state.filterDatas
                      })
                    }}
                    emed_tid={"rpt_expiry_yrMnth"}
                    renderInput={(params) => <TextField size="small" sx={{ width: "9.8vw" }} {...params}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() => { this.setState({ openPickaer: true }) }}
                       />}
                  />
                </LocalizationProvider> :
            <CommonDatePicker
              FromDate={this.state.filterDatas.fromDate}
              ToDate={this.state.filterDatas.toDate}
              DateIndex={this.state.filterDatas.dateIndex}
              SelectedDates={this.filterDate.bind(this)}
              HideAllMenu={false}
              PharmaDateFilterRestriction={true}
            />
          }
        </Box>
        <Box component={'div'}>
          {this.props.pageName === 'SubStockList' || 
          this.props.pageName === 'StockExpiry' || 
          this.props.pageName === 'SubConsumptionReport' ||
          this.props.pageName === 'SubLocExpiryStock'  ?
            <Autocomplete
              size='small'
              clearIcon
              sx={{ width: "28vw", margin: "0.5vw" }}
              options={this.state.brandListData}
              filterOptions={(options) => options}
              getOptionLabel={(options) => typeof (options) === 'string' ? options : options.drug_name}
              value={this.state.filterDatas.brandName}
              onChange={(e, newValue) => {
                this.state.filterDatas.brandName = newValue?.drug_name
                this.setState({
                  filterDatas: this.state.filterDatas
                })
              }}
              onInputChange={(e, value) => this.setState({ brandName: value })}
              renderInput={(params) => <TextField label='Select Brand Name' {...params}
                inputProps={{ ...params.inputProps, emed_tid: 'rpt_brand_name' }}
                onKeyDown={(e) => {
                  if (this.state.brandName?.length >= 3) {
                    this.getBrandListData()
                }
                }}
              />}
            /> : 
            this.props.pageName === 'PharmaBranchInbound' || 
            this.props.pageName === 'pharmaBranchOutbound' ? 
              <Box margin={'1vw'}>
                <Autocomplete
                  size='small'
                  sx={{ width: "26vw"}}
                  options={this.state.toLocationNameList}
                  getOptionLabel={(options) => typeof (options) === 'string' ? options : options.pharmacy_name}
                  value={this.state.filterDatas?.toLocationName?.pharmacy_name}
                  onChange={(e, newValue) => {
                    this.state.filterDatas.toLocationName = newValue
                    this.setState({ filterDatas: this.state.filterDatas })
                  }}
                  renderInput={(params) => <TextField {...params} label={this.props.pageName === 'PharmaInbound' || this.props.pageName === 'StockTransferReport' ? "From Location Name" : "To Location Name"} />}
                />
                {this.props.pageName === 'StockTransferReport' ? null : 
                <Autocomplete
                  size='small'
                  sx={{ width: "26vw", marginTop: '1vw'}}
                  options={status_list}
                  getOptionLabel={(options) => typeof (options) === 'string' ? options : options.label}
                  value={this.state.filterDatas?.status}
                  onChange={(e, newValue) => {
                    this.state.filterDatas.status = newValue?.value
                    this.setState({ filterDatas: this.state.filterDatas })
                  }}
                  renderInput={(params) => <TextField {...params} label={"Status"} />}
                />}
              </Box>
            :
              this.props.pageName === 'StockTransferReport' ?
                <Box margin={'1vw'}>
                  <Autocomplete
                    size='small'
                    sx={{ width: "26vw", marginTop: '1vw' }}
                    options={trans_list}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options.label}
                    value={this.state.filterDatas?.trans_type}
                    onChange={(e, newValue) => {
                      this.state.filterDatas.trans_type = newValue?.value
                      this.setState({ filterDatas: this.state.filterDatas })
                    }}
                    renderInput={(params) => <TextField {...params} label={"Transfer Type"} />}
                  />
                </Box> : null 
          }

        </Box>
        <Box component={"div"} className="eMed_Filter_Btn_Div">
          <Button emed_tid="rpt_flt_clear" size="small" className='eMed_Filter_Btns' onClick={() => { this.clearFilterData() }} variant='outlined'>{'Clear'}</Button>
          <Button emed_tid="rpt_flt_search" size="small" className='eMed_Filter_Btns' onClick={() => { this.filterSearch() }} variant='contained'>{'Search'}</Button>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
