import React, { Component } from 'react'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Autocomplete, Box, Button, Drawer, TextField, Tooltip, Typography, Stack } from '@mui/material'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DateTime } from 'luxon'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { AmountFormat, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../Utility/Constants'
import { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'


export default class LabShareReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            corporateFilterList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            companyName: [],
            employeeName: "",
            FilterOpen: false,
            doctorShare: 0,
            hospitalShare: 0,
            shareDataList: [],
            doctorList: [],
            doctorName: [],
            packageTestData: [],
            labTestData: [],
            grpTestData: [],
            selectedPackageList: [],
            selectedGrpList: [],
            selectedTestList: [],
            loading: false,
            disableBtn: false,
            showCode:false
        }
    }

    componentDidMount() {
        let showCode = JSON.parse(localGetItem('lab_test_code')) ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
        this.setState({showCode:showCode})
        this.getDoctorListData()
        this.getTestListData()
        this.getGroupListData()
        this.getPackageListData()
        this.getShareReportData()
    }

    getDoctorListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            doctorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTestListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            labTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGroupListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            grpTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPackageListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            packageTestData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getShareReportData = () => {
        try {
            let doctorIds = []; let testIds = []; let groupIds = []; let packageIds = [];
            this.state.doctorName?.forEach(element => doctorIds?.push(element?.doctor_id))
            this.state.selectedTestList?.forEach(element => testIds?.push(element?.id))
            this.state.selectedGrpList?.forEach(element => groupIds?.push(element?.id))
            this.state.selectedPackageList?.forEach(element => packageIds?.push(element?.package_id))
            this.setState({ loading: true })
            RestAPIService.getAll(
                Serviceurls.LAB_SHARE_REPORT_GET +
                "?from_date=" + this.state.fromDate +
                "&to_date=" + this.state.toDate +
                "&test_id=" + testIds +
                "&group_id=" + groupIds +
                "&doctor_id=" + doctorIds +
                "&package_id=" + packageIds
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        shareDataList: response.data.data,
                        doctorShare:response.data.total_doctor_share ? response.data.total_doctor_share : 0,
                        hospitalShare:response.data.total_hospital_share ? response.data.total_hospital_share : 0,
                        loading: false
                    })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                if (error?.response?.data?.message) {
                    this.errorMessage(error?.response?.data?.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getShareReportDataPrint = () => {
        try {
            let doctorIds = []; let testIds = []; let groupIds = []; let packageIds = [];
            this.state.doctorName?.forEach(element => doctorIds?.push(element?.doctor_id))
            this.state.selectedTestList?.forEach(element => testIds?.push(element?.id))
            this.state.selectedGrpList?.forEach(element => groupIds?.push(element?.id))
            this.state.selectedPackageList?.forEach(element => packageIds?.push(element?.package_id))
            RestAPIService.getAll(
                Serviceurls.LAB_SHARE_REPORT_GET +
                "?from_date=" + this.state.fromDate +
                "&to_date=" + this.state.toDate +
                "&test_id=" + testIds +
                "&group_id=" + groupIds +
                "&doctor_id=" + doctorIds +
                "&package_id=" + packageIds +
                "&export_type=pdf"
            ).then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({ disableBtn: false })
            }).catch((error) => {
                this.setState({ disableBtn: false })
                if (error?.response?.data?.message) {
                    this.errorMessage(error?.response?.data?.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.setState({ disableBtn: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    selectedTime = (start, end, error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {/* <Box sx={{ width: '22vw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box> */}
                    <Box className='eMed_rts_dropdown_lab_new'>
                        <Autocomplete
                            sx={{ mb: '1vw' }}
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.doctorList}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={('select Doctor')}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            limitTags={1}
                            sx={{ mb: '1vw' }}
                            size='small'
                            disableCloseOnSelect
                            disablePortal
                            onFocus={() => this.setState({ isTextFocused: true })}
                            onBlur={() => this.setState({ isTextFocused: false })}
                            options={this.state.labTestData}
                            getOptionLabel={(option) => (option?.test_name)}
                            value={this.state.selectedTestList}
                            onChange={(event, newValue) => {
                                this.setState({ selectedTestList: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.id}>
                                    {option?.test_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={('select Test')}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            limitTags={1}
                            sx={{ mb: '1vw' }}
                            size='small'
                            options={this.state.grpTestData}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.selectedGrpList}
                            onChange={(event, newValue) => {
                                this.setState({ selectedGrpList: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.id}>
                                    {option?.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={('select Group')}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            sx={{ mb: '1vw' }}
                            options={this.state.packageTestData}
                            getOptionLabel={(option) => (option?.package_name)}
                            value={this.state.selectedPackageList}
                            onChange={(event, newValue) => {
                                this.setState({ selectedPackageList: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.id}>
                                    {option?.package_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={('select Package')}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                selectedTestList: [], selectedGrpList: [], selectedPackageList: [],
                                doctorName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false
                                },
                                timeError: false,FilterOpen:false
                            }, () => this.getShareReportData())
                        }}
                        id="eMed_Config_btn">{("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.setState({ FilterOpen: false }, () => this.getShareReportData())
                        }}
                        id="eMed_Config_btn">{("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.shareDataList?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'invoice_date', headerName: 'Date', flex: 0.143,
                renderCell: (params) => <Box>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>
            },
            {
                field: 'doctor_name', headerName: 'Consultant Name', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)

            },
            {
                field: 'test_name', headerName: 'Group/Test/Package Name', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><div>{`${params?.row?.test_name.slice(0, 20) + "..."}${this.state.showCode ? params?.row?.test_type ? ` - ${params?.row?.test_type}` : null : null}`}</div></Tooltip>
                    : params?.row?.test_name ? `${params?.row?.test_name}${this.state.showCode ? params?.row?.test_type ? ` - ${params?.row?.test_type}` : '' : ''}` : "-"}</Box>)
            },
            {
                field: 'count', headerName: 'Count', flex: 0.143
            },
            {
                field: 'doctor_share', headerName: 'Doctor Share', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_share ? AmountFormat(params?.row?.doctor_share).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'hospital_share', headerName: 'Hospital Share', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.hospital_share ? AmountFormat(params?.row?.hospital_share).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'concession', headerName: 'Concession', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discount_amount ? AmountFormat(params?.row?.discount_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
        ]
        return (
            <Box component={'div'}>
                <Box component={'div'} height={'79dvh'}>
                    <Box component={'div'} height={'15dvh'} alignItems={'center'} p={'0.8rem 0rem'} display={'flex'} justifyContent={'space-between'}>
                        <Box component={'div'} display={'flex'}>
                            {AmountsCard(("Doctor Share"), this.state.doctorShare)}
                            {AmountsCard(("Hospital Share"), this.state.hospitalShare)}
                        </Box>
                        <Box component={'div'} display={'flex'} mr={'0.5vw'}>
                            <Tooltip placement='top' title={'Filter'}>
                                <Button className='Common_Btn_Min_Width'
                                    sx={{ width: '3dvw', height: '5dvh' }}
                                    onClick={() => { this.setState({ FilterOpen: true }) }}
                                >
                                    <Box component={'img'} src={ImagePaths.Filter.default} height={'5dvh'} width={'5dvw'} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement='top' title={'Print'}>
                                <Button className='Common_Btn_Min_Width'
                                    sx={{ width: '3dvw', height: '5dvh' }}
                                    onClick={() => { this.setState({ disableBtn: true }, () => this.getShareReportDataPrint()) }}
                                >
                                    <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'5dvh'} width={'5dvw'} />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'64dvh'} p={'0rem 0.8rem'}>
                        <DataGrid
                            rows={this.state.shareDataList}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            components={{
                                Toolbar: this.gridToolBar,
                                NoRowsOverlay: () => (
                                    <Box className='eMed_conf_nodata'>
                                        {("No Records Found")}
                                    </Box>
                                )
                            }}
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            headerHeight={40}
                            disableSelectionOnClick
                            loading={this.state.loading}
                        />
                    </Box>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
