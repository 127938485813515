import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Breadcrumbs, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader from '../../../../Components/Common Components/CommonComponents'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Gender_List } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { localGetItem } from '../../../../Utility/Services/CacheProviderService'

 export default class DocRegistry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      registryData: [],
      allRegistryData: [],
      genderList: Gender_List,
      openBirthFrom: false,
      openBirthTo: false,
      openAppFrom: false,
      openAppTo: false,
      demoExpand: false,
      diaExapand: false,
      vitalExpand: false,
      drudExpand: false,
      allergyExpand: false,
      immuExpand: false,
      demographicsData: [],
      immunizationData: [],
      demographicsData: [],
      allergiesData: [],

      //Demographics
      fromBirth: null,
      toBirth: null,
      ageFrom: '',
      ageTo: '',
      appointFrom: null,
      appointTo: null,
      gender: '',
      addressArea: '',
      addressCity: '',
      selectedAddressAreaList: [],
      selectedAddressCityList: [],
      genderSearchData: [],

      //diagnosis
      diagnosisList: [],
      searchedIcdCode: '',
      searchedShortDescp: '',
      searchedLongDescp: '',
      selectedIcdCodeList: [],
      provisionalDiagnosis : "",

      //vitals
      fromHeight: '',
      toHeight: '',
      fromWeight: '',
      toWeight: '',
      fromTemp: '',
      toTemp: '',
      fromSbp: '',
      toSbp: '',
      fromDbp: '',
      toDbp: '',
      fromPulse: '',
      toPulse: '',
      fromRate: '',
      toRate: '',
      fromSugar: '',
      toSugar: '',
      fromHead: '',
      toHead: '',

      //DrugName
      selectedBrandList: [],
      searchedBrandName: '',

      //Allergies
      selectedMedicalList: [],
      searchedMedical: '',
      searchedNonMedical: '',
      selectedNonMedicalList: [],

      //Immunization
      immumization: '',
      selectedImmunization: [],
      ApiLoading : false
    }
  }

  getRegistrySearch = (search, type) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_REGISTRY_SEARCH + `?search_key=${search}&search_type=${type}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if ((type === 'area') || (type === 'city')) {
              this.setState({
                demographicsData: response.data.data
              })
            } else if (type === 'drug name') {
              this.setState({
                registryData: response.data.data,
              })
            } else if ((type === 'non medical allergy') || (type === 'medical allergy')) {
              this.setState({
                allergiesData: response.data.data,
              })
            } else {
              this.setState({
                immunizationData: response.data.data
              })
            }

          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getICDSearch = (key, value) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_REGISTRY_ICD_SEARCH + `?${key}=${value}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              diagnosisList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRegistryData = () => {
    try {
      let clinicId = localGetItem('SelectedDoctorClinicId')
      let states = this.state
      let birthFrom = new Date(states.fromBirth)
      let birthTo = new Date(states.toBirth)
      let appFrom = new Date(states.appointFrom)
      let appTo = new Date(states.appointTo)
      let fromBirth = states.fromBirth ? DateTime.fromJSDate(birthFrom).toFormat('yyyy-MM-dd') : ''
      let toBirth = states.toBirth ? DateTime.fromJSDate(birthTo).toFormat('yyyy-MM-dd') : ''
      let fromApp = states.appointFrom ? DateTime.fromJSDate(appFrom).toFormat('yyyy-MM-dd') : ''
      let toApp = states.appointTo ? DateTime.fromJSDate(appTo).toFormat('yyyy-MM-dd') : ''

      let params = '?dob_start_date=' + fromBirth + '&dob_end_date=' + toBirth + '&age_from=' + states.ageFrom + '&age_to=' + states.ageTo + '&appointment_from=' + fromApp + '&appointment_to=' + toApp + '&gender=' + states.genderSearchData +
        '&area=' + states.selectedAddressAreaList + '&city=' + states.selectedAddressCityList + '&icd_code=' + states.selectedIcdCodeList +
        '&drug_name=' + states.selectedBrandList + '&medication_allergy=' + states.selectedMedicalList + '&non_medication_allergy=' + states.selectedNonMedicalList +
        '&vaccination_name=' + states.selectedImmunization + '&height_from=' + states.fromHeight + '&height_to=' + states.toHeight + '&weight_from=' + states.fromWeight +
        '&weight_to=' + states.toWeight + '&bp_systolic_from=' + states.fromSbp + '&bp_systolic_to=' + states.toSbp + '&bp_diastolic_from=' + states.fromDbp +
        '&bp_diastolic_to=' + states.toDbp + '&temperature_from=' + states.fromTemp + '&temperature_to=' + states.toTemp + '&pulse_oximetry_from=' + states.fromPulse +
        '&pulse_oximetry_to=' + states.toPulse + '&pulse_rate_from=' + states.fromRate + '&pulse_rate_to=' + states.toRate + '&blood_sugar_from=' + states.fromSugar +
        '&blood_sugar_to=' + states.toSugar + '&head_circumference_from=' + states.fromHead + '&head_circumference_to=' + states.toHead + '&clinic_id=' + clinicId +
        '&provisional_diagnosis=' + states.provisionalDiagnosis
      this.setState({ ApiLoading : true})
      RestAPIService.getAll(Serviceurls.DOC_REGISTRY_GET + params)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              allRegistryData: response.data.data,
              ApiLoading : false,
              // provisionalDiagnosis :"",
            })  
          }
        }).catch((error) => {
          this.setState({ ApiLoading : false})
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRegistryDataExport = () => {
    try {
      let states = this.state
      let birthFrom = new Date(states.fromBirth)
      let birthTo = new Date(states.toBirth)
      let appFrom = new Date(states.appointFrom)
      let appTo = new Date(states.appointTo)
      let fromBirth = states.fromBirth ? DateTime.fromJSDate(birthFrom).toFormat('yyyy-MM-dd') : ''
      let toBirth = states.toBirth ? DateTime.fromJSDate(birthTo).toFormat('yyyy-MM-dd') : ''
      let fromApp = states.appointFrom ? DateTime.fromJSDate(appFrom).toFormat('yyyy-MM-dd') : ''
      let toApp = states.appointTo ? DateTime.fromJSDate(appTo).toFormat('yyyy-MM-dd') : ''

      let params = '?dob_start_date=' + fromBirth + '&dob_end_date=' + toBirth + '&age_from=' + states.ageFrom + '&age_to=' + states.ageTo + '&appointment_from=' + fromApp + '&appointment_to=' + toApp + '&gender=' + states.gender +
        '&area=' + states.selectedAddressAreaList + '&city=' + states.selectedAddressCityList + '&icd_code=' + states.selectedIcdCodeList +
        '&drug_name=' + states.selectedBrandList + '&medication_allergy=' + states.selectedMedicalList + '&non_medication_allergy=' + states.selectedNonMedicalList +
        '&vaccination_name=' + states.selectedImmunization + '&height_from=' + states.fromHeight + '&height_to=' + states.toHeight + '&weight_from=' + states.fromWeight +
        '&weight_to=' + states.toWeight + '&bp_systolic_from=' + states.fromSbp + '&bp_systolic_to=' + states.toSbp + '&bp_diastolic_from=' + states.fromDbp +
        '&bp_diastolic_to=' + states.toDbp + '&temperature_from=' + states.fromTemp + '&temperature_to=' + states.toTemp + '&pulse_oximetry_from=' + states.fromPulse +
        '&pulse_oximetry_to=' + states.toPulse + '&pulse_rate_from=' + states.fromRate + '&pulse_rate_to=' + states.toRate + '&blood_sugar_from=' + states.fromSugar +
        '&blood_sugar_to=' + states.toSugar + '&head_circumference_from=' + states.fromHead + '&head_circumference_to='+ '&provisional_diagnosis=' + states.provisionalDiagnosis
         + states.toHead + '&export_type=excel'

      RestAPIService.excelGet(Serviceurls.DOC_REGISTRY_GET + params).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"]?.split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      if (CommonValidation.NumberWithDot(value) || value === '') {
        this.setState({
          [names]: value
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, label, width, mr) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width, mr: mr }}
          label={label}
          name={names}
          value={value}
          onChange={(e) => this.onChangeHandler(e, names)}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClickAccordion = (title) => {
    try {
      switch (title) {
        case 'demo':
          this.setState({ demoExpand: !this.state.demoExpand })
          break;
        case 'dia':
          this.setState({ diaExapand: !this.state.diaExapand })
          break;
        case 'vital':
          this.setState({ vitalExpand: !this.state.vitalExpand })
          break;
        case 'drug':
          this.setState({ drudExpand: !this.state.drudExpand })
          break;
        case 'allergy':
          this.setState({ allergyExpand: !this.state.allergyExpand })
          break;
        case 'immu':
          this.setState({ immuExpand: !this.state.immuExpand })
          break;
        default:
          this.setState({
            demoExpand: false, diaExapand: false,
            vitalExpand: false, drudExpand: false, allergyExpand: false,
            immuExpand: false
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = (title) => {
    try {
      switch (title) {
        case 'demo':
          this.setState({
            fromBirth: null, toBirth: null,
            ageFrom: '', ageTo: '',
            appointFrom: null, appointTo: null,
            gender: '', addressArea: '',
            genderSearchData : [],
            addressCity: '', selectedAddressAreaList: [],
            selectedAddressCityList: [],
          })
          break;
        case 'dia':
          this.setState({
            diagnosisList: [], searchedIcdCode: '',
            searchedShortDescp: '', searchedLongDescp: '',
            selectedIcdCodeList: [], provisionalDiagnosis :"",
          })
          break;
        case 'vital':
          this.setState({
            fromHeight: '', toHeight: '',
            fromWeight: '', toWeight: '',
            fromTemp: '', toTemp: '',
            fromSbp: '', toSbp: '',
            fromDbp: '', toDbp: '',
            fromPulse: '', toPulse: '',
            fromRate: '', toRate: '',
            fromSugar: '', toSugar: '',
            fromHead: '', toHead: '',
          })
          break;
        case 'drug':
          this.setState({
            selectedBrandList: [], searchedBrandName: '',
          })
          break;
        case 'allergy':
          this.setState({
            selectedMedicalList: [], searchedMedical: '',
            searchedNonMedical: '', selectedNonMedicalList: [],
          })
          break;
        case 'immu':
          this.setState({
            immumization: '',
            selectedImmunization: [],
          })
          break;
        default:
          this.setState({
            //Demographics
            fromBirth: null, toBirth: null,
            ageFrom: '', ageTo: '',
            appointFrom: null, appointTo: null,
            gender: '', addressArea: '',
            addressCity: '', selectedAddressAreaList: [],
            selectedAddressCityList: [],
            genderSearchData : [], 
            //Vitals
            fromHeight: '', toHeight: '',
            fromWeight: '', toWeight: '',
            fromTemp: '', toTemp: '',
            fromSbp: '', toSbp: '',
            fromDbp: '', toDbp: '',
            fromPulse: '', toPulse: '',
            fromRate: '', toRate: '',
            fromSugar: '', toSugar: '',
            fromHead: '', toHead: '',
            //Diagnosis
            diagnosisList: [], searchedIcdCode: '',
            searchedShortDescp: '', searchedLongDescp: '',
            selectedIcdCodeList: [], provisionalDiagnosis :"",
           
            //Drug Name
            selectedBrandList: [], searchedBrandName: '',
            //Allergy
            selectedMedicalList: [], searchedMedical: '',
            searchedNonMedical: '', selectedNonMedicalList: [],
            //Immunization
            immumization: '',
            selectedImmunization: [],
            allRegistryData : []
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  searchNameList = () => {
    try {
      let demo = ''; let vital = '';
      let dia = ''; let drug = '';
      let allergy = ''; let immu = '';
      let states = this.state

      if ((states.fromBirth !== null) || (states.toBirth !== null) || (states.ageFrom !== '') || (states.ageTo !== '') ||
        (states.appointFrom !== null) || (states.appointTo !== null) || (states.gender !== '') || (states.selectedAddressAreaList?.length !== 0) ||
        (states.selectedAddressCityList?.length !== 0)) {
        demo = 'Demographics'
      }

      if (states.selectedIcdCodeList?.length !== 0 ) {
        dia = 'Diagnosis'
      }

      if (
        (states.fromHeight !== '') || (states.toHeight !== '') ||
        (states.fromWeight !== '') || (states.toWeight !== '') ||
        (states.fromTemp !== '') || (states.toTemp !== '') ||
        (states.fromSbp !== '') || (states.toSbp !== '') ||
        (states.fromDbp !== '') || (states.toDbp !== '') ||
        (states.fromPulse !== '') || (states.toPulse !== '') ||
        (states.fromRate !== '') || (states.toRate !== '') ||
        (states.fromSugar !== '') || (states.toSugar !== '') ||
        (states.fromHead !== '') || (states.toHead !== '')) {
        vital = 'Vitals'
      }

      if (states.selectedBrandList?.length !== 0) {
        drug = 'Drug Name'
      }

      if ((states.selectedMedicalList?.length !== 0) || (states.selectedNonMedicalList?.length !== 0)) {
        allergy = 'Allergy'
      }

      if (states.selectedImmunization?.length !== 0) {
        immu = 'Immunization'
      }
      return { demo, vital, drug, dia, immu, allergy };
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderCustomToolbar = () => {
    try {
      return (
        <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
          <Tooltip placement='top' title='Export'>
            <Button
              onClick={() => this.getRegistryDataExport()}
            >
              <Box component={'img'} src={ImagePaths.Export.default} height={'1.5vw'} width={'1.5vw'} />
            </Button>
          </Tooltip>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      this.state.allRegistryData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'name', headerName: 'Patient Name', flex: 0.33,
          renderCell: (params) => (
            <Box component={'div'} display={'flex'} alignItems={'center'}>
              <Box component={'div'} mr={'0.5vw'}>
                <Box component={'img'} src={params?.row?.photo_url ? params?.row?.photo_url :
                  ((params?.row?.gender === 'Female') || (params?.row?.gender === 'Trans')) ? ImagePaths.Female.default : ImagePaths.Male.default}
                  height={'2.8vw'} width={'2.8vw'} borderRadius={'100px'}
                />
              </Box>
              <Box component={'div'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.name ? params?.row?.name?.length > 20 ?
                  <Tooltip placement='top' title={params?.row?.name}>
                    <Box>{params?.row?.name?.slice(0, 20) + '...' + '(' + (params?.row?.age ? params?.row?.age : '-') + "/" + ((params?.row?.gender === 'Female') || (params?.row?.gender === 'Trans') ? "F" : "M" ) + ")"}</Box></Tooltip> :
                  `${params?.row?.name} (${params?.row?.age ? params?.row?.age : '-'} / ${(params?.row?.gender === 'Female') || (params?.row?.gender === 'Trans') ? "F" : "M" } )` : '-'}</Typography>
                <Typography fontSize={'0.8vw'}>{`${params?.row?.patient_account_number ? params?.row?.patient_account_number : '-'}`}</Typography>
              </Box>
            </Box>
          )
        },
        {
          field: 'mobile_number', headerName: 'Contact Details', flex: 0.33,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{`Mob No. : ${params?.row?.mobile_number ? params?.row?.mobile_number : '-'}`}</Typography>
              <Typography fontSize={'0.8vw'}>{`Email: ${params?.row?.email ? params?.row?.email : '-'}`}</Typography>
            </Box>
          )
        },
        {
          field: 'patient_address', headerName: 'Address', flex: 0.33,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'}>{params?.row?.patient_address?.split(",")[0]}</Typography>
              <Typography fontSize={'0.9vw'}>{params?.row?.patient_address?.split(",")[1] ? params?.row?.patient_address?.split(",")[1] : ''}</Typography>
              <Typography fontSize={'0.9vw'}>{`${params?.row?.patient_address?.split(",")[2] ? params?.row?.patient_address?.split(",")[2] : ''}
              ${params?.row?.patient_address?.split(",")[3] ? params?.row?.patient_address?.split(",")[3] : ''}`}</Typography>
            </Box>
          )
        }
      ]
      let searchData = this.searchNameList()
      return (
        <Box component={'div'}>
          <Box component={'div'} p={'0.5vw 0vw'}>
            <Breadcrumbs>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.demo ? Colors.SecondaryText : 'lightgray'}>{'Demographics'}</Typography>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.dia ? Colors.SecondaryText : 'lightgray'}>{'Diagnosis'}</Typography>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.vital ? Colors.SecondaryText : 'lightgray'}>{'Vitals'}</Typography>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.drug ? Colors.SecondaryText : 'lightgray'}>{'Drug Name'}</Typography>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.allergy ? Colors.SecondaryText : 'lightgray'}>{'Allergies'}</Typography>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={searchData?.immu ? Colors.SecondaryText : 'lightgray'}>{'Immunization'}</Typography>
            </Breadcrumbs>
          </Box>
          <Box component={'div'}>
            <DataGrid
              sx={{ height: '73vh', backgroundColor: Colors.white }}
              rows={this.state.allRegistryData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: this.renderCustomToolbar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              density='comfortable'
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.ApiLoading}
            />
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  demographics = () => {
    try {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('demo')}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>Demographics</Typography>
            </Box>
          </AccordionSummary>
          {this.state.demoExpand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('demo')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Date of Birth</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Box component={'div'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.openBirthFrom}
                          onOpen={() => this.setState({ openBirthFrom: true })}
                          onClose={() => { this.setState({ openBirthFrom: false }) }}
                          inputFormat={"DD-MM-YYYY"}
                          autoComplete="off"
                          InputProps={{
                            disableUnderline: true
                          }}
                          // maxDate={DateTime.now()}
                          value={this.state.fromBirth}
                          onChange={(newDate) => {
                            this.setState({
                              fromBirth: newDate
                            })
                          }}
                          renderInput={(params) => <TextField {...params}
                            sx={{ width: '13.5vw', mr: '0.5vw' }} size='small' label='From'
                            onClick={() => { this.setState({ openBirthFrom: true }) }}
                          />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.openBirthTo}
                          onOpen={() => this.setState({ openBirthTo: true })}
                          onClose={() => { this.setState({ openBirthTo: false }) }}
                          inputFormat={"DD-MM-YYYY"}
                          autoComplete="off"
                          InputProps={{
                            disableUnderline: true
                          }}
                          // maxDate={DateTime.now()}
                          value={this.state.toBirth}
                          onChange={(newDate) => {
                            this.setState({
                              toBirth: newDate
                            })
                          }}
                          renderInput={(params) => <TextField {...params}
                            sx={{ width: '13.5vw' }} size='small' label='To'
                            onClick={() => { this.setState({ openBirthTo: true }) }}
                          />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Age</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('ageFrom', this.state.ageFrom, "From Age", '13.5vw', '0.5vw')}
                    {this.renderTextBox('ageTo', this.state.ageTo, "To Age", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Appointment Date</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Box component={'div'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.openAppFrom}
                          onOpen={() => this.setState({ openAppFrom: true })}
                          onClose={() => { this.setState({ openAppFrom: false }) }}
                          inputFormat={"DD-MM-YYYY"}
                          autoComplete="off"
                          InputProps={{
                            disableUnderline: true
                          }}
                          // maxDate={DateTime.now()}
                          value={this.state.appointFrom}
                          onChange={(newDate) => {
                            this.setState({
                              appointFrom: newDate
                            })
                          }}
                          renderInput={(params) => <TextField {...params}
                            sx={{ width: '13.5vw', mr: '0.5vw' }} size='small' label='Appointment From'
                            onClick={() => { this.setState({ openAppFrom: true }) }}
                          />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.openAppTo}
                          onOpen={() => this.setState({ openAppTo: true })}
                          onClose={() => { this.setState({ openAppTo: false }) }}
                          inputFormat={"DD-MM-YYYY"}
                          autoComplete="off"
                          InputProps={{
                            disableUnderline: true
                          }}
                          // maxDate={DateTime.now()}
                          value={this.state.appointTo}
                          onChange={(newDate) => {
                            this.setState({
                              appointTo: newDate
                            })
                          }}
                          renderInput={(params) => <TextField {...params}
                            sx={{ width: '13.5vw' }} size='small' label='Appointment To'
                            onClick={() => { this.setState({ openAppTo: true }) }}
                          />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Gender</Typography>
                  </Box>
                  <Box component={'div'}>
                    {this.state.genderSearchData?.length > 0 ? this.state.genderSearchData?.map((item, index) => {
                      return (
                        <Chip
                          className={`eMed_chip eMed_Chip_Border`}
                          label={item === 'm' ? 'Male' : item === 'f' ? 'Female' : 'Trans Gender'}
                          // variant={item.value === this.state.gender ? 'contained' : 'outlined'}
                          key={index}
                          // onClick={() => {
                          //   if (item.value === this.state.gender) {
                          //     this.setState({ gender: '' })
                          //   } else {
                          //     this.setState({ gender: item?.value })
                          //   }
                          // }}
                          // color={'primary'}
                          clickable
                          onDelete={() => {
                            this.state.genderSearchData?.splice(index, 1)
                            this.setState({ genderSearchData: this.state.genderSearchData })
                          }}
                        />
                      )
                    }) : null}
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    <FormControl size='small' sx={{ width: '15vw' }}>
                      <InputLabel>Select Gender</InputLabel>
                      <Select
                        label={'Select Gender'}
                        value={this.state.gender}
                        onChange={(e) => {
                          this.setState({ gender: e.target.value }, () => {
                            this.state.genderSearchData?.push(this.state.gender)
                            this.setState({ genderSearchData: this.state.genderSearchData }, () => {
                              this.setState({ gender: '' })
                            })
                          })
                        }}
                      >
                        {this.state.genderList?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Address</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedAddressAreaList?.length > 0 ?
                        <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                          <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Area</Typography>
                        </Box> : null}
                      {this.state.selectedAddressAreaList?.map((item, index) => {
                        return (
                          <Chip
                            className='eMed_chip eMed_Chip_Border'
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedAddressAreaList?.splice(index, 1)
                              this.setState({ selectedAddressAreaList: this.state.selectedAddressAreaList })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      {this.state.selectedAddressCityList?.length > 0 ?
                        <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                          <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>City</Typography>
                        </Box> : null}
                      {this.state.selectedAddressCityList?.map((item, index) => {
                        return (
                          <Chip
                            className='eMed_chip eMed_Chip_Border'
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedAddressCityList?.splice(index, 1)
                              this.setState({ selectedAddressCityList: this.state.selectedAddressCityList })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'} display={'flex'}>
                      <Box component={'div'}>
                        <Autocomplete
                          clearIcon
                          size='small'
                          freeSolo
                          sx={{ width: '13.5vw', mr: '0.5vw', mt: '0.5vw' }}
                          options={this.state.demographicsData.map((item) => item)}
                          value={this.state.addressArea}
                          limitTags={3}
                          autoComplete='off'
                          onChange={(e, newValue) => {
                            if (CommonValidation.alphabetOnly(newValue)) {
                              this.setState({
                                addressArea: newValue
                              }, () => {
                                if (this.state.addressArea?.length > 0) {
                                  this.state.selectedAddressAreaList?.push(this.state.addressArea)
                                  this.setState({
                                    selectedAddressAreaList: this.state.selectedAddressAreaList,
                                  }, () => this.setState({ addressArea: '', demographicsData: [] }))
                                }
                              })
                            }
                          }}
                          renderInput={(params) => <TextField {...params}
                            onChange={(e) => {
                              if (e.target.value?.length > 0) {
                                if (CommonValidation.alphabetOnly(e.target.value)) {
                                  this.setState({
                                    addressArea: e.target.value
                                  }, () => this.getRegistrySearch(this.state.addressArea, 'area'))
                                } else {
                                  this.errorMessage('Only alphabets allowed')
                                }
                              }
                            }}
                            label='Search Area Name' />}
                        />
                      </Box>
                      <Box component={'div'}>
                        <Autocomplete
                          clearIcon
                          size='small'
                          freeSolo
                          sx={{ width: '13.5vw', mr: '0.5vw', mt: '0.5vw' }}
                          options={this.state.demographicsData.map((item) => item)}
                          value={this.state.addressCity}
                          limitTags={3}
                          autoComplete='off'
                          onChange={(event, newValue) => {
                            if (CommonValidation.alphabetOnly(newValue)) {
                              this.setState({
                                addressCity: newValue
                              }, () => {
                                if (this.state.addressCity?.length > 0) {
                                  this.state.selectedAddressCityList?.push(this.state.addressCity)
                                  this.setState({
                                    selectedAddressCityList: this.state.selectedAddressCityList,
                                  }, () => this.setState({ addressCity: '', demographicsData: [] }))
                                }
                              })
                            }
                          }}
                          renderInput={(params) => <TextField {...params}
                            onChange={(e) => {
                              if (e.target.value?.length > 0) {
                                if (CommonValidation.alphabetOnly(e.target.value)) {
                                  this.setState({
                                    addressCity: e.target.value,
                                  }, () => this.getRegistrySearch(this.state.addressCity, 'city'))
                                } else {
                                  this.errorMessage('Only alphabets allowed')
                                }
                              }
                            }
                            }
                            label='Search City Name' />}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails> : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  diagnosis = () => {
    try {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('dia')}>
            <Typography fontSize={'0.9vw'} fontWeight={600}>Diagnosis</Typography>
          </AccordionSummary>
          {this.state.diaExapand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('dia')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>ICD Code</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedIcdCodeList?.map((item, index) => {
                        return (
                          <Chip
                            className={`eMed_chip eMed_Chip_Border`}
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedIcdCodeList?.splice(index, 1)
                              this.setState({
                                selectedIcdCodeList: this.state.selectedIcdCodeList
                              })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        freeSolo
                        sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                        options={this.state.diagnosisList.map((item) => item?.icd_code)}
                        value={this.state.searchedIcdCode}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            searchedIcdCode: newValue
                          }, () => {
                            if (this.state.searchedIcdCode?.length > 0) {
                              this.state.selectedIcdCodeList?.push(this.state.searchedIcdCode)
                              this.setState({
                                selectedIcdCodeList: this.state.selectedIcdCodeList,
                              }, () => this.setState({ searchedIcdCode: '', diagnosisList: [] }))
                            }
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          onChange={(e) => {
                            if (e.target.value?.length > 0) {
                              // if (CommonValidation.alphabetOnly(e.target.value)) {
                                this.setState({
                                  searchedIcdCode: e.target.value
                                }, () => this.getICDSearch('icd_code', this.state.searchedIcdCode))
                              // } else {
                              //   this.errorMessage('Only alphabets allowed')
                              // }
                            }
                          }}
                          label='Search ICD Code' />}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Short Description</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      freeSolo
                      sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw',mb:"1vw" }}
                      options={this.state.diagnosisList}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.icd_short_desc}
                      value={this.state.searchedShortDescp}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          searchedShortDescp: newValue?.icd_code
                        }, () => {
                          if (this.state.searchedShortDescp?.length > 0) {
                            this.state.selectedIcdCodeList?.push(this.state.searchedShortDescp)
                            this.setState({
                              selectedIcdCodeList: this.state.selectedIcdCodeList,
                            }, () => this.setState({ searchedShortDescp: '', diagnosisList: [] }))
                          }
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        onChange={(e) => {
                          if (e.target.value?.length > 0) {
                            if (CommonValidation.alphabetOnly(e.target.value)) {
                              this.setState({
                                searchedShortDescp: e.target.value
                              }, () => this.getICDSearch('desc', this.state.searchedShortDescp))
                            } else {
                              this.errorMessage('Only alphabets allowed')
                            }
                          }
                        }}
                        label='Search Short Description' />}
                    />
                  </Box>
                </Box>
                <hr/>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Provisional Diagnosis</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}> {

                      <TextField
                        sx={{  width: '25vw', mr: '0.5vw', mt: '0.75vw',mb:"1vw" }}
                        size="small"
                        inputProps={{ maxLength: 100 }}
                        autoComplete="off"
                        label={("Provisional Diagnosis")}
                        //  required={required}
                        value={this.state.provisionalDiagnosis}
                        onChange={(e) => {
                          let provisionalDiagnosis = e.target.value
                          this.setState({ provisionalDiagnosis: provisionalDiagnosis })
                        }}
                      />
                    }</Box>
                  </Box>
                </Box>
                {/* Long Description Search has not worked */}
                {/* <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Long Description</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      freeSolo
                      sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                      options={this.state.diagnosisList}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.icd_desc}
                      value={this.state.searchedLongDescp}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          searchedLongDescp: newValue?.icd_code
                        }, () => {
                          if (this.state.searchedLongDescp?.length > 0) {
                            this.state.selectedIcdCodeList?.push(this.state.searchedLongDescp)
                            this.setState({
                              selectedIcdCodeList: this.state.selectedIcdCodeList,
                            }, () => this.setState({ searchedLongDescp: '', diagnosisList: [] }))
                          }
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        onChange={(e) => {
                          if (CommonValidation.alphabetOnly(e.target.value) && e.target.value?.length > 0) {
                            this.setState({
                              searchedLongDescp: e.target.value
                            }, () => this.getICDSearch('long', this.state.searchedLongDescp))
                          } else {
                            this.errorMessage('Only alphabets allowed')
                          }
                        }}
                        label='Search long Description' />}
                    />
                  </Box>
                </Box> */}
              </Box>
            </AccordionDetails> : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  vitals = () => {
    try {
      return (
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('vital')}>
            <Typography fontSize={'0.9vw'} fontWeight={600}>Vitals</Typography>
          </AccordionSummary>
          {this.state.vitalExpand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('vital')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Height (cm)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromHeight', this.state.fromHeight, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toHeight', this.state.toHeight, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Weight (kg)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromWeight', this.state.fromWeight, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toWeight', this.state.toWeight, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Temperature (°F/°C)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromTemp', this.state.fromTemp, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toTemp', this.state.toTemp, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Systolic Blood Pressure (mm/Hg)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromSbp', this.state.fromSbp, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toSbp', this.state.toSbp, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Diastolic Blood Pressure (mm/Hg)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromDbp', this.state.fromDbp, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toDbp', this.state.toDbp, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Pulse Oximetry (%)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromPulse', this.state.fromPulse, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toPulse', this.state.toPulse, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Pulse Rate (min)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromRate', this.state.fromRate, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toRate', this.state.toRate, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Random Blood Sugar (mg/dl)</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    {this.renderTextBox('fromSugar', this.state.fromSugar, "From", '13.5vw', '0.5vw')}
                    {this.renderTextBox('toSugar', this.state.toSugar, "To", '13.5vw', '0.5vw')}
                  </Box>
                </Box>
              </Box>
            </AccordionDetails> : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  drugName = () => {
    try {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('drug')}>
            <Typography fontSize={'0.9vw'} fontWeight={600}>Drug Name</Typography>
          </AccordionSummary>
          {this.state.drudExpand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('drug')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Brand Name</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedBrandList?.map((item, index) => {
                        return (
                          <Chip
                            className={`eMed_chip eMed_Chip_Border`}
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedBrandList?.splice(index, 1)
                              this.setState({
                                selectedBrandList: this.state.selectedBrandList
                              })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        freeSolo
                        sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                        options={this.state.registryData.map((item) => item)}
                        value={this.state.searchedBrandName}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            searchedBrandName: newValue
                          }, () => {
                            if (this.state.searchedBrandName?.length > 0) {
                              this.state.selectedBrandList?.push(this.state.searchedBrandName)
                              this.setState({
                                selectedBrandList: this.state.selectedBrandList,
                              }, () => this.setState({ searchedBrandName: '', registryData: [] }))
                            }
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          onChange={(e) => {
                            if (e.target.value?.length > 0) {
                              if (CommonValidation.alphabetOnly(e.target.value)) {
                                this.setState({
                                  searchedBrandName: e.target.value
                                }, () => this.getRegistrySearch(this.state.searchedBrandName, 'drug name'))
                              } else {
                                this.errorMessage('Only alphabets allowed')
                              }
                            }
                          }}
                          label='Search Brand Name' />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
            : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  allergies = () => {
    try {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('allergy')}>
            <Typography fontSize={'0.9vw'} fontWeight={600}>Allergies</Typography>
          </AccordionSummary>
          {this.state.allergyExpand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('allergy')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Medical Allergy</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedMedicalList?.map((item, index) => {
                        return (
                          <Chip
                            className={`eMed_chip eMed_Chip_Border`}
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedMedicalList?.splice(index, 1)
                              this.setState({
                                selectedMedicalList: this.state.selectedMedicalList
                              })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        freeSolo
                        sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                        options={this.state.allergiesData?.map((item) => item)}
                        value={this.state.searchedMedical}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            searchedMedical: newValue
                          }, () => {
                            if (this.state.searchedMedical?.length > 0) {
                              this.state.selectedMedicalList?.push(this.state.searchedMedical)
                              this.setState({
                                selectedMedicalList: this.state.selectedMedicalList,
                              }, () => this.setState({ searchedMedical: '', allergiesData: [] }))
                            }
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          onChange={(e) => {
                            if (e.target.value?.length > 0) {
                              if (CommonValidation.alphabetOnly(e.target.value)) {
                                this.setState({
                                  searchedMedical: e.target.value
                                }, () => this.getRegistrySearch(this.state.searchedMedical, 'medical allergy'))
                              } else {
                                this.errorMessage('Only alphabets allowed')
                              }
                            }
                          }}
                          label='Search Medical Allergy' />}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Non Medical Allergy</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedNonMedicalList?.map((item, index) => {
                        return (
                          <Chip
                            className={`eMed_chip eMed_Chip_Border`}
                            label={item}
                            variant={'outlined'}
                            key={index}
                            // color={'primary'}
                            onDelete={() => {
                              this.state.selectedNonMedicalList?.splice(index, 1)
                              this.setState({
                                selectedNonMedicalList: this.state.selectedNonMedicalList
                              })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        freeSolo
                        sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                        options={this.state.allergiesData?.map((item) => item)}
                        value={this.state.searchedNonMedical}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            searchedNonMedical: newValue
                          }, () => {
                            if (this.state.searchedNonMedical?.length > 0) {
                              this.state.selectedNonMedicalList?.push(this.state.searchedNonMedical)
                              this.setState({
                                selectedNonMedicalList: this.state.selectedNonMedicalList,
                              }, () => this.setState({ searchedNonMedical: '', allergiesData: [] }))
                            }
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          onChange={(e) => {
                            if (e.target.value?.length > 0) {
                              if (CommonValidation.alphabetOnly(e.target.value)) {
                                this.setState({
                                  searchedNonMedical: e.target.value,
                                }, () => this.getRegistrySearch(this.state.searchedNonMedical, 'non medical allergy'))
                              } else {
                                this.errorMessage('Only alphabets allowed')
                              }
                            }
                          }}
                          label='Search Non Mediacal Allergy Name' />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails> : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  immunization = () => {
    try {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => this.onClickAccordion('immu')}>
            <Typography fontSize={'0.9vw'} fontWeight={600}>Immunization</Typography>
          </AccordionSummary>
          {this.state.immuExpand ?
            <AccordionDetails>
              <Box component={'div'}>
                <Box display={'flex'} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    sx={{ height: '2vw', width: '5vw' }}
                    onClick={() => this.onClearHandler('immu')}
                  >
                    Clear
                  </Button>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Immunization</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'}>
                      {this.state.selectedImmunization?.map((item, index) => {
                        return (
                          <Chip
                            className='eMed_chip'
                            label={item}
                            variant={'outlined'}
                            key={index}
                            color={'primary'}
                            onDelete={() => {
                              this.state.selectedImmunization?.splice(index, 1)
                              this.setState({
                                selectedImmunization: this.state.selectedImmunization
                              })
                            }}
                          />
                        )
                      })}
                    </Box>
                    <Box component={'div'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        freeSolo
                        sx={{ width: '25vw', mr: '0.5vw', mt: '0.5vw' }}
                        options={this.state.immunizationData?.map((item) => item)}
                        value={this.state.immumization}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            immumization: newValue
                          }, () => {
                            if (this.state.immumization?.length > 0) {
                              this.state.selectedImmunization?.push(this.state.immumization)
                              this.setState({
                                selectedImmunization: this.state.selectedImmunization,
                              }, () => this.setState({ immumization: '', immunizationData: [] }))
                            }
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          onChange={(e) => {
                            if (e.target.value?.length > 0) {
                              if (CommonValidation.alphabetOnly(e.target.value)) {
                                this.setState({
                                  immumization: e.target.value,
                                }, () => this.getRegistrySearch(this.state.immumization, 'immunization'))
                              } else {
                                this.errorMessage('Only alphabets allowed')
                              }
                            }
                          }}
                          label='Search Immunization' />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails> : null}
        </Accordion>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
      var UserData = myUser;
      return (
        <Box component={'div'} border={'1px solid lightgray'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Filter</Typography>
          </Box>
          <Box component={'div'} height={'66vh'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
            <Box component={'div'} p={'0.5vw'}>
              {this.demographics()}
              {this.diagnosis()}
              {this.vitals()}
              {this.drugName()}
              {this.allergies()}
              {UserData?.service_type === "PD" ? this.immunization() : null}
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearHandler()}
            >Clear</Button>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => this.getRegistryData()}
            >Apply</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }} height={'80vh'}>
        <Box flex={0.65} component={'div'} p={'0.5vw'}>
          {this.renderLeftSide()}
        </Box>
        <Box flex={0.35} component={'div'} p={'0.5vw'}>
          {this.renderRightSide()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
