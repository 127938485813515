import React, { Component } from "react";
import '../IpPatientDetails.css';
import { Box, Paper, TextField, Button, Stack, Tooltip, Grid, IconButton, Autocomplete, Typography, Menu, MenuItem } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, {
    CommonPatientDetails, CommonPatientCard, ConsultantPatientCard,
    CommonEditButton, CommonPopUp, ReasonPopup
} from "../../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../../../Utility/ImagePaths";
import { Colors } from "../../../../../Styles/Colors";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../../Utility/API/Serviceurls";
import { getCachevalue, localGetItem } from "../../../../../Utility/Services/CacheProviderService";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IP_routeList } from '../../../../../Utility/Constants';
import { t } from "i18next";
import { CheckAccessFunc, formatDateAndTimeMeth2 } from "../../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../../Components/Loader';
import { DateTime } from "luxon";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const multi_option = [
    { value: "Start", label: "Start", color: "#E3FFE6" },
    { value: "Stop", label: "Stop", color: "#FFE3E3" },
    { value: "Hold", label: "Hold", color: "#FFF8BC" },
    { value: "Completed", label: "Completed", color: "#FFFFFF" },
    { value: "view_log", label: "View Log", color: "" },
];

class DrugChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tblData: [],
            patientData: {},
            attenderData: {},
            brandnameList: [],
            genericList: [],
            pharmaData: [],
            pharma_name: "",
            pharma_id: null,
            drug_date: new Date(),
            drug_ms_id: "",
            drug_name: "",
            generic_name: "",
            dosage_type: "",
            dosage_strength: "",
            route: "",
            quantity: null,
            frequency: "",
            instruction: "",
            selectedRow: null,
            isSelected: false,
            anchorEl: null,
            focusError: "",
            current_page: 0,
            rowsPerPage: 10,
            selectedMenu: "",
            viewLogData: [],
            isAdd: false,
            isEdit: false,
            withOutAppointment: {},
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
            selectedData: {},
        }
    }
    componentDidMount() {
        let patientDetails = getCachevalue("IppatientData")
        let patients = JSON.parse(patientDetails)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        this._getPharmaList();
        if (details?.patient_id === patients?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getIpDrugChartDetails();
                this.getPatientData();
            })
        }
        this.setState({
            patientData: patients
        }, () => {
            this.getIpDrugChartDetails();
            this.getPatientData();
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getPatientData = () => {
        this.setState({
            attenderData: {}
        })
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
        if (this.props.is_From_IpNotes && this.state.clinic_id) {
            url = `${url}&clinic_id=${this.state.clinic_id}`
        }
        try {
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderData: response.data.data[0]
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMsgFunc(error.response.data.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    getIpDrugChartDetails = () => {
        let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DRUG_CHART}?ip_admission_id=${admission_id}`)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response.data.data?.map((item, i) => {
                            return { ...item, index: i };
                        }) || [];
                        this.setState({
                            tblData: data || [],
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    } else {
                        this.errorMsgFunc(error.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    _getPharmaList = () => {
        this.setState({
            pharmaData: []
        })
        try {
            let url = Serviceurls.FO_IP_PHARMALIST_GET;
            if (this.props.is_From_IpNotes && this.state.clinic_id) {
                url = `${url}?clinic_id=${this.state.clinic_id}`
            }
            RestAPIService.getAll(url)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            pharmaData: response.data.data,
                            pharma_name: response.data.data[0].pharmacy_name,
                            pharma_id: response.data.data[0].pharmacy_id
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    _getBrandDetails = () => {
        let updatedState = { ...this.state };
        let list = [];
        this.setState({
            genericList: []
        })
        try {
            RestAPIService.getAll(`${Serviceurls.FO_IP_BRANDDETAILS_GET}?pharmacy_id=${this.state.pharma_id || null}&drug_name=${this.state.drug_name || ""}`)
                .then(response => {
                    if (response.data.status === "success") {
                        list = [...response.data.data];
                        if (list.length === 1) {
                            this.setState({
                                drug_ms_id: list[0]["drug_ms_id"],
                                generic_name: list[0]["generic_name"],
                                dosage_type: list[0]["dosage_type"],
                                dosage_strength: list[0]["drug_strength"],
                            })
                        } else {
                            this.setState({
                                genericList: list
                            })
                        }
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    _getBrandList = (brand_name) => {
        this.setState({
            brandnameList: []
        })
        if (brand_name && this.state.pharma_id) {
            try {
                RestAPIService.getAll(`${Serviceurls.FO_IP_BRANDNAMELIST_GET}?pharmacy_id=${this.state.pharma_id || null}&drug_name=${brand_name}`)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.setState({
                                brandnameList: response.data.data,
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMsgFunc(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMsgFunc(e.message);
            }
        }
    }

    _getLogDetails = (rowId) => {
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DRUG_CHART_HISTORY}?id=${rowId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            viewLogData: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMsgFunc(error.response.data.message);
                    }
                });
        } catch (e) {
            this.errorMsgFunc(e.message);
        }
    }

    handleFocus = () => {
        const { t } = this.props;
        let { pharma_name } = this.state;
        if (pharma_name === "") {
            this.setState({ focusError: "Select Pharmacy" });
            this.errorMsgFunc(t("SelectPharmacy"));
        }
    }

    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isAdd: false,
            selectedRow: {},
            selectedMenu: "",
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            isAdd: false,
            selectedRow: {},
            selectedMenu: "",
        })
    }

    handleClear = () => {
        this.setState(
            {
                pharma_name: "",
                pharma_id: null,
                drug_date: new Date(),
                drug_ms_id: "",
                drug_name: "",
                generic_name: "",
                dosage_type: "",
                dosage_strength: "",
                route: "",
                quantity: null,
                frequency: "",
                instruction: "",
                isAdd: false,
                isEdit: false,
                selectedData: {},
            }, () => {
                this._getPharmaList()
            }
        )
    }

    formatDate = (date) => {
        const dt = DateTime.fromJSDate(new Date(date));
        return dt.toFormat(`yyyy-MM-dd HH:mm:ss`);
    };

    handleAdd = (drugStatus = 'Start', reason = "") => {
        try {
            let states = this.state;
            let isDataAvailable = this.checkDataPresence();
            if (isDataAvailable || states.selectedMenu) {
                let data = {
                    'ip_admission_id': states?.patientData?.id,
                    "doctor_id": drugStatus ? (states?.selectedRow?.doctor_id ? states?.selectedRow?.doctor_id : states?.patientData?.doctor_id) :
                        states?.isEdit ? (states?.selectedData?.doctor_id ? states?.selectedData?.doctor_id : states?.patientData?.doctor_id) :
                            states?.patientData?.doctor_id,
                    'date': states?.drug_date ? this.formatDate(states?.drug_date)
                        : states.selectedRow?.date ?
                            this.formatDate(states?.selectedRow?.date) : null,
                    'drug_name': states?.drug_name ? states?.drug_name : states.selectedRow?.drug_name ?
                        states?.selectedRow?.drug_name : "",
                    'generic_name': states?.generic_name ? states?.generic_name : states.selectedRow?.generic_name ?
                        states?.selectedRow?.generic_name : "",
                    'dosage_strength': states?.dosage_strength ? states?.dosage_strength : states.selectedRow?.dosage_strength ?
                        states?.selectedRow?.dosage_strength : "",
                    'dosage_type': states?.dosage_type ? states?.dosage_type : states.selectedRow?.dosage_type ?
                        states?.selectedRow?.dosage_type : "",
                    'route': states?.route ? states?.route : states.selectedRow?.route ?
                        states?.selectedRow?.route : "",
                    'quantity': states?.quantity ? states?.quantity : states.selectedRow?.quantity ?
                        states?.selectedRow?.quantity : "",
                    'frequency': states?.frequency ? states?.frequency : states.selectedRow?.frequency ?
                        states?.selectedRow?.frequency : "",
                    'instruction': states?.instruction ? states?.instruction : states.selectedRow?.instruction ?
                        states?.selectedRow?.instruction : "",
                    'status': states?.isEdit ? states?.selectedData?.status : drugStatus,
                    'comments': reason ? reason : undefined,
                    "status_update": states.selectedMenu ? true : false
                }

                if (states?.isEdit || states.selectedMenu) { data["id"] = states?.selectedData?.id || states?.selectedRow?.id }

                RestAPIService.create(data, Serviceurls.IP_DRUG_CHART).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.handleClear()
                            this.getIpDrugChartDetails()
                            this.successMsgFunc(response.data.message)
                        } else {
                            this.errorMsgFunc(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMsgFunc(error.response.data.message)
                        } else {
                            this.errorMsgFunc(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMsgFunc(error.message)
        }
    }

    DisableRouteOption = (option, IP_routeList) => {
        let Dosage_Type = this.state.dosage_type
        if (Dosage_Type === "Ampules" || Dosage_Type === "Injection") {
            return (
                option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[7] || option === IP_routeList[8] || option === IP_routeList[9] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
            )
        } else if (Dosage_Type === "Tablet") {
            return (
                option === IP_routeList[0] || option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[9] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
            )
        } else if (Dosage_Type === "Steroid Tablets" || Dosage_Type === "Capsule Sr" || Dosage_Type === "Syrup" || Dosage_Type === "Biscuit") {
            return (
                option === IP_routeList[0] || option === IP_routeList[3] || option === IP_routeList[4] || option === IP_routeList[5] || option === IP_routeList[6] || option === IP_routeList[8] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[9] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
            )
        } else if (Dosage_Type === "Capsule") {
            return (
                option === IP_routeList[0] || option === IP_routeList[1] || option === IP_routeList[2] || option === IP_routeList[3] || option === IP_routeList[8] || option === IP_routeList[9] || option === IP_routeList[6] || option === IP_routeList[10] || option === IP_routeList[11] || option === IP_routeList[12]
            )
        }
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        let isValid = false;
        let states = this.state;
        value = value.trimStart();
        switch (name) {
            case "quantity":
                isValid = CommonValidation.NumberWithDot(value);
                if ((isValid && value.length <= 4) || value == "") {
                    states.quantity = value;
                }
                break;
            case "instruction":
                if(value?.length <= 250){
                    states[name] = value;
                }
                break;
            default:
                states[name] = value;
                break;
        }
        this.setState({ states })
    }

    renderTextBox(label, statekey, value, isDisabled = false) {
        const { t } = this.props;
        let { brandnameList, drug_name, genericList, pharmaData, focusError } = this.state;
        let updatedState = { ...this.state };
        return (
            <div id="eMed_srvConfig_divs">
                {statekey === "pharma_name" ?
                    <Autocomplete
                        disableClearable
                        value={value ? value : ""}
                        sx={{ width: "13.6vw", fontSize: "0.9vw", marginRight: "0.5vw" }}
                        inputProps={{'emed_tid': 'drg_pharma_name' }}
                        size="small"
                        options={pharmaData?.length > 0 ? [...pharmaData] : []}
                        getOptionLabel={option => typeof (option) === "string" ? option : option.pharmacy_name}
                        onChange={(event, newPharma) => {
                            this.setState({
                                pharma_name: newPharma?.pharmacy_name,
                                pharma_id: newPharma?.pharmacy_id,
                                focusError: "",
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={`${t(label)} (${pharmaData?.length || 0}) *`}
                                autoComplete="off"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                                error={value === "" && focusError?.length > 0}
                            />
                        )}
                    />
                    : statekey === "drug_name" ?
                        <Autocomplete
                            disableClearable
                            value={value ? value : ""}
                            sx={{ width: "13.6dvw" }}
                            size="small"
                            options={brandnameList?.length != 0 ? [...brandnameList] : []}
                            inputProps={{'emed_tid': 'drg_drug_name' }}
                            getOptionLabel={option => typeof (option) === "string" ? option : option}
                            onChange={(event, newbrand) => {
                                this.setState({
                                    drug_name: newbrand
                                }, () => {
                                    this._getBrandDetails();
                                })
                            }}
                            onInputChange={(event, newBrand) => {
                                let previousBrand_name = JSON.stringify(drug_name);
                                if (drug_name !== newBrand) {
                                    updatedState.drug_name = ''
                                };
                                if (previousBrand_name != JSON.stringify(newBrand)) {
                                    updatedState.drug_ms_id = null;
                                    updatedState.generic_name = "";
                                    updatedState.dosage_type = "";
                                    updatedState.dosage_strength = "";
                                }
                                this.setState({
                                    updatedState,
                                    genericList: previousBrand_name != JSON.stringify(newBrand) ? [] : [...this.state.genericList]
                                })
                                this._getBrandList(newBrand);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t(label) + "*"}
                                    autoComplete="off"
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    onFocus={this.handleFocus}
                                />
                            )}
                        />
                        : statekey === "generic_name" ?
                            <Autocomplete
                                disableClearable
                                value={value ? value : ""}
                                sx={{ width: "13.6dvw" }}
                                size="small"
                                options={genericList?.length != 0 ? [...genericList] : []}
                                inputProps={{ 'emed_tid': 'drg_generic_name' }}
                                getOptionLabel={option => typeof (option) === "string" ? option : option.display_name}
                                onChange={(event, newGeneric) => {
                                    this.setState({
                                        drug_ms_id: newGeneric.drug_ms_id,
                                        generic_name: newGeneric.generic_name,
                                        dosage_type: newGeneric.dosage_type,
                                        dosage_strength: newGeneric.drug_strength,
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t(label)}
                                        autoComplete="off"
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        onFocus={this.handleFocus}
                                    />
                                )}
                            />
                            : statekey === "route" ?
                                <Autocomplete
                                    disableClearable
                                    value={value ? value : ""}
                                    sx={{ width: "13.6dvw" }}
                                    size="small"
                                    inputProps={{'emed_tid': 'drg_route' }}
                                    options={(IP_routeList.length > 0 && this.state.pharma_name !== "") ? IP_routeList : []}
                                    // getOptionDisabled={
                                    //     (option) => this.DisableRouteOption(option, IP_routeList)
                                    // }
                                    getOptionLabel={option => typeof (option) === "string" ? option : option.value}
                                    onChange={(event, newRoute) => {
                                        this.setState({
                                            route: newRoute.value
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t(label)}
                                            autoComplete="off"
                                            size="small"
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            onFocus={this.handleFocus}
                                        />
                                    )}
                                /> :
                                <TextField
                                    sx={{ width: "13.6dvw" }}
                                    size="small"
                                    autoComplete="off"
                                    inputProps={{ emed_tid: `drg_route${statekey}` }}
                                    label={t(label)}
                                    name={statekey}
                                    value={value ? value : ""}
                                    disabled={isDisabled}
                                    onChange={this.handleChange}
                                />}
            </div>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    MenuList = (data) => {
        const { t } = this.props
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        emed_tid="drg_more_icon"
                        disabled={this.state.withOutAppointment?.is_ip}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data,
                                isSelected: true
                            }, () => {this.handleClear()})
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow.index == data.index) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} className="eMed_drugMenu"
                                onClick={() => {
                                    this.setState({
                                        anchorEl: null,
                                        selectedMenu: option.value
                                    },
                                        () => {
                                            if (this.state.selectedMenu === "view_log") {
                                                this._getLogDetails(data.id)
                                            }
                                        })
                                }}
                            >
                                <div className="eMed_drugMenu_item"
                                    style={{
                                        border: option.color === "#FFFFFF" ?
                                            `1px solid #cbbfbf` : option.color !== "" ?
                                                `1px solid ${option.color}` : null,
                                        background: option.color
                                    }}></div>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    // view log popup start
    viewLogPopUpClose = () => {
        this.setState({
            anchorEl: null,
            selectedMenu: "",
            viewLogData: []
        })
    }

    _showViewLog(logData) {
        const { t } = this.props;
        const logColumn = [
            {
                field: 'modified_by', headerName: t('ModifiedBy'), sortable: false, hideable: false, flex: 0.25, renderCell: ({ row }) => {
                    return (<div>{row?.modified_by?.length > 28 ?
                        <Tooltip placement="top" title={row?.modified_by} arrow>
                            <div>{row?.modified_by.slice(0, 25) + "..."}</div></Tooltip>
                        : row?.modified_by ? row?.modified_by : "-"}</div>)
                }
            },
            {
                field: 'modified_date', headerAlign: "center", headerName: t('ModifiedDateAndTime'), align: "center", sortable: false, hideable: false, flex: 0.25, renderCell: ({ row }) => {
                    return (<Typography>{row?.modified_date ? formatDateAndTimeMeth2(row?.modified_date) : "-"}</Typography>)
                }
            },
            {
                field: 'status', headerName: `${t('Previous Status')}`, flex: 0.25, sortable: false, hideable: false, renderCell: ({ row }) => {
                    return (<div>{row?.status?.length > 30 ?
                        <Tooltip placement="top" title={row?.status} arrow>
                            <div>{row?.status.slice(0, 26) + "..."}</div></Tooltip>
                        : row?.status ? row?.status : "-"}</div>)
                }
            },
            {
                field: 'comments', headerName: `${t('Reason')}`, flex: 0.25, sortable: false, hideable: false, renderCell: ({ row }) => {
                    return (<div>{row?.comments?.length > 30 ?
                        <Tooltip placement="top" title={row?.comments} arrow>
                            <div>{row?.comments.slice(0, 26) + "..."}</div></Tooltip>
                        : row?.comments ? row?.comments : "-"}</div>)
                }
            }
        ];

        return (
            <Box id="eMed_ipsrvConfig_accTbl">
                <DataGrid
                    rows={logData ? logData : []}
                    columns={logColumn}
                    getRowId={(row) => row.modified_date}
                    disableColumnMenu
                    hideFooter
                    components={{
                        NoRowsOverlay: () => {
                            return (
                                <Stack className="eMed_conf_nodata">
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }
                    }}
                    headerHeight={40}
                />
            </Box>
        )
    }


    popupClose = () => {
        this.setState({
            selectedMenu: ""
        })
    }

    addCancelComments = (comments) => { 
        this.handleAdd(this.state.selectedMenu, comments)
    }

    // view log popup end

    handleEdit = (data) => {
        this.setState({
            selectedData: data,
            drug_date: data?.date ? new Date(data.date) : null,
            drug_name: data?.drug_name,
            generic_name: data?.generic_name,
            dosage_type: data?.dosage_type,
            dosage_strength: data?.dosage_strength,
            route: data?.route,
            quantity: data?.quantity,
            frequency: data?.frequency,
            instruction: data?.instruction,
            isEdit: true
        })
    }

    checkDataPresence = () => {
        const fieldsToCheck = [
            'drug_ms_id', 'generic_name', 'dosage_type',
            'dosage_strength', 'route', 'quantity', 'frequency', 'instruction'
        ];

        const isDataPresent = fieldsToCheck.some(field => {
            const value = this.state[field];
            return value !== null && value !== "" && value !== undefined;
        });

        return (this.state.drug_name && isDataPresent);
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        const checkuserAccess = CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Drug Chart", "SubTab")?.editAccess ?
            this.state.withOutAppointment?.is_ip : true;
        let myUser = JSON.parse(localGetItem("loggedInUserInfo"));

        const currentDate = new Date();
        const admissionDate = states?.patientData?.admitted_date
            ? new Date(states.patientData?.admitted_date)
            : states?.patientData?.admission_date
                ? new Date(states.patientData?.admission_date)
                : currentDate;
        const dischargeDate = states.patientData?.discharge_date && (new Date(states.patientData?.discharge_date) <= currentDate) ?
            new Date(states.patientData?.discharge_date) : currentDate;

        const columns = [
            {
                field: 'date', headerName: t('Date & Time'), flex: 0.1, renderCell: ({ row }) => {
                    return (
                        <div>{row?.date?.length > 10 ?
                            <Tooltip placement="top" title={formatDateAndTimeMeth2(row?.date || "")} arrow>
                                <div>{formatDateAndTimeMeth2(row?.date).slice(0, 10) + "..."}</div>
                            </Tooltip>
                            : row?.date ? formatDateAndTimeMeth2(row?.date) : "-"}</div>
                    )
                }
            },
            {
                field: 'dosage_type', headerName: t('Dosage type'), flex: 0.1, renderCell: ({ row }) => {
                    return (
                        <div>{row?.dosage_type?.length > 8 ?
                            <Tooltip placement="top" title={row?.dosage_type} arrow>
                                <div>{row?.dosage_type.slice(0, 6) + "..."}</div>
                            </Tooltip>
                            : row?.dosage_type ? row?.dosage_type : "-"}</div>
                    )
                }
            },
            {
                field: 'drug_name', headerName: t('Drug Name'), flex: 0.1, renderCell: ({ row }) => {
                    return (<div>{row?.drug_name?.length > 8 ?
                        <Tooltip placement="top" title={row?.drug_name} arrow>
                            <div>{row?.drug_name.slice(0, 6) + "..."}</div></Tooltip>
                        : row?.drug_name ? row?.drug_name : "-"}</div>
                    )
                }
            },
            {
                field: 'dosage_strength', headerName: t('Dosage Strength'), headerAlign: "center", align: "center", flex: 0.1,
                renderCell: ({ row }) => {
                    return (<div>{row?.dosage_strength?.length > 8 ?
                        <Tooltip placement="top" title={row?.dosage_strength} arrow>
                            <div>{row?.dosage_strength.slice(0, 6) + "..."}</div></Tooltip>
                        : row?.dosage_strength ? row?.dosage_strength : "-"}</div>
                    )
                }
            },
            {
                field: 'route', headerName: t('Route'), flex: 0.1, renderCell: ({ row }) => {
                    return (<div>{row?.route?.length > 8 ?
                        <Tooltip placement="top" title={row?.route} arrow>
                            <div>{row?.route.slice(0, 6) + "..."}</div></Tooltip>
                        : row?.route ? row?.route : "-"}</div>)
                }
            },
            {
                field: 'frequency', headerName: t('Frequency'), flex: 0.1, renderCell: ({ row }) => {
                    return (<div>{row?.frequency?.length > 8 ?
                        <Tooltip placement="top" title={row?.frequency} arrow>
                            <div>{row?.frequency.slice(0, 6) + "..."}</div></Tooltip>
                        : row?.frequency ? row?.frequency : "-"}</div>)
                }
            },
            {
                field: 'instruction', headerName: t('Instruction'), flex: 0.1, renderCell: ({ row }) => {
                    return (<div>{row?.instruction?.length > 8 ?
                        <Tooltip placement="top" title={row?.instruction} arrow>
                            <div>{row?.instruction.slice(0, 6) + "..."}</div></Tooltip>
                        : row?.instruction ? row?.instruction : "-"}</div>)
                }
            },
            {
                field: 'doctor_name', headerName: t('Doctor Name'), flex: 0.1, renderCell: ({ row }) => {
                    return (<div>{row?.doctor_name?.length > 10 ?
                        <Tooltip placement="top" title={row?.doctor_name} arrow>
                            <div>{row?.doctor_name.slice(0, 10) + "..."}</div></Tooltip>
                        : row?.doctor_name ? row?.doctor_name : "-"}</div>)
                }
            },
            {
                field: "action", flex: 0.1, headerName: t("Action"), headerAlign: "center", align: "center",
                sortable: false, hideable: false,
                renderCell: ({ row }) => {
                    const isDocCanEdit = this.props.is_From_IpNotes ? !(myUser?.doctor_id === row?.doctor_id) : checkuserAccess;
                    return (<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <CommonEditButton testID = {"drg_edit_btn"} size={"1.5vw"} disable={isDocCanEdit} onClick={this.handleEdit.bind(this, row)} />
                    {this.MenuList(row)}
                </Box>)}
            }
        ]

        let data = {
            lineOne: t("AttenderName"),
            lineTwo: `${states["attenderData"]["attender_name"] ? states["attenderData"]["attender_name"] : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${states["attenderData"]["attender_mobile"] ? states["attenderData"]["attender_mobile"] : "-"}`
        }

        let Consultant = {
            image: ImagePaths.Doctor_Icon.default,
            width: "2.3vw",
            height: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${states["attenderData"]["doctor_name"] ? states["attenderData"]["doctor_name"] : "-"}`
        }
        return (
            <Box id="eMed_medication_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container className="eMed_medication_Box" >
                    <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} >
                        <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
                            <CommonPatientDetails data={this.state.patientData} showDetailed={true} />
                        </Stack>
                        <CommonPatientCard details={data} showDetailed={true} />
                        <ConsultantPatientCard name={Consultant} width={"20dvw"}/>
                    </Stack>
                    <Grid container item spacing={2} className="eMed_medication_mainDiv">
                        <Grid item xs={8}>
                            <Paper className="eMed_medication_Div" elevation={2}>
                                <DataGrid
                                    rows={states["tblData"]}
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    disableSelectionOnClick = {true}
                                    localeText={{
                                        toolbarColumns: "",
                                        toolbarDensity: "",
                                        toolbarExport: "",
                                        toolbarFilters: "",
                                        toolbarExportPrint: ""
                                    }}
                                    components={{
                                        Toolbar: CommonGridHeader.CommonToolBar,
                                        NoRowsOverlay: () => (
                                            <Stack className='eMed_conf_nodata'>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }}
                                    headerHeight={40}
                                    rowCount={states["tblData"].length}
                                    page={states["current_page"]}
                                    onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                    pageSize={states["rowsPerPage"]}
                                    onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    pagination
                                    getRowClassName={({row}) => {
                                        switch (row.status) {
                                          case 'Start':
                                            return 'eMed_drug_status_start';
                                          case 'Stop':
                                            return 'eMed_drug_status_stop';
                                          case 'Hold':
                                            return 'eMed_drug_status_hold';
                                          case 'Completed':
                                            return 'eMed_drug_status_Completed';
                                          default:
                                            return '';
                                        }
                                    }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className="eMed_medication_Div" elevation={2}>
                                <div className="eMed_lab_subDiv">
                                    <div className='eMed_medication_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                        <p className='eMed_recPayment_rightHead_txt'>{t("Drug Chart")}</p>
                                        {this.renderTextBox("SelectPharmacy", "pharma_name", states["pharma_name"])}
                                    </div>
                                    <Stack className="emedhub_medication_RightContentBox" direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
                                        <Box component={'div'} className='eMed_DocNotes_DateTime' sx={{ backgroundColor: Colors.ThemeLightColor, margin: "0.5dvw !important" }} >
                                            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("Date & Time")}</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    inputFormat='DD-MM-YYYY & hh:mm A'
                                                    value={this.state.drug_date}
                                                    inputProps={{'emed_tid': 'drg_drug_date'}}
                                                    onChange={(newDate) => {
                                                        const currentDate = new Date();
                                                        if (!newDate || newDate > currentDate) {
                                                            this.setState({ drug_date: currentDate });
                                                        } else {
                                                            this.setState({ drug_date: newDate });
                                                        }
                                                    }}
                                                    minDate={admissionDate}
                                                    maxDate={dischargeDate}
                                                    renderInput={(params) =>
                                                        <TextField size='small'
                                                            {...params}
                                                            fullWidth
                                                            variant='standard'
                                                            className='eMed_DocNotes_datePic_Textfield'
                                                        />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <div className="eMed_drugRight_txtDiv">
                                            {this.renderTextBox("Drug Name", "drug_name", states["drug_name"])}
                                            {this.renderTextBox("GenericName", "generic_name", states["generic_name"])}
                                            {this.renderTextBox("DosageType", "dosage_type", states["dosage_type"], true)}
                                            {this.renderTextBox("DosageStrength", "dosage_strength", states["dosage_strength"], true)}
                                            {this.renderTextBox("Route", "route", states["route"])}
                                            {this.renderTextBox("Quantity", "quantity", states["quantity"])}
                                            {this.renderTextBox("Frequency", "frequency", states["frequency"])}
                                            {this.renderTextBox("Instruction", "instruction", states["instruction"])}
                                        </div>
                                    </Stack>
                                </div>
                                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                    <Button variant='outlined' disabled={this.state.withOutAppointment?.is_ip} size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button
                                        disabled={checkuserAccess || this.state.isAdd || !this.checkDataPresence()}
                                        variant='contained' size="small" id="eMed_recPayment_btn"
                                        onClick={() => {
                                            this.setState({ isAdd: true, },
                                                () => { this.handleAdd() })
                                        }}>{t("Add")}</Button>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {
                    (this.state.selectedMenu === "view_log") ?
                        <CommonPopUp width={"75vw"}
                            backgroundColor={Colors.white}
                            popUpClose={this.viewLogPopUpClose.bind(this)}
                            title={t("Log")}
                            component={this._showViewLog.bind(this, states?.viewLogData)} />
                        : null
                }

                {(this.state.selectedMenu && this.state.selectedMenu !== "view_log") ?
                    <ReasonPopup
                        title={t('Reason')}
                        AlertPopupClose={this.popupClose.bind(this)}
                        label={t('Write reason here')}
                        btntext={t('Cancel')}
                        btnvarient={'outlined'}
                        btntext1={t('Save')}
                        btnvarient1={'contained'}
                        sendCmt={this.addCancelComments.bind(this)}
                    /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DrugChart);