import React, { Component } from 'react';
import './ChartHomeCss.css';
import { Box, Typography, Grid, Divider, Button, Drawer, Tooltip, Alert, Skeleton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import LoopIcon from '@mui/icons-material/Loop';
import RevenueCard from './CommonCharts/RevenueCard';
import DoughnutChart from './CommonCharts/DoughnutChart';
import RevenueExpenseTbl from './CommonCharts/RevenueExpenseTbl';
import BarChart from './CommonCharts/Barchart';
import {
    vendorColumns, PurchaseSales, OverallTotalRevenue, OverallRevenueExpence, OverallPaymentCollection,
    OverallCreditAmount, PharmaPatientDemographics, OPRevenueExpenseChart, IPRevenueExpenseChart, OverallAppointmentType,
    OPSpecialzationChart, OPDoctorsChart, OPServicesChart, OPLocationChart, TopSellingDrugs, mostLeastTest, labLocation
} from './CommonCharts/TableColumns';
import ChartDashboardFilter from './ChartDashboardFilter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { ProfitLossPercent, formatDate } from '../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import CountCardView from './CommonCharts/CountCardView';

const createLookupMap = (list, keyName, idName) => {
    return list?.reduce((map, item) => {
        const refKey = `${item[keyName]?.replaceAll(" ", "_")?.toLowerCase()}ref`;
        map[refKey] = item[idName];
        return map;
    }, {});
}

const PharmacyDefault = {
    totalProductsCard: {
        total_product: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    lowStockCard: {
        total_product: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    stockExpiryCard: {
        total_product: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    noMovementCard: {
        total_product: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    pharmaVendor: {
        chartData: [],
        chartLabel: [],
        tblData: [],
        total_amount: 0,
        APILoaded: false
    },
    pharmaPurchase: [],
    pharmaPurchaseAPI: false,
    topSellingDrug: {
        chartData: [],
        chartLabel: [],
        tblData: [],
        APILoaded: false
    },
    pharmaDemographics: [],
    pharmaDemographicsAPILoad: false,
}

const labDefault = {
    orderedTestCard: {
        total_patient: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    completedCard: {
        total_patient: 0,
        total_amount: 0,
        compare_percent: 0,
        APILoaded: false
    },
    labDemographics: [],
    labDemographicsAPI: false,
    labPurchase: [],
    labPurchaseAPILoaded: false,
    mostPerforming: [],
    locationAnalysis: {
        chartData: [],
        chartLabel: [],
        tblData: [],
        APILoaded: false
    },
    leastPerforming: [],
    PerforminAPIload: false
}

class ChartDashboardHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BranchId: localGetItem("BranchId"),
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            FilterOpen: false,
            AlertText: "",
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "CompareFromDate": "",
                "CompareToDate": "",
                "CompareDateIndex": 0,
                "BranchesList": [],
                "ModulesList": [],
                "SelectedBranches": [],
                "SelectedModules": [],
            },
            OverallChartDatas: {
                TotalRevenue: {},
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
            },
            OutPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            InPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            PharmacyChartDatas: { ...PharmacyDefault },
            LabChartDatas: { ...labDefault },
            RadiologyChartDatas: {

                RadiologyDemographics: [],
                RadiologyDemographicsAPI: false,
                mostPerforming: [],
                locationAnalysis: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                leastPerforming: [],
                PerforminAPIload: false
            }
        }
        this.childComponentIds = ["overallRef", "opRef", "ipRef", "pharmaRef", "labRef", "radiologyRef"];
    }

    componentDidMount() {
        this.GetIntialDatas();
        const LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
        const PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
        const LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)) : [];

        // Create lookup maps for pharmacies and laboratories
        if (PharmacyList?.length) {
            this.pharmacyMap = createLookupMap(PharmacyList, 'pharmacy_name', 'pharmacy_id');
        }
        if (LabList?.length) {
            this.labMap = createLookupMap(LabList, 'laboratory_name', 'laboratory_id');
        }
    }

    setupIntersectionObserver = () => {
        const options = {
            threshold: 0.25,
        };
        this.observer = new IntersectionObserver(this.handleIntersection, options);
        this.childComponentIds?.forEach((childId) => {
            const childElement = document.getElementById(childId);
            if (childElement) {
                this.observer.observe(childElement);
            }
        });
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const childId = entry.target.id;
                this.ChartDataAPIcall(childId);
                this.observer.unobserve(entry.target);
            }
        });
    }

    ChartDataAPIcall(childId) {
        try {
            switch (childId) {
                case "overallRef":
                    this.OverallApiCalls();
                    break;
                case "opRef":
                    if (this.isModuleSelected("OP")) { this.OutPatientApiCalls() }
                    break;
                case "ipRef":
                    if (this.isModuleSelected("IP")) { this.InPatientApiCalls() }
                    break;
                case "pharmaRef":
                    if (this.isModuleSelected("PHARMACY")) { this.pharmacyApiCalls() }
                    break;
                case "labRef":
                    if (this.isModuleSelected("LAB")) { this.labApiCalls() }
                    break;
                case "radiologyRef":
                    if (this.isModuleSelected("RADIOLOGY")) { this.radiologyApiCalls() }
                    break;
                default:
                    if (this.pharmacyMap) {
                        const pharmacyId = this.pharmacyMap[childId];
                        if (pharmacyId) {
                            this.pharmacyApiCalls(pharmacyId, childId.replace("ref", ""));
                        }
                    }
                    if (this.labMap) {
                        const labId = this.labMap[childId];
                        if (labId) {
                            this.labApiCalls(labId, childId.replace("ref", ""));
                        }
                    }
                    break;
            }
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    RefreshAllChartsData() {
        this.setState({
            OverallChartDatas: {
                TotalRevenue: {},
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
            },
            OutPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            InPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            PharmacyChartDatas: {
                totalProductsCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                lowStockCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                stockExpiryCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                noMovementCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                pharmaVendor: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    total_amount: 0,
                    APILoaded: false
                },
                pharmaPurchase: [],
                pharmaPurchaseAPI: false,
                topSellingDrug: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                pharmaDemographics: [],
                pharmaDemographicsAPILoad: false,
            },
            LabChartDatas: {
                orderedTestCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                completedCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                labDemographics: [],
                labDemographicsAPI: false,
                labPurchase: [],
                labPurchaseAPILoaded: false,
                mostPerforming: [],
                locationAnalysis: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                leastPerforming: [],
                PerforminAPIload: false
            }
        }, () => { this.setupIntersectionObserver() })
    }

    ShowGreeting() {
        const now = new Date();
        const currentHour = now.getHours();
        let greeting = "";
        if (currentHour >= 5 && currentHour < 12) {
            greeting = "Good Morning !";
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Good Afternoon !";
        } else if (currentHour >= 18 || currentHour < 5) {
            greeting = "Good Evening !";
        }
        return greeting
    }


    sortList = (data, sortBy = "", order) => {
        switch (order) {
            case "asc":
                if (sortBy) {
                    return data.sort((a, b) => a[sortBy] - b[sortBy])
                } else {
                    return data.sort((a, b) => a - b)
                }
            default:
                if (sortBy) {
                    return data.sort((a, b) => b[sortBy] - a[sortBy])
                } else {
                    return data.sort((a, b) => b - a)
                }
        }
    }

    GetIntialDatas() {
        try {
            // Get Modules List from LocalStorage
            const ModulesList = localGetItem("ModulesList") ? JSON.parse(localGetItem("ModulesList")) : [];
            const LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
            const PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
            const LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)) : [];

            if (ModulesList?.length > 0) {
                const branchId = localGetItem("BranchId") ? +localGetItem("BranchId") : null;
                const module = ModulesList?.find((list) => list?.branch_id === branchId);
                const ModuleData = module?.data || [];
                const list = Array.from(new Set(ModuleData.map(item => item.moduels)));

                let FinalData = []
                const states = this.state
                if (list.includes("front_office")) {
                    FinalData.push({ modules: "front_office", module_name: "OP" },
                        { modules: "front_office", module_name: "IP" }
                    )
                }
                if (list.includes("Pharmacy")) {
                    // if((localGetItem('multiPharmacy') === "true")){
                        PharmacyList?.forEach(({ pharmacy_id, pharmacy_name }) => {
                            FinalData.push({ modules: "Pharmacy", module_name: pharmacy_name, pharmacy_id });
                            const pharmacyNameKey = (pharmacy_name)?.replaceAll(" ", "_")?.toLowerCase();
                            states[pharmacyNameKey] = { pharmacy_id, ...JSON.parse(JSON.stringify(PharmacyDefault)) };
                            this.childComponentIds.push(`${pharmacyNameKey}ref`)
                        });
                        this.childComponentIds = this.childComponentIds?.filter((list) => list !== "pharmaRef");
                    // } else {
                    //     FinalData.push({ modules: "Pharmacy", module_name: "PHARMACY" });
                    // }
                }
                if (list.includes("Laboratory")) {
                    // if ((localGetItem('multiLaboratory') === "true")) {
                        LabList?.forEach(({ laboratory_name, laboratory_id }) => {
                            FinalData.push({ modules: "Laboratory", module_name: laboratory_name, laboratory_id });
                            const labNameKey = (laboratory_name)?.replaceAll(" ", "_")?.toLowerCase();
                            states[labNameKey] = { laboratory_id, ...JSON.parse(JSON.stringify(labDefault)) };
                            this.childComponentIds.push(`${labNameKey}ref`)
                        })
                        this.childComponentIds = this.childComponentIds?.filter((list) => list !== "labRef");
                    // } else {
                    //     FinalData.push({ modules: "Laboratory", module_name: "LAB" });
                    // }
                }
                if (list.includes("Radiology")) {
                    FinalData.push({ modules: "Radiology", module_name: "RADIOLOGY" })
                }
                states.FilterDatas.ModulesList = FinalData
                states.FilterDatas.SelectedModules = FinalData
                this.setState({ states }, () => {
                    this.setupIntersectionObserver()
                })
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    OverallApiCalls() {
        try {
            const states = this.state;
            const pharmaId = [], labId = [];
            const selectedModules = Array.from(new Set(states.FilterDatas?.SelectedModules?.map(item => {
                if (item.modules === 'Pharmacy') {
                    if (item.pharmacy_id) pharmaId.push(item.pharmacy_id);
                    return 'PHARMACY';
                } else if (item.modules === 'Laboratory') {
                    if (item.laboratory_id) labId.push(item.laboratory_id);
                    return 'LAB';
                }
                return item.module_name;
            })))
            const OverAllChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&module_type=" + selectedModules +
                "&branch_id=" + states?.BranchId +
                "&pharmacy_id=" + pharmaId +
                "&laboratory_id=" + labId

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_REVENUE +
                "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&cmp_from_date=" + states.FilterDatas?.CompareFromDate +
                "&cmp_to_date=" + states.FilterDatas?.CompareToDate +
                "&module_type=" + selectedModules +
                "&branch_id=" + states?.BranchId +
                "&pharmacy_id=" + pharmaId +
                "&laboratory_id=" + labId)
                .then((response) => {
                    if (response.data.status === "success" && response?.data?.data?.length > 0) {
                        let initialObj = {
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            Pharmacy: 0,
                            Radiology: 0,
                            OT: 0,
                        }
                        let totalAmount = response?.data?.data.reduce((acc, cur, i) => {
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["Pharmacy"] = parseFloat(acc.Pharmacy) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["Radiology"] = parseFloat(acc.Radiology) + parseFloat(cur.radiology_revenue ? cur.radiology_revenue : 0);
                            acc["OT"] = parseFloat(acc.OT) + parseFloat(cur.ot_revenue ? cur.ot_revenue : 0);
                            return acc;
                        }, initialObj)
                        let tableDataArray = []
                        response?.data?.data?.forEach((element) => {
                            element.overall_profit = element?.overall_revenue - element?.overall_expense
                            tableDataArray.push(element)
                        })
                        states["OverallChartDatas"]["TotalRevenue"]["TableData"] = tableDataArray
                        states["OverallChartDatas"]["TotalRevenue"]["ChartLabel"] = Object.keys(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["ChartData"] = Object.values(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["TotalObject"] = totalAmount
                        states["OverallChartDatas"]["TotalRevenue"]["TotalRevenueAmount"] = response?.data?.overall_revenue ? response?.data?.overall_revenue : 0
                        states["OverallChartDatas"]["TotalRevenue"]["TotalExpenseAmount"] = response?.data?.overall_expense ? response?.data?.overall_expense : 0
                        states["OverallChartDatas"]["TotalRevenue"]["BranchName"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.branch_name : "-"

                        let CompareIntailObj = {
                            CompareOP: 0,
                            CompareIP: 0,
                            Comparelab: 0,
                            ComparePharma: 0,
                            CompareRadiology: 0,
                            CompareOT: 0,
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            pharma: 0,
                            Radiology: 0,
                            OT: 0,
                            expence: 0,
                            CompareExpence: 0,
                        }
                        let CompareArray = response?.data?.data.reduce((acc, cur, i) => {
                            acc["CompareOP"] = parseFloat(acc.CompareOP) + parseFloat(cur.cmp_op_revenue ? cur.cmp_op_revenue : 0);
                            acc["CompareIP"] = parseFloat(acc.CompareIP) + parseFloat(cur.cmp_ip_revenue ? cur.cmp_ip_revenue : 0);
                            acc["Comparelab"] = parseFloat(acc.Comparelab) + parseFloat(cur.cmp_lab_revenue ? cur.cmp_lab_revenue : 0);
                            acc["ComparePharma"] = parseFloat(acc.ComparePharma) + parseFloat(cur.cmp_pharmacy_revenue ? cur.cmp_pharmacy_revenue : 0);
                            acc["CompareRadiology"] = parseFloat(acc.CompareRadiology) + parseFloat(cur.cmp_radiology_revenue ? cur.cmp_radiology_revenue : 0);
                            acc["CompareOT"] = parseFloat(acc.CompareOT) + parseFloat(cur.cmp_ot_revenue ? cur.cmp_ot_revenue : 0);
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["pharma"] = parseFloat(acc.pharma) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["OT"] = parseFloat(acc.OT) + parseFloat(cur.ot_revenue ? cur.ot_revenue : 0);
                            acc["Radiology"] = parseFloat(acc.Radiology) + parseFloat(cur.radiology_revenue ? cur.radiology_revenue : 0);
                            acc["expence"] = parseFloat(acc.expence) + parseFloat(cur.overall_expense ? cur.overall_expense : 0);
                            acc["CompareExpence"] = parseFloat(acc.CompareExpence) + parseFloat(cur.cmp_overall_expense ? cur.cmp_overall_expense : 0);
                            return acc;
                        }, CompareIntailObj)

                        states["OverallChartDatas"]["TotalRevenue"]["ComparePercent"] = {
                            OPComparePercent: ProfitLossPercent(CompareArray?.OP, CompareArray?.CompareOP),
                            IPComparePercent: ProfitLossPercent(CompareArray?.IP, CompareArray?.CompareIP),
                            PharmacyComparePercent: ProfitLossPercent(CompareArray?.pharma, CompareArray?.ComparePharma),
                            LabComparePercent: ProfitLossPercent(CompareArray?.Lab, CompareArray?.Comparelab),
                            RadiologyComparePercent: ProfitLossPercent(CompareArray?.Radiology, CompareArray?.CompareRadiology),
                            OTComparePercent: ProfitLossPercent(CompareArray?.OT, CompareArray?.CompareOT),
                            ExpenceComparePercent: ProfitLossPercent(CompareArray?.expence, CompareArray?.CompareExpence),
                        }
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_PAYMENTS + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["PaymentCollection"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["TotalCredit"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["ChartData"] = [
                            response?.data?.total_cash,
                            response?.data?.total_card,
                            response?.data?.total_upi,
                            response?.data?.total_cheque,
                            response?.data?.total_bank_transfer,
                        ]
                        states["OverallChartDatas"]["PaymentCollection"]["ChartLabel"] = ["Cash", "Card", "UPI", "Cheque", "Bank Trans"]
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_CREDIT_AMOUNT + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["CreditAmount"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["CreditAmount"]["ChartData"] = [
                            response?.data?.total_op_credit,
                            response?.data?.total_ip_credit,
                            response?.data?.total_lab_credit,
                            response?.data?.total_pharmacy_credit,
                            response?.data?.total_rad_credit,]
                        states["OverallChartDatas"]["CreditAmount"]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy", "Radiology"]
                        states["OverallChartDatas"]["CreditAmount"]["TotalCreditAmount"] = response?.data?.total_op_credit + response?.data?.total_ip_credit + response?.data?.total_pharmacy_credit + response?.data?.total_lab_credit + response?.data?.total_rad_credit
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            //Call only when OP Module is Selected
            if (this.isModuleSelected("OP")) {
                RestAPIService.getAll(Serviceurls.CHART_OVERALL_APPOINTMENT_TYPE_COUNT + OverAllChartsAPIQuery).
                    then((response) => {
                        if (response.data.status === "success") {
                            let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Type", "des")
                            states["OverallChartDatas"]["AppointmentType"]["TableData"] = FinalData?.TableData
                            states["OverallChartDatas"]["AppointmentType"]["ChartData"] = FinalData?.ChartData
                            states["OverallChartDatas"]["AppointmentType"]["ChartLabel"] = FinalData?.ChartLabel
                            states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                            this.setState({ states })
                        }
                        else {
                            states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                            this.setState({ states })
                            this.errorMessage(response.data.message)
                        }
                    }).catch((error) => {
                        states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(error.message)
                    })
            }
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    OutPatientApiCalls() {
        try {
            let states = this.state
            let OutPatientsChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&branch_id=" + states?.BranchId
            RestAPIService.getAll(Serviceurls.CHART_OP_SPECIALZATION + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["SpecialzationChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["SpecialzationChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["SpecialzationChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_TOP_DOCTORS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_LOCATION_ANALYSIS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Location", "des")
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_SERVICES + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["TopServicesChart"]["TableData"] = FinalData?.TableData?.slice(0, 50) // Slice only First 50 datas
                        states["OutPatientChartDatas"]["TopServicesChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["TopServicesChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        this.setState({ states })

                        let LeastFinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "asc")
                        states["OutPatientChartDatas"]["LeastServicesChart"]["TableData"] = LeastFinalData?.TableData?.slice(0, 50) // Slice only First 50 datas
                        states["OutPatientChartDatas"]["LeastServicesChart"]["ChartData"] = LeastFinalData?.ChartData
                        states["OutPatientChartDatas"]["LeastServicesChart"]["ChartLabel"] = LeastFinalData?.ChartLabel
                        states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                    states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    InPatientApiCalls() {
        try {
            let states = this.state
            let OutPatientsChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&branch_id=" + states?.BranchId
            RestAPIService.getAll(Serviceurls.CHART_IP_SPECIALZATION + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["SpecialzationChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["SpecialzationChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["SpecialzationChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_TOP_DOCTORS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["TopDoctorsChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["TopDoctorsChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["TopDoctorsChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_LOCATION_ANALYSIS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Location", "des")
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_SERVICES + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["TopServicesChart"]["TableData"] = FinalData?.TableData?.slice(0, 50)
                        states["InPatientChartDatas"]["TopServicesChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["TopServicesChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        this.setState({ states })

                        let LeastFinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "asc")
                        states["InPatientChartDatas"]["LeastServicesChart"]["TableData"] = LeastFinalData?.TableData?.slice(0, 50)
                        states["InPatientChartDatas"]["LeastServicesChart"]["ChartData"] = LeastFinalData?.ChartData
                        states["InPatientChartDatas"]["LeastServicesChart"]["ChartLabel"] = LeastFinalData?.ChartLabel
                        states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                    states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    ChangeIntoSingleArray(APIdata, ElseKey = "", SortOrder = "") {
        try {
            let aggregatedData = {};
            APIdata.forEach(list => {
                list.type_data.forEach(type => {
                    const Type = type.title || ElseKey;
                    if (aggregatedData[Type]) {
                        aggregatedData[Type] += type.values;
                    } else {
                        aggregatedData[Type] = type.values;
                    }
                });
            });
            let FinalTotalArray = Object.entries(aggregatedData).map(([Type, values]) => ({ "title": Type, values }));
            if (SortOrder === "asc") {
                FinalTotalArray = FinalTotalArray.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                FinalTotalArray = FinalTotalArray.sort((a, b) => b.values - a.values);
            }
            let ChartData = []
            FinalTotalArray.forEach((item) => (ChartData.push(item?.values)))
            let ChartLabel = []
            FinalTotalArray.forEach((item) => (ChartLabel.push(item?.title ? item?.title.replace(/Appointment/g, '') : ElseKey)))
            let rows = [];
            // Extracting Table datas
            APIdata?.forEach(item => {
                if (item?.type_data?.length > 0) {
                    rows = [...rows, ...item?.type_data?.map((type, index) => ({
                        title: type.title ? type.title : ElseKey,
                        values: type?.values,
                        branch_name: item?.branch_name,
                        branch_id: item?.branch_id,
                    }))]
                }
            })
            if (SortOrder === "asc") {
                rows = rows.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                rows = rows.sort((a, b) => b.values - a.values);
            }
            return { "TableData": rows, "ChartData": ChartData, "ChartLabel": ChartLabel }
        }
        catch (e) {
            console.log(e?.message);
            return { "TableData": [], "ChartData": [], "ChartLabel": [] }
        }
    }

    datastoArray = (apiData, innerData, key1, key2, key3 = "", key4 = "") => {
        let rows = [];
        try {
            if (apiData?.length > 0) {
                // Extracting Table datas
                apiData?.forEach(item => {
                    if (item[innerData]?.length > 0) {
                        rows = [...rows, ...item[innerData]?.map((list, i) => ({
                            branch_name: item?.branch_name,
                            [key1]: list[key1],
                            [key2]: list[key2],
                            [key3]: key3 ? list[key3] : "",
                            [key4]: key4 ? list[key4] : ""
                        }))]
                    };
                })
            }
            return rows;
        } catch (e) {
            console.log(e?.message);
            return rows;
        }
    }

    pharmacyApiCalls(id = '', statekey = 'PharmacyChartDatas') {
        const { FilterDatas, BranchId } = this.state;
        const Getquery = `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&branch_id=${BranchId}&pharmacy_id=${id}`;
        const pharmaData = this.state[statekey]?.pharmacy_id === id ? { ...this.state[statekey] } : {};
        try {

            // Vendor Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_VENDOR + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let list = Data?.data ? Data?.data : [];
                        let rows = [];

                        rows = this.datastoArray(list, "vendor_data", "vendor_id", "vendor__name", "pharmacy_id__branch_detail_id__name", "total_paid_amount");

                        // Extracting chart datas
                        const initialObj = {
                            chartData: [],
                            chartLabel: [],
                            totalAmount: 0,
                        }

                        const chartDatas = list[0]?.top_five?.length > 0 ? list[0]?.top_five?.reduce((acc, cur) => {
                            let total_Amount = parseFloat(cur.total_paid_amount ? cur.total_paid_amount : 0);
                            acc["totalAmount"] += total_Amount;
                            acc["chartData"].push(total_Amount);
                            acc["chartLabel"].push(cur.vendor__name ? cur.vendor__name : "");
                            return acc;
                        }, initialObj) : initialObj;

                        if (chartDatas?.chartData?.length > 0 && chartDatas?.chartLabel?.length > 0) {
                            let remainingValue = Math.abs(parseFloat(Data?.total_amount ? Data.total_amount : 0) - parseFloat(chartDatas?.totalAmount ? chartDatas?.totalAmount : 0));
                            if (remainingValue) {
                                chartDatas["chartData"].push(remainingValue);
                                chartDatas["chartLabel"].push("Others");
                            }
                        }

                        //Assigning values to state
                        pharmaData.pharmaVendor.chartData = chartDatas?.chartData?.length > 0 ? chartDatas?.chartData : [];
                        pharmaData.pharmaVendor.chartLabel = chartDatas?.chartLabel?.length > 0 ? chartDatas?.chartLabel : [];
                        pharmaData.pharmaVendor.tblData = rows.length > 0 ? this.sortList([...rows], "total_paid_amount", "") : [];
                        pharmaData.pharmaVendor.total_amount = Data?.total_amount ? parseFloat(Data.total_amount) : 0;
                        pharmaData.pharmaVendor.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                    else {
                        pharmaData.pharmaVendor.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                        this.errorMessage(Data.message)
                    }
                }).catch((error) => {
                    pharmaData.pharmaVendor.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Purchase Vs Sales Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_SALE_PURCHASE + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        pharmaData.pharmaPurchase = list;
                        pharmaData.pharmaPurchaseAPI = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.pharmaPurchaseAPI = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Top selling drugs Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_TOP_SELLING + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let list = Data?.data?.length > 0 ? Data?.data : [];
                        let rows = [], chartData = [], chartLabel = [];

                        rows = this.datastoArray(list, "drug_data", "branch_id", "drug_ms__drug_name", "drug_counnt", "drug_amount");

                        // Extracting chart data
                        let sortedItem = this.sortList([...rows], "drug_counnt");
                        let slicedData = sortedItem?.slice(0, 6);

                        if (slicedData?.length > 0) {
                            slicedData?.forEach(data => {
                                chartData.push(data?.drug_counnt ? data?.drug_counnt : 0);
                                chartLabel.push(data?.drug_ms__drug_name ? data?.drug_ms__drug_name : "");
                            })
                        }

                        //Assigning values to state
                        pharmaData.topSellingDrug.tblData = sortedItem?.length > 0 ? sortedItem : [];
                        pharmaData.topSellingDrug.chartData = chartData?.length > 0 ? chartData : [];
                        pharmaData.topSellingDrug.chartLabel = chartLabel?.length > 0 ? chartLabel : [];
                        pharmaData.topSellingDrug.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.topSellingDrug.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Patient Demographics Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_DEMOGRAPHICS + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        pharmaData.pharmaDemographics = list;
                        pharmaData.pharmaDemographicsAPILoad = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.pharmaDemographicsAPILoad = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Pharmacy card data Api calls

            // No movement drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_NOMOVEMENT + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        pharmaData.noMovementCard.total_product = Data?.data?.total_product;
                        pharmaData.noMovementCard.total_amount = total_amount1;
                        pharmaData.noMovementCard.compare_percent = comparePercent;
                        pharmaData.noMovementCard.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.noMovementCard.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Short expiry drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_STOCKEXPIRY + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.cmp_total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        pharmaData.stockExpiryCard.total_product = Data?.data?.total_drug;
                        pharmaData.stockExpiryCard.total_amount = total_amount1;
                        pharmaData.stockExpiryCard.compare_percent = comparePercent;
                        pharmaData.stockExpiryCard.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.stockExpiryCard.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            // Low Stock drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_LOWSTOCK + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        pharmaData.lowStockCard.total_product = Data?.data?.total_product;
                        pharmaData.lowStockCard.total_amount = total_amount1;
                        pharmaData.lowStockCard.compare_percent = comparePercent;
                        pharmaData.lowStockCard.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.lowStockCard.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

            //Total drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_TOTALDRUG + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        pharmaData.totalProductsCard.total_product = Data?.data?.total_product;
                        pharmaData.totalProductsCard.total_amount = total_amount1;
                        pharmaData.totalProductsCard.compare_percent = comparePercent;
                        pharmaData.totalProductsCard.APILoaded = true;
                        this.setState({
                            [statekey]: pharmaData
                        })
                    }
                }).catch((error) => {
                    pharmaData.totalProductsCard.APILoaded = true;
                    this.setState({
                        [statekey]: pharmaData
                    })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    labApiCalls(id = '', statekey = 'LabChartDatas') {
        const { FilterDatas, BranchId } = this.state;
        const labData = this.state[statekey]?.laboratory_id === id ? { ...this.state[statekey] } : {};
        let Getquery = `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&branch_id=${BranchId}&laboratory_id=${id}`;
        let Chartquery = (FilterDatas?.CompareFromDate && FilterDatas?.CompareToDate) ?
            `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&cmp_from_date=${FilterDatas?.CompareFromDate}&cmp_to_date=${FilterDatas?.CompareToDate}&branch_id=${BranchId}&laboratory_id=${id}`
            : Getquery;

        try {
            // Laboratory card data Api calls

            // Total Ordered Test
            RestAPIService.getAll(Serviceurls.CHART_LAB_ORDEREDTEST + Chartquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        labData.orderedTestCard.total_patient = Data?.data?.total_patient;
                        labData.orderedTestCard.total_amount = total_amount1;
                        labData.orderedTestCard.compare_percent = comparePercent;
                        labData.orderedTestCard.APILoaded = true;
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.orderedTestCard.APILoaded = true;
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

            // Total Completed Test
            RestAPIService.getAll(Serviceurls.CHART_LAB_COMPLETEDTEST + Chartquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        labData.completedCard.total_patient = Data.data.total_patient;
                        labData.completedCard.total_amount = total_amount1;
                        labData.completedCard.compare_percent = comparePercent;
                        labData.completedCard.APILoaded = true;
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.completedCard.APILoaded = true;
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

            // Patient Demographics Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_DEMOGRAPHICS + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        labData.labDemographics = Data?.data?.length > 0 ? this.sortList([...Data?.data], "city_count") : [];
                        labData.labDemographicsAPI = true
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.labDemographicsAPI = true
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

            // Purchase Vs Sales Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_SALE_PURCHASE + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        labData.labPurchase = list;
                        labData.labPurchaseAPILoaded = true;
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.labPurchaseAPILoaded = true;
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

            // Location Analysis Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_LOCATION + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [], cityDetails = {};
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "data", "branch_id", "patient__city__city_name", "city_count");
                        if (rows?.length > 0) {
                            cityDetails = rows?.reduce((acc, cur) => {
                                let { patient__city__city_name, city_count } = cur;
                                acc[patient__city__city_name] = (acc[patient__city__city_name] ? acc[patient__city__city_name] : 0) + (city_count ? city_count : 0)
                                return acc;
                            }, {})
                        }
                        labData.locationAnalysis.tblData = rows;
                        labData.locationAnalysis.chartData = Object.values(cityDetails);
                        labData.locationAnalysis.chartLabel = Object.keys(cityDetails);
                        labData.locationAnalysis.APILoaded = true;
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.locationAnalysis.APILoaded = true;
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

            // Most and least performing Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_MOST_PERFORMING + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [];
                    let list = Data?.data ? Data?.data : [];

                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "test_data", "branch_id", "test_name", "count");
                        labData.mostPerforming = rows?.length > 0 ? this.sortList([...rows], "count", "")?.slice(0, 50) : [];
                        labData.leastPerforming = rows?.length > 0 ? this.sortList([...rows], "count", "asc")?.slice(0, 50) : [];
                        labData.PerforminAPIload = true;
                        this.setState({
                            [statekey] : labData
                        })
                    }
                }).catch((error) => {
                    labData.PerforminAPIload = true;
                    this.setState({
                        [statekey] : labData
                    })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    radiologyApiCalls() {
        let { RadiologyChartDatas, FilterDatas, BranchId } = this.state;
        let Getquery = `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&branch_id=${BranchId}`;

        try {
            // radiology card data Api calls

            // Patient Demographics Api call
            RestAPIService.getAll(Serviceurls.CHART_RAD_DEMOGRAPHICS + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        RadiologyChartDatas.RadiologyDemographics = Data?.data?.length > 0 ? this.sortList([...Data?.data], "city_count") : [];
                        RadiologyChartDatas.RadiologyDemographicsAPI = true
                        this.setState({
                            RadiologyChartDatas
                        })
                    }
                }).catch((error) => {
                    RadiologyChartDatas.RadiologyDemographicsAPI = true
                    this.setState({
                        RadiologyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_RAD_TESTS + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [];
                    let list = Data?.data ? Data?.data : [];

                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "test_data", "branch_id", "test_name", "count");
                        RadiologyChartDatas.mostPerforming = rows?.length > 0 ? this.sortList([...rows], "count", "")?.slice(0, 50) : [];
                        RadiologyChartDatas.PerforminAPIload = true;
                        this.setState({
                            RadiologyChartDatas
                        })
                    }
                }).catch((error) => {
                    RadiologyChartDatas.PerforminAPIload = true;
                    this.setState({
                        RadiologyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Location Analysis Api call
            RestAPIService.getAll(Serviceurls.CHART_RAD_LOCATION + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [], cityDetails = {};
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "data", "branch_id", "patient__city__city_name", "city_count");
                        if (rows?.length > 0) {
                            cityDetails = rows?.reduce((acc, cur) => {
                                let { patient__city__city_name, city_count } = cur;
                                acc[patient__city__city_name] = (acc[patient__city__city_name] ? acc[patient__city__city_name] : 0) + (city_count ? city_count : 0)
                                return acc;
                            }, {})
                        }
                        RadiologyChartDatas.locationAnalysis.tblData = rows;
                        RadiologyChartDatas.locationAnalysis.chartData = Object.values(cityDetails);
                        RadiologyChartDatas.locationAnalysis.chartLabel = Object.keys(cityDetails);
                        RadiologyChartDatas.locationAnalysis.APILoaded = true;
                        this.setState({
                            RadiologyChartDatas
                        })
                    }
                }).catch((error) => {
                    RadiologyChartDatas.locationAnalysis.APILoaded = true;
                    this.setState({
                        RadiologyChartDatas
                    })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    FilterDate = (From, To, Index) => {
        this.setState({
            FromDate: From,
            ToDate: To,
            DateIndex: Index
        })
    }

    returnFilteredDate() {
        let { FilterDatas } = this.state;
        if (FilterDatas?.FromDate === "" && FilterDatas?.ToDate === "") {
            return "All"
        } else if (FilterDatas?.FromDate === FilterDatas?.ToDate) {
            return formatDate(FilterDatas?.FromDate)
        } else {
            return `${formatDate(FilterDatas?.FromDate)} - ${formatDate(FilterDatas?.ToDate)}`
        }
    }

    HeaderSegment = () => {
        let Fi
        return (
            <Box className="eMed_CharhPage_Header_div">
                <Box flex={0.8}>
                    <Typography fontWeight={600} fontSize={"1.2vw"}>{this.ShowGreeting()}</Typography>
                    <Typography fontSize={"1vw"}>Here is a Overview of your Hospital</Typography>
                </Box>
                <Box flex={0.2} className="eMed_CharhPage_Header_filter">
                    <Box display={'flex'} flexDirection={'row'} width={'25vw'} justifyContent={'flex-end'} paddingRight={"1vw"} alignItems={"flex-end"}>
                        <Typography fontSize={"0.8vw"} paddingRight={"0.5vw"}>Filtered Date : </Typography>
                        <Typography fontSize={"0.9vw"} paddingRight={"0.5vw"} fontWeight={600}>{this.returnFilteredDate()}</Typography>
                    </Box>
                    <Tooltip title="Filter" arrow placement='top'>
                        <Button onClick={() => { this.setState({ FilterOpen: true }) }} variant='outlined' sx={{ backgroundColor: 'white', marginRight: '1vw' }}>
                            <FilterAltIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Refresh All Charts" arrow placement='top'>
                        <Button onClick={() => {
                            this.RefreshAllChartsData()
                        }} variant='outlined' sx={{ backgroundColor: 'white' }}>
                            <LoopIcon />
                        </Button>
                    </Tooltip>
                </Box>

            </Box>
        )
    }

    OverallCharts = () => {
        let IPRevenueData = {
            image: ImagePaths.DashboardBed.default,
            title: "IP Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.IPComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.IPComparePercent : 0,
        }
        let OPRevenueData = {
            image: ImagePaths.DashboardOp.default,
            title: "OP Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.OPComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.OPComparePercent : 0,
        }
        let PharmaRevenueData = {
            image: ImagePaths.Product.default,
            title: "Pharamcy Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.PharmacyComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.PharmacyComparePercent : 0,
        }
        let LabRevenueData = {
            image: ImagePaths.DashboardTick.default,
            title: "Laboratory Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.LabComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.LabComparePercent : 0,
        }
        let RadiologyRevenueData = {
            image: ImagePaths.DashboardTick.default,
            title: "Radiology Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Radiology ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Radiology : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.RadiologyComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.RadiologyComparePercent : 0,
        }
        let OTRevenueData = {
            image: ImagePaths.DashboardExpense.default,
            title: "OT Revenue",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OT ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OT : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : 0,
        }
        let TotalExpenseData = {
            image: ImagePaths.DashboardExpense.default,
            title: "Total expense",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.ExpenceComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.ExpenceComparePercent : 0,
        }

        return (
            <Box id="overallRef" className="eMed_Ovarall_Chart_Wrapper">
                <Grid container spacing={1} >
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <DoughnutChart
                                    title={"Total Revenue"}
                                    chartLabel={this.state.OverallChartDatas?.TotalRevenue?.ChartLabel && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.TotalRevenue?.ChartData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []}
                                    subTitle={'Revenue'}
                                    totalAmt={this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount : 0}
                                    position={"bottom"}
                                    // chartColor={["#6987D5", "#7BD8C2", "#CD6FC9", "#F1584A", "#ECE263", "#CD6EA8"].splice(0, this.state.OverallChartDatas?.TotalRevenue?.ChartData ? this.state.OverallChartDatas?.TotalRevenue?.ChartData?.length : 0)}
                                    extractValue={"revenue"}
                                    extractLabel={"module"}
                                    showValueAtCenter={true}
                                    tblColumn={OverallTotalRevenue}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} className="eMed_DashChart_CardView_Wrapper">
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={OPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={IPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={PharmaRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={LabRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={RadiologyRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={OTRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.PaymentCollection?.APILoaded ?
                                <BarChart
                                    title={"Overall Payment Collection"}
                                    Tlt2={"Amount"}
                                    chartLabel={this.state.OverallChartDatas?.PaymentCollection?.ChartLabel ? this.state.OverallChartDatas?.PaymentCollection?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.PaymentCollection?.ChartData ? this.state.OverallChartDatas?.PaymentCollection?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.PaymentCollection?.TableData ? this.state.OverallChartDatas?.PaymentCollection?.TableData : []}
                                    tblColumn={OverallPaymentCollection}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <BarChart
                                    title={"Revenue and Expense"}
                                    Tlt2={"Revenue"}
                                    Tlt3={"Expense"}
                                    tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []}
                                    valueToBeExtracted={["overall_revenue", "overall_expense"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={OverallRevenueExpence}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.CreditAmount?.APILoaded ?
                                <DoughnutChart
                                    title={"Credit Bill Value"}
                                    chartLabel={this.state.OverallChartDatas?.CreditAmount?.ChartLabel && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.CreditAmount?.ChartData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.CreditAmount?.TableData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TableData : []}
                                    subTitle={'Credit Bill Value'}
                                    totalAmt={this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount : 0}
                                    position={"bottom"}
                                    // chartColor={["#6987D5", "#7BD8C2", "#CD6FC9", "#F1584A", "#ECE263"]}
                                    extractValue="lab_bill_summary_id"
                                    extractLabel="lab_test__name"
                                    showValueAtCenter={true}
                                    tblColumn={OverallCreditAmount}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.isModuleSelected("OP") ?   // Show only When OP Module is Selected
                                this.state.OverallChartDatas?.AppointmentType?.APILoaded ?
                                    <BarChart
                                        title={"Appointment Type"}
                                        Tlt2={"Count"}
                                        chartLabel={this.state.OverallChartDatas?.AppointmentType?.ChartLabel ? this.state.OverallChartDatas?.AppointmentType?.ChartLabel : []}
                                        chartData={this.state.OverallChartDatas?.AppointmentType?.ChartData ? this.state.OverallChartDatas?.AppointmentType?.ChartData : []}
                                        tblData={this.state.OverallChartDatas?.AppointmentType?.TableData ? this.state.OverallChartDatas?.AppointmentType?.TableData : []}
                                        tblColumn={OverallAppointmentType}
                                    /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" /> : null}
                        </Box>
                    </Grid>
                    <Grid item className="eMed_DashChart_CardView_Wrapper">
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={TotalExpenseData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    OutPatientCharts = () => {
        return (
            <Box id="opRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>Out Patient Dashboard</Typography>
                </Divider>
                <Grid container spacing={1} >
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.SpecialzationChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Specialzation"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.SpecialzationChart?.ChartLabel ? this.state.OutPatientChartDatas?.SpecialzationChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.SpecialzationChart?.ChartData ? this.state.OutPatientChartDatas?.SpecialzationChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.SpecialzationChart?.TableData ? this.state.OutPatientChartDatas?.SpecialzationChart?.TableData : []}
                                    tblColumn={OPSpecialzationChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.TopDoctorsChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Doctor"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartLabel ? this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartData ? this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.TopDoctorsChart?.TableData ? this.state.OutPatientChartDatas?.TopDoctorsChart?.TableData : []}
                                    tblColumn={OPDoctorsChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.TopServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Most Performing Services"}
                                    chartLabel={this.state.OutPatientChartDatas?.TopServicesChart?.ChartLabel ? this.state.OutPatientChartDatas?.TopServicesChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.TopServicesChart?.ChartData ? this.state.OutPatientChartDatas?.TopServicesChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.TopServicesChart?.TableData ? this.state.OutPatientChartDatas?.TopServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.LocationAnalysisChart?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartLabel ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartData ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.LocationAnalysisChart?.TableData ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.TableData : []}
                                    tblColumn={OPLocationChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueExpenseTbl tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []} columns={OPRevenueExpenseChart} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.LeastServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Least Performing Services"}
                                    chartLabel={this.state.OutPatientChartDatas?.LeastServicesChart?.ChartLabel ? this.state.OutPatientChartDatas?.LeastServicesChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.LeastServicesChart?.ChartData ? this.state.OutPatientChartDatas?.LeastServicesChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.LeastServicesChart?.TableData ? this.state.OutPatientChartDatas?.LeastServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                    subTitle={'Services'}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    InPatientCharts = () => {
        return (
            <Box id="ipRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>In Patient Dashboard</Typography>
                </Divider>
                <Grid container spacing={1} >
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.SpecialzationChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Specialzation"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.SpecialzationChart?.ChartLabel ? this.state.InPatientChartDatas?.SpecialzationChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.SpecialzationChart?.ChartData ? this.state.InPatientChartDatas?.SpecialzationChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.SpecialzationChart?.TableData ? this.state.InPatientChartDatas?.SpecialzationChart?.TableData : []}
                                    tblColumn={OPSpecialzationChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.TopDoctorsChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Doctor"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.TopDoctorsChart?.ChartLabel ? this.state.InPatientChartDatas?.TopDoctorsChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.TopDoctorsChart?.ChartData ? this.state.InPatientChartDatas?.TopDoctorsChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.TopDoctorsChart?.TableData ? this.state.InPatientChartDatas?.TopDoctorsChart?.TableData : []}
                                    tblColumn={OPDoctorsChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.TopServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Most Performing Services"}
                                    chartLabel={this.state.InPatientChartDatas?.TopServicesChart?.ChartLabel ? this.state.InPatientChartDatas?.TopServicesChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.TopServicesChart?.ChartData ? this.state.InPatientChartDatas?.TopServicesChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.TopServicesChart?.TableData ? this.state.InPatientChartDatas?.TopServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.LocationAnalysisChart?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartLabel ? this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartData ? this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.LocationAnalysisChart?.TableData ? this.state.InPatientChartDatas?.LocationAnalysisChart?.TableData : []}
                                    tblColumn={OPLocationChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueExpenseTbl tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []} columns={IPRevenueExpenseChart} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.LeastServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Least Performing Services"}
                                    chartLabel={this.state.InPatientChartDatas?.LeastServicesChart?.ChartLabel ? this.state.InPatientChartDatas?.LeastServicesChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.LeastServicesChart?.ChartData ? this.state.InPatientChartDatas?.LeastServicesChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.LeastServicesChart?.TableData ? this.state.InPatientChartDatas?.LeastServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    PharmacyCharts = (Multiple = false, PharmacyData = null) => {
        const { OverallChartDatas, PharmacyChartDatas } = this.state;
        let pharmacy_datas = { ...PharmacyChartDatas };
        if (pharmacy_datas) {
            const pharmacyNameKey = (PharmacyData?.pharmacy_name)?.replaceAll(" ", "_")?.toLowerCase();
            pharmacy_datas = this.state[pharmacyNameKey]
        }
        let vendorDatas = pharmacy_datas?.pharmaVendor, noMovementData = pharmacy_datas?.noMovementCard, shortExpiry = pharmacy_datas?.stockExpiryCard;
        let lowStock = pharmacy_datas?.lowStockCard, totalDrug = pharmacy_datas?.totalProductsCard, topSellingDrug = pharmacy_datas?.topSellingDrug;
        let Branch_name = OverallChartDatas?.TotalRevenue?.BranchName ? OverallChartDatas?.TotalRevenue?.BranchName : "-";

        const TotalProducts = {
            image: ImagePaths.Product.default, title: "Total Drugs", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: totalDrug?.total_product ? totalDrug?.total_product : 0,
            Amount: totalDrug?.total_amount ? totalDrug?.total_amount : 0,
        }

        const TotalLowStock = {
            image: ImagePaths.LowStock.default, title: "Total Low Stock", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: lowStock?.total_product ? lowStock?.total_product : 0,
            Amount: lowStock?.total_amount ? lowStock?.total_amount : 0,
        }

        const ShortExpiry = {
            image: ImagePaths.ShortExpiry.default, title: "Short Expiry", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: shortExpiry?.total_product ? shortExpiry?.total_product : 0,
            Amount: shortExpiry?.total_amount ? shortExpiry?.total_amount : 0,
        }

        const NoMovementStock = {
            image: ImagePaths.LowStock.default, title: "No Movement Stock", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: noMovementData?.total_product ? noMovementData?.total_product : 0,
            Amount: noMovementData?.total_amount ? noMovementData?.total_amount : 0,
        }

        return (
            // id={Multiple ? `${(PharmacyData?.pharmacy_name).replace(" ", "_")}Ref` : "pharmaRef"}
            <Box id={`${(PharmacyData?.pharmacy_name)?.replaceAll(" ", "_")?.toLowerCase()}ref`} className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>{Multiple && PharmacyData?.pharmacy_name ?
                        `${PharmacyData?.pharmacy_name} Dashboard` :
                        "Pharmacy Dashboard"}</Typography>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={3.36} className='eMed_DashChart_CardView_Wrapper'>
                        <Box className="eMed_DashChart_CardView">
                            {pharmacy_datas?.totalProductsCard?.APILoaded ?
                                <CountCardView details={TotalProducts} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {pharmacy_datas?.lowStockCard?.APILoaded ?
                                <CountCardView details={TotalLowStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {pharmacy_datas?.stockExpiryCard?.APILoaded ?
                                <CountCardView details={ShortExpiry} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {pharmacy_datas?.noMovementCard?.APILoaded ?
                                <CountCardView details={NoMovementStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4.57} >
                        <Box className="eMed_Individual_DashChart">
                            {vendorDatas?.APILoaded ?
                                <DoughnutChart
                                    title={"Vendor Payment"}
                                    chartData={vendorDatas?.chartData?.length > 0 ? vendorDatas?.chartData : []}
                                    chartLabel={vendorDatas?.chartLabel?.length > 0 ? vendorDatas?.chartLabel : []}
                                    tblData={vendorDatas?.tblData?.length > 0 ? vendorDatas?.tblData : []}
                                    subTitle={'Purchase value'}
                                    totalAmt={vendorDatas.total_amount ? vendorDatas.total_amount : 0}
                                    position={""}
                                    showValueAtCenter={true}
                                    tblColumn={vendorColumns}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4.07} >
                        <Box className="eMed_Individual_DashChart">
                            {pharmacy_datas?.pharmaPurchaseAPI ?
                                <BarChart
                                    title={"Purchase Vs Sales"}
                                    Tlt2={"Sales"}
                                    Tlt3={"Purchase"}
                                    tblData={pharmacy_datas?.pharmaPurchase?.length > 0 ? pharmacy_datas?.pharmaPurchase : []}
                                    valueToBeExtracted={["sales_amount", "purchase_amount"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PurchaseSales}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">

                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {topSellingDrug?.APILoaded ?
                                <BarChart
                                    title={"Top Selling Drugs"} Tlt2={"Count"}
                                    tblData={topSellingDrug?.tblData?.length > 0 ? topSellingDrug?.tblData : []}
                                    chartData={topSellingDrug?.chartData?.length > 0 ? topSellingDrug?.chartData : []}
                                    chartLabel={topSellingDrug?.chartLabel?.length > 0 ? topSellingDrug?.chartLabel : []}
                                    tblColumn={TopSellingDrugs}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {pharmacy_datas?.pharmaDemographicsAPILoad ?
                                <BarChart
                                    title={"Patient Demographics"}
                                    Tlt1={"IP"} Tlt2={"OP"} Tlt3={"Self"}
                                    tblData={pharmacy_datas?.pharmaDemographics?.length > 0 ? pharmacy_datas?.pharmaDemographics : []}
                                    valueToBeExtracted={["ip_bill_count", "op_bill_count", "self_bill_count"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PharmaPatientDemographics}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    LaboratoryCharts = (Multiple = false, labData = null) => {
        const { LabChartDatas, OverallChartDatas } = this.state;
        let lab_datas = { ...LabChartDatas };
        if (labData) {
            const labNameKey = (labData?.laboratory_name)?.replaceAll(" ", "_")?.toLowerCase();
            lab_datas = this.state[labNameKey];
        }
        const { orderedTestCard, completedCard, locationAnalysis } = lab_datas;

        const Branch_name = OverallChartDatas?.TotalRevenue?.BranchName ? OverallChartDatas?.TotalRevenue?.BranchName : "-";

        const orderTests = {
            image: ImagePaths.Product.default, title: "Total Ordered Test", countLabel: "Total Test",
            branchName: Branch_name,
            count: orderedTestCard?.total_patient ? orderedTestCard?.total_patient : 0,
            Amount: orderedTestCard?.total_amount ? orderedTestCard?.total_amount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : (orderedTestCard?.compare_percent ? orderedTestCard?.compare_percent : 0)
        }

        const completedTests = {
            image: ImagePaths.LowStock.default, title: "Total Completed Test", countLabel: "Total Test",
            branchName: Branch_name,
            count: completedCard?.total_patient ? completedCard?.total_patient : 0,
            Amount: completedCard?.total_amount ? completedCard?.total_amount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : (completedCard?.compare_percent ? completedCard?.compare_percent : 0)
        }

        return (
            // d= {Multiple ? `${(labData?.laboratory_name)?.replace(" ", "_")}Ref`:"labRef"}
            <Box id= {`${(labData?.laboratory_name)?.replaceAll(" ", "_")?.toLowerCase()}ref`} className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>{(Multiple && labData?.laboratory_name) ? `${labData?.laboratory_name} Dashboard` : "Laboratory Dashboard"}</Typography>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={1.66} className='eMed_DashChart_CardView_Wrapper'>
                        <Box className="eMed_DashChart_CardView">
                            {orderedTestCard?.APILoaded ?
                                <CountCardView details={orderTests} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {completedCard?.APILoaded ?
                                <CountCardView details={completedTests} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {lab_datas?.labDemographicsAPI ?
                                <BarChart
                                    title={"Patient Demographics"}
                                    Tlt1={"IP"} Tlt2={"OP"} Tlt3={"Self"}
                                    tblData={lab_datas?.labDemographics?.length > 0 ? lab_datas?.labDemographics : []}
                                    valueToBeExtracted={["ip_bill_count", "op_bill_count", "self_bill_count"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PharmaPatientDemographics}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {lab_datas?.labPurchaseAPILoaded ?
                                <BarChart
                                    title={"Purchase Vs Sales"}
                                    Tlt2={"Sales"}
                                    Tlt3={"Purchase"}
                                    tblData={lab_datas?.labPurchase?.length > 0 ? lab_datas?.labPurchase : []}
                                    valueToBeExtracted={["sales_amount", "purchase_amount"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PurchaseSales}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {lab_datas?.PerforminAPIload ?
                                <DoughnutChart
                                    title={"Most Performing Tests"}
                                    tblData={lab_datas?.mostPerforming?.length > 0 ? lab_datas?.mostPerforming : []}
                                    position={"bottom"}
                                    extractValue="count"
                                    extractLabel="test_name"
                                    // showCountOnChart={true}
                                    tblColumn={mostLeastTest}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {locationAnalysis?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    tblData={locationAnalysis?.tblData?.length > 0 ? locationAnalysis?.tblData : []}
                                    chartData={locationAnalysis?.chartData?.length > 0 ? locationAnalysis?.chartData : []}
                                    chartLabel={locationAnalysis?.chartLabel?.length > 0 ? locationAnalysis?.chartLabel : []}
                                    tblColumn={labLocation}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">

                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {lab_datas?.PerforminAPIload ?
                                <DoughnutChart
                                    title={"Least Performing Tests"}
                                    tblData={lab_datas?.leastPerforming?.length > 0 ? lab_datas?.leastPerforming : []}
                                    position={"bottom"}
                                    extractValue="count"
                                    extractLabel="test_name"
                                    // showCountOnChart={true}
                                    tblColumn={mostLeastTest}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    RadiologyCharts = () => {
        let { RadiologyChartDatas } = this.state;
        let { locationAnalysis } = RadiologyChartDatas;
        return (
            <Box id="radiologyRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>Radiology Dashboard</Typography>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {RadiologyChartDatas?.RadiologyDemographicsAPI ?
                                <BarChart
                                    title={"Patient Demographics"}
                                    Tlt1={"IP"} Tlt2={"OP"} Tlt3={"Self"}
                                    tblData={RadiologyChartDatas?.RadiologyDemographics?.length > 0 ? RadiologyChartDatas?.RadiologyDemographics : []}
                                    valueToBeExtracted={["ip_bill_count", "op_bill_count", "self_bill_count"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PharmaPatientDemographics}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {RadiologyChartDatas?.PerforminAPIload ?
                                <DoughnutChart
                                    title={"Most Performing Tests"}
                                    tblData={RadiologyChartDatas?.mostPerforming?.length > 0 ? RadiologyChartDatas?.mostPerforming : []}
                                    position={"bottom"}
                                    extractValue="count"
                                    extractLabel="test_name"
                                    tblColumn={mostLeastTest}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            <Box className="eMed_Individual_DashChart">
                                {locationAnalysis?.APILoaded ?
                                    <BarChart
                                        title={"Location Analysis"}
                                        Tlt2={"Count"}
                                        tblData={locationAnalysis?.tblData?.length > 0 ? locationAnalysis?.tblData : []}
                                        chartData={locationAnalysis?.chartData?.length > 0 ? locationAnalysis?.chartData : []}
                                        chartLabel={locationAnalysis?.chartLabel?.length > 0 ? locationAnalysis?.chartLabel : []}
                                        tblColumn={labLocation}
                                    /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    ClosePopUp() {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => {
            this.RefreshAllChartsData()
        })
    }

    isModuleSelected = (moduleName, key = "module_name") => {
        return this.state.FilterDatas?.SelectedModules?.some(obj => obj[key] === moduleName);
    }

    render() {
        const {AlertText} = this.state;
        const LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
        const PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
        const LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)) : [];
        return (
            <Box id='eMed_BM_mainDiv'>
                {this.HeaderSegment()}
                {AlertText !== "" ? <Alert severity="error" onClose={() => { this.setState({ AlertText: "" }) }}>{AlertText}</Alert> : null}
                <Box className="eMed_Dashboard_Chart_wrapper" height={AlertText !== "" ? "69vh" : "75.5vh"}>
                    {this.OverallCharts()}
                    {this.isModuleSelected("OP") ? this.OutPatientCharts() : null}
                    {this.isModuleSelected("IP") ? this.InPatientCharts() : null}
                    {PharmacyList?.map((list) => {
                        if (this.isModuleSelected(list?.pharmacy_id, 'pharmacy_id')) {
                            return this.PharmacyCharts(localGetItem('multiPharmacy') === "true", list)
                        }
                        return null
                    })}

                    {LabList?.map((list) => {
                        if (this.isModuleSelected(list?.laboratory_id, "laboratory_id")) {
                            return this.LaboratoryCharts(localGetItem('multiLaboratory') === "true", list)
                        }
                        return null
                    })}

                    {this.isModuleSelected("RADIOLOGY") ? this.RadiologyCharts() : null}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <ChartDashboardFilter FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} />
                </Drawer>
            </Box>
        )
    }
}
export default withTranslation()(ChartDashboardHome);