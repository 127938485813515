import React, { Component } from 'react';
import {
    Box, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Stack, Typography, Autocomplete, Grid, Tooltip, IconButton, Checkbox, InputAdornment,
    Dialog,
    DialogContent,
    DialogActions
} from "@mui/material";
import "./PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { ImagePaths } from '../../../Utility/ImagePaths';
import PharmaDirectPoNew from "./PharmaDirectPoNew";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CommonEditButton, CommonDeleteButton, CommonPopUp, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import AddIcon from '@mui/icons-material/Add';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { CurrencySymbol } from '../../../Utility/Constants';
import WarningIcon from '@mui/icons-material/Warning';

let userAccess = null;

class PharmaDirectPo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            billNo: "",
            billDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            purchase_order_date: null,
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            purchase_from: "directPo",
            previous_vendor_bills: [],
            bill_id: null,
            open: false,
            open1: false,
            isselectedLineItem: false,
            isNewBatch: false,
            editWithin: -1,
            showSaveBtn: true,
            EditIndex: -1,
            tabValue: "",
            stockData: {},
            stockFrom: this.props.from ? this.props.from : "",
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isSaveClicked: false,
            from_page: "",
            is_vendor_dc: false,
            PropsFromDC: {},
            dc_popup_data: [],
            is_dc_popup: false,
            clearEditData: false,
            isFinished: false,
            callPost: false,
            fromWanted: false,
            isUpdateCall: false,
            saveLoad: false,
            paisa: '',
            roundofAmt: '',
            type_Paisa: true,
            fromDcPopup: false,
            grn_no: "-",
            dup_purchase_order_number: '',
            taxableAmt: "0.00",
            genGrn: false,
            openPopup: false,
            selectedRemoveIndex: null,
            dupLftData: '[]',
            fromWarnPopup: false,
            vendor_bill_id: '',
            fromGrnLine: false,
            clickFinish: false,
            tcsAmt: '',
            isEditAccessGRN : false,
            fromDC: this.props.fromDC ? this.props.fromDC : false
        }
    }

    componentDidMount() {
          let  RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        if(RoleData.is_user){
           let permissionAccess = RoleData?.permission_access?.pharma 
           let grantAccess = permissionAccess?.isGRNEntry ? false : true
           this.setState({
              isEditAccessGRN : grantAccess
           })
        }else{
            this.setState({
                isEditAccessGRN : false
             })
        }
        userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Purchase Order', null, "Tab");
        this._getVendorList();
    }

    componentDidUpdate(prevProps) {
        let states = this.state;
        let history = prevProps.history?.location?.state;
        let purchase_order_id = history?.purchase_order_id;
        let isUser = !userAccess?.editAccess;

        if (purchase_order_id !== undefined && purchase_order_id !== states.purchase_order_id) {
            this.setState({
                purchase_order_id: purchase_order_id ? purchase_order_id : null,
                purchase_status: history?.purchase_status ? history?.purchase_status : "",
                purchase_from: history?.from ? history?.from : "",
                showSaveBtn: (history?.purchase_status === "Completely Delivered" || history?.purchase_status === 'Completed') && isUser ? false : this.state.showSaveBtn,
                vendor_id: history?.purchaseDetail?.vendor_id ? history?.purchaseDetail?.vendor_id : '',
                vendor_bill_id: history?.vendor_bill_id ? history?.vendor_bill_id : '',
                fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            }, () => {
                this._getPurchaseDetails();
                if (!history?.purchaseDetail?.is_finished) {
                    this.getVendorDClist()
                }
            })
        } else if (this.props.stockData !== undefined && Object.keys(this.props.stockData)?.length > 0 && this.props.stockData !== this.state.stockData) {
            this.setState({
                stockData: this.props.stockData,
                showSaveBtn: false,
                fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            }, () => {
                this.assignStockDetails()
            })
        } else if (prevProps.from !== this.props.from && this.props.from) {
            this.setState({
                stockFrom: this.props.from,
                showSaveBtn: false,
                fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            })
        }

        if (history?.propsFromDc?.dc_number !== this.state.PropsFromDC?.dc_number) {
            let LineItems = []
            if (history?.propsFromDc?.line_items?.length > 0) {
                history?.propsFromDc?.line_items?.forEach((list, index) => {
                    list.serial_no = index + 1
                    LineItems.push(list)
                })
            }
            this.setState({
                PropsFromDC: history?.propsFromDc,
                vendor_name: history?.propsFromDc?.vendor_name,
                vendor_id: history?.propsFromDc?.vendor_id,
                cgstAmt: (history?.propsFromDc?.cgstAmt) ? (history?.propsFromDc?.cgstAmt).toFixed(2) : 0,
                sgstAmt: (history?.propsFromDc?.sgstAmt) ? (history?.propsFromDc?.sgstAmt).toFixed(2) : 0,
                billAmt: (history?.propsFromDc?.billAmt) ? (history?.propsFromDc?.billAmt).toFixed(2) : 0,
                lftTblData: LineItems,
                roundofAmt: (history?.propsFromDc?.billAmt) ? (history?.propsFromDc?.billAmt).toFixed(2) : 0,
                taxableAmt: history?.propsFromDc?.billAmt ? (history?.propsFromDc?.billAmt - (history?.propsFromDc?.cgstAmt * 2))?.toFixed(2) : 0,
                fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            })
        }

        if (history?.from_page != states.from_page && history?.from_page) {
            this.setState({
                from_page: (history?.from_page) ? (history?.from_page) : ""
            })
        }
    }

    assignStockDetails = () => {
        let { stockData, lftTblData } = this.state;
        if (stockData !== undefined && Object.keys(stockData)?.length > 0) {
            stockData["index"] = 1;
            lftTblData.push(stockData);

            this.setState({
                lftTblData,
                vendor_name: stockData.vendor_name,
                delivery_date: stockData.delivery_date,
                purchase_order_date: new Date(stockData.purchase_order_date),
                purchase_order_id: stockData.purchase_order_id,
                purchase_order_number: stockData.purchase_order_number,
                vendor_id: stockData.vendor_id,
                billDate: stockData.bill_date,
                cgstAmt: stockData.cgst_amount ? stockData.cgst_amount : "0.00",
                sgstAmt: stockData.sgst_amount ? stockData.sgst_amount : "0.00",
                grn_no: stockData.grn_no ? stockData.grn_no : '-',
            })
        }
    }

    getPurchaseRequestPrint = () => {
        try {
            let states = this.state
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.PHARMA_PO_REQUEST_PRINT + `?id=${states.purchase_order_id}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            saveLoad: false
        })
    }

    WarningMessage = (message) => {
        this.setState({
            isWarnMsg: true,
            isWarnMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isWarnMsg: false,
            isWarnMsgText: ""
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    IsClearEdit = () => {
        this.setState({
            EditIndex: -1,
            clearEditData: false
        })
    }

    _getVendorList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            vendor_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    _getPurchaseDetails = () => {
        try {
            this.LoaderFunction(true);
            let params = '';
            if (this.state.fromGrnLine) {
                params = `?purchase_order_id=${this.state.purchase_order_id}&vendor_bill_id=${this.state.vendor_bill_id}`
            } else {
                params = `?purchase_order_id=${this.state.purchase_order_id}`
            }
            RestAPIService.getAll(Serviceurls.PURCHASE_PO_DETAILS_GET + params)
                .then(response => {
                    if (response.data?.status == "success") {
                        this.LoaderFunction(false);
                        this.assignPoDetails(response.data?.data);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                        setTimeout(()=>{ this.props.history.push({ pathname: "/PharmacyPurchase/PurchaseOrder"}) }, 2000)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
            setTimeout(()=>{ this.props.history.push({ pathname: "/PharmacyPurchase/PurchaseOrder"}) }, 2000)

        }
    }

    getNumberOfDaysBetweenDates(date1, date2) {
        const Date1 = new Date(date1);
        const Date2 = new Date(date2);
        const timeDiffMilliseconds = Math.abs(Date2 - Date1);
        const days = Math.ceil(timeDiffMilliseconds / (24 * 60 * 60 * 1000));
        return days;
    }

    assignPoDetails = (data) => {
        let states = this.state;

        let latest_vendor_bill = Object.keys(data.latest_vendor_bill).length > 0 ? data.latest_vendor_bill : {};
        let previous_vendor_bills = data.previous_vendor_bills?.length > 0 ? data.previous_vendor_bills : [];
        let editWithin = this.getNumberOfDaysBetweenDates(latest_vendor_bill?.delivery_date, new Date());
        let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;

        if (states.purchase_status === "Completely Delivered" || states.purchase_status === 'Completed') {
            let is_allStock_sold = !(data?.stock_items?.every(item => item.is_stocks_sold === true));
            states.grn_no = data.grn_no ? data.grn_no : '-'
            states.lftTblData = [...data.stock_items];
            states.editWithin = editWithin;
            states.showSaveBtn = (is_allStock_sold && editWithin <= 30 && states.purchase_from !== "directPo" && states.stockFrom === "" && userAccess?.editAccess)

        } else if (states.purchase_status === "Placed Order" || states.purchase_status === "Partially Delivered" || states.purchase_status === 'Saved') {

            let partialArr = (data.po_line_items).length > 0 || (data.stock_items).length > 0 ? [...data.po_line_items, ...data.stock_items] : [];
            states.lftTblData = partialArr.sort((a, b) => a.serial_no - b.serial_no);
            states.showSaveBtn = userAccess?.editAccess;
            states.grn_no = data.grn_no ? data.grn_no : '-'
        }

        let Paisa = `${latest_vendor_bill?.paise_amount}`.split('.')
        let paisaVal = Paisa[1]
        states.grn_no = data.grn_no ? data.grn_no : '-'
        states.billDate = this.state.fromGrnLine ? latest_vendor_bill?.bill_date : DateTime.now().toFormat('yyyy-MM-dd');
        states.delivery_date = this.state.fromGrnLine ? latest_vendor_bill?.delivery_date : DateTime.now().toFormat('yyyy-MM-dd');
        states.billNo = this.state.fromGrnLine ? latest_vendor_bill?.bill_no : '';
        states.billAmt = this.state.fromGrnLine ? +latest_vendor_bill?.bill_amount?.toFixed(2) : 0;
        states.roundofAmt = this.state.fromGrnLine ? +latest_vendor_bill?.total_amount?.toFixed(2) : 0;
        states.cgstAmt = this.state.fromGrnLine ? latest_vendor_bill?.cgst_amount : 0;
        states.sgstAmt = this.state.fromGrnLine ? latest_vendor_bill?.sgst_amount : 0;
        states.bill_id = this.state.fromGrnLine ? latest_vendor_bill?.bill_id : '';
        states.paisa = this.state.fromGrnLine ? +paisaVal : ''
        states.purchase_order_date = new Date(data.purchase_order_date);
        states.purchase_order_id = data.purchase_order_id;
        states.purchase_order_number = data.purchase_order_number;
        states.vendor_id = data.vendor_id;
        states.vendor_name = data.vendor_name;
        states.previous_vendor_bills = previous_vendor_bills;
        states.isFinished = data?.latest_vendor_bill?.is_finished
        states.callPost = data?.call_post_api
        states.taxableAmt = this.state.fromGrnLine ? (latest_vendor_bill?.bill_amount ? (((latest_vendor_bill?.bill_amount - (latest_vendor_bill?.cgst_amount * 2)) - (+latest_vendor_bill?.tcs_amount)))?.toFixed(2) : 0) : 0;
        states.tcsAmt = this.state.fromGrnLine ? latest_vendor_bill?.tcs_amount : ''
        this.setState({
            states
        }, () => {
            states.lftTblData?.forEach(element => element.gen_grn = true)
            this.setState({ states, dupLftData: JSON.stringify(states.lftTblData) })
        })
    }

    getPoNumber = () => {
        try {
            this.setState({ saveLoad: true })
            RestAPIService.getAll(`${Serviceurls.PURCHASE_PO_NUMBER_GET}`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            purchase_order_number: response.data?.data?.po_number,
                            saveLoad: false
                        }, () => {
                            this.AddToStock();
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.status === "fail") {
                        this.errorMessage(error.response?.data?.message);
                        this.setState({
                            isSaveClicked: false,
                            saveLoad: false
                        })
                    }
                })
        } catch (e) {
            this.setState({ saveLoad: false })
            this.errorMessage(e.message);
        }
    }

    validateVendor = () => {
        const { t } = this.props;
        let states = this.state;
        let msg = "";
        if (!states.vendor_id) {
            msg = t("EnterVendor")
        } else if (!states.delivery_date) {
            msg = t("Enterdeliverydate")
        } else if (!states.billNo) {
            msg = t("Enterpurchasebillnumber")
        } else if (!states.billDate) {
            msg = t("Enterpurchasebilldate")
        } else if (!states.billAmt && states.billAmt != 0) {
            msg = t("Enterpurchasebillamount")
        } else if (states.lftTblData.length === 0) {
            msg = t("EnterDrugDetails")
        } else if (states.delivery_date < states.billDate) {
            msg = "Delivery Date Should Be Greater than Bill Date"
        }
        return msg
    }

    handleClearData = () => {
        let todate = new Date();
        this.setState({
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            billNo: "",
            billDate: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            purchase_order_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            purchase_from: "directPo",
            previous_vendor_bills: [],
            latest_vendor_bill: {},
            open: false,
            open1: false,
            isselectedLineItem: false,
            EditIndex: -1,
            roundofAmt: '',
            paisa: '',
            type_Paisa: true,
            taxableAmt: '0.00'
        })
    }

    printDirectpo = () => {
        let states = this.state;
        try {
            this.setState({ disableBtn: true })
            RestAPIService.getAll(`${Serviceurls.PURCHASE_DIRECT_PO_POST}?purchase_order_number=${states.purchase_order_number ? states.purchase_order_number : 0}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    getLastDateOfMonth(dateString) {
        try {
            // Parse the input date string
            const date = new Date(dateString);

            // Get the year and month from the input date
            const year = date.getFullYear();
            const month = date.getMonth();

            // Create a new date object set to the first day of the next month
            const nextMonth = new Date(year, month + 1, 1);

            // Subtract one day to get the last day of the current month
            const lastDateOfMonth = new Date(nextMonth - 1);

            // Format the date as 'YYYY-MM-DD'
            const formattedDate = lastDateOfMonth.toISOString().split('T')[0];

            return formattedDate;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AddToStock = () => {
        let states = this.state;
        let isValid = this.validateVendor();

        if (isValid === "") {

            let datas = {};
            let url = "";
            let method = "";
            let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;
            let tcs_amount = states.tcsAmt ? +states.tcsAmt : 0
            if (states.purchase_from === "purchaseOrder") {
                url = Serviceurls.PURCHASE_ADD_TO_STK_POST;
                this.state.isUpdateCall && this.state.callPost ? method = "create" : ((states.purchase_status === "Completely Delivered" || states.purchase_status === "Partially Delivered" || this.state.purchase_status === "Saved" || this.state.purchase_status === "Completed") && userAccess?.editAccess) ? method = "updateWithOutId" : method = "create";
                datas = {
                    purchase_order_date: states.purchase_order_date ? DateTime.fromJSDate(states.purchase_order_date).toFormat("yyyy-MM-dd") : DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
                    purchase_order_number: states.purchase_order_number,
                    stock_items: [],
                    vendor_bill_details: {
                        bill_date: states.billDate,
                        bill_no: states.billNo,
                        cgst_amount: states.cgstAmt ? +states.cgstAmt : 0.00,
                        delivery_date: states.delivery_date,
                        sgst_amount: states.sgstAmt ? +states.sgstAmt : 0.00,
                        total_amount: +states.roundofAmt ? (+states.roundofAmt) : (+states.billAmt),
                        vendor_id: states.vendor_id,
                        bill_amount: +states.billAmt ? (+states.billAmt) : 0,
                        paise_amount: states.paisa ? (states.paisa / 100) : 0,
                        is_finished: this.state.isSaved ? false : true,
                        // taxableAmt: (+states.billAmt - (states.cgstAmt * 2))?.toFixed(2),
                        tcs_amount: states.tcsAmt ? +states.tcsAmt : 0,
                        // tcs_bill_amount: +states.roundofAmt ? (+states.roundofAmt) : (+states.billAmt)
                    },
                }
                if (this.state.purchase_status === "Completely Delivered" || this.state.purchase_status === "Partially Delivered" || this.state.purchase_status === 'Saved' || this.state.purchase_status === 'Completed') {
                    datas["vendor_bill_details"]["bill_id"] = states.bill_id ? +states.bill_id : 0
                    datas['vendor_bill_details']['is_finished'] = this.state.isSaved ? false : true
                }
            } else if (states.from_page === "DC") {
                url = Serviceurls.PURCHASE_DC_POST;
                method = "create";
                datas = {
                    dc_number: states.billNo,
                    dc_date: states.delivery_date,
                    stock_items: [],
                    vendor_id: states.vendor_id
                }
            } else {
                //direct po
                url = Serviceurls.PURCHASE_DIRECT_PO_POST;
                method = "create";
                datas = {
                    bill_date: states.billDate,
                    bill_no: states.billNo,
                    cgst_amount: states.cgstAmt ? +states.cgstAmt : 0.00,
                    delivery_date: states.delivery_date,
                    purchase_order_date: states.purchase_order_date ? states.purchase_order_date : DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
                    purchase_order_number: states.purchase_order_number,
                    sgst_amount: states.sgstAmt ? +states.sgstAmt : 0.00,
                    stock_items: [],
                    total_amount: +states.roundofAmt ? (+states.roundofAmt) : (+states.billAmt),
                    vendor_id: states.vendor_id,
                    is_finished: this.state.isSaved ? false : true,
                    bill_amount: +states.billAmt ? (+states.billAmt) : 0,
                    paise_amount: states.paisa ? (states.paisa / 100) : 0,
                    // taxableAmt: (+states.billAmt - (states.cgstAmt * 2))?.toFixed(2),
                    tcs_amount: states.tcsAmt ? +states.tcsAmt : 0,
                    // tcs_bill_amount: +states.roundofAmt ? (+states.roundofAmt) : (+states.billAmt)
                }
            }
            let filteredList = states.lftTblData?.filter(item => {
                let filterList = ("newBatch" in item && item["newBatch"] ? item.newBatch : "status" in item && (item?.status === "Not Delivered" || item?.status === "") && item?.status !== "Partially Delivered")
                    && (item.batch_no || item.free_batch_no) && states.purchase_from === "purchaseOrder"
                return filterList;
            });
            // Completely Delivered Edit
            let deliveryEditList = states.lftTblData?.filter(item => item.is_stocks_returned === false && item.is_stocks_sold === false && item?.is_updated && item?.stock_id);
            // let list = filteredList.length > 0 ? filteredList : deliveryEditList.length > 0 ? deliveryEditList : states.lftTblData;
            let newFilteredData = [];
            deliveryEditList?.forEach(element => {
                newFilteredData?.push(element)
            })
            let finalFilterData = newFilteredData?.concat(filteredList)
            let list = []

            // if (this.state.callPost) {
            //     let wantedNewDrug = []

            //     if (filteredList?.length === 0) {
            //         states.lftTblData?.forEach(element => {
            //             if (!element?.stock_id) {
            //                 wantedNewDrug?.push(element)
            //             }
            //         })
            //     }
            //     list = filteredList?.length > 0 ? filteredList : states.lftTblData
            // } else {
            // }
            let listData = []; let flag = false;
            states.lftTblData?.forEach(element => {
                if (element?.batch_no || element.free_batch_no) {
                    listData?.push(element)
                } else if (element?.gen_grn && (!element.batch_no || !element.free_batch_no)) {
                    flag = true
                }
            })
            if (listData?.length > 0) {
                list = listData
            } else {
                list = states.lftTblData?.length > 0 ? states.lftTblData : deliveryEditList?.length > 0 ? deliveryEditList : finalFilterData;
            }

            list?.forEach((item, i) => {
                let tempdata = {
                    bar_code: item.bar_code ? item.bar_code : "",
                    batch_no: item.tabValue === "two" ? CommonValidation.removeSpace(item.free_batch_no) : item.batch_no ? CommonValidation.removeSpace(item.batch_no) : "",
                    buying_price: +item.buying_price,
                    cgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.cgst_amount ? item.cgst_amount : 0,
                    cgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.cgst_percentage ? item.cgst_percentage : 0,
                    discount_amount: item.discount_amount ? +item.discount_amount : 0,
                    discount_percentage: item.discount_percentage ? +item.discount_percentage : 0,
                    drug_ms_id: item.drug_id ? +item.drug_id : +item.drug_ms_id,
                    epr_value: +item.epr_value,
                    expiry_alert_period: item.expiry_alert_period ? +item.expiry_alert_period : 3,
                    expiry_date: item.tabValue === "two" ? this.getLastDateOfMonth(item.free_expiry_date) : this.getLastDateOfMonth(item.expiry_date),
                    free_quantity: item.tabValue === "two" ? (+item.free_no_of_unit * + item.free_qty_per_unit) : item.free_quantity ? (+item.free_quantity * +item.pack_of_drugs) : 0,
                    group: item.group ? item.group : "",
                    hsn_code: item.hsn_code ? item.hsn_code : "",
                    instructions: item.description ? item.description : item.instructions ? item.instructions : "",
                    manufacturer_name: item.manufacturer_name ? item.manufacturer_name : "",
                    mrp_per_pack: item.tabValue === "two" ? +item.free_mrp_unit : item.mrp_per_pack ? +item.mrp_per_pack : 0,
                    mrp_per_quantity: item.mrp_per_quantity ? +item.mrp_per_quantity : item?.is_free_quantity ? +(+item.free_mrp_unit / +item.free_qty_per_unit)?.toFixed(2) : 0,
                    pack_of_drugs: item.tabValue === "two" ? +item.free_qty_per_unit : item.pack_of_drugs ? +item.pack_of_drugs : 0,
                    physical_box_no: item.physical_box_no ? item.physical_box_no : "",
                    recommended_by: item.recommended_by ? item.recommended_by : "",
                    reorder_threshold: item.reorder_threshold ? +item.reorder_threshold : 0,
                    sales_discount: item.sales_discount ? +item.sales_discount : 0,
                    sales_discount_amount: item.sales_discount_amount ? +item.sales_discount_amount : 0,
                    sales_unit: item.tabValue === "two" ? +item.free_no_of_unit : +item.sales_unit,
                    schedule: item.schedule ? item.schedule : "",
                    serial_no: item.tabValue === "two" ? (item.serial_no ? item.serial_no : i + 1) : item.serial_no,
                    sgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.sgst_amount ? item.sgst_amount : 0,
                    sgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.sgst_percentage ? item.sgst_percentage : 0,
                    stock_in_quantity: item.stock_in_quantity ? +item.stock_in_quantity : 0,
                    total_buying_price: item.total_buying_price ? +item.total_buying_price : (item.buying_price && item?.sales_unit) ? (+item.buying_price * +item?.sales_unit) : 0,
                    sales_discount_for_bill: item.tabValue === "two" ? +item.free_sales_discount_bills : item.sales_discount_for_bill ? +item.sales_discount_for_bill : 0,
                    is_free_quantity: item.tabValue === "two" || item?.is_free_quantity ? true : false,
                    po_line_item_id: item.po_line_item_id ? item.po_line_item_id : null
                }
                let fromDirectPO = this.props.history?.location?.state?.fromDirectPO
                if (states.from_page !== 'DC') {
                    tempdata['add_new_item'] = item?.fromDcPopup ? true : item?.stock_id ? false : true
                    tempdata['is_updated'] = item?.is_updated ? item?.is_updated : false
                    tempdata['from_dc'] = item?.fromDcPopup || item?.from_dc ? true : false
                    tempdata['gen_grn'] = item?.gen_grn ? item?.gen_grn : item?.grn_number ? true : (this.state.isSaved && this.state.genGrn || !this.state.isSaved) ? true : false
                    if (item?.grn_number !== null) {
                        tempdata['grn_number'] = item?.grn_number
                    }
                    tempdata['add_new_bill'] = !item?.grn_number && !this.state.fromGrnLine ? true : !item?.stock_id && this.state.fromGrnLine ? false : !item?.stock_id ? true : false
                }
                if (states.purchase_from !== "purchaseOrder" && states.from_page !== "DC") {
                    tempdata["from_dc"] = item?.item_from_dc ? true : false
                }
                if (states.purchase_from === "purchaseOrder") {
                    tempdata["drug_id"] = item.drug_id ? +item.drug_id : +item.drug_ms_id;
                    if (item.stock_id) {
                        tempdata["stock_id"] = +item.stock_id;
                    }
                }
                if (item?.item_from_dc) {
                    tempdata["stock_id"] = +item.stock_id
                }
                datas["stock_items"].push(tempdata)
            })
            let genGrnIds = [];
            states.lftTblData?.forEach(element => {
                if (element.stock_id && element?.gen_grn && !element?.grn_number) {
                    genGrnIds?.push(element.stock_id)
                }
            })
            datas['gen_grn_stock_ids'] = genGrnIds
            // let checkFree = datas['stock_items']?.every(item => !item?.stock_id && !item.grn_number && !this.state.fromGrnLine && item?.total_buying_price === 0)
            try {
                if (flag) {
                    this.errorMessage('Please Enter the details for checked items')
                    this.setState({ saveLoad: false })
                } else if (states.billAmt === 0) {
                    this.errorMessage('Bill Amount Should not be zero')
                    this.setState({ saveLoad: false })
                } else {
                    this.LoaderFunction(true);
                    this.setState({ saveLoad: true, clickFinish: true })
                    RestAPIService[method](datas, url)
                        .then(response => {
                            if (response.data.status === "success") {
                                this.LoaderFunction(false);
                                this.handleClearData();
                                this.setState({
                                    isSaveClicked: false,
                                    fromWanted: false,
                                    isUpdateCall: false,
                                    saveLoad: false,
                                    fromDcPopup: false,
                                    genGrn: false,
                                    fromWarnPopup: false,
                                    clickFinish: false
                                })
                                this.props.history.push({
                                    pathname: states.from_page === "DC" ? "/PharmacyPurchase/DeliveryChallan" : "/PharmacyPurchase/PurchaseOrder", state: { DirectPoSuccessMsg: response.data.message }
                                })
                            }
                        })
                        .catch(error => {
                            if (error?.response?.data?.status === "fail") {
                                this.setState({
                                    isSaveClicked: false,
                                    saveLoad: false,
                                    genGrn: false,
                                    fromWarnPopup: false,
                                    clickFinish: false
                                })
                                this.LoaderFunction(false);
                                this.errorMessage(error.response.data.message);
                            }
                        });
                }
            }
            catch (e) {
                this.errorMessage(e.message);
                this.setState({
                    isSaveClicked: false,
                    saveLoad: false,
                    genGrn: false,
                    fromWarnPopup: false,
                    clickFinish: false
                })
            }
        } else {
            this.setState({
                isSaveClicked: false,
                saveLoad: false,
                genGrn: false,
                fromWarnPopup: false,
                clickFinish: false
            })
            this.errorMessage(isValid)
        }
    }

    AddToData = (list, isSelected, tabValue) => {
        const { t } = this.props;
        let states = this.state;
        if (list) {
            let alreadyExist = null;

            states?.lftTblData?.forEach(element => {
                if (element.drug_id === list.drug_id) {
                    if ((!list?.is_free_quantity && (element?.batch_no === list?.batch_no || element?.free_batch_no === list?.batch_no)) || (list?.is_free_quantity && (element?.free_batch_no === list?.free_batch_no || element?.batch_no === list?.free_batch_no))) {
                        if (element?.serial_no !== list?.serial_no) {
                            alreadyExist = element
                        }
                    }
                }
            })
            // let alreadyExist = states.lftTblData?.find(item => item.drug_id === list.drug_id && (!list?.is_free_quantity && (item?.batch_no === list?.batch_no || item?.free_batch_no === list?.batch_no)) || (list?.is_free_quantity && (item?.free_batch_no === list?.free_batch_no || item?.batch_no === list?.free_batch_no)) && item.serial_no !== list.serial_no);

            if (states.EditIndex !== -1 && (list.edit_index === states.EditIndex) && alreadyExist === null) {
                states.lftTblData[states.EditIndex - 1] = list;
            } else if (states.isNewBatch) {
                delete list.stock_id;
                list["newBatch"] = true;
                states.lftTblData.push(list);
            } else if ((states.EditIndex === -1 && alreadyExist) || alreadyExist) {
                this.errorMessage(`${alreadyExist?.brand_name} Same Batch Number ${t("AlreadyExist")}`)
            } else {
                list["tabValue"] = tabValue;
                states.lftTblData.push(list);
            }
            this.setState({
                states,
                isselectedLineItem: isSelected,
                isNewBatch: isSelected,
                EditIndex: -1,
                selectedData: {}
            }, () => {
                this.calculateBillAmount()
                this.ChecknewDrugSalesDiscGst(list)
                // let { lftTblData } = this.state;
                // let initialObj = {
                //     total_buying_price: 0,
                //     total_gst_amount: 0
                // }
                // let totalAmount = lftTblData?.reduce((acc, cur, i) => {
                //     let gst_amount = cur.total_gst_amount ? cur.total_gst_amount : (2 * cur.cgst_amount);
                //     "serial_no" in cur ? cur["serial_no"] = cur["serial_no"] : cur["serial_no"] = i + 1;
                //     acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
                //     acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(gst_amount ? gst_amount : 0);
                //     return acc;
                // }, initialObj)

                // let newTotalAmt = 0; let newGST = 0;
                // lftTblData?.forEach(element => {
                //     if (!element?.stock_id || (element.stock_id && element.addDCtoPO)) {
                //         newTotalAmt = newTotalAmt + (element?.total_buying_price ? element?.total_buying_price : 0)
                //         newGST = newGST + (element?.total_gst_amount ? element?.total_gst_amount : 0)
                //     }
                // })
                // if (this.state.fromGrnLine) {
                //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+totalAmount.total_buying_price + (this.state.paisa / 100)) :
                //         (+totalAmount.total_buying_price - (this.state.paisa / 100)) : +totalAmount.total_buying_price
                //     this.setState({
                //         lftTblData,
                //         cgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                //         sgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                //         billAmt: totalAmount.total_buying_price ? totalAmount.total_buying_price.toFixed(2) : 0,
                //         roundofAmt: +Bill_Amt.toFixed(2),
                //         taxableAmt: totalAmount.total_buying_price ? (+totalAmount.total_buying_price - +totalAmount.total_gst_amount)?.toFixed(2) : 0
                //     })
                // } else {
                //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+newTotalAmt + (this.state.paisa / 100)) :
                //         (+newTotalAmt - (this.state.paisa / 100)) : +newTotalAmt
                //     this.setState({
                //         lftTblData,
                //         cgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                //         sgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                //         billAmt: newTotalAmt ? newTotalAmt?.toFixed(2) : 0,
                //         roundofAmt: +Bill_Amt.toFixed(2),
                //         taxableAmt: newTotalAmt ? (+newTotalAmt - +newGST)?.toFixed(2) : 0
                //     })
                // }
                // this.ChecknewDrugSalesDiscGst()
            })
        }
    }

    _calculateTransaction(data, index) {
        let Tabvalue = 'one'
        let pack_of_drugs = parseInt(data.pack_of_drugs) || 0;
        let sales_unit = parseInt(data.sales_unit) || 0;
        let buying_price = Tabvalue === "two" ? 0 : parseFloat(data.buying_price) || 0;
        let free_quantity = +data.free_quantity || 0;
        let discount_percentage = Tabvalue === "two" ? 0 : parseFloat(data.discount_percentage) || 0;
        // let discount_amount = parseFloat(data.discount_amount) || 0;
        let discount_amount = (data.discount_amount) || 0;
        let seller_disc_percent = Tabvalue === "two" ? 0 : parseFloat(data.sales_discount) || 0;
        let seller_disc_amount = (data.sales_discount_amount) || 0;
        let mrp_per_pack = parseFloat(data.mrp_per_pack) || 0;
        let gst_percentage = Tabvalue === "two" ? 0 : parseFloat(data.gst_percentage) || 0;

        //Free quantity data
        let free_qty_per_unit = parseInt(data.free_qty_per_unit) || 0;
        let free_mrp_unit = parseFloat(data.free_mrp_unit) || 0;

        // calc quantity
        let stock_in_quantity = Tabvalue === "two" ? 0 : pack_of_drugs * sales_unit;
        data.total_quantity = Tabvalue === "two" ? 0 :((pack_of_drugs * sales_unit) + (pack_of_drugs * free_quantity) );

        let total_buying_price = parseFloat((sales_unit * buying_price).toFixed(2));
        let mrp_per_quantity = pack_of_drugs ? (mrp_per_pack / pack_of_drugs).toFixed(2) : 0;
        let free_mrp_per_quantity = free_qty_per_unit ? (free_mrp_unit / free_qty_per_unit).toFixed(2) : 0;

        // normal discount
        // if (is_discount_amount_changed) {
            // set discount percentage
        //     data.discount_percentage = total_buying_price && discount_amount > 0 ? ((100 * discount_amount) / total_buying_price).toFixed(2) : 0;
        // } else {
            discount_amount = total_buying_price > 0 && discount_percentage > 0 ? ((total_buying_price * discount_percentage) / 100).toFixed(2) : 0;
        // }

        // total buying price after discount deduction
        let total_buying_price_after_discount = total_buying_price - parseFloat(discount_amount);

        // if (is_sales_amount_changed) {
            // set sales discount percent
        //     data.sales_discount = total_buying_price_after_discount && seller_disc_amount > 0 ? ((100 * seller_disc_amount) / total_buying_price_after_discount).toFixed(2) : 0;
        // } else {
            // set sales discount amount
            seller_disc_amount = buying_price && seller_disc_percent > 0 ? ((total_buying_price_after_discount * seller_disc_percent) / 100).toFixed(2) : 0;
        // }

        // set data
        data.sales_discount_amount = seller_disc_amount;
        data.discount_amount = discount_amount;
        data.stock_in_quantity = +stock_in_quantity;
        data.mrp_per_quantity = Tabvalue === "two" ? +free_mrp_per_quantity : +mrp_per_quantity;

        //total buying price calculation if gst not mentioned
        data.total_buying_price = +(parseFloat(total_buying_price.toFixed(2)) - parseFloat(discount_amount) - parseFloat(seller_disc_amount)).toFixed(2);

        //if gst percentage added
        if (gst_percentage === 0) {
            data.total_gst_amount = 0;
        } else {
            let taxable_amount = parseFloat(total_buying_price) - parseFloat(discount_amount) - parseFloat(seller_disc_amount);
            let gst_amount = ((taxable_amount * gst_percentage) / 100).toFixed(2)
            data.total_gst_amount = +gst_amount;

            // add GST to final buying price
            data.total_buying_price = +(parseFloat(data.total_buying_price) + parseFloat(gst_amount)).toFixed(2)

        }
        delete data.is_new_saldisc_gst_percent
        let DrugData = JSON.parse(JSON.stringify(this.state.lftTblData))
        DrugData[index] = data
        this.setState({
            lftTblData : DrugData
        })
    }

    ChecknewDrugSalesDiscGst = (DrugData) => {
        try {
            // updating sales discount, gst percent for not given durgs
            let LeftData = JSON.parse(JSON.stringify(this.state.lftTblData))
            let isUpdated = false
            let isUpdatedGST = false
            let NoDiscDrugsListWithoutDisc = this.state.lftTblData.some((item) => (DrugData?.drug_id === item?.drug_id && item.batch_no && !item.stock_id && !item.po_line_item_id && !item.is_default_salesDiscount && this.state.lftTblData?.length > 1))
            if (NoDiscDrugsListWithoutDisc) {
                LeftData?.forEach((data, index) => {
                    if (DrugData?.drug_id === data?.drug_id && data?.batch_no && !data.po_line_item_id && !data.stock_id && !data.is_default_salesDiscount && LeftData[index].sales_discount_for_bill != DrugData?.sales_discount_for_bill) {
                        LeftData[index].sales_discount_for_bill = DrugData?.sales_discount_for_bill
                        LeftData[index].is_new_saldisc_gst_percent = true
                        isUpdated = true
                    }
                })
            }
            let NoGSTDrugsList = this.state.lftTblData.some((item) => (DrugData?.drug_id === item?.drug_id && item.batch_no && !item.stock_id && !item.po_line_item_id && !item.is_default_gstpercent && this.state.lftTblData?.length > 1))
            if (NoGSTDrugsList) {
                LeftData?.forEach((data, index) => {
                    if (DrugData?.drug_id === data?.drug_id && data?.batch_no && !data.po_line_item_id && !data.stock_id && !data.is_default_gstpercent && LeftData[index].gst_percentage != DrugData?.gst_percentage) {
                        LeftData[index].gst_percentage = DrugData?.gst_percentage
                        LeftData[index].is_new_saldisc_gst_percent = true
                        isUpdatedGST = true
                    }
                })
            }
            
            if (isUpdated || isUpdatedGST) {
                this.setState({
                    lftTblData: LeftData
                }, () => {
                    LeftData?.forEach((item, index) => {
                        if (item?.is_new_saldisc_gst_percent) {
                            this._calculateTransaction(item, index)
                        }
                    })
                    this.WarningMessage(`${isUpdated ? `Sales discount for drug - ${DrugData?.brand_name} is updated to ${DrugData?.sales_discount_for_bill ? DrugData?.sales_discount_for_bill : 0} %.` : ""} ${isUpdatedGST ? `GST percentage for drug - ${DrugData?.brand_name} is updated to ${DrugData?.gst_percentage ? DrugData?.gst_percentage : 0} %` : ""}`)
                })
            }
        } catch (e) {

        }
    }

    handleOpen = (statekey) => {
        let states = this.state;
        states[statekey] = true;
        this.setState({
            states
        })
    }

    getVendorDClist = (vendorID) => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PURCHASE_DC_POST + "?detailed_view=true" + "&dc_number=" + "&vendor_id=" + this.state.vendor_id + "&from_direct_po=true").
                then((response) => {
                    if (response.data.status === "success") {
                        var Data = (response?.data?.data) ? (response?.data?.data).filter((list) => list?.moved_to_po === false) : []
                        this.LoaderFunction(false);
                        Data?.forEach(element => {
                            element.fromDcPopup = true
                            element.gen_grn = true
                            element.addDCtoPO = true
                        })
                        this.setState({
                            is_vendor_dc: Data?.length > 0 ? true : false,
                            dc_popup_data: Data ? Data : []
                        })
                    }
                    else {
                        this.LoaderFunction(false);
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false);
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderAutoSlt = (label, stateKey1, stateKey2, showkey, valueKey, Data = [], width) => {
        let states = this.state;
        let LastVendor = states.vendor_id
        const { t } = this.props;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <Autocomplete
                    disabled={stateKey1 === "vendor_name" ? this.state?.PropsFromDC?.vendor_id : false}
                    size='small'
                    clearIcon={false}
                    readOnly={states.purchase_status !== "" || states.stockFrom !== "" ? true : false}
                    sx={{ width: width ? width : '30.80vw' }}
                    options={Data}
                    getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )
                    }}
                    value={states[stateKey1] ? states[stateKey1] : ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            states[stateKey1] = newValue[showkey] // value to be shown
                            states[stateKey2] = newValue[valueKey] // actual value
                            this.setState({ states }, () => {
                                if (label === "VendorName" && states.purchase_from === "directPo" && states.from_page != "DC") {
                                    this.getVendorDClist(stateKey2)
                                    var isDcData = (states.lftTblData).some(list => list.item_from_dc)
                                    if ((states.lftTblData).length > 0 && (LastVendor != newValue[valueKey]) && isDcData) {
                                        this.setState({
                                            lftTblData: [],
                                            cgstAmt: 0,
                                            sgstAmt: 0,
                                            billAmt: 0,
                                            roundofAmt: 0,
                                            paisa: '',
                                            clearEditData: true,
                                            taxableAmt: 0
                                        })
                                    }
                                }
                            })
                        }

                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                // type: 'search',
                                emed_tid: label,
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    renderDatePicker = (label, statekey, openMenu, width, noshow) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={noshow ? false : states[openMenu]}
                        onOpen={() => this.handleOpen(openMenu)}
                        onClose={() => {
                            states[openMenu] = false;
                            this.setState(states)
                        }}
                        inputFormat='dd-MM-yyyy'
                        label={t(label) + " *"}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : null}
                        autoComplete="off"
                        minDate={label === 'DeliveryDate' ? new Date(this.state.billDate) : null}
                        maxDate={label === 'DeliveryDate' ? null : DateTime.now()}
                        onChange={(value) => {
                            states[statekey] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
                            this.setState({
                                states
                            })
                        }}
                        renderInput={(params) => <TextField
                            size="small"
                            onKeyDown={(e) => { e.preventDefault() }}
                            sx={{ width: width ? width : "9.8vw" }}
                            autoComplete="off"
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                emed_tid: label,
                            }}
                            onClick={(e) => this.handleOpen(openMenu)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    getPharmacyDuplicateCheck = (number, value, poNumber, venderId) => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMACY_DUPLICATE_BILL_DC_CHECK + `?bill_no=${number}&dc_value=${value}&po_number=${poNumber}&vendor_id=${venderId}`)
                .then((response) => {
                    if (response.data.status === 'fail') {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderText(label, statekey, Noshow = false, mandatory = false, width) {
        const { t } = this.props;
        const { purchase_status, editWithin, type_Paisa, paisa } = this.state; // Destructure state for better readability
        let states = { ...this.state }; // Create a copy of the state

        return (
            <div className="eMed_pharmadrctpo_txt">
                <TextField
                    label={t(label)}
                    required={mandatory}
                    sx={{ width: width ? width : "7vw" }}
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    value={states[statekey] ? states[statekey] : statekey === "billNo" ? "" : statekey === "paisa" ? "" : statekey === "tcsAmt" ? '' : "0.00"}
                    InputProps={{
                        readOnly: purchase_status === "Completely Delivered" && editWithin > 30 || Noshow,
                        endAdornment: statekey === "paisa" ?
                            <InputAdornment sx={{ margin: '-1vw' }}>
                                <IconButton sx={{ width: '1.3vw', height: '1vw' }} onClick={() => this.setState({ type_Paisa: !type_Paisa }, () => {
                                    var Bill_Amt = this.state.type_Paisa ? (+this.state.billAmt + (this.state.paisa / 100)) :
                                        (+this.state.billAmt - (this.state.paisa / 100))
                                    this.setState({
                                        roundofAmt: +Bill_Amt.toFixed(2)
                                    })
                                })}>
                                    {type_Paisa ? <img sx={{ width: '1vw', height: '1vw' }} src={ImagePaths.PlusIcon.default} alt="Plus Icon" /> : <img sx={{ width: '1vw', height: '1vw' }} src={ImagePaths.MinusIcon.default} alt="Minus Icon" />}
                                </IconButton>
                            </InputAdornment> : null
                    }}
                    inputProps={{ emed_tid: label, maxLength: statekey === "billNo" ? 25 : undefined }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (label === 'BillNo') {
                                if (this.state.billNo) {
                                    let value = label === 'BillNo' ? false : true
                                    let billNo = CommonValidation.removeSpace(this.state.billNo)
                                    this.getPharmacyDuplicateCheck(billNo, value, this.state.purchase_order_number, this.state.vendor_id)
                                }
                            }
                        }
                    }
                    }
                    onChange={this.updateState}
                />
            </div>
        )
    }

    updateState = (event) => {
        const { name, value } = event.target;
        let states = { ...this.state };

        if (name === "billNo") {
            states.billNo = value;
        } else if (name === 'paisa') {
            let numberVal = CommonValidation.NumandSpl(value);
            if ((numberVal && +value < 100) || value === '') {
                states.paisa = +value;
            }
        } else if (name === 'tcsAmt') {
            if (CommonValidation.NumberWithDotThree(value) || value === '') {
                states.tcsAmt = value
            }
        } else {
            states[name] = value;
        }

        this.setState({
            ...states
        }, () => {
            if (name === 'tcsAmt') {
                this.setState({
                    billAmt: (+this.state.taxableAmt + (+this.state.sgstAmt * 2) + (+this.state.tcsAmt))?.toFixed(2)
                }, () => {
                    var Bill_Amt = this.state.type_Paisa ? (+this.state.billAmt + (this.state.paisa / 100)) :
                        (+this.state.billAmt - (this.state.paisa / 100))
                    this.setState({
                        roundofAmt: +Bill_Amt.toFixed(2)
                    })
                })
            } else {
                var Bill_Amt = this.state.type_Paisa ? (+this.state.billAmt + (this.state.paisa / 100)) :
                    (+this.state.billAmt - (this.state.paisa / 100))
                this.setState({
                    roundofAmt: +Bill_Amt.toFixed(2)
                })
            }
        });
    }

    handleEdit = (data, index) => {
        data["edit_index"] = index + 1;
        this.setState({
            selectedData: { ...data },
            EditIndex: (index === 0 || index) ? index + 1 : data?.serial_no
        })
        if (this.state.tabValue !== "two" && this.state.stockFrom === "" && userAccess?.editAccess) {
            this.setState({ fromWanted: true, isUpdateCall: true })
        }
        if (this.state.stockFrom === "" && this.state.purchase_status !== "Placed Order" && !this.state.isFinished) {
            this.setState({ fromWanted: false })
        }
        // if(data?.item_from_dc){
        //     data.gen_grn = true
        // }
    }

    handleDelete = (data) => {
        var { dc_popup_data } = this.state
        let deletedArr = [...this.state.lftTblData]
        let index = deletedArr.indexOf(data)
        deletedArr.splice(index, 1)
        if (this.state.from_page === "DC") {
            deletedArr.forEach((val, i) => {
                val.serial_no = i + 1
            })
        }
        var DCindex = dc_popup_data.findIndex(list => list.stock_id === data.stock_id)
        if ((DCindex != -1) && dc_popup_data[DCindex].rowCheck) {
            dc_popup_data[DCindex].rowCheck = false
            dc_popup_data[DCindex].pushed = false
        }
        this.setState({
            lftTblData: deletedArr,
            dc_popup_data
        }, () => {
            this.setState({ isUpdateCall: false })
            this.calculateBillAmount()
            // let { lftTblData } = this.state;
            // let initialObj = {
            //     total_buying_price: 0,
            //     total_gst_amount: 0
            // }
            // let totalAmount = lftTblData?.reduce((acc, cur, i) => {
            //     acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
            //     acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(cur.total_gst_amount ? cur.total_gst_amount : 0);
            //     return acc;
            // }, initialObj)
            // if (this.state.stockFrom === "" && this.state.purchase_status !== "Placed Order" && !this.state.isFinished) {
            //     this.setState({ fromWanted: false })
            // }
            // let newTotalAmt = 0; let newGST = 0;
            // this.state.lftTblData?.forEach(element => {
            //     if ((!element?.stock_id) || (element?.stock_id && element.addDCtoPO)) {
            //         newTotalAmt = newTotalAmt + (element?.total_buying_price ? element?.total_buying_price : 0)
            //         newGST = newGST + (element?.total_gst_amount ? element?.total_gst_amount : 0)
            //     }
            // })
            // if (this.state.fromGrnLine) {
            //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+totalAmount.total_buying_price + (this.state.paisa / 100)) :
            //         (+totalAmount.total_buying_price - (this.state.paisa / 100)) : +totalAmount.total_buying_price
            //     this.setState({
            //         cgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
            //         sgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
            //         billAmt: totalAmount.total_buying_price ? totalAmount.total_buying_price.toFixed(2) : 0,
            //         isUpdateCall: false,
            //         roundofAmt: +Bill_Amt.toFixed(2),
            //         taxableAmt: (totalAmount.total_buying_price - totalAmount.total_gst_amount)?.toFixed(2)
            //     })
            // } else {
            //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+newTotalAmt + (this.state.paisa / 100)) :
            //         (+newTotalAmt - (this.state.paisa / 100)) : +newTotalAmt
            //     this.setState({
            //         lftTblData,
            //         cgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
            //         sgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
            //         billAmt: newTotalAmt ? newTotalAmt?.toFixed(2) : 0,
            //         isUpdateCall: false,
            //         roundofAmt: +Bill_Amt.toFixed(2),
            //         taxableAmt: newTotalAmt ? (+newTotalAmt - +newGST)?.toFixed(2) : '0.00',
            //     })
            // }
        })
    }

    AddNewBatch = (data) => {
        let quantity = data.purchase_quantity ? ((+data.purchase_quantity) - (+data.stock_in_quantity)) : 0;
        let tempDetails = {
            "serial_no": data.serial_no,
            "drug_id": data.drug_id,
            "brand_name": data.brand_name,
            "generic_name": data.generic_name,
            "dosage_type": data.dosage_type,
            "dosage_strength": data.dosage_strength,
            "stock_in_quantity": +quantity,
            "stock_id": data.stock_id,
            "schedule": data.schedule,
            // "wanted_request":true,
            // "wanted_quantity":data.wanted_quantity,
            "po_line_item_id": data.po_line_item_id,
            "hsn_code": data.hsn_code,
            'gst_percentage': data?.cgst_percentage * 2
        }
        this.setState({
            selectedData: tempDetails,
            isNewBatch: true,
            isUpdateCall: true
        })
    }

    renderEdit = (data, index) => {
        let states = this.state;
        const { t } = this.props;
        let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;
        return (
            (data?.item_from_dc) ?
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                    <CommonEditButton testID="drt_dlv_edit" size="1.5vw" disable={(this.state.isEditAccessGRN && !this.state.fromDC && data?.grn_number) ? true : (data?.fromDcPopup ? data?.grn_number && data?.stock_id && !this.state.fromGrnLine : data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} onClick={this.handleEdit.bind(this, data, index)} />
                    <CommonDeleteButton testID="drt_btc_dlt" size="1.5vw" disable={(data?.fromDcPopup ? data?.grn_number && data?.stock_id && !this.state.fromGrnLine : data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} onClick={this.handleDelete.bind(this, data)} />
                </Stack>
                : (data.status === "Delivered") ?
                    <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                        {(data.is_stocks_sold === false && data.is_stocks_returned === false && this.state.editWithin <= 30 && userAccess?.editAccess) ?  // Edit PO on not sold and with in 30days
                            <CommonEditButton   testID="drt_dlv_edit" size="1.5vw" disable={(this.state.isEditAccessGRN && !this.state.fromDC && data?.grn_number) ? true : (data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} onClick={this.handleEdit.bind(this, data, index)} /> : null}
                        <Typography sx={{ color: Colors.themeDark, fontSize: "0.9vw" }}>{data.status ? data.status : "-"}</Typography>
                    </Stack>
                    : data.status === "Partially Delivered" && !data.newBatch ?
                        <Stack direction="column" spacing={2} justifyContent={"center"} alignItems={"center"}>
                            {data?.gen_grn && data?.stock_id && !this.state.fromGrnLine ? null :
                                userAccess?.editAccess ? <Tooltip title={t("NewBatch")} placement="top" arrow>
                                    <Button emed_tid="drt_new_batch" size="small" variant="contained" sx={{ minWidth: '2vw' }} onClick={() => this.AddNewBatch(data)}><AddIcon /></Button>
                                </Tooltip> : null}
                            <Typography id="eMed_pharmadrctpo_stsTxt">{"Partially Delivered"}</Typography>
                        </Stack>
                        : (data.newBatch && data.status === "") || (data.tabValue === "two") ?
                            <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                                <CommonEditButton testID="drt_edit" disable={(this.state.isEditAccessGRN && !this.state.fromDC && data?.grn_number) ? true :(data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} size="1.5vw" onClick={this.handleEdit.bind(this, data, index)} />
                                {(data?.purchase_order__is_po_request && !this.state.fromGrnLine) ? null :
                                    <CommonDeleteButton testID="drt_btc_dlt" disable={data?.gen_grn && data?.stock_id && !this.state.fromGrnLine} size="1.5vw" onClick={this.handleDelete.bind(this, data)} />
                                }
                            </Stack>
                            : this.state.stockFrom === "" && (this.state.purchase_status !== "Placed Order" || data?.is_from_multi_batach) && !this.state.isFinished ?
                                <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                                    <CommonEditButton testID="drt_edit" disable={ (this.state.isEditAccessGRN && !this.state.fromDC && data?.grn_number) ? true : (data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} size="1.5vw" onClick={this.handleEdit.bind(this, data, index)} />
                                    {(data?.purchase_order__is_po_request && !this.state.fromGrnLine) ? null :
                                        <CommonDeleteButton testID="drt_dlt" disable={data?.gen_grn && data?.stock_id && !this.state.fromGrnLine} size="1.5vw" onClick={this.handleDelete.bind(this, data)} />
                                    }
                                </Stack>
                                : data.tabValue !== "two" && this.state.stockFrom === "" && userAccess?.editAccess ?
                                    <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                                        <CommonEditButton testID="drt_edit" disable={(this.state.isEditAccessGRN && !this.state.fromDC && data?.grn_number) ? true : (data?.gen_grn && data?.stock_id && !this.state.fromGrnLine)} size="1.5vw" onClick={this.handleEdit.bind(this, data, index)} />
                                    </Stack> : null

        )
    }

    getAbsoluteFix = (num) => {
        let absoluteValue = Math.abs(num);
        let formattedValue = absoluteValue.toFixed(2);
        return formattedValue;
    }

    PopUpClose = () => {
        this.setState({
            is_dc_popup: false
        })
    }

    renderDCpopup = () => {
        var states = this.state
        const { t } = this.props;
        var allRowCheckTrue = (states.dc_popup_data).every((data) => data.rowCheck === true)
        return (
            <Box sx={{ backgroundColor: 'white', height: 'vh' }}>
                <TableContainer id='eMed_pharmaReturnPO_table' elevation={1} sx={{ backgroundColor: Colors.white }}>
                    <Table stickyHeader size='small'>
                        <TableHead className="eMed_pharmadrctpo_tblhead" sx={{ height: "5vh" }}>
                            <TableRow>
                                <TableCell sx={{ flex: 0.05 }} id="eMed_pharmadrctpo_tblheadTxt">{
                                    <Checkbox size='small' checked={allRowCheckTrue} onClick={(e) => {
                                        var updatedData = [...states.dc_popup_data]
                                        var DataString = JSON.stringify(updatedData)
                                        DataString = JSON.parse(DataString)
                                        DataString.forEach((data) => {
                                            data.rowCheck = e.target.checked
                                            data.item_from_dc = true
                                            data.total_gst_amount = data?.cgst_amount ? data?.cgst_amount * 2 : 0
                                        });
                                        this.setState({
                                            dc_popup_data: DataString,
                                        });
                                    }} />
                                }</TableCell>
                                <TableCell sx={{ flex: 0.20 }} id="eMed_pharmadrctpo_tblheadTxt">{t("ProductDetails")}</TableCell>
                                <TableCell sx={{ flex: 0.10 }} id="eMed_pharmadrctpo_tblheadTxt">{t(`MRP (${CurrencySymbol})`)}</TableCell>
                                <TableCell sx={{ flex: 0.35 }} id="eMed_pharmadrctpo_tblheadTxt">{"Quantity"}</TableCell>
                                <TableCell sx={{ flex: 0.20 }} id="eMed_pharmadrctpo_tblheadTxt"> {t("PurchaseValue")}</TableCell>
                                <TableCell sx={{ flex: 0.10 }} id="eMed_pharmadrctpo_tblheadTxt">{t("NetAmount") + `(${CurrencySymbol})`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="eMed_pharmadrctpo_tblhead">
                            {(states.dc_popup_data)?.length > 0 && (states.dc_popup_data).map((data, index) => {
                                let gst_percentage = data.cgst_percentage ? (data.cgst_percentage * 2) : 0;
                                let gst_amount = data.cgst_amount ? (data.cgst_amount * 2) : 0;
                                let buying_price = data.total_buying_price && data.total_gst_amount ? (data.total_buying_price - data.total_gst_amount) : (data.total_buying_price - gst_amount);
                                let today = (data.tabValue === "two" && data.free_expiry_date) ? new Date(data.free_expiry_date) : data.expiry_date ? new Date(data.expiry_date) : "";
                                let expiry_date = today ? DateTime.fromJSDate(today).toFormat("MM-yyyy") : null;
                                // let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * + data.free_qty_per_unit)
                                //     : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;

                                let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * +data.free_qty_per_unit)
                                    : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                // let salesQty = (data?.sales_unit && data?.pack_of_drugs) ? data?.sales_unit * data?.pack_of_drugs : (data?.quantity || data?.purchase_quantity)
                                let salesQty = data?.sales_unit * data?.pack_of_drugs
                                // let stockQty = (data?.sales_unit && data?.pack_of_drugs) ? salesQty + (+freeQty) : data.stock_in_quantity ? data.stock_in_quantity : ''
                                let stockQty = salesQty + (+freeQty)
                                let onlyFreeQty = (data?.is_free_quantity && data?.pack_of_drugs && data?.sales_unit) ? (data?.pack_of_drugs * data?.sales_unit) : (data?.free_no_of_unit * data?.free_qty_per_unit)

                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ flex: 0.05 }}>
                                            <Checkbox size='small' checked={data?.rowCheck === true} onClick={(e) => {
                                                var updatedData = [...states.dc_popup_data]
                                                updatedData[index] = {
                                                    ...updatedData[index],
                                                    rowCheck: e.target.checked,
                                                    item_from_dc: true,
                                                    total_gst_amount: gst_amount,
                                                };
                                                this.setState({
                                                    dc_popup_data: updatedData,
                                                });
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.20 }}>
                                            <Stack direction="column">
                                                <Typography id="eMed_pharmadrctpo_tblbodyTxt">{data.brand_name?.length > 13 ?
                                                    <Tooltip placement="top" title={data.brand_name} arrow><div>{data.brand_name.slice(0, 12) + "..."}</div></Tooltip>
                                                    : data.brand_name ? data.brand_name : "-"}</Typography>
                                                <Typography id="eMed_pharmadrctpo_tblTxt">{data.generic_name?.length > 13 ?
                                                    <Tooltip placement="top" title={data.generic_name} arrow><div>{data.generic_name.slice(0, 12) + "..."}</div></Tooltip>
                                                    : data.generic_name ? data.generic_name : "-"}</Typography>
                                                <Typography id="eMed_pharmadrctpo_tblTxt">{data.dosage_type + "|" + data.dosage_strength}</Typography>
                                                {data.hsn_code ?
                                                    <Typography id="eMed_pharmadrctpo_tblTxt">HSN Code:{data.hsn_code}</Typography>
                                                    : null}
                                            </Stack>
                                        </TableCell>
                                        <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.10 }}>
                                            {data.mrp_per_quantity ? this.getAbsoluteFix(data.mrp_per_quantity) : "0"}
                                        </TableCell>
                                        <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.35 }}>
                                            <Stack direction="column">
                                                <Typography sx={{ textAlign: data.quantity && !(data.stock_in_quantity) ? "center" : null }}>
                                                    {data?.is_free_quantity ? `${data?.purchase_quantity ? data?.purchase_quantity : onlyFreeQty} (Free)` :
                                                        ((data.quantity || salesQty) && data.free_quantity) ?
                                                            (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0} + ${freeQty} (Free) `)
                                                            : data.free_quantity || data.free_no_of_unit ? (`${stockQty ? stockQty : "0"} + ${freeQty} (Free) `)
                                                                : (data.quantity || data.purchase_quantity) ? (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0}`)
                                                                    : data.current_quantity && data.free_quantity ? // From stock List
                                                                        (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0} + ${freeQty} (Free) `)
                                                                        : data.current_quantity ? (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0}`)
                                                                            : data.stock_in_quantity}
                                                </Typography>
                                                {data.batch_no || data.free_batch_no ? <Typography id="eMed_pharmadrctpo_tblTxt">Batch No: {data.tabValue === "two" ? data.free_batch_no : data.batch_no}</Typography> : null}
                                                {expiry_date ? <Typography id="eMed_pharmadrctpo_tblTxt">Exp Date: {expiry_date}</Typography> : null}
                                            </Stack>
                                        </TableCell>
                                        <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.20 }}>{(data.gst_percentage || gst_percentage) != 0 ?
                                            (`${buying_price ? this.getAbsoluteFix(buying_price) : 0} + ${data.gst_percentage ? data.gst_percentage : gst_percentage ? gst_percentage : 0}% GST`)
                                            : (data.total_buying_price || data.buying_price) ? (`${data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                                : data.buying_price ? this.getAbsoluteFix(data.buying_price) : ""} + 0% GST`) : "0 + 0% GST"}</TableCell>
                                        <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.10 }}>{(data.total_buying_price || data.buying_price) ? (data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                            : data.buying_price ? this.getAbsoluteFix(data.buying_price) : "0") : "0"}</TableCell>
                                    </TableRow>
                                )
                            })

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    addDCtoPO = () => {
        let { dc_popup_data } = this.state
        var Valid = dc_popup_data.some((data) => data.rowCheck === true)
        if (Valid) {
            this.setState({
                cgstAmt: 0,
                sgstAmt: 0,
                billAmt: 0,
                roundofAmt: 0,
                taxableAmt: 0
            }, () => {
                let { dc_popup_data, lftTblData, billAmt, cgstAmt } = this.state
                let LineItems = [...lftTblData]
                var ValidList = dc_popup_data.filter((data) => data.rowCheck === true)
                var BillAmount = +(billAmt) ? +(billAmt) : 0
                var cgstAmount = +(cgstAmt) ? +(cgstAmt) : 0
                if (ValidList?.length > 0) {
                    ValidList?.filter((data) => !(data.pushed))?.forEach((list, index) => {
                        LineItems.push(list)
                    })
                }
                dc_popup_data.forEach((item, i) => {
                    if (item.pushed && !(item.rowCheck)) {
                        var Index = LineItems.findIndex(list => list.stock_id === item.stock_id)
                        LineItems.splice(Index, 1)
                    }
                    item.pushed = item.rowCheck ? true : false
                    item.addDCtoPO = true
                })
                LineItems.forEach((val, i) => {
                    BillAmount += val.total_buying_price ? val.total_buying_price : 0
                    cgstAmount += val.cgst_amount ? val.cgst_amount : val.total_gst_amount ? (val.total_gst_amount / 2) : 0
                    val.serial_no = i + 1
                })
                this.setState({ lftTblData: LineItems, dc_popup_data }, () => {
                    let tltAmt = 0; let newGST = 0;
                    this.state.lftTblData?.forEach(element => {
                        if ((element?.stock_id && element.addDCtoPO) || (!element.stock_id)) {
                            tltAmt = tltAmt + (element?.total_buying_price ? element?.total_buying_price : 0)
                            newGST = newGST + (element?.total_gst_amount ? element?.total_gst_amount : 0)
                            // element.gen_grn = true
                        }
                    })
                    if (this.state.fromGrnLine) {
                        let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+BillAmount + (this.state.paisa / 100)) :
                            (+BillAmount - (this.state.paisa / 100)) : BillAmount
                        this.setState({
                            cgstAmt: cgstAmount ? (cgstAmount).toFixed(2) : 0,
                            sgstAmt: cgstAmount ? (cgstAmount).toFixed(2) : 0,
                            billAmt: BillAmount ? (BillAmount).toFixed(2) : 0,
                            lftTblData: this.state.lftTblData,
                            is_dc_popup: false,
                            // dc_popup_data,
                            roundofAmt: +Bill_Amt.toFixed(2),
                            taxableAmt: (+Bill_Amt - (cgstAmount * 2))?.toFixed(2),
                            fromDcPopup: true
                        })
                    } else {
                        let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+tltAmt + (this.state.paisa / 100)) :
                            (+tltAmt - (this.state.paisa / 100)) : tltAmt
                        this.setState({
                            cgstAmt: newGST ? +(newGST / 2).toFixed(2) : 0,
                            sgstAmt: newGST ? +(newGST / 2).toFixed(2) : 0,
                            billAmt: tltAmt ? +(tltAmt).toFixed(2) : 0,
                            lftTblData: this.state.lftTblData,
                            is_dc_popup: false,
                            // dc_popup_data,
                            roundofAmt: +Bill_Amt.toFixed(2),
                            taxableAmt: (+tltAmt - (newGST))?.toFixed(2),
                            fromDcPopup: true
                        })
                    }
                })
            })
        } else {
            this.errorMessage("Kindly Select Atleast One Product")
        }
    }

    calculateBillAmount = () => {
        try {
            let { lftTblData } = this.state;
            let initialObj = {
                total_buying_price: 0,
                total_gst_amount: 0
            }
            let totalAmount = lftTblData?.reduce((acc, cur, i) => {
                let gst_amount = cur.total_gst_amount ? cur.total_gst_amount : (2 * cur.cgst_amount);
                "serial_no" in cur ? cur["serial_no"] = cur["serial_no"] : cur["serial_no"] = i + 1;
                acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
                acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(gst_amount ? gst_amount : 0);
                return acc;
            }, initialObj)

            let newTotalAmt = 0; let newGST = 0;
            lftTblData?.forEach(element => {
                if ((!element?.stock_id) || (element?.stock_id && element.addDCtoPO)) {
                    newTotalAmt = newTotalAmt + (element?.total_buying_price ? element?.total_buying_price : 0)
                    newGST = newGST + (element?.total_gst_amount ? element?.total_gst_amount : 0)
                }
            })
            if (this.state.fromGrnLine) {
                let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+totalAmount.total_buying_price + (this.state.paisa / 100)) :
                    (+totalAmount.total_buying_price - (this.state.paisa / 100)) : +totalAmount.total_buying_price
                this.setState({
                    lftTblData,
                    cgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                    sgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                    billAmt: totalAmount.total_buying_price ? totalAmount.total_buying_price.toFixed(2) : 0,
                    roundofAmt: +Bill_Amt.toFixed(2),
                    taxableAmt: (+totalAmount.total_buying_price - +totalAmount.total_gst_amount)?.toFixed(2)
                })
            } else {
                let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+newTotalAmt + (this.state.paisa / 100)) :
                    (+newTotalAmt - (this.state.paisa / 100)) : +newTotalAmt
                this.setState({
                    lftTblData,
                    cgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                    sgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                    billAmt: newTotalAmt ? newTotalAmt?.toFixed(2) : 0,
                    roundofAmt: +Bill_Amt.toFixed(2),
                    taxableAmt: newTotalAmt ? (+newTotalAmt - +newGST)?.toFixed(2) : '0.00'
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderWarningPopup = () => {
        try {
            return (
                <Box component={'div'}>
                    <Dialog open={this.state.openPopup}>
                        <DialogContent>
                            {this.state.purchase_status === 'Placed Order' ?
                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                    <WarningIcon color='warning' />
                                    <Typography ml={'0.5vw'} fontWeight={600}>Are you willing to uncheck the item then it will be retain with previous data.</Typography>
                                </Box> :
                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                    <WarningIcon color='warning' />
                                    <Typography ml={'0.5vw'} fontWeight={600}>Are you willing to uncheck the item then it will not be consider and it will be removed.</Typography>
                                </Box>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' color='warning'
                                onClick={() => {
                                    let states = this.state
                                    states.lftTblData[states.selectedRemoveIndex]['gen_grn'] = true
                                    this.setState({ lftTblData: this.state.lftTblData }, () => this.setState({ openPopup: false, selectedRemoveIndex: null }))
                                }}>Cancel</Button>
                            <Button variant='contained' color='warning'
                                onClick={() => {
                                    let states = this.state
                                    let dupLftData = JSON.parse(states.dupLftData)
                                    if ((states.purchase_status === 'Placed Order' || states.lftTblData[states.selectedRemoveIndex]?.purchase_order__is_po_request) && dupLftData[states.selectedRemoveIndex]) {
                                        dupLftData[states.selectedRemoveIndex]['gen_grn'] = false
                                        states.lftTblData[states.selectedRemoveIndex] = dupLftData[states.selectedRemoveIndex]
                                        this.setState({ lftTblData: this.state.lftTblData, fromWarnPopup: true }, () => {
                                            this.calculateBillAmount()
                                            this.setState({ openPopup: false })
                                        })
                                    } else {
                                        states.lftTblData?.splice(states.selectedRemoveIndex, 1)
                                        this.setState({ lftTblData: this.state.lftTblData }, () => {
                                            this.calculateBillAmount()
                                            this.setState({ openPopup: false, selectedRemoveIndex: null })
                                        })
                                    }
                                }}
                            >Yes</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    isItemsforCancel() {
        let states = this.state;
        let isHaveValidItems = states?.lftTblData.some((item) => (!item?.grn_number && item?.po_line_item_id))
        if ((states.purchase_status === "Placed Order" || states.purchase_status === "Partially Delivered") && isHaveValidItems) {
            return true
        } else {
            return false
        }
    }

    cancelPopclose = () => {
        this.setState({
            CancelPopClicked: false
        })
    }

    CancelDrugItems = () => {
        try {
          let states = this.state;
          let isHaveValidItems = states?.lftTblData.filter((item) => (!item?.grn_number && item?.po_line_item_id && item?.gen_grn))?.map((item)=>( item?.po_line_item_id ))
          let FinalKey = isHaveValidItems.join(',');
          let data = {
            "po_line_id" : FinalKey,
            "po_id" : states?.purchase_order_id,
          }
          RestAPIService.create(data, Serviceurls.PHARMACY_PO_CANCEL)
            .then(response => {
              if (response.data.status === 'success') {
                this.successMessage(response.data.message)
                this.setState({
                    CancelPopClicked : false
                }, () => { this._getPurchaseDetails() })
              }
            }).catch((error) => {
              if (error?.response?.data?.message) {
                this.errorMessage(error?.response?.data?.message)
              } else {
                this.errorMessage(error.message)
              }
            })
        } catch (error) {
          this.setState({ loading: false })
          this.errorMessage(error.message)
        }
      }

    render() {

        const { t } = this.props;
        let { lftTblData } = this.state
        let states = this.state;
        let isDataAvail = this.validateVendor();
        let isWantedPartial = lftTblData.some((item) => (item.status === "Partially Delivered" && this.state.isFinished))
        // let isWantedPartial = false
        let fromDirectPO = this.props.history?.location?.state?.fromDirectPO
        let grnCheck1 = states.lftTblData?.every(item => item?.gen_grn)
        let grnFinish = [];
        states.lftTblData?.forEach(element => {
            if (!element?.grn_number) {
                grnFinish?.push(element)
            }
        })
        let grnCheck = grnFinish?.some(item => item?.gen_grn)
        let hideFinish = this.props.history?.location?.state?.notShowFinish
        let tctEnable = this.state.loggedInuserInfo?.tcs_purchase_applicable ? true : false
        return (
            <Box id={states.stockFrom !== "" ? "eMed_pharmartnDrctpo_Container" : "eMed_pharmadrctpo_Container"}>
                <Grid container spacing={states.stockFrom !== "" ? 2.5 : 3} id={states.stockFrom !== "" ? "eMed_pharmartnDrctpo_mainDiv" : "eMed_pharmadrctpo_mainDiv"}>
                    <Box sx={{ marginX: '2vw', marginTop: '1vw', width: '100%' }}>
                        <Grid >
                            {states.stockFrom === "stockListView" ?
                                <Paper id={"eMed_pharmadrctpo_viewSkDiv"} elevation={1} >
                                    {this.renderAutoSlt("VendorName", "vendor_name", "vendor_id", "name", "vendor_id", states.vendor_list, "35vw")}
                                    {states.purchase_order_number ? this.renderText("Purchase No", "purchase_order_number", true, false, "10vw") : null}
                                    {this.renderDatePicker("BillDate", "billDate", "open1", "10vw", !states.showSaveBtn)}
                                    {/* {this.renderText("GRN No", "grn_no", true, false, "10vw")} */}
                                    {this.renderDatePicker("DeliveryDate", "delivery_date", "open", "", !states.showSaveBtn)}
                                    {this.renderText("CGSTAmount", "cgstAmt", true)}
                                    {this.renderText("SGSTAmount", "sgstAmt", true)}
                                </Paper>
                                : <Paper id={states.stockFrom !== "" ? "eMed_pharmartnDrctpo_paper1" : "eMed_pharmadrctpo_paper1"} elevation={states.stockFrom !== "" ? 2 : 1} >
                                    <Stack direction="row" justifyContent={"flex-start"} alignItems={"center"} spacing={1} sx={{ height: "7vh" }}>
                                        {this.renderAutoSlt("VendorName", "vendor_name", "vendor_id", "name", "vendor_id", states.vendor_list, states.purchase_order_number ? "22vw" : "30vw")}
                                        {this.state.purchase_status === 'Placed Order' ? null :
                                            (this.state.is_vendor_dc || (!this.state.isFinished && this.state.is_vendor_dc)) ?
                                                <Tooltip title="DC detail" placement='top' arrow>
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            is_dc_popup: true,
                                                        })
                                                    }}><ErrorOutlineIcon color='primary' /></IconButton>
                                                </Tooltip> : null}
                                        {states.purchase_order_number && states.purchase_from !== "directPo" ? this.renderText("Purchase No", "purchase_order_number", true, false, "15vw") : null}
                                        {(states.from_page === "DC") ? null : this.renderDatePicker("BillDate", "billDate", "open1", "10vw", !states.showSaveBtn)}
                                        {/* {states.purchase_order_number && states.purchase_from !== "directPo" ? this.renderText("GRN No", "grn_no", true, false, "15vw") : null} */}
                                        {this.renderText((states.from_page === "DC") ? "DC No" : "BillNo", "billNo", !states.showSaveBtn, true, "15vw")}
                                        {/* {states.purchase_from === "directPo" || states.stockFrom ? null :
                                            states.purchase_status === "Placed Order" ?
                                                <Tooltip title={t("Print")} placement='top' arrow>
                                                    <IconButton emed_tid='drt_print' size="small" onClick={() => { }}>
                                                        <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_IP_Exp_printimg' disabled={this.state.disableBtn} onClick={() => this.getPurchaseRequestPrint()} />
                                                    </IconButton>
                                                </Tooltip>
                                                : <Tooltip title={t("Print")} placement='top' arrow>
                                                    <IconButton emed_tid='drt_print' size="small" disabled={this.state.disableBtn} onClick={() => { this.printDirectpo() }}>
                                                        <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_IP_Exp_printimg' />
                                                    </IconButton>
                                                </Tooltip>
                                        } */}
                                        {this.renderDatePicker((states.from_page === "DC") ? "DC Date" : "DeliveryDate", "delivery_date", "open", "", !states.showSaveBtn, "15vw")}
                                    </Stack>
                                    <div className="eMed_pharmadrctpo_subDiv">
                                        {this.renderText(`Taxable Amount(${CurrencySymbol})`, "taxableAmt", true, false, '10vw')}
                                        {this.renderText(`CGST(${CurrencySymbol})`, "cgstAmt", true, false, '10vw')}
                                        {this.renderText(`SGST(${CurrencySymbol})`, "sgstAmt", true, false, '10vw')}
                                        {tctEnable ? states.from_page === "DC" ? null : this.renderText(`TCS(${CurrencySymbol})`, "tcsAmt", false, false, '10vw') : null}
                                        {this.renderText("BillAmount", "billAmt", true, false, '10vw')}
                                        {states.from_page === "DC" ? null : this.renderText("Paisa", "paisa", (this.state.billAmt && !this.state.isFinished) ? false : true, false, '7vw')}
                                        {states.from_page === "DC" ? null : this.renderText("Round off Value", "roundofAmt", true, false, '10vw')}
                                        {/* {states.from_page !== "DC" && !fromDirectPO ?
                                            <Button emed_tid='drt_save' size="small" variant={'contained'} sx={{ textTransform: "capitalize", minWidth: "4vw", height: "5vh", marginRight: '0.5vw', marginTop: '1vh' }}
                                                disabled={this.state.isFinished || this.state.saveLoad}
                                                onClick={() => {
                                                    this.setState({
                                                        isSaved: true
                                                    }, () => {
                                                        if (states.purchase_from === "directPo" && isDataAvail === "" && states.from_page != "DC") {
                                                            this.getPoNumber();
                                                        } else {
                                                            this.AddToStock();
                                                        }
                                                    })
                                                }}
                                            >{"Generate GRN" : t("Save")}</Button> : null
                                        } */}
                                        {states.from_page !== "DC" ?
                                            <Button emed_tid='drt_grn' size="small" variant={'contained'} sx={{ textTransform: "capitalize", minWidth: "4vw", height: "5vh", marginRight: '0.5vw', marginTop: '1vh' }}
                                                disabled={this.state.isFinished || this.state.saveLoad}
                                                onClick={() => {
                                                    this.setState({
                                                        isSaved: true,
                                                        genGrn: this.state.fromWarnPopup ? false : true,
                                                        saveLoad: true
                                                    }, () => {
                                                        if (states.purchase_from === "directPo" && isDataAvail === "" && states.from_page != "DC") {
                                                            this.getPoNumber();
                                                        } else {
                                                            this.AddToStock();
                                                        }
                                                    })
                                                }}
                                            >{hideFinish ? 'Generate GRN' : (this.state.fromGrnLine) ? 'Save' : "Generate GRN"}</Button> : null
                                        }
                                        {(this.state.fromGrnLine && !hideFinish) || (states.from_page === 'DC') ?
                                            states.showSaveBtn ? <Button
                                                disabled={this.state.clickFinish || this.state.isSaveClicked || (states.from_page != "DC" && isWantedPartial) || states.purchase_status === "Placed Order" || (!grnCheck1 && !fromDirectPO && states.from_page !== "DC" && !this.state.isFinished) || (!grnCheck && !fromDirectPO && states.from_page !== "DC" && !this.state.isFinished && states.purchase_status === "Placed Order")}
                                                emed_tid='drt_finish' size="small" variant={this.state.isSaveClicked ? 'outlined' : 'contained'} sx={{ textTransform: "capitalize", minWidth: "4vw", height: "5vh", marginTop: '1vh' }} onClick={() => {
                                                    this.setState({
                                                        isSaveClicked: true,
                                                        isSaved: false,
                                                        clickFinish: true,
                                                        saveLoad: true
                                                    }, () => {
                                                        if (states.purchase_from === "directPo" && isDataAvail === "" && states.from_page != "DC") {
                                                            this.getPoNumber();
                                                        } else {
                                                            this.AddToStock();
                                                        }
                                                    })
                                                }}>{states.from_page != "DC" ? t("Finish") : t("Save")}</Button> : null : null}
                                        {
                                            this.isItemsforCancel() ?
                                                <Button
                                                    sx={{ textTransform: "capitalize", minWidth: "4vw", height: "5vh", marginRight: '0.5vw', marginTop: '1vh' }}
                                                    size='small'
                                                    disabled={states?.lftTblData.some((item) => (!item?.grn_number && item?.po_line_item_id && item?.gen_grn)) ? false : true}
                                                    variant='outlined'
                                                    color='error'
                                                    onClick={()=>{ this.setState({ CancelPopClicked : true})}}
                                                >Cancel Drugs</Button>
                                                : null
                                        }

                                    </div>
                                </Paper>}
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '2%', marginX: '2vw', width: '100%' }}>
                        <Grid item xs={7.5}>
                            <Paper id={states.stockFrom !== "" ? "eMed_pharmartnDrct   po_table" : "eMed_pharmadrctpo_table"} elevation={1} sx={{ backgroundColor: Colors.white }}>
                                <TableContainer id={states.stockFrom !== "" ? "eMed_pharmartnDrctpo_table" : 'eMed_pharmadrctpo_table'}>
                                    <Table stickyHeader size='small'>
                                        <TableHead className="eMed_pharmadrctpo_tblhead" sx={{ height: "5vh" }}>
                                            <TableRow>
                                                {window.location.href.includes("/PharmacyInventory/StockList") ?
                                                    <TableCell width={'5%'} id="eMed_pharmadrctpo_tblheadTxt">{"S.No"}</TableCell> :
                                                    !fromDirectPO && states.from_page !== "DC" ?
                                                        <TableCell width={'5%'} id="eMed_pharmadrctpo_tblheadTxt">
                                                            <Box component={'div'} display={'flex'}>
                                                                <Checkbox size='small'
                                                                    disabled={states.lftTblData?.some(item => item?.grn_number)}
                                                                    checked={this.state.lftTblData?.every(item => item?.gen_grn)}
                                                                    onChange={(e) => {
                                                                        let states = this.state
                                                                        states.lftTblData?.forEach((element, index) => element.gen_grn = e.target.checked)
                                                                        this.setState({ lftTblData: this.state.lftTblData })
                                                                    }} />
                                                            </Box>
                                                        </TableCell> :
                                                        <TableCell width={'5%'} id="eMed_pharmadrctpo_tblheadTxt">{"S.No"}</TableCell>
                                                }
                                                <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("ProductDetails")}</TableCell>
                                                <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("Buying Price/Unit")}</TableCell>
                                                <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t(`MRP (${CurrencySymbol})`)}</TableCell>
                                                <TableCell width={'15.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("Quantity")}</TableCell>
                                                <TableCell width={'12.11%'} id="eMed_pharmadrctpo_tblheadTxt"> {t("PurchaseValue")}</TableCell>
                                                {states.from_page !== "DC" ? <TableCell width={'12.11%'} id="eMed_pharmadrctpo_tblheadTxt"> {t("GRN Details")}</TableCell> : null}
                                                <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("Net Amt") + `(${CurrencySymbol})`}</TableCell>
                                                <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt" align='center'>{t("Action")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="eMed_pharmadrctpo_tblhead">
                                            {lftTblData.length > 0 && lftTblData.map((data, index) => {
                                                let gst_percentage = data.cgst_percentage ? (data.cgst_percentage * 2) : 0;
                                                let gst_amount = data.cgst_amount ? (data.cgst_amount * 2) : 0;
                                                let buying_price = data.total_buying_price && data.total_gst_amount ? (data.total_buying_price - data.total_gst_amount) : (data.total_buying_price - gst_amount);
                                                let today = (data.tabValue === "two" && data.free_expiry_date) ? new Date(data.free_expiry_date) : data.expiry_date ? new Date(data.expiry_date) : "";
                                                let expiry_date = today ? DateTime.fromJSDate(today).toFormat("MM-yyyy") : null;
                                                let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * +data.free_qty_per_unit)
                                                    : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                // let salesQty = (data?.sales_unit && data?.pack_of_drugs) ? data?.sales_unit * data?.pack_of_drugs : (data?.quantity || data?.purchase_quantity)
                                                let salesQty = ((!data?.from_wanted && !data?.sales_unit && !data?.pack_of_drugs) || (data?.from_wanted && data?.sales_unit && data?.pack_of_drugs) || this.state.callPost) ? (data?.quantity || data?.purchase_quantity) : data?.sales_unit * data?.pack_of_drugs
                                                // let stockQty = (data?.sales_unit && data?.pack_of_drugs) ? salesQty + (+freeQty) : data.stock_in_quantity ? data.stock_in_quantity : ''
                                                let stockQty = (((!data?.from_wanted && !data?.sales_unit && !data?.pack_of_drugs) || (data?.from_wanted && data?.sales_unit && data?.pack_of_drugs) || this.state.callPost) ? data.stock_in_quantity : salesQty + (+freeQty))
                                                let onlyFreeQty = (data?.is_free_quantity && data?.pack_of_drugs && data?.sales_unit) ? (data?.pack_of_drugs * data?.sales_unit) : (data?.free_no_of_unit * data?.free_qty_per_unit)
                                                let testId = (data?.sales_unit && data?.pack_of_drugs) ? (data?.sales_unit * data?.pack_of_drugs) === data?.quantity ? 'delivered' : 'partial' : 'null'
                                                let batchNo = data.tabValue === "two" ? `Batch No.:${data.free_batch_no}` : `Batch No.:${data.batch_no}`
                                                let purFreeQty = (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                let QtyPerUnit = (data?.total_buying_price - (data?.cgst_amount * 2))?.toFixed(2)
                                                let overAllQty = (data?.sales_unit * data?.pack_of_drugs) + purFreeQty
                                                let purPerQty = (QtyPerUnit && overAllQty) ? (QtyPerUnit / overAllQty)?.toFixed(2) : 0
                                                // if(data?.addDCtoPO){
                                                //     data.gen_grn = true
                                                // }
                                                return (
                                                    <TableRow key={index} emed_tid={testId} sx={{ backgroundColor: states.EditIndex - 1 === index ? '#e2e2e2' : (data.item_from_dc || data.from_dc) ? '#FEB4B9' : null }}>
                                                        {window.location.href.includes("/PharmacyInventory/StockList/StockReturn") ?
                                                            <TableCell>{index + 1}</TableCell> :
                                                            !fromDirectPO && states.from_page !== "DC" ?
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    <Box component={'div'}>
                                                                        <Checkbox
                                                                            disabled={data?.grn_number ? true : false}
                                                                            checked={data?.gen_grn ? (data?.gen_grn) : false} size='small'
                                                                            onChange={(e) => {
                                                                                let states = this.state
                                                                                states.lftTblData[index]['gen_grn'] = e.target.checked
                                                                                this.setState({ lftTblData: this.state.lftTblData, grnCheck: e.target.checked }, () => {
                                                                                    if (!states.lftTblData[index]['gen_grn']) {
                                                                                        this.setState({ selectedRemoveIndex: index }, () => this.setState({ openPopup: true }))
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </Box>
                                                                </TableCell> :
                                                                <TableCell>{index + 1}</TableCell>}
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                            <Stack direction="column">
                                                                <Typography component={'div'} id="eMed_pharmadrctpo_tblbodyTxt" color={'black'}>{data.brand_name?.length > 11 ?
                                                                    <Tooltip placement="top" title={data.brand_name} arrow><div style={{ color: 'black' }} id="eMed_pharmadrctpo_tblbodyTxt">{data.brand_name.slice(0, 11) + ".."}</div></Tooltip>
                                                                    : data.brand_name ? <Typography component={'span'} color={'black'} id="eMed_pharmadrctpo_tblbodyTxt">{data.brand_name}</Typography> : "-"}</Typography>
                                                                <Typography id="eMed_pharmadrctpo_tblTxt">{data.generic_name?.length > 6 ?
                                                                    <Tooltip placement="top" title={data.generic_name} arrow><div style={{ fontSize: '0.9vw' }}>{data.generic_name.slice(0, 6) + "..."}</div></Tooltip>
                                                                    : data.generic_name ? data.generic_name : "-"}</Typography>
                                                                <Typography id="eMed_pharmadrctpo_tblTxt">{data.dosage_type + "|" + data.dosage_strength}</Typography>
                                                                {data.hsn_code ? data.hsn_code?.length > 6 ?
                                                                    <Tooltip placement='top' title={data?.hsn_code}><Typography id="eMed_pharmadrctpo_tblTxt">{data?.hsn_code?.slice(0, 6) + '...'}</Typography></Tooltip> :
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">HSN Code:{data.hsn_code}</Typography>
                                                                    : null}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr" >{data?.is_free_quantity ? 0 : (data.buying_price ? (data.buying_price) : "0")}</TableCell>
                                                        {/* <TableCell id="eMed_pharmadrctpo_Txtclr" >{data?.is_free_quantity ? 0 : (data?.purchase_per_qty ?  (data?.purchase_per_qty) : (purPerQty))}</TableCell> */}
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr" >{data?.is_free_quantity ? (data.free_mrp_unit && data.free_qty_per_unit ? +(+data.free_mrp_unit / +data.free_qty_per_unit)?.toFixed(2) : data.mrp_per_quantity) : (data.mrp_per_quantity ? this.getAbsoluteFix(data.mrp_per_quantity) : "0")}</TableCell>
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                            <Stack direction="column">
                                                                <Typography fontSize={'0.9vw'} color={'black'} fontWeight={600} sx={{ textAlign: data.quantity && !(data.stock_in_quantity) ? "center" : null }}>
                                                                    {data?.is_free_quantity ? `${data?.purchase_quantity ? data?.purchase_quantity : onlyFreeQty} (Free)` :
                                                                        ((data.quantity || salesQty) && data.free_quantity) ?
                                                                            (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0} + ${freeQty} (Free) `)
                                                                            : data.free_quantity || data.free_no_of_unit ? (`${stockQty ? stockQty : "0"} + ${freeQty} (Free) `)
                                                                                : (data.quantity || data.purchase_quantity) ? (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0}`)
                                                                                    : data.current_quantity && data.free_quantity ? // From stock List
                                                                                        (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0} + ${freeQty} (Free) `)
                                                                                        : data.current_quantity ? (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0}`)
                                                                                            : data.stock_in_quantity}
                                                                </Typography>
                                                                {/* {data.batch_no || data.free_batch_no ? <Typography id="eMed_pharmadrctpo_tblTxt">Batch No: {data.tabValue === "two" ? data.free_batch_no:data.batch_no}</Typography> : null} */}
                                                                {data?.batch_no || data?.free_batch_no ?
                                                                    batchNo?.length > 15 ? <Tooltip placement='top' title={batchNo}><Typography id="eMed_pharmadrctpo_tblTxt">{batchNo?.slice(0, 15) + '...'}</Typography></Tooltip> :
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">{batchNo}</Typography> : null
                                                                }
                                                                {expiry_date ? <Typography id="eMed_pharmadrctpo_tblTxt">Exp Date: {expiry_date}</Typography> : null}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr" >{(data.gst_percentage || gst_percentage) != 0 ?
                                                            (`${buying_price ? this.getAbsoluteFix(buying_price) : 0} + ${data.gst_percentage ? data.gst_percentage : gst_percentage ? gst_percentage : 0}% GST`)
                                                            : (data.total_buying_price || data.buying_price) ? (`${data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                                                : data.buying_price ? this.getAbsoluteFix(data.buying_price) : ""} + 0% GST`) : "0 + 0% GST"}</TableCell>
                                                        {states.from_page !== "DC" ?
                                                            <TableCell>
                                                                <Typography fontSize={'0.8vw'} color={Colors.grayShade} >{data?.grn_number ? (data?.grn_number) : '-'}</Typography>
                                                                <Typography fontSize={'0.8vw'} color={Colors.grayShade} >{data?.grn_date === null ? '-' : data?.grn_date ? formatDate(data?.grn_date?.slice(0, 10)) : '-'}</Typography>
                                                            </TableCell> : null
                                                        }
                                                        <TableCell id="eMed_pharmadrctpo_Txtclr" >{(data.total_buying_price || data.buying_price) ? (data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                                            : data.buying_price ? this.getAbsoluteFix(data.buying_price) : "0") : "0"}</TableCell>
                                                        <TableCell >{this.renderEdit(data, index)}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Box sx={{ height: '57vh', overflow: 'hidden' }}>
                                {
                                    this.state.stockFrom === "stockListReturn" ? this.props.returnComponent()
                                        : this.state.stockFrom === "stockListView" ? null
                                            : <PharmaDirectPoNew
                                                EditData={this.state.selectedData}
                                                PO_id={this.state.purchase_order_id ? this.state.purchase_order_id : null}
                                                sendToList={this.AddToData.bind(this)}
                                                tabValue={this.state.tabValue ? this.state.tabValue : "one"}
                                                purchase_status={this.state.purchase_status}
                                                errorMessage={this.errorMessage.bind(this)}
                                                successMessage={this.successMessage.bind(this)}
                                                LoaderFunction={this.LoaderFunction.bind(this)}
                                                IsClearEdit={this.IsClearEdit.bind(this)}
                                                editWithin={this.state.editWithin}
                                                isAdmin={this.state.loggedInuserInfo?.is_branch_admin || this.state.loggedInuserInfo?.is_super_admin}
                                                RightDataClear={this.state.clearEditData}
                                                isFinished={this.state.isFinished}
                                                fromWanted={this.state.fromWanted}
                                                lftTblData={this.state.lftTblData}
                                            />
                                }
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <Loader loaderOpen={this.state.isLoader} />
                {states.openPopup ? this.renderWarningPopup() : null}
                {states.is_dc_popup ?
                    <CommonPopUp
                        width={"55vw"}
                        testID={"pur_drtpo_dc"}
                        title={t("Delivery Challan Details")}
                        buttonOne={t("Add To PO")}
                        buttonOneAction={this.addDCtoPO.bind(this)}
                        popUpClose={this.PopUpClose.bind(this)}
                        component={this.renderDCpopup.bind(this)}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isWarnMsg ?
                    <ToastMsg
                        severity={'warning'}
                        msg={this.state.isWarnMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.CancelPopClicked ? <DeletePopup
                    DeletTitle={`Are you sure, you want to cancel the Selected drugs ?`}
                    deleteAlertPopupClose={this.cancelPopclose.bind(this)}
                    removeData={this.CancelDrugItems.bind(this)}
                    ButtonText={"Cancel Drugs"}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaDirectPo);
