import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { AXIS_OPTIONS, CYL_OPTIONS, SPH_OPTIONS } from "../../../../Utility/Constants";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import ShowComponents from "./ShowComponent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DocAutoRefractometry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            appointmentDate: this.props.appointmentDate,
            isLocked: this.props.isLocked,
            LE_SPH: "",
            LE_CYL: "",
            LE_AXIS: "",
            BD_comments: "",
            RE_SPH: "",
            RE_CYL: "",
            RE_AXIS: "",
            AD_comments: "",
            postLoad: false,
            BeforeDilationRE: [],
            AfterDilationRE: [],
            BeforeDilationLE: [],
            AfterDilationLE: [],
            PreBeforeDilationRE: [],
            PreAfterDilationRE: [],
            PreBeforeDilationLE: [],
            PreAfterDilationLE: [],
            selectedEye: "RightEye",
            selectedTab: "BeforeDilation",
            getAvailableEyeTypesId: [],
            Leftchecked: false,
            Rightchecked: false,
            comparePopup: false,
            isEdit: false,
            SPHselectedValues: "Minus",
            CYLselectedValues: "Minus",
        }
        this.SPH_REF = React.createRef();
        this.CYL_REF = React.createRef();
        this.AXIS_REF = React.createRef();
    }

    apiCalls = () => {
        this.getAutoRefractometry()
        this.getPreAutoRefractometry()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getAutoRefractometry = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_AUTO_REFRACTOMETRY + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.data.auto_refractometry ? response.data.data.auto_refractometry : [];

                        var availableEyeId = []

                        for (var i = 0; i < data.length; i++) {
                            var prepareData = {
                                "eye_type": data[i].eye_type,
                                "id": data[i].id,
                                "dilation": data[i].is_before_dilation == true ? "BeforeDilation" : "AfterDilation"
                            }
                            availableEyeId.push(prepareData);
                        }

                        let { BeforeDilationRE, AfterDilationRE, BeforeDilationLE, AfterDilationLE } = this.state
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].is_before_dilation == true && data[i].eye_type == "RE") {
                                BeforeDilationRE.push(data[i])
                                this.setState({ BeforeDilationRE })
                            } else if (data[i].is_before_dilation == true && data[i].eye_type == "LE") {
                                BeforeDilationLE.push(data[i])
                                this.setState({ BeforeDilationLE })
                            } else if (data[i].is_before_dilation == false && data[i].eye_type == "RE") {
                                AfterDilationRE.push(data[i])
                                this.setState({ AfterDilationRE })
                            } else if (data[i].is_before_dilation == false && data[i].eye_type == "LE") {
                                AfterDilationLE.push(data[i])
                                this.setState({ AfterDilationLE })
                            }
                        }
                        this.setState({
                            getAvailableEyeTypesId: availableEyeId,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreAutoRefractometry = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_AUTO_REFRACTO_PREVIOUS + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.data ? response.data.data : [];

                        let { PreBeforeDilationRE, PreAfterDilationRE, PreBeforeDilationLE, PreAfterDilationLE } = this.state

                        for (var i = 0; i < data.length; i++) {
                            if (data[i].is_before_dilation == true && data[i].eye_type == "RE") {
                                PreBeforeDilationRE.push(data[i])
                                this.setState({ PreBeforeDilationRE })
                            } else if (data[i].is_before_dilation == true && data[i].eye_type == "LE") {
                                PreBeforeDilationLE.push(data[i])
                                this.setState({ PreBeforeDilationLE })
                            } else if (data[i].is_before_dilation == false && data[i].eye_type == "RE") {
                                PreAfterDilationRE.push(data[i])
                                this.setState({ PreAfterDilationRE })
                            } else if (data[i].is_before_dilation == false && data[i].eye_type == "LE") {
                                PreAfterDilationLE.push(data[i])
                                this.setState({ PreAfterDilationLE })
                            }
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item, Type) => {
        this.setState({
            SPHselectedValues : "Minus",
            CYLselectedValues : "Minus",
        })
        let states = this.state
        let data = states.getAvailableEyeTypesId
        for (var i = 0; i < data.length; i++) {
            if (data[i].eye_type == "LE" && data[i].dilation == "BeforeDilation") {
                states["BL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "BeforeDilation") {
                states["BR_ID"] = data[i].id
            } if (data[i].eye_type == "LE" && data[i].dilation == "AfterDilation") {
                states["AL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "AfterDilation") {
                states["AR_ID"] = data[i].id
            }
        }
        if (Type == "BD_LE") {
            states["LE_SPH"] = item?.SPH
            states["LE_CYL"] = item?.CYL
            states["LE_AXIS"] = item?.AXIS
            states["selectedTab"] = "BeforeDilation"
            states["selectedEye"] = "LeftEye"
            states["BD_comments"] = item?.comments
            this.setState({
                states, openPopUp: true, disableAfter: true, disableRight: true, isEdit: true
            })
        } else if (Type == "BD_RE") {
            states["RE_SPH"] = item?.SPH
            states["RE_CYL"] = item?.CYL
            states["RE_AXIS"] = item?.AXIS
            states["selectedTab"] = "BeforeDilation"
            states["selectedEye"] = "RightEye"
            states["BD_comments"] = item?.comments
            this.setState({
                states, openPopUp: true, disableAfter: true, disableLeft: true, isEdit: true
            })
        } else if (Type == "AD_LE") {
            states["LE_SPH"] = item?.SPH
            states["LE_CYL"] = item?.CYL
            states["LE_AXIS"] = item?.AXIS
            states["selectedTab"] = "AfterDilation"
            states["selectedEye"] = "LeftEye"
            states["AD_comments"] = item?.comments
            this.setState({
                states, openPopUp: true, disableBefore: true, disableRight: true, isEdit: true
            })
        } else if (Type == "AD_RE") {
            states["RE_SPH"] = item?.SPH
            states["RE_CYL"] = item?.CYL
            states["RE_AXIS"] = item?.AXIS
            states["selectedTab"] = "AfterDilation"
            states["selectedEye"] = "RightEye"
            states["AD_comments"] = item?.comments
            this.setState({
                states, openPopUp: true, disableBefore: true, disableLeft: true, isEdit: true
            })
        }
    }

    renderAutoRefractometryTable = () => {
        try {
            return (
                <Box component={'div'}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Before Dilation</Typography>
                        {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreBeforeDilationRE?.length > 0 || this.state.PreBeforeDilationLE?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.PreBeforeDilationRE?.length > 0 || this.state.PreBeforeDilationLE?.length > 0) && !this.props.isLocked) { this.CopyPreviousData("BD") } }}>
                                <Tooltip placement='top' title='Copy Previous Before Dilation'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.BeforeDilationRE?.length > 0 || this.state.BeforeDilationLE?.length > 0) && (this.state.PreBeforeDilationRE?.length > 0 || this.state.PreBeforeDilationLE?.length > 0)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.BeforeDilationRE?.length > 0 || this.state.BeforeDilationLE?.length > 0) && (this.state.PreBeforeDilationRE?.length > 0 || this.state.PreBeforeDilationLE?.length > 0) && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                <Tooltip placement='top' title='Compare'>
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                    <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.BeforeDilationRE?.length > 0 ? this.state.BeforeDilationRE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center'>
                                            <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                <Button
                                                    className='Common_Btn_Min_Width'
                                                    onClick={() => this.onEditHandler(item, "BD_RE")}
                                                    disabled={this.state.isLocked || this.props.isLocked}
                                                >
                                                    {this.state.isLocked || this.props.isLocked ?
                                                        <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                        :
                                                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                    }
                                                </Button>
                                            </Box>
                                        </TableCell>}
                                    </TableRow>
                                )) : null}
                                {this.state.BeforeDilationLE?.length > 0 ? this.state.BeforeDilationLE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center'>
                                            <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                <Button
                                                    className='Common_Btn_Min_Width'
                                                    onClick={() => this.onEditHandler(item, "BD_LE")}
                                                    disabled={this.state.isLocked || this.props.isLocked}
                                                >
                                                    {this.state.isLocked || this.props.isLocked ?
                                                        <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                        :
                                                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                    }
                                                </Button>
                                            </Box>
                                        </TableCell>}
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography mt={"0.5vw"} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>After Dilation</Typography>
                        {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreAfterDilationRE?.length > 0 || this.state.PreAfterDilationLE?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.PreAfterDilationRE?.length > 0 || this.state.PreAfterDilationLE?.length > 0) && !this.props.isLocked) { this.CopyPreviousData("AD") } }}>
                                <Tooltip placement='top' title='Copy Previous After Dilation'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.AfterDilationRE?.length > 0 || this.state.AfterDilationLE?.length > 0) && (this.state.PreAfterDilationRE?.length > 0 || this.state.PreAfterDilationLE?.length > 0)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.AfterDilationRE?.length > 0 || this.state.AfterDilationLE?.length > 0) && (this.state.PreAfterDilationRE?.length > 0 || this.state.PreAfterDilationLE?.length > 0) && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                <Tooltip placement='top' title='Compare'>
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                    <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.AfterDilationRE?.length > 0 ? this.state.AfterDilationRE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center'>
                                            <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                <Button
                                                    className='Common_Btn_Min_Width'
                                                    onClick={() => this.onEditHandler(item, "AD_RE")}
                                                    disabled={this.state.isLocked || this.props.isLocked}
                                                >
                                                    {this.state.isLocked || this.props.isLocked ?
                                                        <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                        :
                                                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                    }
                                                </Button>
                                            </Box>
                                        </TableCell>}
                                    </TableRow>
                                )) : null}
                                {this.state.AfterDilationLE?.length > 0 ? this.state.AfterDilationLE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center'>
                                            <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                <Button
                                                    className='Common_Btn_Min_Width'
                                                    onClick={() => this.onEditHandler(item, "AD_LE")}
                                                    disabled={this.state.isLocked || this.props.isLocked}
                                                >
                                                    {this.state.isLocked || this.props.isLocked ?
                                                        <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                        :
                                                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                    }
                                                </Button>
                                            </Box>
                                        </TableCell>}
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box component={'div'}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Before Dilation</Typography>
                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData("BD") }}>
                            <Tooltip placement='top' title='Copy'>
                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                            </Tooltip>
                        </div>
                    </div>
                    <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.PreBeforeDilationRE?.length > 0 ? this.state.PreBeforeDilationRE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    </TableRow>
                                )) : null}
                                {this.state.PreBeforeDilationLE?.length > 0 ? this.state.PreBeforeDilationLE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography mt={"0.5vw"} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>After Dilation</Typography>
                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw" }} onClick={() => { this.CopyPreviousData("AD") }}>
                            <Tooltip placement='top' title='Copy'>
                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                            </Tooltip>
                        </div>
                    </div>
                    <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                    <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.PreAfterDilationRE?.length > 0 ? this.state.PreAfterDilationRE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    </TableRow>
                                )) : null}
                                {this.state.PreAfterDilationLE?.length > 0 ? this.state.PreAfterDilationLE?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                        <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        var states = this.state
        if ((states.LE_SPH == "" && states.LE_CYL == "" && states.LE_AXIS == "") &&
            (states.RE_SPH == "" && states.RE_CYL == "" && states.RE_AXIS == "")) {
            return "Select Any Eye Value"
        } else {
            return null
        }
    }

    PostAutoRefractometry = () => {
        let states = this.state
        let { BL_ID, BR_ID, AL_ID, AR_ID } = this.state
        var data = {}
        try {
            if (
                (states.LE_SPH != "" || states.LE_CYL != "" || states.LE_AXIS != "") &&
                (states.RE_SPH != "" || states.RE_CYL != "" || states.RE_AXIS != "")
            ) {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": 'RE',
                        "SPH": states.RE_SPH ? states.RE_SPH : "",
                        "CYL": states.RE_CYL ? states.RE_CYL : "",
                        "AXIS": states.RE_AXIS ? states.RE_AXIS : "",
                        "comments": this.state.selectedTab === "BeforeDilation" ? states.BD_comments : states.AD_comments,
                        "is_before_dilation": this.state.selectedTab === "BeforeDilation" ? true : false
                    },
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": 'LE',
                        'SPH': states.LE_SPH ? states.LE_SPH : '',
                        'CYL': states.LE_CYL ? states.LE_CYL : '',
                        'AXIS': states.LE_AXIS ? states.LE_AXIS : '',
                        "comments": this.state.selectedTab === "BeforeDilation" ? states.BD_comments : states.AD_comments,
                        "is_before_dilation": this.state.selectedTab === "BeforeDilation" ? true : false
                    }
                ]

                if (BR_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BR_ID; }
                else if (AR_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AR_ID; }
                if (BL_ID && states.selectedTab === "BeforeDilation") { data[1]["id"] = BL_ID; }
                else if (AL_ID && states.selectedTab != "BeforeDilation") { data[1]["id"] = AL_ID; }
            }
            else if (states.RE_SPH != "" || states.RE_CYL != "" || states.RE_AXIS != "") {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": 'RE',
                        "SPH": states.RE_SPH ? states.RE_SPH : "",
                        "CYL": states.RE_CYL ? states.RE_CYL : "",
                        "AXIS": states.RE_AXIS ? states.RE_AXIS : "",
                        "comments": this.state.selectedTab === "BeforeDilation" ? states.BD_comments : states.AD_comments,
                        "is_before_dilation": this.state.selectedTab === "BeforeDilation" ? true : false
                    }
                ]
                if (BR_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BR_ID; }
                else if (AR_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AR_ID; }
            }
            else if (states.LE_SPH != "" || states.LE_CYL != "" || states.LE_AXIS != "") {
                data = [
                    {
                        "appointment_id": states.appointmentId,
                        "eye_type": 'LE',
                        'SPH': states.LE_SPH ? states.LE_SPH : '',
                        'CYL': states.LE_CYL ? states.LE_CYL : '',
                        'AXIS': states.LE_AXIS ? states.LE_AXIS : '',
                        "comments": this.state.selectedTab === "BeforeDilation" ? states.BD_comments : states.AD_comments,
                        "is_before_dilation": this.state.selectedTab === "BeforeDilation" ? true : false
                    }
                ]
                if (BL_ID && states.selectedTab === "BeforeDilation") { data[0]["id"] = BL_ID; }
                else if (AL_ID && states.selectedTab != "BeforeDilation") { data[0]["id"] = AL_ID; }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_AUTO_REFRACTOMETRY)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({
                                postLoad: false, openPopUp: false, BL_ID: "", BR_ID: "", AL_ID: "", AR_ID: "", comparePopup: false,
                                BeforeDilationRE: [], AfterDilationRE: [], BeforeDilationLE: [], AfterDilationLE: [], isEdit: false
                            }, () => {
                                this.getAutoRefractometry()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = (type) => {
        let states = this.state
        let data = states.getAvailableEyeTypesId
        let { PreBeforeDilationRE, PreAfterDilationRE, PreBeforeDilationLE, PreAfterDilationLE } = this.state
        for (var i = 0; i < data.length; i++) {
            if (data[i].eye_type == "LE" && data[i].dilation == "BeforeDilation") {
                states["BL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "BeforeDilation") {
                states["BR_ID"] = data[i].id
            } if (data[i].eye_type == "LE" && data[i].dilation == "AfterDilation") {
                states["AL_ID"] = data[i].id
            } if (data[i].eye_type == "RE" && data[i].dilation == "AfterDilation") {
                states["AR_ID"] = data[i].id
            }
        }
        if (type == "BD") {
            this.setState({
                states,
                LE_SPH: PreBeforeDilationLE[0]?.SPH ? PreBeforeDilationLE[0]?.SPH : "",
                LE_CYL: PreBeforeDilationLE[0]?.CYL ? PreBeforeDilationLE[0]?.CYL : "",
                LE_AXIS: PreBeforeDilationLE[0]?.AXIS ? PreBeforeDilationLE[0]?.AXIS : "",
                RE_SPH: PreBeforeDilationRE[0]?.SPH ? PreBeforeDilationRE[0]?.SPH : "",
                RE_CYL: PreBeforeDilationRE[0]?.CYL ? PreBeforeDilationRE[0]?.CYL : "",
                RE_AXIS: PreBeforeDilationRE[0]?.AXIS ? PreBeforeDilationRE[0]?.AXIS : "",
                selectedTab: "BeforeDilation"
            }, () => { this.PostAutoRefractometry() })
        } else {
            this.setState({
                states,
                LE_SPH: PreAfterDilationLE[0]?.SPH ? PreAfterDilationLE[0]?.SPH : "",
                LE_CYL: PreAfterDilationLE[0]?.CYL ? PreAfterDilationLE[0]?.CYL : "",
                LE_AXIS: PreAfterDilationLE[0]?.AXIS ? PreAfterDilationLE[0]?.AXIS : "",
                RE_SPH: PreAfterDilationRE[0]?.SPH ? PreAfterDilationRE[0]?.SPH : "",
                RE_CYL: PreAfterDilationRE[0]?.CYL ? PreAfterDilationRE[0]?.CYL : "",
                RE_AXIS: PreAfterDilationRE[0]?.AXIS ? PreAfterDilationRE[0]?.AXIS : "",
                selectedTab: "AfterDilation"
            }, () => { this.PostAutoRefractometry() })
        }
    }
  
    renderGlassPower = (Heading, values, key, Tab, Eye, ref, scrollTo) => {
        let states = this.state
        let sortedvalue = values
        let { SPHselectedValues, CYLselectedValues } = this.state
        if(Heading == "SPH"){
            if(SPHselectedValues != "All"){
                sortedvalue = sortedvalue.filter((item) => SPHselectedValues === "plus"  ? item?.value >= 0 : SPHselectedValues  === "Minus" ? item?.value <= 0 : sortedvalue  )
            }
        }
        else if(Heading == "CYL"){
            if(CYLselectedValues != "All"){
                sortedvalue = sortedvalue.filter((item) => CYLselectedValues === "plus"  ? item?.value >= 0 :  CYLselectedValues  === "Minus" ? item?.value <= 0 : sortedvalue)
            }
        }
        
        return (
            <div>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <Box component={'div'} sx={{ display: "flex", flexWrap: "wrap", marginLeft: "1vw" }}>
                        {
                           ( Heading === "SPH" ||   Heading === "CYL") ?
                                <RadioGroup
                                    value={ Heading === "SPH"  ? this.state.SPHselectedValues  : this.state.CYLselectedValues}
                                    sx={{ display: 'flex', flexDirection: 'row' , marginTop :"-4vh", marginLeft :"2vw" }}
                                    onChange={(e, values) => {
                                        if(Heading === "SPH"){
                                            this.setState({ SPHselectedValues: e.target.value })
                                        }
                                        else if(Heading === "CYL"){
                                            this.setState({ CYLselectedValues: e.target.value })
                                        }
                                    }}
                                >
                                    {/* <FormControlLabel control={<Radio inputProps = {{emed_tid : "All_testID"}} size='small' value={"All"}  />} label="All" /> */}
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "plus_testID"}} size='small' value={"plus"}  />} label="+" />
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "Minus_testID"}} size='small' value={"Minus"} />} label="-" />
                                </RadioGroup> : 
                               null
                        }
                    </Box>
                <div   ref={ref} style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        sortedvalue && sortedvalue.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "4vw" }}
                                label={item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (this.state.selectedEye != null) {
                                        if (item.label === key) {
                                            states[Tab] = ""
                                            this.setState({ states })
                                        } else {
                                            states[Tab] = item?.value
                                            this.setState({ states })
                                        }
                                        if (Eye == "LeftEye" && this.state.Leftchecked) {
                                            this.CopyLefttoRight(!this.state.Leftchecked)
                                        } else if (Eye == "RightEye" && this.state.Rightchecked) {
                                            this.CopyRighttoLeft(!this.state.Rightchecked)
                                        }
                                    } else {
                                        this.errorMessage("Select Any Eye Type")
                                    }
                                    this.scrollToRef(scrollTo)
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    renderRightSideValues = (Heading, value, optionData = [], stateKey, filterState = '') => {
        let states = this.state
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <Autocomplete
                    inputProps={{ maxLength: 250 }}
                    sx={{ width: '13.5dvw' }}
                    value={value ? value : ''}
                    options={optionData}
                    getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.label}
                    onChange={(e, newValue) => {
                        states[stateKey] = newValue?.value
                        if (newValue?.value?.includes("+") && filterState !== "" && states[filterState] !== "All") {
                            states[filterState] = 'plus'
                        } else if (newValue?.value?.includes("-") && filterState !== "" && states[filterState] !== "All") {
                            states[filterState] = 'Minus'
                        }
                        this.setState({
                            states
                        })
                    }}
                    clearIcon={false}
                    size='small'
                    renderInput={(params) => <TextField {...params} label={Heading} />}
                />
            </div>
        )
    }

    CopyLefttoRight = (value) => {
        let states = this.state
        if (value) {
            states.RE_SPH = states.LE_SPH
            states.RE_CYL = states.LE_CYL
            states.RE_AXIS = states.LE_AXIS
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                selectedEye: this.state?.isEdit ? "LeftEye" : "RightEye",
                states
            })
        } else {
            states.RE_SPH = ""
            states.RE_CYL = ""
            states.RE_AXIS = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let states = this.state
        if (value) {
            states.LE_SPH = states.RE_SPH
            states.LE_CYL = states.RE_CYL
            states.LE_AXIS = states.RE_AXIS
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                selectedEye: this.state?.isEdit ? "RightEye" : "LeftEye",
                states
            })
        } else {
            states.LE_SPH = ""
            states.LE_CYL = ""
            states.LE_AXIS = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    handleClear = () => {
        this.setState({
            LE_SPH: "",
            LE_CYL: "",
            LE_AXIS: "",
            BD_comments: "",
            RE_SPH: "",
            RE_CYL: "",
            RE_AXIS: "",
            AD_comments: "",
            selectedEye: "RightEye",
            selectedTab: "BeforeDilation",
            Leftchecked: false,
            Rightchecked: false,
            disableAfter: false,
            disableBefore: false,
            disableRight: false,
            disableLeft: false
        })
    }

    renderBottomArea = (Title, value) => {
        return (
            <div style={{ marginRight: "0.5vw", width: "12vw", marginLeft: "0.5vw" }}>
                <div>{Title}</div>
                {value && value?.length > 15 ?
                    <Tooltip placement='top' title={value}><div style={{ fontWeight: "600", color: "black" }}>{value?.slice(0, 15) + '...'}</div></Tooltip> :
                    <div style={{ fontWeight: "600", color: "black", fontSize: "0.9vw" }}>{value ? value : "-"}</div>
                }
            </div>
        )
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "95vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Auto Refractometry</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Auto Refractometry</Typography>
                                    </div>
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Auto Refractometry</Typography>
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>{this.renderAutoRefractometryTable()}</div>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    AutoRefractometryPopup = () => {
        let states = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "90vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Auto Refractometry</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, isEdit: false }, () => { this.handleClear() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Box sx={{ marginLeft: "0.5vw" }}>
                                        <Button size='small' disabled={this.state.disableBefore} onClick={() => { this.setState({ selectedTab: "BeforeDilation" , SPHselectedValues:"Minus" ,CYLselectedValues:"Minus" }) }} sx={{ color: this.state.selectedTab === "BeforeDilation" ? Colors.themeDark : Colors.disableLabelColor, textTransform: 'capitalize', fontSize: "1vw" }}>Before Dilation</Button>
                                        <Button size='small' disabled={this.state.disableAfter} onClick={() => { this.setState({ selectedTab: "AfterDilation" , CYLselectedValues:"Minus" , SPHselectedValues:"Minus"}) }} sx={{ color: this.state.selectedTab === "AfterDilation" ? Colors.themeDark : Colors.disableLabelColor, textTransform: 'capitalize', fontSize: "1vw", marginLeft: "0.5vw" }}>After Dilation</Button>
                                    </Box>
                                    <Box sx={{ marginLeft: "1vw", marginTop: "0.5vw" }}>
                                        <Button size='small' disabled={this.state.disableRight} variant={this.state.selectedEye == "RightEye" ? "contained" : "outlined"} onClick={() => { this.setState({ selectedEye: "RightEye" }, () => this.scrollToRef(this.SPH_REF)) }} style={{ textTransform: 'capitalize' }} >{"Right Eye (OD)"}</Button>
                                        <Button size='small' disabled={this.state.disableLeft} variant={this.state.selectedEye == "LeftEye" ? "contained" : "outlined"} onClick={() => { this.setState({ selectedEye: "LeftEye" }, () => this.scrollToRef(this.SPH_REF)) }} style={{ textTransform: 'capitalize', marginLeft: "0.65vw" }} >{"Left Eye (OS)"}</Button>
                                    </Box>
                                    {
                                        this.state.selectedEye == "LeftEye" ?
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "65vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("SPH", SPH_OPTIONS, states.LE_SPH, "LE_SPH", "LeftEye", this.SPH_REF, this.CYL_REF)}
                                                        {this.renderGlassPower("CYL", CYL_OPTIONS, states.LE_CYL, "LE_CYL", "LeftEye", this.CYL_REF, this.AXIS_REF)}
                                                        {this.renderGlassPower("AXIS", AXIS_OPTIONS, states.LE_AXIS, "LE_AXIS", "LeftEye", this.AXIS_REF, this.AXIS_REF)}
                                                    </div>
                                                </Box>
                                            </Box>
                                            :
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "65vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("SPH", SPH_OPTIONS, states.RE_SPH, "RE_SPH", "RightEye", this.SPH_REF, this.CYL_REF)}
                                                        {this.renderGlassPower("CYL", CYL_OPTIONS, states.RE_CYL, "RE_CYL", "RightEye", this.CYL_REF, this.AXIS_REF)}
                                                        {this.renderGlassPower("AXIS", AXIS_OPTIONS, states.RE_AXIS, "RE_AXIS", "RightEye", this.AXIS_REF, this.AXIS_REF)}
                                                    </div>
                                                </Box>
                                            </Box>
                                    }
                                </div>
                                <div style={{ width: "40%" }}>
                                    <Box component={'div'} style={{ marginTop: "5.5vw", marginLeft: "1vw", height: "50vh", overflow: "scroll" }}>
                                        <div style={{ fontWeight: "600", marginBottom: "0.75vw", marginTop: "0.5vw" }}>{this.state.selectedTab === "BeforeDilation" ? "Before Dilation" : "After Dilation"}</div>
                                        {this.state.selectedEye == "LeftEye" ?
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("SPH", states.LE_SPH, SPH_OPTIONS, "LE_SPH", "SPHselectedValues")}
                                                    {this.renderRightSideValues("CYL", states.LE_CYL, CYL_OPTIONS, "LE_CYL", "CYLselectedValues")}
                                                    {this.renderRightSideValues("AXIS", states.LE_AXIS, AXIS_OPTIONS, "LE_AXIS", "")}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                                </Box>
                                            </div> :
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("SPH", states.RE_SPH, SPH_OPTIONS, "RE_SPH", "SPHselectedValues")}
                                                    {this.renderRightSideValues("CYL", states.RE_CYL, CYL_OPTIONS, "RE_CYL", "CYLselectedValues")}
                                                    {this.renderRightSideValues("AXIS", states.RE_AXIS, AXIS_OPTIONS, "RE_AXIS", "")}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                                </Box>
                                            </div>}
                                        <div style={{ marginTop: "1vw" }}>
                                            <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{"Comments"}</div>
                                            <div >
                                                <TextField
                                                    label={("Enter Comments")}
                                                    style={{ height: "5vw", marginTop: "0.5vw", width: "95%" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.selectedTab === "BeforeDilation" ? states.BD_comments : states.AD_comments}
                                                    onChange={(event) => {
                                                        var value = event.target.value
                                                        if (this.state.selectedTab === "BeforeDilation") {
                                                            if (value?.length <= 250) {
                                                                states["BD_comments"] = value
                                                                this.setState({
                                                                    states
                                                                })
                                                            } else { this.errorMessage('Allowed only 250 characters') }
                                                        } else {
                                                            if (value?.length <= 250) {
                                                                states["AD_comments"] = value
                                                                this.setState({
                                                                    states
                                                                })
                                                            }
                                                            else { this.errorMessage('Allowed only 250 characters') }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostAutoRefractometry() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    render() {
        let states = this.state
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Auto Refractometry</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: "0.65vw" }}>
                                    <Button
                                        disabled={this.state.isLocked || this.props.isLocked || (states?.BeforeDilationRE.length > 0 && states?.BeforeDilationLE.length > 0 && states?.AfterDilationRE.length > 0 && states?.AfterDilationLE.length > 0)}
                                        variant='contained'
                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                        onClick={() => {
                                            this.setState({ openPopUp: true , SPHselectedValues : "Minus" , CYLselectedValues : "Minus"})
                                        }}
                                    >Add</Button>
                                </Box>
                                <Box component={'div'}>
                                    {this.renderAutoRefractometryTable()}
                                </Box>
                            </Box>
                            <Box component={'div'} p={'0.5vw'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                {this.state.BD_comments ? this.renderBottomArea("RE - Comments", this.state.BD_comments) : null}
                                {this.state.AD_comments ? this.renderBottomArea("LE - Comments", this.state.AD_comments) : null}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.AutoRefractometryPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }

}