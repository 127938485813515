import React, { Component } from 'react'
import './Config.css';
import { Button, Stack, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Tooltip, Box, InputAdornment, FormControl, Select, InputLabel, MenuItem, RadioGroup, FormControlLabel, Radio, Typography, Modal } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc, getLabS3Storage } from '../../../Components/CommonFunctions/CommonFunctions';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { Bucket_Name } from '../../../Utility/Constants';
import CircularProgress from '@mui/material/CircularProgress';

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    signatureVersion: 'v4',
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1",
    params: { Bucket: Bucket_Name },
    Effect: "Allow",
});

const TypesOfRole = [
    { RoleName: "Lab-Admin", value: "Lab Admin" },
    { RoleName: "Lab-Technican", value: "Lab Technican" },
    { RoleName: "Pathologist", value: "Pathologist" },
    { RoleName: "Microbiologist", value: "Microbiologist" }
]

class SignatureConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            selectedIndex: '',
            SearchText: "",
            SelectedRole: "",
            SelectedName: "",
            SelectedStatus: true,
            imgUpload: [],
            SelectedImage: null,
            isImgClicked: false,
            selectedEditID: '',
            EsignatureList: [],
            deletedapiID: "",
            Lab_ID: null,
            S3bucketsize: 0,
            src: null,
            crop: {
                unit: "%",
                x: 22.5,
                y: 30,
                width: 60,
                height: 25,
                aspect: 16 / 9,
            },
            CropPop: false,
            FileName: "",
            selectedData: "",
            rotation: 0,
            imageURL: "",
            removeData: false,
            files: [],
            isLoader: false,
            disableBtn: false,
        }
        this.uploadImage = React.createRef();
    }

    componentDidMount() {
        this.getSignatureData()
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            Lab_ID: myUser?.laboratory_id
        })
        let S3bucketsize = getLabS3Storage(this.state.Lab_ID);
        S3bucketsize.then((val) => { 
            this.setState({
                S3bucketsize: val
            })
            return val;
        });
    }
    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getSignatureData = () => {
        try {
            this.LoaderFunction(true)
            this.setState({isLoader: true})
            RestAPIService.getAll(Serviceurls.LAB_E_SIGNATURE_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ EsignatureList: response.data.data, isLoader: false, }
                            , () => { if(response.data?.data?.length > 0){
                                this.getAllFilesfromS3()
                                this.LoaderFunction(false)
                            } })
                    }
                }).catch((e) => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message,
                        isLoader: false,
                    })
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                isLoader: false,
            })
        }
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    postSignatureData = (filePath) => {
        var states = this.state
        let data = {
            "name": states.SelectedName ? states.SelectedName : "",
            "role": states.SelectedRole ? states.SelectedRole : "",
            "status": states.SelectedStatus ? JSON.parse(states.SelectedStatus) : "",
            "e_sign": filePath,
        }

        if (this.state.selectedEditID) {
            data['id'] = states.selectedEditID ? states.selectedEditID : null
        }
        this.setState({isLoader: true})
        try {
            RestAPIService.create(data, Serviceurls.LAB_E_SIGNATURE_CONFIG)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message,
                            isLoader: false,
                        }, () => {
                            this.getSignatureData()
                            this.clearValue()
                            this.getAllFilesfromS3()
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        isLoader: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                isLoader: false,
            })
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        })
    }


    editCultureTestData = (item, index) => {
        let Upload = []
        let val = this.state.files.filter(list => list.Key === item.e_sign)
        Upload.push(val[0]?.download_Url)
        this.setState({
            imgUpload: []
        }, () => {
            this.setState({
                selectedIndex: index,
                SelectedRole: item?.role,
                SelectedName: item?.name,
                SelectedStatus: item?.status,
                selectedEditID: item?.id,
                imgUpload: Upload,
                imageURL: item?.e_sign
            })
        })
    }

    deleteCultureData = (ID) => {
        try {
            RestAPIService.deleteAll(Serviceurls.LAB_E_SIGNATURE_CONFIG + "?id=" + ID)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.setState({
                                selectedKey: null,
                                deletePopup: false
                            }, () => { this.getAllFilesfromS3() })
                            this.getSignatureData()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    clearValue = () => {
        this.setState({
            SearchText: "",
            SelectedRole: "",
            SelectedName: "",
            SelectedStatus: true,
            imgUpload: [],
            SelectedImage: null,
            isImgClicked: false,
            selectedEditID: '',
            selectedIndex: '',
            FileName: "",
            selectedData: "",
            // files: []
        })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }

    removeDataList = () => {
        this.deleteObject(this.state.selectedData)
        this.setState({
            deletedPopup: false
        })
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        const filtereddata = this.state.EsignatureList?.filter((data) => {
            return (
                (data.role === null ? null : data.role.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.name === null ? null : data.name.toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                    <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Role")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Name")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Status")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.isLoader ? 
                            <Box sx={{display: 'flex', justifyContent: 'center', position: 'relative', left: '22vw', top: '14vw'}}>
                            <CircularProgress color='primary' />
                        </Box>
                        : filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    return (
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                                                <TableCell align='left'><div>{sno}</div></TableCell>
                                                <TableCell align='left'><div>{data?.role}</div></TableCell>
                                                <TableCell align='left'><div>{data?.name}</div></TableCell>
                                                <TableCell align='left'><div>{data.status == true ? "Active" : "Inactive"}</div></TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small' disabled={!CheckAccessFunc("Laboratory", "Configuration", "Lab E-Signature Configuration", null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Lab E-Signature Configuration", null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} 
                                                            className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editCultureTestData(data, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton size='small' disabled={!CheckAccessFunc("Laboratory", "Configuration", "Lab E-Signature Configuration", null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Lab E-Signature Configuration", null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} 
                                                            className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({ deletedPopup: true, deletedapiID: data.id, selectedData: data })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata?.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsShown')}
                            </div>
                            : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={10}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }

    onImageRemove = (index) => {
        let { imgUpload } = this.state;
        imgUpload.splice(index, 1)
        this.setState({
            imgUpload: this.state.imgUpload,
        })
    }

    UploadcroppedImg = () => {
        let blob = this.state.croppedImageUrl;
        let NewFile = []
        fetch(blob)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], this.state.FileName, { type: blob.type });
                NewFile.push(file)
                this.setState({
                    imgUpload: NewFile,
                    crop: {
                        unit: "%",
                        x: 22.5,
                        y: 30,
                        width: 60,
                        height: 25,
                        aspect: 16 / 9,
                    }
                })
            })
            .catch(error => console.error('Error fetching blob:', error));
    }

    handleChange = async (e) => {
        // const imageList = [];
        // let imageFiles = [...e.target.files];
        // let clearedArr = imageFiles?.filter((image, i) => {
        //     let file = image?.name;
        //     return String(file).toLowerCase() !== String(imageFiles[i + 1]?.name).toLowerCase()
        // });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result, CropPop: true })
            );
            this.setState({ FileName: e.target.files[0]?.name })
            reader.readAsDataURL(e.target.files[0]);
        }
        this.uploadImage.current.value = null;
    }

    s3FileUpload = async (item = []) => {
        try {
            if (item?.length > 0) {
                for (let i = 0; i < item?.length; i++) {
                    let totalSize = 0;
                    var s3allocatedStorage = this.state.S3limit;
                    totalSize = this.state.S3bucketsize
                    if (totalSize >= s3allocatedStorage) {
                        this.errorMessage("Your allocated Storage space has filled.")
                    } else {
                        let fileName = item[i].name
                        let filePath = Bucket_Name + "/" + "lab/" + this.state.Lab_ID + "/" + fileName;
                        let params = {
                            Key: filePath,
                            Body: item[i],
                            ACL: "public-read",
                        }
                        this.setState({ loading: true, disableBtn: true })
                        s3.upload(params, (err, data) => {
                            if (err) {
                                this.errorMessage(err.stack)
                                this.setState({ loading: false, disableBtn: false })
                            } else {
                                let fileName = item[i].name ? (Bucket_Name + "/" + "lab/" + this.state.Lab_ID + "/" + item[i].name) : this.state.imageURL
                                this.successMessage('File uploaded successfully')
                                this.postSignatureData(fileName)
                                this.setState({ loading: false, imageuploadList: [], disableBtn: false })
                            }
                        })
                    }
                }
            } else {
                this.errorMessage('Please,Add atleast one file')
                this.setState({disableBtn: false})
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteObject = async (item) => {
        try {
            let params = {
                Bucket: Bucket_Name,
                Key: item?.e_sign
            }
            this.setState({ removeData: true })
            await s3.deleteObject(params, (err, data) => {
                if (err) {
                    this.setState({ removeData: false })
                } else {
                    this.setState({ removeData: false })
                    this.deleteCultureData(this.state.deletedapiID)
                }
            })
            // .promise().catch(error => {
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: error.message,
            //         removeData: false
            //     })
            // });

        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getAllFilesfromS3 = async () => {
        try {
            let ContinuationToken;
            let params = {
                Bucket: Bucket_Name,
                Prefix: `${Bucket_Name}/lab/${this.state.Lab_ID}/`, ContinuationToken
                // Prefix: `https://emedhub-stage.s3.ap-south-1.amazonaws.com/${path}/`, ContinuationToken
            };
            do {
                let s3Data = await s3.listObjectsV2(params).promise().catch(error => {
                })
                if (s3Data?.Contents?.length > 0) {
                    let filesData = [];
                    s3Data?.Contents?.forEach(element => {
                        filesData?.push({
                            Key: element.Key,
                            download_Url: s3.getSignedUrl('getObject', { Key: element.Key }),
                            LastModified: element?.LastModified
                        })
                    })
                    this.setState({
                        files: filesData
                    })
                }
                ContinuationToken = s3Data?.NextContinuationToken
            } while (ContinuationToken)
        } catch (error) {
            this.errorMessage('Error', error.message)
        }
    }

    renderImgCard = () => {
        const { t } = this.props;
        let imageListLen = this.state.imgUpload.length;
        let { imageURL } = this.state
        return (
            <Stack className="eMed_DocRepo_imgDiv" justifyContent="center" alignItems="center" sx={{ backgroundColor: Colors.SecondaryFill }}>
                <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={this.handleChange}
                    ref={this.uploadImage}
                    style={{ display: "none" }}
                />
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                    <Stack className="eMed_DocRepo_imgSubDiv" justifyContent="center" alignItems="center" spacing={0.5}>
                        <IconButton size="small" disabled={imageListLen === 6 ? true : false} onClick={() => this.uploadImage.current.click()}><img id="eMed_upload_img" src={imageListLen === 6 ? ImagePaths.LabUploadButton.default : ImagePaths.Export.default} alt="Upload" /></IconButton>
                        <Typography id="eMed_img_lblTxt" >{imageListLen === 6 ? t("MaxUpload") : t("uploadFile")}</Typography>
                        {imageListLen === 6 ? null : <Typography id="eMed_img_subTxt" sx={{ marginLeft: "0.5vw" }}>{t("imgFormat")}</Typography>}
                    </Stack>
                    {
                        imageListLen > 0 ?
                            <Stack className="eMed_Testrep_Img_item" direction="row" justifyContent={imageListLen <= 5 ? "center" : "flex-start"} alignItems="center">
                                {imageListLen > 0 && this.state.imgUpload.map((image, index) => (
                                    <div key={index} className="eMed_Testrep_Img" style={{ marginRight: "0.5vw" }}>
                                        <Tooltip title={typeof (image) === "string" ? null : image?.name} arrow placement='top'>
                                            <img
                                                onClick={() => {
                                                    if (typeof (image) === "string") {
                                                        if (imageURL.split('.').pop() === "jpg" || imageURL.split('.').pop() === "jpeg" || imageURL.split('.').pop() === "JPG" || imageURL.split('.').pop() === "png") {
                                                            this.setState({ isImgClicked: true, popUpOpen: true, SelectedImage: image })
                                                        }
                                                        else {
                                                            window.open(image)
                                                        }
                                                    } else {
                                                        this.setState({ isImgClicked: true, popUpOpen: true, SelectedImage: URL.createObjectURL(image) })
                                                    }
                                                }}
                                                src={ImagePaths.ImagePreview.default} className='eMed_Testrep_Img' />
                                        </Tooltip>
                                        <div><IconButton id="eMed_Testrep_Icon" size="small"
                                            onClick={() => {
                                                this.setState({ SelectedImage: null }, () => { this.onImageRemove(index) });
                                            }}>
                                            <CancelTwoToneIcon fontSize='small' color="error" />
                                        </IconButton></div>
                                    </div>
                                ))}
                            </Stack>
                            : null
                    }
                </Stack>
            </Stack>
        )
    }

    rotateImage = (degrees) => {
        this.setState({
            rotation: (this.state.rotation + degrees) % 360
        })
    };

    handleClose = () => {
        this.setState({
            imgUpload: [],
            SelectedImage: null,
            isImgClicked: false,
            selectedEditID: '',
            selectedIndex: '',
            FileName: "",
            selectedData: "",
            CropPop: false,
            crop: {
                unit: "%",
                x: 22.5,
                y: 30,
                width: 60,
                height: 25,
                aspect: 16 / 9,
            }
        })
    }

    previewPopup = () => {
        return (
            <Box>
                <Modal open={true}>
                    <Paper elevation={3} id="emedhub_vendorDetails_mainBox" style={{ width: "40vw", height: "70vh" }}>
                        <div id="emedhub_vendorDetails_header">
                            <Typography>{"Image Preview"}</Typography>
                            <IconButton onClick={() => { this.setState({ popUpOpen: false }) }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        </div>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "90%" }}>
                            <img src={this.state.SelectedImage} alt='Image Preview' style={{ width: "45%", height: "45%" }} />
                        </Box>
                    </Paper>
                </Modal>
            </Box>
        )
    }

    OpenCropPopUP = () => {
        const { crop, croppedImageUrl, src } = this.state;
        try {
            return (
                <Box>
                    <Modal open={true}>
                        <Paper elevation={3} id="emedhub_vendorDetails_mainBox" style={{ width: "60vw", height: "80vh" }}>
                            <div id="emedhub_vendorDetails_header">
                                <Typography>{"Crop Signature"}</Typography>
                                <IconButton onClick={this.handleClose} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                            </div>
                            <Box component={'div'} style={{ marginTop: "0.75vw", height: "25vw" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: "60%", height: "100%", marginTop: "1.5vw", marginLeft: "1.5vw" }}>
                                        {src && (
                                            <ReactCrop
                                                style={{ height: "50%", width: "62%" }}
                                                src={src}
                                                crop={crop}
                                                ruleOfThirds
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        )}
                                    </div>
                                    <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "-1.5vw", marginRight: "2.5vw" }}>
                                        {croppedImageUrl && (
                                            <img alt="Crop" style={{ width: "75%", height: "45%", transform: `rotate(${this.state.rotation}deg)` }} src={croppedImageUrl} />
                                        )}
                                    </div>
                                </div>
                            </Box>
                            <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ CropPop: false }, () => { this.UploadcroppedImg() }) }}>OK</Button>
                            </Box>
                        </Paper>
                    </Modal>
                </Box>
            );
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.FileName,
                this.state.rotation
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    validation = () => {
        let { SelectedName, SelectedRole, imgUpload } = this.state
        if (SelectedName !== "" && SelectedRole !== "") {
            this.s3FileUpload(imgUpload)
        } else {
            if (SelectedName == "") {
                this.errorMessage("Please enter the name")
            } else if (SelectedRole == "") {
                this.errorMessage("Please select the role")
            }
        }
    }


    render() {  
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead' style={{ height: "5.5vh" }}>
                            <p className='eMed_Testconfig_RightHead_text'>{t('Lab E-Signature')}</p>
                        </div>
                        <div className='eMed_CulturalConfig_RightContentBox'>
                            <div style={{ display: "flex", flexDirection: "row", padding: "1vw", justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl size='small' style={{ width: "14vw" }}>
                                    <InputLabel>{t('Select Role')}</InputLabel>
                                    <Select
                                        value={this.state.SelectedRole ? this.state.SelectedRole : ""}
                                        label={t('Select Role')}
                                        onChange={(event) => {
                                            let values = event.target.value;
                                            this.setState({
                                                SelectedRole: values
                                            })
                                        }}
                                        MenuProps={{
                                            style: { maxHeight: 350 },
                                        }}
                                    >
                                        {TypesOfRole?.length > 0 ?
                                            TypesOfRole.map((item, index) => {
                                                return <MenuItem key={index} value={item.value}>{(item.RoleName)}</MenuItem>
                                            }) : null}
                                    </Select>
                                </FormControl>
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    label={'Name'}
                                    value={this.state.SelectedName}
                                    style={{ width: "14vw" }}
                                    onChange={(event) => {
                                        this.setState({
                                            SelectedName: event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "-0.65vw" }}>
                            <p className='eMed_Testconfig_RightHead_text'>{t('Status')}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "0.6vw", marginTop: "-0.5vw" }}>
                            <FormControl disabled={this.state.selectedEditID == '' ? true : false} className='eMed_room_radio'>
                                <RadioGroup
                                    value={this.state.SelectedStatus}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                    onChange={(e) => { this.setState({ SelectedStatus: e.target.value }) }}
                                >
                                    <FormControlLabel value={true} control={<Radio size='small' />} label="Active" />
                                    <FormControlLabel value={false} control={<Radio size='small' />} label="InActive" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div style={{ marginTop: "-0.5vw" }}>
                            <p className='eMed_Testconfig_RightHead_text'>{t('Upload Your Signature')}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "-0.5vw" }}>
                            {this.renderImgCard()}
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='SignConfig_Save_Btn' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Lab E-Signature Configuration", null, "Tab")?.editAccess} variant='contained' onClick={() => { this.validation() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.CropPop ?
                    <div>
                        {this.OpenCropPopUP()}
                    </div>
                    : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("Are You Sure? Do you want to remove this Signature")}
                        DeleteNotify={t("effectEntireConfig")}
                        DeletContent={t("This Signature is linked with various Configuration")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                        disable={this.state.removeData}
                    />
                    : null}
                {this.state.popUpOpen ?
                    this.previewPopup()
                    : null
                }
            </div>
        )
    }
}
export default withTranslation()(SignatureConfig)