import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Typography, Tooltip, Grid, FormControl, InputLabel, Select, MenuItem, Autocomplete, TableHead, TableRow, TableCell, Table, TableBody, Tab, } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonGridToolBarWithFilterTextCustom, DeletePopup } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AmountFormat, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class ServiceTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceTempDetail: [],
            serviceNameandType: [],
            data: [],
            deleteId: null,
            srcTemplate_name: "",
            id: null,
            template_name: "",
            description: "",
            service_id: null,
            data: [],
            selectedItem: {},
            editIndex: -1,
            deletedPopup: false,
            isEdit: false,
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            clinicAmt:'',
            surgeryAmt:'',

            opNewServiceTypeData:[],
            opNewServiceType:null,
            opNewServiceNameData:[],
            opNewServiceName:null,
            newServiceListData:[],
            newServiceList:[]
        }
    }

    componentDidMount() {
        this._getServiceTempDetails();
        this._getServiceNameandType();
        this.getOpNewServiceData()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getServiceTempDetails = () => {
        this.setState({
            serviceTempDetail: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSRCTEMPLATE)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceTempDetail: response.data.data,
                        }, () => {
                            this.state.serviceTempDetail.forEach((item, i) => {
                                item.index = i
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error.response.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _getServiceNameandType = () => {
        this.setState({
            serviceNameandType: []
        })
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSRCNAMEANDTYPE)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceNameandType: response.data.data,
                        })
                        // ===> Hold

                        // ()=>{
                        //     let doctorAndHospitalShare = {
                        //         service_name:"Doctor and Hospital Share",
                        //         data:[{
                        //             amount:'',
                        //             is_edit_front_office:false,
                        //             service_id:null,
                        //             service_name:"Doctor and Hospital Share",
                        //             service_type:"Doctor and Hospital Share",
                        //             hospitalShare:'',
                        //             doctorShare:''
                        //         }]
                        //     }
                        //     this.state.serviceNameandType.push(doctorAndHospitalShare)
                        //     this.setState({
                        //         serviceNameandType:this.state.serviceNameandType
                        //     })
                        // });
                    }
                })
                .catch(error => {
                    if (error.response.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    getOpNewServiceData = () => {
        try{
            RestAPIService.getAll(Serviceurls.FO_NEW_OP_SERVICE_CONFIG + "?service_type=")
            .then((response) => {
                if(response.data.status === 'success'){
                    this.setState({
                        opNewServiceTypeData:response.data.data
                    })
                }
            }).catch((error) => {
                if(error?.response?.data?.message){
                    this.responseSuccessErrorMessage(error.response.data.message, false);
                }else{
                    this.responseSuccessErrorMessage(error.message, false);
                }
            })
        }catch(error){
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    //Start of left table Function
    handleEdit = (item) => {
        let states = this.state
        item["service_data"].forEach((value, i) => {
            value.index = i
        })
        states["id"] = item.id;
        states["template_name"] = item.template_name;
        states["description"] = item.description;
        states["data"] = JSON.parse(JSON.stringify(item.service_data));
        states["isEdit"] = true;
        states["selectedItem"] = {};
        states["editIndex"] = -1;
        this.setState({
            states
        })
    }

    handleDelete = (value) => {
        if(!this.state.isEdit){
        this.setState({
            deletedPopup: true,
            deleteId: value.id,
            srcTemplate_name: value.template_name
        })
    }}

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            srcTemplate_name: ""
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_OPSRCTEMPLATE}?id=${this.state.deleteId ? this.state.deleteId : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            deletedPopup: false,
                            deleteId: null,
                            srcTemplate_name: ""
                        }, () => {
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this._getServiceTempDetails();
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    getService_name = (params) => {
        let service_name = "";
        let Datas = params?.length > 0 ? params : [];
        Datas.forEach(element => {
            service_name = service_name ? (`${service_name},  ${element.service_name} - ${element.service_type}`) : `${element.service_name} - ${element.service_type}`
        });
        return (
            <div>{service_name?.length > 40 ? <Tooltip placement="top" title={service_name} arrow><div>{service_name.slice(0, 35) + "..."}</div></Tooltip> : service_name ? service_name : "-"}</div>
        )
    }
    // End of left table Function

    //Start of right div Function
    handleChange = (event) => {
        let states = this.state
        let name = event.target.name;
        let value = event.target.value.trimStart();
        if (name === "template_name") {
            let isValid = CommonValidation.alphanumValidation(value)
            if (value === "" || isValid) {
                states[name] = value;
            }
        } else if (name === "description"){
            if (value === "" || value.length <= 250) {
                states["description"] = value;
            }
        }
        this.setState({
            states
        })
    }

    onAdd = () => {
        const { t } = this.props;
        let { data, selectedItem, editIndex, serviceNameandType} = this.state;

        serviceNameandType.map((item) => (
            item.service_name === selectedItem["service_name"] ? (
                item.data.map((i) => (
                    i.service_type === selectedItem["service_type"] ?  (
                        selectedItem["amount"] = i.amount, 
                        selectedItem["id"] = i.service_id 
                        ): null
                ))
            ): null
        ))

        let exist = data?.find(item => item["service_id"] ? item["service_id"] : item["id"]  === selectedItem.id);
        let isCrt = true;
        let Message = "";
        if (!selectedItem.service_name && !selectedItem.service_type) { isCrt = false; Message = `${t("Enter")} ${t("ServiceName")} ${t("and")} ${t("ServiceType")}` }
        if (!selectedItem.service_name) { isCrt = false; Message = `${t("Enter")} ${t("ServiceName")}` }
        else if (!selectedItem.service_type) { isCrt = false; Message = `${t("Enter")} ${t("ServiceType")}` }
        else if (exist) {
            isCrt = false;
            Message = `${exist["service_name"]} ${t("and")} ${exist["service_type"]} ${t("AlreadyExist")}`;
        }
        if (isCrt) {
            if (editIndex !== -1) {
                let temp = JSON.parse(JSON.stringify(selectedItem))
                let newList = data.map((item, index) => {
                    return index === editIndex ? temp : item
                })
                data = JSON.parse(JSON.stringify(newList))
                // if(selectedItem.service_type === "Doctor and Hospital Share"){
                // data.find((item) => item.service_name === "Doctor and Hospital Share" ? (item.amount = (+this.state.clinicAmt + (+this.state.surgeryAmt)) ,item.hospitalShare = this.state.clinicAmt,item.doctorShare = this.state.surgeryAmt): item.amount)
                // this.setState({data})
                // }
            } else {
                selectedItem["index"] = data.length;
                let temp = selectedItem;
                data = [...data, temp]
                // if(selectedItem.service_type === "Doctor and Hospital Share"){
                // data.find((item) => item.service_type === "Doctor and Hospital Share" ? (item.amount = (+this.state.clinicAmt + (+this.state.surgeryAmt)) ,item.hospitalShare = this.state.clinicAmt,item.doctorShare = this.state.surgeryAmt) : item.amount)
                // this.setState({data})
                // }
            }
            this.setState({
                data,
                editIndex: -1,
                selectedItem: {},
                // clinicAmt:'',
                // surgeryAmt:''
            })
        } else {
            this.responseSuccessErrorMessage(Message, false)
        }
    }

    handleEditTbl = (item) => {
        let states = this.state
        states["selectedItem"] = JSON.parse(JSON.stringify(item));
        states["editIndex"] = item.index;
        // states['clinicAmt'] = item.hospitalShare;
        // states['surgeryAmt'] = item.doctorShare;
        this.setState({
            states
        })
    }

    handleDeleteTbl = (item) => {
        let { data, editIndex } = this.state;
        if(editIndex  === -1){
        let deletedArr = [...data]
        let index = deletedArr.indexOf(item)
        deletedArr.splice(index, 1)
        this.setState({
            data: JSON.parse(JSON.stringify(deletedArr))
        })}
    }
    handleClear = () => {
        let states = this.state;
        states["template_name"] = "";
        states["description"] = "";
        states["selectedItem"] = {};
        states["data"] = [];
        states["id"] = null;
        states["isEdit"] = false;
        states["editIndex"] = -1;
        // states['clinicAmt'] = '';
        // states['surgeryAmt'] = '';
        this.setState({
            states
        })
    }
    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let { serviceNameandType, selectedItem } = this.state
        return (
            <div id="eMed_srvConfig_divs">
                {
                    statekey === "service_name" ?
                    <FormControl sx={{ width: width ? width : "11vw" }} size="small">
                    <InputLabel>{t(label) + " *"}</InputLabel>
                    <Select
                        value={value ? value : ""}
                        name={statekey}
                        label={t(label)+ " *"}
                        onChange={(event) => {
                            selectedItem["service_name"] = event.target.value;
                            this.setState({
                                selectedItem
                            })
                        }}
                        MenuProps={{
                            style: { maxHeight: 350 },
                        }}
                    >
                        <MenuItem value={""} >{"Select"}</MenuItem>
                        {
                            serviceNameandType?.length > 0 ? serviceNameandType.map((list, index) => (
                                <MenuItem key={index} value={list.service_name} >{list.service_name}</MenuItem>
                            ))
                            : null
                        }
                    </Select>
                </FormControl>
                        :
                        statekey === "service_type" ? 
                        <FormControl sx={{ width: width ? width : "11vw" }} size="small">
                                <InputLabel>{t(label)+ " *"}</InputLabel>
                                <Select
                                    value={value ? value : ""}
                                    name={statekey}
                                    label={t(label)+ " *"}
                                    onChange={(event) => {
                                        selectedItem["service_type"] = event.target.value;
                                        this.setState({
                                            selectedItem
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    <MenuItem value={""} >{"Select"}</MenuItem>
                                    { serviceNameandType?.length > 0 ? serviceNameandType.map((item) => {
                                        return item?.service_name === this.state.selectedItem["service_name"] ?
                                            item?.data.map((i, index) => (
                                                <MenuItem key={index} value={i.service_type} >{i.service_type}</MenuItem>
                                            )) : null}) : null
                                    }
                                </Select>
                            </FormControl> :
                            <TextField
                                sx={{ width: width ? width : "10vw" }}
                                size="small"
                                autoComplete="off"
                                label={t(label)}
                                name={statekey}
                                required = {statekey === "description" ? false : true}
                                value={value ? value : ""}
                                multiline={statekey === "description" ? true : false}
                                rows={statekey === "description" ? 3 : null}
                                onChange={this.handleChange}
                            />
                }
            </div>
        )
    }
    handleSave = () => {
        const { t } = this.props
        // let { template_name, description, data, id, editIndex } = this.state
        // let datas = {};
        // let isAvl = true;
        // let Message = "";
        // if (!template_name && data.length === 0) { isAvl = false; Message = t("Fillmandatory") }
        // else if (!template_name) { isAvl = false; Message = `${t("Enter")} ${t("TemplateName")}` }
        // else if (data.length === 0) { isAvl = false; Message = `${t("Enter")} ${t("ServiceName")} ${t("and")} ${t("ServiceType")}` }
        // if (isAvl && editIndex === -1) {
        //     // api call
        //     if(id){
        //     datas = {
        //         "id": id,
        //         "template_name": template_name,
        //         "description": description,
        //         "service_ids": []
        //     }
        // }else{
        //     datas = {
        //         "id": null,
        //         "template_name": template_name,
        //         "description": description,
        //         "service_ids": []
        //     }
        //     }

        //     data.forEach(item => datas["service_ids"].push(item.service_id ? item.service_id : item.id ))
        try {
            let serviceIds = []
            this.state.newServiceList.forEach(element => {
                serviceIds.push(element.id)
            })
            let data = {
                'id':this.state.onClickEditId ? this.state.onClickEditId : null,
                'template_name': this.state.template_name,
                'description': this.state.description,
                "service_ids": serviceIds
            }
            if (this.state.template_name !== '' && data.service_ids?.length > 0) {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_CONFIG_OPSRCTEMPLATE)
                    .then(response => {
                        if (response.data.status === "success") {
                            // this.handleClear();
                            this.setState({
                                isEdit:false,
                                template_name:'',
                                description:'',
                                newServiceList:[],
                                selectedEditIndex:null
                            })
                            this._getServiceTempDetails();
                            this.getOpNewServiceData()
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } else {
                this.responseSuccessErrorMessage("Enter all mandatory fields", false)
            }
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    // End of right div Function

    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }

    //   onChangeHandler = (e) => {
    //     const {name,value} = e.target
    //     if(CommonValidation.NumberWithDot(value) || value === ""){
    //         this.setState({
    //             [name] : value
    //         })
    //     }
    //   }

    //   inputTextBox = (state,key,label,margin) => {
    //     return (
    //         <TextField 
    //             sx={{width:"11vw",marginLeft:margin,marginBottom:"1.5vw"}}
    //             size="small"
    //             label={label}
    //             name={key}
    //             value={state}
    //             onChange={(e)=>this.onChangeHandler(e)}
    //         />
    //     )
    //   }

    newServiceEdit = (item, index) => {
        try {
            let serviceNameList = this.state.opNewServiceTypeData.find((data) => data.service_type === item?.service_type)
            console.log(serviceNameList);
            this.setState({
                selectedEditIndex: index,
                opNewServiceName: item?.service_name,
                opNewServiceType: item?.service_type,
                opNewServiceNameData: serviceNameList?.data
            })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    newServiceDelete = (item, index) => {
        try {
            this.state.newServiceList.splice(index, 1)
            this.setState({ newServiceList: this.state.newServiceList })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    renderRightTable = () => {
        try {
            return (
                <Box margin={'0.5vw'} border={'1px solid lightgray'}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText }}>
                                    Service
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText }}>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.newServiceList?.length > 0 ? this.state.newServiceList?.map((item, index) => {
                                let serviceName = `${item?.service_type} - ${item?.service_name}`
                                return <TableRow key={index}>
                                    <TableCell>{serviceName?.length > 25 ?
                                        <Tooltip placement="top" title={serviceName}><div>{serviceName?.slice(0, 25) + '...'}</div></Tooltip> :
                                        serviceName}
                                    </TableCell>
                                    <TableCell>
                                        <CommonEditButton size="1.5vw" onClick={this.newServiceEdit.bind(this, item, index)} />
                                        <CommonDeleteButton size="1.5vw" onClick={this.newServiceDelete.bind(this, item, index)} />
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell />
                                <TableCell>No Records</TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    checkDuplicateData = () => {
        try {
            let message = null
            if (this.state.opNewServiceName !== null && this.state.opNewServiceType !== null) {
                this.state.newServiceList?.forEach((element, index) => {
                    let serviceName = this.state.opNewServiceName?.service_name ? CommonValidation.removeSpace(this.state.opNewServiceName?.service_name) : CommonValidation.removeSpace(this.state.opNewServiceName)
                    let serviceType = this.state.opNewServiceType?.service_type ? CommonValidation.removeSpace(this.state.opNewServiceType?.service_type) : CommonValidation.removeSpace(this.state.opNewServiceType)
                    if (index !== this.state.selectedEditIndex) {
                        if (CommonValidation.removeSpace(element.service_name) === serviceName
                            && CommonValidation.removeSpace(element.service_type) === serviceType) {
                            message = "Service Type and Service Name Already exists"
                        }
                    }
                })
            } else {
                message = "Enter Service Name and Service Type"
            }
            return message;
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    addServiceListData = () => {
        try {
            let duplicate = this.checkDuplicateData()
            if (duplicate === null) {
                let data = CommonValidation.updateOrInsertList(this.state.newServiceList, this.state.opNewServiceName, this.state.selectedEditIndex)
                this.setState({
                    newServiceList: data
                }, () => this.setState({
                    opNewServiceName: null,
                    opNewServiceType: null,
                    description: '',
                    opNewServiceNameData: [],
                    selectedEditIndex: null
                }))
            } else {
                this.responseSuccessErrorMessage(duplicate, false)
            }
        } catch (error) {
            this.responseSuccessErrorMessage(error.message)
        }
    }

    OnClickEditHandler = (item) => {
        try {
            this.setState({
                onClickEditId: item?.id,
                newServiceList: item?.service_data,
                template_name: item?.template_name,
                description: item?.description
            })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    gridToolBar = () => {
        // variable store datas for toolbar 
        var ExportData = []

        // mapping the data from service template details state
        this.state.serviceTempDetail.map((item, index) => {

            // mapping the service name and type from service_data key in serviceTempDetail
            const serviceNames = item.service_data.map(
                (service) => `${service.service_name} - ${service.service_type}`
            ).join("/"); 

            // includes into the ExportData array for toolbar
            ExportData.push({
                "S.no": index + 1,
                "Template Name": item.template_name,
                "Service Name": serviceNames
            });
        });
        
        return(
            <Box>
            <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Service Template"} filename={"Service Template"} />
        </Box> 
        )
        
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, serviceTempDetail, description, data, template_name, selectedItem } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.08, renderCell: (index) => index.api.getRowIndex(index.row.index) + 1 },
            {
                field: 'template_name', headerName: t('ServiceTemplateName'), flex: 0.24, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.template_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.template_name} arrow><div>{cellvalues?.row?.template_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.template_name ? cellvalues?.row?.template_name : "-"}</div>
                    )
                }
            },
            { field: 'service_name', sortable: false, headerName: t('Services'), flex: 0.40, renderCell: ({row}) => this.getService_name(row.service_data)},
            // { field: 'total_amount', headerAlign: "right", headerName: t('Amount') + " (₹)", flex: 0.15, align: "right", renderCell: (cellvalues) =>{
            //     let amount = AmountFormat(cellvalues.row?.total_amount)
            //     return (<Typography>{amount ? amount.replace("₹", "") : "-"}</Typography>)
            // }},
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.13, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Service Template", "SubTab")?.editAccess} size="1.5vw" onClick={this.OnClickEditHandler.bind(this, cellvalues.row)} />
                                <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Service Template", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, cellvalues.row)} />
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        // const rightColumn = [
        //     {
        //         field: 'service', headerName: t('Service'), sortable: false, hideable: false, flex: 0.35, renderCell: (cellvalues) => {
        //             let service = `${cellvalues.row?.service_name}, ${cellvalues.row?.service_type}`
        //             return (<div>{service?.length > 30 ? <Tooltip placement="top" title={service} arrow><div>{service.slice(0, 28) + "..."}</div></Tooltip> : service ? service : "-"}</div>)
        //         }
        //     },
        //     { field: "amount", headerAlign: 'right', headerName: t('Amount') + " (₹)", flex: 0.20, sortable: false, hideable: false, align: "right", renderCell: (cellvalues) =>{
        //         let amounts = AmountFormat(cellvalues.row?.amount)
        //         return (<Typography>{amounts ? amounts.replace("₹", "") : "-"}</Typography>)
        //     } },
        //     {
        //         field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
        //             return (
        //                 <Stack direction="row" alignItems="center" spacing={4}>
        //                     <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
        //                         <CommonEditButton size="1.5vw" onClick={this.handleEditTbl.bind(this, cellvalues.row)} />
        //                         <CommonDeleteButton size="1.5vw" onClick={this.handleDeleteTbl.bind(this, cellvalues.row)} />
        //                     </Stack>
        //                 </Stack>)
        //         }
        //     }
        // ]
        return (
            <Box  id="eMed_srvConfig_Container" sx={{backgroundColor: Colors.configHomeBg}}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={serviceTempDetail}
                                columns={columns}
                                getRowId={(row) => row.index}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={serviceTempDetail.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("ServiceTemplateConfiguration")}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                <Stack spacing={2}>
                                    {this.renderTextBox("ServiceTemplateName", "template_name", template_name, "29.8vw")}
                                    {this.renderTextBox("Description", "description", description, "29.8vw")}
                                </Stack>
                                {/* <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "29.8vw" }}  mb={1}>
                                    {this.renderTextBox("ServiceName", "service_name", selectedItem.service_name)}
                                    {this.renderTextBox("ServiceType", "service_type", selectedItem.service_type)}
                                    <Button size="small" variant="contained" id="eMed_Config_btn" onClick={() => { this.onAdd() }}>{t("Add")}</Button>
                                </Stack> */}
                                {/* {selectedItem.service_type === "Doctor and Hospital Share" ? 
                                    <Box>{this.inputTextBox(this.state.clinicAmt,'clinicAmt','Hospital Share','0.5vw')}
                                    {this.inputTextBox(this.state.surgeryAmt,'surgeryAmt','Doctor Share','1.9vw')}
                                    </Box>
                                    :null} */}
                                {/* <div id="eMed_srvTemp_rightTbl">
                                    <DataGrid
                                        //sx={{height:selectedItem.service_type === "Doctor and Hospital Share" ? "12vw" : '16vw'}}
                                        rows={data}
                                        columns={rightColumn}
                                        getRowId={(row) => row.index}
                                        disableColumnMenu
                                        hideFooter
                                        headerHeight={40}
                                        components={{
                                            NoRowsOverlay: () => ""
                                        }}
                                    />
                                </div> */}
                            
                                <Box component={'div'} mt={'0.5vw'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Autocomplete
                                            clearIcon
                                            size="small"
                                            sx={{ width: '12.3vw', mr: '0.5vw',ml:'0.5vw' }}
                                            options={this.state.opNewServiceTypeData}
                                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.service_type}
                                            value={this.state.opNewServiceType}
                                            onChange={(e, newValue) => {
                                                this.setState({ 
                                                    opNewServiceType: newValue,
                                                    opNewServiceNameData:newValue?.data,
                                                    opNewServiceName:null
                                                 })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Service Type' />}
                                        />
                                        <Autocomplete
                                            clearIcon
                                            size="small"
                                            sx={{ width: '12.3vw', mr: '0.5vw' }}
                                            options={this.state.opNewServiceNameData}
                                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.service_name}
                                            value={this.state.opNewServiceName}
                                            onChange={(e, newValue) => {
                                                this.setState({ opNewServiceName: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Service Name'/>}
                                        />
                                        <Button 
                                        size="small"
                                         variant="contained" 
                                         sx={{textTransform:'capitalize',height:'2vw'}}
                                         onClick={()=>this.addServiceListData()}
                                         >Add</Button>
                                    </Box>
                                </Box>
                                <Box component={'div'}>
                                    {this.renderRightTable()}
                                </Box>
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Service Template", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.srcTemplate_name ? this.state.srcTemplate_name : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(ServiceTemplate);
