import { Accordion, Box, FormControlLabel, AccordionSummary, Radio, RadioGroup, Typography, IconButton, TextField, Select, MenuItem, Button } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../Components/Loader';
import { localGetItem, localSetItem } from '../../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';

class SmsConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpenEmedhub: true,
            OpenMobileApp: true,
            OpenSmsConfig: true,
            emedhubConfig: {
                "op_bill_sms": false,
                "ip_bill_sms": false,
                "lab_bill_sms": false,
                "pharmacy_bill_sms": false,
                "radiology_bill_sms": false,
                "visit_report_sms": false,
                "prescription_sms": false,
                "appointment_confirmation_sms": false,
                // "appointment_status_update_sms": false,
                "appointment_changed_sms": false,
                "appointment_rescheduled_sms": false,
                "appointment_transferred_sms": false,
                "appointment_token_sms": false,
                "appointment_completed_sms": false,
                "doctor_emergency_sms": false,
                "appointment_canceled_sms": false,
                // "appointment_reminder_sms": false,
                // "appointment_aproximate_time_sms": false,

                // "emed_precription_payment_sms": false,
                // "emed_prescription_order_sms": false
            },
            mobileAppConfig: {
                "mobile_prescription_sms": false,
                "mobile_appointment_status_update_sms": false,
                "online_prescription_order_sms": false,
                "precription_payment_sms": false,
                // "telemedicine_appointment_cancel_sms": false,
                // "telemedicine_call_end_sms": false
            },
            thresholdValue: null,
            timeInterval: 0,
            id: '',
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            disableBtn: false,
            isLoader: false,
            sms_limit: 0,
            sms_count: 0,

        }
    }

    componentDidMount = () => {
        this.getSmsConfiguration()
    }

    assignData = (data) => {
        let states = this.state
        let emedHub = data?.emedhub ? data?.emedhub : {}
        let mobileApp = data?.mobile_app ? data?.mobile_app : {}
        let smsConfig = data?.sms_configuration ? data?.sms_configuration : {}

        Object.keys(emedHub)?.forEach((item) => {
            states.emedhubConfig[item] = emedHub[item]
        })

        states.mobileAppConfig.mobile_prescription_sms = mobileApp?.mobile_prescription_sms
        states.mobileAppConfig.mobile_appointment_status_update_sms = mobileApp?.mobile_appointment_status_update_sms
        states.mobileAppConfig.online_prescription_order_sms = mobileApp?.online_prescription_order_sms
        states.mobileAppConfig.precription_payment_sms = mobileApp?.precription_payment_sms
        // states.mobileAppConfig.telemedicine_appointment_cancel_sms = mobileApp?.telemedicine_appointment_cancel_sms
        // states.mobileAppConfig.telemedicine_call_end_sms = mobileApp?.telemedicine_call_end_sms

        this.setState({
            emedhubConfig: this.state.emedhubConfig,
            mobileAppConfig: this.state.mobileAppConfig,
            thresholdValue: smsConfig?.sms_threshold ? smsConfig?.sms_threshold : null,
            timeInterval: smsConfig?.threshold_interval ? smsConfig?.threshold_interval : 0,
            id: data?.id ? data?.id : '',
            sms_count: smsConfig?.sms_count ? smsConfig?.sms_count : 0,
            sms_limit: smsConfig?.sms_limit ? smsConfig?.sms_limit : 0,
            isLoader: false,
        })
    }

    getSmsConfiguration = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAllTwo(Serviceurls.FO_SMS_CONFIG)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.assignData(response?.data?.data)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ isLoader: false })
                })

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ isLoader: false })
        }


    }

    handleChange = (item, newValue) => {
        let states = this.state
        item["value"] = newValue;
        this.setState({ states });
    }

    clearData = () => {
        this.getSmsConfiguration()
    }

    postData = () => {
        try {
            let states = this.state
            let data = {
                "id": states.id ? states.id : '',
                "prescription_sms": states.emedhubConfig.prescription_sms,
                "visit_report_sms": states.emedhubConfig.visit_report_sms,
                "appointment_confirmation_sms": states.emedhubConfig.appointment_confirmation_sms,
                // "appointment_status_update_sms": states.emedhubConfig.appointment_status_update_sms,
                "op_bill_sms": states.emedhubConfig.op_bill_sms,
                "ip_bill_sms": states.emedhubConfig.ip_bill_sms,
                "lab_bill_sms": states.emedhubConfig.lab_bill_sms,
                "pharmacy_bill_sms": states.emedhubConfig.pharmacy_bill_sms,
                "radiology_bill_sms": states.emedhubConfig.radiology_bill_sms,
                "appointment_changed_sms": states.emedhubConfig.appointment_changed_sms,
                "appointment_rescheduled_sms": states.emedhubConfig.appointment_rescheduled_sms,
                "appointment_transferred_sms": states.emedhubConfig.appointment_transferred_sms,
                "appointment_token_sms": states.emedhubConfig.appointment_token_sms,
                "appointment_completed_sms": states.emedhubConfig.appointment_completed_sms,
                "doctor_emergency_sms": states.emedhubConfig.doctor_emergency_sms,
                "appointment_canceled_sms": states.emedhubConfig.appointment_canceled_sms,
                // "emed_precription_payment_sms": states.emedhubConfig.emed_precription_payment_sms,
                // "emed_prescription_order_sms": states.emedhubConfig.emed_prescription_order_sms,
                "mobile_prescription_sms": states.mobileAppConfig.mobile_prescription_sms,
                "mobile_appointment_status_update_sms": states.mobileAppConfig.mobile_appointment_status_update_sms,
                "online_prescription_order_sms": states.mobileAppConfig.online_prescription_order_sms,
                "precription_payment_sms": states.mobileAppConfig.precription_payment_sms,
                // "telemedicine_appointment_cancel_sms": states.mobileAppConfig.telemedicine_appointment_cancel_sms,
                // "telemedicine_call_end_sms": states.mobileAppConfig.telemedicine_call_end_sms,
                "sms_threshold": this.state.thresholdValue ? this.state.thresholdValue : null,
                "threshold_interval": this.state.timeInterval ? this.state.timeInterval : null,
                "sms_limit": this.state.sms_limit ? this.state.sms_limit : 0,
                "sms_count": this.state.sms_count ? this.state.sms_count : 0
            }
            this.setState({ disableBtn: true })
            RestAPIService.create(data, Serviceurls.FO_SMS_CONFIG)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getSmsConfiguration()
                        let smsConfig = JSON.stringify(data);
                        localSetItem("smsConfig", smsConfig)
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false })
                })

        } catch (e) {
            this.errorMessage(e?.message)
            this.setState({ disableBtn: false })
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        })
    }

    handleRadioChange = (state, key, value) => {
        let states = this.state
        state[key] = JSON.parse(value)
        this.setState({ states })
    }

    renderRadio = (label, state, key) => {
        let value = state[key]
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '0.5vw', ml: '2vw' }}>
                <Typography>{label}</Typography>
                <RadioGroup row sx={{ gap: '2vw', mr: '1vw' }} value={value} onChange={(e) => { this.handleRadioChange(state, key, e.target.value) }}>
                    <FormControlLabel value={true} control={<Radio inputProps={{ 'emed_tid': `${key}_yesBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess} />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio inputProps={{ 'emed_tid': `${key}_noBtn_testID` }} disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess} />} label="No" />
                </RadioGroup>
            </Box>
        )
    }

    render() {
        const { t } = this.props
        let daysOption = []
        for (let i = 1; i <= 7; i++) { daysOption.push(i) }
        const UserData = JSON.parse(localGetItem("loggedInUserInfo"))
        let qmsConfig = UserData?.is_qms_enabled ? UserData?.is_qms_enabled : false
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} height={'78vh'} overflow={'auto'}>
                    <Accordion expanded={this.state.OpenEmedhub}>
                        <AccordionSummary sx={{ flexDirection: 'row-reverse' }}
                            expandIcon={
                                <IconButton color='primary' onClick={() => { this.setState({ OpenEmedhub: !this.state.OpenEmedhub }) }} emed_tid="emedhub_expandBtn_testID"><ExpandMoreIcon /></IconButton>}
                        ><Typography fontWeight={'bold'}>eMedHub</Typography></AccordionSummary>
                        {this.renderRadio("Patient Prescription Report SMS", this.state.emedhubConfig, "prescription_sms")}
                        {this.renderRadio("Doctor Visit Report SMS", this.state.emedhubConfig, "visit_report_sms")}
                        {/* {this.renderRadio("Appointment Status Update SMS", this.state.emedhubConfig, "appointment_status_update_sms")} */}
                        {this.renderRadio("OP Billing SMS", this.state.emedhubConfig, "op_bill_sms")}
                        {this.renderRadio("IP Billing SMS", this.state.emedhubConfig, "ip_bill_sms")}
                        {this.renderRadio("Lab Billing SMS", this.state.emedhubConfig, "lab_bill_sms")}
                        {this.renderRadio("Pharmacy Billing SMS", this.state.emedhubConfig, "pharmacy_bill_sms")}
                        {this.renderRadio("Radiology Billing SMS", this.state.emedhubConfig, "radiology_bill_sms")}
                        {this.renderRadio("Appointment Confirmation SMS", this.state.emedhubConfig, "appointment_confirmation_sms")}
                        {this.renderRadio("Appointment Changed SMS", this.state.emedhubConfig, "appointment_changed_sms")}
                        {this.renderRadio("Appointment Cancelled SMS", this.state.emedhubConfig, "appointment_canceled_sms")}
                        {this.renderRadio("Appointment Completed SMS", this.state.emedhubConfig, "appointment_completed_sms")}
                        {this.renderRadio("Appointment Rescheduled SMS", this.state.emedhubConfig, "appointment_rescheduled_sms")}
                        {this.renderRadio("Appointment Transferred SMS", this.state.emedhubConfig, "appointment_transferred_sms")}
                        {this.renderRadio("Appointment Token SMS", this.state.emedhubConfig, "appointment_token_sms")}
                        {qmsConfig ? this.renderRadio("Doctor Emergency SMS", this.state.emedhubConfig, "doctor_emergency_sms") : null }
                        {/* {this.renderRadio("Patient Prescription Payment SMS", this.state.emedhubConfig, "emed_precription_payment_sms")} */}
                        {/* {this.renderRadio("Online Prescription Order SMS", this.state.emedhubConfig, "emed_prescription_order_sms")} */}
                    </Accordion>
                    {/* <Accordion expanded={this.state.OpenMobileApp}> // Comment mobile app for future usage
                        <AccordionSummary sx={{ flexDirection: 'row-reverse' }}
                            expandIcon={
                                <IconButton color='primary' onClick={() => { this.setState({ OpenMobileApp: !this.state.OpenMobileApp }) }} emed_tid="mobileApp_expandBtn_testID"><ExpandMoreIcon /></IconButton>}
                        ><Typography fontWeight={'bold'}>Mobile App</Typography></AccordionSummary>
                        {this.renderRadio("Prescription Transmission SMS", this.state.mobileAppConfig, "mobile_prescription_sms")}
                        {this.renderRadio("Appointment Status Notification SMS", this.state.mobileAppConfig, "mobile_appointment_status_update_sms")}
                        {this.renderRadio("Online Prescription Order Status SMS", this.state.mobileAppConfig, "online_prescription_order_sms")}
                        {this.renderRadio("Prescription Payment Confirmation SMS", this.state.mobileAppConfig, "precription_payment_sms")}
                        {this.renderRadio("Telemedicine Appointment Cancellation SMS", this.state.mobileAppConfig, "telemedicine_appointment_cancel_sms")}
                        {this.renderRadio("Telemedicine Call Ended SMS", this.state.mobileAppConfig, "telemedicine_call_end_sms")}
                    </Accordion> */}
                    <Accordion expanded={this.state.OpenSmsConfig}>
                        <AccordionSummary sx={{ flexDirection: 'row-reverse' }}
                            expandIcon={
                                <IconButton color='primary' onClick={() => { this.setState({ OpenSmsConfig: !this.state.OpenSmsConfig }) }} emed_tid="smsConfig_expandBtn_testID"><ExpandMoreIcon /></IconButton>}
                        ><Typography fontWeight={'bold'}>SMS Configuration</Typography></AccordionSummary>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '0.5vw', ml: '2vw', mr: '1vw' }}>
                                <Typography>Threshold For SMS</Typography>
                                <TextField
                                    label='Threshold Value'
                                    size='small'
                                    autoComplete='off'
                                    sx={{ width: '15vw' }}
                                    inputProps={{ emed_tid: "thresholdValue_testID", maxLength: 30 }}
                                    value={this.state.thresholdValue ? this.state.thresholdValue : ""}
                                    disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess}
                                    onChange={(e) => {
                                        if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                                            this.setState({ thresholdValue: +e.target.value })
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '0.5vw', ml: '2vw', mr: '1vw' }}>
                                <Typography>Time Interval</Typography>
                                <Box component={'div'}>
                                    <Select
                                        sx={{ width: '10vw', fontSize: '0.95vw' }}
                                        size='small'
                                        value={this.state.timeInterval}
                                        onChange={(e) => { this.setState({ timeInterval: e.target.value }) }}
                                        inputProps={{ emed_tid: 'timeInterval_testID' }}
                                        disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess}
                                    >
                                        <MenuItem sx={{ fontSize: '0.95vw' }} value={0}>Select Interval</MenuItem>
                                        {daysOption.map((list) => (
                                            <MenuItem sx={{ fontSize: '0.95vw' }} key={list} value={list}>{list}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextField size='small' value={"Days"} sx={{ width: '5vw' }} inputProps={{ emed_tid: 'timeInterval_days_testID' }} disabled={true} />
                                </Box>
                            </Box>

                        </Box>
                    </Accordion>
                    <Box sx={{ display: 'flex', gap: '1vw', m: '2vw', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' emed_tid='smsConfig_clearBtn_testID' onClick={this.clearData} disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess}>Clear</Button>
                        <Button variant='contained' emed_tid='smsConfig_SaveBtn_testID' disabled={!CheckAccessFunc("front_office", "Settings", 'SMS Configuration', "SMS Configuration", "SubTab")?.editAccess ? true : this.state.disableBtn} onClick={this.postData}>Save</Button>
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

export default withTranslation()(SmsConfig)