import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Popover, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { DIOPTER_OPTIONS, MM_OPTIONS, CYLINDRICAL_OPTIONS, AXIS_OPTIONS } from "../../../../Utility/Constants";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import ShowComponents from "./ShowComponent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Mms } from "@material-ui/icons";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import AddIcon from '@mui/icons-material/Add';



export default class DocKeratomentry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            appointmentDate: this.props.appointmentDate,
            isLocked: this.props.isLocked,
            postLoad: false,
            selectedEye: "",
            Curvature: null,
            DIOPTER_OPTIONS: [],
            localChip: true,
            diopterKey: "",
            mmKey: "",
            axisKey: "",
            MM_OPTIONS: [],
            CYLINDRICAL_OPTIONS: [],
            AXIS_OPTIONS: [],
            isEdit: false,
            K1: {
                Dioptre: "",
                MM: "",
                AXIS: ""
            },
            K2: {
                Dioptre: "",
                MM: "",
                AXIS: ""
            },
            Average: {
                Dioptre: "",
                MM: "",
                AXIS: ""
            },
            Cylindrical: {
                Dioptre: "",
                MM: "",
                AXIS: ""
            },
            comparePopup: false,
            showLeft: false,
            showRight: false,
            axis: [],
            diopter: [],
            mm: [],
            CurvatureData: [],
            RightKeratometryList: [],
            LeftKeratometryList: [],
            PrevRightKeratometryList: [],
            PrevLeftKeratometryList: [],
            CurvatureNameList: [],
        }
        this.DIOPTRE = React.createRef();
        this.MM = React.createRef();
        this.AXIS = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    apiCalls = () => {
        this.getKeratometry()
        this.getPreviousKeratometry()
        this.getCurvatureDatas(true)
        this.getKeratometrySuggestion()
    }

    getKeratometry = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_KERATOMETRY + `?appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data ? response.data.data : [];
                        this.setState({
                            RightKeratometryList: data.filter((item) => (item?.eye_type === "RE")),
                            LeftKeratometryList: data.filter((item) => (item?.eye_type === "LE")),
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousKeratometry = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_KERATOMETRY + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data ? response.data.data : [];
                        this.setState({
                            PrevRightKeratometryList: data.filter((item) => (item?.eye_type === "RE")),
                            PrevLeftKeratometryList: data.filter((item) => (item?.eye_type === "LE")),
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getCurvatureDatas = (setCur = false) => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_KERATOMETRY_CURVATURE + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props?.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let Data = response.data.data ? response.data.data : []
                        let NewArr = {}
                        Data.forEach((item) => {
                            NewArr[item] = {
                                "Dioptre": "",
                                "MM": "",
                                "AXIS": "",
                                "Curvature": item
                            }
                        })
                        this.setState({
                            CurvatureData: NewArr,
                            CurvatureNameList: Data,
                            Curvature: setCur ? response.data.data.length > 0 ? response.data.data[0] : null : this.state.Curvature
                        }, () => {

                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = (type) => {
        let CurvDataObj = {}
        if (type == "LE") {
            this.state.PrevLeftKeratometryList.forEach((item) => {
                CurvDataObj[item?.curvature] = {
                    "Dioptre": item?.dioptre ? item?.dioptre : "",
                    "MM": item?.mm ? item?.mm : "",
                    "AXIS": item?.axis ? item?.axis : "",
                    "Curvature": item?.curvature ? item?.curvature : "",
                    "id": this.state.LeftKeratometryList.some((list) => (list?.curvature === item?.curvature)) ? (this.state.LeftKeratometryList.find((list) => (list.curvature === item?.curvature)) ? this.state.LeftKeratometryList.find((list) => (list.curvature === item?.curvature))?.id : "") : "",
                }
            })
        } else {
            this.state.PrevRightKeratometryList.forEach((item) => {
                CurvDataObj[item?.curvature] = {
                    "Dioptre": item?.dioptre ? item?.dioptre : "",
                    "MM": item?.mm ? item?.mm : "",
                    "AXIS": item?.axis ? item?.axis : "",
                    "Curvature": item?.curvature ? item?.curvature : "",
                    "id": this.state.RightKeratometryList.some((list) => (list?.curvature === item?.curvature)) ? (this.state.RightKeratometryList.find((list) => (list.curvature === item?.curvature)) ? this.state.RightKeratometryList.find((list) => (list.curvature === item?.curvature))?.id : "") : "",
                }
            })
        }

        this.setState({
            CurvatureData: CurvDataObj,
            selectedEye: type == "LE" ? "Left" : "Right"
        }, () => { this.PostKeratometry() })
    }

    onEditHandler = (item, Eye) => {
        let states = this.state
        states.CurvatureData[item?.curvature] = {
            "Dioptre": item?.dioptre ? item?.dioptre : "",
            "MM": item?.mm ? item?.mm : "",
            "AXIS": item?.axis ? item?.axis : "",
            "Curvature": item?.curvature ? item?.curvature : "",
            "id": item?.id ? item?.id : null
        }
        this.setState({
            selectedEye: Eye,
            Curvature: item?.curvature,
            isEdit: true,
            openPopUp: true,
            CurvatureData: this.state.CurvatureData
        })
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box component={'div'}>
                    {(this.state.comparePopup && this.state.showLeft || !this.state.comparePopup) ? <div>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Left Eye</Typography>
                                <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PrevLeftKeratometryList?.length > 0) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PrevLeftKeratometryList?.length > 0) { this.CopyPreviousData("LE") } }}>
                                        {this.state.PrevLeftKeratometryList?.length > 0 ?
                                            <Tooltip placement='top' title='Copy Previous Keratometry'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip> :
                                            <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Curvature Values</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Dioptre</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>MM</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.PrevLeftKeratometryList?.length > 0 ? this.state.PrevLeftKeratometryList?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.curvature ? item?.curvature : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.dioptre ? item?.dioptre : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.mm ? item?.mm : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.axis ? item?.axis?.length > 10 ?
                                        <Tooltip placement='top' title={item?.axis}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis}</Typography> : '-'}
                                    </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </div> : null}
                    {(this.state.comparePopup && this.state.showRight || !this.state.comparePopup) ? <div>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography mt={"0.5vw"} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Right Eye</Typography>
                                <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PrevRightKeratometryList?.length > 0) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PrevRightKeratometryList?.length > 0) { this.CopyPreviousData("RE") } }}>
                                        {this.state.PrevRightKeratometryList?.length > 0 ?
                                            <Tooltip placement='top' title='Copy Previous Keratometry'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip> :
                                            <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Curvature Values</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Dioptre</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>MM</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.PrevRightKeratometryList?.length > 0 ? this.state.PrevRightKeratometryList?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.curvature ? item?.curvature : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.dioptre ? item?.dioptre : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.mm ? item?.mm : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.axis ? item?.axis?.length > 10 ?
                                        <Tooltip placement='top' title={item?.axis}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis}</Typography> : '-'}
                                    </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </div> : null}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderKeratomentryTable = () => {
        let states = this.state
        try {
            return (
                <Box component={'div'}>
                    {(this.state.comparePopup && this.state.showRight || !this.state.comparePopup) ? <div>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography mt={"0.5vw"} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Right Eye</Typography>
                                {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PrevRightKeratometryList?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.PrevRightKeratometryList.length > 0) && !this.props.isLocked) { this.CopyPreviousData("RE") } }}>
                                        <Tooltip placement='top' title='Copy Previous Keratometry Right Eye'>
                                            <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Tooltip>
                                    </div>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.PrevRightKeratometryList?.length > 0 && this.state.RightKeratometryList?.length > 0)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.PrevRightKeratometryList?.length > 0 && this.state.RightKeratometryList?.length > 0) && !this.props.isLocked) { this.setState({ comparePopup: true, showRight: true }) } }}>
                                        <Tooltip placement='top' title='Compare'>
                                            <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Tooltip>
                                    </div>
                                </div>}
                            </div>
                            {this.state.comparePopup ? null : <Button
                                disabled={this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => {
                                    let curvature = "";
                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                        if (this.state.RightKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                            curvature = this.state.CurvatureNameList[i]
                                            break;
                                        }
                                    }
                                    this.setState({ Curvature: curvature, openPopUp: true, selectedEye: "Right" });
                                }}
                            >Add</Button>}
                        </Box>
                        <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Curvature Values</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Dioptre</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>MM</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.RightKeratometryList?.length > 0 ? this.state.RightKeratometryList?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.curvature ? item?.curvature : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.dioptre ? item?.dioptre : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.mm ? item?.mm : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.axis ? item?.axis?.length > 10 ?
                                        <Tooltip placement='top' title={item?.axis}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis}</Typography> : '-'}
                                    </TableCell>
                                            {this.state.comparePopup ? null : <TableCell align='center'>
                                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        onClick={() => this.onEditHandler(item, "Right")}
                                                        disabled={this.state.isLocked || this.props.isLocked}
                                                    >
                                                        {this.state.isLocked || this.props.isLocked ?
                                                            <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                            :
                                                            <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                        }
                                                    </Button>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        onClick={() => { this.setState({ isDeleteClicked: true, selectedKerato: item }) }}
                                                        disabled={this.state.isLocked || this.props.isLocked}
                                                    >
                                                        {this.state.isLocked || this.props.isLocked ?
                                                            <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                            :
                                                            <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                        }
                                                    </Button>
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </div> : null}
                    {(this.state.comparePopup && this.state.showLeft || !this.state.comparePopup) ? <div>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Left Eye</Typography>
                                {this.state.comparePopup ? null : <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.7vw" }}>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PrevLeftKeratometryList?.length > 0) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (((this.state.PrevLeftKeratometryList?.length > 0) && !this.props.isLocked)) { this.CopyPreviousData("LE") } }}>
                                        <Tooltip placement='top' title='Copy Previous Keratometry Left Eye'>
                                            <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Tooltip>
                                    </div>
                                    <div className="eMed_icon_styles" style={{ backgroundColor: ((this.state.LeftKeratometryList?.length > 0) && (this.state.PrevLeftKeratometryList?.length > 0)) ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if ((this.state.LeftKeratometryList?.length > 0) && (this.state.PrevLeftKeratometryList?.length > 0) && !this.props.isLocked) { this.setState({ comparePopup: true, showLeft: true }) } }}>
                                        <Tooltip placement='top' title='Compare'>
                                            <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Tooltip>
                                    </div>
                                </div>}
                            </div>
                            {this.state.comparePopup ? null : <Button
                                disabled={this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => {
                                    let curvature = "";
                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                        if (this.state.LeftKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                            curvature = this.state.CurvatureNameList[i]
                                            break;
                                        }
                                    }
                                    this.setState({ Curvature: curvature, openPopUp: true, selectedEye: "Left" });
                                }}
                            >Add</Button>}
                        </Box>
                        <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray', marginTop: "0.5vw" }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Curvature Values</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Dioptre</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>MM</TableCell>
                                        <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                        {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.LeftKeratometryList?.length > 0 ? this.state.LeftKeratometryList?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.curvature ? item?.curvature : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.dioptre ? item?.dioptre : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.mm ? item?.mm : '-'}</TableCell>
                                            <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.axis ? item?.axis?.length > 10 ?
                                        <Tooltip placement='top' title={item?.axis}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.axis}</Typography> : '-'}
                                    </TableCell>
                                            {this.state.comparePopup ? null : <TableCell align='center'>
                                                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        onClick={() => this.onEditHandler(item, "Left")}
                                                        disabled={this.state.isLocked || this.props.isLocked}
                                                    >
                                                        {this.state.isLocked || this.props.isLocked ?
                                                            <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                            :
                                                            <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                        }
                                                    </Button>
                                                    <Button
                                                        className='Common_Btn_Min_Width'
                                                        onClick={() => { this.setState({ isDeleteClicked: true, selectedKerato: item }) }}
                                                        disabled={this.state.isLocked || this.props.isLocked}
                                                    >
                                                        {this.state.isLocked || this.props.isLocked ?
                                                            <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                            :
                                                            <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                        }
                                                    </Button>
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </Box>
                    </div> : null}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getKeratometrySuggestion = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_KERATOMETRY_SUGGESTION + `?doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let dioptreData = []
                        response?.data?.dioptre?.forEach((item) => {
                            let constantDiopterData = JSON.parse(JSON.stringify(DIOPTER_OPTIONS))
                            if (!constantDiopterData.some((element) => (element.value === item))) {
                                if (item) {
                                    dioptreData.push({
                                        label: item,
                                        value: item,
                                    })
                                }
                            }
                        })
                        let axisData = []
                        response?.data?.axis?.forEach((item) => {
                            let constantAxisData = JSON.parse(JSON.stringify(AXIS_OPTIONS))
                            if (!constantAxisData.some((element) => (element.value === item))) {
                                if (item) {
                                    axisData.push({
                                        label: item,
                                        value: item,
                                    })
                                }
                            }
                        })
                        let mmData = []
                        response?.data?.mm?.forEach((item) => {
                            let constantMmData = JSON.parse(JSON.stringify(MM_OPTIONS))
                            if (!constantMmData.some((element) => (element.value === item))) {
                                if (item) {
                                    mmData.push({
                                        label: item,
                                        value: item,
                                    })
                                }
                            }
                        })
                        this.setState({
                            diopter: dioptreData,
                            mm: mmData,
                            axis: axisData,
                        }, () => {
                            let originalDiopter = JSON.parse(JSON.stringify(DIOPTER_OPTIONS)).concat(dioptreData)
                            let originalMm = MM_OPTIONS.concat(mmData)
                            let originalAxis = AXIS_OPTIONS.concat(axisData)

                            this.setState({
                                diopter: originalDiopter,
                                mm: originalMm,
                                axis: originalAxis
                            })
                        }
                        )
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleChipDelete = () => {
        let diopterKey = this.state.header === "Dioptre" ? this.state.selectedChip.label : ""
        let mmKey = this.state.header === "MM" ? this.state.selectedChip.label : ""
        let axisKey = this.state.header === "AXIS" ? this.state.selectedChip.label : ""

        try {
            if (this.state.selectedCurvature) {
                RestAPIService.delete(`${Serviceurls.DOC_KERATOMETRY_CURVATURE}?curvature=${encodeURIComponent(this.state.selectedCurvature)}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                    .then(response => {
                        if (response.data.status === "success") {
                            if (this.state.selectedCurvature === this.state.Curvature) {
                                let curvature = ""
                                if (this.state.selectedEye === "Left") {
                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                        if (this.state.LeftKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                            curvature = this.state.CurvatureNameList[i]
                                            break;
                                        }
                                    }
                                } else {
                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                        if (this.state.RightKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                            curvature = this.state.CurvatureNameList[i]
                                            break;
                                        }
                                    }
                                }
                                this.setState({ Curvature: curvature })
                            }
                            this.setState({ isDeleteClicked: false, selectedCurvature: null }, () => { this.getCurvatureDatas() })
                            this.successMessage(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message);
                        }
                    });
            }
            else if (this.state.selectedKerato) {
                RestAPIService.delete(`${Serviceurls.DOC_KERATOMETRY}?id=${this.state.selectedKerato?.id}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.getKeratometry()
                            this.setState({ isDeleteClicked: false, selectedKerato: null })
                            this.successMessage(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message);
                        }
                    });
            }
            else {
                RestAPIService.delete(`${Serviceurls.DOC_KERATOMETRY_SUGGESTION}?dioptre=${diopterKey}&axis=${axisKey}&mm=${mmKey}&doctor_id=${this.props.doctorID}`)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.getKeratometrySuggestion()
                            this.setState({ isDeleteClicked: false, })
                            this.successMessage(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message);
                        }
                    });
            }

        } catch (e) {
            this.errorMessage(e.message, false);
        }
    }

    returnKeratoData = () => {
        let CurvDataList = this.state.CurvatureData ? Object.keys(this.state.CurvatureData) : []
        let FinalData = []
        CurvDataList.forEach((item) => {
            if (this.state.CurvatureData[item].Dioptre !== "" || this.state.CurvatureData[item].MM !== "" || this.state.CurvatureData[item].AXIS !== "") {
                let newObj = {
                    "curvature": item,
                    "dioptre": this.state.CurvatureData[item].Dioptre,
                    "mm": this.state.CurvatureData[item].MM,
                    "axis": this.state.CurvatureData[item].AXIS
                };
                if (this.state.CurvatureData[item].id) { newObj["id"] = this.state.CurvatureData[item].id; }
                FinalData.push(newObj);
            }
        })
        return FinalData
    }

    PostKeratometry = () => {
        let states = this.state


        try {
            const { selectedEye } = this.state
            var data = {
                "appointment_id": states.appointmentId,
                "patient_id": states.patientId,
                "eye_type": selectedEye === "Left" ? "LE" : "RE",
                "data": this.returnKeratoData(),
                "doctor_id": this.props.doctorID,
            }
            if (this.returnKeratoData().length > 0) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_KERATOMETRY)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.setState({
                                isEdit: false,
                                selectedText: "",
                                NewText: "",
                            }, () => {
                                this.handleClear()
                                this.setState({
                                    postLoad: false, openPopUp: false,
                                    comparePopup: false,
                                    showRight: false, showLeft: false
                                }, () => {
                                    this.getKeratometry()
                                    this.getKeratometrySuggestion()
                                    this.getCurvatureDatas()
                                })
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage("Select Any Value") }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    renderChip = (Heading, values, ref, scrollTo, constant) => {
        let states = this.state
        let sortedValue
        if (Heading == "MM") {
            if (values?.length > 0) {
                let alphaArray = []
                let NumArray = []
                values.forEach((item) => {
                    if (isNaN(+item?.value)) {
                        alphaArray.push(item)
                    } else {
                        NumArray.push(item)
                    }
                });
                alphaArray.sort((a, b) => {
                    let valueA = a?.value?.toUpperCase(); // Convert to uppercase for case-insensitive comparison
                    let valueB = b?.value?.toUpperCase();
                    if (valueA < valueB) {
                        return -1; // a should come before b in the sorted order
                    }
                    if (valueA > valueB) {
                        return 1; // a should come after b in the sorted order
                    }
                    return 0; // a and b are considered equal
                });
                // alphaArray.sort(function (a, b) { return (b.value - a.value) }) 
                NumArray.sort(function (a, b) { return (+b.value - +a.value) })
                sortedValue = NumArray.concat(alphaArray)
            }
        }
        else {
            if (values?.length > 0) {
                let alphaArray = []
                let NumArray = []
                values.forEach((item) => {
                    if (isNaN(+item?.value)) {
                        alphaArray.push(item)

                    } else {
                        NumArray.push(item)
                    }
                });
                alphaArray.sort((a, b) => {
                    let valueA = a?.value?.toUpperCase();
                    let valueB = b?.value?.toUpperCase();
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                    return 0;
                });
                NumArray.sort(function (a, b) { return (+a.value - +b.value) })
                sortedValue = NumArray.concat(alphaArray)
            }
        }
        return (
            <div ref={ref}>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        sortedValue && sortedValue.map((item, index) => {
                            if (item || item?.value) {
                                let SelectedValue = this.state.CurvatureData && this.state.Curvature && this.state.CurvatureData[this.state.Curvature] ? this.state.CurvatureData[this.state.Curvature][Heading] : ""
                                return (
                                    <Chip
                                        className={`eMed_chip ${item?.label === SelectedValue ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                        style={{ width: "7vw" }}
                                        label={item?.label}
                                        variant={item?.label === SelectedValue ? 'contained' : 'outlined'}
                                        key={index}
                                        onDelete={constant.some((element) => (element?.value === item?.value)) ? null : () => {
                                            if (item.isFromLocal) {
                                                if (Heading == "Dioptre") {
                                                    let newArray = this.state.diopter.filter((list) => list.value !== item.value)
                                                    this.setState({ diopter: newArray })
                                                }
                                                else if (Heading == "MM") {
                                                    let newArray = this.state.mm.filter((list) => list.value !== item.value)
                                                    this.setState({ mm: newArray })
                                                }
                                                else if (Heading == "AXIS") {
                                                    let newArray = this.state.axis.filter((list) => list.value !== item.value)
                                                    this.setState({ axis: newArray })
                                                }
                                            }
                                            else {
                                                this.setState({ isDeleteClicked: true, selectedChip: item, header: Heading })
                                            }
                                        }}
                                        onClick={() => {
                                            if (this.state.Curvature && states.CurvatureData[this.state.Curvature]) {
                                                if (item?.label === SelectedValue) {
                                                    states.CurvatureData[this.state.Curvature][Heading] = ""
                                                    this.setState({ states })
                                                } else {
                                                    states.CurvatureData[this.state.Curvature][Heading] = item?.value
                                                    this.setState({ states })
                                                }
                                            } else {
                                                this.errorMessage("Please select curvature")
                                            }

                                            this.scrollToRef(scrollTo)
                                        }}
                                        clickable
                                    />)
                            } else {
                                return null
                            }

                        })

                    }
                </div>
                <div>
                    {this.state.selectedText != Heading ?
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', marginBottom: "0.5vw" }}
                            variant='contained'
                            onClick={() => { this.setState({ selectedText: Heading, NewText: "" }) }}
                        >Add</Button>
                        : null}
                </div>
            </div>
        )
    }

    onBlurRHSValue = (Heading, optionData, addBtnContains) => {
        if (addBtnContains) {
            let states = this.state
            let value = (states.CurvatureData && states.CurvatureData[states.Curvature] && states.CurvatureData[states.Curvature][Heading]) ? states.CurvatureData[states.Curvature][Heading] : '';
            if (value && !optionData?.some((item) => item.value === value)) {
                let data = { label: CommonValidation.removeSpace(value), value: CommonValidation.removeSpace(value) }
                optionData?.push(data)
                this.setState({ states })
            }
        }
    }

    onChangeRHSValue = (Heading, value, addBtnContains = false, fromSelect = false) => {
        if (addBtnContains || fromSelect) {
            let states = this.state
            states.CurvatureData[states.Curvature][Heading] = value
            this.setState({ states })
        }
    }

    renderRightSideValues = (Heading, value, optionData = [], addBtnContains = false) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <Autocomplete
                    freeSolo={addBtnContains ? true : false}
                    inputProps={{ maxLength: 250 }}
                    sx={{ width: '13.5dvw' }}
                    value={value ? value : ''}
                    options={optionData}
                    getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.label}
                    onChange={(e, newValue) => {
                        this.onChangeRHSValue(Heading, newValue?.value, addBtnContains, false)
                    }}
                    clearIcon={false}
                    size='small'
                    renderInput={(params) =>
                        <TextField {...params} label={Heading}
                            inputProps={{
                                ...params.inputProps,
                                maxLength: 10,
                            }}
                            onChange={(e) => {
                                this.onChangeRHSValue(Heading, e.target.value, addBtnContains, false)
                            }}
                        />}
                    onBlur={() => { this.onBlurRHSValue(Heading, optionData, addBtnContains) }}
                />
            </div>
        )
    }

    handleClear = () => {
        let NewArr = {}
        this.state.CurvatureNameList.forEach((item) => {
            NewArr[item] = {
                "Dioptre": "",
                "MM": "",
                "AXIS": "",
                "Curvature": item
            }
        })
        this.setState({
            isEdit: false,
            CurvatureData: NewArr,
        })
    }
    popupClose = () => {
        this.setState({
            isDeleteClicked: false
        })
    }
    renderNewText = (Heading, values, Tab) => {
        let states = this.state
        return (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                <TextField
                    label={(`Add ${Heading}`)}
                    size="small"
                    style={{ marginTop: "0.5vw", width: "20vw" }}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    value={states.NewText}
                    onChange={(event) => {
                        let value = event.target.value
                            this.setState({ NewText: value })
                    }}
                />
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        if (this.state.NewText === "") {
                            this.setState({ selectedText: "", NewText: "" })
                        } else {
                            let isDuplicate = []
                            switch (Heading) {
                                case "Dioptre":
                                    isDuplicate = this.state.diopter.filter((item) => this.state.NewText === item.value)
                                    break;
                                case "MM":
                                    isDuplicate = this.state.mm.filter((item) => this.state.NewText === item.value)
                                    break;
                                case "AXIS":
                                    isDuplicate = this.state.axis.filter((item) => this.state.NewText === item.value)
                                    break;
                            }

                            if (isDuplicate.length > 0) {
                                this.state.CurvatureData[this.state.Curvature][Heading] = this.state.NewText
                                this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                            } else {
                                let data = { label: this.state.NewText, value: this.state.NewText, isFromLocal: true }
                                values.push(data)
                                this.state.CurvatureData[this.state.Curvature][Heading] = this.state.NewText
                                this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                            }
                        }
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                </div>
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        this.setState({ selectedText: "", NewText: "" })
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                </div>
            </div>
        )
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "80vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Keratometry</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false, showRight: false, showLeft: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Keratometry</Typography>
                                    </div>
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Keratometry</Typography>
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>{this.renderKeratomentryTable()}</div>
                                </div>
                            </Box>

                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
    isCurvatureHaveData = (Curvature) => {
        if (this.state.selectedEye === "Right") {
            return this.state.RightKeratometryList.some((item) => (item.curvature === Curvature))
        } else {
            return this.state.LeftKeratometryList.some((item) => (item.curvature === Curvature))
        }
    }

    KeratomentryPopup = () => {
        let states = this.state
        let { selectedText } = this.state
        let CurvDataList = this.state.CurvatureData ? Object.keys(this.state.CurvatureData) : []
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "83vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{`Keratometry - ${states.selectedEye} Eye ${states.Curvature && states.isEdit ? ("-" + states.Curvature) : ""}`}</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, selectedText: "", NewText: "" }, () => { this.handleClear() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%", marginTop: "0.75vw" }}>
                                    <Box component={"div"} style={{ padding: "0.75vw" }}>
                                        <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "65vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                            <div>
                                                {this.renderChip("Dioptre", this.state.diopter, this.DIOPTRE, this.MM, DIOPTER_OPTIONS)}
                                                {selectedText == "Dioptre" ? this.renderNewText("Dioptre", this.state.diopter, this.state.Curvature) : null}
                                                {this.renderChip("MM", this.state.mm, this.MM, this.AXIS, MM_OPTIONS)}
                                                {selectedText == "MM" ? this.renderNewText("MM", this.state.mm, this.state.Curvature) : null}
                                                {this.renderChip("AXIS", this.state.axis, this.AXIS, this.AXIS, AXIS_OPTIONS)}
                                                {selectedText == "AXIS" ? this.renderNewText("AXIS", this.state.axis, this.state.Curvature) : null}
                                            </div>
                                        </Box>
                                    </Box>
                                </div>
                                <div style={{ width: "40%" }}>
                                    <div style={{ marginLeft: "1vw", fontWeight: "600", marginTop: "2.5vw" }}>{"Select Curvature Values"}</div>
                                    <Box sx={{ marginLeft: "1.35vw", marginTop: "0.75vw", display: 'flex', flexWrap: 'wrap', }}>
                                        {
                                            CurvDataList.map((list, index) => (
                                                <Chip
                                                    className={`eMed_chip ${this.state.Curvature == list ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    style={{ width: "7vw" }}
                                                    label={list}
                                                    disabled={this.state.isEdit || this.isCurvatureHaveData(list)}
                                                    variant={this.state.Curvature == list ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onDelete={["K1", "K2", "Average", "Cylindrical"].includes(list) ? null : () => {
                                                        if (this.state.CurvatureNameList.includes(list)) {
                                                            this.setState({ isDeleteClicked: true, selectedCurvature: list, header: "Curvature" })
                                                        } else {
                                                            if (list === this.state.Curvature) {
                                                                let curvature = ""
                                                                if (this.state.selectedEye === "Left") {
                                                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                                                        if (this.state.LeftKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                                                            curvature = this.state.CurvatureNameList[i]
                                                                            break;
                                                                        }
                                                                    }
                                                                } else {
                                                                    for (let i = 0; i <= this.state.CurvatureNameList.length; i++) {
                                                                        if (this.state.RightKeratometryList.every((list) => (list.curvature !== this.state.CurvatureNameList[i] && list.curvature))) {
                                                                            curvature = this.state.CurvatureNameList[i]
                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                                states.Curvature = curvature
                                                            }
                                                            delete states.CurvatureData[list]
                                                            this.setState({ states })
                                                        }
                                                    }}
                                                    onClick={() => { this.setState({ Curvature: list }, () => this.scrollToRef(this.DIOPTRE)) }}
                                                    clickable
                                                />
                                            ))
                                        }
                                        {this.state.NewCurvatureAdd ?
                                            <TextField
                                                size='small'
                                                value={this.state.NewCurvatureName}
                                                onChange={(e) => {
                                                    this.setState({ NewCurvatureName: e.target.value })
                                                }}
                                                inputProps={{ maxLength: 35, style: { height: "0.8vw" } }}
                                                sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                                                onKeyDown={(e) => {
                                                    let NewCurvatureName = this.state.NewCurvatureName ? CommonValidation.removeSpace(this.state.NewCurvatureName) : ""
                                                    if (e.key === 'Enter' && NewCurvatureName) {
                                                        let List = this.state.CurvatureData ? Object.keys(this.state.CurvatureData) : []
                                                        let isVaccIsAlreadyHave = ((List.some((list) => (list.toLowerCase() === NewCurvatureName.toLowerCase() && NewCurvatureName))))
                                                        if (!isVaccIsAlreadyHave) {
                                                            this.state.CurvatureData[NewCurvatureName] = {
                                                                "Dioptre": "",
                                                                "MM": "",
                                                                "AXIS": "",
                                                                "Curvature": NewCurvatureName
                                                            }
                                                            this.setState({
                                                                CurvatureData: this.state.CurvatureData,
                                                                Curvature: NewCurvatureName,
                                                                NewCurvatureName: "",
                                                                NewCurvatureAdd: false,
                                                            }, () => { this.scrollToRef(this.DIOPTRE) })
                                                        } else {
                                                            this.errorMessage("Curvature already exists")
                                                        }

                                                    }
                                                }}
                                            /> :
                                            <Tooltip title="Add new vaccination" placement='top' arrow>
                                                <IconButton
                                                    disabled={this.state.isEdit}
                                                    onClick={() => {
                                                        this.setState({
                                                            NewCurvatureAdd: true
                                                        })
                                                    }}>
                                                    <AddIcon color={this.state.isEdit ? 'grey' : 'primary'} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                    <Box component={'div'} style={{ marginTop: "0.75vw", marginLeft: "1vw", height: "22vh", overflow: "scroll" }}>
                                        <div style={{ fontWeight: "600", marginBottom: "0.75vw", marginTop: "0.5vw" }}>{states.Curvature ? states.Curvature : "-"}</div>
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                {this.renderRightSideValues("Dioptre", this.state.CurvatureData[this.state.Curvature]?.Dioptre ? this.state.CurvatureData[this.state.Curvature]?.Dioptre : "", DIOPTER_OPTIONS, true)}
                                                {this.renderRightSideValues("MM", this.state.CurvatureData[this.state.Curvature]?.MM ? this.state.CurvatureData[this.state.Curvature]?.MM : "", MM_OPTIONS, true)}
                                                {this.renderRightSideValues("AXIS", this.state.CurvatureData[this.state.Curvature]?.AXIS ? this.state.CurvatureData[this.state.Curvature]?.AXIS : "", AXIS_OPTIONS, true)}
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => {
                                        let states = this.state
                                        if (this.state.isEdit) {
                                            states.CurvatureData[this.state.Curvature] = {
                                                "Dioptre": "",
                                                "MM": "",
                                                "AXIS": "",
                                                "Curvature": this.state.Curvature,
                                                "id": states.CurvatureData[this.state.Curvature]?.id
                                            }
                                            this.setState({ CurvatureData: this.state.CurvatureData })
                                        } else {
                                            let NewArr = {}
                                            this.state.CurvatureNameList.forEach((item) => {
                                                NewArr[item] = {
                                                    "Dioptre": "",
                                                    "MM": "",
                                                    "AXIS": "",
                                                    "Curvature": item
                                                }
                                            })
                                            this.setState({
                                                CurvatureData: NewArr,
                                                NewCurvatureName: "",
                                                NewCurvatureAdd: false,
                                            })
                                        }

                                    }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostKeratometry() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "2.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Keratometry</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box component={'div'}>
                                    {this.renderKeratomentryTable()}
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.KeratomentryPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.isDeleteClicked ? <DeletePopup
                        DeletTitle={this.state.selectedKerato ? `Are you sure ? Do you want to delete - ${this.state.selectedKerato?.curvature} ?` : this.state.selectedCurvature ? `Are you sure ? Do you want to remove curvature - ${this.state.selectedCurvature} ?` : `Are you sure you want to delete this item ? `}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.handleChipDelete.bind(this)}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}