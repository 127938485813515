import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, Tooltip, MenuItem, IconButton, Menu, Box } from '@mui/material';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import './../../../Laboratory/Home/home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
class SavedBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            SavedBillData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            SelectedBill: {},
            disableBtn: false,
        }
    }
    componentDidMount() {
        this.getSavedBill()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getSavedBill() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.SAVED_BILL_HOME + "?search=" + this.state.searchkey)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            SavedBillData: response.data.data
                        }, ()=>{this.LoaderFunction(false)});
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.reponse.data.status)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                });
        }
        catch (e) {
            this.errorMsgFunc(e.message)
        }
    }

    DeleteSavedBill() {
        try {
            var data = {}
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.PHARMA_SAVED_BILL_DELETE + this.state.SelectedBill?.bill_summary_id + "/delete/")
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            SelectedBill: {},
                        }, () => {
                            this.getSavedBill()
                            this.props?.getPharmaSavedBillCount(true)
                            this.successMsgFunc(response.data?.message)
                            this.setState({disableBtn: false})
                        });
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.errorMsgFunc(e.reponse.data.status)
                    } else {
                        this.errorMsgFunc(e.message)
                    }
                    this.setState({disableBtn: false})
                });
        }
        catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({disableBtn: false})
        }
    }
    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    // getSavedBillPrint = (patient_id) => {
    //     RestAPIService.getAll(Serviceurls.SAVED_BILL_HOME + `?export_type=pdf&patient_id=${patient_id}`).
    //         then((response) => {
    //             const file = new Blob(
    //                 [response.data],
    //                 { type: 'application/pdf' });
    //             const fileURL = URL.createObjectURL(file);
    //             window.open(fileURL);
    //         }).catch((error) => {
    //             this.setState({
    //                 isErrorMsg: true,
    //                 isErrorMsgText: error.reponse.data.message
    //             }, () => {
    //                 setTimeout(() => {
    //                     this.setState({ isErrorMsg: false })
    //                 }, 2000);
    //             })
    //         })
    // }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    popupClose() {
        this.setState({
            SelectedBill: {},
        })
    }

    getSavedPrint = (data, option) => {
        try {
            let url = ''
            if (option === "TokenPrint") {
                url = Serviceurls.PHARMA_BILL_TOKEN_PRINT
            } else {
                url = Serviceurls.PHARMA_SALES_BILL_PRINT
            }
            RestAPIService.getAllPrint(url + `?bill_summary_id=${data?.bill_summary_id}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isSelected : false, selectedRow: null})
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMsgFunc(e?.response?.data?.message)
                    } else {
                        this.errorMsgFunc(e?.message)
                    }
                    this.setState({ isSelected : false, selectedRow: null})
                })
        } catch (e) {
            this.errorMsgFunc(e.message)
            this.setState({ isSelected : false, selectedRow: null})
        }
    }

    MoreMenus = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "SalesBillPrint", label: t("Sales Bill Print") },
            { value: "TokenPrint", label: t("Token Print") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data?.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data?.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem onClick={() => {
                                this.getSavedPrint(data, option?.value)
                            }}>
                                {option.label}
                            </MenuItem>))}
                    </Menu>
                    : null}
            </div>
        )
    }

    render() {
        const { t } = this.props
        const { history } = this.props.properties
        let Settings = localGetItem("PharmaSettings") === null ? {} : JSON.parse(localGetItem("PharmaSettings"))
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{t("SavedBill")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickSavedBillClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt=""
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <OutlinedInput
                        placeholder="Search Bill..."
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            }, () => {
                                this.getSavedBill()
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0vw" }}
                        endAdornment={
                            <InputAdornment position="end">
                                <i class="fas fa-search"></i>
                            </InputAdornment>
                        }
                        inputProps={{ emed_tid :"saved_toBill_search"}}
                    />
                </div>
                {this.state.SavedBillData && this.state.SavedBillData.length > 0 ?
                    <div className='eMed_Saved_DataView'>
                        {this.state.SavedBillData.map((item, index) => {
                            return (
                                <div className="Lab_Home_Saved_Bill">
                                    <div style={{ flex: 0.08 }}>{index + 1}</div>
                                    <div style={{ flex: 0.10 }}><img src={ImagePaths.Male.default} alt='Avatar' /></div>
                                    <div style={{ flex: 0.24 }}>
                                        {item?.billing_customer_name?.length > 11 ?
                                            <Tooltip title={item?.billing_customer_name} placement='top'>
                                                <Typography className='savedBillText'>{item?.billing_customer_name ? item?.billing_customer_name.slice(0, 11) + '...' : '-'}</Typography>
                                            </Tooltip> : <Typography className='savedBillText'>{item?.billing_customer_name ? item?.billing_customer_name : '-'}</Typography>}
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.billing_customer_mobile_no ? item?.billing_customer_mobile_no : '-'}</Typography>
                                    </div>
                                    <div style={{ flex: 0.24 }}>
                                        {item?.doctor_name ? item?.doctor_name?.length > 12 ?
                                            <Tooltip title={item?.doctor_name} placement='top'>
                                                <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name.slice(0, 12) + '...' : '-'}</Typography>
                                            </Tooltip> : <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name : '-'}</Typography> : "-"}
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.patient_account_number ? item?.patient_account_number : '-'}</Typography>
                                    </div>
                                    <div style={{ flex: 0.26 }}>
                                        {(!this.props?.isFromSubLocation && RoleData?.show_pharmacy_sales_token) ?
                                            <div>
                                                {item?.temp_invoice_sequence ? item?.temp_invoice_sequence?.length > 4 ?
                                                    <Tooltip title={item?.temp_invoice_sequence} placement='top'>
                                                        <Typography className='savedBillText'>Token No: {'...' + item?.temp_invoice_sequence?.slice(-4)}</Typography>
                                                    </Tooltip> : <Typography className='savedBillText'>Token No: {item?.temp_invoice_sequence ? item?.temp_invoice_sequence : '-'}</Typography> : "-"}
                                                <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{(item?.created_date ? item?.created_date : '-') + "/" + (item?.created_time ? item?.created_time : '-')}</Typography>
                                            </div>
                                            :
                                            <div>
                                                <Typography className='savedBillText'>{item?.created_date ? item?.created_date : '-'}</Typography>
                                                <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.created_time ? item?.created_time : '-'}</Typography>
                                            </div>}
                                    </div>
                                    <Box sx={{ display: 'flex', flex: 0.10 }}>
                                        <IconButton
                                            emed_tid="saved_toBill_create"
                                            onClick={() => {
                                                if (this.props?.isFromSubLocation) {
                                                    this.props.properties.history.push({ pathname: "/SubLocationBilling", state: { Patient_ID: item?.patient_id, Bill_ID: item?.bill_summary_id, isFromSavedBill: true, billDetails: item } })
                                                    this.props.onClickSavedBillClose(false)
                                                } else {
                                                    this.props.properties.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: item?.patient_id, Bill_ID: item?.bill_summary_id, isFromSavedBill: true, billDetails: item } })
                                                    this.props.onClickSavedBillClose(false)
                                                }
                                            }}>
                                            <img style={{ width: '1.5vw', height: '1.5vw', cursor: 'pointer' }} src={ImagePaths.CreateBill.default} alt='delete' />
                                        </IconButton>
                                        {
                                            (Settings?.saved_bill_restriction || !RoleData.is_user) ?
                                                <IconButton emed_tid="saved_toBill_delete" onClick={() => { this.setState({ SelectedBill: item }) }}>
                                                    <img style={{ width: '1.5vw', height: '1.5vw', cursor: 'pointer' }} src={ImagePaths.Delete.default} alt='delete' />
                                                </IconButton> : null
                                        }
                                        {(!this.props?.isFromSubLocation && RoleData?.show_pharmacy_sales_token) ? this.MoreMenus(item) : null}
                                    </Box>

                                </div>
                            )
                        })}


                    </div> :
                    this.state.isLoader ? <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}} className='eMed_Saved_DataView'><CircularProgress/></div>:<div><Typography id='eMed_Pres_pop_NORec'>{t("No records found")}</Typography></div>}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {Object.keys(this.state.SelectedBill).length > 0 ? <DeletePopup
                    DeletTitle={"Are you sure ? Do you want to delete this Saved bill ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DeleteSavedBill.bind(this)}
                    testID="Saved_toBill_deleteOk"
                    BillType="Saved Bill"
                    disable={this.state.disableBtn}
                /> : null}
            </div>
        );
    }
}
export default withTranslation()(SavedBill)