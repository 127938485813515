import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Autocomplete, Box, Button, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import '../../Pharmacy/PharmacyReports/pharmaReports.css';
import { Colors } from '../../../Styles/Colors';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { formatDate, } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import CircularProgress from '@mui/material/CircularProgress';

class PharmaDrugWiseSTReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            drugWiseSTData: [],
            page: 0,
            pageSize: 10,
            isLoader: false,
            days: 90,
            brand_suggestionList: [],
            LocationNameList: [],
            filterDatas: {
                dateIndex: 1,
                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                fromLocation: null,
                toLocation: null,
                brand_name: null,
                fromLocationOption: [],
                toLocationOption: []
            }
        }
    }

    componentDidMount() {
        this.getDrugWiseSTData()
        this.getLocationListData()
    }

    getDrugWiseSTData = () => {
        try {
            this.LoaderFunction(true);
            let { filterDatas } = this.state
            let fromLocModuleType = filterDatas?.fromLocation?.module_type ? filterDatas?.fromLocation?.module_type : ''
            let toLocModuleType = filterDatas?.toLocation?.module_type ? filterDatas?.toLocation?.module_type : ''
            let fromLocName = filterDatas?.fromLocation?.module_name ? filterDatas?.fromLocation?.module_name : ''
            let toLocName = filterDatas?.toLocation?.module_name ? filterDatas?.toLocation?.module_name : ''
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_DRUGWISE_STOCKTRANSFER_REPORT + `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&drug_name=${filterDatas.brand_name ? filterDatas.brand_name?.drug_ms_id : ''}&from_location=${fromLocName}&to_location=${toLocName}&module_type_from=${fromLocModuleType}&module_type_to=${toLocModuleType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            drugWiseSTData: response.data?.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getDrugName = (brandName) => {
        try {
            this.brandLoader(true);
            let brand_name = brandName ? brandName?.replaceAll('&', '%26') : ''
            RestAPIService.getAll(`${Serviceurls.PHARMA_BILLING_BRAND_SEARCH}?search=${brand_name}&full_drug_list=true`)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response?.data;
                        this.brandLoader(false);
                        this.setState({
                            brand_suggestionList: response?.data?.data
                        })
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.brandLoader(false);
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.brandLoader(false);
                        this.errorMessage(error.message);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getLocationListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.SUBLOC_LOCATION_DETAILS)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            LocationNameList: response.data.data ? response.data.data : [],
                            fromLocationOption: response.data.data ? response.data.data : [],
                            toLocationOption: response.data.data ? response.data.data : [],
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDrugWiseSTPrint = () => {
        try {
            this.setState({ disableBtn: true })
            let { filterDatas } = this.state
            let fromLocModuleType = filterDatas?.fromLocation?.module_type ? filterDatas?.fromLocation?.module_type : ''
            let toLocModuleType = filterDatas?.toLocation?.module_type ? filterDatas?.toLocation?.module_type : ''
            let fromLocName = filterDatas?.fromLocation?.module_name ? filterDatas?.fromLocation?.module_name : ''
            let toLocName = filterDatas?.toLocation?.module_name ? filterDatas?.toLocation?.module_name : ''
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_DRUGWISE_STOCKTRANSFER_REPORT + `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&drug_name=${filterDatas.brand_name ? filterDatas.brand_name?.drug_ms_id : ''}&from_location=${fromLocName}&to_location=${toLocName}&module_type_from=${fromLocModuleType}&module_type_to=${toLocModuleType}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    getDrugWiseSTExcel = () => {
        try {
            this.setState({ disableBtn: true })
            let { filterDatas } = this.state
            let fromLocModuleType = filterDatas?.fromLocation?.module_type ? filterDatas?.fromLocation?.module_type : ''
            let toLocModuleType = filterDatas?.toLocation?.module_type ? filterDatas?.toLocation?.module_type : ''
            let fromLocName = filterDatas?.fromLocation?.module_name ? filterDatas?.fromLocation?.module_name : ''
            let toLocName = filterDatas?.toLocation?.module_name ? filterDatas?.toLocation?.module_name : ''
            RestAPIService.excelGet(Serviceurls.PHA_BRANCH_DRUGWISE_STOCKTRANSFER_REPORT + `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&drug_name=${filterDatas.brand_name ? filterDatas.brand_name?.drug_ms_id : ''}&from_location=${fromLocName}&to_location=${toLocName}&module_type_from=${fromLocModuleType}&module_type_to=${toLocModuleType}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.setState({ disableBtn: false })
            this.errorMessage(error.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    handleLocation = (value, stateKey) => {
        let states = this.state
        states.filterDatas[stateKey] = value
        if (states.LocationNameList?.length > 0) {
            if (stateKey === "toLocation") {
                states["fromLocationOption"] = states.LocationNameList?.filter((item) => (item?.id !== value?.id || item?.module_type !== value?.module_type))
            } else {
                states["toLocationOption"] = states.LocationNameList?.filter((item) => (item?.id !== value?.id || item?.module_type !== value?.module_type))
            }
        }
        this.setState({ states })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let { filterDatas } = this.state
        return (
            <Box display={'flex'} flexDirection={'row'} mt={'.5vw'} mr={'.8vw'} ml={'.8vw'} alignItems={'center'}>
                <Box sx={{ width: "15%" }}>
                    <CommonDatePicker
                        FromDate={filterDatas.fromDate}
                        ToDate={filterDatas.toDate}
                        DateIndex={filterDatas.dateIndex}
                        SelectedDates={this.filterDate.bind(this)}
                        HideAllMenu={true}
                        days={filterDatas.days}
                        HideDate={true}
                        height={true}
                        LabelText={"Requested Date"}
                        customLeft={200}
                    />
                </Box>
                <Autocomplete
                    name={"brand_name"}
                    value={filterDatas.brand_name ? filterDatas.brand_name?.drug_name : null}
                    sx={{ width: "15vw", marginRight: '1vw' }}
                    options={this.state.brand_suggestionList?.length > 0 ? this.state.brand_suggestionList : []}
                    getOptionLabel={(option) => (typeof (option?.drug_name) === "string" ? option?.drug_name : option)}
                    onChange={(event, newBrand) => {
                        let { filterDatas } = this.state
                        filterDatas["brand_name"] = newBrand ? newBrand : null
                        this.setState({
                            filterDatas
                        })
                    }}
                    onInputChange={(event, newBrand) => {
                        if (newBrand?.length > 2) {
                            this.getDrugName(newBrand);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Drug Name")}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                emed_tid: "Drug_Name",
                                endAdornment: this.state.isBrandLoader ?
                                    <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                    : null,
                            }}
                        />
                    )}
                />
                <Autocomplete
                    size='small'
                    sx={{ width: "15vw", marginRight: '1vw' }}
                    options={this.state.fromLocationOption ? this.state.fromLocationOption : []}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.module_name}
                    value={filterDatas?.fromLocation?.module_name ? filterDatas?.fromLocation?.module_name : ''}
                    onChange={(e, newValue) => { this.handleLocation(newValue, "fromLocation") }}
                    renderInput={(params) => <TextField {...params} label={"From Location"} inputProps={{ ...params.inputProps, 'emed_tid': 'from_location_testId' }} />}
                />
                <Autocomplete
                    size='small'
                    sx={{ width: "15vw", marginRight: '1vw' }}
                    options={this.state.toLocationOption ? this.state.toLocationOption : []}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.module_name}
                    value={filterDatas?.toLocation?.module_name ? filterDatas?.toLocation?.module_name : ''}
                    onChange={(e, newValue) => { this.handleLocation(newValue, "toLocation") }}
                    renderInput={(params) => <TextField {...params} label={"To Location"} inputProps={{ ...params.inputProps, 'emed_tid': 'to_location_testId' }} />}
                />
                <Box display={'flex'} gap={'1vw'}>
                    <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', width: '10vw' }} emed_tid='clear_filter_testID'
                        onClick={() => {
                            let { filterDatas } = this.state
                            filterDatas["brand_name"] = null
                            filterDatas["dateIndex"] = 1
                            filterDatas["fromDate"] = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
                            filterDatas["toDate"] = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
                            filterDatas["days"] = 90
                            filterDatas["fromLocation"] = null
                            filterDatas["toLocation"] = null
                            this.setState({ filterDatas }, () => {
                                this.getDrugWiseSTData()
                            })
                        }}>
                        Clear
                    </Button>
                    <Button size='small' variant='contained' className='eMed_Canteen_BtnText' emed_tid='search_filter_testID' sx={{ height: '2vw', width: '10vw' }} onClick={() => { this.getDrugWiseSTData() }}>Search</Button>
                </Box>
            </Box>
        )
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    filterDate = (from, to, index) => {
        let { filterDatas } = this.state
        filterDatas['fromDate'] = from
        filterDatas['toDate'] = to
        filterDatas['dateIndex'] = index
        this.setState({ filterDatas })
    }
    brandLoader = (key) => {
        this.setState({
            isBrandLoader: key
        })
    }
    render() {
        const { t } = this.props
        this.state.drugWiseSTData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'request_number', headerName: "Req Details", flex: 0.11, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        <Typography fontWeight={"bold"}>{params?.row?.request_number ?
                            params?.row?.request_number?.length > 12 ?
                                <Tooltip title={params?.row?.request_number} placement='top'><Typography>{params?.row?.request_number?.slice(0, 11) + '...'}</Typography></Tooltip>
                                : params?.row?.request_number : '-'}</Typography>
                        <Typography fontSize={'0.8vw'}>{params?.row?.request_date ? formatDate(params?.row?.request_date) : '-'}</Typography>
                    </Box>
                )
            },
            {
                field: 'brand_name', headerName: "Drug Details", flex: 0.11, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        <Typography fontWeight={"bold"}>{params?.row?.brand_name ?
                            params?.row?.brand_name?.length > 14 ?
                                <Tooltip title={params?.row?.brand_name} placement='top'><Typography>{params?.row?.brand_name?.slice(0, 14) + '...'}</Typography></Tooltip>
                                : params?.row?.brand_name : '-'}</Typography>
                        {params?.row?.generic_name?.length > 18 ?
                            <Tooltip title={params?.row?.generic_name} placement='top'><Typography fontSize={'0.8vw'}>{params?.row?.generic_name?.slice(0, 15) + '...'}</Typography></Tooltip> : <Typography fontSize={'0.8vw'}>{params?.row?.generic_name ? params?.row?.generic_name : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: 'batch_detail', headerName: "Batch/Expiry", flex: 0.11, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        {params?.row?.batch_detail?.length > 0 ? params?.row?.batch_detail?.map((item, index) => {
                            return (
                                <Box component={'div'} key={index}>
                                    <Typography fontWeight={"bold"}>
                                        {item?.batch_no && item?.expiry_date ? (
                                            item?.batch_no?.length > 7 ? (
                                                <Tooltip title={item?.batch_no} placement='top'>
                                                    <Typography fontSize={'0.8vw'}>
                                                        {item?.batch_no?.slice(0, 6) + '...'} / {formatDate(item?.expiry_date)}
                                                    </Typography>
                                                </Tooltip>
                                            ) : (<Typography fontSize={'0.9vw'}>{`${item?.batch_no}/${item?.expiry_date ? formatDate(item?.expiry_date) : '-'}`}</Typography>)
                                        ) : ('-')}
                                    </Typography>
                                </Box>
                            );
                        }) : "-"}
                    </Box>
                )
            },
            {
                field: 'transfer_from', headerName: `Transfer From`, flex: 0.116, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        {params?.row?.transfer_from?.length > 15 ?
                            <Tooltip title={params?.row?.transfer_from} placement='top'>
                                <Typography>{params?.row?.transfer_from?.slice(0, 15) + '...'}</Typography>
                            </Tooltip>
                            : <Typography>{params?.row?.transfer_from ? params?.row?.transfer_from : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: 'transfer_to', headerName: `Transfer To`, flex: 0.116, type: 'string',
                renderCell: (params) => (
                    <Box component={'div'}>
                        {params?.row?.transfer_to?.length > 15 ?
                            <Tooltip title={params?.row?.transfer_to} placement='top'>
                                <Typography>{params?.row?.transfer_to?.slice(0, 15) + '...'}</Typography>
                            </Tooltip>
                            : <Typography>{params?.row?.transfer_to ? params?.row?.transfer_to : '-'}</Typography>}
                    </Box>
                )
            },
            {
                field: 'transfer_quantity', headerName: `Transferred Quantity`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box>{params.row?.transfer_quantity ? params.row?.transfer_quantity : '0'}</Box>)
            },
            {
                field: 'received_quantity', headerName: `Received Quantity`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box>{params.row?.received_quantity ? params.row?.received_quantity : '0'}</Box>)
            },
            {
                field: 'total_cost', headerName: `Total Cost`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box>{params.row?.total_cost ? params.row?.total_cost : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background, paddingTop: '1vw' }}>
                <Box component={'div'} backgroundColor={'white'} width={'92vw'} height={'16vh'} marginTop={'1vw'} margin={'auto'} border={'1px solid #E3E2E2'} borderRadius={'.3vw'}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={'.5vw'} mr={'.8vw'} ml={'.8vw'}>
                        <Typography fontSize={'1vw'} fontWeight={'bold'}>Filter</Typography>
                        <Box display={'flex'}>
                            <Button emed_tid="rpt_consoli_flt" disabled={this.state.drugWiseSTData?.length === 0 || this.state.disableBtn} className='eMed_Pharma_rts_btn' onClick={() => this.getDrugWiseSTPrint()}>
                                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2vw'} width={'2vw'} />
                            </Button>
                            <Button emed_tid="rpt_saleOver_export" className='eMed_Pharma_rts_btn' disabled={this.state.drugWiseSTData?.length === 0 || this.state.disableBtn} onClick={() => this.getDrugWiseSTExcel()}>
                                <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2vw'} width={'2vw'} />
                            </Button>
                        </Box>
                    </Box>
                    <hr />
                    {this.renderFilterScreen()}
                </Box>
                <Box component={'div'} height={'59vh'} marginTop={'.5vw'}>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.drugWiseSTData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        paginationMode="client"
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                        getRowHeight={() => 'auto'}
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaDrugWiseSTReport)