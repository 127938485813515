import { Box, Button, Drawer, FormHelperText, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';

class StockValue extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stockValueData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        'drugName': '',
        'genericName': '',
        'quantityFrom': '',
        'quantityTo': '',
        "days":30,
        "dosageType_list": [],
        "dosageType": [],
      },
      pageName: 'stockValue',
      filterOpen: false,
      purchaseValue: 0,
      salesValue: 0,
      openFrom: false,
      openTo: false,
      setMonth: 1
    }
  }

  componentDidMount() {
    this.getStockValueData()
    this.getDosageTypeList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => { this.getStockValueData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>

    )
  }

  getStockValueData = () => {
    try {
      let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
      this.LoaderFunction(true)
      // RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?date_search=${this.state.filterDatas.fromDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas.genericName}&quantity_from=${this.state.filterDatas.quantityFrom}&quantity_to=${this.state.filterDatas.quantityTo}`)
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&mode=batch&dosage_type=${this.state.filterDatas?.dosageType}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              stockValueData: response.data.data,
              purchaseValue: response.data?.total_purchase_price,
              salesValue: response.data?.total_mrp_value
            }, () => { this.LoaderFunction(false) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getStockValueExcel = () => {
    try {
      this.setState({ disableBtn: true })
      let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
      // RestAPIService.excelGet(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?date_search=${this.state.filterDatas.fromDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas.genericName}&quantity_from=${this.state.filterDatas.quantityFrom}&quantity_to=${this.state.filterDatas.quantityTo}&export=excel`).
      RestAPIService.excelGet(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&dosage_type=${this.state.filterDatas?.dosageType}&mode=batch&export=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"]?.split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message);
      this.setState({ disableBtn: false })
    }
  }


  StockValuePrint = () => {
    this.setState({ disabled: true })
    try {
      let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
      // RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_name=${brandName}&generic_name=${this.state.filterDatas.genericName}&quantity_from=${this.state.filterDatas.quantityFrom}&quantity_to=${this.state.filterDatas.quantityTo} &export=pdf`).
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET + `?from_date=${this.state.filterDatas?.fromDate}&to_date=${this.state.filterDatas?.toDate}&dosage_type=${this.state.filterDatas?.dosageType}&mode=batch&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disabled: false })
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disabled: false })
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disabled: false })
    }
  }

  getDosageTypeList = () => {
    try {
        RestAPIService.getAll(`${Serviceurls.PHARMA_DOSAGE_TYPE_LIST}`)
            .then(response => {
                if (response?.data?.status === "success") {
                  this.state.filterDatas.dosageType_list = response.data.data
                    this.setState({
                        filterDatas: this.state.filterDatas
                    });
                }
            })
            .catch(error => {
                if (error.response?.data?.status === "fail") {
                    this.errorMessage(error.response?.data?.message);
                }
                else{
                  this.errorMessage(error?.message)
                }
            })
    } catch (e) {
        this.errorMessage(e.message);
    }
}

  checkFilterValidation = () => {
    try {
      let message = null;
      let states = this.state

      if (states.filterDatas?.toDate < states.filterDatas?.fromDate) {
        message = 'To Date Should Be Greater Than From Date'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FilterDate = (From, To, Index) => {
    this.state.filterDatas.fromDate = From
    this.state.filterDatas.toDate = To
    this.state.filterDatas.dateIndex = Index
    this.setState({ filterDatas: this.state.filterDatas },() => this.getStockValueData())
  }

  render() {
    const { t } = this.props
    this.state.stockValueData.forEach((element, index) => element.sno = index + 1)
    // const columns = [
    //   {
    //     field: 'brand_name', headerName: "Drug Details", flex: 0.12,
    //     renderCell: (params) => (<Box component={'div'}>
    //       <CommonCorporateAndInsuranceDetails fromPharma={true} data={params?.row} />
    //     </Box>)
    //   },
    //   {
    //     field: 'drug_strength', headerName: "Dosage Strength", flex: 0.09, headerAlign: "center", align: "center",
    //     renderCell: (params) => (<Box component={'div'}>
    //       {params?.row?.dosage_strength?.length > 12 ? <Tooltip placement='top' title={params?.row?.drug_strength} arrow><div>{params?.row?.drug_strength?.slice(0, 12) + '...'}</div></Tooltip> :
    //         params?.row?.drug_strength ? params?.row?.drug_strength : '-'}
    //     </Box>)
    //   },
    //   {
    //     field: 'stock_in_date', headerName: "Stock In Date", flex: 0.09, headerAlign: "center", align: "center",
    //     renderCell: (params) => (<Box component={'div'}>
    //       {params?.row?.stock_in_date?.length > 12 ? <Tooltip placement='top' title={params?.row?.stock_in_date} arrow><div>{params?.row?.stock_in_date?.slice(0, 12) + '...'}</div></Tooltip> :
    //         params?.row?.stock_in_date ? params?.row?.stock_in_date : '-'}
    //     </Box>)
    //   },
    //   {
    //     field: 'batch_no', headerName: "Batch No", flex: 0.09, headerAlign: 'center', align: 'center',
    //     renderCell: (params) => (<Box component={'div'}>
    //       {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
    //         params?.row?.batch_no ? params?.row?.batch_no : '-'}
    //     </Box>)
    //   },
    //   {
    //     field: 'expiry_date', headerName: `Expiry Date`, flex: 0.09, headerAlign: "center", align: "center",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.expiry_date ? params.row?.expiry_date : '-'}</Box>)
    //   },
    //   {
    //     field: 'stock_in_quantity', headerName: `Stock Qty`, flex: 0.07, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.stock_in_quantity ? params.row?.stock_in_quantity : '0'}</Box>)
    //   },
    //   {
    //     field: 'purchase_price', headerName: `Purchase Price/Qty`, flex: 0.11, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.purchase_price ? params.row?.purchase_price : '0'}</Box>)
    //   },
    //   {
    //     field: 'mrp_per_quantity', headerName: `MRP/Qty`, flex: 0.09, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.mrp_per_quantity ? params.row?.mrp_per_quantity : '0'}</Box>)
    //   },
    //   {
    //     field: 'gst_percentage', headerName: `GST (%)`, flex: 0.06, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.gst_percentage ? params.row?.gst_percentage : '0'}</Box>)
    //   },
    //   {
    //     field: 'total_purchase_value', headerName: `Total Purchase Value`, flex: 0.09, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.total_purchase_value ? params.row?.total_purchase_value : '0'}</Box>)
    //   },
    //   {
    //     field: 'total_sales_value', headerName: `Total Sales Value`, flex: 0.09, type: "number",
    //     renderCell: (params) => (<Box component={'div'}>{params.row?.total_sales_value ? params.row?.total_sales_value : '0'}</Box>)
    //   },
    // ]

    const columns = [
      {
        field: 'brand_name', headerName: "Drug Details", flex: 0.083,
        renderCell: (params) => {
          let dosageTypeAndStr = `${params?.row?.dosagetype ? params?.row?.dosagetype : '-'} | ${params?.row?.drug_strength ? params?.row?.drug_strength : '-'}`
          return(
            <Box component={'div'}>
          {/* <CommonCorporateAndInsuranceDetails fromPharma={true} data={params?.row} /> */}
          {params?.row?.brand_name ? params?.row?.brand_name?.length > 10 ? 
              <Tooltip placement='top' title={params?.row?.brand_name}><Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.brand_name?.slice(0,10) + '...'}</Typography></Tooltip>
            : <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.brand_name ? params?.row?.brand_name : '-'}</Typography> : '-'}
          {dosageTypeAndStr ? dosageTypeAndStr?.length > 10 ? 
          <Tooltip placement='top' title={dosageTypeAndStr}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageTypeAndStr?.slice(0,10) + '...'}</Typography></Tooltip>
          : <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{dosageTypeAndStr ? dosageTypeAndStr : '-'}</Typography> : '-'}
        </Box>
          )
        }
      },
      {
        field: 'opening_qty', headerName: 'Opening Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.opening_qty ? params?.row?.opening_qty?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.opening_qty}><Typography>{params?.row?.opening_qty?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.opening_qty}</Typography> : 0}</Box>
        )
      },
      {
        field: 'stock_in_quantity', headerName: 'Stock In Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.stock_in_quantity}><Typography>{params?.row?.stock_in_quantity?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.stock_in_quantity}</Typography> : 0}</Box>
        )
      },
      {
        field: 'stock_out', headerName: 'Stock Out Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.stock_out ? params?.row?.stock_out?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.stock_out}><Typography>{params?.row?.stock_out?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.stock_out}</Typography> : 0}</Box>
        )
      },
      {
        field: 'closing_qty', headerName: 'Closing Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.closing_qty ? params?.row?.closing_qty?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.closing_qty}><Typography>{params?.row?.closing_qty?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.closing_qty}</Typography> : 0}</Box>
        )
      },
      {
        field: 'batch_no', headerName: 'Batch No', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.batch_no ? params?.row?.batch_no?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.batch_no}><Typography>{params?.row?.batch_no?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.batch_no}</Typography> : "-"}</Box>
        )
      },
      {
        field: 'expiry_date', headerName: 'Expire Date', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.expiry_date ? (params?.row?.expiry_date) : 0}</Box>
        )
      },
      {
        field: 'purchase_value', headerName: 'Purchase Price/Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.purchase_value ? params?.row?.purchase_value?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.purchase_value}><Typography>{params?.row?.purchase_value?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.purchase_value}</Typography> : 0}</Box>
        )
      },
      {
        field: 'mrp_price', headerName: 'MRP/Qty', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.mrp_price ? params?.row?.mrp_price?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.mrp_price}><Typography>{params?.row?.mrp_price?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.mrp_price}</Typography> : 0}</Box>
        )
      },
      {
        field: 'gst_percentage', headerName: 'GST %', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.gst_percentage ? params?.row?.gst_percentage?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.gst_percentage}><Typography>{params?.row?.gst_percentage?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.gst_percentage}</Typography> : 0}</Box>
        )
      },
      {
        field: 'total_purchase_value', headerName: 'Total Purchase Value', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.total_purchase_value ? params?.row?.total_purchase_value?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.total_purchase_value}><Typography>{params?.row?.total_purchase_value?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.total_purchase_value}</Typography> : 0}</Box>
        )
      },
      {
        field: 'total_mrp_price', headerName: 'Total MRP Value', flex: 0.083, type: 'number',
        renderCell: (params) => (
          <Box>{params?.row?.total_mrp_price ? params?.row?.total_mrp_price?.length > 10 ? 
            <Tooltip placement='top' title={params?.row?.total_mrp_price}><Typography>{params?.row?.total_mrp_price?.slice(0,10) + '...'}</Typography></Tooltip> : 
            <Typography>{params?.row?.total_mrp_price}</Typography> : 0}</Box>
        )
      },
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard('Total Purchase Value', this.state.purchaseValue, this.state.purchase, "white", false, null, 'rpt_stkVlu_sal_value')}
            {AmountsCard('Total MRP Value', this.state.salesValue, this.state.purchase, "white", false, null, 'rpt_stkVlu_pur_value')}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            {/* <Button emed_tid="rpt_stkVlu_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                <Tooltip placement='top' title='Expiry Drug And Inactive Drugs Are Not Included' arrow>
                  <Box><ErrorOutlineIcon color='primary' /></Box>
                </Tooltip>
              </Box>
              <Box component={'div'} width={'11vw'} pr={'0vw'} position={'relative'} top={'0.7vw'} left={'0.5vw'}>
              <CommonDatePicker
                FromDate={this.state.filterDatas.fromDate}
                ToDate={this.state.filterDatas.toDate}
                DateIndex={this.state.filterDatas.dateIndex}
                SelectedDates={this.FilterDate.bind(this)}
                days={this.state.filterDatas.days}
                HideAllMenu={true}
                PharmaDateFilterRestriction={true}
                fromStockValue={true}
              />
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                <Button className='Common_Btn_Min_Width'
                  onClick={() => {
                    this.state.filterDatas = {
                      'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                      'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                      'dateIndex': 1,
                      'days': 30,
                    }
                    this.setState({ filterDatas: this.state.filterDatas }, () => {
                      this.getStockValueData()
                    })
                  }}
                ><CancelPresentationIcon color='primary' /></Button>
              </Box>
            </Box>
            <Button emed_tid = "rpt_stkVlu_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.StockValuePrint()} />
            </Button>
            <Button emed_tid="rpt_stkVlu_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getStockValueExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.stockValueData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowCount={this.state.stockValueData?.length}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDosType={true}/>
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(StockValue)