import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions'

export default class DocClinicExamENTRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notesData: this.props.EditClinicalExamNotes ? this.props.EditClinicalExamNotes : '',
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps && this.state.notesData !== this.props.EditClinicalExamNotes) {
      this.setState({ notesData: this.props.EditClinicalExamNotes })
    }
  }

  handleAdd = () => {
    this.props.AddClinicalExamNotes(this.state.notesData)
    this.setState({ notesData: '' })
  }

  handleNotesChange = (e) => {
    this.setState({
      notesData: e.target.value,
    });
  };

  render() {
    const checkuserAccess = CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? false : true
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{"Clinical Examination"}</Typography>
        <Box height={"56dvh"} sx={{ overflowY: 'scroll' }}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <TextField
              size='small'
              className='eMed_DocNotes_TextFields'
              multiline={true}
              rows={5}
              label={"Notes"}
              placeholder="Enter your notes here"
              inputProps={{ maxLength: 1000 }}
              value={this.state.notesData}
              onChange={this.handleNotesChange}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" id="eMed_Config_btn" disabled={checkuserAccess} onClick={() => { this.setState({ notesData: '' }) }}>{"Clear"}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" disabled={checkuserAccess} onClick={() => { this.handleAdd() }}>{"Add"}</Button>
        </Stack>
      </Box>
    )
  }
}
