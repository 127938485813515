import { Box, Button, Collapse, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './PatientBill.css'
import { Colors } from '../../../../../Styles/Colors'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { t } from 'i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../../Components/Common Components/CommonComponents'
import { MoreVert } from '@material-ui/icons'
import { CheckAccessFunc, AmountFormat, ContentInTooltip } from '../../../../../Components/CommonFunctions/CommonFunctions'
import Loader from '../../../../../Components/Loader';
import { CurrencySymbol } from '../../../../../Utility/Constants'
class PatientSavedBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpPatientData: {},
            open: false,
            savedBillData: [],
            attenderName: "",
            attenderNumber: "",
            selectedIndex: null,
            opSavedBillId: '',
            withOutAppointment:{},
            billDeleteClicked: false,
            selectedDeleteID: null,
            removeData:false,
        }
    }

    componentDidMount() {
        let patientData = getCachevalue("patientData")
        let OpPatientData = JSON.parse(patientData)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        if (details?.patient_id === OpPatientData?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getPatientDatails();
                this.getPatientSavedBillData();
            })
        }
        this.setState({
            OpPatientData: OpPatientData
        }, () => {
            this.getPatientDatails()
            this.getPatientSavedBillData()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPatientDatails = () => {
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        try {
            RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_DETAILS + "?appointment_id=" + appointment_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderName: response.data.data.caretaker_name,
                            attenderNumber: response.data.data.caretaker_number
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPatientSavedBillData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_SAVED_GET + "?patient_id=" + this.state.OpPatientData?.patient_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            savedBillData: response.data.data
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPatientHeader = () => {
        const { t } = this.props
        let attenderDetails = {
            lineOne: t("AttenderName"),
            lineTwo: this.state.attenderName ? this.state.attenderName : "-",
            lineFour: t("ContactNumber"),
            LineFive: this.state.attenderNumber ? this.state.attenderNumber : "-"
        }
        return (
            <Box component={'div'} display={'flex'}>
                <Box component={'div'} className='eMed_nur_notes_details' ml={'0.8vw'}>
                    <CommonPatientDetails data={this.state.OpPatientData} />
                </Box>
                <CommonPatientCard details={attenderDetails} showDetailed={true} />
            </Box>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    getSavedBillPrint = () => {
        try {
            let API_URL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + (+this.state.opSavedBillId)
            RestAPIService.getAllPrint(API_URL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderTableOne = (item, index) => {
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === index}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillNumber")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillType")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Module")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DoctorNurseName")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{getCachevalue("isForCancelBill") === "true" ? "" : t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item.bill_detail?.length > 0 ? item?.bill_detail.map((i, subIndex) => {
                                    let billType = i?.bill_type ? this.getCapitalize(i.bill_type): "-";
                                    return(
                                    <TableRow key={subIndex}>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{i?.invoice_date ? i.invoice_date : '-'}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{i?.bill_number ? i.bill_number : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{billType ? billType : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} > {i?.module ? i.module : '-'}</Typography>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{
                                                        (i?.module === "PHARMACY" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(i?.pharmacy_name ? i?.pharmacy_name : "-", 10, "0.8rem") :
                                                            (i?.module === "LAB" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(i?.laboratory_name ? i?.laboratory_name : "-", 10, "0.8rem") : ""}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{i?.doctor_name ? i.doctor_name : i.nurse_name ? i.nurse_name : '-'}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{i?.total_amounts ? AmountFormat(i.total_amounts)?.replace(`${CurrencySymbol}`, "") : '-'}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style' sx={{flexDirection: 'row', justifyContent: 'center', display: 'flex', visibility : getCachevalue("isForCancelBill") === "true" ? "hidden" : "visible"}} align='center'>
                                            <Button
                                               disabled={CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab")?.editAccess ? (this.state.withOutAppointment?.is_op || i?.module !== "OP" ? true : false) : true}
                                                className='eMed_Pat_tbl_two_btn'
                                                onClick={() => {
                                                    let { history } = this.props
                                                    this.setState({
                                                        opSavedBillId: i?.id
                                                    }, () => {
                                                        history.push({
                                                            pathname: "/OPHome/PatientDetails/Billing",
                                                            state: { AppointmentID: this.state.OpPatientData?.appointment_id, InvoiceID: this.state.opSavedBillId, Reset:true }
                                                        })
                                                    })
                                                }}
                                            >
                                                <Tooltip placement='top' title={"Create Bill"} arrow><img
                                                    src={CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab")?.editAccess ? (this.state.withOutAppointment?.is_op || i?.module !== "OP" ? ImagePaths.DisabledCreateBill.default : ImagePaths.CreateBill.default) : ImagePaths.DisabledCreateBill.default } 
                                                    className='eMed_Pat_Bill_img'
                                                    alt='credit' /></Tooltip>
                                            </Button>
                                            <Button
                                               disabled={CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab")?.editAccess ? (this.state.withOutAppointment?.is_op || this.state.removeData) : true}
                                                className='eMed_Pat_tbl_two_btn'
                                                onClick={() => {
                                                    this.setState({
                                                        billDeleteClicked: true,
                                                        selectedDeleteID: i
                                                    })
                                                }}
                                            >
                                                <Tooltip placement='top' title={"Delete Bill"} arrow><img
                                                    src={CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} 
                                                    className='eMed_Pat_Bill_img'
                                                    alt='credit' /></Tooltip>
                                            </Button>
                                            {/* <Button
                                            //    disabled={CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
                                                className='eMed_Pat_tbl_two_btn'
                                                onClick={() => {
                                                    this.setState({
                                                        opSavedBillId: i?.id
                                                    },()=>{this.getSavedBillPrint()})
                                                }}
                                            >
                                                <Tooltip placement='top' title={"Print"} arrow><img
                                                    src={ImagePaths.PrintIcons.default} 
                                                    className='eMed_Pat_Bill_img'
                                                    alt='print' /></Tooltip>
                                            </Button> */}
                                            {/* <Button className='eMed_Pat_Bill_more'><MoreVert /></Button> */}
                                        </TableCell>
                                    </TableRow>
                                )}) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell />
                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    renderTable = () => {
        return (
            <TableContainer component={'div'} className='eMed_Pat_Bill_Table'>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("AppointmentNumberAndDate")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NoOfBill")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.savedBillData?.length > 0 ? this.state.savedBillData.map((item, index) => (
                            <><TableRow
                            sx={{ backgroundColor : this.state.selectedIndex === index ? Colors?.ThemeLightColor : 'white', cursor:"pointer"}}
                            key={index} 
                            onClick={() => {
                                this.setState({
                                    selectedIndex: this.state.selectedIndex === index ? null : index
                                })
                            }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === index ? null : index
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === index ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.op_number ? item?.op_number : "-"}
                                     | ${item?.appointment_date ? item?.appointment_date : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.no_of_bills ? item?.no_of_bills : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_bill_amount ? AmountFormat(item?.total_bill_amount)?.replace(`${CurrencySymbol}`, "") : "-"}</TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableOne(item, index)}
                                </TableRow></>
                        )) : <TableRow sx={{ position: 'relative', top: "12vw" }}>
                            <TableCell sx={{ borderBottom: "none" }} /><TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }}>{t("NoRecordsFound")}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} />
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }

    popupClose = () => {
    this.setState({
        billDeleteClicked: false,
        selectedDeleteID: null,
    })
    }

    onDeleteBill = () => {
        try{
            var Url = ""
            var data = {}
            let Module = this.state.selectedDeleteID?.module

            if (Module === "PHARMACY") {
                Url = Serviceurls.PHARMACY_SAVED_BILL_LIST + "?bill_summary_id=" + this.state.selectedDeleteID?.id + "&pharmacy_id=" + this.state.selectedDeleteID?.module_id
            } else if (Module === "LAB") {
                Url = Serviceurls.LAB_SAVED_BILL_DELETE + "?bill_summary_id=" + this.state.selectedDeleteID?.id + "&laboratory_id=" + this.state.selectedDeleteID?.module_id
            } else {
                Url = Serviceurls.FO_PATIENT_APPOINTMENT_SAVED_GET
                data = { id: this.state.selectedDeleteID?.id }
            }
            this.setState({removeData:true})
            RestAPIService.delete(Url, data)
            .then((response => {
            if (response.data.status === 'success') {
                this.successMessage(response.data.message)
                this.getPatientSavedBillData()
                this.popupClose()
                this.setState({removeData:false})
            }
            })).catch(e => {
                this.setState({removeData:false})
            if (e?.data?.message) {
                this.errorMessage(e.data.message)
            } else {
                this.errorMessage(e.message)
            }
            })

        }catch (e) {
            this.setState({removeData:false})
        this.errorMessage(e.message)
        }
    }
    
    render() {
        return (
            <Box component={'div'} className='eMed_Pat_Bill_Container' sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pat_Bill_Header'>
                    {this.renderPatientHeader()}
                </Box>
                <Box component={'div'} className='eMed_Pat_Bill_content'>
                    {this.renderTable()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.billDeleteClicked ?
                <DeletePopup
                    DeletTitle={`${t("Are you sure ? Do you want to delete this Saved bill ?")}`}
                    deleteAlertPopupClose={this.popupClose}
                    removeData={this.onDeleteBill}
                    ButtonText="Delete"
                    BillType="Saved Bill"
                    disable={this.state.removeData}
                />
                : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}


export default withTranslation()(PatientSavedBill)