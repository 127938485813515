import React, { Component } from 'react'
import '../../Laboratory/Inventory/inventory.css'
import { Autocomplete, Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Trans, withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { FlashlightOnTwoTone } from '@mui/icons-material'

const ExpiryAlertList = [
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Months" },
    { value: 3, label: "3 Months" },
    { value: 6, label: "6 Months" },
]

class EditStockPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DosageTypeList: [],
            GenericNameList: [],
            editStockList: JSON.parse(this.props?.SelectedDurg),
            comments: "",
            ReasonRadio: 0,
            allowForceUpdate: false,
            isForViewOnly: this.props?.isForViewOnly,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.GetDosageTypeList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    warningMessage = (message) => {
        this.setState({
            isWarningMsg: true,
            isWarningMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isWarningMsg: false,
            isWarningMsgText : ''
        })
    }

    GetDosageTypeList() {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_DOSAGE_TYPE_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                       let DosageTypeList = response.data?.data?.filter((item)=>(item !== "N/A")) 
                        DosageTypeList.unshift("N/A")
                        this.setState({
                            DosageTypeList: DosageTypeList
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.PHARMA_GENERIC_NAME_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            GenericNameList: response.data?.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    onChangeStock = (e) => {
        let { editStockList, correction } = this.state
        let isAlpha = CommonValidation.alphabetOnly(e.target.value)
        let isNum = CommonValidation.numberOnly(e.target.value)
        let isDeci = CommonValidation.DecimalNumber(e.target.value)

        let name = e.target.name
        let value = e.target.value
        if (name === "stock_quantity" || name === "reorder_threshold") {
            if ((isNum || e.target.value !== 0) && e.target.value < 1000000) {
                editStockList[name] = +e.target.value

            }
        } else if (name === "sales_discount_for_bill") {
            if (value === "" || (isDeci && +value <= 100)) {
                editStockList["sales_discount_for_bill"] = value;
            }
        } else {
            editStockList[name] = value
        }
        this.setState({ editStockList, correction })
    }

    onChangeHandler = (event, key) => {
        if (key === "adjusted_quantity") {
            [key] = this.numberValidation(event.target.value)
        } else {
            this.setState({
                [key]: event.target.value
            })
        }
    }

    UpdateStockData() {
        const {t} = this.props;
        try {
            let { editStockList } = this.state
            var data = {
                "id": editStockList?.stock_id,
                "drug_ms_id": editStockList?.drug_ms_id,
                "batch_no": editStockList?.batch_no,
                "expiry_date": editStockList?.expiry_date,
                "mrp_per_quantity": editStockList?.mrp_per_quantity,
                "reorder_threshold": editStockList?.reorder_threshold,
                "expiry_alert_period": editStockList?.expiry_alert_period,
                "physical_box_no": editStockList?.physical_box_no,
                "sales_discount_for_bill": editStockList?.sales_discount_for_bill ? editStockList?.sales_discount_for_bill : 0,
                "hsn_code": editStockList?.hsn_code,
                "grouping": editStockList?.grouping,
                "schedules": editStockList?.schedules,
                "stock_quantity": editStockList?.stock_quantity,
                "generic_name": editStockList?.generic_name,
                "generic_id": editStockList?.generic_id,
                "drug_name": editStockList?.drug_name,
                "drug_strength": editStockList?.drug_strength,
                "drug_type": editStockList?.drug_type,
                "dosage_type": editStockList?.dosage_type,
                "free_quantity": editStockList?.free_quantity,
                "buying_price": editStockList?.buying_price,
                "drug_id": editStockList?.drug_id,
                "stock_id": editStockList?.stock_id,
                "tempPaginationId": 1,
                "available_quantity": editStockList?.stock_quantity,
                "comments": this.state.comments,
                "reason": +this.state.ReasonRadio,
                "force_update": this.state.allowForceUpdate
            }
            this.LoaderFunction(true)
            // this.setState({disableBtn: true})
            RestAPIService.updateWithOutId(data, Serviceurls.PHARMA_STOCK_CORRECTION_LIST).
                then((response) => {
                    if (response.data.status === "Success") {
                        this.setState({disableBtn : false})
                        this.LoaderFunction(false)
                        this.props.PopUpclose(true, response.data.message)
                    } else {
                        this.setState({disableBtn : false})
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                    this.setState({disableBtn: false})
                }).catch(error => {
                    if (error?.response?.data?.status === "fail" && error?.response?.data?.message === "Drug already exists") {
                        this.setState({
                            allowForceUpdate: true
                        }, () => {
                            this.LoaderFunction(false)
                            this.warningMessage(t("UpdateExistDrug"))
                            this.setState({disableBtn: false})
                        })
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                        this.setState({disableBtn: false})
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({disableBtn: false})
        }

    }

    render() {
        const { t } = this.props
        let { editStockList } = this.state
        return (
            <div className='eMed_stock_edit_popup' style={{ minHeight: this.state.isForViewOnly ? "23.5vw" : "40.5vw", maxHeight: this.state.isForViewOnly ? "24.5vw" : "fit-content" }}>
                <div className="stock_edit_header">
                    <label className="stock_edit_header_text">{this.state.isForViewOnly ? "View Drug Details" : 'Stock Correction'}</label>
                    <div className='stock_edit_close' emed_tid="esc_close">
                        <img className='stock_edit_img_close' src={ImagePaths.LabCloseButton.default} onClick={() => { this.props.PopUpclose() }} alt='close' />
                    </div>
                </div>
                <div className="eMed_stock_edit_middle_card" >
                    <div className='eMed_active_view_textfield'>
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={('Brand Name') + "*"}
                            name="drug_name"
                            value={editStockList["drug_name"] || editStockList["brand_name"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_drug_name"}}
                        />
                        <FormControl >
                            <Autocomplete
                                disabled={this.state.isForViewOnly}
                                clearIcon={false}
                                disableClearable
                                value={this.state.GenericNameList.find((item) => (item?.generic_name === editStockList["generic_name"])) ? this.state.GenericNameList.find((item) => (item?.generic_name === editStockList["generic_name"])) : null}
                                options={this.state.GenericNameList}
                                getOptionLabel={(item) => (item?.generic_name)}
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                onChange={(e, newValue) => {
                                    editStockList["generic_id"] = newValue?.id
                                    editStockList["generic_name"] = newValue?.generic_name
                                    this.setState({ editStockList })
                                }}
                                renderInput={(params) => <TextField {...params} size="small" label="Generic Name *" inputProps={{ ...params.inputProps, type: "search", emed_tid: "esc_generic_name" }} />}
                            />
                        </FormControl>
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={('Dosage Strength') + "*"}
                            name="drug_strength"
                            value={editStockList["drug_strength"] || editStockList["dosage_strength"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_drug_strength"}}
                        />

                        <FormControl >
                            <Autocomplete
                                disabled={this.state.isForViewOnly}
                                disableClearable
                                value={editStockList["dosage_type"]}
                                options={this.state.DosageTypeList.map((item) => item)}
                                sx={{ marginRight: '1vw', width: "14.2vw" }}
                                onChange={(e, newValue) => {
                                    editStockList["dosage_type"] = newValue
                                    this.setState({ editStockList })
                                }}
                                renderInput={(params) => <TextField {...params} size="small" label="Dosage Type *" inputProps={{ ...params.inputProps, type: "search", emed_tid: "esc_dosage_type" }} />}
                            />
                        </FormControl>
                    </div>
                    <div className='eMed_active_view_textfield'>
                        <TextField
                            disabled
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Batch No *')}
                            name="batch_no"
                            value={editStockList["batch_no"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_batch_no"}}
                        />
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Schedule')}
                            name="schedules"
                            value={editStockList["schedules"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_schedules"}}
                        />
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Grouping')}
                            name="grouping"
                            value={editStockList["grouping"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_grouping"}}
                        />
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Reorder Threshold')}
                            name="reorder_threshold"
                            value={editStockList["reorder_threshold"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_reorder_threshold"}}
                        />

                    </div>
                    <div className='eMed_active_view_textfield'>
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Physical Box No')}
                            name="physical_box_no"
                            value={editStockList["physical_box_no"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_physical_box_no"}}
                        />
                        <FormControl size='small' sx={{ marginRight: '1vw', width: "14.2vw" }}>
                            <InputLabel>{t('Expiry Alert Period')}</InputLabel>
                            <Select
                                disabled={this.state.isForViewOnly}
                                label={t('Expiry Alert Period')}
                                name="expiry_alert_period"
                                value={editStockList["expiry_alert_period"]}
                                onChange={(e) => { this.onChangeStock(e) }}
                            >
                                {ExpiryAlertList.length > 0 ? ExpiryAlertList.map((list, index) => (
                                    <MenuItem emed_tid="esc_exp_alrt_perd" key={index} value={list.value} >{list.label}</MenuItem>
                                )) : "No data"}
                            </Select>
                        </FormControl>
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw", textAlign: 'center' }}
                            label={t('Quantity')}
                            name="stock_quantity"
                            value={editStockList["stock_quantity"] || editStockList["stock_in_quantity"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ style: { textAlign: 'center' }, emed_tid: "esc_stock_quantity" }}
                            InputProps={{
                                startAdornment: (
                                    this.state.isForViewOnly ? null :
                                        <InputAdornment position="start">
                                            <IconButton emed_tid="esc_stock_qty_add" onClick={() => {
                                                editStockList["stock_quantity"] -= 1
                                                this.setState({ editStockList })
                                            }}><RemoveIcon color='primary' /></IconButton>
                                        </InputAdornment>
                                ),
                                endAdornment: (
                                    this.state.isForViewOnly ? null :
                                        <InputAdornment position="end">
                                            <IconButton emed_tid="esc_stock_qty_minus" onClick={() => {
                                                editStockList["stock_quantity"] += 1
                                                this.setState({ editStockList })
                                            }}><AddIcon color='primary' /></IconButton>
                                        </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            size='small'
                            sx={{ marginRight: '1vw', width: "14.2vw" }}
                            label={t('Expiry Date')}
                            value={(editStockList.expiry_date)}
                            disabled
                            inputProps={{ emed_tid: "esc_exp_date"}}
                        />

                    </div>
                    {/* <div className='eMed_active_view_textfield' style={{ marginRight: "46.5vw" }}>
                        <TextField
                            disabled={this.state.isForViewOnly}
                            size='small'
                            sx={{ width: "14.2vw" }}
                            label={t('SalesDiscount%')}
                            name="sales_discount_for_bill"
                            value={editStockList["sales_discount_for_bill"]}
                            onChange={(e) => { this.onChangeStock(e) }}
                            inputProps={{ emed_tid: "esc_sales_discount_for_bill" }}
                        />
                    </div> */}
                </div>
                {
                    this.state.isForViewOnly ? null :
                        <div style= {{ height: "18vw"}}>
                            <div className="eMed_stock_edit_radio_card" style={{ boxShadow: "none" }}>
                                <label className="stock_edit_radio_text">{'Select the Reason for Correction *'}</label>
                                <div className="stock_edit_radio">
                                    <FormControl>
                                        <RadioGroup row
                                            name="row-radio-buttons-group"
                                            value={this.state.ReasonRadio}
                                            onChange={(e) => this.onChangeHandler(e, "ReasonRadio")}
                                        >
                                            <FormControlLabel value={1} control={<Radio emed_tid="esc_Breakage" />} label={t('Breakage')} />
                                            <FormControlLabel value={2} control={<Radio emed_tid="esc_Loss"/>} label={t('Loss')} />
                                            <FormControlLabel value={3} control={<Radio emed_tid="esc_Expired"/>} label={t('Expired')} />
                                            <FormControlLabel value={4} control={<Radio emed_tid="esc_Miscellaneous"/>} label={t('Miscellaneous')} />
                                            <FormControlLabel value={5} control={<Radio emed_tid="esc_Others"/>} label={t('Others')} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="eMed_stock_edit_input_card" style={{ boxShadow: "none" }}>
                                <TextField
                                    sx={{ width: "61vw", marginLeft: "1vw" }}
                                    multiline
                                    rows={2}
                                    maxRows={2}
                                    label={t("correction") + " *"}
                                    value={this.state.comments}
                                    onChange={(e) => this.onChangeHandler(e, "comments")}
                                    inputProps={{ emed_tid: "esc_comments"}}
                                />
                            </div>
                                <div className='eMed_active_view_footer' style = {{margin: "1vw"}}>
                                        <Button
                                            emed_tid="esc_reset"
                                            sx={{ marginRight: "0.5vw", textTransform: "capitalize" }}
                                            variant='outlined'
                                            onClick={() => {
                                                this.setState({
                                                    editStockList: JSON.parse(this.props?.SelectedDurg),
                                                    comments: "",
                                                    ReasonRadio: 0,
                                                    allowForceUpdate: false
                                                })
                                            }}
                                        >{('Reset')}</Button>
                                        <Button
                                            emed_tid="esc_update_stock"
                                            disabled={this.state.disableBtn ? true : editStockList["drug_name"] === "" || editStockList["generic_name"] === "" || editStockList["drug_strength"] === "" || editStockList["dosage_type"] === "" || this.state.comments === "" || this.state.ReasonRadio === 0}
                                            sx={{ textTransform: "capitalize" }}
                                            variant='contained'
                                            onClick={() => {
                                                this.setState({disableBtn : true},()=>{ this.UpdateStockData()})
                                            }}
                                        >{('Update Stock')}</Button>
                                </div>
                            </div>
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isWarningMsg ?
                    <ToastMsg
                        severity={'warning'}
                        msg={this.state.isWarningMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(EditStockPopUp)
