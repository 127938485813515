import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../ClinicalNotes/DoctorNotes.css'
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import { formatDateAndTimeMeth2 } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';
import { ImagePaths } from "../../../../../../Utility/ImagePaths";
import VoiceToText from '../../../../../../Components/Common Components/VoiceToText';
import MicIcon from '@mui/icons-material/Mic';
import { grey } from '@mui/material/colors';
import { DateTime } from 'luxon';
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation';

class ProvDiagnosisLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ProvDiagnosisData: [],
      ProvDiagnosisText: {},
      withOutAppointment : this.props?.withOutAppointment,
      listenPopUp: false,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  componentDidMount() {
    // this.GetProvDiagnosisData()
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetProvDiagnosisData() }
    if (prevprops.IpPatientData?.patient_id !== this.props.IpPatientData?.patient_id) {
      this.setState({ IpPatientData: this.props.IpPatientData },
        () => { this.GetProvDiagnosisData() })
    }
    if (prevprops.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) {
      this.setState({ withOutAppointment: this.props.withOutAppointment },
        () => { this.GetProvDiagnosisData() })
    }

    // Clear ProvDiagnosisText
    if (prevprops.isClearClked !== this.props.isClearClked) {
      if (this.props.isClearClked) {
        this.setState(prevState => ({
          ProvDiagnosisText: {
            ...prevState.ProvDiagnosisText,
            notes: "",
          }
        }), () => {
          this.props.onSaveComplete();
        });
      }
    }

    // Call save
    if (prevprops.isSaveClked !== this.props.isSaveClked && this.props.isSaveClked) {
      this.PostProvDiagnosis();
    }
  }

  GetProvDiagnosisData() {
    let admission_id = this.state.IpPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props.IpPatientData?.id;
    const { from_date, to_date, doctorIds } = this.props;

    let url = Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG + "?ip_admission_id=" + admission_id +
      "&from_date=" + (from_date ? from_date : null) +
      "&to_date=" + (to_date ? to_date : null);
    if (doctorIds?.length > 0) {
      url = `${url}&doctor_id=${doctorIds}`
    }

    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    let login_doctor_id = myUser?.doctor_id;

    try {
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            let data = response.data.data?.length > 0 ? response.data.data : [];
            let result = data.reduce((acc, cur) => {
              const check_doc_id = this.props.is_From_IpNotes ? (cur?.doctor_id === login_doctor_id)
                : (cur?.doctor_id === this.state.IpPatientData?.doctor_id);
              if (check_doc_id) {
                acc.data_txt = { ...cur };
              } else {
                acc.dataList.push({ ...cur });
              }
              return acc;
            }, {
              data_txt: {},
              dataList: []
            });
            this.setState({
              ProvDiagnosisText: result?.data_txt || {},
              ProvDiagnosisData: result?.dataList || []
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    } finally {
      this.props.onApiTrigger();
    }
  }

  PostProvDiagnosis() {
    try {
      var states = this.state
      const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      if (CommonValidation.removeSpace(states.ProvDiagnosisText?.notes) !== "") {
        var data = {
          "id": states.ProvDiagnosisText?.id,
          "ip_admission_id": this.props?.IpPatientData?.id,
          "clinic_id": this.props.clinic_id,
          "doctor_id": this.props.is_From_IpNotes ?
            (states.ProvDiagnosisText?.doctor_id || myUser?.doctor_id)
            : this.props?.IPadmissionDetails?.doctor_id,
          "diagnosis_date": DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd HH:mm:ss"),
          // "notes": CommonValidation.removeSpace(states.ProvDiagnosisText?.notes),
          "notes": states.ProvDiagnosisText?.notes,
        }

        RestAPIService.create(data, Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG).
          then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message);
              this.setState({
                ProvDiagnosisData: [],
                ProvDiagnosisText: {},
              })
              this.GetProvDiagnosisData();
            } else {
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          }).finally(() => {
            this.props.onSaveComplete();
          });
      } else {
        this.errorMessage("Enter Provisional Diagnosis");
        this.props.onSaveComplete();
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  // onEditHandler(data) {
  //   this.props.EditProvDiagnosisData(this.state.ProvDiagnosisText)
  // }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  listenPopUp = () => {
    this.setState({
      listenPopUp: false
    })
  }

  voiceToTextgetter = (textStorage) => {
    if (textStorage !== '') {
      let updatedNotes = { ...this.state.ProvDiagnosisText };
      const newValue = updatedNotes?.notes?.length > 0 ?
        updatedNotes?.notes + " " + textStorage : textStorage;
        const finalValue = newValue?.length > 250 ? newValue.substring(0, 250) : newValue;
        updatedNotes.notes = finalValue;
        this.setState({
          ProvDiagnosisText: { ...updatedNotes }
        })   
    } else {
      this.setState({ listenPopUp: false });
    }
  }

  renderVoiceTexbox = () => {
    const { t } = this.props
    return (
      <TextField
        sx={{ width: '100%', marginTop: '1vw' }}
        multiline
        value={this.state.ProvDiagnosisText?.notes ? this.state.ProvDiagnosisText?.notes : ""}
        label={t("Write Provisional diagnosis here")}
        inputProps={{ maxLength: 250, emed_tid: 'prov_diagnosis' }}
        variant="outlined"
        onChange={(event) => {
          let updatedNotes = { ...this.state.ProvDiagnosisText };
          const newValue = event.target.value;
          // if ((newValue?.length <= 250) || newValue === "") {
              updatedNotes.notes = newValue;
              this.setState({
                ProvDiagnosisText: { ...updatedNotes }
              })
          // }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <MicIcon sx={{ color: grey[1000], cursor: "pointer" }}
              onClick={() => { this.setState({ listenPopUp: true }) }} />
          </InputAdornment>
        }}
      />)
  }

  render() {
    const { t } = this.props;
    // return (
    //   <Box display={'flex'} flexDirection={'row'}>
    //     <TextField
    //       size='small'
    //       className='eMed_DocNotes_ProvDiag_TextField'
    //       multiline={true}
    //       rows={5}
    //       label={t("ProvDiagnosis")}
    //       value={this.state.ProvDiagnosisText}
    //       InputProps={{ readOnly: true }}
    //     />
    //     <Box marginTop={'0.3vw'}>
    //       <CommonEditButton
    //         disable={this.props.is_From_IpNotes ? false :
    //           CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ?
    //             this.state.withOutAppointment?.is_ip : true}
    //         size={"1.5vw"} onClick={this.onEditHandler.bind(this)} />
    //     </Box>
    //     {this.state.isErrorMsg ?
    //       <ToastMsg
    //         severity={'error'}
    //         msg={this.state.isErrorMsgText}
    //         msgPop={this.msgClose.bind(this)}
    //       />
    //       : null}
    //   </Box>
    // )
    return (
      <Box className="eMed_WrtNotes_Cont">
          {this.renderVoiceTexbox()}
          <div style={{ margin: "10px 0px" }}>
              {
                  this.state.ProvDiagnosisData?.length > 0 ? this.state.ProvDiagnosisData?.map((item, index) => {
                      return (
                          <Box height={"100%"} key={index} marginY={"10px"}>
                              <Box className="eMed_WrtNotes_docDiv">
                                  <Box component={'div'} className='eMed_patient_image'>
                                      <img className='eMed_WrtNotes_docImg'
                                          src={item?.doctor__photo_url ? item.doctor__photo_url : ImagePaths.Male.default}
                                          alt="male" />
                                  </Box>
                                  <Box component={'div'} className="eMed_Pateint_Detail_LenthData">
                                      <Box component={'div'} className='eMed_patient_txt'>
                                          <Typography fontSize={"0.95dvw"} fontWeight={600}>{item?.doctor_name || "-"}</Typography>
                                          <Typography fontSize={"0.8dvw"} >{item?.doctor_speciality || "-"}</Typography>
                                      </Box>
                                  </Box>
                              </Box>
                              <Typography fontSize={"0.85dvw"} marginY={"10px"}>{item?.notes || "-"}</Typography>
                              <Typography fontSize={"0.8dvw"} marginTop={"10px"}>{item?.diagnosis_date ? formatDateAndTimeMeth2(item?.diagnosis_date) : ""}</Typography>
                          </Box>
                      )
                  }) : null
              }
          </div>
          {this.state.isErrorMsg ?
              <ToastMsg
                  severity={'error'}
                  msg={this.state.isErrorMsgText}
                  msgPop={this.msgClose.bind(this)}
              />
              : null}
          {this.state.successMsg ?
              <ToastMsg
                  severity={'success'}
                  msg={this.state.successMsgText}
                  msgPop={this.msgClose.bind(this)}
              />
              : null}
          {this.state.listenPopUp ?
              <VoiceToText
                  voiceTextConverter={this.voiceToTextgetter.bind(this)}
                  listenPopUp={this.listenPopUp.bind(this)}
              /> : null
          }
      </Box>
  )
  }
}
export default withTranslation()(ProvDiagnosisLeft)