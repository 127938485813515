import React, { Component } from 'react'
import { Box, Button, IconButton, TextField, Tooltip } from "@mui/material";
import { SketchField, Tools } from "react-sketch-draw";
import { Colors } from '../../../../Styles/Colors';
import ShowComponents from '../Common/ShowComponent';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import AddIcon from "@material-ui/icons/AddOutlined";
import SprayIcon from "@material-ui/icons/BlurCircular";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import RedoIcon from "@material-ui/icons/RedoOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CreateIcon from "@material-ui/icons/Create";
import LineIcon from "@material-ui/icons/BorderColor";
import RectIcon from "@material-ui/icons/CropLandscape";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import Loader from "../../../../Components/Loader"
import { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Bucket_Name, ClinicalExamImage } from '../../../../Utility/Constants';

var AWS = require("aws-sdk");
var s3 = new AWS.S3({
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1",
});

const styles = {
    control: {
        width: "24px",
        height: "24px",
    },
    control_space_1: {
        width: "24px",
    },
}

export default class DocClinicExamENTLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicExamData: {},
            lineColor: "#000000",
            fillColor: "red",
            backgroundColor: "#FFF",
            appointmentId: this.props.appointmentId,
            patientId: this.props.PatientData?.patient_id,
            notesData: this.props.clinicalExamNotes ? this.props.clinicalExamNotes : '',
            templateUrl: ClinicalExamImage,
        }
        this.componentRef = React.createRef()
        this.intersectionObserver = null
        this.sketch = null
    }

    apiCalls = () => {
        this.getClinicExamENT()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.isSaveClicked && this.state.isSaveClicked !== this.props.isSaveClicked) {
                this.setState({
                    isSaveClicked: true
                }, () => this.handleSave())
            }
            // if ((this.props.clinicalExamNotes || this.props.clinicalExamNotes === "") && this.state.notesData !== this.props.clinicalExamNotes) {
            //     this.props.EditClinicalExamNotes("")
            //     this.setState({
            //         notesData: this.props.clinicalExamNotes ? this.props.clinicalExamNotes : '',
            //     })
            // }
        }
    }

    getClinicExamENT = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.DOC_ENT_CLINICAL_EXAM_GET + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`)
                .then((response => {
                    if (response?.data?.status === 'success') {
                        this.setState({ isLoader: false })
                        let clinicExamData = response.data.data ? response.data.data : {}
                        this.setState({
                            notesData: clinicExamData?.notes ? clinicExamData?.notes : ''
                        })
                        try {
                            if (clinicExamData?.clinicexam_image_key) {
                                this.setBackgroundImage(clinicExamData?.clinicexam_image_key);
                            } else if (ClinicalExamImage) {
                                this.sketch.setBackgroundFromDataUrl(ClinicalExamImage, { fit: 'cover' });
                            }
                        } catch (e) {
                            if (ClinicalExamImage) {
                                this.sketch.setBackgroundFromDataUrl();
                            }
                        }
                    }
                })).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ isLoader: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ isLoader: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isLoader: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            isLoader: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderClinicExamImage = () => {
        let { controlledValue } = this.state;
        return (
            <div style={{ backgroundColor: Colors.white, border: "1px solid black", height: 'auto', width: '100%', overflow: 'auto' }}>
                <SketchField
                    name={"sketchfield"}
                    ref={(c) => (this.sketch = c)}
                    tool={this.state.tool}
                    lineColor={this.state.lineColor}
                    lineWidth={this.state.lineWidth}
                    fillColor={"transparent"}
                    backgroundColor={"transparent"}
                    width={842}
                    height={652}
                    undoSteps={2000}
                    value={controlledValue}
                    forceValue
                    onChange={this.onSketchChange}
                />
            </div>
        )
    }

    addText = () => {
        this.sketch.addText(this.state.text ? this.state.text : '');
        this.setState({ tool: Tools.Select, text: '' });
    };

    onSketchChange = () => {
        let prev = this.state.canUndo;
        let now = this.sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    setBackgroundImage(doc_key) {
        const parms = { Bucket: Bucket_Name, Key: doc_key, Expires: 120 };
        s3.getSignedUrl("getObject", parms, (err, url) => {
            if (err) {
                console.log(err);
            } else if (url) {
                this.sketch.setBackgroundFromDataUrl(url);
            }
        });
    }


    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    handleUndo = () => {
        this.sketch.undo();
        this.setState({
            canUndo: this.sketch.canUndo(),
            canRedo: this.sketch.canRedo(),
        });
    };

    handleRedo = () => {
        this.sketch.redo();
        this.setState({
            canUndo: this.sketch.canUndo(),
            canRedo: this.sketch.canRedo(),
        });
    };

    clear = () => {
        this.sketch.clear();
        this.sketch.setBackgroundFromDataUrl(this.state.templateUrl);
        this.setState({
            controlledValue: null,
            backgroundColor: "#FFF",
            fillWithBackgroundColor: false,
            canUndo: this.sketch.canUndo(),
            canRedo: this.sketch.canRedo(),
            isDeleteCliked: false,
        }, () => {
            // setTimeout(() => { this.handleSave() }, 1500)
        });
    };

    changeColor = (color) => {
        let { tool } = this.state;

        this.setState({
            lineColor: color,
            tool: Tools.Pan
        }, () => {
            this.setState({
                tool: tool
            });
        });
    };

    popupClose = () => {
        this.setState({
            isDeleteCliked: false
        })
    }

    handleSave = () => {
        try {
            let sketchData = ''
            sketchData = this.sketch.toDataURL()
            if (sketchData) {
                let data = new FormData();
                data.append('appointment_id', this.state.appointmentId);
                data.append('patient_id', this.state.patientId);
                data.append('image', this.dataURItoBlob(sketchData));
                data.append('image_json', JSON.stringify(this.sketch))
                data.append('notes', JSON.stringify(this.state.notesData))
                RestAPIService.create(data, Serviceurls.DOC_ENT_CLINICAL_EXAM_GET)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.props.isDataSaved()
                            this.setState({
                                isSaveClicked: false
                            }, () => { this.getClinicExamENT() })
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                        this.props.isDataSaved()
                        this.setState({ isSaveClicked: false })
                    })
            }
        } catch (e) {
            this.errorMessage(e.message)
            this.props.isDataSaved()
            this.setState({ isSaveClicked: false })
        }
    }

    handleNotesDelete = () => {
        // this.props.EditClinicalExamNotes("")
        // this.props.AddClinicalExamNotes("")
        this.setState({ notesData: '' })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} p={'0.5vw'}>
                    <Box component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: '100%', overflow: 'auto', height: '47vh', paddingTop: '47vh' }}>
                        {this.renderClinicExamImage()}
                    </Box>
                    <div style={{ position: 'sticky', bottom: 0, backgroundColor: Colors.Background }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "0.5vw", flexDirection: "row", justifyContent: "center", backgroundColor: Colors.Background }}>
                            <div>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#000000" ? "1px solid black" : null }} onClick={() => { this.changeColor("#000000") }}>
                                    <Box component={'img'} src={ImagePaths.Black.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#F19C38" ? "1px solid black" : null }} onClick={() => { this.changeColor("#F19C38") }}>
                                    <Box component={'img'} src={ImagePaths.Orange.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#4A95EC" ? "1px solid black" : null }} onClick={() => { this.changeColor("#4A95EC") }}>
                                    <Box component={'img'} src={ImagePaths.Blue.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#67AC5B" ? "1px solid black" : null }} onClick={() => { this.changeColor("#67AC5B") }}>
                                    <Box component={'img'} src={ImagePaths.Green.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#E15241" ? "1px solid black" : null }} onClick={() => { this.changeColor("#E15241") }}>
                                    <Box component={'img'} src={ImagePaths.Red.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#74574A" ? "1px solid black" : null }} onClick={() => { this.changeColor("#74574A") }}>
                                    <Box component={'img'} src={ImagePaths.Brown.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#FFFFFF" ? "1px solid black" : null }} onClick={() => { this.changeColor("#FFFFFF") }}>
                                    <Box component={'img'} src={ImagePaths.White.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                            </div>
                            <div style={{ marginLeft: "4vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Tooltip placement="top" title={'Spray'}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Spray,
                                                lineWidth: 10,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <SprayIcon style={styles.control} />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={"Pencil"}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Pencil,
                                                lineWidth: 3,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <CreateIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Line Pencil"}>
                                    <Button sx={{ marginTop: "0.5vw" }} color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Line,
                                                lineWidth: 3,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <LineIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Rectangle"}>

                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Rectangle,
                                                lineWidth: 3,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <RectIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>


                                <Tooltip placement="top" title={"Circle"}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Circle,
                                                lineWidth: 3,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <CircleIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Brush"}>
                                    <Button
                                        color="primary"
                                        style={{ marginLeft: "-0.5vw" }}
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Pencil,
                                                lineWidth: 10,
                                            }, () => { this.changeColor("#000000") });
                                        }}
                                    >
                                        <Box component={'img'} src={ImagePaths.Brush.default} height={'1.4vw'} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>

                            <Tooltip placement="top" title={"Eraser"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Pencil,
                                            lineWidth: 10,
                                        }, () => { this.changeColor("#FFFFFF") });
                                    }}
                                >
                                    <Box component={'img'} src={ImagePaths.Eraser.default} height={'1.2vw'} width={'1.2vw'} />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Undo"}>
                                <Button
                                    color="primary"
                                    disabled={!this.state.canUndo}
                                    onClick={() => { this.handleUndo() }}
                                >
                                    <UndoIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Redo"}>

                                <Button
                                    color="primary"
                                    disabled={!this.state.canRedo}
                                    onClick={() => { this.handleRedo() }}
                                >
                                    <RedoIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <div style={{ justifyContent: "center" }}>
                                <TextField id="standard-basic" variant="standard" value={this.state.text} onChange={(e) => { this.setState({ text: e.target.value }) }} />
                                <div style={{ fontSize: 10, color: "grey", marginTop: 5 }} >Enter text to add</div>
                            </div>
                            <Tooltip placement="top" title={"Add Text"}>

                                <Button
                                    color="primary"
                                    onClick={() => { this.addText() }}
                                >
                                    <AddIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={"Trash"}>

                                <Button
                                    color="primary"
                                    disabled={this.props.isLocked}
                                    onClick={() => {
                                        this.setState({
                                            isDeleteCliked: true
                                        })
                                    }}
                                >
                                    <DeleteIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </Box>
                <Box component={'div'} p={'0.5vw'}>
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <TextField
                            size='small'
                            sx={{ width: '95%' }}
                            multiline={true}
                            label={"Clinical Examination Notes"}
                            rows={4}
                            value={this.state.notesData ? this.state.notesData : ''}
                            onChange={(e) => this.setState({ notesData: e.target.value })}
                        />
                        {this.state.notesData ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                                {/* <CommonEditButton size={"1.5vw"} disable={this.props?.isLocked} onClick={() => { this.props.EditClinicalExamNotes(this.state.notesData) }} /> */}
                                <Tooltip title="Clear" placement="top" arrow>
                                    <Button emed_tid='ClinicalExam_Notes_Clear' className="eMed_Edit_btn eMed_usrconf_btn" disabled={this.props?.isLocked} size='small'>
                                        <img src={this.props?.isLocked ? ImagePaths.DisabledDelete.default : ImagePaths.LabDeleteButton.default} style={{ height: '1.5vw', width: '1.5vw' }} alt='Delete'
                                            onClick={() => { this.handleNotesDelete() }} />
                                    </Button>
                                </Tooltip>
                            </Box>
                            : null}
                    </div>
                </Box>
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={"Are you sure you want to delete Clinical Examination ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.clear.bind(this)}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </ShowComponents >
        )
    }
}
