import React from "react";
import eMedHub from "../Assets/Logo/eMedHub.jpeg";
import { Box, Button, CircularProgress, Dialog, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Logo from "../Assets/Logo/Logo.svg";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from "../Utility/ImagePaths";
import CommonValidation from "../Components/CommonFunctions/CommonValidation";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestAPIService from "../Utility/Services/RestAPIService";
import { Serviceurls } from "../Utility/API/Serviceurls";
import ToastMsg from "../Components/ToastMsg/ToastMsg";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_Name: '',
            Oldpasswords: "",
            Newpasswords: "",
            Confirmpasswords: "",
            is_Success: false,
            showResetPass: false,
            isLoader: false,
        };

    }

    componentDidMount () {
        let uName = this.props.history.location.state.userName || ''
        this.setState({user_Name: uName})
    }

    ErrorMsg = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    SuccessMsg = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    resetPassword = () => {
        try {
            var states = this.state
            let passWord = CommonValidation.checkPassword(this.state.Newpasswords)
            if (states.Oldpasswords) {
                if (passWord && states.Newpasswords === states.Confirmpasswords) {
                    let data = {
                        "username": states.user_Name ? states.user_Name : '',
                        "old_password": states.Oldpasswords ? states.Oldpasswords : null,
                        "new_password": states.Newpasswords ? states.Newpasswords : null,
                        "confirm_password": states.Confirmpasswords ? states.Confirmpasswords : null,
                    }
                    RestAPIService.create(data, Serviceurls.LOGIN_RESET_PASS).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.SuccessMsg(response.data.message)
                                this.setState({
                                    is_Success: true,
                                    Oldpasswords: '',
                                    Newpasswords: '',
                                    Confirmpasswords: '',
                                })
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.status === "fail") {
                                this.ErrorMsg(error.response.data.message)
                            } else {
                                this.ErrorMsg(error.message)
                            }
                        })
                } else {
                    if (passWord === false) {
                        this.ErrorMsg('Password should be 6 characters, one UpperCase,Special Characters,LowerCase')
                    } else {
                        this.ErrorMsg('New password and Confirm password not matched')
                    }
                }
            } else {
                this.ErrorMsg('Please Enter the Old Password')
            }

        } catch (e) {
            this.ErrorMsg(e.message)
        }
    }



    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }


    render() {
        const { t } = this.props
        return (
            <div class="total">
                <div class="left">
                    <img src={eMedHub} width="100%" height="100%" alt="login-img" />
                </div>

                <Box class="eMed_Forget_Con">
                    <img src={Logo} alt="logo" style={{ width: '6vw', height: '6vw' }} />

                    <Box className='eMed_Forget_Box'>
                        <Box className='eMed_Forget_HeadCon'>
                            <IconButton onClick={() => { this.props.history.push('/') }}>
                                <img className="eMed_DisSum_Lay_StarIcon" src={ImagePaths.Back_Arrow.default} alt="Back" />
                            </IconButton>
                            <Typography id='eMed_Forget_HeaderText'>{'Reset Password'}</Typography>
                        </Box>
                        <Typography id='eMed_Forget_HelpText'>{`Enter password here.`}</Typography>
                        <TextField
                            size='small'
                            label={"Old Password"}
                            name={'Oldpasswords'}
                            variant='standard'
                            type={this.state.showResetPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment>
                                        <IconButton onClick={() => this.setState({ showResetPass: !this.state.showResetPass })}>
                                            {this.state.showResetPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            value={this.state.Oldpasswords}
                            sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                            onChange={(e) => {
                                const { name, value } = e.target
                                let PasswordInput = CommonValidation.PasswordInput(value)
                                if ((PasswordInput && value.length <= 14) || value === "") {
                                    this.setState({
                                        [name]: value
                                    })
                                }
                            }}
                            autoComplete='no'
                        />
                        <TextField
                            size='small'
                            name={'Newpasswords'}
                            variant='standard'
                            label='New Password'
                            type={this.state.showResetNew ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment>
                                        <IconButton onClick={() => this.setState({ showResetNew: !this.state.showResetNew })}>
                                            {this.state.showResetNew ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            value={this.state.Newpasswords}
                            sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                            onChange={(e) => {
                                const { name, value } = e.target
                                let PasswordInput = CommonValidation.PasswordInput(value)
                                if ((PasswordInput && value.length <= 14) || value === "") {
                                    this.setState({
                                        [name]: value
                                    })
                                }
                            }}
                        />
                        <TextField
                            size='small'
                            name={'Confirmpasswords'}
                            variant='standard'
                            type={this.state.showResetConfirm ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment>
                                        <IconButton onClick={() => this.setState({ showResetConfirm: !this.state.showResetConfirm })}>
                                            {this.state.showResetConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            label='Confirm Password'
                            value={this.state.Confirmpasswords}
                            sx={{ margin: '0.5vw 1vw 0.5vw 1vw' }}
                            onChange={(e) => {
                                const { name, value } = e.target
                                let PasswordInput = CommonValidation.PasswordInput(value)
                                if ((PasswordInput && value.length <= 14) || value === "") {
                                    this.setState({
                                        [name]: value
                                    })
                                }
                            }}
                        />

                        <Box display={'flex'} justifyContent={'center'}>
                            <Button
                                id="eMed_Forget_Btn"
                                variant='contained'
                                size='small'
                                disabled={this.state.confirmPass === ''}
                                onClick={() => {
                                    if (!this.state.isLoader) {
                                        this.resetPassword()
                                    }
                                }}>{this.state.isLoader ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Reset Password"}</Button>
                        </Box>
                    </Box>
                </Box>
                {this.state.is_Success ?
                    <Dialog open={this.state.is_Success}>
                        <Box sx={{ width: '25vw', height: '33vh', margin: '1vw' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                <img style={{ width: '6vw', height: '6vw' }} src={ImagePaths.SuccessAnimationGif.default} />
                            </Box>
                            <Box display={'flex'} justifySelf={'center'} marginTop={'1vw'} flexDirection={'column'}>
                                <Typography sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Congratulation {this.state.userName}!</Typography>
                                <Typography sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Your Password has been Changed Successfully</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'center'} marginTop={'1.5vw'}>
                                <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => { this.props.history.push('/') }}>
                                    Back to Login
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                        msgtop={true}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                        msgtop={true}
                    />
                    : null}
            </div>
        );
    }
}
export default withTranslation()(ResetPassword);

