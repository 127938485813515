import { Autocomplete, Box, Button, CircularProgress, FormControlLabel, Drawer, IconButton, InputAdornment, Modal, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientCard, CommonPatientDetails, ConfirmPopup } from '../../../../Components/Common Components/CommonComponents'
import { clearCachevalue, getCachevalue, localGetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import DocVitals from './DocVitals'
import DocPatientCategory from './DocPatientCategory'
import DocAllergies from './DocAllergies'
import DocSymptoms from './DocSymptoms'
import DocTest from './DocTest'
import { CurrencySymbol, Doctor_Notes_Left_Layout_JSON, Doctor_Notes_List, Doctor_Notes_OPT_Left_Layout_JSON, Doctor_Notes_OPT_Right_Layout_JSON, Doctor_Notes_Right_Layout_JSON, Doctor_Notes_OPTHOMETRY_Left_Layout_JSON, Doctor_Notes_OPTHOMETRY_Right_Layout_JSON, } from '../../../../Utility/Constants'
import DocDiagonisis from './DocDiagonisis'
import DocGeneralAdvice from './DocGeneralAdvice'
import DocPlan from './DocPlan'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import DocMedication from './DocMedication'
import DocExamination from './DocExamination'
import DocPreviousGlassPower from './DocPreviousGlassPower'
import DocSubjectiveRefraction from './DocSubjectiveRefraction'
import DocRetinoscopicRefraction from './DocRetinoscopicRefraction'
import DocIntraocularPressure from './DocIntraocularPressure'
import DocAutoRefractometry from './DocAutoRefractometry'
import DocAnteriorSegment from './DocAnteriorSegment'
import DocPosteriorSegment from './DocPosteriorSegment'
import DocFundus from './DocFundus'
import DocKeratomentry from './DocKeratomentry'
import DocGonioscopy from './DocGonioscopy'
import DocTreatmentPlan from './DocTreatmentPlan'
import DocGlassPrescription from './DocGlassPrescription'
import DocComplaints from './DocComplaints'
import DocSystemicIllness from './DocSystemicIllness'
import DocOcularHistory from './DocOcularHistory'
import DocVisionAssesment from './DocVisionAssesment'
import DocReviewPlan from './DocReviewPlan'
import DocInvestigations from './DocInvestigations'
import DocOverallNotes from './DocOverallNotes'
import DocOtherInvestigation from './DocOtherInvestigation'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { DateTime } from 'luxon'
import DocAnteriorSegTable from './DocAnteriorSegTable'
import { timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions'
import Loader from '../../../../Components/Loader'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import '../DoctorNotes.css'
import DocOcularResponser from './DocOcularResponser'
import DocRetinoscopicAcceptance from './DocRetinoscopicAcceptance'
import DocIOLMaster from './DocIOLMaster'
import OptometryDoctorNotes from '../Others/OptometryDoctorNotes'

const Eye_Type = [
  { value: "Right Eye (OD)", label: "Right Eye (OD)" },
  { value: "Left Eye (OS)", label: "Left Eye (OS)" },
  { value: "Both Eye (OU)", label: "Both Eye (OU)" }
]

export default class DocNotesHome extends Component {
  constructor(props) {
    super(props)
    this.box1Ref = React.createRef();
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      doctorNotesTitle: Doctor_Notes_List,
      selectedDoctorNotes: '',
      openPopUp: false,
      surgeryNameData: [],
      doctorNameData: [],
      anaesthetistNameData: [],
      counsellorData: {},
      surgery: '',
      package: '',
      doctor: '',
      anaesthetist: '',
      counsellor: '',
      notes: '',
      isLocked: false,
      postLoad: false,
      transmitCounsellor: null,
      appointmentDetails: {},

      doctorNotesLeft: [],
      doctorNotesRight: [],
      transmit: false,
      layout: false,
      prevAppointmentId: null,
      isOPT: false,
      appointmentDate: null,
      TransferPopup: false,
      addendumPopup: false,
      TranferMethod: "Transfer Appointment",
      spcInfo: [],
      selectedspec: null,
      docDetails: [],
      selectedDoc: null,
      comments: "",
      POVList: [],
      PurposeOfVisit: "",
      confirmpopup: false,
      Addcomments: "",
      eyeType: null,
      Power_IOL: '',
      IOL_type: true,
      status: '',
      doctorID: "",
      disableButton: false,
      TansmitDisable: false,
      TransferDisable: false,
      statuspopup: false,
      triggerGlassPrescription: false,
      filteredDocList: [],
      filterspcInfo: [],
      Specilazation: null,
      Doctor: null,
      SpecializationInfo: [],
      SpecdocDetails: [],
      surgeryName: null,
      isDropdownOpen: false,
      isPDdoc: false,
      isInsideBox1: false,
      AnteriorData: false,
      triggerAnteriorTable: false,
      PackageData: [],
      surgery_id: null,
      selectedPharmacy: null,
      prescriptionId: null,
      isPrescriptionTrans: null,
      isLoader: false,
      isCompleted: false,
      recallPopup: false,
      recallNotes: "",
      selectedOpto: null,
      optometristNameList: [],
      isrecall: false,
      isrefraction: false,
      allergyData: [],
      optometristName: ''
    }
    this.dropdownRef = React.createRef();
    this.LeftdivRefs = [];
    this.RightdivRefs = [];
  }


  componentDidMount() {
    // document.addEventListener('mousedown', this.handleDocumentClick);
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ServiceType = localGetItem("loggedInUserInfo")
    let myUser = JSON.parse(ServiceType)
    let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
    let array = []
    JSON.parse(POVList).forEach(element => array.push(element.reason));
    if (array.length > 0) {
      this.setState({
        POVList: array
      })
    }
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id,
      isLocked: appointmentId?.transfer_appointment ? appointmentId?.transfer_appointment : appointmentId?.is_locked,
      appointmentDate: appointmentId?.appointment_date ? (appointmentId?.appointment_date).slice(0, 10) : null,
      appointmentStatus: '',
      isOPT: myUser.service_type === "OPT" ? true : false,
      isPDdoc: myUser.service_type === "PD" ? true : false,
      doctorID: appointmentId?.doctor_id,
      disableButton: appointmentId?.transfer_appointment ? appointmentId?.transfer_appointment : false,
      TransferDisable: appointmentId?.is_transmitted ? appointmentId?.is_transmitted : false,
      optometristName: appointmentId?.optometrist_name ? appointmentId?.optometrist_name : ""
    }, () => {
      this.getPatientDetailData()
      this.getSurgeryNameList()
      if (myUser?.module_name !== "Optometry") {
        this.getCounsellorData()
        this.getTapperMed()
        this.getPharmacyList()
        this.getOptometristNameList()
      }
      this.getDoctorListData()
      this.getDoctorDetails(myUser?.doctor_id ? myUser?.doctor_id : null)
    })
    this.getDoctorNotesLayout()
    let previousAppointmentId = this.props.history?.location?.state?.appointmentId
    if (previousAppointmentId) {
      this.setState({
        prevAppointmentId: previousAppointmentId
      })
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleBox1MouseDown = () => {
    this.setState({ isInsideBox1: true });
  };

  handleBox1Blur = () => {
    if (!this.state.isInsideBox1) {
      this.setState({
        triggerAnteriorTable: true
      }, () => {
        this.setState({
          triggerAnteriorTable: false,
          AnteriorData: false
        })
      })
    }
  };

  allergyData = (data) => {
    this.setState({ allergyData: data })
  }

  getOptometristNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_OPTOMETRIST_NAME_LIST + `?doctor_id=${this.state.doctorID}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              optometristNameList: response.data.data
            }, () => {
              if (response.data.data.length == 1) {
                this.setState({ selectedOpto: response.data.data[0] })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorDetails = (CurrentDocID) => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?clinic_id=${this.state.clinicId}`)
        .then(response => {
          if (response.data.status === "success") {
            let List = response.data.data ? response.data.data.filter((item) => (item?.doctor_id !== CurrentDocID)) : []
            this.setState({
              docDetails: List,
            })
          }
        })
        .catch(error => {
          if (error?.response?.data.status === "fail") {
            this.errorMessage(error?.response?.data?.message, false);
          }
        });
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e?.message, false);
    }
  }

  getSpecInfo = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION + `?clinic_id=${this.state.clinicId}`)
        .then(response => {
          if (response.data.status === "success") {
            let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
            let datas = response.data.data ? response.data.data : []
            let SpecList = []
            let Specialization = []
            if (this.state.TranferMethod === "Transfer Appointment") {
              SpecList = datas?.filter((item) => (item.is_active === true && item.id === UserData?.specialization_id))
            } else {
              Specialization = datas?.filter((item) => (item.is_active === true))
            }
            this.setState({
              spcInfo: SpecList,
              SpecializationInfo: Specialization,
              selectedspec: SpecList[0],
              SpecdocDetails: this.state?.docDetails?.filter((item) => (item?.specialization_id === SpecList[0]?.id))
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message, false);
          }
        });
    } catch (e) {
      this.errorMessage(e.message, false);
    }
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            }, () => {
              this.setState({
                isrecall: response.data?.data?.recall_status !== "pending",
                isrefraction: response.data?.data?.recall_status === "pending"
              })
            })

          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorPatientAppointment = () => {
    let { history } = this.props;
    try {
      RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + '?clinic_id=' + this.state.clinicId + '&appointment_id=' + this.state.appointmentId)
        .then((response) => {
          if (response) {
            this.setState({
              appointmentDetails: response.data.data,
              isLocked: response.data.data?.is_locked ? true : false
            }, () => {
              setCachevalue(JSON.stringify(this.state.appointmentDetails), 'DoctorPatientData')
              // history.push({ pathname: "/DoctorHome/DoctorNotes",  })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getCounsellorData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              counsellorData: response?.data?.data,
              transmit: response?.data?.data?.surgery_details?.id ? true : false,
              status: response?.data?.data?.surgery_details?.status
            }, () => {
              this.setState({
                TansmitDisable: response?.data?.data?.surgery_details?.status === "Transmitted" || response?.data?.data?.surgery_details?.status === "Approved" ? true : false
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  RecallNotesPost = (status) => {
    try {
      let data = {
        "clinic_id": this.state.clinicId,
        "appointment_id": this.state?.appointmentId,
        "patient_id": this.state.patientId,
        "recall_status": status
      }
      if (status === "pending") {
        data["recall_notes"] = this.state.recallNotes;
        data["recall_optometrist_id"] = this.state.selectedOpto?.optometrist_id;
      }
      RestAPIService.create(data, Serviceurls.DOC_RECALL_NOTES_POST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              recallPopup: false
            }, () => { this.getPatientDetailData() })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTapperMed = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionId: response.data.data[response.data?.data.length - 1]?.prescription_id,
              isPrescriptionTrans: response.data.data[response.data?.data.length - 1]?.is_transmitted,
            }, () => {
              if (this.state.isCompleted) {
                if (this.state.isPrescriptionTrans == false) {
                  this.MedicationTransmit()
                } else {
                  this.postPatientStatus("Completed")
                }
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              doctorNameData: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PHARMACY_LIST_GET + `?clinic_id=${this.state.clinicId}&suggest=true`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              selectedPharmacy: response?.data?.data[0]?.pharmacy_id
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSurgeryNameList = () => {
    let states = this.state
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (response?.data?.data?.length > 0) {
              response?.data?.data.forEach((item) => {
                if (item?.surgery_key != null) {
                  states["surgeryNameData"].push(item)
                }
                this.setState({ states })
              })
            } else {
              this.setState({
                surgeryNameData: response?.data?.data ? response?.data?.data : []
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  SearchSurgeryName = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + `?surgery_key=${this.state.surgeryName}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              PackageData: response?.data?.data ? response?.data?.data : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorNotesLayout = () => {
    try {
      this.setState({ layout: true })
      let DoctorClinicId = localGetItem('SelectedDoctorClinicId')
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let ApiUrl = ""
      if (UserData?.service_type === "OPTOMETRY") {
        ApiUrl = Serviceurls.DOC_DOCTOR_NOTES_LAYOUT
      } else {
        ApiUrl = Serviceurls.DOC_DOCTOR_NOTES_LAYOUT
      }
      RestAPIService.getAll(ApiUrl)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              doctorLayout: response.data.data,
              doctorNotesLeft: response.data.data?.left_data ? response.data.data?.left_data : (this.state.isOPT || (UserData?.service_type === "OPTOMETRY")) ? Doctor_Notes_OPT_Left_Layout_JSON : Doctor_Notes_Left_Layout_JSON,
              doctorNotesRight: response.data.data?.right_data ? response.data.data?.right_data : (this.state.isOPT || (UserData?.service_type === "OPTOMETRY")) ? Doctor_Notes_OPT_Right_Layout_JSON : Doctor_Notes_Right_Layout_JSON,
              layout: false
            }, () => {
              for (let i = 0; i < this.state.doctorNotesLeft.length; i++) {
                this.LeftdivRefs[i] = React.createRef();
              }
              for (let i = 0; i < this.state.doctorNotesRight.length; i++) {
                this.RightdivRefs[i] = React.createRef();
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ layout: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ layout: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ layout: false })
      this.errorMessage(error.message)
    }
  }

  validate = () => {
    try {
      let message = null
      if ((this.state.transmitCounsellor?.counsellor_name || this.state.counsellor) &&
        (this.state.doctor) &&
        (this.state.transmitCounsellor?.counsellor_id || this.state.counsellor_ID)) {
        if (this.state.isOPT && !this.state.eyeType) {
          message = 'Enter all mandatory fields'
        } else {
          message = null
        }
      } else {
        message = 'Enter all mandatory fields'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postCounsellerTransmit = () => {
    try {
      let data = {
        anaesthetist: this.state.anaesthetist,
        appointment_id: this.state.appointmentId,
        clinic_id: this.state.clinicId,
        counsellor_name: this.state.transmitCounsellor?.counsellor_name ? this.state.transmitCounsellor?.counsellor_name : this.state.counsellor,
        counsellor_id: this.state.transmitCounsellor?.counsellor_id ? this.state.transmitCounsellor?.counsellor_id : this.state.counsellor_ID,
        notes: this.state.notes ? this.state.notes : null,
        package_id: this.state.package == "" ? null : this.state.surgery?.id ? this.state.surgery?.id : this.state.counsellorData?.surgery_details?.package_id ? this.state.counsellorData?.surgery_details?.package_id : null,
        patient_id: this.state.patientId,
        surgeon_name: this.state.doctor ? this.state.doctor : this.state.counsellorData?.surgery_details?.surgeon_name,
        surgery_id: this.state.surgeryName == "" ? null : this.state.surgery_id ? this.state.surgery_id : this.state.counsellorData?.surgery_details?.surgery_id ? this.state.counsellorData?.surgery_details?.surgery_id : null,
        status: null,
        id: this.state.counsellorData?.surgery_details?.id ? this.state.counsellorData?.surgery_details?.id : null
      }
      if (this.state.isOPT) {
        data["eye_type"] = this.state.eyeType
        data["iol_power"] = this.state.Power_IOL ? this.state.IOL_type ? `+${this.state.Power_IOL}` : `-${this.state.Power_IOL}` : null
      }

      let check = this.validate()
      if (check === null) {
        this.setState({ postLoad: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              // this.onClearHandler()
              this.setState({ postLoad: false, isDropdownOpen: false })
              this.getCounsellorData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoad: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoad: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(check)
      }
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  transmitCounsellor = () => {
    try {
      let data = {
        appointment_id: this.state.appointmentId,
        clinic_id: this.state.clinicId,
        patient_id: this.state.patientId,
        id: this.state.counsellorData?.surgery_details?.id,
        surgery_id: this.state.counsellorData?.surgery_details?.surgery_id,
        counsellor_id: this.state.transmitCounsellor?.counsellor_id ? this.state.transmitCounsellor?.counsellor_id : this.state?.counsellor_ID ? this.state.counsellor_ID : this.state.counsellorData?.surgery_details?.id,
        status: 'Transmitted'
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getCounsellorData()
            this.setState({ postLoad: false, transmitCounsellor: null, transmit: false, openPopUp: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  lockDoctorNotes = () => {
    try {
      let data = {}
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LOCK + `${this.state.appointmentId}/lock/`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.getDoctorPatientAppointment()
            // this.postTransmitSummary()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPatientStatus = (status) => {
    let { history } = this.props;
    try {
      var states = this.state
      var data = {}
      if (status == "Scan/Surgery") {
        data = {
          "appointment_id": this.state.appointmentId,
          "appointment_status": status,
          "waiting_timer": null,
          "clinic_id" : this.state.clinicId,
        }
      } else if (status == "Completed") {
        data = {
          "appointment_id": this.state.appointmentId,
          "appointment_status": "Completed",
          "waiting_timer": null,
          "clinic_id" : this.state.clinicId,
        }
      } else {
        data = {
          "appointment_id": this.state.appointmentId,
          "appointment_status": "Completed",
          "waiting_timer": null,
          "is_transfered": true,
          "transfer_doctor_id": this.state.Doctor?.doctor_id ? this.state.Doctor?.doctor_id : null,
          "transfer_id": states?.transferId ? states?.transferId : null,
          "clinic_id" : this.state.clinicId,
        }
      }
      RestAPIService.create(data, Serviceurls.DOC_APPOINTMENT_STATUS_UPDATE)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              scan_status: false,
              isCompleted: false
            }, () => clearCachevalue('updatePatient'))
            history.push({ pathname: "/DoctorHome" })
          }
        }).catch(e => {
          if (e?.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  PostTransfer = () => {
    let { history } = this.props;
    try {
      let data = {
        "clinic_id": this.state.clinicId,
        "appointment_id": this.state?.appointmentId,
        "doctor_id": this.state.selectedDoc?.doctor_id,
        "patient_id": this.state.patientId,
        "transfer_notes": this.state.comments
      }
      if (this.state.selectedDoc?.doctor_id) {
        RestAPIService.create(data, Serviceurls.DOC_TRANSFER_REQUEST)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({
                TransferPopup: false,
                optometristName: "",
              }, () => {
                clearCachevalue("DoctorPatientData")
                history.push({ pathname: "/DoctorHome" })
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Kindly Select the Mandatory Fields")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTransmitSummary = () => {
    try {
      let data = {}
      RestAPIService.create(data, Serviceurls.DOC_TRANSMIT_POST + this.state.appointmentId + "/")
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.getPatientDetailData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PostAddendum = () => {
    try {
      let data = {
        addendum_notes: this.state.Addcomments
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LOCK + `${this.state.appointmentId}/lock/`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ addendumPopup: false, Addcomments: "" }, () => {
              this.getDoctorPatientAppointment()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postDoctorAppointmentCreate = () => {
    try {
      var states = this.state

      var AptDateTime = new Date()

      var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')

      var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let clinicId = getCachevalue('SelectedDoctorClinic')
      if (states.Doctor?.doctor_id && states.Specilazation?.id && states.PurposeOfVisit) {
        var data = {
          patient_id: this.state.patientId,
          doctor_id: states.Doctor?.doctor_id ? states.Doctor?.doctor_id : null,
          speciality_id: states.Specilazation?.id ? states.Specilazation?.id : null,
          purpose_of_visit: states.PurposeOfVisit,
          patient_tag_id: states.selectedTagId?.id,
          appointment_date: AptDate,
          appointment_start_time: Aptime,
          patient_appointment_type: states.selectedAppointTypeId === null ? null : states.selectedAppointTypeId?.id ? states.selectedAppointTypeId?.id : 3,
          nurse_id: states.selectedNurseId?.staff_id ? states.selectedNurseId?.staff_id : null,
          request_id: this.state.RequestedPatientData?.id ? this.state.RequestedPatientData?.id : null,
          clinic_id: +clinicId,
          new_appointment: true
        }
        RestAPIService.create(data, Serviceurls.DOC_PATIENT_APPOINTMENT)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({ TransferPopup: false })
              this.postPatientStatus()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
      else {
        this.errorMessage("Kindly Select the Mandatory Fields")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearHandler = () => {
    try {
      this.setState({
        surgery: '',
        package: '',
        doctor: '',
        anaesthetist: '',
        counsellor: '',
        notes: '',
        surgeryName: "",
        isDropdownOpen: false,
        eyeType: null,
        Power_IOL: '',
        IOL_type: true,
        transmitCounsellor: null,
        PackageData: [],
        surgery_id: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleDisable = (data) => {
    this.setState({
      TansmitDisable: data
    })
  }

  addendumPopup = () => {
    return (
      <div>
        <Modal open={this.state.addendumPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "33vw", height: "40vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>Addendum</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ addendumPopup: false, Addcomments: "" })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <div style={{ fontWeight: 600, marginLeft: "0.5vw", color: Colors.SecondaryText }}>Reason</div>
            <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
              <TextField
                label={("Enter Description")}
                style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                multiline={true}
                rows={4}
                variant="outlined"
                value={this.state.Addcomments}
                onChange={(event) => {
                  let value = event.target.value
                  if (value?.length <= 250) {
                    this.setState({ Addcomments: value })
                  } else {
                    this.errorMessage('Allowed only 250 characters')
                  }
                }}
              />
            </div>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='contained'
                onClick={() => { this.setState({ addendumPopup: false, Addcomments: "" }) }}
              >Cancel</Button>
              <Button
                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => {
                  if (this.state.Addcomments != "") {
                    this.PostAddendum()
                  } else {
                    this.errorMessage('Enter Addendum Comments')
                  }
                }}
              >Addendum</Button>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  recallPopup = () => {
    return (
      <div>
        <Modal open={this.state.recallPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "28vw", height: "47vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>Recall</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ recallPopup: false, recallNotes: "", selectedOpto: null })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box className='eMed_rts_dropdown' sx={{ width: "91%" }}>
              <Autocomplete
                limitTags={1}
                size='small'
                options={this.state.optometristNameList?.map((item) => item)}
                getOptionLabel={(option) => (option?.optometrist_name)}
                value={this.state.selectedOpto}
                onChange={(event, newValue) => {
                  this.setState({ selectedOpto: newValue })
                }}
                renderInput={(params) => (<TextField {...params} label={'Select Opthometrist'} />
                )}
              />
            </Box>
            <div style={{ fontWeight: 600, fontSize: "0.9vw", marginLeft: "1vw", color: Colors.SecondaryText }}>Notes</div>
            <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
              <TextField
                label={("Enter Notes Here")}
                style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                multiline={true}
                rows={4}
                variant="outlined"
                value={this.state.recallNotes}
                onChange={(event) => {
                  let value = event.target.value
                  if (value?.length <= 500) {
                    this.setState({ recallNotes: value })
                  } else {
                    this.errorMessage('Allowed only 500 characters')
                  }
                }}
              />
            </div>
            <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} height={'3vw'} mr={"1.2vw"}>
              <Button
                sx={{ height: '2vw', width: '6.5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => { this.setState({ recallNotes: "", selectedOpto: null }) }}
              >Clear</Button>
              <Button
                sx={{ height: '2vw', width: '6.5vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => {
                  if (this.state.selectedOpto && this.state.recallNotes !== "") {
                    this.RecallNotesPost("pending")
                  } else {
                    if (!this.state.selectedOpto) {
                      this.errorMessage('Select Opthometrist')
                    } else {
                      this.errorMessage('Enter Notes')
                    }
                  }
                }}
              >Send</Button>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  renderSuggestionDropDown = (label, stateKey, Data) => {
    let states = this.state;
    return (
      <Autocomplete
        size='small'
        clearIcon={false}
        sx={{ width: '15vw' }}
        options={Data}
        getOptionLabel={(item) => (label === "Doctor" ? item.doctor_name : item.name)}
        value={states[stateKey]}
        onChange={(event, newValue) => {
          states[stateKey] = newValue
          if (newValue !== null && "doctor_id" in newValue) {
            let docDetail = { ...newValue }
            docDetail["name"] = docDetail["specialization_name"];
            delete docDetail.specialization_name;
            docDetail["id"] = docDetail["specialization_id"];
            delete docDetail.specialization_id;
            states["Specilazation"] = docDetail;
          }
          if (newValue !== null && "name" in newValue) {
            let filteredLst = states["docDetails"].length > 0 && states["docDetails"].filter(item => item["specialization_id"] === newValue["id"])
            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
            states["Doctor"] = null;
          }
          if (newValue === null && label === "Specialization") {
            states["filteredDocList"] = states["docDetails"];
          }
          this.setState({ states })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label + `${(label === "Doctor" || label === "Specialization") ? " *" : ""}`}
            placeholder={label}
          />
        )}
      />
    )
  }

  TransferAppointPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.TransferPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "33vw", height: "60vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontWeight={600}>Transfer Appointment</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ TransferPopup: false, TranferMethod: "Transfer Appointment", selectedDoc: null, selectedspec: null, comments: "", PurposeOfVisit: "" })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box component={'div'}>
              <Box component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <RadioGroup value={this.state.TranferMethod}
                  sx={{ display: 'flex', flexDirection: 'row', marginTop: "0.65vw" }}
                  onClick={(e) => { this.setState({ TranferMethod: e.target.value, selectedspec: null, selectedDoc: null, filteredDocList: [], Specilazation: null, Doctor: null, PurposeOfVisit: "" }, () => { this.getSpecInfo() }) }}
                >
                  <FormControlLabel value={'Transfer Appointment'} control={<Radio size='small' />} label='Transfer Doctor' />
                  <FormControlLabel value={'New Appointment'} control={<Radio size='small' />} label='Transfer Appointment' />
                </RadioGroup>
              </Box>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                  {this.state?.TranferMethod === "Transfer Appointment" ? <Autocomplete
                    clearIcon
                    freeSolo
                    size='small'
                    sx={{ width: "15vw" }}
                    options={this.state.spcInfo}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.name}
                    value={this.state?.selectedspec}
                    onChange={(e, newValue) => {
                      states["selectedspec"] = newValue
                      this.setState({
                        states
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label='Specialization *' />}
                  /> :
                    this.renderSuggestionDropDown("Specialization", "Specilazation", this.state.SpecializationInfo)
                  }
                </div>
                <div style={{ marginLeft: "1vw", marginTop: "0.65vw" }}>
                  {this.state?.TranferMethod === "Transfer Appointment" ? <Autocomplete
                    clearIcon
                    freeSolo
                    size='small'
                    sx={{ width: "15vw" }}
                    options={this.state.SpecdocDetails}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.doctor_name}
                    value={this.state?.selectedDoc}
                    onChange={(e, newValue) => {
                      states["selectedDoc"] = newValue
                      this.setState({
                        states
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label='Doctor *' />}
                  /> :
                    this.renderSuggestionDropDown("Doctor", "Doctor", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.docDetails)
                  }
                </div>
              </div>
              {this.state.TranferMethod == "New Appointment" ? <Box sx={{ paddingLeft: "1vw", paddingBottom: "0.5vw" }}>
                <Autocomplete
                  inputProps={{ maxLength: 90 }}
                  options={states.POVList}
                  getOptionLabel={(options) => options}
                  onChange={(e, value) => {
                    this.setState({
                      PurposeOfVisit: value
                    })
                  }}
                  clearIcon={false}
                  size='small'
                  sx={{ width: "31vw" }}
                  value={states.PurposeOfVisit}
                  renderInput={(params) => <TextField onChange={(event) => {
                    this.setState({
                      PurposeOfVisit: event.target.value
                    })
                  }}
                    {...params}
                    placeholder="Purpose Of Visit *"
                  />}
                />
              </Box> : null}
              <div style={{ fontWeight: 600, marginLeft: "0.5vw", color: Colors.SecondaryText }}>Notes</div>
              <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
                <TextField
                  label={("Enter Description")}
                  style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                  multiline={true}
                  rows={4}
                  variant="outlined"
                  value={this.state.comments}
                  onChange={(event) => {
                    let value = event.target.value
                    if (value?.length <= 250) {
                      this.setState({ comments: value })
                    } else {
                      this.errorMessage('Allowed only 250 characters')
                    }
                  }}
                />
              </div>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='contained'
                  onClick={() => { this.setState({ TransferPopup: false, TranferMethod: "Transfer Appointment", selectedDoc: null, selectedspec: null, comments: "", PurposeOfVisit: "" }) }}
                >Cancel</Button>
                <Button
                  sx={{ height: '2vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => { this.state.TranferMethod == "Transfer Appointment" ? this.PostTransfer() : this.postDoctorAppointmentCreate() }}
                >{this.state.TranferMethod == "Transfer Appointment" ? 'Transfer' : 'create Appointment'}</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div >
    )
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  // handleToggleDropdown = () => {
  //   this.setState((prevState) => ({
  //     isDropdownOpen: !prevState.isDropdownOpen,
  //   }));
  // };

  handleOptionSelect = (option) => {
    this.setState({
      surgeryName: option?.surgery_names,
      isDropdownOpen: false,
      surgery: option,
      package: option?.package_name,
      transmit: false
    });
  };

  renderPopup = () => {
    try {
      let states = this.state
      let list = states.counsellorData?.surgery_details
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPopUp}>
            <Box className='eMed_Doc_Notes_Counsellor_Pop_Up'>
              <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Move To Counsellor</Typography>
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => this.setState({ openPopUp: false })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} maxHeight={'32vw'} overflow={'auto'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} m={'0.5vw'}>
                  <Box>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Surgery</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        freeSolo
                        options={this.state.surgeryNameData}
                        getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.surgery_key}
                        value={this.state.surgeryName}
                        autoComplete='off'
                        onChange={(e) => {
                          let newValue = e.target.value;
                          if (newValue === "") {
                            this.setState({
                              package: '',
                              PackageData: [], surgery_id: "",
                              surgery: '', surgeryName: ""
                            })
                          }
                        }}
                        renderOption={(props, option) => {
                          return (
                            <div onClick={() => {
                              this.setState({
                                surgeryName: option?.surgery_key,
                                surgery_id: option?.surgery_id,
                                transmit: false,
                                package: '',
                                surgery: '',
                                PackageData: []
                              }, () => { this.SearchSurgeryName() })
                            }}>
                              <li {...props}>
                                {option?.surgery_key ? option?.surgery_key : null}
                              </li>
                            </div>
                          )
                        }}
                        renderInput={(params) => <TextField {...params} label='Search and Select Surgery' />}
                      />
                      {/* <div className="custom-dropdown" ref={this.dropdownRef}>
                        <TextField
                          label='Search and Select Surgery'
                          autoComplete="off"
                          size='small'
                          sx={{ width: '15vw' }}
                          // onClick={() => { this.handleToggleDropdown() }}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            this.setState({ package: '', surgeryName: newValue }, () => {
                              if (newValue.trim() === "") {
                                this.setState({ surgeryNameData: [], isDropdownOpen: false, surgery: null });
                              } else if (this.state.surgeryName.length > 0) {
                                this.getSurgeryNameList();
                              }
                            })
                          }}
                          value={this.state.surgeryName}
                        />
                        {(this.state.surgeryNameData.length > 0 && this.state.isDropdownOpen) && (
                          <div className="dropdown-content">
                            {this.state.surgeryNameData.map((option, index) => (
                              <div
                                className="dropdown-item"
                                key={index}
                                onClick={() => { this.handleOptionSelect(option) }}
                              >
                                {`${option?.surgery_names} ${option?.package_name ? (" - " + option?.package_name) : ""}`}
                              </div>
                            ))}
                          </div>
                        )}
                      </div> */}
                    </Box>
                  </Box>
                  <Box>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Package </Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      {/* <TextField
                        size='small'
                        sx={{ width: '15vw' }}
                        disabled={true}
                        value={this.state.package}
                        label='Package Name'
                        InputProps={{ readOnly: true }}
                      /> */}
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        options={this.state.PackageData}
                        getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.package_name}
                        value={this.state.package}
                        autoComplete='off'
                        onChange={(e, value) => {
                          this.setState({
                            package: value?.package_name,
                            surgery: value,
                            transmit: false
                          })
                        }}
                        renderInput={(params) => <TextField {...params} label='Select Package' />}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Surgeon Name *</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        options={this.state.doctorNameData?.map((item) => item?.first_name)}
                        value={this.state.doctor}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            doctor: newValue,
                            transmit: false
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          label='Select Surgeon'
                        />}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} ml={'0.5vw'}>
                  {this.state.isOPT ? <Box component={'div'} mr={'1vw'}>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye Type *</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        options={Eye_Type?.map((item) => item?.label)}
                        value={this.state.eyeType}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            eyeType: newValue,
                            transmit: false
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          label='Select Eye Type'
                        />}
                      />
                    </Box>
                  </Box> : null}

                  <Box component={'div'} mr={'1vw'}>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Anaesthetist</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '15vw' }}
                        options={this.state.doctorNameData?.map((item) => item?.first_name)}
                        value={this.state.anaesthetist}
                        limitTags={3}
                        autoComplete='off'
                        onChange={(event, newValue) => {
                          this.setState({
                            anaesthetist: newValue,
                            transmit: false
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          label='Select Anaesthetist'
                        />}
                      />
                    </Box>
                  </Box>
                  {/* <Box component={'div'} mr='1vw'>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Counseller</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <TextField
                        size='small'
                        sx={{ width: '15vw' }}
                        value={this.state.counsellor}
                        label={'Enter Counseller Name'}
                        autoComplete='off'
                        onChange={(e) => this.setState({ counsellor: e.target.value })}
                      />
                    </Box>
                  </Box> */}
                  <Box component={'div'}>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Counsellor *</Typography>
                    </Box>
                    <Autocomplete
                      clearIcon
                      size='small'
                      sx={{ width: '15vw', ml: '0.2vw' }}
                      options={this.state.counsellorData?.counsellor_details}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.counsellor_name}
                      value={this.state.transmitCounsellor}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          transmitCounsellor: newValue,
                          transmit: false,
                          counsellor_ID: "",
                          counsellor: ""
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        label='Select Counsellor'
                      />}
                    />
                  </Box>
                  <Box width={'15vw'} ml={'1vw'}></Box>
                </Box>
                {this.state.isOPT ? <Box component={'div'} display={'flex'} justifyContent={'space-between'} ml={'0.5vw'}>
                  <Box component={'div'} mr={'1vw'}>
                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>IOL Power</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'}>
                      <TextField
                        placeholder={'IOL Power'}
                        sx={{ width: "12vw" }}
                        size="small"
                        name={'Power_IOL'}
                        value={this.state.Power_IOL}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                // sx={{ width: '1.5vw', height: '1vw' }}
                                onClick={() => {
                                  this.setState({
                                    IOL_type: !this.state.IOL_type
                                  });
                                }}
                              >
                                {this.state.IOL_type ? (
                                  <img sx={{ width: '1.3vw', height: '1.3vw' }} src={ImagePaths.PlusIcon.default} alt="Plus Icon" />
                                ) : (
                                  <img sx={{ width: '1.3vw', height: '1.3vw' }} src={ImagePaths.MinusIcon.default} alt="Minus Icon" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          const isValidDecimal = CommonValidation.DecimalNumber(value);
                          if (isValidDecimal && (+value < 100 || value === '')) {
                            this.setState({
                              [name]: value === '' ? '' : value,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box width={'15vw'} ml={'1vw'}></Box>
                </Box> : null}
                <Box component={'div'}>
                  <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Doctor Notes</Typography>
                  </Box>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    value={this.state.notes}
                    sx={{ p: '0.5vw' }}
                    onChange={(e) => this.setState({ notes: e.target.value, transmit: false })}
                    label='Enter Notes'
                  />
                </Box>
                {this.state.transmit ?
                  <Box component={'div'}>
                    <Box component={'div'}>
                      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} p={'0.5vw'}>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Surgery</Typography>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Package Name</Typography>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Surgery Date</Typography>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Surgery Time</Typography>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Surgeon Name</Typography>
                        <Typography fontSize={'0.9vw'} width={'16.66%'} fontWeight={600} color={Colors.SecondaryText}>Anaesthetist</Typography>
                      </Box>
                      <Box component={'div'} height={'2.5vw'} display={'flex'} p={'0.5vw'}>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.surgery_name ? list?.surgery_name : '-'}</Typography>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.package_name ? list?.package_name : '-'}</Typography>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.surgery_date ? list?.surgery_date : '-'}</Typography>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.surgery_time ? timeOnlyConvert(list?.surgery_time) : '-'}</Typography>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.surgeon_name ? list?.surgeon_name : '-'}</Typography>
                        <Typography fontSize={'0.8vw'} width={'16.66%'} fontWeight={600}>{list?.anaesthetist ? list?.anaesthetist : '-'}</Typography>
                      </Box>
                    </Box>
                  </Box> : null}
                <Box component={'div'} p={'0.5vw'} borderTop={'2px solid lightgray'}>
                  <Box component={'div'}>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>Counseller Details</Typography>
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText} ml={'0.5vw'}>Package Details</Typography>
                    <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15dvh'} overflow={'auto'} mt={'0.5vw'}>
                      <Table stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>Package Name</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>{`Amount (${CurrencySymbol})`}</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>{`Discount (${CurrencySymbol})`}</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>{`Discount (%)`}</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>{`Bill Amount (${CurrencySymbol})`}</TableCell>
                            <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background }}>{`Status`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{this.state.counsellorData?.surgery_details?.package_name ? this.state.counsellorData?.surgery_details?.package_name?.length > 15 ?
                              <Tooltip placement='top' title={this.state.counsellorData?.package_name}><Typography fontSize={'0.8vw'}>{this.state.counsellorData?.surgery_details?.package_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
                              this.state.counsellorData?.surgery_details?.package_name : '-'}</TableCell>
                            <TableCell>{this.state.counsellorData?.surgery_details?.amount}</TableCell>
                            <TableCell>{this.state.counsellorData?.surgery_details?.discount_amount}</TableCell>
                            <TableCell>{this.state.counsellorData?.surgery_details?.discount_percentage}</TableCell>
                            <TableCell>{this.state.counsellorData?.surgery_details?.net_amount}</TableCell>
                            <TableCell>{this.state.counsellorData?.surgery_details?.status}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    <Box component={'div'} mt={'0.5vw'} mb={'0.5vw'}>
                      <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText} ml={'0.5vw'}>Counseller Comments</Typography>
                      <Box component={'div'} ml={'1vw'}>
                        {this.state.counsellorData?.surgery_details?.counsellor_notes}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.onClearHandler()}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='contained'
                  disabled={this.state.status === "Transmitted" || this.state.status === "Draft" || this.state.status === "Completed" || this.state.status === "Approved"}
                  onClick={() => this.postCounsellerTransmit()}
                >Save</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  disabled={this.state.postLoad || !this.state.transmit || this.state.status === "Transmitted" || this.state.status === "Draft" || this.state.status === "Completed" || this.state.status === "Approved"}
                  onClick={() => this.transmitCounsellor()}
                >Transmit</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  sentDatatoGlassPres = (data) => {
    this.setState({
      triggerGlassPrescription: data
    }, () => {
      this.setState({
        triggerGlassPrescription: !data
      })
    })
  }

  getDatafromAnt = (data) => {
    if (data) {
      this.setState({
        AnteriorData: true
      })
    }
  }

  renderLeftSide = () => {
    try {
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let isOpthometry = UserData?.module_name === "Optometry"
      return (
        <Box component={'div'} mb={'0.5vw'}>
          {this.state.doctorNotesLeft?.length > 0 ? this.state.doctorNotesLeft?.map((item, index) => (
            <Box key={index} component={'div'} pr={'0.3vw'}>
              {item?.label === 'Symptoms' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  {/* Symptoms */}
                  <DocSymptoms
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId}
                  />
                </Box> : null
              }
              {item?.label === 'Examination' && item?.is_active ?
                <Box component={'div'} border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocExamination
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Prescription' && item?.is_active && !isOpthometry ?
                <Box component={'div'} border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocMedication
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId}
                    appointmentDate={this.state.appointmentDate}
                    MedicTransmited={this.handleDisable.bind(this)} />
                </Box> : null
              }
              {item?.label === 'Diagnosis' && item?.is_active && !isOpthometry ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocDiagonisis
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Plan' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocPlan
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'General Advice' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocGeneralAdvice
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Previous Glass Power' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocPreviousGlassPower
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Subjective Refraction' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocSubjectiveRefraction
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID}
                    DatatoGlassPrescription={this.sentDatatoGlassPres.bind(this)}
                    isOpthometry = {isOpthometry} />
                </Box> : null
              }
              {item?.label === 'Retinoscopic Refraction' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocRetinoscopicRefraction
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID}
                    isOpthometry = {isOpthometry} />
                </Box> : null
              }
              {item?.label === 'Retinoscopic Acceptance' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocRetinoscopicAcceptance
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID}
                    DatatoGlassPrescription={this.sentDatatoGlassPres.bind(this)}
                    isOpthometry = {isOpthometry} />
                </Box> : null
              }
              {item?.label === 'Vision Assesment' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocVisionAssesment
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Ocular Response Analyzer' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocOcularResponser
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Intraocular Pressure' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocIntraocularPressure
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked} />
                </Box> : null
              }
              {item?.label === 'Auto Refractory' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocAutoRefractometry
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Anterior Segment' && item?.is_active ?
                <Box component={"div"} border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]} onBlur={() => { this.handleBox1Blur() }} onMouseDown={() => { this.handleBox1MouseDown() }} tabIndex="0">
                  <DocAnteriorSegTable
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID}
                    AntData={this.getDatafromAnt.bind(this)}
                    triggerAnteriorTable={this.state.triggerAnteriorTable} />
                </Box> : null
              }
              {item?.label === 'Anterior Segment Diagram' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocAnteriorSegment
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Posterior Segment' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocPosteriorSegment
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Fundus' && item?.is_active && !isOpthometry ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocFundus
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Other Investigation' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocOtherInvestigation
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Keratomentry' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocKeratomentry
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID}
                    appointmentDate={this.state.appointmentDate} />
                </Box> : null
              }
              {item?.label === 'IOLMaster' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocIOLMaster
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID}
                    appointmentDate={this.state.appointmentDate} />
                </Box> : null
              }
              {item?.label === 'Gonioscopy' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocGonioscopy
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    appointmentDate={this.state.appointmentDate} />
                </Box> : null
              }
              {item?.label === 'Glass Prescription' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocGlassPrescription
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    PatientData={this.state.patientDetails}
                    triggerGlassPrescription={this.state.triggerGlassPrescription} 
                    subjectiveRefraction={this.state.doctorNotesLeft?.find(item => item?.label === 'Subjective Refraction')}
                    fromOptometry={isOpthometry ? true : false}
                    doctorID={this.state.doctorID}
                    isOpthometry = {isOpthometry}
                    />
                </Box> : null
              }
              {item?.label === 'Treatment Plan' && item?.is_active && !isOpthometry ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocTreatmentPlan
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked} />
                </Box> : null
              }
              {item?.label === 'Notes' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <DocOverallNotes
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
                 {item?.label === 'Optometrist Notes' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.LeftdivRefs[index]}>
                  <OptometryDoctorNotes
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID}
                    isOpthometry={isOpthometry}
                   
                     />
                </Box> : null
              }
            </Box>
          )) :
            <Box component={'div'} display={'flex'} justifyContent={'center'} height={'62vh'} mt={'15vw'}>
              <Typography>Loading ....</Typography>
            </Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onOpenCounsellor = () => {
    try {
      let states = this.state
      let list = states.counsellorData?.surgery_details
      this.setState({
        counsellor: list?.counsellor_name ? list?.counsellor_name : '',
        surgery: list?.surgery_name ? list?.surgery_name : '',
        package: list?.package_name ? list?.package_name : '',
        doctor: list?.surgeon_name ? list?.surgeon_name : '',
        anaesthetist: list?.anaesthetist ? list?.anaesthetist : '',
        transmitCounsellor: list?.counsellor_name ? list?.counsellor_name : '',
        notes: list?.notes ? list?.notes : list?.doctor_notes,
        eyeType: list?.eye_type ? list?.eye_type : null,
        surgeryName: list?.surgery_name ? list?.surgery_name : '',
        transmit: list?.surgeon_name ? true : false,
        Power_IOL: list?.iol_power ? list?.iol_power.substring(1) : "",
        IOL_type: +list?.iol_power < 0 ? false : true,
        counsellor_ID: list?.counsellor_id ? list?.counsellor_id : ""
      }, () => {
        this.setState({ openPopUp: true, PackageData: [] }, () => {
          this.SearchSurgeryName()
        })
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let isOpthometry = UserData?.module_name === "Optometry"
      return (
        <Box component={'div'}>
          {this.state.doctorNotesRight?.length > 0 ? this.state.doctorNotesRight?.map((item, index) => (
            <Box key={index} component={'div'} pl={'0.2vw'} pr={'0.2vw'}>
              {item?.label === 'Patient Category' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocPatientCategory
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Allergy' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocAllergies
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} 
                    allergyData={this.allergyData} />
                </Box> : null
              }
              {item?.label === 'Vitals' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocVitals
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    isPD={this.state.isPDdoc}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Test' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocTest
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    prevAppointmentId={this.state.prevAppointmentId} />
                </Box> : null
              }
              {item?.label === 'Complaints' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocComplaints
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked} />
                </Box> : null
              }
              {item?.label === 'Ocular History' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocOcularHistory
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    doctorID={this.state.doctorID} />
                </Box> : null
              }
              {item?.label === 'Systemic Illness' && item?.is_active ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocSystemicIllness
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked}
                    allergyData={this.allergyData} />
                </Box> : null
              }
              {item?.label === 'Review Plan' && item?.is_active && !isOpthometry ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocReviewPlan
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked} />
                </Box> : null
              }
              {item?.label === 'Investigations' && item?.is_active && !isOpthometry ?
                <Box border={'1px solid lightgray'} mb={'0.5vw'} ref={this.RightdivRefs[index]}>
                  <DocInvestigations
                    appointmentId={this.state.appointmentId}
                    clinicId={this.state.clinicId}
                    patientId={this.state.patientId}
                    isLocked={this.state.isLocked} />
                </Box> : null
              }
              {item?.label === 'Counsellor' && item?.is_active && !isOpthometry ?
                <Box component={'div'} mb={'1vw'} ref={this.RightdivRefs[index]}>
                  <Box component={'div'} className='eMed_Patient_Card' sx={{ width: '25vw' }}>
                    <Box component={'div'} className='eMed_patient_image'>
                      <img style={{ height: '3vw', width: '3vw' }} src={ImagePaths.CounsellorTransfer.default} alt="" />
                    </Box>
                    <Box component={'div'}>
                      <Typography pl={'0.5vw'} fontSize={'0.8vw'} color={'gray'}>Counsellor</Typography>
                      <Box component={'div'}>
                        <Button
                          className='Common_Btn_Min_Width'
                          sx={{ textTransform: 'capitalize', padding: '0vw', pl: '0.5vw' }}
                          onClick={() => { this.onOpenCounsellor() }}
                          disabled={this.state.isLocked}
                        >
                          <Typography fontSize={'0.9vw'} fontWeight={600}>Click To Move Counsellor</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box> : null
              }
            </Box>
          )) :
            <Box component={'div'} display={'flex'} justifyContent={'center'} height={'62vh'} mt={'15vw'}>
              <Typography>Loading ...</Typography>
            </Box>}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  MedicationTransmit = () => {
    try {
      let data = {}
      this.setState({ isLoader: true })
      data = {
        appointment_id: this.state.appointmentId,
        patient_id: this.state.patientId,
        pharmacy_id: this.state.selectedPharmacy,
        prescription_id: this.state.prescriptionId
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TRANSMIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ isLoader: false, isCompleted: false }, () => {
              this.postPatientStatus("Completed")
            })

          }
        }).catch((error) => {
          this.setState({ isLoader: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ isLoader: false })
      this.errorMessage(error.message)
    }
  }

  confirmSave = () => {
    this.setState({
      confirmpopup: false
    }, () => {
      this.lockDoctorNotes()
    })
  }
  statusChange = () => {
    this.setState({
      statuspopup: false
    }, () => {
      if (this.state?.scan_status) {
        this.postPatientStatus("Scan/Surgery")
      } else {
        this.setState({
          isCompleted: true
        }, () => {
          this.getTapperMed()
        })

      }
    })
  }

  popClose = () => {
    this.setState({
      confirmpopup: false
    })
  }

  statuspopClose = () => {
    this.setState({
      statuspopup: false
    })
  }

  render() {
    let { history } = this.props
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    let PatientData = JSON.parse(getCachevalue('DoctorPatientData'))
    let appointment = getCachevalue('DoctorPatientData')
    let isOpthometry = UserData?.module_name === "Optometry"
    let LockDocNotes;
    if (localGetItem('DoctorNotesLock') === 'false') {
      LockDocNotes = true
    } else {
      LockDocNotes = false
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'80vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box component={'div'} display={'flex'}>
            <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
              <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
            </Box>
            <Box component={'div'} onClick={() => { history.push({ pathname: "/DoctorHome/Reports/DoctorVisit" }) }} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
              <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.8vw'} width={'1.8vw'} />
              <div style={{ fontSize: "0.85vw" }}>Visit Log</div>
            </Box>
            {this.state?.isOPT ? null :
              <CommonPatientCard details={attender} showDetailed={true} />
            }
            {this.state?.isOPT && this.state.isrecall ? <Box component={'div'} onClick={() => { this.setState({ recallPopup: true }) }} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
              <Box component={'img'} src={ImagePaths.SyncIcon.default} height={'1.5vw'} width={'1.5vw'} />
              <div style={{ fontSize: "0.85vw" }}>Recall</div>
            </Box> : isOpthometry && this.state.isrefraction ?
              <Box component={'div'} onClick={() => { this.RecallNotesPost("completed") }} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
                <Box component={'img'} src={ImagePaths.SyncCompleted.default} height={'1.5vw'} width={'1.5vw'} />
                <div style={{ fontSize: "0.85vw" }}>Complete</div>
              </Box>
              : null}
            {(this.state.isOPT && this.state.optometristName) ?
              <Box component={'div'} className='doc_header_card' mr={'0.5vw'} display={"flex"} width={"18vw"}>
                <Box><img src={ImagePaths.OptometristEye.default} alt={"Optometrist"} /></Box>
                <Box marginLeft={"1vw"} >
                  <Typography sx={{ fontSize: '0.8vw' }}>Optometrist</Typography>
                  <Typography fontWeight={"bold"} sx={{ fontSize: '0.9vw' }}>{this.state.optometristName?.length > 23 ? this.state.optometristName?.slice(0, 25) + '...' : this.state.optometristName}</Typography>
                </Box>
              </Box>
              : null}
            {this.state.allergyData?.length > 0 ?
              <Tooltip placement='top' title={this.state.allergyData?.map(item => item.name).join(",")} arrow>
                <Box component={'div'} className='doc_header_card' sx={{ width: "6vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
                  <Box component={'img'} src={ImagePaths.AllergyAlert.default} height={'2vw'} width={'2vw'} />
                  <div style={{ fontSize: "0.7vw" }}>Allergy</div>
                </Box>
              </Tooltip> : null}
            {PatientData?.insurance_name || this.state.patientDetails?.insurance_company_name ?
              <Box component={'div'} className='doc_header_card_New' sx={{ width: "15vw", display: 'flex', flexDirection: 'column' }} mr={'0.5vw'}>
                <Typography color={Colors.Primary} fontSize={'0.9vw'} fontWeight={600} m={'0.2vw'}>Insurance Details</Typography>
                {PatientData?.insurance_name?.length > 24 || this.state.patientDetails?.insurance_company_name?.length > 24 ?
                  <Tooltip title={PatientData?.insurance_name ? PatientData?.insurance_name : this.state.patientDetails?.insurance_company_name} placement='top' arrow><Typography fontSize={'0.9vw'} fontWeight={600}>{PatientData?.insurance_name ? PatientData?.insurance_name?.slice(0, 24) + '...' : this.state.patientDetails?.insurance_company_name?.slice(0, 24) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{PatientData?.insurance_name ? PatientData?.insurance_name : this.state.patientDetails?.insurance_company_name}</Typography>}
              </Box>
              : null}
          </Box>
          <Button emed_tid='doc_notes_open_drawer' sx={{ width: '2vw' }} className="eMed_Other_DocNotes_DrawerIcon" onClick={() => { this.setState({ OpenDrawer: true }) }} onMouseEnter={() => { this.setState({ OpenDrawer: true }) }}>
            <MenuOpenIcon color='primary' />
          </Button>
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} pb={'0.5vw'} maxHeight={'62vh'} overflow={'scroll'}>
          <Box component={'div'} width={'70%'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} width={'30%'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {isOpthometry ? null :
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems="center" borderTop={'1px solid lightgray'} height={'3vw'} >
            {/* <Button
            sx={{ textTransform: 'capitalize', mr: '1vw' }}
            size="small"
            variant='contained'
            disabled={!this.state.isLocked}
            onClick={() => this.postTransmitSummary()}
          >Transmit Summary</Button> */}
            <Button
              sx={{ textTransform: 'capitalize', mr: '1vw' }}
              size="small"
              variant='contained'
              disabled={this.state.disableButton || this.state.TansmitDisable || this.state.TransferDisable || this.state.isLocked || PatientData?.appointment_status === "Completed"}
              onClick={() => { this.setState({ TransferPopup: true }, () => { this.getSpecInfo() }) }}
            >TRANSFER</Button>
            <Button
              sx={{ textTransform: 'capitalize' }}
              variant='contained'
              size="small"
              disabled={this.state.disableButton || (this.state.isLocked ? LockDocNotes : false)}
              onClick={() => { this.state.isLocked ? this.setState({ addendumPopup: true }) : this.setState({ confirmpopup: true }) }}
            >{this.state.isLocked ? "ADDENDUM" : "LOCK"}</Button>
            {this.state?.isOPT ? <Button
              sx={{ textTransform: 'capitalize', ml: '1vw' }}
              size="small"
              variant='contained'
              disabled={this.state.disableButton || PatientData?.appointment_status === "Completed" || PatientData?.appointment_status === "Scan/Surgery" || this.state.isLocked}
              onClick={() => { this.setState({ statuspopup: true, scan_status: true }) }}
            >SCAN/SURGERY</Button> : null}
            <Button
              sx={{ textTransform: 'capitalize', ml: '1vw' }}
              size="small"
              variant='contained'
              disabled={this.state.disableButton || PatientData?.appointment_status === "Completed" || this.state.isLocked}
              onClick={() => { this.setState({ statuspopup: true }) }}
            >COMPLETE</Button>
          </Box>}
        {this.state.confirmpopup ?
          <ConfirmPopup
            cnfTitle={'Confirmation'}
            cnfContent1={'Are you sure, Do you want to Lock Doctor Notes'}
            cnfAlertPopupClose={this.popClose.bind(this)}
            cnfData={this.confirmSave.bind(this)}
          />
          : null}
        {this.state.statuspopup ?
          <ConfirmPopup
            cnfTitle={'Confirmation'}
            cnfContent1={'Are you sure, Do you want to change status'}
            cnfAlertPopupClose={this.statuspopClose.bind(this)}
            cnfData={this.statusChange.bind(this)}
          />
          : null}
        {this.renderPopup()}
        {this.TransferAppointPopup()}
        {this.addendumPopup()}
        {this.recallPopup()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        <Drawer
          anchor={'right'}
          open={this.state.OpenDrawer}
          ModalProps={{ onBackdropClick: () => { this.setState({ OpenDrawer: false }) } }}
        >
          <Box className="eMed_OtherDocNotes_DrawerDiv" onMouseLeave={() => { this.setState({ OpenDrawer: false }) }}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: 'auto' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {
                  this.state.doctorNotesLeft.map((item, index) => (
                    (item?.is_active && (Doctor_Notes_OPTHOMETRY_Left_Layout_JSON.some((list) => (list?.label === item.label)) || !isOpthometry)) ?
                      <Button
                        emed_tid='doc_notes_moveto_option'
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => {
                          this.setState({
                            OpenDrawer: false
                          }, () => {
                            this.LeftdivRefs[index]?.current?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'center'
                            });
                          })

                        }}>
                        {item.label}
                      </Button> : null
                  ))
                }
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {
                  this.state.doctorNotesRight.map((item, index) => (
                    (item?.is_active && (Doctor_Notes_OPTHOMETRY_Right_Layout_JSON.some((list) => (list?.label === item.label)) || !isOpthometry)) ?
                      <Button
                        emed_tid='doc_notes_moveto_option'
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => {
                          this.setState({
                            OpenDrawer: false
                          }, () => {
                            this.RightdivRefs[index]?.current?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'center'
                            });
                          })

                        }}>
                        {item.label}
                      </Button> : null
                  ))
                }
              </Box>
            </Box>

          </Box>
        </Drawer>
        {/* {this.renderCounsellerScreen()} */}
      </Box>
    )
  }
}
