import React, { Component } from 'react';
import '../IpPatientDetails.css';
import { Box, Stack, Grid, Typography, Paper, TextField, Autocomplete, Button} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { CommonPatientDetails, CommonPatientCard, CommonEditButton, CommonDeleteButton, CommonPopUp, DeletePopup, ConsultantPatientCard  } from "../../../../../Components/Common Components/CommonComponents";
import { Colors } from '../../../../../Styles/Colors';
import {getCachevalue, localGetItem} from "../../../../../Utility/Services/CacheProviderService";
import RestAPIService from "../../../../../Utility/Services/RestAPIService";
import {Serviceurls} from "../../../../../Utility/API/Serviceurls";
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {PainLevel} from '../../../../../Utility/Constants';
import { DateTime } from 'luxon';
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../../Components/Loader';
import { CommonGridToolBarWithButton } from '../../../../../Components/Common Components/CommonComponents';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import {ImagePaths} from '../../../../../Utility/ImagePaths';
const labels = ["January", "February", "March", "April", "May", "June"];

class PainChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipPatientData: {},
      IPadmissionDetails: {},
      painChartList: [],
      pageSize: 10,
      painDate: new Date(),
      NurseList: [],
      nurseName: {
        "nurse": "",
        "staff_id": ""
      },
      painLevel: "",
      selectedID: "",
      deleteID: "",
      deletePopup: false,
      isViewChart: false,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
    }
  }
  componentDidMount() {
    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.getPatientData()
      this.GetNurseList()
      this.getPaintLevelList()
    })
  }
  
  GetNurseList = () => {
    try {
      let url = Serviceurls.FO_STAFF_LIST;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}?clinic_id=${this.state.clinic_id}`
      }
        RestAPIService.getAll(url)
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        NurseList: response.data.data
                    })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    } catch (error) {
        this.errorMessage(error.message)
    }
}
  getPatientData = () => {
    let admission_id =   this.state.ipPatientData?.id 
    let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${admission_id}`;
    if (this.props.is_From_IpNotes && this.state.clinic_id) {
      url = `${url}&clinic_id=${this.state.clinic_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              IPadmissionDetails: response.data.data[0]
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  getPaintLevelList = () => {
    try {
      var admission_id = this.state.ipPatientData?.id;
      let url = `${Serviceurls.IP_PAIN_CHART}?ip_admission_id=${admission_id}`;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}&clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url)
          .then((response) => {
              if (response.data.status === "success") {
                  this.setState({
                      painChartList: response.data.data
                  }, () => {
                    var { painChartList } = this.state
                    painChartList && painChartList.length > 0 && painChartList.forEach(data => { data.is_pain_level = false; });
                    { painChartList && painChartList.length > 0 && painChartList.map((item, index) => {
                      var baseIndex = index - 1
                      var prevIndex = baseIndex == -1 ? 0 : baseIndex
                      if(+(painChartList[prevIndex].pain_level) > +(painChartList[index].pain_level)) {
                        painChartList[index].is_pain_level = true
                      } else {
                        painChartList[index].is_pain_level = false
                      }
                      this.setState({
                         painChartList
                      })
                     })
                    }
                  })
                  
              }
          }).catch((error) => {
              if (error?.response?.data?.message) {
                  this.errorMessage(error.response.data.message)
              } else {
                  this.errorMessage(error.message)
              }
          })
    } catch (error) {
      this.errorMessage(error.message)
   }
  }
  deletePainLevel = () => {
   try { 
    RestAPIService.delete(Serviceurls.IP_PAIN_CHART + "?id=" + this.state.deleteID)
    .then((response) => {
      if (response.data.status === "success") {
          this.getPaintLevelList()
          this.handleClear()
          this.successMessage(response.data.message)
      }
     }).catch((error) => {
      this.errorMessage(error.message)
     })
    } catch (error) {
      this.errorMessage(error.message)
   }
  }
  onPressViewButton = (flag) => {
    this.setState({
      isViewChart: flag
    })   
  }
  gridToolBar = () => {
    var { t } = this.props
    return(
      <Box>
         <CommonGridToolBarWithButton onClickCommonButton = {this.onPressViewButton.bind(this)} label = {t("ViewChart")} ViewBtn = {true}/>
      </Box>

    )
  }
  renderLeftContent = () => {
    const { t } = this.props
    this.state.painChartList.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: 'sno', sortable: false, headerName: t("SNo"), flex: 0.05 },
      {
        field: 'pain_chart_date', headerName: t("DateTime"), flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => {
          let startDate = params?.row?.pain_chart_date ? new Date(params.row.pain_chart_date) : ""
          let date = (this.state.painDate) ? DateTime.fromJSDate(startDate).toFormat("dd-MM-yyyy hh:mm:ss a") : this.state.painDate
          return (

            <Box component={'div'}>{date ? (date) : '-'}</Box>
          )
        }
      },
      {
        field: 'pain_level', headerName: t("PainLevel"), flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => { 
          return( 
           <Box component={'div'} sx = {{ display: "flex", flexDirection: "row" }}>
             <Typography> {params?.row?.pain_level ? (params?.row?.pain_level) : '-'}</Typography> 
             {params?.row?.pain_level ? 
              <Box sx = {{ marginLeft: "0.5vw" }}>
                { params?.row?.is_pain_level ? 
                 <img className='emedhub_pain_range' src={ImagePaths.PainLowRange.default} /> :
                 <img className='emedhub_pain_range' src={ImagePaths.PainHighRange.default} />
                }
              </Box> : null
            }
          </Box>)
        }
      },
      {
        field: 'nurse_name', headerName: t("NurseName"), flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name ? (params?.row?.nurse_name) : '-'}</Box>)
      },
      {
        field: 'modified_user', headerName: t("UpdatedBy"), flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_user ? (params?.row?.modified_user) : '-'}</Box>)
      },
      {
        field: 'action', headerName: "Action", flex: 0.2, headerAlign: "center", align: "center",  sortable: false, hideable: false,
        renderCell: (params) => (<Box component={'div'}>
          <CommonEditButton size='1.5vw' onClick={() => {
            var nurseName = {
              "nurse": params.row.nurse_name,
              "staff_id": params.row.nurse_id
            } 
            this.setState({
              painDate : params.row.pain_chart_date,
              painLevel: params.row.pain_level,
              nurseName: nurseName,
              selectedID: params.row.id
            })
          }} />
          <CommonDeleteButton  onClick={() => {
            this.setState({
              deletePopup: true,
              deleteID: params.row.id     
            })
          }} size='1.5vw' />
        </Box>)
      },
    ]
    return (
      <Box component={'div'} className='eMed_pain_left_card'>
        <DataGrid
          className='eMed_room_grid'
          rows={this.state.painChartList}
          columns={columns}
          getRowId={(row) => row.id}
          density='compact'
          disableSelectionOnClick = {true}
          pageSize={this.state.pageSize}
          onPageSizeChange={(newPageSize) => {
              this.setState({ pageSize: newPageSize })
          }}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                </Stack>
            )
        }}
        />
      </Box>
    )
  }
  renderDateFilter = () => {
    const { t } = this.props
    const states = this.state;
    const currentDate = new Date();
    const admissionDate = states?.ipPatientData?.admitted_date
      ? new Date(states.ipPatientData?.admitted_date)
      : states?.ipPatientData?.admission_date
        ? new Date(states.ipPatientData?.admission_date)
        : currentDate;
    const dischargeDate = states.ipPatientData?.discharge_date && (new Date(states.ipPatientData?.discharge_date) <= currentDate) ?
      new Date(states.ipPatientData?.discharge_date) : currentDate;
      
    return(
      <Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor, marginLeft: "1dvw", marginBottom: '0.5vw' }} className='eMed_DocNotes_DateTime' >
            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("DateTime")}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                inputFormat='DD-MM-YYYY & hh:mm:ss A'
                value={this.state.painDate}
                
                onChange={(newDate) => {
                  if (newDate === null) {
                    this.setState({ painDate: new Date() })
                  }
                  else if (newDate > new Date()) {
                    this.setState({ painDate: new Date() })
                  }
                  else {
                    this.setState({ painDate: newDate })
                  }
                }}
                // minDate={this.props?.IPadmissionDetails?.admitted_date}
              minDate={admissionDate}
              maxDate={dischargeDate}
                renderInput={(params) => <TextField size='small'
                  {...params}
                  fullWidth
                  variant='standard'
                  className='eMed_DocNotes_datePic_Textfield'
                />}
              />
            </LocalizationProvider>
          </Box>
      </Box>  
    )
  }
  renderSubContainer = () => {
    const { t } = this.props
    return(
       <Box className = 'emedhub_auto_complate'>
            <Autocomplete className='emedhub_pain_level'
                size='small'
                options={PainLevel}
                getOptionLabel={(option) => option}
                value={this.state.painLevel}
                onChange={(event, newValue) => {
                    this.setState({
                      painLevel: newValue
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("PainManitory")}
                        placeholder={t("PainManitory")}
                    />
                )}
            /> 
            <Autocomplete className='emedhub_Nurse_list'
                size='small'
                options={this.state.NurseList}
                getOptionLabel={(option) => option.nurse ? option.nurse : ""}
                value={this.state.nurseName}
                onChange={(event, newValue) => {
                    this.setState({
                        nurseName: newValue
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("NurseManitory")}
                        placeholder={t("NurseManitory")}
                    />
                )}
            />
       </Box>
    )
  }
  handleClickAdd = () => {
    var { t } = this.props
    try {
      let admission_id =   this.state.ipPatientData?.id 
      let startDate = new Date(this.state.painDate)
      let date = (this.state.painDate) ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")  : this.state.painDate
      let Time= (this.state.painDate) ? DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS)  : this.state.painDate
      var data = {
            "id": this.state.selectedID ? this.state.selectedID : "",
            "ip_admission_id": admission_id,
            "nurse_id": this.state.nurseName.staff_id,
            "pain_date":(date + " " +Time),
            "pain_level": this.state.painLevel
      }
      if( this.state.painLevel == null) {
        this.errorMessage(t("InvalidPainLevel"))
      } else if (this.state.nurseName == null||( this.state.nurseName && (this.state.nurseName.staff_id) == "")) {
        this.errorMessage(t("InvalidNurseName"))
      }else if ( date == "Invalid DateTime") {
        this.errorMessage(t("Invalid Date and Time"))
      } else { 
       RestAPIService.create(data, Serviceurls.IP_PAIN_CHART)
        .then((response) => {
          if (response.data.status === "success") {
              this.getPaintLevelList()
              this.handleClear()
              this.successMessage(response.data.message)
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
      }  
    }

    catch (error) {
    }
  
  }
  handleClear = () => {
    this.setState({
      nurseName: {
        "nurse": "",
        "staff_id": ""
      },
      painDate: new Date(),
      painLevel: "",
      selectedID: "",
      deleteID: "",
      deletePopup: false
    })
  }
  renderRightContent = () => {
    const { t } = this.props
    return (
      <Paper component={'div'} className={'eMed_pain_right_card'}>
          <Typography component={'div'} fontWeight={600} color = {Colors.SecondaryText} className='eMed_pain_rgt_title'>{t("PainChart")}</Typography>
          <Box className = 'emedhub_pain_sub_container'>
            {this.renderDateFilter()}
            {this.renderSubContainer()}
          </Box>
          <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_recPayment_btn" disabled={this.state.BtnDisable} onClick={() => {
            this.setState({
              BtnDisable: true
            }, () => { this.handleClickAdd() })
          }}>{t("Add")}</Button>
        </Stack>
      </Paper>          
    )
  }
  popupClose = () => {
    this.setState({
      deletePopup: false
    })
  }
  renderChartComponent = () => {
   var { painChartList } = this.state
   var labels = []
   var chartData = []
   var lastPainLevel

   for( let i = 0; i < painChartList.length; i++ ) {  
    let start_date = new Date(painChartList[i].pain_chart_date)
    let date = DateTime.fromJSDate(start_date).toFormat("dd-MM-yyyy hh:mm a")
    labels.push(date)
    chartData.push(painChartList[i].pain_level)
    lastPainLevel = painChartList[i].pain_level
   }

   var labelColor

   if(lastPainLevel >= 8){

    labelColor = 'rgb(238,191,191)'

   }else if(lastPainLevel >= 6){

    labelColor = 'rgb(255,196,177)'

   }else if(lastPainLevel >= 4){

    labelColor = 'rgb(255,224,177)'

   }else if(lastPainLevel >= 2){

    labelColor = 'rgb(255,247,177)'

   }else{

    labelColor = 'rgb(179,255,195)'

   }

   var data = {
    "labels": labels,
    "title" : "Pain Cahrt",
    "datasets": [
      
      {
        label: "No Pain",
        backgroundColor: 'rgb(179,255,195)',
        borderColor: 'rgb(179,255,195)',
      },
      {
        label: "Mild Pain",
        backgroundColor: 'rgb(255,247,177)',
        borderColor: 'rgb(255,247,177)',
      },
      {
        label: "Moderate",
        backgroundColor:'rgb(255,224,177)',
        borderColor:'rgb(255,224,177)',
      },
      {
        label: "Severe / Very Severe",
        backgroundColor: 'rgb(255,196,177)',
        borderColor: 'rgb(255,196,177)',
      },
      {
        label: "Worst Pain",
        backgroundColor: 'rgb(238,191,191)',
        borderColor: 'rgb(238,191,191)',
      },
      {
        label: "",
        backgroundColor: Colors.white,
        borderColor: Colors.white,
      },
      {
        label: "",
        backgroundColor: Colors.white,
        borderColor: Colors.white,
      },
      {
        label: "Pain Level",
        backgroundColor: labelColor,
        borderColor: "rgb(187,0,0)",
        data: chartData 
      },
    ],
    
  };

  var options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 10,
        stepSize: 1
      }
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 0.2,
        to: 0,
        loop: true
    }
  },
  plugins: {
    title: {
      display: true,
      text: 'Pain Chart'
    }
  },

    

  }
  var chartBackground ={
    id: 'chartBackground',
    beforeDraw: (chart, args, pluginOptions) => {
      const {ctx, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;

      bgColor(10, 8, 'rgb(238,191,191)')
      bgColor(8, 6, 'rgb(255,196,177)')
      bgColor(6, 4, 'rgb(255,224,177)')
      bgColor(4, 2, 'rgb(255,247,177)')
      bgColor(2, 0, 'rgb(179,255,195)')

      function bgColor (Top, Height, Color) {
      ctx.fillStyle = Color;
      ctx.fillRect(left, y.getPixelForValue(Top), width, y.getPixelForValue(Height) - y.getPixelForValue(Top));
      }
    }
  }
  return(
      <Box>
         { this.state.isViewChart ? 
                  <Box sx={{ width: "70vw" }}>
                    <Line 
                    data={data} 
                    options={options}
                    plugins= {[chartBackground]}
                     />
                  </Box> : null  

          }
      </Box>
    )
  }
  viewChartPopupClose = () => {
    this.setState({
      isViewChart: false
    })
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      BtnDisable : false
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message,
      BtnDisable : false
    })
  }
  render() {
    const { t } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
    }
    return (
      
      <Box id="eMed_medication_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
       <Grid container className="eMed_medication_Box" >
        <Stack className="eMed_medicationCard_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2} style={{marginBottom: "0.2dvw"}}>
          <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
            <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true}/>
          </Stack>
          <CommonPatientCard details={attender} showDetailed={true}/>
          <ConsultantPatientCard name={consultant} width={"20dvw"}/>
        </Stack>
          <Grid container spacing = {2} className={'eMed_vital_Container'}>
            <Grid item xs={8} className={'eMed_vital_left'}>
              {this.renderLeftContent()}
            </Grid>
            <Grid item xs={4} className={'eMed_vital_right'}>
              {this.renderRightContent()}
            </Grid>
          </Grid>
        </Grid>
        {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${"Pain Chart"}?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deletePainLevel.bind(this)}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {(this.state.isViewChart) ?
                    <CommonPopUp width={"75vw"}  height = { "90vh"}
                        backgroundColor={Colors.white}
                        popUpClose={this.viewChartPopupClose.bind(this)}
                        title={""}
                        component={this.renderChartComponent.bind(this)}
                    />
                    : null
                }
                <Loader loaderOpen={this.state.isLoader} />
               
      </Box>

    )
  }
}

export default withTranslation()(PainChart)
