import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Colors } from '../../../Styles/Colors'
import CommonGridHeader, { CommonPatientDetails } from '../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonUserAccess from './commonUserAccess'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

const rows = [
  { sno: 1, user_info: "Anand", userName: "AnandRaj", modules: "FO", status: "active", created_by: "visnhu" }
]

let UserData = null;
class Useraccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newUser: false,
      newUserData: [],
      onEditUser: {},
      isEdit: false,
      FilterOpen: false,
      fromDate: "",
      toDate: "",
      UserStatus: "",
      dateIndex: "",
      Username: "",
      FoAccess: this.props.fromPharmacy ? false : true,
      LabAccess: this.props.fromPharmacy ? false : true,
      RadAccess: this.props.fromPharmacy ? false : true,
      PharmaAccess: true,
      isFromPharmacy: this.props.fromPharmacy ? this.props.fromPharmacy : false
    }
  }

  componentDidMount() {
    if (this.props.history?.location?.state?.NewUserCreated) {
      this.successMessgae(this.props.history?.location?.state?.Message)
      this.props.history.push({ pathname: "/Settings/UserAccess/UserSettings", state: { NewUserCreated: undefined, Message: undefined } })
    }
    UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    if(UserData){
      this.getUserData()
    }
    
  }

  errorMessgae = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessgae = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}

  getUserData = () => {
    try {
      this.LoaderFunction(true)
      let AccessList = []
      if (this.state.FoAccess) { AccessList.push("front_office") }
      if (this.state.LabAccess) { AccessList.push("Laboratory") }
      if (this.state.PharmaAccess) {AccessList.push(this.state.isFromPharmacy && UserData?.pharmacy_type !== 'Inhouse Pharmacy'? "Standalone Pharmacy": "Pharmacy")}
      if(this.state.RadAccess) { AccessList.push("Radiology") }
      this.setState({disableBtn: true})
      RestAPIService.getAll(Serviceurls.USER_ACCESS_GET_LIST + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&search_key=${this.state.Username}&user_modules=${AccessList}&active_status=${this.state.UserStatus}`).
        then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              newUserData: response.data.data
            },()=>{this.LoaderFunction(false)
                   this.setState({disableBtn: false})
            })
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          this.setState({disableBtn: false})
          if (error?.response?.data?.status === "fail") {
            this.errorMessgae(error.response.data.message)
          } else {
            this.errorMessgae(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.setState({disableBtn: false})
      this.errorMessgae(error.message)
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
  ClearData = () => {
    this.setState({
      fromDate: "",
      toDate: "",
      UserStatus: "",
      dateIndex: "",
      Username: "",
      FoAccess: true,
      LabAccess: true,
      RadAccess: true,
      PharmaAccess: true,
      FilterOpen: false,
    })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>

          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)} />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              size='small'
              fullWidth
              label="User Name"
              value={this.state.Username}
              name="Username"
              onChange={(e) => { this.setState({ Username: e.target.value }) }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <FormControl size='small' fullWidth>
              <InputLabel>{t("Status")}</InputLabel>
              <Select
                label={t("Status")}
                name='status'
                value={this.state.UserStatus}
                onChange={(e) => { this.setState({ UserStatus: e.target.value }) }}
              >
                <MenuItem value={true} >{"Active"}</MenuItem>
                <MenuItem value={false} >{"Inactive"}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box component={"div"}>
            <Box className="eMed_Filter_Header">
              <Typography marginLeft={"1vw"}>{t("Modules")}</Typography>
            </Box>
            <Box className="eMed_CheckBox_Group">
              {this.state.isFromPharmacy ? null
                : <>
                  <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FoAccess} onChange={(e) => { this.setState({ FoAccess: e.target.checked }) }} size='small' />} label={t("Front Office")} />
                  <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.LabAccess} onChange={(e) => { this.setState({ LabAccess: e.target.checked }) }} size='small' />} label={t("Laboratory")} />
                  <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.RadAccess} onChange={(e) => { this.setState({ RadAccess: e.target.checked }) }} size='small' />} label={t("Radiology")} />
                </>}
              <FormControlLabel className='eMed_CheckBoxDiv' disabled = {this.state.isFromPharmacy} control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.PharmaAccess} onChange={(e) => { this.setState({ PharmaAccess: e.target.checked }) }} size='small' />} label={t("Pharmacy")} />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => { this.ClearData() }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid='Pharmacy_UserAccess_Filter_Search' disabled={this.state.disableBtn}
            onClick={() => {
              this.getUserData()
              this.setState({
                FilterOpen: false
              })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  
  render() {
    const { t } = this.props
    this.state.newUserData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "login_fname", headerName: "User Information", flex: 0.166,
        renderCell: (params) => (
          <Box component={'div'}>
            <CommonPatientDetails data={params?.row} fromUserAccess={true} />
          </Box>
        )
      },
      { field: "user_name", headerName: "UserName", flex: 0.16 },
      {
        field: "modules_type", headerName: "Modules", flex: 0.16,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.modules_type ? params?.row?.modules_type?.length > 30 ?
            <Tooltip placement='top' title={params?.row?.modules_type} arrow>
              <div>{params?.row?.modules_type.slice(0, 30) + "..."}</div></Tooltip> :
            params?.row?.modules_type : "-"}</Box>)
      },
      {
        field: "status", headerName: "Status", flex: 0.16,  align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.status === false ? <Typography fontWeight={600} color={'green'}>{"Active"}</Typography> : <Typography fontWeight={600} color={Colors.red}>{"Inactive"}</Typography>}</Box>)
      },
      { field: "created_date", headerName: "Created By", flex: 0.20,
      renderCell: (params) => <Box component={'div'}>{params?.row?.created_date  ? `${params?.row?.created_by} - ${params?.row?.created_date.split(" ")[0]}` : "-"}</Box> },
      {
        field: "action", headerName: "Action", flex: 0.12, align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>
          <Tooltip placement='top' title='Edit'>
            <Button className='eMed_user_btn'
              onClick={() => {
                this.setState({
                  isEdit: true
                }, () => {
                  this.state.isFromPharmacy ?
                    this.props.history.push({
                      pathname: "/PharmacySetting/UserConfiguration/New",
                      state: { userData: params?.row, isEdit: this.state.isEdit, showEdit: false }
                    })
                    : this.props.history.push({
                      pathname: "/Settings/UserAccess/UserSettings/New",
                      state: { userData: params?.row, isEdit: this.state.isEdit, showEdit: false }
                    })
                })
              }}>
              <img src={ImagePaths.Edit.default} className='eMed_edit_img' alt='edit' />
            </Button>
          </Tooltip>
        </Box>)
      }
    ]
    
    return (
      <Box component={'div'} className='eMed_user_conf_container' sx={{ backgroundColor: Colors.Background }}>
        <><Box component={'div'} className='eMed_user_conf_header'>
          <Box component={"div"} className='eMed_Filter_Icon_div'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Tooltip>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='small'
              disabled={!CheckAccessFunc("front_office", "Settings", "User Access Configuration", "User Access", "SubTab")?.editAccess}
              onClick={() => {
                this.state.isFromPharmacy ?
                  this.props.history.push({ pathname: "/PharmacySetting/UserConfiguration/New", state: { showEdit: true } }) :
                  this.props.history.push({ pathname: "/Settings/UserAccess/UserSettings/New", state: { showEdit: true } })
              }}
              sx={{ textTransform: "capitalize" }}
            >Add New User</Button>
          </Box>
        </Box>
          <Box component={'div'} className='eMed_user_conf_content'>
            <DataGrid
              className='eMed_user_conf_grid'
              rows={this.state.newUserData}
              columns={columns}
              getRowId={(row) => row['sno']}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              disableSelectionOnClick
              density='comfortable'
              loading={this.state.isLoader}
            />
          </Box></>
        {/* } */}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
      </Box>
    )
  }
}

export default withTranslation()(Useraccess)
