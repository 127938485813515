import React, { Component } from "react";
import '../Configuration.css';
import {
    Box, Paper, TextField, Button, Stack, Grid, TableContainer, Table, TableBody, TableCell, TableRow, IconButton,
    TableHead, TablePagination, Typography, Collapse, InputAdornment, Autocomplete, FormGroup, FormControlLabel, Checkbox, Tooltip
} from "@mui/material";
import { withTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { CommonDeleteButton, CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { TimePicker } from 'antd';
import { DateTime } from 'luxon';
import { ReasonPopup } from "../../../Components/Common Components/CommonComponents";
import moment from "moment/moment";
import { CheckAccessFunc, AmountFormat, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../Components/Loader';
import { CurrencySymbol } from "../../../Utility/Constants";


class AutomaticBills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDate: null,
            autoBillData: [],
            page: 0,
            rowsPerPage: 10,
            selectedIndex: null,
            selectedItem: {},
            serviceType: '',
            serviceAmount: '',
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            newRegTbl: [],
            regSelectIndex: null,
            DoctorsList: [],
            SpecialityList: [],
            selectedSpecId: null,
            selectedDocId: null,
            filteredDocList: [],
            docConAmount: '',
            DocConTbl: [],
            docConSelectIndex: null,
            startTime: '',
            endTime: '',
            emerAmount: '',
            emerConTble: [],
            emergencySelectIndex: null,
            AppoinTypeList: [],
            selectedAppinType: null,
            appoinSelectIndex: null,
            appoinConTbl: [],
            reviewPeriodtbl: [],
            reviewAmount: "",
            reviewDays: "",
            reviewSelectedIndex: null,
            holidayName: '',
            holidayConTbl: [],
            holidayAmount: '',
            holidaySelectIndex: null,
            selectService_id: null,
            selectedRegis_id: null,
            selectedDoctor_id: null,
            selectedEmerg_id: null,
            selectedAppoin_id: null,
            selectedReviewPeriod_id: null,
            selectedIPRegis_id: null,
            IPregistrationIndex: null,
            is_Saturday: false,
            is_Sunday: false,
            selectedHoliday_id: null,
            deleteData: false,
            deleted_id: null,
            newRegAmount: 0,
            DocConAmount: 0,
            emerConAmount: 0,
            appoinConAmount: 0,
            holidayConAmount: 0,
            appoinAmount: '',
            OPServicesList: [],
            OPServiceObj: null,
            IPServicesList: [],
            IPServiceObj: null,
            open: false,
            dupliHolidayConTbl: '[]',
            dupliAutoBillData: '[]',
            BasedOn: null
        }
    }
    componentDidMount() {
        this.GetDatas()
        this.getAutoBilling()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetDatas() {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DoctorsList: response.data.data ? response.data.data.filter((item) => (item.specialization_active === true)) : []
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            SpecialityList: response.data.data ? response.data.data.filter((item) => item.is_active == true) : []
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppoinTypeList: response.data.data
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
            RestAPIService.getAll(Serviceurls.FO_NEW_OP_SERVICE_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        let List = response.data.data.length > 0 ? response.data.data : []
                        let Services = []
                        List.forEach((item) => {
                            if (item?.service_catagory !== "Consultation") {
                                Services = [...Services, ...item?.data]
                            }
                        })
                        this.setState({
                            OPServicesList: Services
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
            RestAPIService.getAll(Serviceurls.IP_NEW_SERVICE_CONFIGURATION).
                then((response) => {
                    if (response.data.status === "success") {
                        let List = response.data.data.length > 0 ? response.data.data : []
                        let Services = []
                        List.forEach((item) => {
                            if (item?.service_category !== "Doctor Consultation Charges") {
                                Services = [...Services, ...item?.data]
                            }
                        })
                        this.setState({
                            IPServicesList: Services
                        })
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    returnTotalAmount = (list) => {
        let totalAmount = 0
        for (let i = 0; i < list.length; i++) {
            totalAmount = +list[i].service_amount + totalAmount
        }
        return totalAmount
    }
    getAutoBilling = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.AUTOBILLING_GET)
                .then((response => {
                    if (response.data.status === 'success') {
                        var item = response.data.data
                        this.setState({
                            autoBillData: response.data.data ? response.data.data : [],
                            dupliAutoBillData: JSON.stringify(response.data.data)
                        }, () => {
                            if (item[0]?.service_name === "Holiday List" &&
                                item[1]?.service_name === "Emergency Service Timing" &&
                                item[2]?.service_name === "Appointment Type" &&
                                item[3]?.service_name === "New Registration" &&
                                item[4]?.service_name === "Review Period" &&
                                item[5]?.service_name === "IP Registration Charges") {
                                this.setState({
                                    holidayConTbl: item[0].data,
                                    dupliHolidayConTbl: JSON.stringify(item[0].data),
                                    emerConTble: item[1].data,
                                    appoinConTbl: item[2].data,
                                    newRegTbl: item[3].data,
                                    reviewPeriodtbl: item[4].data,
                                    IpRegisrationTbl: item[5].data,
                                    appoinConAmount: this.returnTotalAmount(item[2].data),
                                    newRegAmount: this.returnTotalAmount(item[3].data),
                                    holidayConAmount: this.returnTotalAmount(item[4].data),
                                })
                            }
                            this.LoaderFunction(false)
                        })
                    }
                })).catch(e => {
                    this.LoaderFunction(false)
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    postAutoBilling = (data) => {
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.AUTOBILLING_GET)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.getAutoBilling()
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.successMessage(response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.errorMessage(response?.data?.message)
                    }
                })).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                    this.setState({disableBtn: false})
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    updateAutoBilling = (data) => {
        try {
            RestAPIService.updateWithOutId(data, Serviceurls.AUTOBILLING_GET)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.clearData()
                        this.getAutoBilling()
                    }
                })).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    deleteABdata = (cmt, DelID = null) => {
        try {
            let DeleteId = DelID === null ? this.state.deleted_id : DelID
            RestAPIService.delete(Serviceurls.AUTOBILLING_GET + '?service_type_id=' + DeleteId + '&reason=' + cmt)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getAutoBilling()
                        this.setState({
                            deleteData: false
                        })
                    }
                })).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.status)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    renderAutoBillLeft = () => {
        const { t } = this.props
        const tblBillData = this.state?.autoBillData
        return (
            <Box component={'div'}>
                <TableContainer component={'div'} className="eMed_ABill_Tbl" sx={{ overflowY: "scroll" }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Head">{t('SerialNo')}</TableCell>
                                <TableCell sx={{ width: '70vw' }} className="eMed_ABill_Head">{t("ServiceName")}</TableCell>
                                {/* <TableCell sx={{ width: '30vw' }} className="eMed_ABill_Head">{t("Amount") + `(${CurrencySymbol})`}</TableCell> */}
                                <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Head">{t("Action")}</TableCell>
                            </TableRow>
                        </TableHead>
                        {tblBillData?.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage).map((item, index) => (
                                <TableBody>
                                    <TableRow
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === index ? null : index
                                            })
                                        }}
                                        sx={{ backgroundColor: this.state.selectedIndex === index ? Colors.ThemeLightColor : 'white', cursor: 'pointer' }}>
                                        <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Tblcell">{index + 1}</TableCell>
                                        <TableCell sx={{ width: '70vw' }} className="eMed_ABill_Tblcell">{
                                            item.service_name?.length > 40 ?
                                                <Tooltip title={item.service_name} placement="top" arrow>
                                                    <div>{item.service_name ? item.service_name.slice(0, 38) + '...' : '-'}</div>
                                                </Tooltip> : <div>{item.service_name === "Emergency Service Timing" ? "Emergency Consultation" : item.service_name}</div>
                                        }</TableCell>
                                        {/* <TableCell sx={{ width: '30vw' }} className="eMed_ABill_Tblcell">{
                                            item.service_name === 'New Registration' ? this.state.newRegAmount ? AmountFormat(this.state.newRegAmount).replace("₹", "") : "-" :
                                                item.service_name === 'Doctor Consultation' ? this.state.DocConAmount ? AmountFormat(this.state.DocConAmount).replace("₹", "") : "-" :
                                                    item.service_name === 'Emergency Service Timing' ? "" :
                                                        item.service_name === 'Appointment Type' ? this.state.appoinConAmount ? AmountFormat(this.state.appoinConAmount).replace("₹", "") : "-" :
                                                            item.service_name === 'Holiday List' ? "" : '-'}</TableCell> */}
                                        <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Tblcell">
                                            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Hospital Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={() => {
                                                this.setState({
                                                    selectedItem: item,
                                                    selectService_id: item.service_id,
                                                    newRegTbl: item.data,
                                                    DocConTbl: item.data,
                                                    emerConTble: item.data,
                                                    appoinConTbl: item.data,
                                                    holidayConTbl: item.data,
                                                    reviewPeriodtbl: item.data
                                                }, () => {
                                                    this.clearData()
                                                })
                                            }} />
                                            <IconButton
                                                size='small'
                                                onClick={() => {
                                                    this.setState({
                                                        selectedIndex: this.state.selectedIndex === index ? null : index
                                                    })
                                                }}
                                            >
                                                {this.state.selectedIndex === index ?
                                                    <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {this.renderSubtbl(item, index)}
                                    </TableRow>
                                </TableBody>
                            ))}
                    </Table>
                    {tblBillData.length === 0 ?
                        <div className='eMed_AB_NoRecords'>
                            {t('NoRecordsFound')}
                        </div> : null}
                </TableContainer>
                <TablePagination
                    component='div'
                    id="eMed_tbl_pagination"
                    rowsPerPageOptions={[10, 20, 30]}
                    count={tblBillData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onRowsPerPageChange={(event) => { this.setState({ rowsPerPage: +event.target.value, page: 0 }) }}
                />
            </Box>
        )
    }

    renderSubtbl = (list, index) => {
        const { t } = this.props
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Box>
                    <Collapse
                        in={this.state.selectedIndex === index}
                        timeout={'auto'}
                    >
                        <Box margin={1}>
                            <TableContainer component={'div'} sx={{ border: '1px solid lightgray', overflow: "scroll" }}>
                                <Table aria-label="collapsible table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Head">{t('SerialNo')}</TableCell>
                                            {list.service_name === 'Review Period' ?<TableCell sx={{ width: '30vw' }} className="eMed_ABill_Head">{t('Based On')}</TableCell>:null}
                                            <TableCell sx={{ width: '40vw' }} className="eMed_ABill_Head">{
                                                list.service_name === 'New Registration' ? t("Service Name") :
                                                    list.service_name === 'Doctor Consultation' ? t("DoctorName") :
                                                        list.service_name === 'Emergency Service Timing' ? t("Timing") :
                                                            list.service_name === 'Appointment Type' ? t("AppType") :
                                                                list.service_name === 'Review Period' ? t("Review Days") :
                                                                    list.service_name === "IP Registration Charges" ? t("Service Name") : t("Holiday")}</TableCell>

                                            <TableCell sx={{ width: '30vw' }} className="eMed_ABill_Head">
                                                {list.service_name === 'Holiday List' ? t("Date") :
                                                    list.service_name === 'Doctor Consultation' ? t("DocSpe") :
                                                        (list.service_name === "Emergency Service Timing" || list.service_name === "IP Registration Charges") ? "" : t("Amount")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {list?.data?.map((data, index) => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Tblcell">{index + 1}</TableCell>
                                                {list.service_name === 'Review Period' ?<TableCell sx={{ width: '30vw' }} className="eMed_ABill_Tblcell">{
                                                    data.service_type.based_on ? data.service_type.based_on == "doctor" ? "Doctor" : data.service_type.based_on == "specialization" ? "Specialization" : data.service_type.based_on == "hospital" ? "Hospital" : '-' : '-'
                                                }</TableCell>:null}
                                                <TableCell sx={{ width: '40vw' }} className="eMed_ABill_Tblcell">{
                                                    (list.service_name === 'New Registration' || list.service_name === 'IP Registration Charges') ?
                                                        data.service_type?.service_name?.length > 40 ?
                                                            <Tooltip title={data.service_type.service_name} placement="top">
                                                                <div>{data.service_type.service_name ? data.service_type.service_name.slice(0, 40) + '...' : '-'}</div>
                                                            </Tooltip> : <div>{data.service_type.service_name ? data.service_type.service_name : '-'}</div>
                                                        :
                                                        list.service_name === 'Doctor Consultation' ?
                                                            data.service_type?.doctor_name?.length > 12 ?
                                                                <Tooltip title={data.service_type.doctor_name} placement="top">
                                                                    <div>{data.service_type.doctor_name ? data.service_type.doctor_name.slice(0, 12) + '...' : '-'}</div>
                                                                </Tooltip> : <div>{data.service_type.doctor_name ? data.service_type.doctor_name : '-'}</div> :
                                                            list.service_name === 'Emergency Service Timing' ?
                                                                (data.service_type.start_time && data.service_type.end_time) ? data.service_type.start_time + "  To  " + data.service_type.end_time : '-' :
                                                                list.service_name === 'Appointment Type' ?
                                                                    data.service_type?.appointment_type?.length > 40 ?
                                                                        <Tooltip title={data.service_type.appointment_type} placement="top">
                                                                            <div>{data.service_type.appointment_type ? data.service_type.appointment_type.slice(0, 40) + '...' : '-'}</div>
                                                                        </Tooltip> : <div>{data.service_type.appointment_type ? data.service_type.appointment_type : '-'}</div> :
                                                                    list.service_name === 'Holiday List' ?
                                                                        data.service_type?.holiday_name?.length > 12 ?
                                                                            <Tooltip title={data.service_type.holiday_name} placement="top">
                                                                                <div>{data.service_type.holiday_name ? data.service_type.holiday_name.slice(0, 12) + '...' : '-'}</div>
                                                                            </Tooltip> : <div>{data.service_type.holiday_name ? data.service_type.holiday_name : '-'}</div> :
                                                                        list.service_name === 'Review Period' ? data.service_type.review_days ? data.service_type.review_days : '-' :
                                                                            list.service_name === 'Doctor Consultation' ? <div sx={{ width: '30vw' }} className="eMed_ABill_Tblcell">{
                                                                                data.service_type?.doc_Speciality?.length > 12 ?
                                                                                    <Tooltip title={data.service_type.doc_Speciality} placement="top">
                                                                                        <div>{data.service_type.doc_Speciality ? data.service_type.doc_Speciality.slice(0, 12) + '...' : '-'}</div>
                                                                                    </Tooltip> : <div>{data.service_type.doc_Speciality ? data.service_type.doc_Speciality : '-'}</div>
                                                                            }</div> : null}</TableCell>
                                                <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Tblcell">
                                                    {(list.service_name === 'Emergency Service Timing' || list.service_name === 'IP Registration Charges') ? "" :
                                                        list.service_name === 'Holiday List' ? (data.service_type.holiday_date ? formatDate(data.service_type.holiday_date) : '-') :
                                                            (data.service_amount ? AmountFormat(data.service_amount).replace(`${CurrencySymbol}`, "") : "")}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                                {list?.data?.length === 0 ?
                                    <div className='eMed_AB_NoRecords1'>
                                        {t('NoRecordsFound')}
                                    </div> : null}
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
            </TableCell>
        )
    }

    renderAutoBillRight = (sName) => {
        const { t } = this.props
        return (
            <Box component={'div'}>
                {sName === "New Registration" ? this.renderRegCon() :
                    sName === "Emergency Service Timing" ? this.renderEmerCon() :
                        sName === "Appointment Type" ? this.renderAppoinCon() :
                            sName === "Holiday List" ? this.renderHoliCon() :
                                sName === "Review Period" ? this.renderReviewConfig() :
                                    sName === "IP Registration Charges" ? this.renderIPRegCharges() :
                                        <Box component={'div'} className="eMed_AB_leftData">
                                            <Typography>{t('PSST')}</Typography>
                                        </Box>
                }
            </Box>
        )
    }
    renderTextBox = (label, width, value, name, max) => {
        return (
            <Box component={'div'}>
                <TextField
                    id="Ser-type"
                    size="small"
                    inputProps={{ maxLength: max }}
                    value={value}
                    name={name}
                    sx={{ width: width }}
                    label={label}
                    InputProps={label === "Amount" ? {
                        endAdornment: <InputAdornment position="end">{CurrencySymbol}</InputAdornment>,
                    } : null}
                    variant="outlined"
                    onChange={(event) => { this.onChangeHandle(event) }} />
            </Box>
        )
    }
    onChangeHandle = (event) => {
        try {
            const { name, value } = event.target
            let alpha = CommonValidation.alphabetOnly(value);
            let number = CommonValidation.numberOnly(value);
            if (name === 'serviceType' || name === 'holidayName') {
                if (alpha || value === "") {
                    this.setState({ [name]: value })
                }
            } else {
                if (number || value === '')
                    this.setState({ [name]: value })
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    renderRegCon = () => {
        const { t } = this.props
        var states = this.state
        return (
            <Box component={'div'}>
                <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                    {/* {this.renderTextBox(t('ServiceType'), '12vw', this.state.serviceType, 'serviceType')} */}
                    {this.renderDropDown('Service Name', "OPServiceObj", this.state.OPServicesList)}
                    <Button id="eMed_Config_btn" size="small" variant="contained" disabled={states.OPServiceObj === null} onClick={() => { this.addNewRegister() }}>{t('Add')}</Button>
                </Box>
                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('Service Name')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.newRegTbl?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell">{
                                            data.service_type?.service_name?.length > 25 ?
                                                <Tooltip title={data.service_type.service_name} placement="top">
                                                    <div>{data.service_type.service_name ? data.service_type.service_name.slice(0, 25) + '...' : '-'}</div>
                                                </Tooltip> : <div>{data.service_type.service_name ? data.service_type.service_name : '-'}</div>
                                        }</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw" onClick={() => {
                                                this.setState({
                                                    regSelectIndex: index,
                                                    selectedRegis_id: data.service_type_id,
                                                    OPServiceObj: data.service_type
                                                })
                                            }} />
                                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                                for (let i = 0; i < states.newRegTbl.length; i++) {
                                                    if (states.newRegTbl[i].service_type_id) {
                                                        this.setState({
                                                            deleteData: true,
                                                            deleted_id: data.service_type_id
                                                        })
                                                    } else {
                                                        states.newRegTbl.splice(index, 1)
                                                        this.setState({ states, deleteData: false })
                                                    }
                                                }
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.newRegTbl?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
            </Box>
        )
    }
    duplicateRegCheck = () => {
        var { newRegTbl, OPServiceObj, selectedRegis_id, regSelectIndex } = this.state
        var duplicate = false
        const { t } = this.props
        if (OPServiceObj && Object.keys(OPServiceObj).length > 0) {
            for (let i = 0; i < newRegTbl.length; i++) {
                if (selectedRegis_id) {
                    if ((newRegTbl[i].service_type?.service_name === OPServiceObj?.service_name) && newRegTbl[i].service_type_id !== selectedRegis_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (regSelectIndex) {
                    if ((newRegTbl[i].service_type?.service_name === OPServiceObj?.service_name) && i !== regSelectIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if (newRegTbl[i].service_type?.service_name === OPServiceObj?.service_name) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        } else {
            this.errorMessage(t('PAD'))
        }
        return duplicate
    }

    returnServiceAmount(){
        try{
            var states = this.state
            if (states.OPServiceObj?.service_charges[0]?.service_fee?.total_amt) {
                return states.OPServiceObj?.service_charges[0]["service_fee"]['total_amt']
            } else if (states.OPServiceObj?.service_charges[0]?.service_amount) {
                return states.OPServiceObj?.service_charges[0]["service_amount"]
            } else {
                return 0
            }
        }
        catch(e){
            this.errorMessage(e.message)
        }
    }
    addNewRegister = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.duplicateRegCheck()
            var data = {
                'service_id': states.selectService_id,
                'service_type': { 'service_name': states.OPServiceObj.service_name, "service_name_id": states.OPServiceObj.id, },
                'service_amount': states.OPServiceObj?.service_charges?.length > 0 ? this.returnServiceAmount() : 0,
                "service_id_name": states.selectedItem.service_name,
            }
            if (states.selectedRegis_id) {
                data['service_type_id'] = states.selectedRegis_id
            }
            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if (states.OPServiceObj && Object.keys(states.OPServiceObj).length > 0) {
                    if (states.regSelectIndex !== null) {
                        states["newRegTbl"][states.regSelectIndex] = data
                    } else {
                        states['newRegTbl'].push(data)
                    }
                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    renderDropDown = (label, stateKey, Data) => {
        let states = this.state;
        return (
            <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: '20vw' }}
                options={Data}
                getOptionLabel={(item) => (item?.service_name)}
                value={states[stateKey]}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue
                    this.setState({ states })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={label}
                    />
                )}
            />
        )
    }
    duplicateDocConCheck = () => {
        var { DocConTbl, selectedDocId, selectedSpecId, selectedDoctor_id } = this.state
        var duplicate = false
        if (selectedDocId ||
            selectedSpecId) {
            for (let i = 0; i < DocConTbl.length; i++) {
                if (DocConTbl[i].service_type.doctor_name === (typeof selectedDocId === "string" ? selectedDocId : selectedDocId.doctor_name)) {
                    if (selectedDoctor_id != null && DocConTbl[i].service_type_id === selectedDoctor_id) {
                        duplicate = false
                    } else {
                        duplicate = true
                    }
                }
            }
        }
        return duplicate
    }
    renderEmerCon = () => {
        const { t } = this.props
        var states = this.state
        return (
            <Box>
                <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                <TimePicker use12Hours format="h:mm A" 
                    value={this.state.startTime ? moment(this.state.startTime, 'hh:mm a') : null}  onChange={(time, timeString) => {
                    this.setState({
                        startTime: timeString,
                    })
                }} />
                <TimePicker use12Hours format="h:mm A" 
                    value={this.state.endTime ? moment(this.state.endTime, 'hh:mm a') : null}  onChange={(time, timeString) => {
                    this.setState({
                        endTime: timeString,
                    })
                }} />
                    {/* {this.renderTextBox(t('Amount'), '8vw', this.state.emerAmount, 'emerAmount', 7)} */}
                    <Button id="eMed_Config_btn" size="small" variant="contained" onClick={() => { this.checkTimeDuplication(states?.emergencySelectIndex) }}>{t('Add')}</Button>
                </Box>
                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('Timing')}</TableCell>
                                    {/* <TableCell className="eMed_ABill_Head">{t('Amount')}</TableCell> */}
                                    <TableCell className="eMed_ABill_Head">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.emerConTble?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell">{(data.service_type.start_time && data.service_type.end_time) ? data.service_type.start_time + " To " + data.service_type.end_time : '-'}</TableCell>
                                        {/* <TableCell className="eMed_ABill_Tblcell">{data.service_amount ? data.service_amount : '-'}</TableCell> */}
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw" onClick={() => {
                                                this.setState({
                                                    startTime: data.service_type.start_time,
                                                    endTime: data.service_type.end_time,
                                                    emerAmount: data.service_amount,
                                                    emergencySelectIndex: index,
                                                    selectedEmerg_id: data.service_type_id
                                                })
                                            }} />
                                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                                for (let i = 0; i < states.emerConTble.length; i++) {
                                                    if (states.emerConTble[i].service_type_id) {
                                                        this.setState({
                                                            deleteData: true,
                                                            deleted_id: data.service_type_id
                                                        })
                                                    } else {
                                                        states.emerConTble.splice(index, 1)
                                                        this.setState({ states, deleteData: false })
                                                    }
                                                }
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.emerConTble?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
            </Box>
        )
    }
    EmerTimeCheck = () => {
        var { emerConTble, startTime, endTime, emergencySelectIndex, selectedEmerg_id } = this.state
        var duplicate = false
        const { t } = this.props
        if (startTime && endTime) {
            for (let i = 0; i < emerConTble.length; i++) {
                if (selectedEmerg_id) {
                    if ((emerConTble[i].service_type?.start_time === startTime && emerConTble[i].service_type?.end_time === endTime) && emerConTble[i].service_type_id !== selectedEmerg_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (emergencySelectIndex) {
                    if ((emerConTble[i].service_type?.start_time === startTime && emerConTble[i].service_type?.end_time === endTime) && i !== emergencySelectIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if ((emerConTble[i].service_type?.start_time === startTime && emerConTble[i].service_type?.end_time === endTime)) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        } else {
            this.errorMessage(t('PAD'))
        }
        return duplicate
    }

    checkTimeDuplication = (emergencySelectIndex = null) => {
        const { startTime, endTime, emerConTble } = this.state;
    
        const newStart = moment(startTime, 'hh:mm A');
        const newEnd = moment(endTime, 'hh:mm A');
    
        const isOverlap = emerConTble.some((data, index) => {
            if (index === emergencySelectIndex) {
                return false;
            }
            const existingStart = moment(data.service_type.start_time, 'hh:mm A');
            const existingEnd = moment(data.service_type.end_time, 'hh:mm A');
    
            return (
                (newStart.isSameOrAfter(existingStart) && newStart.isBefore(existingEnd)) ||
                (newEnd.isAfter(existingStart) && newEnd.isSameOrBefore(existingEnd)) ||
                (newStart.isBefore(existingStart) && newEnd.isAfter(existingEnd))
            );
        });
    
        if (isOverlap) {
            this.errorMessage("This time slot overlaps with an existing time range. Please choose a different time.");
        } else {
            this.addemergencyTime();
        }
    }      

    addemergencyTime = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.EmerTimeCheck()
            var data = {
                'service_id': states.selectService_id,
                'service_type': { 'start_time': states.startTime, 'end_time': states.endTime },
                'service_amount': 0,
                "service_id_name": states.selectedItem.service_name,
            }
            if (states.selectedEmerg_id) {
                data['service_type_id'] = states.selectedEmerg_id
            }
            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if (states.startTime && states.endTime) {
                    if (states.emergencySelectIndex !== null) {
                        states["emerConTble"][states.emergencySelectIndex] = data
                    } else {
                        states['emerConTble'].push(data)
                    }
                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    renderAppoinCon = () => {
        const { t } = this.props
        var states = this.state
        return (
            <Box component={'div'}>
                <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={states.AppoinTypeList}
                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options.appoinment_type}
                        onChange={(e, newValue) => {
                            this.setState({
                                selectedAppinType: newValue
                            })
                        }}
                        value={states.selectedAppinType}
                        sx={{ width: '12vw' }}
                        renderInput={(params) => <TextField {...params} label={t('AppType')} />}
                    />
                    {this.renderTextBox(t('Amount'), '12vw', this.state.appoinAmount, 'appoinAmount', 7)}
                    <Button id="eMed_Config_btn" size="small" variant="contained" onClick={() => { this.addAppoinType() }}>{t('Add')}</Button>
                </Box>
                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('AppType')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t('Amount')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.appoinConTbl?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell">{
                                            data.service_type?.appointment_type?.length > 15 ?
                                                <Tooltip title={data.service_type.appointment_type} placement="top">
                                                    <div>{data.service_type.appointment_type ? data.service_type.appointment_type.slice(0, 15) + '...' : '-'}</div>
                                                </Tooltip> : <div>{data.service_type.appointment_type ? data.service_type.appointment_type : '-'}</div>
                                        }</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">{data.service_amount ? data.service_amount : '-'}</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw" onClick={() => {
                                                this.setState({
                                                    selectedAppinType: data.service_type.appointment_type,
                                                    appoinAmount: data.service_amount,
                                                    appoinSelectIndex: index,
                                                    selectedAppoin_id: data.service_type_id
                                                })
                                            }} />
                                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                                for (let i = 0; i < states.appoinConTbl.length; i++) {
                                                    if (states.appoinConTbl[i].service_type_id) {
                                                        this.setState({
                                                            deleteData: true,
                                                            deleted_id: data.service_type_id
                                                        })
                                                    } else {
                                                        states.appoinConTbl.splice(index, 1)
                                                        this.setState({ states, deleteData: false })
                                                    }
                                                }
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.appoinConTbl?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
            </Box>
        )
    }
    renderReviewConfig = () => {
        const { t } = this.props
        var states = this.state
        let NumberList = []
        for (var i = 1; i < 100; i++) { NumberList.push(i); }
        const Basedon = [
            {listName: "Doctor", value: "doctor"},
            {listName: "Specialization", value: "specialization"},
            {listName: "Hospital", value: "hospital"}
        ]
        return (
            <Box component={'div'}>
                <Box component={'div'}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={Basedon}
                        getOptionLabel={(item) => item.listName}
                        onChange={(e, newValue) => {
                            this.setState({
                                BasedOn: newValue
                            })
                        }}
                        value={states.BasedOn}
                        sx={{ width: '31.5vw', margin:"0.5vw" }}
                        renderInput={(params) => <TextField {...params} label={t('Based On')} />}
                    />
                </Box>
                <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={NumberList}
                        onChange={(e, newValue) => {
                            this.setState({
                                reviewDays: newValue
                            })
                        }}
                        value={states.reviewDays}
                        sx={{ width: '12vw' }}
                        renderInput={(params) => <TextField {...params} label={t('Review Days')} />}
                    />
                    {this.renderTextBox(t('Amount'), '12vw', this.state.reviewAmount, 'reviewAmount', 7)}
                    <Button id="eMed_Config_btn" disabled={(states.reviewPeriodtbl?.length === 0 || states.reviewSelectedIndex !== null) ? false : true} size="small" variant="contained" onClick={() => { this.AddReviewPeriod() }}>{t('Add')}</Button>
                </Box>
                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('Based On')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t('Review Days')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t('Review Fees')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.reviewPeriodtbl?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell">{data.service_type.based_on ? data.service_type.based_on == "doctor" ? "Doctor" : data.service_type.based_on == "specialization" ? "Specialization" : data.service_type.based_on == "hospital" ? "Hospital" : '-' : '-'}</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">{data.service_type.review_days ? data.service_type.review_days : '-'}</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">{data.service_amount ? data.service_amount : '-'}</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw" onClick={() => {
                                                let Basedon = {
                                                    listName: data.service_type.based_on == "doctor" ? "Doctor" : data.service_type.based_on == "specialization" ? "Specialization" : data.service_type.based_on == "hospital" ? "Hospital" : '-',
                                                    value: data.service_type.based_on
                                                }
                                                this.setState({
                                                    reviewDays: data.service_type.review_days,
                                                    reviewAmount: data.service_amount,
                                                    reviewSelectedIndex: index,
                                                    selectedReviewPeriod_id: data.service_type_id,
                                                    BasedOn : Basedon
                                                })
                                            }} />
                                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                                for (let i = 0; i < states.reviewPeriodtbl.length; i++) {
                                                    if (states.reviewPeriodtbl[i].service_type_id) {
                                                        this.setState({
                                                            deleteData: true,
                                                            deleted_id: data.service_type_id
                                                        })
                                                    } else {
                                                        states.reviewPeriodtbl.splice(index, 1)
                                                        this.setState({ states, deleteData: false })
                                                    }
                                                }
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.appoinConTbl?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
            </Box>
        )
    }
    renderIPRegCharges = () => {
        const { t } = this.props
        var states = this.state
        return (
            <Box component={'div'}>
                <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                    {this.renderDropDown('Service Name', "IPServiceObj", this.state.IPServicesList)}
                    <Button id="eMed_Config_btn" size="small" variant="contained" onClick={() => { this.AddIpRegServies() }}>{t('Add')}</Button>
                </Box>
                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('Service Name')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.IpRegisrationTbl?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell" style={{ fontSize: "1vw" }}>
                                            {
                                                data.service_type?.service_name?.length > 25 ?
                                                    <Tooltip title={data.service_type.service_name} placement="top">
                                                        <div>{data.service_type.service_name ? data.service_type.service_name.slice(0, 25) + '...' : '-'}</div>
                                                    </Tooltip> : <div>{data.service_type.service_name ? data.service_type.service_name : '-'}</div>
                                            }
                                        </TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw" onClick={() => {
                                                this.setState({
                                                    IPServiceObj: data.service_type,
                                                    IPregistrationIndex: index,
                                                    selectedIPRegis_id: data.service_type_id
                                                })
                                            }} />
                                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                                for (let i = 0; i < states.reviewPeriodtbl.length; i++) {
                                                    if (states.reviewPeriodtbl[i].service_type_id) {
                                                        this.setState({
                                                            deleteData: true,
                                                            deleted_id: data.service_type_id
                                                        })
                                                    } else {
                                                        states.reviewPeriodtbl.splice(index, 1)
                                                        this.setState({ states, deleteData: false })
                                                    }
                                                }
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.appoinConTbl?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
            </Box>
        )
    }
    appTypeCheck = () => {
        var { appoinConTbl, selectedAppinType, appoinAmount, selectedAppoin_id, appoinSelectIndex } = this.state
        var duplicate = false
        const { t } = this.props
        if (selectedAppinType && appoinAmount) {
            for (let i = 0; i < appoinConTbl.length; i++) {
                if (selectedAppoin_id) {
                    if ((appoinConTbl[i].service_type?.appointment_type === (typeof selectedDocId === "string" ? selectedAppinType : selectedAppinType.appoinment_type)) && appoinConTbl[i].service_type_id !== selectedAppoin_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (appoinSelectIndex) {
                    if ((appoinConTbl[i].service_type?.appointment_type === (typeof selectedDocId === "string" ? selectedAppinType : selectedAppinType.appoinment_type)) && i !== appoinSelectIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if ((appoinConTbl[i].service_type?.appointment_type === (typeof selectedDocId === "string" ? selectedAppinType : selectedAppinType.appoinment_type))) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        } else {
            this.errorMessage(t('PAD'))
        }
        return duplicate
    }

    reviewPeriodCheck = () => {
        var { reviewPeriodtbl, reviewAmount, reviewDays, selectedReviewPeriod_id, reviewSelectedIndex } = this.state
        var duplicate = false
        if (reviewDays || reviewAmount) {
            for (let i = 0; i < reviewPeriodtbl.length; i++) {
                if (selectedReviewPeriod_id) {
                    if (reviewPeriodtbl[i].service_type?.review_days === reviewDays && reviewPeriodtbl[i].service_type_id !== selectedReviewPeriod_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (reviewSelectedIndex) {
                    if (reviewPeriodtbl[i].service_type?.review_days === reviewDays && i !== reviewSelectedIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if (reviewPeriodtbl[i].service_type?.review_days === reviewDays) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        }
        return duplicate
    }
    IPregistrationCheck = () => {
        var { IpRegisrationTbl, IPServiceObj, selectedIPRegis_id, IPregistrationIndex } = this.state
        var duplicate = false
        const { t } = this.props
        if (IPServiceObj && Object.keys(IPServiceObj).length > 0) {
            for (let i = 0; i < IpRegisrationTbl.length; i++) {
                if (selectedIPRegis_id) {
                    if (IpRegisrationTbl[i].service_type?.service_name === IPServiceObj?.service_name && IpRegisrationTbl[i].service_type_id !== selectedIPRegis_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (IPregistrationIndex) {
                    if (IpRegisrationTbl[i].service_type?.service_name === IPServiceObj?.service_name && i !== IPregistrationIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if (IpRegisrationTbl[i].service_type?.service_name === IPServiceObj?.service_name) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        } else {
            this.errorMessage(t('PAD'))
        }
        return duplicate
    }

    addAppoinType = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.appTypeCheck()
            var data = {
                'service_id': states.selectService_id,
                'service_type': { 'appointment_type': states.selectedAppinType ? typeof (states.selectedAppinType) === 'string' ? states.selectedAppinType : states.selectedAppinType.appoinment_type : null },
                'service_amount': states.appoinAmount,
                "service_id_name": states.selectedItem.service_name,
            }
            if (states.selectedAppoin_id) {
                data['service_type_id'] = states.selectedAppoin_id
            }
            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if (states.selectedAppinType && states.appoinAmount) {
                    if (states.appoinSelectIndex !== null) {
                        states["appoinConTbl"][states.appoinSelectIndex] = data
                    } else {
                        states['appoinConTbl'].push(data)
                    }

                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    AddReviewPeriod = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.reviewPeriodCheck()
            var data = {
                'service_id': states.selectService_id,
                'service_type': { 'review_days': states.reviewDays, "based_on" : states.BasedOn?.value },
                'service_amount': states.reviewAmount,
                "service_id_name": states.selectedItem.service_name,
            }
            if (states.selectedReviewPeriod_id) {
                data['service_type_id'] = states.selectedReviewPeriod_id
            }

            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if (states.reviewDays && states.reviewAmount) {
                    if (states.reviewSelectedIndex !== null) {
                        states["reviewPeriodtbl"][states.reviewSelectedIndex] = data
                    } else {
                        states["reviewPeriodtbl"].push(data)
                    }
                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    AddIpRegServies = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.IPregistrationCheck()
            var data = {
                'service_id': states.selectService_id,
                'service_type': { 'service_name': states.IPServiceObj?.service_name, "service_name_id": states.IPServiceObj?.id, },
                'service_amount': states.IPServiceObj?.consultant_charge?.length > 0 ? +states.IPServiceObj?.consultant_charge[0]["service_amount"] : 0,
                "service_id_name": states.selectedItem.service_name,
            }
            if (states.selectedIPRegis_id) {
                data['service_type_id'] = states.selectedIPRegis_id
            }

            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if (states.IPServiceObj && Object.keys(states.IPServiceObj).length > 0) {
                    if (states.IPregistrationIndex !== null) {
                        states["IpRegisrationTbl"][states.IPregistrationIndex] = data
                    } else {
                        states["IpRegisrationTbl"].push(data)
                    }
                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    AddCheckboxHoliday = (Key, e) => {
        let { holidayConTbl } = this.state
        if (Key === "Both") {
            if (e.target.checked) {
                if (holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Saturday")) === -1) {
                    holidayConTbl.push({
                        'service_id': this.state.selectService_id,
                        'service_type': {
                            'holiday_name': "Saturday",
                            'holiday_date': "",
                        },
                        'service_amount': 0,
                    })
                }
                if (holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Sunday")) === -1) {
                    holidayConTbl.push({
                        'service_id': this.state.selectService_id,
                        'service_type': {
                            'holiday_name': "Sunday",
                            'holiday_date': ""
                        },
                        'service_amount': 0,
                    })
                }
            }
            else {
                if (holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Sunday")) !== -1) {
                    let SundayId = holidayConTbl[holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Sunday"))]?.service_type_id ? holidayConTbl[holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Sunday"))]?.service_type_id : null
                    if (SundayId === null) {
                        holidayConTbl.splice(holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Sunday")), 1)
                    } else {
                        this.deleteABdata("sunday", SundayId)
                    }

                }
                if (holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Saturday")) !== -1) {
                    let SaturdayId = holidayConTbl[holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Saturday"))]?.service_type_id ? holidayConTbl[holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Saturday"))]?.service_type_id : null
                    if (SaturdayId === null) {
                        holidayConTbl.splice(holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === "Saturday")), 1)
                    } else {
                        this.deleteABdata("saturday", SaturdayId)
                    }
                }
            }
        } else {
            let KeyIndex = holidayConTbl.findIndex((list) => (list.service_type?.holiday_name === Key))
            if (KeyIndex === -1 && Key !== "Both") {
                holidayConTbl.push({
                    'service_id': this.state.selectService_id,
                    'service_type': {
                        'holiday_name': Key,
                        'holiday_date': "",
                    },
                    'service_amount': 0,
                })
            } else if (KeyIndex !== -1 && Key !== "Both") {
                let SundayId = holidayConTbl[KeyIndex]?.service_type_id ? holidayConTbl[KeyIndex]?.service_type_id : null
                if (SundayId === null) {
                    holidayConTbl.splice(KeyIndex, 1)
                } else {
                    this.deleteABdata(Key, SundayId)
                }

            }
        }
        this.setState({ holidayConTbl })
    }
    renderHoliCon = () => {
        const { t } = this.props
        var states = this.state
        let isHaveSaturday = states.holidayConTbl.find((list) => (list.service_type?.holiday_name === "Saturday")) ? (Object.keys(states.holidayConTbl.find((list) => (list.service_type?.holiday_name === "Saturday"))).length > 0 ? true : false) : false
        let isHaveSunday = states.holidayConTbl.find((list) => (list.service_type?.holiday_name === "Sunday")) ? (Object.keys(states.holidayConTbl.find((list) => (list.service_type?.holiday_name === "Sunday"))).length > 0 ? true : false) : false
        return (
            <Box component={'div'}>
                {/* {this.renderTextBox(t('HoliName'), '31vw', this.state.holidayName, 'holidayName')} */}
                <Box component={'div'} marginTop={'1vw'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            open={this.state.open}
                            onOpen={() => { this.setState({ open: true }) }}
                            onClose={() => { this.setState({ open: false }) }}
                            value={this.state.selectDate}
                            name={"datePicker"}
                            inputFormat='DD-MM-YYYY'
                            label={t('SelectDate')}
                            views={["year", "month", "day"]}
                            onChange={
                                (newDate) => {
                                    this.setState({
                                        selectDate: newDate,
                                    });
                                }}
                            renderInput={(params) => <TextField autoComplete="off" style={{ width: '12vw' }} size='small' {...params} onClick={() => this.setState({ open: true })} />}
                        />
                    </LocalizationProvider>
                    {this.renderTextBox(t('HoliName'), '12vw', this.state.holidayName, 'holidayName')}
                    <Button id="eMed_Config_btn" size="small" variant="contained" onClick={() => { this.addHolidayTbl() }}>{t('Add')}</Button>
                </Box>

                <Box marginTop={'1vw'}>
                    <TableContainer component={'div'} className="eMed_AB_Left_Tbl">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="eMed_ABill_Head">{t('HoliName')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t('HoliDate')}</TableCell>
                                    <TableCell className="eMed_ABill_Head">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.holidayConTbl?.map((data, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="eMed_ABill_Tblcell">{
                                            data.service_type?.holiday_name?.length > 12 ?
                                                <Tooltip title={data.service_type.holiday_name} placement="top">
                                                    <div>{data.service_type.holiday_name ? data.service_type.holiday_name.slice(0, 12) + '...' : '-'}</div>
                                                </Tooltip> : <div>{data.service_type.holiday_name ? data.service_type.holiday_name : '-'}</div>
                                        }</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">{data.service_type.holiday_date ? formatDate(data.service_type.holiday_date) : '-'}</TableCell>
                                        <TableCell className="eMed_ABill_Tblcell">
                                            <CommonEditButton size="1.5vw"
                                                disable={data.service_type.holiday_name === "Saturday" || data.service_type.holiday_name === "Sunday"}
                                                onClick={() => {
                                                    this.setState({
                                                        holidayName: data.service_type.holiday_name,
                                                        selectDate: data.service_type.holiday_date,
                                                        holidaySelectIndex: index,
                                                        selectedHoliday_id: data.service_type_id
                                                    })
                                                }} />
                                            <CommonDeleteButton size="1.5vw"
                                                disable={data.service_type.holiday_name === "Saturday" || data.service_type.holiday_name === "Sunday"}
                                                onClick={() => {
                                                    for (let i = 0; i < states.holidayConTbl.length; i++) {
                                                        if (states.holidayConTbl[i].service_type_id) {
                                                            this.setState({
                                                                deleteData: true,
                                                                deleted_id: data.service_type_id
                                                            })
                                                        } else {
                                                            states.holidayConTbl.splice(index, 1)
                                                            this.setState({ states, deleteData: false })
                                                        }
                                                    }
                                                }} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                        {states.holidayConTbl?.length === 0 ?
                            <div className='eMed_leftTbl_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Box>
                <Box component={'div'} marginTop={'1vw'} marginLeft={"1vw"} className="eMed_DisSum_Lay_Spec">
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel control={<Checkbox checked={isHaveSaturday && isHaveSunday} onChange={(e) => { this.AddCheckboxHoliday("Both", e) }} />} label={t('Both')} />
                        <FormControlLabel control={<Checkbox checked={isHaveSaturday} onChange={(e) => { this.AddCheckboxHoliday("Saturday", e) }} />} label={t('AllSat')} />
                        <FormControlLabel control={<Checkbox checked={isHaveSunday} onChange={(e) => { this.AddCheckboxHoliday("Sunday", e) }} />} label={t('AllSun')} />
                    </FormGroup>
                </Box>
            </Box>
        )
    }
    holidayDataCheck = () => {
        var { holidayConTbl, holidayName, selectDate, selectedHoliday_id, holidaySelectIndex } = this.state
        var duplicate = false
        var StateDate = new Date(this.state.selectDate)
        var date = this.state.selectDate ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
        const { t } = this.props
        if (holidayName && selectDate) {
            for (let i = 0; i < holidayConTbl.length; i++) {
                if (selectedHoliday_id) {
                    if ((holidayConTbl[i].service_type?.holiday_name === holidayName || holidayConTbl[i].service_type?.holiday_date === date) && holidayConTbl[i].service_type_id !== selectedHoliday_id) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else if (holidaySelectIndex) {
                    if ((holidayConTbl[i].service_type?.holiday_name === holidayName || holidayConTbl[i].service_type?.holiday_date === date) && i !== holidaySelectIndex) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                } else {
                    if (holidayConTbl[i].service_type?.holiday_name === holidayName || holidayConTbl[i].service_type?.holiday_date === date) {
                        duplicate = true
                        break
                    } else {
                        duplicate = false
                    }
                }
            }
        } else {
            this.errorMessage(t('PAD'))
        }
        return duplicate
    }
    addHolidayTbl = () => {
        try {
            const { t } = this.props
            var states = this.state
            var duplicate = this.holidayDataCheck()
            var StateDate = new Date(states.selectDate)
            var date = states.selectDate ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
            var data = {
                'service_id': states.selectService_id,
                "service_id_name": states.selectedItem.service_name,
                'service_type': {
                    'holiday_name': states.holidayName,
                    'holiday_date': date,
                    // 'is_saturday': states.is_Saturday,
                    // 'is_sunday': states.is_Sunday
                },
                'service_amount': 0,
            }
            if (states.selectedHoliday_id) {
                data['service_type_id'] = states.selectedHoliday_id
            }
            if (duplicate) {
                this.errorMessage(t('AVA'))
            } else {
                if ((states.holidayName && states.selectDate)) {
                    if (states.holidaySelectIndex !== null) {
                        states["holidayConTbl"][states.holidaySelectIndex] = data
                    } else {
                        states['holidayConTbl'].push(data)
                    }
                    this.setState({
                        states
                    }, () => { this.clearData() })
                } else {
                    this.errorMessage(t('PAD'))
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    clearData = () => {
        this.setState({
            serviceType: '',
            serviceAmount: '',
            selectedDocId: null,
            selectedSpecId: null,
            docConAmount: '',
            endTime: '',
            startTime: '',
            emerAmount: '',
            selectedAppinType: null,
            appoinAmount: '',
            holidayName: '',
            holidayAmount: '',
            reviewDays: '',
            reviewAmount: '',
            selectDate: null,
            holidaySelectIndex: null,
            appoinSelectIndex: null,
            emergencySelectIndex: null,
            IPregistrationIndex: null,
            docConSelectIndex: null,
            regSelectIndex: null,
            selectedRegis_id: null,
            selectedDoctor_id: null,
            selectedEmerg_id: null,
            selectedHoliday_id: null,
            selectedReviewPeriod_id: null,
            selectedIPRegis_id: null,
            selectedAppoin_id: null,
            is_Saturday: false,
            is_Sunday: false,
            filteredDocList: [],
            reviewSelectedIndex: null,
            OPServiceObj: null,
            IPServiceObj: null,
            BasedOn: null
        })
    }

    saveData = () => {
        var states = this.state
        if (states.selectedItem.service_name === "New Registration" && states.newRegTbl.length > 0) {
            this.postAutoBilling(states.newRegTbl)
        } else if (states.selectedItem.service_name === "Doctor Consultation" && states.DocConTbl.length > 0) {
            this.postAutoBilling(states.DocConTbl)
        } else if (states.selectedItem.service_name === "Emergency Service Timing" && states.emerConTble.length > 0) {
            this.postAutoBilling(states.emerConTble)
        } else if (states.selectedItem.service_name === "Appointment Type" && states.appoinConTbl.length > 0) {
            this.postAutoBilling(states.appoinConTbl)
        } else if (states.selectedItem.service_name === "Holiday List" && states.holidayConTbl.length > 0) {
            this.postAutoBilling(states.holidayConTbl)
        } else if (states.selectedItem.service_name === "Review Period" && states.reviewPeriodtbl.length > 0) {
            this.postAutoBilling(states.reviewPeriodtbl)
        } else if (states.selectedItem.service_name === "IP Registration Charges" && states.IpRegisrationTbl.length > 0) {
            this.postAutoBilling(states.IpRegisrationTbl)
        } else {
            this.errorMessage("Please Add the Details")
        }

    }

    msgClose = () => {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    popupClose() {
        this.setState({
            deleteData: false
        })
    }
    addCancelComments(comments) {
        this.deleteABdata(comments)
    }
    render() {
        const { t } = this.props;
        var states = this.state
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            {this.renderAutoBillLeft()}
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{states.selectedItem.service_name === "New Registration" ? t("NewRegistration") :
                                    states.selectedItem.service_name === "Doctor Consultation" ? t("DocCon") :
                                        states.selectedItem.service_name === "Emergency Service Timing" ? t("EmerService") :
                                            states.selectedItem.service_name === "Appointment Type" ? t("AppType") :
                                                states.selectedItem.service_name === "Holiday List" ? t('HFees') :
                                                    states.selectedItem.service_name === "Review Period" ? t('Review Period') :
                                                        states.selectedItem.service_name === "IP Registration Charges" ? t('IP Registration Charges') : ''}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                {this.renderAutoBillRight(states.selectedItem.service_name)}
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => {
                                    this.setState({
                                        holidayConTbl: JSON.parse(this.state.dupliHolidayConTbl),
                                        autoBillData: JSON.parse(this.state.dupliAutoBillData)
                                    })
                                    this.clearData()
                                }}>{t("Clear")}</Button>
                                <Button variant='contained' size="small" id="eMed_Config_btn" disabled={this.state.disableBtn ? true : this.state.selectService_id ? false : true} onClick={() => { this.saveData() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)} /> : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)} /> : null}
                {this.state.deleteData ? <ReasonPopup
                    title={t('ADDD')}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={t('ER')}
                    btntext={t('Cancel')}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={t('Confirm')}
                    DeleteNotify={t('ifDataDelete')}
                    btnvarient1={'contained'}
                    sendCmt={this.addCancelComments.bind(this)}
                /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(AutomaticBills);
