import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';

class CompletedBills extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "PayMethods": [],
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
            },
            TotalPatient: 0,
            PaidAmount: 0,
            BillPreviewPop: false,
            SelectedBill: {},
        }
    }

    componentDidMount() {
        this.GetBillList()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    GetBillList() {
        try {
            var states = this.state
            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.COMPLETE_BILLS_GET +
                "?bill_status=paid" +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&account_number=" + states.FilterDatas.AccNo +
                "&mobile_number=" + states.FilterDatas.MobNo +
                "&doctor_id=" + doctorId +
                "&bill_number=" + states.FilterDatas.BillNumber +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BillList: response.data.data,
                            TotalPatient: response.data.total_patient,
                            PaidAmount: response.data.paid_amount,
                        },()=>{this.LoaderFunction(false)})
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.GetBillList()
            }
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "NextAppointment", label: t("NextAppointment") },
            { value: "PrintDoctorNotes", label: t("PrintDoctorNotes") },
            { value: "PrintPrescription", label: t("PrintPrescription") },
            { value: "ShareViaEmail", label: t("ShareViaEmail") },
            { value: "ShareViaWhatsApp", label: t("ShareViaWhatsApp") },
            { value: "ShareViaSMS", label: t("ShareViaSMS") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (

                            <MenuItem key={option} onClick={() => { this.setState({ selectedOption: option.value, anchorEl: null }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetBillList() })
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilterDatas.FromDate)},
            {label:"To Date", value:formatDate(this.state.FilterDatas.ToDate)},
        ]
        return(
          <Box>
             <CommonGridToolBarWithFilterText  FilterTextArray={TextArray}/>
          </Box>
    
        )
      }

    render() {
        this.state.BillList.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "invoice_date", flex: 0.125, headerName: t("Date"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "bill_number", flex: 0.15, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_number ? params?.row?.bill_number : "-"}</Box>)
            },
            {
                field: "bill_types", flex: 0.125, headerName: t("BillType"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_types ? params?.row?.bill_types : "-"}</Box>)
            },
            {
                field: "patient__first_name", flex: 0.125, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient__first_name ? params?.row?.patient__first_name : "-"}</Box>)
            },
            {
                field: "doctor_name", flex: 0.125, headerName: t("DoctorName"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "amount_net", flex: 0.125, headerName: t('TotalBill')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_net ? params?.row?.amount_net : params?.row?.amount_net === 0 ? "0" : "-"}</Box>)
            },
            // {
            //     field: "payment_mode_types", flex: 0.125, headerName: t("PaymentMethods"),
            //     renderCell: (params) => (<Box component={'div'}>
            //         {params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 20 ?
            //             <Tooltip placement='top' title={params?.row?.payment_mode_types}>
            //                 <div>{params?.row?.payment_mode_types.slice(0, 20) + "..."}</div></Tooltip> : 
            //                 params?.row?.payment_mode_types: "-"}</Box>)
            // },
            {
                field: "action", flex: 0.125, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} arrow>
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                        </Button></Tooltip>
                    {/* <Tooltip title={t("CreateBill")} placement="top">
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.Export.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.MenuItem(params)} */}
                </Box>
            }
        ]
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalPatients"), this.state.TotalPatient, true)}
                        {AmountsCard(t("BillPaidAmount"), this.state.PaidAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        {/* <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"CompletedBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} />
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Completed Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient" ) : false}
                            cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Completed Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient" ) : false}
                            PrintButton={false}
                            history={this.props.history} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    {/* <Loader loaderOpen={this.state.isLoader} /> */}
            </Box>
        )
    }
}

export default withTranslation()(CompletedBills)

